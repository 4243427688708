export const EMP_KEY_LABELS = {
  password: "labels.password",
  isActive: "labels.status",
  departmentName: "titles.department_name",
  branchName: "labels.branch_name",
  seniorityLevel: "labels.seniority_level",
  directCoachId: "labels.direct_coach",
  directManagerId: "labels.direct_manager",
  type: "labels.type",
  name: "labels.name",
  assign: "labels.assign_to",
  branchId: "labels.branch",
  start_work_date: "labels.start_work_date",
  end_work_date: "labels.end_work_date",
  title: "labels.title",
  gender: "labels.gender",
  father_name: "labels.father_name",
  mother_name: "labels.mother_name",
  phone: "labels.phone",
  date_of_birth: "labels.date_of_birth",
  fax: "labels.fax",
  nationality: "labels.nationality",
  emergency_contact_info: "labels.emergency_contact_info",
  zip: "labels.zip",
  state: "labels.state",
  country: "labels.country",
  city: "labels.company_group",
  address1: "labels.address_1",
  responsibilities: "labels.responsibilities",
  cv: "labels.cv",
  identity: "labels.identity",
  updatedBy: "titles.updated_by",
};
