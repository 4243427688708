import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ComponentButton from "../../../../componentGroups/componentButton";
import TemplateLogo from "../../../../../assets/images/logotemplate.svg";

const PrintTemplate = ({ children }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div>
        <ComponentButton
          value={"Export as Pdf"}
          variant={"outlined"}
          class={"buttonBlank Mright"}
          callback={handlePrint}
        />
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <div className="center-flex">
              <img
                src={TemplateLogo}
                class="center-flex"
                width={350}
                height={150}
                alt="log"
              />
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTemplate;
