import React, { useContext } from "react";
import {
  Grid,
  Typography,
  Chip,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import ComponentCKEditer from "../../../componentGroups/componentCKEditer";
import hooks from "../../../../Hooks/hooks";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

export default function ProjectDetails({
  projectState,
  errors,
  setProjectState,
  options,
  setDescription,
  description,
}) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);

  return (
    <Grid className="AddEmpGenInfo" container rowSpacing={2}>
      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.project_name")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <div>
          <ComponentTextString
            class={"custonInput"}
            placeholder="Mohamed Ahmed"
            value={projectState?.projectName}
            callback={(e) => {
              setProjectState({
                ...projectState,
                projectName: e.target.value,
              });
            }}
          />
          {errors?.projectName && <span>{`* ${t(errors?.projectName)}`}</span>}
        </div>
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.related_case")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <div>
          <ComponentSelect
            option={hooks.sendName(options?.caseOption, "option")}
            callback={(e) => {
              setProjectState({
                ...projectState,
                caseId: e.target.value,
              });
            }}
            value={projectState?.caseId}
          />
          {errors?.caseId && <span>{`* ${t(errors?.caseId)}`}</span>}
        </div>
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.created_by")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <div>
          <ComponentSelect
            option={options?.employess}
            value={projectState?.createdById}
            callback={(e) => {
              setProjectState({
                ...projectState,
                createdById: e.target.value,
              });
            }}
          />
          {errors?.createdById && <span>{`* ${t(errors?.createdById)}`}</span>}
        </div>
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.created_on")}
        </Typography>
      </Grid>
      <Grid className="DueDate" item xs={11} md={9}>
        <div>
          <ComponentDatePicker
            value={projectState?.createdAt}
            callback={(e) => {
              setProjectState({
                ...projectState,
                createdAt: e.target.value,
              });
            }}
          />
          {errors?.createdAt && <span>{`* ${t(errors?.createdAt)}`}</span>}
        </div>
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.assigned_to")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <Stack
          spacing={3}
          sx={{ width: "auto" }}
          className="authoritiesGroup multiselect "
        >
          <Autocomplete
            multiple
            id="tags-standard"
            options={
              options?.employess && options?.employess ? options?.employess : []
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={projectState?.assign_to_users}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {
              if (getUserHasAccess("project", 127)) {
                setProjectState({ ...projectState, assign_to_users: value });
              } else {
                setAuthorityModal(true);
              }
            }}
            renderTags={(value, getTagProps) =>
              projectState?.assign_to_users?.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ paddingLeft: "2px" }}
                variant="standard"
                // label="Multiple values"
                placeholder={t("placeholder.type_atleast_1_letter")}
              />
            )}
          />
        </Stack>

        {errors?.assign_to_users && (
          <span>{`* ${t(errors?.assign_to_users)}`}</span>
        )}
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.shared_with")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <div>
          {/* <ComponentSelect
            option={options?.employess}
            value={projectState?.sharedWith}
            callback={(e) => {
              setProjectState({
                ...projectState,
                sharedWith: e.target.value,
              });
            }}
          /> */}
          <Stack
            spacing={3}
            sx={{ width: "auto" }}
            className="authoritiesGroup multiselect "
          >
            <Autocomplete
              multiple
              id="tags-standard"
              options={
                options?.employess && options?.employess
                  ? options?.employess
                  : []
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={projectState?.sharedWith}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) =>
                setProjectState({ ...projectState, sharedWith: value })
              }
              renderTags={(value, getTagProps) =>
                projectState?.sharedWith?.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ paddingLeft: "2px" }}
                  variant="standard"
                  // label="Multiple values"
                  placeholder={t("placeholder.type_atleast_1_letter")}
                />
              )}
            />
          </Stack>

          {errors?.sharedWith && <span>{`* ${t(errors?.sharedWith)}`}</span>}
        </div>
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.description")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <div>
          <ComponentCKEditer
            value={description}
            callback={(data) => {
              setDescription(data);
            }}
          />
          {errors?.description && <span>{`* ${t(errors?.description)}`}</span>}
        </div>
      </Grid>
    </Grid>
  );
}
