import * as React from "react";
import DocAccordion from "./docAccordian";
import Comments from "../../../../componentGroups/Comments";

export default function Document({
  clientId,
  dropDownOptions,
  loadDropdownOptions,
  fileAddedToFolder,
  setLoader,
  clientState
}) {
  return (
    <div>
      {dropDownOptions.docRows.map((data, index) => (
        <DocAccordion
          data={data}
          dropDownOptions={dropDownOptions}
          loadDropdownOptions={loadDropdownOptions}
          clientId={clientId}
          fileAddedToFolder={fileAddedToFolder}
        />
      ))}
      <Comments setLoader={setLoader} viewId={`DOC-${clientState?.contractNumber}`} />
    </div>
  );
}
