import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import FileView from "./FileView";
import DocumentSubFolder from "./DocumentSubFolder";

const DocumnetFiles = ({ ...props }) => {
  const { id } = props?.match?.params;
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [data, setdata] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFiletype, setSelectedFiletype] = useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getFolderFiles = async (filterType = "") => {
    setSelectedFiletype(filterType);
    if (id) {
      let endPoint = `/api/meeting/folder/${id}/document`;
      if (filterType) {
        endPoint = `${endPoint}?type=${filterType}`;
      }
      setLoader(true);
      const res = await http.getApi(t, endPoint);
      setLoader(false);
      if (!!res) {
        setdata(res);
      }
    }
  };

  useEffect(() => {
    getFolderFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="mainContainer  MeetingFolderPg">
      <div>
        <div className="FilterReassign">
          <Typography className="morningText" variant="h4">
            {t("titles.shared_documents")}{" "}
          </Typography>
          <ComponentButton
            icon={<FilterListIcon />}
            value={t("labels.filter")}
            variant={"outlined"}
            class={"buttonBlank"}
            callback={(e) => {
              handleClick(e);
            }}
          />
        </div>
        <Menu
          className="Action0con"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              getFolderFiles("pdf");
            }}
            style={{
              background: selectedFiletype === "pdf" ? "#2b564c3b" : "none",
            }}
          >
            {t("titles.pdf_files")}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              getFolderFiles("xls");
            }}
            style={{
              background: selectedFiletype === "xls" ? "#2b564c3b" : "none",
            }}
          >
            {t("titles.exel_files")}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              getFolderFiles("doc");
            }}
            style={{
              background: selectedFiletype === "doc" ? "#2b564c3b" : "none",
            }}
          >
            {t("titles.word_document")}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              getFolderFiles();
            }}
            style={{
              background: selectedFiletype === "" ? "#2b564c3b" : "none",
            }}
          >
            {t("titles.all")}
          </MenuItem>
        </Menu>
      </div>
      <div>
        {data?.length != 0 &&
          data.map((item, index) =>
            item?.shareType === 1 ? (
              <DocumentSubFolder index={index} item={item} />
            ) : (
              <FileView index={index} item={item} />
            )
          )}

        {data?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">{t("labels.no_docs_avilable")}</div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default DocumnetFiles;
