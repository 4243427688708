/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import ComponentSwitch from "../../../componentGroups/componentSwitch";
import hooks from "../../../../Hooks/hooks";
import http from "../../../../api/axios";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import "../style.css";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

export default function GeneralInfo({
  errors,
  empState,
  setEmpState,
  dropDownOptions,
  addDropdownOptions,
  isCordinator,
}) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [dep, setDep] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  const getDepData = async () => {
    if (isCordinator) {
      let department = await http.getApi(t, `/api/department/branch-basis/${empState?.branchId?empState?.branchId:undefined}`);
      setDep(department);
    } else {
      let department = await http.getApi(t, `/api/department/branch-basis/${empState?.branchId?empState?.branchId:undefined}`);
      setDep(department);
    }
    // let res = await http.getApi(t, `/api/department/dropdown/list`);
    // const options = res?.map((op) => {
    //   return { ...op, name: op.name ?? op.option };
    // });
    // if (!isCordinator) {
    //   setDep(options);
    // }
  };
  useEffect(() => {
    getDepData();
  }, []);

  useEffect(() => {
    getDepData();
    setEmpState({ ...empState, departmentCode: "" })
  }, [empState?.branchId]);

  useEffect(() => {
    if (isCordinator) {
      setDep(dropDownOptions?.allDepartments);
    }
  }, [isCordinator]);

  return (
    <>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.name")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentTextString
              class={"custonInput"}
              value={empState?.name}
              name="name"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />
            {errors?.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.name)}`}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("titles.employee_id")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            disable="true"
            value={empState?.code}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.password")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentTextString
              class={"custonInput"}
              placeholder="******"
              name="password"
              value={empState.password}
              callback={(evt) => {
                if (getUserHasAccess("employee", 75)) {
                  setEmpState({
                    ...empState,
                    [evt.target.name]: evt.target.value,
                  });
                } else {
                  setAuthorityModal(true);
                }
              }}
            />
            {errors?.password && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.password)}`}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("titles.department_name")}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              disable={isCordinator}
              value={empState?.departmentId}
              option={hooks.sendName(dep, "departmentName")}
              name="departmentId"
              callback={(evt) => {
                if (getUserHasAccess("employee", 81)) {
                  setEmpState({
                    ...empState,
                    [evt.target.name]: evt.target.value,
                  });
                } else {
                  setAuthorityModal(true);
                }
              }}
            />
            {errors?.departmentId && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.departmentId)}`}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("titles.department_code")}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            placeholder=""
            disable="true"
            value={empState?.departmentCode}
          />
        </Grid>

        {isCordinator && (
          <>
            <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
              <Typography variant="body1" className="customLabel">
                {t("labels.assign_to")} <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid className="CustomWidth" item xs={11} md={9}>
              <div>
                <ComponentSelect
                  className="GenDropdown"
                  value={empState?.assignTo}
                  option={dropDownOptions?.employees}
                  name="assignTo"
                  callback={(evt) => {
                    setEmpState({
                      ...empState,
                      [evt.target.name]: evt.target.value,
                    });
                  }}
                />
                {errors?.assignTo && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {`* ${t(errors?.assignTo)}`}
                  </div>
                )}
              </div>
            </Grid>{" "}
          </>
        )}

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.branch")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              value={empState?.branchId}
              option={hooks.sendName(dropDownOptions.allBranches, "branchName")}
              name="branchId"
              callback={(evt) => {
                if (getUserHasAccess("employee", 82)) {
                  const selectedBranch = dropDownOptions.allBranches.find(
                    (item) => parseInt(item.id) === parseInt(evt.target.value)
                  );
                  setEmpState({
                    ...empState,
                    [evt.target.name]: evt.target.value,
                    branchCode: selectedBranch?.branchCode,
                  });
                } else {
                  setAuthorityModal(true);
                }
              }}
            />
            {errors?.branchId && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.branchId)}`}
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.branch_code")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            placeholder=""
            disable="true"
            value={empState?.branchCode}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.seniority_level")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="seniorityLevel"
            value={empState?.seniorityLevel}
            option={dropDownOptions.seniorityLevel}
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) =>
              addDropdownOptions(val, "seniorityLevel", 5)
            }
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.email")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentTextString
              class={"custonInput"}
              value={empState?.email}
              name="email"
              disable="true"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />
            {errors?.email && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.email)}`}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.type")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="type"
            value={empState?.type}
            option={dropDownOptions?.type}
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) => addDropdownOptions(val, "type", 6)}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.job_position")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="jobId"
            value={empState?.jobId}
            option={dropDownOptions?.jobTitle}
            disable={false}
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
          {errors?.jobId && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.jobId)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.start_work_date")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentDatePicker
            class={"custonInput"}
            placeholder=""
            value={empState?.startWorkDate}
            name="startWorkDate"
            callback={(evt) => {
              let endWorkDate = empState?.endWorkDate;
              if (
                endWorkDate !== "" &&
                new Date(evt.target.value) > new Date(endWorkDate)
              ) {
                endWorkDate = "";
              }
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
                endWorkDate: endWorkDate,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.end_work_date")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentDatePicker
            class={"custonInput"}
            placeholder=""
            value={empState?.endWorkDate}
            min={empState?.startWorkDate}
            name="endWorkDate"
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.status")}
          </Typography>
        </Grid>

        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSwitch
            className="GenDropdown"
            name="status"
            value={empState?.status}
            leftLabel={t("labels.inActive")}
            rightLabel={t("labels.active")}
            handleChange={(evt) => {
              if (getUserHasAccess("employee", 77)) {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.checked,
                });
              } else {
                setAuthorityModal(true);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.direct_manager")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              value={empState?.directManagerId}
              option={dropDownOptions.employees}
              name="directManagerId"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />
            {errors?.directManagerId && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.directManagerId)}`}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.direct_coach")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              value={empState?.directCoachId}
              option={dropDownOptions.selectedDepartmentEmployee}
              name="directCoachId"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.responsible_hr")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              value={empState?.responsibleHrId}
              option={hooks.sendName(
                dropDownOptions.employess_of_department,
                "name"
              )}
              name="responsibleHrId"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />
            {errors?.responsibleHrId && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.responsibleHrId)}`}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
