import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "../style.css";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Country, State, City } from "country-state-city";

export function AddressSection({ address, empState, setEmpState, index }) {
  const ITEM_HEIGHT = 48;
  const { t } = useTranslation();
  const [enableEdit, setEnableEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * removes selected index address from addresses
   * @param {Int} selectedIndex
   */
  const handleDelete = (selectedIndex) => {
    const address = [...empState.addresses];
    if (address.length > 1) {
      address.splice(selectedIndex, 1);
      // if deleted address is also a primary address it will set the first address as primary by default
      if (!!empState.addresses[selectedIndex]?.isPrimary) {
        address[0].isPrimary = true;
      }
      setEmpState({
        ...empState,
        addresses: address,
      });
      handleClose();
    }
  };

  /**
   * sets primary adress
   * @param {Int} selectedIndex
   */
  const handlePrimaryAddress = (selectedIndex) => {
    const address = [...empState.addresses];
    address.forEach((__element, index) => {
      if (index != selectedIndex) {
        address[index]["isPrimary"] = false;
      }
    });
    address[selectedIndex]["isPrimary"] = true;
    setEmpState({
      ...empState,
      addresses: address,
    });
    handleClose();
  };

  const [locationDropdowns, setLocationDropdowns] = useState({
    country: [],
    state: [],
    city: [],
  });

  useEffect(() => {
    const countries = Country.getAllCountries()?.map((item) => {
      return { ...item, id: item?.isoCode };
    });
    setLocationDropdowns((prevVals) => ({
      ...prevVals,
      country: countries,
    }));
  }, []);

  useEffect(() => {
    /**
     * fetch states when country is updated
     */
    const states = State.getStatesOfCountry(address.country)?.map((item) => {
      return { ...item, id: item?.isoCode };
    });
    setLocationDropdowns((prevVals) => ({
      ...prevVals,
      state: states,
    }));
  }, [address.country]);

  useEffect(() => {
    /**
     * fetch city when state is updated
     */
    const cities = City.getCitiesOfState(address.country, address.state)?.map(
      (item, index) => {
        return {
          ...item,
          id: `${index}-${item?.stateCode}-${item?.countryCode}`,
        };
      }
    );
    setLocationDropdowns((prevVals) => ({
      ...prevVals,
      city: cities,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.state]);

  return (
    <div className="formAddress">
      <div className="AddressBtn">
        <div className="addressNameContainer">
          {!!enableEdit ? (
            <ComponentTextString
              class={"custonInput"}
              style={{ maxWidth: "270px" }}
              name="addressName"
              value={address.addressName}
              callback={(e) => {
                const { name, value } = e.target;
                const address = [...empState.addresses];
                address[index][name] = value;
                setEmpState({ ...empState, addresses: address });
              }}
              onKeyDown={(evt) => {
                if ((evt.charCode || evt.keyCode) === 13) {
                  setEnableEdit(false);
                }
              }}
              autoFocus={true}
            />
          ) : (
            address.addressName
          )}

          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            style={{ marginLeft: "10px" }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
        <div>
          <Menu
            className="addressMenu"
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <div
              className="TableMenu1 Profileclick1"
              onMouseLeave={() => handleMenuClose()}
            >
              <ul className="menuTableList1">
                <MenuItem>
                  <div
                    onClick={(e) => {
                      handleMenuClose();
                      setEnableEdit(true);
                    }}
                  >
                    <span className="">{t("labels.edit_name")}</span>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div onClick={() => handlePrimaryAddress(index)}>
                    <span className="">{t("labels.set_as_main")}</span>
                  </div>
                </MenuItem>
                {empState?.addresses?.length > 1 && (
                  <MenuItem>
                    <div onClick={(e) => handleDelete(index)}>
                      <span className="">{t("labels.delete_address")}</span>
                    </div>
                  </MenuItem>
                )}
              </ul>
            </div>
          </Menu>
        </div>
      </div>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.address_1")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            placeholder="---"
            name="address1"
            value={address.address1}
            callback={(e) => {
              const { name, value } = e.target;
              const address = [...empState.addresses];
              address[index][name] = value;
              setEmpState({ ...empState, addresses: address });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.address_2")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            placeholder="---"
            name="address2"
            value={address.address2}
            callback={(e) => {
              const { name, value } = e.target;
              const address = [...empState.addresses];
              address[index][name] = value;
              setEmpState({ ...empState, addresses: address });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.country")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            option={locationDropdowns?.country}
            name="country"
            value={address.country}
            callback={(e) => {
              const { name, value } = e.target;
              const address = [...empState.addresses];
              address[index][name] = value;
              address[index]["state"] = "";
              address[index]["city"] = "";
              setEmpState({ ...empState, addresses: address });
              setLocationDropdowns({
                ...locationDropdowns,
                city: [],
                state: [],
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.state")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            option={locationDropdowns.state}
            name="state"
            value={address.state}
            callback={(e) => {
              const { name, value } = e.target;
              const address = [...empState.addresses];
              address[index][name] = value;
              address[index]["city"] = "";
              setEmpState({ ...empState, addresses: address });
              setLocationDropdowns({ ...locationDropdowns, city: [] });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.city")}
          </Typography>
        </Grid>

        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            option={locationDropdowns.city}
            name="city"
            value={address.city}
            callback={(e) => {
              const { name, value } = e.target;
              const address = [...empState.addresses];
              address[index][name] = value;
              setEmpState({ ...empState, addresses: address });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body1" className="customLabel">
            {t("labels.zip")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            placeholder="---"
            name="zip"
            value={address.zip}
            callback={(e) => {
              const { name, value } = e.target;
              const address = [...empState.addresses];
              address[index][name] = value;
              setEmpState({ ...empState, addresses: address });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
