import React from "react";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { FormLabel } from "@mui/material";

const ComponentSwitch = ({
  name,
  value,
  handleChange,
  leftLabel,
  rightLabel,
  disabled = false,
}) => {
  return (
    <div>
      <div className="ToggleSet">
        <span className="ClickOn">{leftLabel}</span>
        <Switch
          disabled={disabled}
          name={name}
          checked={value}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <span className="ClickOff">{rightLabel}</span>
      </div>
    </div>
  );
};

export default ComponentSwitch;
