/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Chip,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import "../style.css";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import ComponentSelect from "../../../componentGroups/componentSelect";
import ComponentUpload from "../../../componentGroups/componentUpload";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";
import ComponentButton from "../../../componentGroups/componentButton";
import http from "../../../../api/axios";
import DescriptionIcon from "@mui/icons-material/Description";
import { formatBytes } from "../../../comman/pxToRem";
//////////////////////////
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import hooks from "../../../../Hooks/hooks";
import validate from "../../../../Hooks/meeting_validaction";
import moment from "moment";
import Svg from "../../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import MeetingFormDoc from "./meetingDocModel";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import { MEET_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/meet_key_lable";
import useFormatTime from "../../../../Hooks/useFormatTime";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import { loadOptions } from "../../../../api/utils/common";

const INITIAL_VALUE = {
  documents: [],
  start_time: moment(new Date()).format("HH:mm:ss"),
  end_time: moment(new Date()).add(10, "minutes").format("HH:mm:ss"),
  reminder: moment(new Date()).subtract(5, "minutes").format("HH:mm:ss"),
  date: moment(new Date()).format("YYYY-MM-DD"),
  location: "",
  form_documents: [],
  url: "",
  branch_id: "",
  notification: "",
  guests: [],
  departments: [],
  description: [],
  title: "",
};

export default function AccordionForm(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { globalToLocal, customDateTimeToGlobal } = useFormatTime();
  const Id = props?.match?.params?.id;
  const [emp, setEmp] = useState([]);
  const [formDocModel, setFormDocModel] = useState(false);
  const [options, setOption] = useState({
    location: [
      { id: "1", name: "titles.online_meeting" },
      { id: "2", name: "titles.offline_meeting" },
    ],
    notification: [
      { id: "1", name: "titles.in_system" },
      // { id: "2", name: "titles.via_Email" },
    ],
  });
  const [meetingState, setMeetingState] = useState({
    ...INITIAL_VALUE,
    documents: [],
    form_documents: [],
  });
  const [errors, setErrors] = useState({});
  const [guestErrors, setGuestErrors] = useState([]);
  const [flag, setFlag] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "location") {
      if (value === "1") {
        setMeetingState({ ...meetingState, branch_id: "", [name]: value });
      } else {
        setMeetingState({ ...meetingState, url: "", [name]: value });
      }
    } else {
      setMeetingState({ ...meetingState, [name]: value });
    }
  };

  const handleChangeFile = (e) => {
    let s1 = { ...meetingState };
    s1.documents.push(e.target.files[0]);
    setMeetingState(s1);
  };

  useEffect(() => {
    setMeetingState({
      ...INITIAL_VALUE,
      documents: [],
      form_documents: [],
      start_time: moment(new Date()).format("HH:mm:ss"),
      end_time: moment(new Date()).add(10, "minutes").format("HH:mm:ss"),
      reminder: moment(new Date()).subtract(5, "minutes").format("HH:mm:ss"),
      date: moment(new Date()).format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors)?.length != 0) {
      let error = validate.createMeeting(meetingState, moment);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingState]);

  const getApiData = async () => {
    props.setLoader(true);
    let allDepartments = await loadOptions(
      t,
      "",
      null,
      "get",
      "/api/department"
    );
    let allBranches = await loadOptions(
      t,
      "",
      null,
      "post",
      "/api/branch/details"
    );
    setOption({
      ...options,
      branches: allBranches,
      departments: allDepartments,
    });
    if (Id) {
      let res = await http.getApi(t, `/api/meeting/view/${Id}`);
      const meetingData = {
        ...res,
        start_time: globalToLocal(`${res?.start_time}`, "HH:mm:ss"),
        end_time: globalToLocal(`${res?.end_time}`, "HH:mm:ss"),
        reminder: globalToLocal(`${res?.reminder}`, "HH:mm:ss"),
      };
      setMeetingState(meetingData);
    } else {
      const res = "";
      setMeetingState({
        ...meetingState,
        meet_code: res,
        start_time: moment(new Date()).format("HH:mm:ss"),
        end_time: moment(new Date()).add(10, "minutes").format("HH:mm:ss"),
        reminder: moment(new Date()).subtract(5, "minutes").format("HH:mm:ss"),
        date: moment(new Date()).format("YYYY-MM-DD"),
      });
    }
    props.setLoader(false);
  };

  const getEmpAccDate = async (data) => {
    if (
      data?.start_time !== "" &&
      data?.end_time !== "" &&
      data?.date !== "" &&
      data?.start_time !== "Invalid date" &&
      data?.end_time !== "Invalid date" &&
      data?.date !== "Invalid date"
    ) {
      const depIDs = meetingState?.departments?.map((item) => item?.id);
      const res = await http.getAllDataApi(
        t,
        `/api/meeting/guest/list?date=${data?.date}&start_time=${data?.start_time}&end_time=${data?.end_time}`,
        {
          departmentId: depIDs,
        }
      );
      const updatedGuests = meetingState?.guests?.filter((guest) =>
        res?.data?.map((item) => item?.id)?.includes(guest?.id)
      );
      setMeetingState({
        ...meetingState,
        guests: updatedGuests,
      });
      setEmp(res?.data);
    }
  };
  useEffect(() => {
    getApiData();
  }, []);

  useEffect(() => {
    if (meetingState?.departments?.length > 0) {
      getEmpAccDate(meetingState);
    }
  }, [meetingState?.departments]);

  useEffect(() => {
    if (meetingState?.guests != undefined) {
      let error = validate.guestValdidate(meetingState?.guests);
      setGuestErrors(error);
    }
  }, [meetingState?.guests]);

  const addMeeting = async (obj) => {
    setFlag(false);
    let error = validate.createMeeting(meetingState, moment);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      let formdata = new FormData();
      formdata.append("title", meetingState?.title);
      formdata.append("date", meetingState?.date);
      formdata.append(
        "start_time",
        customDateTimeToGlobal(
          `${meetingState?.date} ${meetingState?.start_time}`
        )
      );
      formdata.append(
        "end_time",
        customDateTimeToGlobal(
          `${meetingState?.date} ${meetingState?.end_time}`
        )
      );
      formdata.append("location", meetingState?.location);
      if (meetingState?.location == "1") {
        formdata.append("url", meetingState?.url);
      } else {
        formdata.append("branch_id", meetingState?.branch_id);
      }
      formdata.append("notification", meetingState?.notification);
      formdata.append(
        "reminder",
        customDateTimeToGlobal(
          `${meetingState?.date} ${meetingState?.reminder}`
        )
      );
      meetingState?.guests.map((op, index) => {
        formdata.append(`guests[${index}]`, op.id);
      });
      meetingState?.departments.map((op, index) => {
        formdata.append(`departments[${index}]`, op.id);
      });
      formdata.append("description", meetingState?.description);
      meetingState?.documents.map((op, index) => {
        if (!op?.id) {
          formdata.append(`documents[${index}]`, op);
        }
      });
      meetingState?.form_documents.map((op, index) => {
        if (op?.id) {
          formdata.append(`form_documents[${index}][id]`, op?.id);
          formdata.append(`form_documents[${index}][type]`, op?.type);
        }
      });
      if (Id) {
        let res = await http.putApi(t, `/api/meeting/edit/${Id}`, formdata);
        if (!!res) {
          history.push("/createMeeting");
        }
      } else {
        let res = await http.postApi(t, `/api/meeting/create`, formdata);
        res && setFlag(true);
        if (!!res) {
          setMeetingState({
            ...INITIAL_VALUE,
            documents: [],
            form_documents: [],
            start_time: moment(new Date()).format("HH:mm:ss"),
            end_time: moment(new Date()).add(10, "minutes").format("HH:mm:ss"),
            reminder: moment(new Date())
              .subtract(5, "minutes")
              .format("HH:mm:ss"),
            date: moment(new Date()).format("YYYY-MM-DD"),
          }); // to reset form data
        }
      }
      props.setLoader(false);
    } else {
      setErrors(error);
    }
  };
  const OnTimeChange = (time, name) => {
    if (!!time?.$d && time?.$d !== "INVALID DATE") {
      setMeetingState({
        ...meetingState,
        [name]: moment(time?.$d).format("HH:mm:ss"),
      });
    } else {
      setMeetingState({
        ...meetingState,
        [name]: "",
      });
    }
  };

  const removeFiles = async (index) => {
    let s1 = { ...meetingState };
    if (
      Id &&
      meetingState?.documents.length !== 0 &&
      meetingState?.documents[index]?.id
    ) {
      await http.deleteApi(
        t,
        `/api/meeting/document/delete/${meetingState?.documents[index]?.id}`
      );
      s1.documents.splice(index, 1);
      setMeetingState(s1);
    } else {
      s1.documents.splice(index, 1);
      setMeetingState(s1);
    }
  };
  const removeDoc = async (index) => {
    let s1 = { ...meetingState };
    s1.form_documents.splice(index, 1);
    setMeetingState(s1);
  };
  return (
    <>
      <MeetingFormDoc
        model={formDocModel}
        setFormDocModel={setFormDocModel}
        meetingState={meetingState}
        setMeetingState={setMeetingState}
      />
      <div className="addMeetingPg" style={{ background: "white" }}>
        <form>
          <div className="FormsuggestForm">
            <label for="SuggestionTitle">{t("labels.meeting_title")}</label>
            <div className="inputBox">
              <ComponentTextString
                class={"custonInput"}
                placeholder={t("placeholder.enter_title")}
                name="title"
                value={meetingState?.title}
                callback={handleChange}
              />
              {errors && errors?.title && (
                <span className="validation-messages">{`* ${t(
                  errors?.title
                )}`}</span>
              )}
            </div>
          </div>

          <div className="FormsuggestForm">
            <label for="ReferenceType">{t("labels.date")}</label>
            <div>
              <ComponentDatePicker
                class={"custonInput"}
                name="date"
                value={meetingState?.date}
                callback={handleChange}
              />
              {errors && errors?.date && (
                <span className="validation-messages">{`* ${t(
                  errors?.date
                )}`}</span>
              )}
            </div>
          </div>
          <div className="FormsuggestForm TimeZone ">
            <label for="Suggestion Title"> {t("labels.time")}</label>
            <div class="timeBox">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="meetingtimepicker"
                  value={moment(meetingState?.start_time, "HH:mm:ss")}
                  onChange={(time) => OnTimeChange(time, "start_time")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {errors && errors?.start_time && (
                <span className="validation-messages">{`* ${t(
                  errors?.start_time
                )}`}</span>
              )}
            </div>
            <label className="Titlezone" for="Suggestion">
              {" "}
              {t("labels.to")}{" "}
            </label>
            <div class="timeBox1">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="meetingtimepicker"
                  value={moment(meetingState?.end_time, "HH:mm:ss")}
                  onChange={(time) => OnTimeChange(time, "end_time")}
                  renderInput={(params) => <TextField {...params} />}
                  minTime={moment(meetingState?.start_time, "HH:mm:ss")}
                />
              </LocalizationProvider>
              {errors && errors?.end_time && (
                <span className="validation-messages">{`* ${t(
                  errors?.end_time
                )}`}</span>
              )}
            </div>
          </div>

          <div className="FormsuggestForm">
            <label for="RequestedBy">{t("labels.location")}</label>
            <div className="inputBox">
              {Id ? (
                options?.location?.find((op) => op.id == meetingState?.location)
                  ?.id && (
                  <ComponentSelect
                    option={options.location}
                    name="location"
                    value={
                      options.location.find(
                        (op) => op.id == meetingState?.location
                      )?.id
                    }
                    callback={handleChange}
                  />
                )
              ) : (
                <ComponentSelect
                  option={options.location}
                  name="location"
                  value={
                    options.location.find(
                      (op) => op.id == meetingState?.location
                    )?.id
                  }
                  callback={handleChange}
                />
              )}
              {errors && errors?.location && (
                <span className="validation-messages">{`* ${t(
                  errors?.location
                )}`}</span>
              )}
            </div>
          </div>
          {meetingState?.location == "1" ? (
            <div className="FormsuggestForm">
              <label for="Suggestion Title">{t("labels.meeting_url")}</label>
              <div className="inputBox">
                <ComponentTextString
                  class={"custonInput"}
                  placeholder={t("placeholder.enter_the_meet_link")}
                  name="url"
                  value={meetingState?.url}
                  callback={handleChange}
                />
                {errors && errors?.url && (
                  <span className="validation-messages">{`* ${t(
                    errors?.url
                  )}`}</span>
                )}
              </div>
            </div>
          ) : meetingState?.location == "2" ? (
            <>
              <div className="FormsuggestForm">
                <label for="Suggestion Title">{t("labels.branch_name")}</label>
                <div className="branchBox">
                  <div style={{ minWidth: "150px" }}>
                    <ComponentSelect
                      option={
                        options.branches &&
                        hooks.sendName(options.branches, "branchName")
                      }
                      value={meetingState?.branch_id}
                      name="branch_id"
                      callback={handleChange}
                    />
                  </div>
                  <ComponentTextString
                    class={"custonInput"}
                    value={
                      meetingState?.branch_id &&
                      options?.branches?.length &&
                      hooks.sendId(
                        options?.branches,
                        "branchCode",
                        meetingState?.branch_id,
                        "id"
                      )
                    }
                    disable="true"
                  />
                </div>
                <br />
                {errors && errors?.branch_id && (
                  <span className="validation-messages">
                    {`* ${t(errors?.branch_id)}`}
                  </span>
                )}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="FormsuggestForm notifiyinput">
            <label for="Suggestion Title">{t("labels.notification")}</label>
            <div class="notiftBox">
              {Id ? (
                meetingState?.notification && (
                  <ComponentSelect
                    option={options.notification}
                    value={
                      options.notification.find(
                        (op) => op.id == meetingState?.notification
                      )?.id
                    }
                    name="notification"
                    callback={handleChange}
                  />
                )
              ) : (
                <ComponentSelect
                  option={options.notification}
                  value={
                    options.notification.find(
                      (op) => op.id == meetingState?.notification
                    )?.id
                  }
                  name="notification"
                  callback={handleChange}
                />
              )}
              {errors && errors?.notification && (
                <span className="validation-messages">
                  {`* ${t(errors?.notification)}`}
                </span>
              )}
            </div>
            <div className="noteremider">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  className="meetingtimepicker"
                  value={moment(meetingState?.reminder, "HH:mm:ss")}
                  onChange={(time) => OnTimeChange(time, "reminder")}
                  renderInput={(params) => <TextField {...params} />}
                  maxTime={moment(meetingState?.start_time, "HH:mm:ss")}
                />
              </LocalizationProvider>
              {errors && errors?.reminder && (
                <span className="validation-messages">{`* ${t(
                  errors?.reminder
                )}`}</span>
              )}
            </div>
          </div>
          <div className="FormsuggestForm guesttitle">
            <label for="Suggestion Title">{t("titles.departments")}</label>
            <div className="inputBox ">
              {options &&
                options?.departments &&
                options?.departments?.length != 0 && (
                  <Stack
                    spacing={3}
                    sx={{ width: "auto" }}
                    className="authoritiesGroup multiselect"
                  >
                    <Autocomplete
                      className="AutoComp"
                      multiple
                      id="tags-standard"
                      options={options?.departments && options?.departments}
                      value={meetingState?.departments}
                      getOptionLabel={(option) => option.departmentName}
                      onChange={(e, value) =>
                        setMeetingState({ ...meetingState, departments: value })
                      }
                      renderTags={(value, getTagProps) =>
                        meetingState?.departments?.map((option, index) => (
                          <Chip
                            className="chipstyle "
                            variant="outlined"
                            label={option.departmentName}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          className="textF"
                          {...params}
                          sx={{ paddingLeft: "2px" }}
                          variant="standard"
                          // label="Multiple values"
                          placeholder={t("placeholder.type_atleast_1_letter")}
                        />
                      )}
                    />
                  </Stack>
                )}

              {errors && errors?.departments && (
                <span className="validation-messages">
                  {`* ${t(errors?.departments)}`}
                </span>
              )}
            </div>
          </div>

          <div className="FormsuggestForm guesttitle">
            <label for="Suggestion Title">{t("labels.guests")}</label>
            <div className="inputBox ">
              <Stack
                spacing={3}
                sx={{ width: "auto" }}
                className="authoritiesGroup multiselect"
              >
                <Autocomplete
                  className="AutoComp"
                  disabled={
                    !meetingState?.date &&
                    !meetingState?.start_time &&
                    !meetingState?.end_time
                  }
                  multiple
                  id="tags-standard"
                  options={emp || []}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={meetingState?.guests}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) =>
                    setMeetingState({ ...meetingState, guests: value })
                  }
                  renderTags={(value, getTagProps) =>
                    meetingState?.guests?.map((option, index) => (
                      <Chip
                        className="chipstyle "
                        variant="outlined"
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      className="textF"
                      {...params}
                      sx={{ paddingLeft: "2px" }}
                      variant="standard"
                      // label="Multiple values"
                      placeholder={t("placeholder.type_atleast_1_letter")}
                    />
                  )}
                />
              </Stack>
              {errors && errors?.guests && (
                <span className="validation-messages">{`* ${t(
                  errors?.guests
                )}`}</span>
              )}
              {guestErrors?.length != 0 && (
                <span className="validation-messages">
                  {guestErrors?.map((op) => {
                    return (
                      <>
                        {t("errors.has_meeting", {
                          user: op,
                        })}{" "}
                      </>
                    );
                  })}
                </span>
              )}
            </div>
          </div>

          <div className="FormsuggestForm">
            <label for="MeetDetails">{t("labels.description")}</label>
            <div className="decsrtion">
              <MinHeightTextarea
                class={"custonInput"}
                name="description"
                minRows={4}
                placeholder={t("placeholder.enter_the_description")}
                value={meetingState?.description}
                callback={handleChange}
              />
              {errors && errors?.description && (
                <span className="validation-messages">
                  {`* ${t(errors?.description)}`}
                </span>
              )}
            </div>
          </div>
          <div className="FormsuggestForm">
            <label for="MeetDetails">{t("labels.documents")}</label>
            <div className="Twobtns">
              <div className="Mright">
                <ComponentUpload
                  lable={t("labels.upload_files")}
                  name="documents"
                  callback={handleChangeFile}
                />
              </div>
              <div className="">
                <Button
                  variant="outlined"
                  component="label"
                  className="uploadFile"
                  onClick={() => {
                    if (getUserHasAccess("meeting", 108)) {
                      setFormDocModel(true);
                    } else {
                      setAuthorityModal(true);
                    }
                  }}
                >
                  <Typography sx={{ pr: 1 }}>
                    {" "}
                    <Svg type="upload" />
                  </Typography>{" "}
                  <span className="textLabel">
                    {t("labels.select_document")}
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="FormsuggestForm">
            <label for="MeetDetails"></label>
            <div>
              {meetingState?.form_documents &&
                meetingState.form_documents?.length != 0 &&
                meetingState?.form_documents.map((file, ind) => (
                  <div key={ind} className="flexJustify">
                    <Svg
                      type={
                        file.type == 1
                          ? "folder"
                          : file.type == 2
                          ? "pdf"
                          : "file"
                      }
                    />
                    <small>{file.name}</small>
                    {/* <small>{formatBytes(file.size)}</small>  //enable this when file size is also provided in viewdata*/}
                    <div onClick={() => removeDoc(ind)}>
                      <Svg type="closeIcon" />
                    </div>
                  </div>
                ))}
              {meetingState?.documents &&
                meetingState.documents?.length != 0 &&
                meetingState?.documents.map((file, ind) => (
                  <div key={ind} className="flexJustify">
                    <DescriptionIcon />
                    <small>{file.name}</small>
                    {/* <small>{formatBytes(file.size)}</small>  //enable this when file size is also provided in viewdata*/}
                    <div onClick={() => removeFiles(ind)}>
                      <Svg type="closeIcon" />
                    </div>
                  </div>
                ))}
            </div>
            {/* {errors && errors?.documents && (
              <span className="validation-messages">{`* ${t(
                errors?.documents
              )}`}</span>
            )} */}
          </div>
          <div className="SaveMeet">
            <ComponentButton
              value={t("labels.save_meeting")}
              sx={{ paddingRight: "4px", color: "white" }}
              class="buttonFill"
              variant="contained"
              callback={() => addMeeting(meetingState)}
            />
          </div>
        </form>

        {Id && meetingState?.reference_id && (
          <LogNoteAccordion
            id={meetingState?.reference_id}
            labelKeys={MEET_KEY_LABELS}
            flag={flag}
          />
        )}
      </div>
    </>
  );
}
