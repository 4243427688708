import React, { useContext } from 'react'
import { useState } from 'react'
import { getApi, patchApi } from '../../../../../api/axios'
import { useEffect } from 'react'
import TimeoffCard from './TimeoffCard'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from '../../../../../constant'
import ComponentTable from '../../../../componentGroups/componentTable'
import ComponentModal from '../../../../componentGroups/componectModel'
import Comments from '../../../../componentGroups/Comments'
import LoadingContext from '../../../../../context/LoaderContext'



const Timeoffs = () => {
  const [timeoffs, setTimeoffs] = useState()
  const leaveType = JSON.parse(localStorage.getItem('leaveType'))
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.employee_name",
      name: "emp_name",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.date",
      name: "start_date",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.duration",
      name: "period",
      width: "10px",
      align: "left",
      minWidth: 80,
    },
    {
      lable: "labels.documents",
      name: "doc_name",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.approval",
      name: "status",
      width: "10px",
      align: "left",
      minWidth: 110,
    },

  ]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    title: "labels.all_requests",
    page: "Consultations",
  })

  const getData = async () => {
    const res = await getApi(t, `/api/leave/timeoff/${leaveType?.id}`)
    if (res) setTimeoffs(res);
  }

  useEffect(() => {
    getData()
  }, []);

  // modal 
  const ASSIGN_VALUE = {
    id: "",
    leave_type: "",
    days: "",
    start_date: "",
    end_date: "",
    document: "",
    emp_name: "",
    set_new: false
  };
  const [errors, setErrors] = useState({});


  const [timeoffState, setTimeoffState] = useState(ASSIGN_VALUE)
  const [modalData, setModalData] = useState({
    model: false,
    title: "labels.add_request_timeoff",
    data: [
      {
        lable: "labels.employee_name",
        field: {
          type: "text",
          required: true,
          disable: true,
        },
        name: "emp_name",
      },
      {
        lable: "titles.leave_type",
        field: {
          type: "text",
          required: true,
          disable: true,
        },
        name: "leave_type",
      },
      {
        lable: "labels.days_number",
        field: {
          type: "text",
          disable: true,
          required: true,
        },
        name: "period",
      },
      {
        lable: "labels.start_from",
        field: {
          type: "text",
          required: true,
          disable: true,
        },
        name: "start_date",
      },
      {
        lable: "labels.to",
        field: {
          type: "text",
          disable: true,
          required: true,
        },
        name: "end_date",
      },
      {
        lable: "labels.documents",
        name: "document",
        field: {
          type: "file",
          disable: true,
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setErrors({});
          setTimeoffState(ASSIGN_VALUE);
          handleCloseModel();
        },
      },
      {
        value: "labels.reject",
        variant: "outlined",
        class: "buttonBlank outlineBtn delbtnRefrence",
        callback: (value) => {

          // rejected
          udpateTimeoffStatus(2, value?.id);

        },
      },
      {
        value: "labels.approve",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          // approved
          udpateTimeoffStatus(1, value?.id);
        },
      },
    ],

  })

  const udpateTimeoffStatus = async (val, id) => {

    console.log(id, '+++');
    const res = await patchApi(t, `/api/leave/timeoff/update-status/${id}`, { status: val });
    // after all is ok
    if (!!res) {

      setErrors({});
      setTimeoffState(ASSIGN_VALUE);
      handleCloseModel();
    }
  };
  const handleCloseModel = () => {
    setModalData({ ...modalData, model: false });
  };


  useEffect(() => {
    // set it from local
    if (timeoffState.set_new === true) {
      setModalData({
        ...modalData,
        model: true,
      });
    }

  }, [timeoffState.set_new]);

  return (
    <>
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setTimeoffState}
        perantState={timeoffState}
      />

      <div className='CommonPd dashTopGrid' style={{
        maxHeight: "400px",
        overflowY: "scroll"
      }}>
        <Typography variant='h2' className="morningText" style={{
          textTransform: "capitalize"
        }}>
          {leaveType?.name} {t('labels.requests')}
        </Typography>

        {timeoffs ? timeoffs.map((e, i) => (e.status === 0 ? <TimeoffCard data={e} key={i}
          setTimeoffState={setTimeoffState}
        // modalData={modalData} setModalData={setModalData}
        /> : '')) : ''}

      </div>

      <ComponentTable
        rows={timeoffs}
        columns={column}
        page={page}
        setSearch={setSearch}
        search={search}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        pageData={data}
        totalRecords={totalRecords}
      />
      <Grid className="dashTopGrid" style={{
        marginBlock: "2rem"
      }}>
        <Comments setLoader={setLoader} viewId={`TOFF-${leaveType?.id}`} />
      </Grid>
    </>
  )
}

export default Timeoffs