import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import ComponentTable from "../../componentGroups/componentTable";
import ComponentModal from "../../componentGroups/componectModel";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import http from "../../../api/axios";
import MatterEditView from "./matterEditView";
import validate from "../../../Hooks/client_validation";
import { loadOptions } from "../../../api/utils/common";
import axios from "axios";
import LoadingContext from "../../../context/LoaderContext";
import { Grid, Typography } from "@mui/material";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";

const ArchivedMatters = () => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(rows?.length);
  const [column, setColumn] = useState([
    {
      lable: "labels.matter_name",
      name: "matterName",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.matter_code",
      name: "matterCode",
      width: "10px",
      align: "left",
    },
    { lable: "labels.lawyer", name: "lawyer", width: "10px", align: "left" },
    {
      lable: "labels.department",
      name: "departmentName",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.status",
      name: "status",
      width: "10px",
      align: "left",
    },
  ]);
  const [data, setData] = useState({
    // title: "labels.archived",
    page: "Matters",
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/matter/archived?page=${pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count);
    setRows(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  return (
    <ComponentTable
      rows={rows}
      columns={column}
      pageData={data}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      search={search}
      setSearch={setSearch}
      totalRecords={totalRecords}
    />
  )
}

export default ArchivedMatters