import React, { useContext } from "react";
export default function ComponentTextString(props) {
  return (
    <div className="textStringRoot">
      <input
        type={props.type ? props.type : "text"}
        placeholder={props.placeholder}
        max={props.max}
        min={props.min}
        className={props.class}
        disabled={props.disable}
        name={props.name}
        onChange={props.callback}
        value={props.value}
        onKeyDown={props.onKeyDown}
        autoFocus={props.autoFocus}
      />
      {props?.showLable == true &&
        <span>{props?.lableValue}</span>}
    </div>
  );
}
