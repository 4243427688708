import React, { useState, useContext } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DocumentModal from "./documentModal";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../../utils/common";
import AuthorityContext from "../../../../../context/AuthorityContext";

const DocumentAction = ({
  clientId,
  dropDownOptions,
  loadDropdownOptions,
  setFileAddedToFolder,
}) => {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [modalData, setModalData] = useState({
    operationId: "",
    open: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = (operationId) => {
    setModalData({
      open: true,
      operationId: operationId,
    });
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={(e) => handleClick(e)}
        className="buttonFill Mright1 Mright"
        endIcon={
          <KeyboardArrowDownIcon sx={{ color: "white", paddingLeft: "20px" }} />
        }
      >
        {t("labels.actions")}
      </Button>{" "}
      <Menu
        className="Action0con"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            if (getUserHasAccess("client", 92)) {
              handleModal(1);
            } else {
              setAuthorityModal(true);
            }
          }}
        >
          {t("labels.create_label")}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            if (getUserHasAccess("client", 91)) {
              handleModal(2);
            } else {
              setAuthorityModal(true);
            }
          }}
        >
          {t("labels.create_folder")}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            if (getUserHasAccess("client", 95)) {
              handleModal(3);
            } else {
              setAuthorityModal(true);
            }
          }}
        >
          {t("labels.upload_file")}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            if (getUserHasAccess("client", 96)) {
              handleModal(4);
            } else {
              setAuthorityModal(true);
            }
          }}
        >
          {t("labels.generate_doc")}
        </MenuItem>
      </Menu>
      <DocumentModal
        clientId={clientId}
        open={modalData.open}
        operationId={parseInt(modalData?.operationId)}
        onCLose={() => {
          setModalData({
            open: false,
            operationId: "",
          });
          handleClose();
        }}
        dropDownOptions={dropDownOptions}
        loadDropdownOptions={loadDropdownOptions}
        setFileAddedToFolder={setFileAddedToFolder}
      />
    </div>
  );
};

export default DocumentAction;
