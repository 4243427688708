import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { deleteApi, getAllDataApi, getApi, patchApi, postApi } from '../../../api/axios';
import ComponentDatePicker from '../../componentGroups/componentDatepicker';
import ComponentSelect from '../../componentGroups/componentSelect';
import MinHeightTextarea from '../../componentGroups/componentTextarea';
import ComponentTextString from '../../componentGroups/componentTextString';
import ComponentButton from '../../componentGroups/componentButton';
import FormInput from '../ProfileModule/ProfileTechnicalDepartment/FormInput';
import { LargeText } from '../Technical Department';
import { useContext } from 'react';
import LoadingContext from '../../../context/LoaderContext';
import { useHistory } from "react-router-dom";

const AllocationApporval = () => {
  const { t } = useTranslation();
  const allocation = JSON.parse(localStorage.getItem('allocation'));
  const { setLoader } = useContext(LoadingContext);
  const history = useHistory();

  useEffect(() => {
    return () => {
      localStorage.removeItem("allocation");
    };
  }, []);


  const approve = async () => {
    setLoader(true)
    const allocationRes = await patchApi(t, `/api/allocations/approve/${allocation?.id}`)
    if (!!allocationRes) {
      setLoader(false)
      history.push('/allocation')
    }

  }
  const reject = async () => {
    setLoader(true)
    const allocationRes = await deleteApi(t, `/api/allocations/${allocation?.id}`)
    if (!!allocationRes) {
      setLoader(false)
      history.push('/allocation')
    }
  }

  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="DashTitle" variant="h4">
              {t("labels.allocation_approval")}
            </Typography>
          </div>
          <form style={{ marginLeft: "10px", marginTop: "40px", paddingInline: "30px" }}>

            {/* timeOff type */}
            <FormInput
              value={allocation?.leaveType}
              name="timeOff"
              label={t("labels.timeOff")}
              disabled={true}
            />

            {/* allocation type */}
            <FormInput
              value={allocation?.type}
              name="type"
              label={t("labels.allocation_type")}
              disabled={true}
            />

            {/* apply on */}
            <FormInput
              value={allocation?.applyOn}
              name="applyOn"
              label={t("labels.apply_on")}
              disabled={true}
            />

            {/* duration */}
            <FormInput
              value={allocation?.duration}
              name="duration"
              label={t("labels.duration")}
              disabled={true}
            />

            {/* date_of_start */}
            <FormInput
              value={allocation?.startDate}
              name="startDate"
              label={t("labels.date_of_start")}
              disabled={true}
            />

            {/* date_of_end */}
            <FormInput
              value={allocation?.endDate}
              name="endDate"
              label={t("labels.date_of_end")}
              disabled={true}
            />

            {/* reason */}
            <div className="FormsuggestForm">
              <label for="reason">
                {t("labels.reason")}
              </label>

              <LargeText
                desc={allocation?.reason}
                inputName="reason"
                disabled={true}
              />
            </div>

            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{
                  marginTop: "5px",
                  gap: "10px"
                }}
              >
                <ComponentButton
                  value={t("labels.reject")}
                  variant={"outlined"}
                  class={"buttonBlank delbtnRefrence"}
                  callback={() => {
                    reject();
                  }}
                />
                <ComponentButton
                  value={t("labels.approve")}
                  variant="contained"
                  class="buttonFill FillBtn"
                  callback={() => {
                    approve();
                  }}
                />
              </Grid>
            </div>

          </form>
        </Grid>
      </div >
    </div >
  )
}

export default AllocationApporval