import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Modal, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import http from "../../../../../api/axios";
import "../../../formDocument/style.css";
import { useTranslation } from "react-i18next";
import MeetingInComplete from "./inCompleteDoc";
import ComponentButton from "../../../../componentGroups/componentButton";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="Tasksplpage">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 900,
  bgcolor: "background.paper",
  // borderRadius: "2px solid red",
  // border: "2px solid ",
  boxShadow: "20",
  // p: 4,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MeetingFormDoc = ({
  model,
  setFormDocModel,
  meetingState,
  setMeetingState,
}) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [data, setData] = useState({ incomplete: [], complete: [] });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getApiData = async () => {
    let resIncomplete = await http.getApi(
      t,
      "/api/form_doc/form/incompleteDoc"
    );
    let resComplete = await http.getApi(
      t,
      "/api/form_doc/form/showCompeledDocument"
    );
    setData({ incomplete: resIncomplete, complete: resComplete });
  };
  const handleChangeState = (op) => {
    let s1 = { ...meetingState };
    if (meetingState?.form_documents?.find((p) => op.id == p.id)) {
      s1?.form_documents.splice(
        meetingState?.form_documents?.findIndex((p) => op.id == p.id),
        1
      );
    } else {
      s1.form_documents.push({ id: op.id, type: op.shareType, name: op.name });
    }
    setMeetingState(s1);
  };

  const handleClose = () => {
    setFormDocModel(false);
    setMeetingState({ ...meetingState });
  };

  useEffect(() => {
    getApiData();
  }, []);

  return (
    <Modal
      className="MainModal"
      open={model}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="ModalBG" sx={style}>
        <div className="ModalHeading">
          <div className="CommonPd">
            <div className="dashTopGrid pending_req_box">
              <Box
                className="TaskTab TabActive"
                sx={{
                  borderBottom: 1,
                  borderColor: "rgba(183, 161, 96, 0.26)",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="iconText"
                    label={t("titles.incompleted_documents")}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="iconText"
                    label={t("titles.completed_documents")}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}
                style={{ overflow: "auto", height: "500px" }}
              >
                <MeetingInComplete
                  data={data.incomplete}
                  getApiData={getApiData}
                  handleChangeState={handleChangeState}
                  meetingState={meetingState}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                style={{ overflow: "auto", height: "500px" }}
              >
                <MeetingInComplete
                  data={data.complete}
                  getApiData={getApiData}
                  handleChangeState={handleChangeState}
                  meetingState={meetingState}
                />
              </TabPanel>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="ModalFooter">
            <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
              <ComponentButton
                value={t("labels.discard")}
                class={"buttonBlank"}
                callback={() => handleClose()}
              />
              {/* <ComponentButton
                value={t('labels.add')}
                class={"buttonFill"}
                callback={() => setFormDocModel(false)}
              /> */}
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default MeetingFormDoc;
