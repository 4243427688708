/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ComponentTable from "../../../componentGroups/componentTable";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";
import AddEditCategory from "./AddEditCategory";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";

export default function CategoryListing(props) {
  const { t } = useTranslation();
  const { categoryId } = useParams();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setPathValue } = useContext(BreadCrumbContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [modalData, setModalData] = useState({
    open: false,
    operationId: "",
    subCategoryId: "",
    subCategoryValue: "",
  });

  const [column, setColumn] = useState([
    {
      lable: "labels.name",
      name: "option",
      hide: true,
    },
    {
      lable: "labels.date",
      name: "updatedAt",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.added_by",
      name: "createdBy",
      align: "left",
      hide: true,
    },
  ]);
  const [data, setData] = useState({
    title: "",
    page: "",
    hideSearch: true,
    infoIcon: "edit",
    infoCallback: (row) => {
      if (getUserHasAccess("settings", 177)) {
        setModalData({
          open: true,
          subCategoryId: row?.id,
          operationId: 4,
          subCategoryValue: row.option,
        });
      } else {
        setAuthorityModal(true);
      }
    },
    deleteCallback: (row) => {
      if (getUserHasAccess("settings", 178)) {
        setModalData({
          open: true,
          subCategoryId: row?.id,
          operationId: 5,
          subCategoryValue: row.option,
        });
      } else {
        setAuthorityModal(true);
      }
    },
    buttonGroup: [
      {
        type: "add",
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        class: "buttonFill",
        variant: "contained",
        callback: () => {
          if (getUserHasAccess("other", 157)) {
            setModalData({
              open: true,
              operationId: 3,
            });
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);

    const formData = new FormData();
    formData.append("category_id", categoryId);
    const res = await http.getAllDataApi(
      t,
      `/api/dropdown/sub-category?page=${pageIndex + 1}&size=${pageSize}`,
      formData
    );
    setTotalrecords(res?.data_count);
    setCategoryOptions(res?.data);
    setCategoryName(res?.categoryName);
    setData({ ...data, title: res?.categoryName });
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
    // reset the pathValue when componet unmounts
    return () => {
      setPathValue([
        {
          name: "",
          path: ``,
        },
      ]);
    };
  }, [page, size]);

  const handleClose = () => {
    setModalData({
      open: false,
      operationId: "",
      subCategoryId: "",
      subCategoryValue: "",
    });
    getApiData();
    setPage(INITIAL_PAGEINDEX);
    setSize(INITIAL_PAGESIZE);
  };

  return (
    <>
      <AddEditCategory
        open={modalData.open}
        categoryId={categoryId}
        categoryName={categoryName}
        operationId={modalData.operationId}
        subCategoryId={modalData.subCategoryId}
        subCategoryValue={modalData.subCategoryValue}
        onClose={handleClose}
      />
      <ComponentTable
        rows={categoryOptions}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
      />
    </>
  );
}
