import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../../../componentGroups/componentTextString";
import Svg from "../../../../../../assets/svg/svg";

import http from "../../../../../../api/axios";
const Files = ({ allIndex, column, assigned, setdata, data, removeFields }) => {
  const { t } = useTranslation();
  const handleTableFieldsChange = async (
    e,
    index,
    footerIndex,
    rowIndex,
    tableRowIndex,
    tableColIndex
  ) => {
    const form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    let file = await http.getAllDataApi(
      t,
      `/api/form_doc/fileUpload`,
      form_data
    );
    if (file.data) {
      let s1 = [...data];
      console.log(s1, allIndex, "datatatatat");
      s1[rowIndex].data[index].footer[footerIndex].fields.rows[tableRowIndex][
        tableColIndex
      ].value = file.data;
      setdata(s1);
    }
  };

  const removeFieldFile = async (
    footerIndex,
    index,
    rowIndex,
    tableRowIndex,
    tableColIndex,
    val
  ) => {
    const arr = [...data];
    let path =
      arr[rowIndex].data[index].footer[footerIndex].fields.rows[tableRowIndex][
        tableColIndex
      ].value.path;
    if (path) {
      const form_data = new FormData();
      form_data.append("path", path);
      let file = await http.postApi(
        t,
        `/api/form_doc/form/deleteFile`,
        form_data,
        "labels.file_deleted_successfully"
      );
    }
    arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex][
      tableColIndex
    ].exist = false;
    setdata(arr);
  };

  console.log(assigned, "datatatatat");
  return (
    <div className="CrossBtn">
      {column.value ? (
        <div>
          <a href={`${http.baseURL}/${column.value.path}`} target="_blank">
            <span>{column.value.name}</span>
          </a>
          {/* <div
                        class="closeIcon"
                        onClick={() =>
                            removeFieldFile(
                                allIndex.footerIndex,
                                allIndex.index,
                                allIndex.rootRowIndex,
                                allIndex.tableRowIndex,
                                allIndex.tableColIndex,
                                "table"
                            )
                        }
                    >
                        <Svg type="closeIcon" />
                    </div> */}
        </div>
      ) : (
        <ComponentTextString
          type={column.type}
          value={column?.value}
          callback={(e) => {
            handleTableFieldsChange(
              e,
              allIndex.index,
              allIndex.footerIndex,
              allIndex.rootRowIndex,
              allIndex.tableRowIndex,
              allIndex.tableColIndex
            );
          }}
        />
      )}
      {!assigned && (
        <div
          class="closeIcon"
          onClick={() =>
            removeFieldFile(
              allIndex.footerIndex,
              allIndex.index,
              allIndex.rootRowIndex,
              allIndex.tableRowIndex,
              allIndex.tableColIndex,
              "table"
            )
          }
        >
          <Svg type="closeIcon" />
        </div>
      )}
    </div>
  );
};
export default Files;
