import React, { useEffect, createContext, useState } from "react";
import io from 'socket.io-client';
import http from "../api/axios"
import auth from "../services/auth";
let lawUser = auth.getUser("token");

const initialVal = false;

const LoadingContext = createContext(initialVal);
const LoadingContextProvider = LoadingContext.Provider;

export const LoadingProvider = ({ children }) => {
  const [loader, setLoader] = useState(initialVal);
  const [socketData, setSocketData] = useState([]);

  useEffect(() => {
    const socket = io(http.baseURL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            BearerToken: `${lawUser}`
          }
        }
      }
    });
    socket.on("notification", (data) => {
      setSocketData(msg => [...msg, data])
    });
  }, []);

  return (
    <LoadingContextProvider value={{ loader, setLoader, socketData, setSocketData }}>
      {children}
    </LoadingContextProvider>
  );
};

export default LoadingContext;
