import React, { useState, useEffect } from "react";
// import { Grid, Typography } from "@mui/materials";
import { useTranslation } from "react-i18next";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import AddIcon from "@mui/icons-material/Add";
import http from "../../../../api/axios";
import ComponentModal from "../../../componentGroups/componectModel";
import validate from "../../../../Hooks/employee_validaction";
import FilterListIcon from "@mui/icons-material/FilterList";
import hooks from "../../../../Hooks/hooks";
import moment from "moment";
import AvailableLeaves from "../../../componentGroups/AvailableLeaves";
const LeaveBalance = ({ dropDownOptions, setLoader, empState, empId }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [leaveBalance, setLeaveBalance] = useState({
    sick_leave: 0,
    casual_leave: 0,
    annual_leave: 0,
  });
  const [leaveState, setLeaveState] = useState({
    employee: empState?.name,
    employee_id: empState?.id,
  });
  const [errors, setErrors] = useState({});
  const [filterData, setFilterData] = useState({
    filter: false,
    dateFilter: moment(new Date()).format("YYYY-MM"),
  });
  const column = [
    // { lable: "", name: "checkbox", width: "10px", align: "center" },
    {
      lable: "labels.date",
      name: "date",
      type: "date",
      minWidth: "20",
      align: "center",
    },
    {
      lable: "labels.leave_type",
      name: "type",
      type: "dropDown",
      options: dropDownOptions?.leavePeriod,
      minWidth: "80px",
      align: "right",
    },
    {
      lable: "labels.leave_period",
      name: "period",
      minWidth: "100px",
      align: "center",
    },
    {
      lable: "forms.attachment",
      name: "attachment",
      hide: true,
      // minWidth: "100px",
      align: "center",
    },
    {
      lable: "labels.approval",
      name: "approval",
      type: "dropDown",
      options: dropDownOptions?.approvedStatus,
      // minWidth: "120px",
      align: "center",
    },
  ];
  const [data, setData] = useState({
    title: "labels.leave_balance",
    // infoURL: "/branchInfo",
    hideTitle: true,
    showReplaceTitle: { type: "month", name: "dateFilter" },
    showReplaceSearch: { type: "leave", name: "dateFilter" },
    page: "Branches",
    hideSearch: true,
    // search: search,
    // setSearch: setSearch,
    buttonGroup: [
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        class: "buttonBlank",
        variant: "outlined",
        callback: () => { },
      },
      {
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          setModelData({ ...modalData, model: true });
        },
      },
    ],
  });
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_leave",
    data: [
      {
        lable: "labels.employee",
        field: { type: "text", disable: "true", required: true },
        name: "employee",
      },
      {
        lable: "labels.leave_type",
        field: {
          type: "dropdown",
          option: dropDownOptions?.leavePeriod,
          findLable: "name",
          required: true,
        },
        name: "leave_type",
      },
      {
        lable: "labels.leave_period",
        field: {
          type: "text",
          fieldType: "number",
          showLable: true,
          lableValue: "Days",
          required: true,
        },
        name: "leave_period",
      },
      {
        lable: "labels.leave_date",
        field: { type: "date", required: true },
        name: "leave_date",
      },
      {
        lable: "labels.request_approval_from",
        field: {
          type: "dropdown",
          option: dropDownOptions?.employess_of_department,
          findLable: "name",
          required: true,
        },
        name: "request_approval_from",
      },
      {
        lable: "labels.attachments",
        name: "attachments",
        field: {
          type: "file",
          required: true,
          findLable: "name",
          option: [],
        },
      },
      {
        lable: "labels.description",
        name: "description",
        field: {
          type: "ck",
          findLable: "name",
          required: true,
          option: [],
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value, leaveBalance) => {
          //   addBranch(value);
          addLeave(value, leaveBalance);
        },
      },
    ],
  });

  const handleCloseModel = () => {
    setErrors({});
    setLeaveState({ employee: empState?.name, employee_id: empState?.id });
    setModelData({ ...modalData, model: false });
  };

  const addLeave = async (obj, leaveBalance) => {
    let error = validate.leaveValidate(obj, leaveBalance);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      var formdata = new FormData();
      formdata.append("employee_id", obj?.employee_id);
      formdata.append("type", obj?.leave_type);
      formdata.append("period", obj?.leave_period);
      formdata.append("date", obj?.leave_date);
      formdata.append("approval_by", obj?.request_approval_from);
      formdata.append("description", obj?.description);
      formdata.append("documents[0]", obj?.attachments);
      const res = await http.postApi(t, "/api/leave-request/create", formdata);
      if (res) {
        getApiData();
        handleCloseModel();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.leaveValidate(leaveState, leaveBalance);
      setErrors(error);
    }
  }, [leaveState]);

  const getApiData = async () => {
    setLoader(true);
    console.log(filterData, "filterData");
    var formdata = new FormData();
    formdata.append("filter[type]", filterData?.type ?? "");
    formdata.append("filter[approval]", filterData?.approval ?? "");
    let res = await http.getAllDataApi(
      t,
      `/api/leave-request/list?date=${filterData?.dateFilter}&emp_id=${empId}`,
      formdata
    );
    let leave_balance = await http.getApi(
      t,
      `/api/leave-request/balance/${empId}`
    );
    setRows(res?.data);
    setFilterData({
      ...filterData,
      filter: false,
    });
    setLeaveBalance({
      ...leaveBalance,
      sick_leave: leave_balance?.sick_leave,
      casual_leave: leave_balance?.casual_leave,
      annual_leave: leave_balance?.annual_leave,
    });
    setLeaveState({ ...leaveState, employee: empState?.name });
    setTotalrecords(res?.total_count);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  console.log(dropDownOptions, column, "leaveState");
  return (
    <div>
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setLeaveState}
        perantState={leaveState}
        optionalVal={leaveBalance}
      />
      <AvailableLeaves empId={empId} />
      <ComponentTable
        rows={rows}
        columns={column}
        filterData={filterData}
        setFilterData={setFilterData}
        page={page}
        // setSearch={setSearch}
        // search={search}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        pageData={data}
        totalRecords={totalRecords}
        enableReset={true}
        resetdata={{
          dateFilter: moment(new Date()).format("YYYY-MM"),
        }}
        leaveBalance={leaveBalance}
      // selected={selectedData}
      // handleSelected={handleSelected}
      // handleAllselect={handleAllselect}
      // handleDelete={handleDelete}
      />
    </div>
  );
};
export default LeaveBalance;
