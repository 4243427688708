import React from "react";

const FormInput = (props) => {
  const handleInputChange = props.onChange
    ? props.onChange
    : (event) => props.setValue(event.target.value);

  return (
    <>
      <div
        className={
          props.error ? "FormsuggestForm withError otherinputform" : "FormsuggestForm otherinputform"
        }
        style={{
          alignItems: "baseline",
        }}
      >
        {props.label && <label for={props.name}>{props.label}</label>}
        <div
          style={{
            width: "100%",
          }}
        >
          <input
            placeholder={props.placeholder}
            value={props.value}
            onChange={handleInputChange}
            type={props.type || "text"}
            name={props.name}
            required={props.required ? props.required : false}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            disabled={props.disabled}
          />
          {props.error && (
            <p
              style={{
                color: "#ff0101",
                fontSize: "13px",
              }}
            >
              *{props.error}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default FormInput;
