import React, { useState } from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';

export default function ComponentRadioGroup(props) {


  const handleOptionChange = (event) => {
    props.setSelectedOption(event.target.value);
  };

  return (
    <RadioGroup value={props.selectedOption} onChange={handleOptionChange} row >
      {props.options?.map((op, i) => <FormControlLabel value={op.value} key={i} control={<Radio sx={{
        color: '#2B564C',
        '&.Mui-checked': {
          color: "#2B564C",
        },
      }} />} label={op.label} />)}
    </RadioGroup>
  );
}
