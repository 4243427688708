export const PROMOTION_KEY_LABELS = {
  newSalary: "new_salary",
  startDate: "labels.start_date",
  status: "labels.status",
  promoted_to: "labels.promoted_to",
  employee: "labels.employee",
  promotedFrom: "labels.promoted_from",
  requestedBy: "labels.request_by",
  PromotionApprovals: "labels.request_approval_from",
  updatedBy: "titles.updated_by",
};
