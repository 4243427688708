import React, { useEffect, useState } from "react";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import http from "../../../api/axios";
import { useTranslation } from "react-i18next";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
export default function Calander() {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const getApiData = async () => {
    const res = await http.getApi(t, `/api/meeting/events`);
    setEvents(res);
  };
  useEffect(() => {
    getApiData();
  }, []);

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
}
