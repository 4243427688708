/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ComponentTable from "../../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../../constant";
import http from "../../../../../api/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../../utils/common";
import AuthorityContext from "../../../../../context/AuthorityContext";
import LoadingContext from "../../../../../context/LoaderContext";

export default function EmployeeBonusList({ id }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [bonusData, setBonusData] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");

  const [column, setColumn] = useState([
    // {
    //   lable: "",
    //   name: "checkbox",
    //   width: "10px",
    //   align: "center",
    //   minWidth: 10,
    // },
    {
      lable: "Bonus Type",
      name: "bonus_type",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "Amount",
      name: "amount",
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "Applies On",
      name: "applies_on",
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.description",
      name: "description",
      width: "10px",
      align: "left",
      minWidth: 50,
    },
  ]);
  const [data, setData] = useState({
    title: "",
    page: "Employee Bonuses",
    infoURL: `${location.pathname}/view-employeebonus`,
    hasVariable: true,
    deleteCallback: (row) => {
      if (getUserHasAccess("settings", 192)) {
        handleDelete(row?.id);
      } else {
        setAuthorityModal(true);
      }
    },
    buttonGroup: [
      // {
      //   type: "filter",
      //   title: "labels.filter_by",
      //   icon: <FilterListIcon />,
      //   variant: "outlined",
      //   class: "buttonBlank",
      //   callback: () => {},
      // },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/employee/bonus/${id}?&page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count);
    setFilterData({ ...filterData, filter: false });
    setBonusData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  const handleDelete = async (id) => {
    setLoader(true);
    const api = `/api/employee/bonus/${id}`;
    await http.deleteApi(t, api);
    getApiData();
    setLoader(false);
  };

  return (
    <ComponentTable
      rows={bonusData}
      columns={column}
      pageData={data}
      filterData={filterData}
      setFilterData={setFilterData}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      search={search}
      setSearch={setSearch}
      totalRecords={totalRecords}
      enableReset={true}
      resetdata={{}}
    />
  );
}
