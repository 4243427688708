import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Grid, TextField, Autocomplete, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import ComponentUpload from "../../../componentGroups//componentUpload";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";
import GenericModal from "../../../componentGroups/genericModal";
import Svg from "../../../../assets/svg/svg";
import http from "../../../../api/axios";
import validate from "../../../../Hooks/client_validation";
import axios from "axios";
import moment from "moment";
import "../style.css";
import LoadingContext from "../../../../context/LoaderContext";
import useFormatTime from "../../../../Hooks/useFormatTime";

const INITIAL_VALUES = {
  title: "",
  typeId: "",
  reminderDate: "",
  reminderTime: null,
  repeatId: "",
  notifyMeText: "",
  notifyMeTime: null,
  description: "",
  documents: [],
  employees: [],
  matters: [],
};

const ReminderAddEdit = ({ id, open, mode, onCLose, getApiData, clientId }) => {
  const { setLoader } = useContext(LoadingContext);
  const { t, i18n } = useTranslation();
  const { customDateTimeToGlobal } = useFormatTime();
  const [viewMode, setViewMode] = useState(true);
  const [reminderData, setReminederData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    reminderType: [],
    repeat: [
      { id: 1, name: "labels.Repeat" },
      { id: 2, name: "labels.every_day" },
      { id: 3, name: "labels.every_month" },
      { id: 4, name: "labels.every_week" },
    ],
    employees: [],
    matters: [],
    notification: [
      { id: "1", name: "titles.in_system" },
      // { id: "2", name: "titles.via_Email" },
    ],
  });

  /**
   *
   * @param {*} key  dropdown options key
   * @param {*} id   category id on dropdown field
   */
  const loadDropdownOptions = async (
    key,
    id,
    type = "post",
    endPoint = "/api/dropdown/show-options"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("id", id);
      res = await http.getAllDataApi(t, endPoint, formData);
      res = res?.data;
    }
    if (type === "get") {
      res = await http.getApi(t, `${endPoint}`);
    }
    const options = res?.map((op) => {
      if (!op?.name) {
        return {
          ...op,
          name: key === "matters" ? op?.matterName : op?.option,
        };
      } else {
        return op;
      }
    });
    setDropDownOptions((prevVals) => ({
      ...prevVals,
      [key]: options,
    }));
  };

  /**
   * add custom options in dropdowns
   * @param {*} val
   * @param {*} key
   * @param {*} category_id
   * @param {*} type
   * @param {*} endPoint
   */
  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", i18n.language === "en" ? 1 : 2);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        loadDropdownOptions(key, category_id);
      }
    }
  };

  const handleClose = () => {
    setErrors({});
    setReminederData(INITIAL_VALUES);
    setViewMode(true);
    onCLose();
  };

  // const getMatterData = async () => {
  //   setLoader(true);
  //   await http
  //     .getApi(t,`/api/reminder/${id}`)
  //     .then(async (res) => {
  //       //add if view or edit mode added
  //     })
  //     .finally(() => {
  //       setLoader(false);
  //     });
  // };

  useEffect(() => {
    setLoader(true);
    axios
      .all([
        loadDropdownOptions("reminderType", 15),
        loadDropdownOptions(
          "employees",
          "",
          "get",
          "/api/employee/dropdown/all/attorney"
        ),
        loadDropdownOptions(
          "matters",
          "",
          "get",
          `/api/reminder/all/matters/${clientId}`
        ),
      ])
      .finally(() => setLoader(false));

    // if (id && mode === "view") {
    //   getMatterData();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (mode === "add") {
      setViewMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.reminderValidate(reminderData, moment);
      setErrors(error);
    }
  }, [reminderData]);

  const updateMatter = async () => {
    let error = validate.reminderValidate(reminderData, moment);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      const updatedData = { ...reminderData };
      delete updatedData?.documents;
      delete updatedData?.employees;
      delete updatedData?.matters;
      updatedData.reminderTime = customDateTimeToGlobal(
        `${updatedData?.reminderDate} ${updatedData?.reminderTime}`
      );
      updatedData.notifyMeTime = customDateTimeToGlobal(
        `${updatedData?.reminderDate} ${updatedData?.notifyMeTime}`
      );
      Object.keys(updatedData).forEach((element) => {
        formData.append(element, updatedData[element]);
      });
      const doccuments = [...reminderData.documents];
      doccuments.forEach((item, index) => {
        formData.append(`documents[${index}]`, item);
      });
      const employees = [...reminderData.employees].map((item) => item?.id);
      employees.forEach((item, index) => {
        formData.append(`employees[${index}]`, item);
      });
      const matters = [...reminderData.matters].map((item) => item?.id);
      matters.forEach((item, index) => {
        formData.append(`matters[${index}]`, item);
      });
      formData.append("clientId", clientId);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.postApi(t, "/api/reminder/create", formData);
      setLoader(false);
      if (!!res) {
        getApiData();
        handleClose();
      }
    } else {
      setErrors(error);
    }
  };

  return (
    <GenericModal title={"titles.add_new_reminder"} open={open}>
      <div className="scroll-auto ModalContent" style={{ minHeight: "550px" }}>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.reminder_title")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.title && (
              <span className="validate">{t(errors.title)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentTextString
                placeholder={""}
                disable={viewMode}
                name="title"
                value={reminderData?.title}
                callback={(e) => {
                  setReminederData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
              {errors?.title && <span>{t(errors.title)}</span>}
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.reminder_type")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.typeId && (
              <span className="validate">{t(errors.typeId)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentSelect
                disable={viewMode}
                value={reminderData?.typeId}
                name="typeId"
                option={dropDownOptions?.reminderType}
                callback={(e) =>
                  setReminederData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  })
                }
                addIconClass="allmodalAddbtn"
                enableDynamicOptionInput={true}
                optionInputClass={"custonInput"}
                onOptionSave={(e, val) =>
                  addDropdownOptions(val, "reminderType", 15)
                }
              />
              {errors?.typeId && (
                <span className="validate">{t(errors.typeId)}</span>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          md={12}
          className="modalGroup"
          style={{ paddingRight: "15px" }}
        >
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.reminder_date")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.reminderDate && (
              <span className="validate">{t(errors.reminderDate)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentDatePicker
                class={"custonInput"}
                name="reminderDate"
                value={reminderData?.reminderDate}
                callback={(e) =>
                  setReminederData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {errors?.reminderDate && (
                <span className="validate">{t(errors.reminderDate)}</span>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.reminder_time")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {errors?.reminderTime && (
              <span className="validate">{t(errors.reminderTime)}</span>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  name="reminderTime"
                  className="timePicker"
                  value={
                    reminderData?.reminderTime
                      ? moment(reminderData?.reminderTime, "HH:mm:ss")
                      : null
                  }
                  onChange={(time) => {
                    if (!!time?.$d && time?.$d !== "INVALID DATE") {
                      setReminederData({
                        ...reminderData,
                        reminderTime: moment(time?.$d).format("HH:mm:ss"),
                      });
                    } else {
                      setReminederData({
                        ...reminderData,
                        reminderTime: "",
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {errors?.reminderDate && (
                <span className="validate">{t(errors.reminderDate)}</span>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.Repeat")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.
             && (
              <span className="validate">{t(errors.repeatId)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentSelect
                disable={viewMode}
                value={reminderData?.repeatId}
                name="repeatId"
                option={dropDownOptions?.repeat}
                callback={(e) =>
                  setReminederData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {errors?.repeatId && (
                <span className="validate">{t(errors.repeatId)}</span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.lawyer")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.employees && (
              <span className="validate">{t(errors.employees)}</span>
            )} */}
          </Grid>

          <Grid item xs={12} md={9}>
            <div>
              <Stack
                spacing={3}
                sx={{ width: "auto" }}
                className="authoritiesGroup multiselect"
              >
                <Autocomplete
                  className="AutoComp"
                  multiple
                  disabled={viewMode}
                  options={dropDownOptions?.employees || []}
                  getOptionLabel={(option) => option.name}
                  value={reminderData?.employees}
                  onChange={(e, value) => {
                    setReminederData({
                      ...reminderData,
                      employees: [...value],
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="textF"
                      {...params}
                      variant="standard"
                      placeholder="Type at least 1 letter"
                    />
                  )}
                />
              </Stack>
              {errors?.employees && (
                <span className="validate">{t(errors.employees)}</span>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.notification")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="notificationContainer">
              <div class="notiftBox">
                <ComponentSelect
                  option={dropDownOptions.notification}
                  value={
                    dropDownOptions.notification.find(
                      (op) => op.id == reminderData?.notifyMeText
                    )?.id
                  }
                  name="notifyMeText"
                  callback={(e) =>
                    setReminederData({
                      ...reminderData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                {errors && errors?.notifyMeText && (
                  <span className="validation-messages">
                    {`* ${t(errors?.notifyMeText)}`}
                  </span>
                )}
              </div>
              <div className="noteremider">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    className="timePicker"
                    value={
                      reminderData?.notifyMeTime
                        ? moment(reminderData?.notifyMeTime, "HH:mm:ss")
                        : null
                    }
                    onChange={(time) => {
                      if (!!time?.$d && time?.$d !== "INVALID DATE") {
                        setReminederData({
                          ...reminderData,
                          notifyMeTime: moment(time?.$d).format("HH:mm:ss"),
                        });
                      } else {
                        setReminederData({
                          ...reminderData,
                          notifyMeTime: null,
                        });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {errors && errors?.notifyMeTime && (
                  <span className="validation-messages">{`* ${t(
                    errors?.notifyMeTime
                  )}`}</span>
                )}
              </div>
              <div className="before-meet">{t("labels.before_meeting")}</div>
            </div>
          </Grid>
        </Grid>

        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.reminder_matters")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.matters && (
              <span className="validate">{t(errors.matters)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <Stack
                spacing={3}
                sx={{ width: "auto" }}
                className="authoritiesGroup multiselect"
              >
                <Autocomplete
                  className="AutoComp"
                  multiple
                  disabled={viewMode}
                  options={dropDownOptions?.matters || []}
                  getOptionLabel={(option) => option.name}
                  value={reminderData?.matters}
                  onChange={(e, value) => {
                    setReminederData({
                      ...reminderData,
                      matters: [...value],
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Type at least 1 letter"
                    />
                  )}
                />
              </Stack>
              {errors?.matters && (
                <span className="validate">{t(errors.matters)}</span>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          md={12}
          className="modalGroup"
          style={{ paddingRight: "15px" }}
        >
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.description")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <MinHeightTextarea
                class={"custonInput"}
                name="description"
                minRows={4}
                placeholder={"Enter the description"}
                value={reminderData?.description}
                callback={(e) =>
                  setReminederData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {errors && errors?.description && (
                <span className=" validate validation-messages">
                  {`* ${t(errors?.description)}`}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.related_attachments")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.documents && (
              <span className="validate">{t(errors.documents)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentUpload
                lable={t("labels.upload_contract")}
                disable={viewMode}
                name="documents"
                callback={(e) =>
                  setReminederData({
                    ...reminderData,
                    [e.target.name]: [
                      ...reminderData.documents,
                      e.target.files[0],
                    ],
                  })
                }
              />
              {reminderData?.documents.map((item, index) => {
                return (
                  <div className="fileDelete">
                    <span>{item.name}</span>
                    <span
                      onClick={() => {
                        const docs = reminderData?.documents;
                        docs.splice(index, 1);
                        setReminederData({ ...reminderData, documents: docs });
                      }}
                    >
                      <Svg type="close" style={{ cursor: "pointer" }} />
                    </span>
                  </div>
                );
              })}
              {errors?.documents && (
                <span className="validate">{t(errors.documents)}</span>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        {viewMode && (
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.close")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.edit")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => {
                  setViewMode(false);
                }}
              />
            </Grid>
          </Grid>
        )}
        {!viewMode && (
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.create")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => updateMatter()}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </GenericModal>
  );
};

export default ReminderAddEdit;
