import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";

const DashBoardForgotRequests = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [pendingRequestToshow, setPendingRequestToshow] = useState([]);

  const getLatestDecisions = async () => {
    setLoader(true);
    const res = await http.getApi(t, `/api/user/all-password-requests`);
    setLoader(false);
    if (!!res && res?.length > 0) {
      setPendingRequest(res);
    } else {
      setPendingRequest([]);
    }
  };

  useEffect(() => {
    getLatestDecisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setPendingRequestToshow(pendingRequest);
    } else {
      if (pendingRequest?.length > 5) {
        let sliced = [...pendingRequest]?.slice(0, 5);
        setPendingRequestToshow(sliced);
      } else {
        setPendingRequestToshow(pendingRequest);
      }
    }
  }, [showAll, pendingRequest]);

  return (
    <>
      <div className="CommonPd">
        <div className="dashTopGrid pending_req_box">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("titles.forgot_requests")}
              </Typography>
            </Grid>
            <Grid className="btnStyle">
              {pendingRequest?.length > 5 && (
                <ComponentButton
                  value={showAll ? t("labels.show_less") : t("labels.view_all")}
                  variant={"outlined"}
                  class={"buttonBlank outlineBtn "}
                  callback={() => {
                    setShowAll(!showAll);
                  }}
                />
              )}
            </Grid>
          </Grid>

          {pendingRequest?.length > 0 ? (
            pendingRequestToshow?.map((op) => (
              <div className="dashboard Btn">
                <Grid className="gridLeft">
                  <Typography component="span" variant="body2">
                    {op?.email}
                  </Typography>
                </Grid>
                <Grid className="gridLeftbtn">
                  <ComponentButton
                    value={t("labels.view_details")}
                    variant={"outlined"}
                    class={"buttonBlank Mright"}
                    callback={() => history.push(`/addEmployee/${op?.userId}`)}
                  />
                </Grid>
              </div>
            ))
          ) : (
            <span className="noDataFound">
              {" "}
              {t("labels.no_pending _requests_found")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default DashBoardForgotRequests;
