/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import moment from "moment";
import { loadOptions } from "../../../../api/utils/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";

export default function SelectedEmployeeAttendance({ id }) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [lateAttendance, setLateAttendance] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.date",
      name: "date",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.check_in",
      name: "checkIn",
      type: "time",
      format: "LT",
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.check_out",
      name: "checkOut",
      type: "time",
      format: "LT",
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.overtime",
      name: "overtime",
      width: "10px",
      align: "left",
      minWidth: 50,
    },
  ]);
  const [data, setData] = useState({
    title: "titles.employees_attendance_report",
    page: "Late Attendance",
    search: search,
    setSearch: setSearch,
    buttonGroup: [
      // {
      //   title: "labels.action",
      //   type: "actions",
      //   icon: <AddIcon />,
      //   variant: "outlined",
      //   class: "buttonFill ml-0",
      //   callback: () => {},
      // },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[checkIn]", filterData?.checkIn ?? "");
    formdata.append("filter[checkOut]", filterData?.checkOut ?? "");
    formdata.append("filter[overTime]", filterData?.overTime ?? "");
    formdata.append("filter[date]", filterData?.date ?? "");
    formdata.append("filter[export]", 0);
    const res = await http.getAllDataApi(
      t,
      `/api/reports/employee-attendance-reports/${id}?&page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setFilterData({ ...filterData, filter: false });
    setLateAttendance(res.data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("task", 120)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(t, "", formData, "");
        getApiData(page, size);
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <ComponentTable
      rows={lateAttendance}
      columns={column}
      pageData={data}
      filterData={filterData}
      setFilterData={setFilterData}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      search={search}
      setSearch={setSearch}
      totalRecords={totalRecords}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{}}
    />
  );
}
