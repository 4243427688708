import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import FormInput from "./FormInput";
import { useFormik } from "formik";
import * as YUP from "yup";
import { LargeText } from "../../Technical Department";
import { patchApi, postApi } from "../../../../api/axios";

const CategoryForm = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState("");
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: "",
      image: "sdfsadfg",
    },
    // validateOnMount: true,
    enableReinitialize: true,

    validationSchema: YUP.object({
      name: YUP.string()
        .required("name is required.")
        .min(2, "must be at least 2 characters"),
    }),
    onSubmit: async (values) => {
      // add request
      let fd = new FormData();
      fd.append("name", values.name);
      fd.append("image", file);
      const res = await postApi(t, `/api/reference-categories/create`, fd);
      if (res.status) {
        history.push(`/reference-categories`);
      }
    },
  });

  // console.log(new Date());
  return (
    <div className="SuggestDetailPg">
      <div
        class="CommonPd"
        style={{
          padding: "2rem",
        }}
      >
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.category_form")}
              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            <FormInput
              name="name"
              label={t("labels.name")}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : null
              }
              required={true}
            />
            <div
              className="FormsuggestForm"
              style={
                {
                  // display: 'flex',
                  // justifyContent: 'center'
                }
              }
            >
              <label for="file">{t("labels.choose_file")}</label>

              <input
                type="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
                required={true}
                style={{
                  // width: '30%', alignSelf: 'center',
                  cursor: "pointer",
                }}
              />
            </div>

            {/* <FormInput value={formik.values.employee} name='employee' onBlur={formik.handleBlur} onChange={formik.handleChange} label='Employee name' error={formik.errors.employee && formik.touched.employee ? formik.errors.employee : null} required={true} />

            <div className="FormsuggestForm">
              <label for="description">
                Description
              </label>

              <LargeText desc={formik.values.description} inputName='description' onBlur={formik.handleBlur} onChange={formik.handleChange} error={formik.errors.description && formik.touched.description ? formik.errors.description : null} required={true} />

            </div> */}
          </form>

          <div className="RefresugstBtn">
            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.go_back")}
              variant={"outlined"}
              class={"buttonBlank Mright delbtnRefrence"}
              callback={() => history.push(`/reference-categories`)}
            />
            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.add_to_library")}
              variant={"contained"}
              class={"buttonFill"}
              callback={formik.submitForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;
