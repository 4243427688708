import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import Info from "../../../assets/images/info.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ComponentModal from "../../componentGroups/componectModel";
import { useTranslation } from "react-i18next";
import http, { baseURL } from "../../../api/axios";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import { loadOptions } from "../../../api/utils/common";
import axios from "axios";
import validate from "../../../Hooks/client_validation";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";

const INITIAL_VALUES = {
  name: "",
  phoneNo: "",
  email: "",
  clientType: "",
  relationshipType: "",
  jobTitle: "",
  contractNumber: "",
  contractDate: "",
  branchId: "",
  upload_cotract: "",
  procuration: "",
};
// import "../../assets/css/comman/AllInOneStyle.css";
export default function Clients(props) {
  const { t, i18n } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [clientData, setClientData] = useState();
  const [newClientData, setNewClientData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({ filter: false });

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("client", 90)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`client[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/client/delete/multiple",
          formData,
          "labels.client_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  const [column, setColumn] = useState([
    { lable: "", name: "checkbox", width: "10px", align: "center" },
    {
      lable: "labels.client_name",
      name: "name",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.client_code",
      name: "code",
      width: "10px",
      align: "left",
    },
    { lable: "labels.email", name: "email", width: "10px", align: "left" },
    {
      lable: "labels.phone_number",
      name: "phoneNo",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.procuration",
      name: "procuration",
      width: "10px",
      align: "left",
    },
  ]);

  const handleButton = () => {};
  const [data, setData] = useState({
    title: "tableTitle.3.our_clients",
    page: "Clients",
    infoURL: "/clients/edit-client",
    hasVariable: true,
    handlExport: (page, size, filterData) =>
      handleExport(page, size, filterData),
    buttonGroup: [
      {
        title: "labels.filter_by",
        type: "filter",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {
          handleButton();
        },
      },
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("client", 88)) {
            handleModel("addClient");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });
  const [modalData, setmodelData] = useState({
    model: false,
    title: "titles.add_new_client",
    data: [
      {
        lable: "labels.clients_type",
        field: {
          type: "dropdown_plus",
          required: true,
          findLable: "option",
          option: [],
        },
        button: {
          name: "client_add",
          callback: (val) => {
            addDropdownOptions(val?.client_add, 3);
          },
        },
        name: "clientType",
      },
      {
        lable: "labels.relationship_type",
        field: {
          type: "dropdown_plus",
          required: true,
          findLable: "option",
          option: [],
        },
        button: {
          name: "relationship_add",
          callback: (val) => {
            addDropdownOptions(val?.relationship_add, 4);
          },
        },
        name: "relationshipType",
      },
      {
        lable: "labels.branch",
        field: {
          type: "dropdown",
          required: true,
          findLable: "branchName",
          option: [],
        },
        name: "branchId",
      },
      { lable: "labels.client_name", field: { type: "text" }, name: "name" },
      {
        lable: "labels.phone_number",
        field: { type: "contact", required: true },
        name: "phoneNo",
      },
      {
        lable: "labels.email",
        field: { type: "text", required: true },
        name: "email",
      },
      {
        lable: "labels.job",
        field: { type: "text", required: true },
        name: "jobTitle",
      },
      {
        lable: "labels.procuration",
        field: { type: "text", required: false },
        name: "procuration",
      },
      // {
      //   lable: "Address",
      //   field: { type: "text", required: true },
      //   name: "address",
      // },
      {
        lable: "labels.contract_no",
        field: { type: "text", required: true },
        name: "contractNumber",
      },
      {
        lable: "labels.contract_date",
        field: { type: "date", required: true },
        name: "contractDate",
      },
      {
        lable: "labels.upload_contract",
        field: { type: "file", required: true, accept: "image/*" },
        name: "upload_cotract",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setNewClientData(INITIAL_VALUES);
          handleCloseModel();
        },
      },
      {
        value: "labels.create",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addClient(val);
        },
      },
    ],
  });
  const [branchData, setBranchData] = useState([]);

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ? filterData?.name : "");
    formdata.append("filter[code]", filterData?.code ? filterData?.code : "");
    formdata.append(
      "filter[email]",
      filterData?.email ? filterData?.email : ""
    );
    formdata.append(
      "filter[phoneNo]",
      filterData?.phoneNo ? filterData?.phoneNo : ""
    );
    formdata.append(
      "filter[procuration]",
      filterData?.procuration ? filterData?.procuration : ""
    );
    props.setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/client/exportAllClients`,
      formdata
    );
    props.setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ? filterData?.name : "");
    formdata.append("filter[code]", filterData?.code ? filterData?.code : "");
    formdata.append(
      "filter[email]",
      filterData?.email ? filterData?.email : ""
    );
    formdata.append(
      "filter[phoneNo]",
      filterData?.phoneNo ? filterData?.phoneNo : ""
    );
    formdata.append(
      "filter[procuration]",
      filterData?.procuration ? filterData?.procuration : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/client/?page=${pageIndex + 1}&size=${pageSize}&search=${search}`,
      formdata
    );
    setFilterData({ ...filterData, filter: false });
    setTotalrecords(res.data_count);
    setClientData(res?.data);

    //TODO :needed to be updated to be moved in seperate function
    const branchRes = null;
    if (branchRes) {
      var branchName = [];
      branchRes?.forEach(function (o) {
        const findValue = Object.getOwnPropertyDescriptors(o);
        branchName.push(findValue.branchName.value);
      });
      setBranchData(branchName);
    }
    //
    props.setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, filterData?.filter == true]);

  const addDropdownOptions = async (
    val,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    props.setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", 1);
      res = await http.postApi(t, endPoint, formData);
      setNewClientData({ ...clientData, relationship_add: "", client_add: "" });
      if (!!res) {
        getDropDownOptions(true);
      }
    }
    props.setLoader(false);
  };

  const getDropDownOptions = (val = false) => {
    props.setLoader(true);
    axios
      .all([
        loadOptions(t, "relationshipType", 4),
        loadOptions(t, "clientType", 3),
        loadOptions(t, "", null, "post", "/api/branch/details"),
      ])
      .then((res) => {
        let s1 = { ...modalData, model: val };
        s1.data[0].field.option = res[1];
        s1.data[1].field.option = res[0];
        s1.data[2].field.option = res[2];
        setmodelData(s1);
        props.setLoader(false);
      });
  };

  useEffect(() => {
    getDropDownOptions();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.clientValidate(newClientData);
      setErrors(error);
    }
  }, [newClientData]);

  const addClient = async (obj) => {
    let error = validate.clientValidate(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("phoneNo", obj.phoneNo);
      formData.append("email", obj.email);
      formData.append("clientType", obj.clientType);
      formData.append("relationshipType", obj.relationshipType);
      formData.append("jobTitle", obj.jobTitle);
      formData.append("procuration", obj.procuration);
      formData.append("contractNumber", obj.contractNumber);
      formData.append("contractDate", obj.contractDate);
      formData.append("branchId", obj.branchId);
      formData.append("clientNo", obj.clientNo);
      formData.append("uploadcontract", obj?.upload_cotract);
      formData.append("address", "obj?.upload_cotract"); // to beremoved
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.postApi(t, "/api/client/create", formData);
      props.setLoader(false);
      if (!!res) {
        handleCloseModel();
        getApiData();
      }
    } else {
      setErrors(error);
    }
  };

  const handleCloseModel = () => {
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addClient") {
      setmodelData({
        ...modalData,
        model: true,
      });
    }
  };

  useEffect(() => {
    if (branchData?.length && modalData?.data?.length) {
      const newData = { ...modalData };
      const findValues = newData.data?.find((el) => el.lable === "Branch");
      findValues.field.option = branchData;
      setData(newData);
    }
  }, [branchData, modalData?.data]);

  return (
    <div className="">
      <ComponentModal
        modalData={modalData}
        setPerantState={setNewClientData}
        perantState={newClientData}
        errors={errors}
      />
      <ComponentTable
        rows={clientData}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords || clientData?.length}
        search={search}
        setSearch={setSearch}
        selected={selectedData}
        handleSelected={handleSelected}
        handleAllselect={handleAllselect}
        handleDelete={handleDelete}
        filterData={filterData}
        setFilterData={setFilterData}
        enableReset={true}
        resetdata={{}}
      />
    </div>
  );
}
