// const keyName = "lawUser";


function login(key,obj) {
    let str = JSON.stringify(obj);
    localStorage.setItem(key, str)
}


function logout(key) {
    localStorage.removeItem(key)
}


function getUser(key) {
    let str = localStorage.getItem(key);
    let obj = str ? JSON.parse(str) : null;
    return obj;
}

export default {
    login,
    logout,
    getUser,
}