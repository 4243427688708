import http from "../axios";

/**
 *
 * @param {*} key  dropdown options key
 * @param {*} id   category id on dropdown field
 */
export const loadOptions = async (
  t,
  key,
  id,
  type = "post",
  endPoint = "/api/dropdown/show-options"
) => {
  let res = {};
  if (type === "post") {
    if (id) {
      const formData = new FormData();
      formData.append("id", id);
      res = await http.getAllDataApi(t, endPoint, formData);
      res = res?.data;
    } else {
      res = await http.getAllDataApi(t, endPoint);
      res = res?.data;
    }
  }
  if (type === "get") {
    res = await http.getApi(t, `${endPoint}`);
  }
  return res;
};
