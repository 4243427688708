import { Grid } from '@mui/material';
import React from 'react'
import ComponentButton from '../../../../componentGroups/componentButton';
import { useTranslation } from 'react-i18next';

const TimeoffCard = ({ data, modalData, setModalData, setTimeoffState }) => {
  // console.log(data);
  const { t } = useTranslation();
  const handleModal = () => {
    if (data) localStorage.setItem('timeoffReq', JSON.stringify(data))
    // if (modalData) setModalData({
    //   ...modalData,
    //   model: true,
    // });
    setTimeoffState({ ...data, set_new: true, leave_type: data?.leave_type?.name, id: data.id })

  }

  return (
    <div style={{
      paddingBlock: "33px",
      paddingInline: "25px",
      borderBottom: "1px solid rgba(43, 86, 76, 0.17)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }}>
      <p style={{
        fontSize: "18px",
        textTransform: "capitalize"
      }}>
        {data?.emp_name + ` ` + t('labels.requested') + ` ` + data?.period + ` ` + t('days.days') + ` ` + data?.leave_type?.name}
      </p>
      <Grid className="btnStyle">
        <ComponentButton
          value={t("labels.view_details")}
          variant={"contained"}
          class={"buttonFill"}
          callback={handleModal}
        />
      </Grid>
    </div>
  )
}

export default TimeoffCard