

import React, { useContext, useEffect, useState } from 'react'
import { getApi } from '../../../api/axios';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import BroadcastCard from '../../componentGroups/BroadcastCard';
import LoadingContext from '../../../context/LoaderContext';


const RecievedBroadcasts = () => {

  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [broadcasts, setBroadcasts] = useState([]);
  const getApiData = async () => {

    let res = await getApi(t, `/api/broadcasts/recieved`);

    if (!!res) {
      setBroadcasts(res)
    }
    setLoader(false);
  };


  useEffect(() => {
    setLoader(true);
    getApiData();
  }, [])
  return (
    <Grid style={{
      display: "flex",
      flexDirection: "column",
      gap: "1rem"
    }}>

      {broadcasts.length > 0 ? broadcasts?.map((broadcast) => <BroadcastCard data={broadcast} key={broadcast.id} />) : <span className="noDataFound">
        {t('labels.no_data')}
      </span>}
    </Grid>
  )
}

export default RecievedBroadcasts