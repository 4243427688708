import React, { useState } from "react";
import moment from "moment";
import { Typography, Box, Grid, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import FileView from "./FileView";
import Svg from "../../../../assets/svg/svg";

const DocumentSubFolder = ({ item, index }) => {
  const { t } = useTranslation();

  const [expand, setExpand] = useState(false);
  const handleClose = () => {
    setExpand(false);
  };
  const handleOpen = () => {
    setExpand(true);
  };

  return (
    <>
      <Box sx={{ mt: 2, p: 2 }} className="box box1" key={index}>
        <Grid
          onClick={() => (expand === false ? handleOpen() : handleClose())}
          sx={{ cursor: "pointer" }}
          className="perviewBox"
          style={{
            padding: "10px 10px !important",
            marginTop: "0px",
          }}
          spacing={3}
        >
          <div
            className="detailsBox arrowINbox"
            style={{
              width: "100%",
            }}
          >
            <Grid>
              <div className="folderIvcccIcon">
                <Svg type="folder" />
                <div className="folderIvccc">
                  <Typography variant="body2" className="title iconText">
                    {item?.name}
                  </Typography>
                  <Typography variant="body2" className="details">
                    {" "}
                    {"Files"} :{" "}
                    {item?.form_documents?.length + item?.client_files?.length}
                  </Typography>
                  {item?.updatedAt && (
                    <Typography variant="body2" className="details">
                      {" "}
                      {t("labels.modified")} :{" "}
                      {item?.updatedAt &&
                        `${moment(item.updatedAt).format(
                          "MMMM DD , YYYY"
                        )}${" "} at `}
                      {item?.updatedAt && moment(item.updatedAt).format("hh A")}
                    </Typography>
                  )}
                </div>
              </div>
            </Grid>
            <div className="folderArrow">
              <span>
                {expand ? (
                  <KeyboardArrowUp className="iconArrow" />
                ) : (
                  <KeyboardArrowDownIcon className="iconArrow" />
                )}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="interFile">
            <Collapse in={expand} timeout="auto" unmountOnExit>
              {item?.client_files?.map((file, index) => {
                return <FileView index={index} item={file} />;
              })}
              {item?.form_documents?.map((file, index) => {
                return <FileView index={index} item={file} />;
              })}
            </Collapse>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default DocumentSubFolder;
