import React, { useEffect, useState, useContext } from "react";
import { styled, alpha } from "@mui/material/styles";
import "../../assets/css/comman/AllInOneStyle.css";
import "../../assets/css/style.css";
import "../../assets/css/comman/commonStyle.css";
import {
  AppBar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { FormLabel } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import logo from "../../assets/images/logo.jpg";
import chat from "../../assets/images/chat.jpg";
import Avatar from "@mui/material/Avatar";
import notifaction1 from "../../assets/images/notifaction.png";
import profile from "../../assets/images/profile.png";
import USA from "../../assets/images/usa.png";
import Arabic from "../../assets/images/arabFlag.png";
import SegmentIcon from "@mui/icons-material/Segment";
import cookie from "js-cookie";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Svg from "../../assets/svg/svg";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import ComponentSearch from "../componentGroups/componentSearch";
import auth from "../../services/auth";
import LogoutModal from "./logoutModal";
import ComponentModal from "../componentGroups/componectModel";
import http from "../../api/axios";
import ComponentSwitch from "../componentGroups/componentSwitch";
import SearchIcon from "@mui/icons-material/Search";
import LoadingContext from "../../context/LoaderContext";
import { useTranslation } from "react-i18next";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const options = [
  "You Have a Meeting After 15 Mins",
  "Meeting Mins Validation Request",
  "You Have a Meeting After 15 Mins",
  "Meeting Mins Validation Request",
  "You Have a Meeting After 15 Mins",
  "Meeting Mins Validation Request",
];
function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const language = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
    dir: "ltr",
  },
  {
    code: "ar",
    name: "عربي",
    country_code: "sa",
    dir: "rtl",
  },
];

export default function PrimarySearchAppBar(props) {
  const { t } = useTranslation();
  const { socketData } = useContext(LoadingContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [menuToggle, SetToggle] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [logoutToggle, setLogoutTooggle] = React.useState(false);
  const [anchorNotify, setAnchorNotify] = React.useState(null);
  const notifaction = Boolean(anchorNotify);
  const isMenuOpen = Boolean(anchorEl);
  const currentLanguageCode = cookie.get("i18next") || "en";
  const currentLanguage = language.find((l) => l.code == currentLanguageCode);
  const [menuLaw, setMenuLaw] = useState(false);
  const [clockStatus, setClockStatus] = useState("");
  const [nickName, setNickName] = useState({ nickname: "" });
  const [modalDataNickName, setModalDataNickName] = useState({
    model: false,
    title: "titles.add_nick_name",
    data: [
      {
        lable: "labels.nick_name",
        field: { type: "text", required: true },
        name: "nickname",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          closeModelNickName();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addNickName(value);
        },
      },
    ],
  });
  const [modalData, setModalData] = useState({
    model: false,
    title: "",
    description: "",
  });

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/user/profile/get`);
    let clock = await http.getApi(t, "/api/employee/currentattendence/status");
    setClockStatus(clock?.status);
    setNickName({
      ...nickName,
      nickname: res?.nickname,
      profile_pic_path: res?.profile_pic_path,
    });
  };
  // console.log(socketData, "lllllllllllllllllllllllllllllllllllllllllll");

  const addjustClockType = async () => {
    props.setLoader(true);
    var formdata = new FormData();
    formdata.append("clockType", clockStatus == 1 ? 0 : 1);
    formdata.append("date", new Date());
    let res = await http.postApi(
      t,
      `/api/employee/timer/counter`,
      formdata,
      clockStatus == 1 ? "Clock out " : "Clock In "
    );
    getApiData();
    props.setLoader(false);
  };

  const addNickName = async (obj) => {
    console.log(obj, "nickNameAA");
    var formdata = new FormData();
    formdata.append("nickname", obj?.nickname);
    let res = http.patchApi(t, `/api/user/profile/nickname`, formdata);
    getApiData();
    closeModelNickName();
  };

  useEffect(() => {
    getApiData();
  }, []);
  // document.body.dir ="rtl"
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);

  const handleCloseModel = () => {
    setAnchorNotify(null);
    history.push("/notification");
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const closeModelNickName = () => {
    setModalDataNickName({ ...modalDataNickName, model: false });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMenuLaw(!menuLaw);
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLang = (option, position, lang) => {
    SetToggle(false);
    props.setlangId(lang);
    props.changeLanguage(option);
    document.body.dir = position;
    i18next.changeLanguage(option);
  };

  const notificationId = "lock-menu";
  const randerNotifaction = (
    <Menu
      onClick={() => handleCloseModel()}
      className="notifactionBox"
      id={notificationId}
      anchorEl={anchorNotify}
      open={notifaction}
      onClose={() => setAnchorNotify(null)}
      MenuListProps={{
        "aria-labelledby": "lock-button",
        role: "listbox",
      }}
    >
      <MenuItem>
        <Typography variant="h6" className="notifactionTitle">
          NOTIFICATION
        </Typography>
      </MenuItem>
      <Divider className="divider" />
      {socketData?.length != 0 &&
        socketData
          .slice(socketData.length - 5, socketData.length)
          ?.map((option, index) => (
            <div>
              <MenuItem
                key={option}
                className="notifaction"
                // disabled={index === 0}
                // selected={index === selectedIndex}
                // onClick={() => handleLang(option.code)}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option?.message}
                <Svg type="dot" style={{ marginLeft: "10px" }} />
              </MenuItem>
              <Divider className="divider" />
            </div>
          ))
          .reverse()}
      <MenuItem
        sx={{ justifyContent: "center" }}
        onClick={() => handleCloseModel()}
      >
        <Typography variant="h6" className="viewAll">
          View All
        </Typography>
      </MenuItem>
    </Menu>
  );

  const handleLogoutModel = () => {
    getApiData();
    setModalData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addLogout") {
      setModalData({
        ...modalData,
        model: true,
        title: t("titles.are_you_sure_you_want_to_Log_Out"),
        type: "logout",
        description: t("titles.you_are_about_logout_as_result"),
      });
    } else if (val == "nickName") {
      setModalDataNickName({
        ...modalDataNickName,
        model: true,
      });
    } else if (val == "profile_pic") {
      setModalData({
        ...modalData,
        model: true,
        title: "Change Profile Picture",
        type: "nickName",
      });
    } else {
      history.push("/empAttendance");
    }
  };
  const handleLangMenu = () => {
    SetToggle(!menuToggle);
  };

  const logoutSelect = () => {
    props.setLoader(true);
    auth.logout("token");
    auth.logout("user");
    window.location.href = "/";
    props.setLoader(false);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      className="navbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{t("titles.profile")}</MenuItem>
      <MenuItem onClick={handleMenuClose}>{t("titles.logout")}</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <>
      <div
        className="TableMenu Profileclick"
        onMouseLeave={() => setMenuLaw(false)}
      >
        <ul className="menuTableList">
          <MenuItem className="div-desktop" li sx={{ display: { lg: "none" } }}>
            <a onClick={(e) => handleMenuClose()}>
              <Svg type={"profile"} />
              <span class="sideicon">{t("titles.profile")} </span>
            </a>
          </MenuItem>
          <MenuItem className="div-desktop" li sx={{ display: { lg: "none" } }}>
            <a onClick={() => handleLang(Option.code)}>
              <Svg
                type={"notifaction"}
                aria-label="show 17 new notifications"
              />
              <span class="sideicon"> {t("titles.notifications")} </span>
            </a>
          </MenuItem>
          <MenuItem className="div-desktop" li sx={{ display: { lg: "none" } }}>
            <a>
              <Svg type={"email"} aria-label="show 4 new mails" />
              <span class="sideicon"> {t("titles.messages")} </span>
            </a>
          </MenuItem>

          <li>
            <a onClick={() => handleModel("profile_pic")}>
              <Svg type={"change_profile_picture"} />
              <span class="sideicon">
                {" "}
                {t("titles.change_profile_picture")}{" "}
              </span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleModel("nickName");
              }}
            >
              <Svg type={"add_nick_name"} />
              <span class="sideicon"> {t("titles.add_nick_name")}</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                handleModel("");
              }}
            >
              <Svg type={"Attendance"} />
              <span class="sideicon"> {t("labels.attendance")}</span>
            </a>
          </li>

          <li class="language_select_mobile ">
            <div className=" Flagnav menuLanBtn ">
              <IconButton
                size="medium"
                color="inherit"
                onClick={handleLangMenu}
              >
                <a href="javascript:void(0)" className="dropdown-toggle ">
                  <Svg type="globe" />{" "}
                  <span className="sideicon">{t("titles.language")} ▼</span>
                </a>
              </IconButton>
              {menuToggle ? (
                <ul class="dropdown-menu" onMouseLeave={() => SetToggle(false)}>
                  <li id="div-desktop">
                    <div
                      onClick={() => handleLang("ar", "rtl", 0)}
                      class="rootDiv"
                    >
                      <img src={Arabic} class="icon" />
                      <a>Arabic</a>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => handleLang("en", "ltr", 1)}
                      class="rootDiv"
                    >
                      <img src={USA} class="icon" />
                      <a>English</a>
                    </div>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </li>

          <li>
            <a
              onClick={() => {
                handleModel("addLogout");
              }}
            >
              <Svg type={"logout"} />
              <span class="sideicon">{t("titles.logout")} </span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <ComponentModal
        // errors={errors}
        modalData={modalDataNickName}
        setPerantState={setNickName}
        perantState={nickName}
      />
      <Box sx={{ flexGrow: 1 }}>
        <ElevationScroll {...props}>
          <AppBar position="static" sx={{ flexGrow: 1, background: "#FFFFFF" }}>
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <img src={logo} className="" />
              </Typography>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 2 }}
                onClick={props.handleDrawerClose}
                className="TestinSidebar"
              >
                <SegmentIcon sx={{ color: "#2B564C" }} />
              </IconButton>
              <div className="header_nav">
                <SearchIconWrapper className="hideShowSearch">
                  {/* <SearchIcon sx={{ color: "red" }} /> */}
                </SearchIconWrapper>

                <div className="TopsearchBar searchResponsive ">
                  <ComponentSearch navSearch={true} />
                </div>
                <div className="mobile_togle_btnn">
                  <ComponentSwitch
                    value={clockStatus == 1 ? true : false}
                    rightLabel={"Clock IN"}
                    leftLabel={"Clock Out"}
                    handleChange={(evt) => {
                      addjustClockType(evt.target.checked);
                    }}
                  />
                </div>
              </div>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <div className="clcokInOut ">
                  <ComponentSwitch
                    value={clockStatus == 1 ? true : false}
                    rightLabel={t("titles.clock_in")}
                    leftLabel={t("titles.clock_out")}
                    handleChange={(evt) => {
                      addjustClockType(evt.target.checked);
                    }}
                  />
                </div>
                <div className=" Flagnav menuLanBtn ">
                  <IconButton
                    size="medium"
                    color="inherit"
                    onClick={handleLangMenu}
                  >
                    <a href="javascript:void(0)" className="dropdown-toggle ">
                      <Svg type="globe" /> <span>▼</span>
                    </a>
                  </IconButton>
                  {menuToggle ? (
                    <ul
                      class="dropdown-menu"
                      onMouseLeave={() => SetToggle(false)}
                    >
                      <li id="div-desktop">
                        <div
                          onClick={() => handleLang("ar", "rtl", 0)}
                          class="rootDiv"
                        >
                          <img src={Arabic} class="icon" />
                          <a>Arabic</a>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => handleLang("en", "ltr", 1)}
                          class="rootDiv"
                        >
                          <img src={USA} class="icon" />
                          <a>English</a>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <img className="chatIcon" src={chat} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  id="lock-button"
                  aria-haspopup="listbox"
                  aria-controls="lock-menu"
                  aria-label="show 17 new notifications"
                  aria-expanded={notifaction ? "true" : undefined}
                  onClick={(event) => setAnchorNotify(event.currentTarget)}
                  color="inherit"
                >
                  <Badge badgeContent={socketData.length} color="error">
                    <img className="chatIcon" src={notifaction1} />
                  </Badge>
                </IconButton>
                <ListItem
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                  className="profileWrapper pointer"
                >
                  <ListItemAvatar>
                    <Avatar
                      className="userImg"
                      alt="Profile Picture"
                      src={`${http.baseURL}/${nickName?.profile_pic_path}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className="ProfileDetail"
                    primary={user?.name}
                    secondary={
                      user?.departmentId === 13
                        ? "Lawyer's Affairs Manager"
                        : user?.departmentId === 12
                        ? "Academic Researcher"
                        : user?.departmentId === 11
                        ? "Legal Secretary"
                        : user?.departmentId === 10
                        ? "Class A/B Attorney"
                        : user?.departmentId === 9
                        ? "Executive Secretary"
                        : user?.departmentId === 8
                        ? "HR"
                        : user?.departmentId === 7
                        ? "Technical officer Manger"
                        : user?.departmentId === 6
                        ? "Technical Department"
                        : user?.departmentId === 5
                        ? "Employee"
                        : user?.departmentId === 4
                        ? "Client"
                        : user?.departmentId === 2
                        ? "Partner"
                        : user?.departmentId === 3
                        ? "Coordinator"
                        : user?.departmentId === 1
                        ? "Executive Director"
                        : ""
                    }
                  />
                </ListItem>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  sx={{ color: "#2b564c" }}
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
              {menuLaw ? renderMobileMenu : ""}
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        {randerNotifaction}

        {renderMenu}
      </Box>
      <LogoutModal
        modalData={modalData}
        closeModal={() => handleLogoutModel()}
        logOut={() => logoutSelect()}
        setModalData={setModalData}
      />
    </>
  );
}
