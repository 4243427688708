import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Collapse,
  IconButton,
} from "@mui/material";
import Svg from "../../../../../assets/svg/svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../../../formDocument/style.css";
import DocumentForm from "../../../formDocument/documents/document_files";
export default function MeetingInComplete({
  data,
  meetingState,
  getApiData,
  handleChangeState,
}) {
  const { t, i18n } = useTranslation();
  const [menuToggle, SetToggle] = React.useState(false);
  const [expanded, setExpanded] = useState(-1);
  const [inExpanded, setInExpanded] = useState(false);
  const [modelState, setModelState] = useState({})
  const [modalData, setmodalData] = useState({})


  const handleClose = () => {
    setInExpanded(false);
    setExpanded(-1);
  };
  const handleOpen = (index) => {
    setInExpanded(true);
    setExpanded(index);
  };
  console.log(meetingState?.form_documents, data, "lplplplpl");

  return (
    <>
      <div className="DocPage">
        <div className="CommonPd">
          <div className="latest_des_box">
            <Grid className="dashTopGrid documentContainer">
              {data?.length != 0 ? (
                data?.map((op, index) => (
                  <Box
                    sx={{ mt: 2, p: 2 }}
                    className="box"
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ marginRight: "10px", marginTop: "10px" }}>
                      {" "}
                      <input
                        type="checkbox"
                        onClick={() => handleChangeState(op)}
                        checked={meetingState?.form_documents?.find(
                          (p) => p.id == op.id
                        )}
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      container
                      spacing={1}
                      style={{ width: "100%" }}
                    >
                      {op?.shareType == 1 ? (
                        <>
                          <Grid
                            onClick={() =>
                              inExpanded == false
                                ? handleOpen(index)
                                : handleClose()
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <div
                              className="detailsBox arrowINbox"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid>
                                <div className="folderIvcccIcon">
                                  <Svg type="folder" />
                                  <div className="folderIvccc">
                                    <Typography
                                      variant="body2"
                                      className="iconText"
                                      style={{
                                        fontSize: "14px",
                                        color: "#2b564c",
                                      }}
                                    >
                                      {op?.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {" "}
                                      {"Files"} :{" "}
                                      {op?.form_documents?.length +
                                        op?.client_files?.length}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="details"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {" "}
                                      {t("labels.modified")} :{" "}
                                      {op?.updatedAt &&
                                        `${moment(op.updatedAt).format(
                                          "MMMM DD , YYYY"
                                        )}${" "} at `}
                                      {op?.updatedAt &&
                                        moment(op.updatedAt).format("hh A")}
                                    </Typography>
                                  </div>
                                </div>
                              </Grid>
                              <div className="folderArrow">
                                <span>
                                  {inExpanded == true && index == expanded ? (
                                    <KeyboardArrowUp className="iconArrow" />
                                  ) : (
                                    <KeyboardArrowDownIcon className="iconArrow" />
                                  )}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <div className="interFile">
                              <Collapse
                                in={index == expanded && inExpanded == true}
                                timeout="auto"
                                unmountOnExit
                              >
                                {op?.form_documents &&
                                  op?.form_documents.length != 0 &&
                                  op?.form_documents.map((form) => (
                                    <DocumentForm
                                      setmodalData={setmodalData}
                                      modalData={modalData}
                                      modelState={modelState}
                                      setModelState={setModelState}
                                      getApiData={getApiData}
                                      op={form}
                                      expanded={expanded}
                                      disablePreview={true}
                                    />
                                  ))}
                                {op?.client_files &&
                                  op?.client_files.length != 0 &&
                                  op?.client_files.map((file) => (
                                    <DocumentForm
                                      setmodalData={setmodalData}
                                      modalData={modalData}
                                      modelState={modelState}
                                      setModelState={setModelState}
                                      getApiData={getApiData}
                                      op={file}
                                      expanded={expanded}
                                      disablePreview={true}
                                    />
                                  ))}
                              </Collapse>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} md={12} lg={12}>
                          <DocumentForm
                            setmodalData={setmodalData}
                            modalData={modalData}
                            modelState={modelState}
                            setModelState={setModelState}
                            getApiData={getApiData}
                            op={op}
                            disablePreview={true}
                          />
                        </Grid>
                      )}
                    </div>
                  </Box>
                ))
              ) : (
                <div class="noDataFound h-full">{t("labels.no_data")}</div>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
