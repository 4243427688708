import { Typography } from '@mui/material';
import React from 'react'

const DateLine = ({ title, date }) => {

  const finalDate = new Date(
    date
  ).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',

    hour: 'numeric',
    hour12: true,
  });

  return (
    <Typography variant="body2" color="text.secondary" className="textFont">{`${title} `}{finalDate}</Typography>
  )
}

export default DateLine