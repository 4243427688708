import * as React from "react";
import { Grid, Typography } from "@mui/material";
import "../style.css";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function PersonalInformation({
  errors,
  empState,
  setEmpState,
  dropDownOptions,
  addDropdownOptions,
}) {
  const { t } = useTranslation();

  const addContact = () => {
    const data = { ...empState };
    data.emergencyContact.push({ id: 0, contact: "" });
    setEmpState(data);
  };

  const removeContact = (index) => {
    let data = { ...empState };
    data.emergencyContact.splice(index, 1);
    setEmpState(data);
  };

  return (
    <>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.title")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            option={dropDownOptions.title}
            name="title"
            value={empState?.title}
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) => addDropdownOptions(val, "title", 7)}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.gender")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            option={dropDownOptions.gender}
            name="gender"
            value={empState?.gender}
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.father_name")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={empState?.fatherName}
            name="fatherName"
            callback={(evt) => {
              setEmpState({ ...empState, [evt.target.name]: evt.target.value });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.mother_name")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={empState?.motherName}
            name="motherName"
            callback={(evt) => {
              setEmpState({ ...empState, [evt.target.name]: evt.target.value });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.phone")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={empState?.phone}
            name="phone"
            callback={(evt) => {
              if (!isNaN(evt.target.value)) {
                setEmpState({
                  ...empState,
                  [evt.target.name]: evt.target.value,
                });
              }
            }}
          />
          {errors?.phone && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.phone)}`}
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.date_of_birth")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          {/* <ComponentTextString class={"custonInput"} placeholder="" /> */}
          <ComponentDatePicker
            class={"custonInput"}
            placeholder=""
            value={empState?.dob}
            name="dob"
            callback={(evt) => {
              setEmpState({ ...empState, [evt.target.name]: evt.target.value });
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.fax")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={empState?.fax}
            name="fax"
            callback={(evt) => {
              setEmpState({ ...empState, [evt.target.name]: evt.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.nationality")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            option={dropDownOptions.nationality}
            name="nationality"
            value={empState?.nationality}
            callback={(evt) => {
              setEmpState({
                ...empState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) => addDropdownOptions(val, "nationality", 9)}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.emergency_contact_info")}
          </Typography>
        </Grid>
        <Grid item xs={11} md={9}>
          {empState.emergencyContact?.map((item, index) => {
            return (
              <Grid className="CustomWidth" item>
                <div
                  className="flex-center"
                  style={{ position: "relative", marginBottom: " 10px" }}
                >
                  <ComponentTextString
                    class={"custonInput"}
                    value={item.contact}
                    name={index}
                    callback={(evt) => {
                      const { value, name } = evt.target;
                      if (!isNaN(value)) {
                        let data = { ...empState };
                        data.emergencyContact[name].contact = value;
                        setEmpState(data);
                      }
                    }}
                  />
                  {index === 0 ? (
                    <AddCircleOutlineIcon
                      className={"onclickAdd"}
                      sx={{
                        color: "#367AF6",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      fontSize="medium"
                      onClick={() => addContact()}
                    />
                  ) : (
                    <CancelOutlinedIcon
                      className={"onclickAdd"}
                      sx={{
                        mt: 1,
                        color: "red",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => removeContact(index)}
                    />
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
