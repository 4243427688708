import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import http from "../../../../api/axios";
import ComponentButton from "../../../componentGroups/componentButton";
import LoadingContext from "../../../../context/LoaderContext";
import DocumentFolder from "./DocumentFolder";
import FileItem from "./FileItem";

const CompleteAndIncompleteDocumentViews = ({
  title,
  endPoint = "",
  enablArchive = false,
  enableSubmitTo = false,
}) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [files, setFiles] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [filesToshow, setFilesToShow] = useState([]);

  const getFiles = async () => {
    if (endPoint !== "") {
      setLoader(true);
      const res = await http.getApi(t, endPoint);
      setLoader(false);
      if (!!res && res?.length > 0) {
        setFiles(res);
      } else {
        setFiles([]);
      }
    }
  };

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setFilesToShow(files);
    } else {
      if (files?.length > 3) {
        let sliced = [...files]?.slice(0, 3);
        setFilesToShow(sliced);
      } else {
        setFilesToShow(files);
      }
    }
  }, [showAll, files]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography className="morningText" variant="h4">
              {t(title)}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {files?.length > 3 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
          </Grid>
        </Grid>

        {filesToshow?.map((item) => {
          return item.shareType === 1 ? (
            <DocumentFolder
              item={item}
              enablArchive={enablArchive}
              enableSubmitTo={enableSubmitTo}
              getFiles={getFiles}
            />
          ) : (
            <FileItem
              item={item}
              enablArchive={enablArchive}
              enableSubmitTo={enableSubmitTo}
              getFiles={getFiles}
            />
          );
        })}
        {files?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">{t("labels.no_docs_avilable")}</div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default CompleteAndIncompleteDocumentViews;
