import React from "react"
import { Typography, Box, Modal } from "@mui/material"
import "../../style.css"
import ComponentButton from "../../../../componentGroups/componentButton"
import Svg from "../../../../../assets/svg/svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    // borderRadius: "2px solid red",
    // border: "2px solid ",
    boxShadow: 20,
    // p: 4,
};
const ValidationMintsModal = ({ modalData, handleModal, setRequestModalData }) => {
    return (
        <Modal
            className="MainModal"
            open={modalData.model}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="ModalBG" sx={style}>
                <div>
                    <div>
                        <div className="root_heading">
                            <Typography className="validate_text">Validation Request</Typography>
                            <span onClick={() => setRequestModalData({ ...modalData, model: false })}>  <Svg type="close" /></span>
                        </div>
                        <div className="meeting_mints_root">
                            <ComponentButton
                                value={"Send OTP Email"}
                                variant={"contained"}
                                class={"buttonFill"}
                                callback={() => {
                                    handleModal("otp");
                                }}
                            />
                            <ComponentButton
                                value={"Send Validation Request in System"}
                                variant={"contained"}
                                class={"buttonFill"}
                                callback={() => {
                                    handleModal("inSystem");
                                }}
                            />
                        </div>

                    </div>
                </div>
            </Box>
        </Modal>)
}
export default ValidationMintsModal