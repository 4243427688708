import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentButton from "../../../componentGroups/componentButton";
import GenericModal from "../../../componentGroups/genericModal";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import "./style.css";
import moment from "moment";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import { PROMOTION_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/promotion_key_lable";

const PromotionDetail = () => {
  const { promotionId } = useParams();
  const id = promotionId;
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const isDirector = user?.departmentId === 1;
  const [open, setOpen] = useState(false);
  const [promotionDetail, setPromotionDetail] = useState({
    newSalary: "",
    startDate: "",
    employee: "",
    promoted_to: "",
    status: "",
    requestedBy: "",
    PromotionApprovals: [],
    requestApprovalFrom: "",
    approvalUsers: {},
  });
  const [rejectionReason, setRejectionReason] = useState("");
  const [hrId, setHrId] = useState("");

  const getPromotionDetail = async () => {
    setLoader(true);
    const res = await http.getApi(t, `/api/promotions/view/${id}`);
    let users = {};
    const requestApprovalFrom = res?.PromotionApprovals?.map((item) => {
      users[item.user.id] = item.status;
      if (item.user.id !== user?.id && user?.departmentId === 1) {
        setHrId(item.user.id);
      }
      return item?.user?.name;
    }).join(", ");
    setPromotionDetail({
      ...res,
      requestApprovalFrom: requestApprovalFrom,
      approvalUsers: users,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      getPromotionDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
    setRejectionReason("");
  };

  const handleApproval = async (status, rejectionReason) => {
    setLoader(true);
    let data = {
      status: status,
    };
    if (status === 3) {
      data = { ...data, reason: rejectionReason };
    }
    await http.patchApi(t, `/api/promotions/approval/${id}`, data);
    setLoader(false);
    getPromotionDetail();
    handleClose();
  };

  return (
    <>
      <GenericModal title={"labels.rejection_reason"} open={open}>
        <div
          className="scroll-auto ModalContent"
          style={{ minHeight: "200px" }}
        >
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.rejection_reason")}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <MinHeightTextarea
                name="rejectionReason"
                value={rejectionReason}
                minRows={10}
                callback={(e) => setRejectionReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.send")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => handleApproval(3, rejectionReason)}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t("labels.promotion_request_details")}
                </Typography>
              </Grid>
              {!!promotionDetail?.status && promotionDetail?.status !== 2 && (
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div
                    className="promotionStatus"
                    style={{
                      background:
                        promotionDetail?.status === 1 ? "#02b935" : "#FF0101",
                    }}
                  >
                    {promotionDetail?.status === 1
                      ? t("labels.approved")
                      : t("labels.rejected")}
                  </div>
                </Grid>
              )}
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.employee")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="employee"
                    value={promotionDetail.employee}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.promoted_to")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="promoted_to"
                    value={promotionDetail.promoted_to}
                    disab
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.start_from")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="startDate"
                    value={moment(promotionDetail.startDate).format("LL")}
                    disab
                  />
                </Grid>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.new_salary")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="newSalary"
                    value={promotionDetail.newSalary}
                    disab
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.request_approval_from")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="taskTitle"
                    value={promotionDetail?.requestApprovalFrom}
                  />
                </Grid>
              </Grid>
              {!!promotionDetail?.approvalUsers.hasOwnProperty(user.id) &&
                (promotionDetail?.approvalUsers[user.id] === 2 ||
                  (isDirector &&
                    promotionDetail?.approvalUsers[user.id] === 0 &&
                    promotionDetail?.approvalUsers[hrId] === 4)) && (
                  <Grid
                    container
                    style={{ margin: "16px 0", justifyContent: "center" }}
                  >
                    <Grid item>
                      <ComponentButton
                        value={t("labels.reject")}
                        variant={"outlined"}
                        class={"buttonBlank outlineBtn"}
                        callback={() => setOpen(true)}
                      />
                    </Grid>
                    <Grid item>
                      <ComponentButton
                        value={t("labels.approve")}
                        variant="contained"
                        class="buttonFill FillBtn ml-3"
                        callback={() => handleApproval(1)}
                      />
                    </Grid>
                  </Grid>
                )}
            </div>
            <div style={{ marginTop: "20px" }}>
              {id && (
                <LogNoteAccordion
                  id={`Promotion${id}`}
                  labelKeys={PROMOTION_KEY_LABELS}
                />
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PromotionDetail;
