import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MeetingMinsValidationModal from "./MeetingMinsValidationModal";
import "../style.css";

const PastDecisionsCard = ({ item, getPastDecisions, modulePath }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState({
    open: false,
    item: {},
  });

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Card className="Partner-meet">
        <div className="folderSection">
          <div className="folderSection1">
            <Typography variant="subtitle1">{item?.title}</Typography>
          </div>
        </div>
        <CardContent
          sx={{ display: "flex", gap: "30px" }}
          style={{ height: "50px", overflowY: "auto" }}
        >
          <div className="boardmetcontent">
            <Typography variant="subtitle2">{item?.description}</Typography>
          </div>
        </CardContent>
        <div
          style={{ justifyContent: "flex-end" }}
          className="partnerCard-btnContainer folderSection"
        >
          <ComponentButton
            value={t("labels.view_details")}
            variant={"outlined"}
            class={"buttonBlank Mright min-w-130"}
            callback={() => {
              history.push({
                pathname: modulePath
                  ? `/${modulePath}/meeting-detail`
                  : "/meetingDetails",
                state: { id: item?.id },
              });
            }}
          />
          <ComponentButton
            value={t("labels.validate_now")}
            variant={"contained"}
            class={"buttonFill fill1 min-w-130"}
            sx={{ paddingRight: "0px !important" }}
            callback={() => {
              setModal({
                open: true,
                item: item,
              });
            }}
          />
        </div>
      </Card>
      <MeetingMinsValidationModal
        open={modal?.open}
        item={modal?.item}
        onCLose={() => {
          setModal({
            open: false,
            item: {},
          });
          getPastDecisions();
        }}
      />
    </Grid>
  );
};

export default PastDecisionsCard;
