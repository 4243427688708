import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../../api/axios";
import ComponentButton from "../../../componentGroups/componentButton";
import LoadingContext from "../../../../context/LoaderContext";

const PromotionRequests = ({ refetch = false }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const history = useHistory();
  const [promotionRequests, setPromotionRequests] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [promotionRequestsToVIew, setPromotionRequestsToVIew] = useState([]);

  const getPromotionRequests = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/promotions/requests");
    setLoader(false);
    if (!!res && res?.length > 0) {
      setPromotionRequests(res);
    } else {
      setPromotionRequests([]);
    }
  };

  useEffect(() => {
    getPromotionRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setPromotionRequestsToVIew(promotionRequests);
    } else {
      if (promotionRequests?.length > 3) {
        let sliced = promotionRequests?.slice(0, 3);
        setPromotionRequestsToVIew(sliced);
      } else {
        setPromotionRequestsToVIew(promotionRequests);
      }
    }
  }, [showAll, promotionRequests]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid className="TxtMbottom">
            <Typography className="morningText" variant="h4">
              {t("titles.promotion_requests")}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {promotionRequests?.length > 3 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank Mright outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
          </Grid>
        </Grid>
        {promotionRequestsToVIew?.map((item) => {
          return (
            <div className="dashboard Btn">
              <Grid className="gridLeft">
                <Typography component="span" variant="body2">
                  {item?.requestedBy} Sent You a Promotion Request to{" "}
                  <spam className="highlight">{item.employee}</spam>
                  &nbsp; who Needs To be Promoted To
                  <spam className="highlight">{" " + item.promoted_to}</spam>
                </Typography>
              </Grid>
              <Grid className="gridLeftbtn">
                <ComponentButton
                  value={t("labels.view_details")}
                  variant={"contained"}
                  class={"buttonFill FillBtn"}
                  callback={() => {
                    history.push(
                      `/promotions/promotion-detail/${item?.promotion_id}`
                    );
                  }}
                />
              </Grid>
            </div>
          );
        })}
        {promotionRequests?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">
              {t("titles.no_promotion_available")}
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default PromotionRequests;
