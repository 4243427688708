import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Tabs, Tab } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import Svg from "../../../assets/svg/svg";
import http from "../../../api/axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../componentGroups/componectModel";
import { loadOptions } from "../../../api/utils/common";
import validate from "../../../Hooks/meeting_decision_validaction";
import useFormatTime from "../../../Hooks/useFormatTime";

const MeetingDecisionList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { globalToLocal } = useFormatTime();
  const [meetingData, setMeetingData] = useState([]);
  const [modelState, setModelState] = useState({});
  const [errors, setErrors] = useState({});
  const [modalData, setModelData] = useState({
    model: false,
    title: "labels.share_decision_with",
    data: [
      {
        lable: "labels.send_to",
        field: { type: "dropdown", findLable: "name", required: true },
        name: "sendTo",
      },
      {
        lable: "labels.note",
        field: { type: "textArea", required: true, minRows: 4 },
        name: "note",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.send",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          shareWith(value);
        },
      },
    ],
  });
  const getApiData = async () => {
    props.setLoader(true);
    let res = await http.getApi(t, `/api/meeting/meeting-decision/all/list`);
    setMeetingData(res);
    props.setLoader(false);
  };
  const getModelData = async () => {
    let s1 = { ...modalData };
    let res = await loadOptions(t, 1, undefined, "post", "/api/employee");
    s1.data[0].field.option = res;
    setModelData(s1);
  };

  const handleCloseModel = () => {
    setModelState({});
    setErrors({});
    setModelData({ ...modalData, model: false });
  };

  const shareWith = async (obj) => {
    let error = validate.decisionValidaction(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      var formdata = new FormData();
      formdata.append("send_to", obj?.sendTo);
      formdata.append("note", obj?.note);
      const res = await http.postApi(
        t,
        `/api/meeting/meeting-decision/share/${obj?.id}`,
        formdata
      );
      props.setLoader(false);
      getApiData();
      handleCloseModel();
    } else {
      setErrors(error);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.decisionValidaction(modelState);
      setErrors(error);
    }
  }, [modelState]);

  useEffect(() => {
    getApiData();
    getModelData();
  }, []);

  return (
    <div>
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setModelState}
        perantState={modelState}
      />
      <div className="CommonPd">
        <div className="dashTopGrid ">
          <div className="mainCon">
            <Typography variant="h6" className="morningText">
              {t("titles.meeting_decisions")}
            </Typography>
            <ComponentButton
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                history.push("/createMeeting");
              }}
            />
          </div>
          <div>
            {meetingData?.map((op, index) => (
              <Box sx={{ mt: 2, p: 2 }} className="box box1" key={index}>
                <Grid
                  style={{ padding: "10px 10px !important" }}
                  className="perviewBox"
                  container
                  spacing={3}
                >
                  <Grid item xs={6} md={6}>
                    <div className="detailsBox">
                      <div>
                        <Svg type="pdf" />
                      </div>
                      <div>
                        <Typography variant="body2" className="title iconText">
                          {op?.title}
                        </Typography>
                        <Typography variant="body2" className="details">
                          {t("labels.decision_numbers")} : {op?.decision_count}
                        </Typography>
                        <Typography variant="body2" className="details">
                          {t("labels.date")} :{" "}
                          {op.date != null &&
                            `${globalToLocal(
                              op.date,
                              "MMMM DD , YYYY"
                            )} at ${globalToLocal(op.date, "h:mm a")}`}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className=""
                    item
                    xs={6}
                    md={6}
                    sx={{
                      textAlign: "right",
                      gap: "5px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <ComponentButton
                      value={t("labels.preview")}
                      variant={"outlined"}
                      class={"buttonBlank btnRyt"}
                      callback={() => {
                        history.push({
                          pathname: "/meeting-decisons/meeting-detail",
                          state: { id: op.id },
                        });
                      }}
                    />
                    {!op?.validated && (
                      <ComponentButton
                        value={t("labels.share_with")}
                        variant={"contained"}
                        class={"buttonFill"}
                        sx={{ paddingRight: "0px !important" }}
                        callback={() => {
                          setModelState({ ...modelState, id: op.id });
                          setModelData({ ...modalData, model: true });
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingDecisionList;
