import React, { useState, useEffect, useContext } from "react";
import Info from "../../../../assets/images/info.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../../componentGroups/componectModel";
import http from "../../../../api/axios";
import validate from "../../../../Hooks/department_valdaction";
import EastIcon from "@mui/icons-material/East";
import ComponentButton from "../../../componentGroups/componentButton";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

export default function BranchesDep(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { branchId } = useParams();
  const Id = branchId;
  const [depState, setDepState] = useState({});
  const [depData, setDepData] = useState([]);
  const [errors, setErrors] = useState({});
  const handleOpenDept = (id) => {
    history.push(`/branches/branchInfo/${branchId}/edit-department/${id}`);
  };

  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_department",
    data: [
      {
        lable: "titles.department_name",
        field: {
          required: true,
          type: "text",
          placeholder: "Department Name",
        },
        name: "departmentName",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addDept(value);
        },
      },
    ],
  });

  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    setModelData({
      ...modalData,
      model: true,
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.deptCreateValidate(depState);
      setErrors(error);
    }
  }, [depState]);

  const getApiData = async () => {
    // props.setLoader(true);
    let res = await http.getAllDataApi(t, `/api/branch/department/all/${Id}`);
    setDepData(res.data);
    props.setLoader(false);
  };

  useEffect(() => {
    getApiData();
  }, []);

  const addDept = async (obj) => {
    let error = validate.deptCreateValidate(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formData = new FormData();
      formData.append("departmentName", obj.departmentName);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      await http.postApi(t, "/api/department", formData);
      handleCloseModel();
      props.setLoader(false);
      getApiData();
    } else {
      setErrors(error);
    }
  };

  return (
    <div>
      <ComponentModal
        modalData={modalData}
        setPerantState={setDepState}
        perantState={depState}
        errors={errors}
      />
      <div className="CommonPd latest_des_box">
        <Grid className="">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="morningText" variant="h4">
              {t("titles.departments")}{" "}
            </Typography>
            {/* <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                if (getUserHasAccess("department", 55)) {
                  handleModel();
                } else {
                  setAuthorityModal(true);
                }
              }}
            /> */}
          </Grid>
          <Grid
            className="multiColsBreak"
            sx={{
              justifyContent: "",
            }}
          >
            {depData && depData.length != 0 ? (
              depData.map((op) => (
                <Grid item xs={12} sm={12} md={4}>
                  <Card
                    className="DeptContent"
                    // sx={{ minWidth: 10 }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        className="iconText"
                      >
                        {op.departmentName}
                      </Typography>
                      <Typography variant="body2" sx={{ pt: 1 }}>
                        {t("titles.department_code")} : {op.departmentCode}
                      </Typography>
                      <Typography variant="body2" sx={{ pt: 1 }}>
                        {t("titles.employees_number")} : {op.userCount}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "end" }}>
                      <Button
                        className="OpenBtn"
                        size="small"
                        endIcon={
                          <EastIcon
                            sx={{ color: "#2B564C", paddingLeft: "5px" }}
                          />
                        }
                        sx={{ color: "#2B564C" }}
                        onClick={() => handleOpenDept(op.id)}
                      >
                        {t("labels.open")}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <span className="noDataFound">
                There is no data to display here.
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
