import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Collapse,
  IconButton,
} from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import Svg from "../../../../assets/svg/svg";
import { useLocation } from "react-router-dom";
import ComponentModal from "../../../componentGroups/componectModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import People from "../../../../assets/images/people.png";
import { useTranslation } from "react-i18next";
import "../style.css";
import { Menu, MenuItem } from "@mui/material";
import ComponentTextString from "../../../componentGroups/componentTextString";
// import { People } from "@mui/icons-material";
import http from "../../../../api/axios";
import moment from "moment";
import DocumentForm from "./document_files";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";

export default function Documents(props) {
  const { setPathValue } = useContext(BreadCrumbContext);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [menuToggle, SetToggle] = React.useState(false);
  const [data, setdata] = useState([]);
  const [openCommentBox, setOpenCommentBox] = React.useState(false);
  const [expanded, setExpanded] = useState(-1);
  const [inExpanded, setInExpanded] = useState(false);
  const handleCloseCommentBox = () => {
    setOpenCommentBox(!openCommentBox);
  };

  const [modalData, setmodalData] = useState([]);
  const handleCloseModel = () => {
    setCheckboxStatus({ folder: false, files: false });
    setModelState({ folder: "", file: "" });
    setmodalData({
      ...modalData,
      model: false,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkboxStatus, setCheckboxStatus] = useState({
    folder: false,
    files: false,
  });
  const [modelState, setModelState] = useState({ folder: "", file: "" });
  const open = Boolean(anchorEl);

  const getApiData = async () => {
    props.setLoader(true);
    let res = null;
    if (preview == "CompleteDocuments") {
      res = await http.getApi(t, "/api/form_doc/form/showCompeledDocument");
    } else if (preview == "ArchivedDocuments") {
      res = await http.getApi(t, "/api/form_doc/form/achirveDocument");
    } else {
      res = await http.getApi(t, "/api/form_doc/form/incompleteDoc");
    }
    props.setLoader(false);
    setdata(res);
  };
  useEffect(() => {
    getApiData();
  }, []);

  const handleClose = () => {
    setInExpanded(false);
    setExpanded(-1);
  };
  const handleOpen = (index) => {
    setInExpanded(true);
    setExpanded(index);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLangMenu = () => {
    SetToggle(!menuToggle);
  };

  const handleModal = async (op, state) => {
    // setLoader(true);
    console.log(op, state);
    const formData = new FormData();
    formData.append("name", state?.folder);
    formData.append("fileType", op.shareType == 2 ? 1 : 2);
    await http.putApi(
      t,
      op.shareType == 1
        ? `/api/client/renamefoldername/${op?.id}`
        : `/api/client/updatefilename/${op?.id}`,
      formData
    );
    getApiData();
    handleCloseModel();
    // setLoader(false);
  };
  const handleActions = (e, op) => {
    console.log(op);
    setModelState({
      ...modelState,
      folder: op.shareType == 3 ? op.title : op.name,
    });
    setmodalData({
      model: true,
      title: "labels.rename_folder",
      title:
        op.shareType == 1
          ? "labels.rename_folder"
          : op.shareType == 2
          ? "labels.rename_file"
          : "labels.rename_document",
      data: [
        {
          lable:
            op.shareType == 1
              ? "labels.folder_name"
              : op.shareType == 2
              ? "labels.file_name"
              : "labels.document_name",
          field: { type: "text" },
          name: "folder",
        },
      ],
      buttonGroup: [
        {
          value: "labels.discard",
          variant: "outlined",
          class: "buttonBlank outlineBtn",
          callback: () => {
            handleCloseModel();
          },
        },
        {
          value: "labels.save",
          variant: "contained",
          class: "buttonFill FillBtn",
          callback: (state) => {
            handleModal(op, state);
          },
        },
      ],
    });
  };
  let { preview } = props.match.params;

  useEffect(() => {
    let name =
      preview === "CompleteDocuments"
        ? "titles.completed_documents"
        : preview === "ArchivedDocuments"
        ? "titles.achirve_documents"
        : "titles.incompleted_documents";

    setPathValue([
      {
        name: name,
        path: pathname,
      },
    ]);
  }, []);

  return (
    <>
      <div className="DocPage">
        <div className="CommonPd">
          <ComponentModal
            modalData={modalData}
            perantState={modelState}
            setPerantState={setModelState}
          />
          <div className="latest_des_box">
            <Grid className="dashTopGrid documentContainer">
              <div className="topCompDocTtile">
                {preview == "CompleteDocuments" ? (
                  <Typography variant="h4" className="morningText">
                    {t("titles.completed_documents")}
                  </Typography>
                ) : preview == "ArchivedDocuments" ? (
                  <Typography variant="h6" className="morningText">
                    {t("titles.achirve_documents")}
                  </Typography>
                ) : (
                  <Typography variant="h6" className="morningText">
                    {t("titles.incompleted_documents")}
                  </Typography>
                )}

                <div className="ActionTopBtn Flagnav menuLanBtn">
                  <Button
                    className="ActionMain buttonBlank"
                    size="medium"
                    color="inherit"
                    onClick={handleLangMenu}
                  >
                    <a href="javascript:void(0)" className="dropdown-toggle ">
                      Action<span>▼</span>
                    </a>
                  </Button>
                  {menuToggle ? (
                    <ul
                      class="dropdown-menu"
                      onMouseLeave={() => SetToggle(false)}
                    >
                      {/* <li>
                        <div
                          // onClick={() => handleLang("ar", "rtl", 0)}
                          class="rootDiv"
                        >
                          <a>{t("titles.add_to_existing_folder")}</a>
                        </div>
                      </li>
                      <li>
                        <div
                          // onClick={() => handleLang("ar", "rtl", 0)}
                          class="rootDiv"
                        >
                          <a>{t("titles.add_to_new_folder")}</a>
                        </div>
                      </li>
                      <li>
                        <div
                          // onClick={() => handleLang("ar", "rtl", 0)}
                          class="rootDiv"
                        >
                          <a>{t("labels.delete")}</a>
                        </div>
                      </li> */}
                      <li>
                        <div
                          // onClick={() => handleLang("en", "ltr", 1)}
                          onClick={() =>
                            setCheckboxStatus({
                              ...checkboxStatus,
                              folder: true,
                              files: false,
                            })
                          }
                          class="rootDiv"
                        >
                          <a>{t("labels.edit_folder_name")}</a>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() =>
                            setCheckboxStatus({
                              ...checkboxStatus,
                              files: true,
                              folder: false,
                            })
                          }
                          class="rootDiv"
                        >
                          <a>{t("labels.edit_file_name")}</a>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="Flagnav menuLanBtn">
  
                  {menuToggle ? (
                    <ul
                      class="dropdown-menu"
                      // onMouseLeave={() => SetToggle(false)}
                    >
                      <li>
                        <div
                          // onClick={() => handleLang("ar", "rtl", 0)}
                          class="rootDiv"
                        >
                          
                          <a>Arabic</a>
                        </div>
                      </li>
                      <li>
                        <div
                          // onClick={() => handleLang("en", "ltr", 1)}
                          class="rootDiv"
                        >
                        
                          <a>English</a>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div> */}

              {data?.length != 0 ? (
                data?.map((op, index) => (
                  <Box sx={{ mt: 2, p: 2 }} className="box" key={index}>
                    <div className="" container spacing={1}>
                      {op?.shareType == 1 ? (
                        <>
                          <Grid
                            onClick={() =>
                              inExpanded == false
                                ? handleOpen(index)
                                : handleClose()
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <div
                              className="detailsBox arrowINbox"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid sx={{ display: "flex" }}>
                                {checkboxStatus?.folder && (
                                  <span
                                    style={{
                                      marginTop: "20px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      style={{
                                        padding: "15px",
                                        color: "#B7A160",
                                        minWidth: op.minWidth,
                                      }}
                                      onChange={(e) => handleActions(e, op)}
                                    />
                                  </span>
                                )}
                                <div className="folderIvcccIcon">
                                  <Svg type="folder" />
                                  <div className="folderIvccc">
                                    <Typography
                                      variant="body2"
                                      className="title iconText"
                                    >
                                      {op?.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="details"
                                    >
                                      {" "}
                                      {"Files"} :{" "}
                                      {op?.form_documents?.length +
                                        op?.client_files?.length}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="details"
                                    >
                                      {" "}
                                      {t("labels.modified")} :{" "}
                                      {op?.updatedAt &&
                                        `${moment(op.updatedAt).format(
                                          "MMMM DD , YYYY"
                                        )}${" "} at `}
                                      {op?.updatedAt &&
                                        moment(op.updatedAt).format("hh A")}
                                    </Typography>
                                  </div>
                                </div>
                              </Grid>
                              <div className="folderArrow">
                                <span>
                                  {inExpanded == true && index == expanded ? (
                                    <KeyboardArrowUp className="iconArrow" />
                                  ) : (
                                    <KeyboardArrowDownIcon className="iconArrow" />
                                  )}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <div className="interFile">
                              <Collapse
                                in={index == expanded && inExpanded == true}
                                timeout="auto"
                                unmountOnExit
                              >
                                {op?.form_documents &&
                                  op?.form_documents.length != 0 &&
                                  op?.form_documents.map((form) => (
                                    <DocumentForm
                                      handleActions={handleActions}
                                      setmodalData={setmodalData}
                                      modalData={modalData}
                                      modelState={modelState}
                                      setModelState={setModelState}
                                      checkboxStatus={checkboxStatus}
                                      getApiData={getApiData}
                                      op={form}
                                      expanded={expanded}
                                      setLoader={props.setLoader}
                                    />
                                  ))}
                                {op?.client_files &&
                                  op?.client_files.length != 0 &&
                                  op?.client_files.map((file) => (
                                    <DocumentForm
                                      handleActions={handleActions}
                                      setmodalData={setmodalData}
                                      modalData={modalData}
                                      modelState={modelState}
                                      setModelState={setModelState}
                                      checkboxStatus={checkboxStatus}
                                      getApiData={getApiData}
                                      op={file}
                                      expanded={expanded}
                                      setLoader={props.setLoader}
                                    />
                                  ))}
                              </Collapse>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} md={12} lg={12}>
                          <DocumentForm
                            handleActions={handleActions}
                            setmodalData={setmodalData}
                            modalData={modalData}
                            modelState={modelState}
                            setModelState={setModelState}
                            checkboxStatus={checkboxStatus}
                            getApiData={getApiData}
                            op={op}
                            setLoader={props.setLoader}
                          />
                        </Grid>
                      )}
                    </div>
                  </Box>
                ))
              ) : (
                <div class="noDataFound h-full">{t("labels.no_data")}</div>
              )}
            </Grid>
          </div>
        </div>
      </div>

      {/* <div className="CommonPd latest_des_box">
    <Grid className="dashTopGrid">
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "0 !important",
        }}
      >
        <Grid className="gridLeft">
          <Typography className="DashTitle" variant="h4">
            {t("partner.bod_decision")}
          </Typography>
        </Grid>
        <Grid className="gridLeftbtn">
          <ComponentButton
            value={t("labels.view_all")}
            variant={"outlined"}
            class={"buttonBlank"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "0 !important",
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <div>
            {data.map((op, index) => (
              <Box sx={{ mt: 2, p: 2 }} className="box" key={index}>
                <Grid
                  style={{ padding: "10px 10px !important" }}
                  className="perviewBox"
                  container
                  spacing={3}
                >
                  <Grid item xs={6} md={12}>
                    <div className="detailsBox">
                      <div>
                        <Typography variant="h5" className="Yellowtext">
                          {t("lorem.lorem6")}
                        </Typography>
                        <Typography variant="body2" className="details">
                          {t("lorem.lorem3")}
                          <br></br> {t("lorem.lorem4")}
                          <br></br> {t("lorem.lorem5")}
                        </Typography>
                      </div>
                    </div>
                    <div className="commentSection">
                      <div className="commentBox">
                        <img src={People.png} />
                        <span>Mohamed commented on this Decision and
                         <small>3 People more</small></span>
                      </div>
                      <ComponentButton
                        value={t("labels.add_comment")}
                        variant={"contained"}
                        class="buttonFill FillBtn"
                        callback={() => {
                          handleCloseCommentBox();
                        }}
                      />
                    </div>
                    {openCommentBox && (
                      <div className="commentBox addComment">
                        <ComponentTextString
                          placeholder={t("labels.write_comment")}
                          class="inputComment"
                        />
                        <ComponentButton
                          value={t("labels.add_comment")}
                          variant={"contained"}
                          class="buttonFill FillBtn"
                          callback={() => {
                            handleCloseCommentBox();
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </div>
        </Grid>
      </Grid>
    </Grid>
  </div> */}
    </>
  );
}
