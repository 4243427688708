import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function ComponentCKEditer(props) {
  return (
    <CKEditor
      editor={ClassicEditor}
      height="100"
      data={props?.value ? props?.value : `<p><p>`}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
      }}
      onChange={(event, editor) => {
        props?.callback(editor.getData());
      }}
      onBlur={(event, editor) => {}}
      onFocus={(event, editor) => {}}
      disable={props?.disable}
    />
  );
}
