/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import http from "../../../../api/axios";
import validate from "../../../../Hooks/department_valdaction";
import ComponentButton from "../../../componentGroups/componentButton";
import { Grid, Typography, TextField } from "@mui/material";
import moment from "moment";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import ComponentTextString from "../../../componentGroups/componentTextString";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import LoadingContext from "../../../../context/LoaderContext";
import { getDuration } from "../../../../utils/common";
import useFormatTime from "../../../../Hooks/useFormatTime";

export default function BranchWorkingHours({ id }) {
  const { setLoader } = useContext(LoadingContext);
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t } = useTranslation();
  const { globalToLocal, localToGlobal } = useFormatTime();
  const [enableEdit, setEnableEdit] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [workingHour, setWorkingHour] = useState([
    {
      startTime: "",
      endTime: "",
      duration: "",
    },
  ]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.deptCreateValidate("");
      setErrors(error);
    }
  }, []);

  const getWorkingHours = async () => {
    setLoader(true);
    let res = await http.getApi(t, `/api/branch/branch-working-hours/${id}`);
    setLoader(false);
    const data = res?.map((item) => {
      return {
        ...item,
        startTime: globalToLocal(item?.startTime),
        endTime: globalToLocal(item?.endTime),
      };
    });
    setWorkingHour(data);
    setInitialData(data);
  };

  useEffect(() => {
    getWorkingHours();
  }, []);

  console.log("here", workingHour);

  return (
    <div>
      <div className="CommonPd latest_des_box">
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="morningText" variant="h4">
              {t("labels.working_hours")}{" "}
            </Typography>
          </Grid>
          <div
            style={{ paddingLeft: "50px", marginTop: "20px", width: "100%" }}
          >
            <Grid
              className="AddEmpGenInfo"
              container
              rowSpacing={2}
              columnSpacing={2}
            >
              {workingHour.length !== 0 ? (
                workingHour?.map((item) => {
                  return (
                    <>
                      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                        <Typography variant="body1" className="customLabel">
                          {t("From")}
                        </Typography>
                      </Grid>
                      <Grid className="CustomWidth" item xs={12} md={4}>
                        <ComponentTextString
                          disable={true}
                          class={"custonInput"}
                          placeholder=""
                          name="duration"
                          value={item.from}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sx={{ paddingTop: 0 }}
                        style={{ textAlign: "center" }}
                      >
                        <Typography variant="body1" className="customLabel">
                          {t("To")}
                        </Typography>
                      </Grid>
                      <Grid className="CustomWidth" item xs={12} md={4}>
                        <ComponentTextString
                          disable={true}
                          class={"custonInput"}
                          placeholder=""
                          name="duration"
                          value={item.to}
                        />{" "}
                      </Grid>
                      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                        <Typography variant="body1" className="customLabel">
                          {t("labels.start_time")}
                        </Typography>
                      </Grid>
                      <Grid className="CustomWidth" item xs={11} md={10}>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              disabled={!enableEdit}
                              className="timePicker"
                              value={
                                item.startTime ? moment(item.startTime) : null
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                        <Typography variant="body1" className="customLabel">
                          {t("labels.end_time")}
                        </Typography>
                      </Grid>
                      <Grid className="CustomWidth" item xs={11} md={10}>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              disabled={!enableEdit}
                              className="timePicker"
                              value={item.endTime ? moment(item.endTime) : null}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                        <Typography variant="body1" className="customLabel">
                          {t("labels.branch_working_hours")}
                        </Typography>
                      </Grid>
                      <Grid className="CustomWidth" item xs={11} md={10}>
                        <ComponentTextString
                          disable={true}
                          class={"custonInput"}
                          placeholder=""
                          name="duration"
                          value={item.duration}
                        />
                      </Grid>
                    </>
                  );
                })
              ) : (
                <span className="noDataFound">
                  There is no data to display here
                </span>
              )}
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
}
