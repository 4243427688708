/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";
import GenericModal from "../../../componentGroups/genericModal";
import ComponentButton from "../../../componentGroups/componentButton";
import useFormatTime from "../../../../Hooks/useFormatTime";

export default function WorkingSchedule() {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const { globalToLocal } = useFormatTime();
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [attendance, setAttendance] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [modalData, setModalData] = useState({
    open: false,
    data: {},
  });

  const [column, setColumn] = useState([
    {
      lable: "labels.branch",
      name: "branchName",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.from",
      name: "from",
      hide: true,
    },
    {
      lable: "labels.to",
      name: "to",
      hide: true,
    },
    {
      lable: "labels.work_from",
      name: "startTime",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.work_to",
      name: "endTime",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.working_hours",
      name: "duration",
      align: "left",
      hide: true,
    },
  ]);

  const [data, setData] = useState({
    title: "routes.working_schedule",
    page: "Working Schedule",
    hideSearch: true,
    infoIcon: "edit",
    infoURL: "/settings/working-schedule/edit-working-hour",
    hasVariable: true,
    deleteCallback: (row) => {
      if (getUserHasAccess("settings", 192)) {
        setModalData({
          open: true,
          data: row,
        });
      } else {
        setAuthorityModal(true);
      }
    },
    buttonGroup: [
      {
        type: "add",
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        class: "buttonFill",
        variant: "contained",
        callback: () => {
          if (getUserHasAccess("settings", 190)) {
            history.push("/settings/working-schedule/add-working-hour");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/branch/working-hours/all?page=${pageIndex + 1}&size=${pageSize}`
    );
    const data = res?.data?.map((item) => {
      return {
        ...item,
        startTime: globalToLocal(item.startTime, "h:mm:a"),
        endTime: globalToLocal(item.endTime, "h:mm:a"),
      };
    });
    setTotalrecords(res?.data_count);
    setAttendance(data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size]);

  const handleDelete = async () => {
    setLoader(true);
    const api = `/api/branch/working-hours/${modalData?.data?.id}`;
    await http.deleteApi(t, api);
    setLoader(false);
    handleClose();
  };

  const handleClose = () => {
    setModalData({
      open: false,
      data: {},
    });
    getApiData();
    setPage(INITIAL_PAGEINDEX);
    setSize(INITIAL_PAGESIZE);
  };

  return (
    <>
      <GenericModal
        title={"titles.delete_working_schedule"}
        open={modalData.open}
      >
        <div className="scroll-auto ModalContent" style={{ minHeight: "80px" }}>
          <Grid container md={12} className="modalGroup">
            <Grid item sm={12}>
              <Typography textAlign={"center"}>
                {t("titles.sure_delete_data_from")}
                {t("labels.branch_working_hours")} {t("titles.database")}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => handleClose()}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.delete")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => handleDelete()}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      <ComponentTable
        rows={attendance}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
      />
    </>
  );
}
