import React, { useState, useEffect, useContext } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "../style.css";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ComponentModal from "../../../componentGroups/componectModel";
import FileUpload from "../../employees/addEmployees/fileUpload";
import http from "../../../../api/axios";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import Comments from "../../../componentGroups/Comments";

const Attachments = ({ clientState, setClientState, setLoader }) => {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [errors, setErrors] = useState({});
  const [addedCategory, setAddedCategory] = useState({
    name: "",
  });

  const [modalData, setModelData] = useState({
    title: "labels.add_new_field",
    model: false,
    data: [
      {
        lable: "labels.name",
        field: { type: "text", required: false },
        name: "name",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleClose();
        },
      },
      {
        value: "labels.save",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val, empData) => {
          handleSave(val, empData);
        },
      },
    ],
  });

  /**
   * adds new category to emp doccuments
   * @param {*} val
   * @param {*} empData // empstate is passed as a callback value
   */
  const handleSave = (val, empData) => {
    const documents = [...empData.documents];
    let categoryNames = documents?.map((item) => item?.category);
    categoryNames = [...categoryNames, "contract", "identity"];
    const categoryName = val.name.trim();
    if (categoryName !== "") {
      if (!categoryNames.includes(categoryName.toLowerCase())) {
        setClientState({
          ...empData,
          documents: [
            ...documents,
            {
              category: categoryName,
              name: "",
              type: "",
              path: "",
              file: "",
            },
          ],
        });
        handleClose();
        setAddedCategory({ name: "" });
      } else {
        setErrors({ name: "errors.attachment_category_present" });
      }
    } else {
      setErrors({ name: "errors.enter_attachment_category" });
    }
  };

  const handleClose = () => {
    setModelData({
      ...modalData,
      model: false,
    });
  };

  const handleOpen = () => {
    if (getUserHasAccess("client", 101)) {
      setModelData({
        ...modalData,
        model: true,
      });
    } else {
      setAuthorityModal(true);
    }
  };

  /**
   * update file data
   * @param {*} event
   * @param {*} category
   */
  const handleFileChange = (event, category) => {
    const selectedFile = event.target.files[0];
    const documents = [...clientState.documents];
    documents.forEach((item) => {
      if (item.category === category) {
        item.file = selectedFile;
        item.name = selectedFile.name;
        item.type = selectedFile.type;
      }
    });
    setClientState({ ...clientState, documents: documents });
  };

  /**
   * remove file
   * @param {string} category
   */
  const removeFile = (category) => {
    if (getUserHasAccess("client", 101)) {
      const documents = [...clientState.documents];
      const updated = documents.map((item) => {
        if (item.category === category) {
          if (!!item?.id) {
            http.deleteFileApi(t, `/api/client/document/delete/${item.id}`);
          }
          return {
            category: category,
            name: "",
            type: "",
            path: "",
            file: "",
          };
        } else {
          return item;
        }
      });
      setClientState({ ...clientState, documents: updated });
    } else {
      setAuthorityModal(true);
    }
  };

  /**
   * remove file type
   * @param {*} category
   */
  const removeDocuments = (category) => {
    if (getUserHasAccess("client", 101)) {
      const documents = [...clientState.documents];
      const updated = documents.filter((item) => item.category !== category);
      setClientState({ ...clientState, documents: updated });
    } else {
      setAuthorityModal(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      if (
        addedCategory?.name?.trim() === "" ||
        addedCategory?.name === undefined
      ) {
        setErrors({ name: "errors.enter_attachment_category" });
      } else {
        setErrors({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedCategory?.name]);

  return (
    <div>
      <ComponentModal
        modalData={modalData}
        setPerantState={setAddedCategory}
        perantState={addedCategory}
        optionalVal={clientState}
        errors={errors}
        MHeight={"80px"}
      />
      {clientState?.documents &&
        clientState?.documents?.map((item) => (
          <div className="GernalForm">
            <form>
              <div className="genralinfoContant fileContainer AddEmpGenInfo  empfileview">
                <div className="flex-center1">
                  <Typography variant="body1" className="customLabel">
                    {t(`labels.${item.category.toUpperCase()}`)}
                  </Typography>
                  {item.category !== "contract" &&
                    item.category !== "identity" && (
                      <DeleteIcon
                        fontSize="10px"
                        style={{ marginLeft: "8px" }}
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() => removeDocuments(item.category)}
                      />
                    )}
                </div>
                <div
                  className="attachment"
                  style={{
                    justifyContent: !!item?.name ? "space-between" : "flex-end",
                  }}
                >
                  {!!item?.name && (
                    <Typography
                      variant="div"
                      className="flex-center1"
                      style={{ marginLeft: "8px" }}
                    >
                      {" "}
                      {item.name}{" "}
                      <CancelRoundedIcon
                        fontSize="10px"
                        style={{ marginLeft: "8px" }}
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() => removeFile(item.category)}
                      />
                    </Typography>
                  )}
                  <FileUpload
                    item={item}
                    buttonLabel={t("labels.upload_file")}
                    handleChange={(e) => handleFileChange(e, item.category)}
                    module="client"
                    authId={101}
                  />
                </div>
              </div>
            </form>
          </div>
        ))}

      <div class="Text-Right">
        <button
          variant="contained"
          className=" addMoreFiles"
          onClick={handleOpen}
        >
          <AddCircleOutlineIcon /> {t("labels.add_new_field")}
        </button>
      </div>
      <Comments setLoader={setLoader} viewId={`ATTCH-${clientState?.contractNumber}`} />
    </div>
  );
};

export default Attachments;
