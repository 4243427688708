const authorities = {
  form: {
    operations: [
      {
        id: 41,
        name: "Preview All forms",
      },
      {
        id: 42,
        name: "Creating Forms Category",
      },
      {
        id: 43,
        name: "Form Document Edit",
      },
      {
        id: 44,
        name: "Form Document Create",
      },
      {
        id: 45,
        name: "Form Document Preview All ",
      },
      {
        id: 46,
        name: "Form Document Preview According Department ",
      },
      // {
      //   id: 47,
      //   name: "Form Document Print ",
      // },
      {
        id: 48,
        name: "Form Document completed view ",
      },
      {
        id: 49,
        name: "Form Document Incompleted view ",
      },
      // {
      //   id: 50,
      //   name: "Form Document Download ",
      // },
      {
        id: 51,
        name: "Form Document Archive view ",
      },
      {
        id: 52,
        name: "Form Document Comment ",
      },
      {
        id: 53,
        name: "Form Document View Comments",
      },
    ],
  },
  department: {
    operations: [
      {
        id: 54,
        name: "Department View",
      },
      {
        id: 55,
        name: "Department Create",
      },
      {
        id: 56,
        name: "Add/Edit Department Authorities",
      },
      // {
      //   id: 57,
      //   name: "Edit Department Authorities",
      // },
      {
        id: 58,
        name: "Add Department Employee",
      },
      {
        id: 59,
        name: "Edit Department Employee",
      },
      // {
      //   id: 60,
      //   name: "Department Employee profile View",
      // },
    ],
  },
  branch: {
    operations: [
      {
        id: 61,
        name: "Branch Create",
      },
      {
        id: 62,
        name: "Branch Edit",
      },
      {
        id: 64,
        name: "Branch View",
      },
      {
        id: 65,
        name: "Add Branch Employee",
      },
      {
        id: 66,
        name: "Edit Branch Employee",
      },
      {
        id: 67,
        name: "Branch Delete",
      },
      {
        id: 68,
        name: "Add Branch Client",
      },
      {
        id: 69,
        name: "Edit Branch Client",
      },
    ],
  },
  employee: {
    operations: [
      {
        id: 70,
        name: "Employee View",
      },
      {
        id: 71,
        name: "Employee Create",
      },
      {
        id: 72,
        name: "Employee Edit",
      },
      {
        id: 73,
        name: "Employee Delete",
      },
      {
        id: 75,
        name: "Manage Credentials Of Employee",
      },
      {
        id: 76,
        name: "Add Employee Documnets",
      },
      {
        id: 77,
        name: "Activate /Deactivate Employee ",
      },
      {
        id: 79,
        name: "Add/Edit Employee Authorities ",
      },
      {
        id: 81,
        name: "Edit Employee Department ",
      },
      {
        id: 82,
        name: "Edit Employee Branch ",
      },
      {
        id: 166,
        name: "Edit Employee Payroll ",
      },
      {
        id: 167,
        name: "Edit Employee Attendence ",
      },
      {
        id: 168,
        name: "Edit Employee Leave Balance ",
      },
    ],
  },
  coordinator: {
    operations: [
      {
        id: 83,
        name: "Coordinator View",
      },
      {
        id: 84,
        name: "Coordinator Create",
      },
      {
        id: 85,
        name: "Coordinator Edit",
      },
      {
        id: 86,
        name: "Coordinator Delete",
      },
    ],
  },
  client: {
    operations: [
      {
        id: 87,
        name: "Client View",
      },
      {
        id: 88,
        name: "Client Create",
      },
      {
        id: 89,
        name: "Client Edit",
      },
      {
        id: 90,
        name: "Client Delete",
      },
      {
        id: 91,
        name: "Client Create folder",
      },
      {
        id: 92,
        name: "Client Create label",
      },
      // {
      //   id: 93,
      //   name: "Client View Personal View", change
      // },
      {
        id: 94,
        name: "Client Edit Folder",
      },
      {
        id: 95,
        name: "Client Upload File",
      },
      {
        id: 96,
        name: "Client Generated Document",
      },
      // {
      //   id: 97,
      //   name: "Client Choose Documents templates",
      // },
      // {
      //   id: 98,
      //   name: "Add Client Reminders File", //To be discussed need to be made optional
      // },
      // {
      //   id: 99,
      //   name: "Add Client Matters File", //To be discussed need to be made optional
      // },
      // {
      //   id: 100,
      //   name: "Add Client Financials",
      // },
      {
        id: 101,
        name: "Client Upload Attachment File",
      },
    ],
  },
  meeting: {
    operations: [
      {
        id: 102,
        name: "Meeting View",
      },
      {
        id: 103,
        name: "Meeting Create",
      },
      {
        id: 104,
        name: "Meeting Edit",
      },
      {
        id: 105,
        name: "Meeting View Details",
      },
      {
        id: 106,
        name: "Meeting Comment",
      },
      {
        id: 108,
        name: "Meeting document",
      },

      // {
      //   id: 110,
      //   name: "Add Meeting reminder", //inside meeding
      // },
      // {
      //   id: 111,
      //   name: "Meeting Set Reminder Details", //to be discussed
      // },

      {
        id: 112,
        name: "Meeting Delete",
      },
      {
        id: 169,
        name: "Add Meeting Mins",
      },
    ],
  },
  decision: {
    operations: [
      {
        id: 113,
        name: "Decision View",
      },
      {
        id: 114,
        name: "Decision Create",
      },
      {
        id: 115,
        name: "Decision Edit",
      },
      {
        id: 116,
        name: "Decision Delete",
      },
    ],
  },
  task: {
    operations: [
      {
        id: 117,
        name: "Task View",
      },
      {
        id: 118,
        name: "Task Create",
      },
      {
        id: 119,
        name: "Task Edit",
      },
      {
        id: 120,
        name: "Task Delete",
      },
      {
        id: 121,
        name: "Task Assign",
      },
      {
        id: 122,
        name: "Set Task Priority status due date", // need to be brolen down and then changes respectively
      },
      {
        id: 123,
        name: "Task document",
      },
      {
        id: 133,
        name: "View All Task",
      },
      {
        id: 134,
        name: "View Send Task",
      },
      {
        id: 135,
        name: "View My Task",
      },
      {
        id: 136,
        name: "View Late Task",
      },
      // {
      //   id: 124,
      //   name: "Task Add comment ", //to be discussed
      // },
      // {
      //   id: 125,
      //   name: "Task view comment ", //to be discussed
      // },
    ],
  },
  project: {
    operations: [
      {
        id: 126,
        name: "Project View",
      },
      {
        id: 127,
        name: "Project Assign",
      },
      {
        id: 129,
        name: "Project Create",
      },
      {
        id: 130,
        name: "Project Edit",
      },
      {
        id: 131,
        name: "Project Delete",
      },
    ],
  },
  consultation: {
    operations: [
      { id: 149, group: "consultation", name: "View Consultation" },
      { id: 170, group: "consultation", name: "Add Consultation" },
      { id: 171, group: "consultation", name: "View Consultation Info" },
      { id: 220, group: "consultation", name: "Archive Consultation" },
    ],
  },
  vacation: {
    operations: [
      { id: 150, group: "vacation", name: "View Vacations" },
      { id: 151, group: "vacation", name: "Create Vacations" },
    ],
  },
  promotion: {
    operations: [
      { id: 152, group: "promotion", name: "View Promotions" },
      { id: 153, group: "promotion", name: "Create Promotions" },
      { id: 154, group: "promotion", name: "Delete Promotions" },
    ],
  },
  requests: {
    operations: [
      { id: 155, group: "requests", name: "View Requests" },
      { id: 156, group: "requests", name: "Create Requests" },
    ],
  },
  reference: {
    operations: [
      { id: 158, group: "reference", name: "View Reference" },
      { id: 159, group: "reference", name: "Create Reference Category" },
      { id: 161, group: "reference", name: "Add Reference" },
      { id: 162, group: "reference", name: "Add Suggestion" },
      { id: 163, group: "reference", name: "View Suggestion Details" },
      { id: 164, group: "reference", name: "Approve And Reject Suggestions" },
      { id: 215, group: "reference", name: "Delete Reference" },
      { id: 216, group: "reference", name: "Edit Reference" },
    ],
  },
  reports: {
    operations: [{ id: 165, group: "reports", name: "View Reports" }],
  },
  other: {
    operations: [
      { id: 139, group: "other", name: "Meeting Decisions" },
      { id: 140, group: "other", name: "Partner Board" },
      { id: 141, group: "other", name: "Attorneys" },
      { id: 142, group: "other", name: "Executive Director" },
      { id: 143, group: "other", name: "Documents(Meeting)" },
      { id: 144, group: "other", name: "Executive Director Meeting" },
      { id: 145, group: "other", name: "Bod Decision" },
      { id: 146, group: "other", name: "Voting Validation" },
      { id: 157, group: "other", name: "Add DropDown Options" },
    ],
  },
  settings: {
    operations: [
      { id: 175, group: "settings", name: "View Settings" },
      { id: 176, group: "settings", name: "Add Global Field" },
      { id: 177, group: "settings", name: "Edit Global Field Option" },
      { id: 178, group: "settings", name: "Delete Global Field Option" },
      { id: 179, group: "settings", name: "View Employee Performance Scores" },
      { id: 180, group: "settings", name: "Add Attendance Score" },
      { id: 181, group: "settings", name: "Edit Attendance Score" },
      { id: 182, group: "settings", name: "Delete Attendance Score" },
      { id: 183, group: "settings", name: "Add Task Score" },
      { id: 184, group: "settings", name: "Edit Task Score" },
      { id: 185, group: "settings", name: "Delete Task Score" },
      { id: 186, group: "settings", name: "View Penalties And Bonus" },
      { id: 187, group: "settings", name: "Add Penalty Type Name" },
      { id: 188, group: "settings", name: "Edit Penalty Type Name" },
      { id: 189, group: "settings", name: "Delete Penalty Type Name" },
      { id: 190, group: "settings", name: "Add Bonus Type Name" },
      { id: 191, group: "settings", name: "Edit Bonus Type Name" },
      { id: 192, group: "settings", name: "Delete Bonus Type Name" },
    ],
  },
  // here you add the group name and its options
  allocation: {
    operations: [
      { id: 193, group: "allocation", name: "View Allocations" },
      { id: 194, group: "allocation", name: "Add Allocation" },
      { id: 195, group: "allocation", name: "update status" },

    ],
  },

  loan: {
    operations: [
      { id: 196, group: "loan", name: "View Loan" },
      { id: 197, group: "loan", name: "View All Loans" },
      { id: 198, group: "loan", name: "Add Loan" },
      { id: 199, group: "loan", name: "View Loan Detail" },
      { id: 200, group: "loan", name: "Approve Loan Request" },
    ],
  },
  vacancies: {
    operations: [
      { id: 201, group: "vacancy", name: "View All Vacancies" },
      { id: 202, group: "vacancy", name: "Add Vacancies" },
      { id: 203, group: "vacancy", name: "Add Candidates" },
      { id: 204, group: "vacancy", name: "View Vacancy Details" },
      { id: 205, group: "vacancy", name: "View Candidate Details" },

    ],
  },
  broadcast: {
    operations: [
      { id: 206, group: "broadcast", name: "View Broadcasts" },
      { id: 207, group: "broadcast", name: "Add Broadcast" },
      { id: 208, group: "broadcast", name: "View Broadcast Details" },
    ],
  },
  matters: {
    operations: [
      { id: 210, group: "matters", name: "View Matters" },
      { id: 211, group: "matters", name: "Add Matter" },
      { id: 212, group: "matters", name: "Edit Matter" },
    ],
  },
};

// here you add the main group selection and the default value from its options (its mainly the view all)
export const defaultModulevalues = {
  form: 41,
  department: 54,
  branch: 64,
  employee: 70,
  coordinator: 83,
  client: 87,
  meeting: 102,
  decision: 113,
  task: 117,
  project: 126,
  requests: 155,
  promotion: 152,
  vacation: 150,
  consultation: 149,
  reference: 158,
  reports: 165,
  settings: 175,
  allocation: 193,
  loan: 196,
  vacancy: 201,
  broadcast: 206,
  matters: 210,
};

/**
 * checks weather user has access and the operation also exists
 * @param {*} module
 * @param {*} userAuthority
 * @param {*} operationId
 * @returns true if user has access
 */
export const getUserHasAccess = (module, operationId) => {
  const userAuthority = JSON.parse(localStorage.getItem("authority")) || [];
  let has_access = false;
  const authOperationIds = authorities[module].operations?.map(
    (item) => item.id
  );
  if (
    authOperationIds?.includes(operationId) &&
    userAuthority?.includes(operationId)
  ) {
    has_access = true;
  }
  return has_access;
};

/**
 *
 * @returns true if user has authority for that component
 */
export const hasAuthority = (authorities = []) => {
  const user_authority = JSON.parse(localStorage.getItem("authority")) || [];
  let has_access = false;
  user_authority.forEach((element) => {
    if (authorities?.includes(element)) {
      has_access = true;
      return;
    }
  });

  if (has_access) {
    return true;
  }
  return false;
};

export const gotoDetails = (history, id = "", type) => {
  switch (type) {
    case "Department":
      !!id
        ? history.push({
          pathname: `/add_department/${id}`,
          state: { id: id },
        })
        : history.push("/departments");
      break;
    case "Client":
      !!id
        ? history.push({
          pathname: `/clients/edit-client/${id}`,
          state: { id: id },
        })
        : history.push("/clients");
      break;
    case "Task":
      !!id
        ? history.push({
          pathname: "/taskInfo",
          state: { id: id },
        })
        : history.push("/tasks");

      break;
    case "Branch":
      !!id
        ? history.push({
          pathname: `/branches/branchInfo/${id}`,
          state: { id: id },
        })
        : history.push("/branches");
      break;
    case "Users":
      !!id
        ? history.push({
          pathname: `/addEmployee/${id}`,
          state: { id: id },
        })
        : history.push("/employees");
      break;
    case "Meeting":
    case "Create a Meeting":
      !!id
        ? history.push({
          pathname: "/meetingDetails",
          state: { id: id },
        })
        : history.push("/createMeeting");
      break;
    case "Dashboard":
      history.push("/");
      break;
    case "Attroneys":
      history.push("/attroneys");
      break;
    case "Company Decisions":
      history.push("/company-decision");
      break;
    case "Forms & Documents":
      history.push("/formDocuments");
      break;
    case "Coordinators":
      history.push("/coordinators");
      break;
    case "Partners Meeting":
      history.push("/partnerMeetings");
      break;
    case "BOD Decisions":
      history.push("/bodDecision");
      break;
    case "Expenses and profits":
      history.push("/");
      break;
    case "Requests and Assigns":
      history.push("/requestAssign");
      break;
    case "Documents":
      history.push("/partnerBoard/documents");
      break;
    case "Calendar":
      history.push("/");
      break;
    case "Meeting Decision":
      history.push("/meeting-decisons");
      break;
    case "Voting & Validation":
      history.push("/votingValidations");
      break;
    case "References":
      history.push("/profile-technical-references");
      break;
    case "Projects":
      history.push("/projects");
      break;
    case "Academic Researcher":
      history.push("");
      break;
    case "Vacations":
      history.push("/vactions");
      break;
    case "Promotions":
      history.push("/promotions");
      break;
    case "Ask for a consultation":
      history.push("/ask-consultation");
      break;
    case "Executive Director":
      history.push("/executive_director");
      break;
    case "Loan":
      history.push("/loan");
      break;
    case "Settings":
      history.push("/settings");
      break;
    case "Broadcast":
      history.push("/broadcasts");
      break;
    case "Allocation":
      history.push("/allocation");
      break;
    case "Recruitments":
      history.push("/recruitments");
      break;
    default:
      return;
  }
};

export const getDuration = (startTime, endTime) => {
  const durationMinutes = endTime.diff(startTime, "minutes");
  const durationHours = Math.floor(durationMinutes / 60);
  const remainingMinutes = durationMinutes % 60;

  return `${durationHours} hr ${remainingMinutes} min`;
};
