import React, { useState, useEffect, useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import DocumentDetails from "./documentDetails";
import http from "../../../../../api/axios";
import { useTranslation } from "react-i18next";
import LoadingContext from "../../../../../context/LoaderContext";

const DocAccordion = ({
  data,
  dropDownOptions,
  loadDropdownOptions,
  clientId,
  fileAddedToFolder,
}) => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const [folders, setfolders] = useState([]);
  const [folderToview, setfoldersToView] = useState([]);

  /**
   * returns folder associated with selected row
   * @param {*} rowId
   */
  const getFolders = async (rowId = data?.id) => {
    setLoader(true);
    let res = await http.getApi(t, `/api/client/findallfolderbyrows/${rowId}`);
    setLoader(false);
    if (res?.length && !!res) {
      setfolders(res);
    } else {
      setfolders([]);
    }
  };

  useEffect(() => {
    if (showAll) {
      setfoldersToView(folders);
    } else {
      if (folders?.length > 4) {
        let sliced = folders?.slice(0, 4);
        setfoldersToView(sliced);
      } else {
        setfoldersToView(folders);
      }
    }
  }, [showAll, folders]);

  useEffect(() => {
    if (!!data?.id) {
      getFolders(data?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id, dropDownOptions?.docRows]);

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          className="clientInfoDocAccodian"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography variant="h4" sx={{ width: "33%", flexShrink: 0 }}>
            {data?.name}
          </Typography>
          <div className="documentrow-titleDetail">
            <Typography variant="h4" sx={{ color: "text.secondary" }}>
              {t("labels.folder_count")} :{data?.folder_count}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {t("labels.created_by")}:{data?.created_by}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ paddingTop: "25px" }}>
            {folderToview?.map((item) => {
              return (
                <Grid item xs={6} md={6}>
                  <DocumentDetails
                    folder={item}
                    setLoader={setLoader}
                    loadDropdownOptions={loadDropdownOptions}
                    clientId={clientId}
                    getFolders={getFolders}
                    fileAddedToFolder={fileAddedToFolder}
                  />
                </Grid>
              );
            })}
            {folders?.length > 4 && (
              <Grid item xs={12}>
                <div onClick={() => setShowAll(!showAll)}>
                  <button variant="contained" className="centerBtn newBtn1 new">
                    {showAll ? t("labels.show_less") : t("labels.view_all")}
                  </button>
                </div>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DocAccordion;
