import React from "react";
import CompleteAndIncompleteDocumentViews from "../LegalSecretary/Common/CompleteAndIncompleteDocumentViews";
import Schedule from "../LegalSecretary/Common/Schedule";
import AssignedProjects from "./AssignedProjects";
import Greeting from "../DashBoard/Greeting";
import DashBoardPendingRequests from "../DashBoard/DashBoardPendingRequests";
import DashBoardAttendanceRequest from "../DashBoard/DashBoardAttendanceRequest";

export default function AcademicResearcherDashboard({
  isClassAteorney = false,
}) {
  return (
    <div>
      <Greeting />
      <DashBoardPendingRequests />
      <DashBoardAttendanceRequest />
      <Schedule
        endPoint={"/api/meeting/upcoming/list"}
        disableAddNew={true}
        enableReminderPopup={true}
      />
      {isClassAteorney && (
        <AssignedProjects endPoint={"/api/project/assigned-projects"} />
      )}
      <CompleteAndIncompleteDocumentViews
        endPoint={"/api/form_doc/form/incompleteDoc"}
        title={"titles.incompleted_documents"}
      />
      <CompleteAndIncompleteDocumentViews
        endPoint={
          isClassAteorney
            ? "/api/form_doc/form/showCompeledDocument"
            : "/api/form_doc/form/showCompeledDocument"
        }
        title={
          isClassAteorney
            ? "labels.approved_documents"
            : "titles.completed_documents"
        }
        enableSubmitTo={isClassAteorney ? false : true}
      />
    </div>
  );
}
