import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import http from "../../../../api/axios";
import auth from "../../../../services/auth";
import { useTranslation } from "react-i18next";
import SelectedEmployeeTask from "./selectedEmployeeTask";
import SelectedEmployeeAttendance from "./selectedEmployeeAttendance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SelectedEmployeeReport() {
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedEmp } = useParams();
  selectedEmp && auth.login("selectedEmployeeReportId", selectedEmp);

  const id = auth.getUser("selectedEmployeeReportId");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={9}>
            <Typography variant="h6" className="morningText">
              {t("titles.employees")}
            </Typography>
          </Grid>
        </Grid>
        <Box
          className="TaskTab TabActive"
          sx={{
            borderBottom: 1,
            borderColor: "rgba(183, 161, 96, 0.26)",
            pt: 6,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab className="iconText" label={t("labels.tasks")} value={0} />
            <Tab
              className="iconText"
              label={t("labels.attendance")}
              value={1}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SelectedEmployeeTask id={id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SelectedEmployeeAttendance id={id} />
        </TabPanel>
      </Grid>
    </div>
  );
}
