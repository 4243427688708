/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import ComponentTable from "../../../componentGroups/componentTable";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography } from "@mui/material";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";
import GenericModal from "../../../componentGroups/genericModal";
import ComponentButton from "../../../componentGroups/componentButton";

export default function AttendanceScore() {
  const { t } = useTranslation();
  const history = useHistory();
  const { type } = useParams();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const { setPathValue } = useContext(BreadCrumbContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [attendance, setAttendance] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [modalData, setModalData] = useState({
    open: false,
    data: {},
  });

  const PAGE_VALUES = {
    early: {
      title: t("routes.early_attendance_score"),
      api: "/api/setting/early-attendance-score/findAll",
      path: "/settings/employee-performance/atendance-score/early",
      deleteApi: "/api/setting/early-attendance-score",
    },
    late: {
      title: t("routes.late_attendance_score"),
      api: "/api/setting/late-attendance-score/findAll",
      path: "/settings/employee-performance/atendance-score/late",
      deleteApi: "/api/setting/late-attendance-score",
    },
  };

  const [column, setColumn] = useState([
    {
      lable: "labels.department",
      name: "departmentName",
      hide: true,
    },
    {
      lable: "labels.job_position",
      name: "jobTitle",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.redundancy",
      name: "redundancy",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.score",
      name: "score",
      align: "left",
      hide: true,
    },
  ]);

  const [data, setData] = useState({
    title: PAGE_VALUES[type].title,
    page: PAGE_VALUES[type].title,
    hideSearch: true,
    infoIcon: "edit",
    infoURL: `/settings/employee-performance/atendance-score/${type}/edit-score`,
    hasVariable: true,
    deleteCallback: (row) => {
      if (getUserHasAccess("settings", 182)) {
        setModalData({
          open: true,
          data: row,
        });
      } else {
        setAuthorityModal(true);
      }
    },
    buttonGroup: [
      {
        type: "add",
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        class: "buttonFill",
        variant: "contained",
        callback: () => {
          if (getUserHasAccess("settings", 180)) {
            history.push(
              `/settings/employee-performance/atendance-score/${type}/add-score`
            );
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    // let formdata = new FormData();
    // formdata.append("filter[name]", "");
    const res = await http.getAllDataApi(
      t,
      `${PAGE_VALUES[type].api}?page=${pageIndex + 1}&size=${pageSize}`
      // formdata
    );
    setTotalrecords(res?.data_count);
    setAttendance(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    if (type) {
      setPathValue([
        {
          name: PAGE_VALUES[type].title,
          path: PAGE_VALUES[type].path,
        },
      ]);
    }
  }, [type]);

  useEffect(() => {
    getApiData(page, size);
    return () => {
      setPathValue([
        {
          name: "",
          path: ``,
        },
      ]);
    };
  }, [page, size]);

  const handleDelete = async () => {
    setLoader(true);
    const api = `${PAGE_VALUES[type].deleteApi}/${modalData?.data?.id}`;
    await http.deleteApi(t, api);
    setLoader(false);
    handleClose();
  };

  const handleClose = () => {
    setModalData({
      open: false,
      data: {},
    });
    setPage(INITIAL_PAGEINDEX);
    setSize(INITIAL_PAGESIZE);
    getApiData();
  };

  return (
    <>
      <GenericModal
        title={`Delete ${PAGE_VALUES[type].title}`}
        open={modalData.open}
      >
        <div className="scroll-auto ModalContent" style={{ minHeight: "80px" }}>
          <Grid container md={12} className="modalGroup">
            <Grid item sm={12}>
              <Typography textAlign={"center"}>
                Are You Sure You Delete Data From {PAGE_VALUES[type].title}{" "}
                Database?
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => handleClose()}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.delete")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => handleDelete()}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      <ComponentTable
        rows={attendance}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
      />
    </>
  );
}
