import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../../componentGroups/componentButton";
import GenericModal from "../../../../componentGroups/genericModal";
import http from "../../../../../api/axios";
import LoadingContext from "../../../../../context/LoaderContext";

const LeaveApprovalModal = ({ open, id, getLatestRequests, onCLose }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [leaveData, setLeaveData] = useState({});

  const handleCloseModel = () => {
    setLeaveData({});
    getLatestRequests && getLatestRequests();
    onCLose();
  };

  const handleValidate = async (validateId) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("status", validateId);
    await http.patchApi(t, `/api/leave-request/approval/${id}`, formData);
    setLoader(false);
    handleCloseModel();
  };

  // /*
  //  get the meeting validate mins data from  api
  // /*
  const getLeaveData = async () => {
    if (id) {
      setLoader(true);
      let leaveData = await http.getApi(t, `/api/leave-request/findone/${id}`);
      let leaveRequest = {
        name: leaveData?.name ?? "",
        type: leaveData?.type ?? [],
        document: leaveData?.attachment ?? [],
      };
      setLeaveData(leaveRequest);
      setLoader(false);
    }
  };

  useEffect(() => {
    getLeaveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <GenericModal
      title={"titles.leave_Approval"}
      open={open}
      enableCloseIcon={true}
      onClose={handleCloseModel}
    >
      <div className="scroll-auto ModalContent">
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.employee")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              <p>{leaveData?.name}</p>
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.leave_type")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              <p>{leaveData?.type}</p>
            </div>
          </Grid>
        </Grid>

        {/* <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.document")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              {leaveData?.documents?.map((doc) => {
                return <p>{doc?.name}</p>;
              })}
            </div>
          </Grid>
        </Grid> */}
      </div>

      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.approve")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => {
                handleValidate(1);
              }}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t("labels.reject")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn ml-10"}
              callback={() => {
                handleValidate(2);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default LeaveApprovalModal;
