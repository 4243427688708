/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../componentGroups/componectModel";
import http, { baseURL } from "../../../api/axios";
import hooks from "../../../Hooks/hooks";
import validate from "../../../Hooks/employee_validaction";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import { loadOptions } from "../../../api/utils/common";
import axios from "axios";

export default function Employess(props) {
  const { t, i18n } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [empState, setEmpState] = useState({ responsibilities: [] });
  const [empData, setEmpData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("employee", 73)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`employee[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/employee/delete/multiple",
          formData,
          "labels.employee_deleted_successfully"
        );
        getEmployees();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  const [data, setData] = useState({
    column: [
      {
        lable: "",
        name: "checkbox",
        width: "10px",
        align: "center",
      },
      { lable: "labels.name", name: "name", width: "10px", align: "left" },
      { lable: "labels.code", name: "code", width: "10px", align: "left" },
      {
        lable: "labels.phone_number",
        name: "phone",
        width: "10px",
        align: "left",
      },
      {
        lable: "labels.department",
        name: "departmentName",
        width: "10px",
        align: "left",
        type: "dropDown",
        options: [],
      },
      { lable: "labels.email", name: "email", width: "10px", align: "left" },
      {
        lable: "labels.status",
        type: "dropDown",
        name: "status",
        width: "10px",
        align: "left",
        minWidth: 20,
        options: [
          { id: 1, name: "Active", option: "Active" },
          { id: 0, name: "In Active", option: "In Active" },
          { id: 2, name: "All", option: "All" },
        ],
      },
      // { lable: "", name: "info", width: "10px", align: "left" },
    ],
    pageData: {
      title: "tableTitle.2.employees",
      page: "Employess",
      infoURL: "/employees/edit-employee",
      hasVariable: true,
      handlExport: (page, size, filterData) =>
        handleExport(page, size, filterData),
      buttonGroup: [
        {
          type: "filter",
          title: "labels.filter_by",
          icon: <FilterListIcon />,
          class: "buttonBlank",
          variant: "outlined",
          callback: () => { },
        },
        {
          type: "actions",
          title: "labels.action",
          icon: <AddIcon />,
          variant: "outlined",
          class: "buttonFill ml-0",
          callback: () => {
            handleCloseModel();
          },
          onDelete: handleDelete,
        },
        {
          type: "add",
          title: "titles.add_new",
          icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
          class: "buttonFill",
          variant: "contained",
          callback: () => {
            if (getUserHasAccess("employee", 71)) {
              handleModel("addEmp");
            } else {
              setAuthorityModal(true);
            }
          },
        },
      ],
    },
  });
  const [modalData, setModalData] = useState({
    model: false,
    data: [
      {
        lable: "labels.name",
        field: { type: "text", required: true },
        name: "name",
      },
      {
        lable: "labels.email",
        field: {
          type: "text",
          required: true,
        },
        name: "email",
      },
      {
        lable: "labels.password",
        field: { type: "text", required: true },
        name: "password",
      },
      {
        lable: "labels.department",
        field: {
          type: "dropdown",
          required: true,
          findLable: "departmentName",
          option: ["1", "2"],
        },
        name: "departmentName",
      },
      {
        lable: "titles.department_code",
        field: {
          type: "text",
          required: true,
          disable: true,
        },

        name: "departmentCode",
      },
      {
        lable: "labels.branch",
        field: {
          type: "dropdown",
          required: true,
          findLable: "branchName",
          option: ["1", "2"],
        },
        name: "branchName",
      },
      {
        lable: "labels.branch_code",
        field: { type: "text", required: true, disable: true },
        name: "branchCode",
      },
      {
        lable: "labels.job_position",
        name: "jobId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "title",
          option: [],
        },
      },
      {
        lable: "labels.responsible_hr",
        name: "responsibleHrId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
      {
        lable: "labels.direct_manager",
        name: "directManagerId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setEmpState({});
          setErrors({});
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addEmployee(val);
          // handleCloseModel();
        },
      },
    ],
    title: "titles.add_new_employee",
  });

  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({
    filter: false,
    status: 2,
    departmentName: "",
  });
  const [codes, setCodes] = useState({});
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState("");
  const handleModel = (val) => {
    if (val == "addEmp") {
      setModalData({
        ...modalData,
        model: true,
      });
    }
  };
  const handleCloseModel = () => {
    setModalData({
      ...modalData,
      model: false,
    });
  };

  const handleDepAndBranchChange = async () => {
    if (empState?.branchName && codes.branchName != empState?.branchName) {
      setCodes({ ...codes, branchName: empState?.branchName });
      setEmpState({
        ...empState,
        branchCode: hooks.sendId(
          modalData.data[5].field.option,
          "branchCode",
          empState.branchName,
          "id"
        ),
      });
    }
    if (
      empState?.departmentName &&
      codes.departmentName != empState?.departmentName
    ) {
      const res = await http.getApi(
        t,
        `/api/employee/responsibilities/all/${empState?.departmentName}`
      );
      const options = res?.map((op) => {
        return op?.id;
      });
      setCodes({ ...codes, departmentName: empState?.departmentName });
      setEmpState({
        ...empState,
        departmentCode: hooks.sendId(
          modalData.data[3].field.option,
          "departmentCode",
          empState.departmentName,
          "id"
        ),
        responsibilities: [...options],
      });
    }
  };

  useEffect(() => {
    handleDepAndBranchChange();
    if (Object.keys(errors).length != 0) {
      let error = validate.empValidate(empState);
      setErrors(error);
    }
  }, [empState]);

  const getJobPositions = async () => {
    props.setLoader(true);
    axios
      .all([
        loadOptions(
          t,
          "",
          null,
          "get",
          `/api/setting/department/job-position/${empState.departmentName}`
        ),
        loadOptions(t, "", null, "get", `/api/employee/dropdown/all`),
      ])
      .then((res) => {
        let s1 = { ...modalData };
        s1.data[7].field.option = res[0];
        s1.data[9].field.option = res[1];
        setModalData(s1);
      });

    props.setLoader(false);
  };

  useEffect(() => {
    if (empState.departmentName) {
      getJobPositions();
    }
  }, [empState.departmentName]);

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ? filterData?.name : "");
    formdata.append("filter[code]", filterData?.code ? filterData?.code : "");
    formdata.append(
      "filter[email]",
      filterData?.email ? filterData?.email : ""
    );
    formdata.append(
      "filter[status]",
      filterData?.status === 2 ? "" : filterData?.status
    );
    formdata.append(
      "filter[departmentName]",
      filterData?.departmentName ? filterData?.departmentName : ""
    );
    formdata.append(
      "filter[phone]",
      filterData?.phone ? filterData?.phone : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/employee/exportemployees/`,
      formdata
    );
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getEmployees = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ? filterData?.name : "");
    formdata.append("filter[code]", filterData?.code ? filterData?.code : "");
    formdata.append(
      "filter[email]",
      filterData?.email ? filterData?.email : ""
    );
    formdata.append(
      "filter[status]",
      filterData?.status === 2 ? "" : filterData?.status
    );
    formdata.append(
      "filter[departmentName]",
      filterData?.departmentName ? filterData?.departmentName : ""
    );
    formdata.append(
      "filter[phone]",
      filterData?.phone ? filterData?.phone : ""
    );
    // }
    const res = await http.getAllDataApi(
      t,
      `/api/employee/?page=${pageIndex + 1}&size=${pageSize}&search=${search}`,
      formdata
    );
    setEmpData(res?.data);
    setFilterData({ ...filterData, filter: false });
    setTotalrecords(res?.data_count);
  };

  const getApiData = async () => {
    props.setLoader(true);
    let hr = await loadOptions(
      t,
      "",
      null,
      "get",
      "/api/employee/all/department/8"
    );
    let allDepartments = await loadOptions(
      t,
      "",
      null,
      "get",
      "/api/department"
    );
    let allBranches = await loadOptions(
      t,
      "",
      null,
      "post",
      "/api/branch/details"
    );
    let s1 = { ...modalData };
    s1.data[3].field.option = allDepartments;
    s1.data[5].field.option = allBranches;
    s1.data[8].field.option = hr;
    setEmpState({ ...empState });
    setModalData(s1);
    props.setLoader(false);
    const updatedData = { ...data };
    const dept = allDepartments?.map((item) => {
      return {
        ...item,
        name: item?.departmentName,
      };
    });
    updatedData.column[4].options = dept;
    setData(updatedData);
  };

  useEffect(() => {
    getApiData();
  }, []);

  useEffect(() => {
    getEmployees(page, size);
  }, [page, size, search, filterData?.filter == true]);

  const addEmployee = async (obj) => {
    let error = validate.empValidate(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("password", obj.password);
      formData.append("email", obj.email);
      formData.append("departmentId", obj.departmentName);
      formData.append("branchId", obj.branchName);
      formData.append("jobId", obj.jobId);
      formData.append("responsibleHrId", obj.responsibleHrId);
      formData.append("directManagerId", obj.directManagerId);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const responsibilities = [...obj?.responsibilities];
      responsibilities?.forEach((item) => {
        formData.append(`responsibilities[]`, item);
      });
      const res = await http.postApi(t, "/api/employee/create", formData);
      if (res) {
        getEmployees();
        props.setLoader(false);
        setEmpState({});
        handleCloseModel();
      }
      props.setLoader(false);
    } else {
      setErrors(error);
    }
    // setLoader(false);
  };

  return (
    <div className="">
      <ComponentModal
        modalData={modalData}
        setPerantState={setEmpState}
        perantState={empState}
        errors={errors}
      />
      {empData && (
        <ComponentTable
          rows={empData}
          columns={data.column}
          page={page}
          search={search}
          setSearch={setSearch}
          filterData={filterData}
          setFilterData={setFilterData}
          rowsPerPage={size}
          setPage={setPage}
          setRowsPerPage={setSize}
          pageData={data.pageData}
          totalRecords={totalRecords}
          selected={selectedData}
          handleSelected={handleSelected}
          handleAllselect={handleAllselect}
          handleDelete={handleDelete}
          enableReset={true}
          resetdata={{ status: 2, departmentName: "" }}
        />
      )}
    </div>
  );
}
