import React, { useContext } from "react";
import http from "../../../../api/axios";
import Svg from "../../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import { encode } from "string-encode-decode";
import LoadingContext from "../../../../context/LoaderContext";
import { Typography } from "@mui/material";

export default function Sign({ meetingId, code, getData }) {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);

  const handleClickOpen = async () => {
    let currentDate = new Date();
    let timezoneName = currentDate.toLocaleString("en-US", {
      timeZoneName: "long",
    });
    let en = encode(
      `Name : ${code?.userName} , userCode : ${code?.userCode} , Department : ${code?.department} Date&Time:${timezoneName}`
    );
    const form_data = new FormData();
    form_data.append("userId", code.userId);
    form_data.append("meeting_id", meetingId);
    form_data.append("sign", en);
    setLoader(true);
    await http.postApi(
      t,
      `/api/meeting/meeting-decision/addSignature`,
      form_data,
      "labels.signature_added_successfully"
    );
    setLoader(false);
    getData();
  };
  return (
    <div className="Assred">
      <div className="iconTool " onClick={handleClickOpen}>
        <Svg type="addTool" />
        <Typography variant="h6" className="slideText">
          {t("forms.signature")}
        </Typography>
      </div>
    </div>
  );
}
