import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../../componentGroups/componentTextString";
import ComponentButton from "../../../../componentGroups/componentButton";
import http from "../../../../../api/axios";
import LoadingContext from "../../../../../context/LoaderContext";
import ComponentSelect from "../../../../componentGroups/componentSelect";
import axios from "axios";
import { loadOptions } from "../../../../../api/utils/common";
import LogNoteAccordion from "../../../../componentGroups/logNotes/logNoteAccordian";
import { BONUS_KEY_LABELS } from "../../../../componentGroups/logNotes/Labels/bonus_key_lable";

// predeined values to reuse the same component while in add and edit
const PAGE_VALUES = {
  add: "routes.add_bonus_type_name",
  edit: "routes.edit_bonus_type_name",
  addApi: "/api/setting/bonus",
  getDataApi: "/api/setting/bonus/findOne",
};

export default function AddEditBonus() {
  const { bonusId } = useParams();
  const id = bonusId;
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const [errors, setErrors] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    department: [],
    jobTitle: [],
  });
  const [bonusDetail, setBonusDetail] = useState({
    departmentId: "",
    jobId: "",
    amount: "",
    type: "",
  });
  const [flag, setFlag] = useState(false);

  const getBonusData = async () => {
    const api = `${PAGE_VALUES.getDataApi}/${id}`;
    setLoader(true);
    const res = await http.getApi(t, `${api}`);
    setBonusDetail({
      ...res,
    });
    setLoader(false);
  };

  const getJobPositions = async () => {
    setLoader(true);
    const res = await loadOptions(
      t,
      "",
      null,
      "get",
      `/api/setting/department/job-position/${bonusDetail.departmentId}`
    );
    const jobIds = res?.map((item) => {
      return { ...item, name: item?.title };
    });
    setDropDownOptions({
      ...dropDownOptions,
      jobTitle: jobIds,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (bonusDetail.departmentId) {
      getJobPositions();
    }
  }, [bonusDetail.departmentId]);

  const validateErrors = (data) => {
    let error = {};
    if (data.departmentId === undefined || data.departmentId === "") {
      error.departmentId = "errors.select_department";
    }
    if (data.jobId === undefined || data.jobId === "") {
      error.jobId = "errors.select_job_position";
    }
    if (data.type === undefined || data.type === "") {
      error.type = "Enter Bonus Type Name";
    }
    if (data.amount === undefined || data.amount === "") {
      error.amount = "Enter Bonus Amount";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(bonusDetail);
      setErrors(error);
    }
  }, [bonusDetail]);

  //used to change breadcumb name
  useEffect(() => {
    axios
      .all([loadOptions(t, "departments", "", "get", `/api/department`)])
      .then((res) => {
        const department = res[0]?.map((item) => {
          return { ...item, name: item?.departmentName };
        });
        setDropDownOptions({
          department: department,
        });
      })
      .finally(() => {
        if (id) {
          getBonusData();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleAddEdit = async () => {
    let error = validateErrors(bonusDetail);
    if (Object.keys(error).length === 0) {
      const api = id ? `${PAGE_VALUES.addApi}/${id}` : PAGE_VALUES.addApi;
      setLoader(true);
      const formData = new FormData();
      formData.append("departmentId", bonusDetail.departmentId);
      formData.append("jobId", bonusDetail.jobId);
      formData.append("amount", bonusDetail.amount);
      formData.append("type", bonusDetail.type);
      await http.postApi(t, api, formData);
      setLoader(false);
      history.goBack();
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(id ? PAGE_VALUES.edit : PAGE_VALUES.add)}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.type")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="type"
                    value={bonusDetail.type}
                    callback={(e) =>
                      setBonusDetail({
                        ...bonusDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.type && (
                    <span className="validate">{t(errors.type)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.department")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    value={bonusDetail?.departmentId}
                    name="departmentId"
                    option={dropDownOptions.department}
                    callback={(e) =>
                      setBonusDetail({
                        ...bonusDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.departmentId && (
                    <span className="validate">{t(errors.departmentId)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.job_position")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    value={bonusDetail?.jobId}
                    name="jobId"
                    option={dropDownOptions.jobTitle}
                    callback={(e) =>
                      setBonusDetail({
                        ...bonusDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.jobId && (
                    <span className="validate">{t(errors.jobId)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.deduction_amount")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="amount"
                    value={bonusDetail.amount}
                    callback={(e) =>
                      setBonusDetail({
                        ...bonusDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.amount && (
                    <span className="validate">{t(errors.amount)}</span>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                style={{ margin: "16px 0", justifyContent: "center" }}
              >
                <Grid item>
                  <ComponentButton
                    value={t("labels.discard")}
                    variant={"outlined"}
                    class={"buttonBlank outlineBtn"}
                    callback={() => history.goBack()}
                  />
                </Grid>
                <Grid item>
                  <ComponentButton
                    value={t("labels.save")}
                    variant="contained"
                    class="buttonFill FillBtn ml-3"
                    callback={() => {
                      handleAddEdit();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {bonusId && (
              <LogNoteAccordion
                id={`BONUS${bonusId}`}
                flag={flag}
                labelKeys={BONUS_KEY_LABELS}
              />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
