import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import moment from "moment";

const LateAttendanceDetail = () => {
  const { attandanceId } = useParams();
  const id = attandanceId;
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [lateAttendanceDetail, setlateAttendanceDetail] = useState({
    name: "",
    code: "",
    date: "",
    checkIn: "",
    checkOut: "",
    branchName: "",
  });

  const getLateAttendanceDetail = async () => {
    setLoader(true);
    const res = await http.getApi(t, `/api/employee/attendence/late/${id}`);
    setlateAttendanceDetail({
      ...res,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      getLateAttendanceDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t("titles.late_attendance")}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.employee_name")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="name"
                    value={lateAttendanceDetail.name}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("titles.employee_code")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="code"
                    value={lateAttendanceDetail.code}
                    disab
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.date")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="Date"
                    value={moment(lateAttendanceDetail.date).format("LL")}
                    disab
                  />
                </Grid>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.check_in_time")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="checkIn"
                    value={lateAttendanceDetail?.checkIn}
                    disab
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.check_out_time")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="checkOut"
                    value={lateAttendanceDetail?.checkOut}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.branch")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="branchName"
                    value={lateAttendanceDetail?.branchName}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LateAttendanceDetail;
