import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import cookie from "js-cookie";

const currentLanguageCode = cookie.get("i18next") || "en";
const initialLanguage = currentLanguageCode || "en";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr", "ar"],
    lng: initialLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStroge", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locale/{{lng}}/translate.json",
    },
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
const loadingMarkup = (
  <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <h1>Loading...</h1>
  </div>
);
root.render(
  <Suspense fallback={loadingMarkup}>
    <Router>
      <App />
    </Router>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
