import React from "react";
import { Grid } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import ReferenceCard from "../../../componentGroups/componentRefrenceCard";

export default function PropheticTraditionReferences(props) {
  return (
    <div className="pending_suggestions_reqPG">
      {/* <Typography variant='h4' className="morningText" >{t("titles.library_categories ")}</Typography> */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <ReferenceCard />
        </Grid>
      </Grid>
    </div>
  );
}
