import React from "react";
import { useTranslation } from "react-i18next";
import Meetings from "./Common/Meetings";
import Schedule from "./Common/Schedule";

export default function Attroneys() {
  const { t } = useTranslation();

  return (
    <>
      <Schedule
        enableReminderPopup={true}
        endPoint={"/api/meeting/upcoming/at/list"}
      />
      <Meetings
        modulePath={"attroneys"}
        titile={"titles.attroneys_meeting"}
        endPoint={"/api/meeting/at/list"}
      />{" "}
    </>
  );
}
