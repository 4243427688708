import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import ComponentTable from "../../../componentGroups/componentTable";
import http from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import ReminderAddEdit from "./ReminderAddEdit";
import moment from "moment";

export default function Reminders({
  setLoader,
  clientId,
  loadDropdownOptions,
  dropDownOptions,
}) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [matterModal, setMatterModal] = useState({
    open: false,
    id: "",
    mode: "",
  });
  const [column, setColumn] = useState([
    {
      lable: "labels.reminder_date",
      name: "reminderDate",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.reminder_time",
      name: "reminderTime",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.details",
      name: "description",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.reminder_type",
      name: "reminderType",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.related_matters",
      name: "matterName",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.related_lawyer",
      name: "lawyerName",
      width: "10px",
      align: "left",
    },
  ]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(rows?.length);
  const [data, setData] = useState({
    title: "labels.reminders",
    page: "Reminders",
    // infoCallback: (row) => {
    //   setMatterModal({
    //     open: true,
    //     id: row?.id,
    //     mode: "view",
    //   });
    // },

    buttonGroup: [
      {
        title: "labels.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          setMatterModal({
            open: true,
            id: "",
            mode: "add",
          });
        },
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/reminder/all/${clientId}?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count);
    const updated = res?.data?.map((item) => {
      return {
        ...item,
        reminderTime: moment(item?.reminderTime, "h:mm:ss").format("h:mm a"),
      };
    });
    setRows(updated);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  return (
    <>
      <ReminderAddEdit
        open={matterModal?.open}
        onCLose={() =>
          setMatterModal({
            open: false,
            id: "",
            mode: "",
          })
        }
        mode={matterModal?.mode}
        id={matterModal?.id}
        dropDownOptions={dropDownOptions}
        setLoader={setLoader}
        getApiData={getApiData}
        clientId={clientId}
        loadDropdownOptions={loadDropdownOptions}
      />
      <ComponentTable
        rows={rows}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        search={search}
        setSearch={setSearch}
        totalRecords={totalRecords}
      />
    </>
  );
}
