import React from "react";
import "../style.css";
import { Divider, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { baseURL } from "../../../../api/axios";

const CommentsView = ({ comments }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item sm={12} md={12} lg={12} className="allCommantAvtar">
        <div className="commentSection">
          <div className="commentBox">
            {!comments || comments?.length === 0 ? (
              <span className="noDataFound">
                {t("labels.no_comments_available")}
              </span>
            ) : (
              <div className="avatarcontainer">
                <AvatarGroup max={3} className="avatargroup">
                  {comments?.map((item) => {
                    return (
                      <Avatar
                        key={item?.id}
                        alt={item?.name}
                        src={`${baseURL}/${item?.pic}`}
                        className="avatar"
                      />
                    );
                  })}
                </AvatarGroup>
                <span>
                  <span className="HideText">
                    {" "}
                    {comments[0]?.name} {t("labels.commented_on_decision")}
                  </span>{" "}
                  {comments?.length > 1 && t("labels.and")}
                  {comments?.length > 1 && (
                    <>
                      <small>
                        {t("labels.more_people", {
                          count: comments?.length - 1,
                        })}
                      </small>
                    </>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </Grid>
      <Grid container>
        {comments?.length > 0 &&
          comments?.map((item, index) => {
            return (
              <Grid item md={12}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "15px",
                    display: "flex",
                  }}
                >
                  <div>
                    <Avatar
                      key={""}
                      alt="Shubham"
                      src={`${baseURL}/${item?.pic}`}
                      className="avatar-lg"
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <div className="NameComment">{item?.name ?? ""}</div>
                    <div
                      className="NameCommentTitle"
                      style={{ marginTop: "8px" }}
                    >
                      <Typography variant="div" className="details">
                        {item?.comment ?? ""}
                      </Typography>
                    </div>
                  </div>
                </div>
                {comments?.length - 1 !== index && (
                  <Divider
                    style={{
                      marginLeft: "10px",
                      marginTop: "15px",
                    }}
                  />
                )}
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default CommentsView;
