import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import http from "../../../../api/axios";
import GeneralInfo from "./generalInfo";
import BranchesEmployee from "./branchEmployee";
import BranchesClient from "./branchClient";
import BranchesDep from "./branchDep";
import validate from "../../../../Hooks/branch_validacion";
import auth from "../../../../services/auth";
import { useTranslation } from "react-i18next";
import { Country, City } from "country-state-city";
import BranchWorkingHours from "./WorkingHour";
import { BRANCH_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/branch_key_lable";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BranchInfo(props) {
  const { t } = useTranslation();
  const { branchId } = useParams();
  branchId && auth.login("branchId", branchId);
  const Id = auth.getUser("branchId");
  const [value, setValue] = useState(0);
  const [branchData, setBranchData] = useState();
  const [branchState, setBranchState] = useState({
    countryName: "",
    cityName: "",
  });
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [flag, setFlag] = useState(false);

  const updateBranch = async () => {
    setFlag(false);
    let error = validate.useBranchEditValidate(branchState);
    if (Object.keys(error).length === 0) {
      //  setLoader(true);
      var formdata = new FormData();
      formdata.append("branchCode", branchState?.branchCode);
      formdata.append("branchName", branchState?.branchName);
      branchState?.branch_departments?.map((op, index) => {
        formdata.append(`departments[${index}]`, op.id);
      });
      formdata.append("langId", "1");
      formdata.append("branchCity", branchState?.cityName);
      formdata.append("branchCountry", branchState?.countryName);
      branchState?.branches_addresses?.map((op, index) => {
        formdata.append(`branchAddresses[${index}]`, op.address);
      });
      let res = http.putApi(t, `/api/branch/${branchState?.id}`, formdata);
      setFlag(true);
      // if (res) {
      //   // setLoader(false);
      //   history.push("/branches")
      // }
      // setLoader(false);
    } else {
      setErrors(error);
    }
    // setLoader(false);
  };

  const getApiData = async () => {
    props.setLoader(true);
    let res = await http.getApi(t, `/api/branch/${Id}`);
    let resDept = await http.getApi(t, "/api/department");
    props.setLoader(false);

    const countryRes = Country.getAllCountries()?.map((item) => {
      return { ...item, id: item?.isoCode };
    });
    setDepartments(resDept);
    setCountries(countryRes);
    getCity(res?.countryName);
    const dept = res?.branch_departments.map((op) => {
      return {
        id: op.departmentId,
        departmentName: op?.department?.departmentName,
      };
    });
    if (res?.branches_addresses?.length != 0) {
      setBranchState({ ...res, branch_departments: dept });
    } else {
      setBranchState({
        ...res,
        branch_departments: dept,
        branches_addresses: [{ address: "" }],
      });
    }
  };

  const removeDuplicateNames = (dataArray) => {
    const uniqueNamesSet = new Set();
    return dataArray.filter((obj) => {
      if (!uniqueNamesSet.has(obj.name)) {
        uniqueNamesSet.add(obj.name);
        return true;
      }
      return false;
    });
  };

  const getCity = async (val) => {
    const citiesRes = await City.getCitiesOfCountry(val)?.map((item, index) => {
      return {
        ...item,
        id: `${index}-${item?.stateCode}-${item?.countryCode}`,
      };
    });
    setCities(removeDuplicateNames(citiesRes));
  };

  useEffect(() => {
    getApiData();
  }, []);
  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.useBranchEditValidate(branchState);
      setErrors(error);
    }
  }, [branchState]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={9}>
            {branchState && (
              <Typography variant="h6" className="morningText">
                {branchState?.branchName} / {branchState?.branchCode}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              sx={{ marginLeft: "50px" }}
              className="buttonFill"
              onClick={() => updateBranch()}
            >
              {t("labels.save")}
            </Button>
          </Grid>
        </Grid>
        <Box
          className="TaskTab TabActive"
          sx={{
            borderBottom: 1,
            borderColor: "rgba(183, 161, 96, 0.26)",
            pt: 6,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="iconText"
              label={t("labels.general_info")}
              value={0}
            />
            <Tab
              className="iconText"
              label={t("labels.branchs_employees")}
              value={1}
            />
            <Tab
              className="iconText"
              label={t("labels.branchs_clients")}
              value={2}
            />
            <Tab
              className="iconText"
              label={t("labels.branchs_departments")}
              value={3}
            />
            <Tab
              className="iconText"
              label={t("labels.working_hours")}
              value={4}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GeneralInfo
            branchData={branchData}
            Id={Id}
            departments={departments}
            setLoader={props.setLoader}
            setBranchState={setBranchState}
            branchState={branchState}
            setErrors={setErrors}
            errors={errors}
            countries={countries}
            cities={cities}
            getCity={getCity}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BranchesEmployee
            setLoader={props.setLoader}
            branchData={branchState}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BranchesClient
            setLoader={props.setLoader}
            branchData={branchState}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BranchesDep setLoader={props.setLoader} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <BranchWorkingHours id={Id} />
        </TabPanel>
        {branchState?.branchCode && (
          <LogNoteAccordion
            id={branchState?.branchCode}
            labelKeys={BRANCH_KEY_LABELS}
            flag={flag}
          />
        )}
      </Grid>
    </div>
  );
}
