import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingContext from "../../../../context/LoaderContext";

export default function Meetings({
  titile,
  endPoint,
  disableCreateMeeting = false,
  modulePath = "",
}) {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [column, setColumn] = useState([
    {
      lable: "labels.meeting_code",
      name: "meeting_code",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.meeting_title",
      name: "meeting_title",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.date",
      name: "date",
      width: "10px",
      align: "left",
      type: "date",
      minWidth: 20,
    },
    {
      lable: "labels.status",
      type: "dropDown",
      name: "status",
      width: "10px",
      align: "left",
      minWidth: 20,
      options: [
        { id: 1, name: "Validated", option: "Validated" },
        { id: 0, name: "Not Validated", option: "Not Validated" },
      ],
    },
  ]);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(rows.length);
  const [search, setSearch] = useState("");

  const [data, setData] = useState({
    title: titile,
    page: "",
    infoURL: `/${modulePath}/meeting-detail`,
    handlExport: () => handleExport(),
    buttonGroup: disableCreateMeeting
      ? [
          {
            title: "labels.action",
            type: "actions",
            icon: <AddIcon />,
            variant: "outlined",
            class: "buttonFill ml-0",
            callback: () => {},
          },
        ]
      : [
          {
            title: "labels.action",
            type: "actions",
            icon: <AddIcon />,
            variant: "outlined",
            class: "buttonFill ml-0",
            callback: () => {},
          },
          {
            title: "labels.create_meeting",
            icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
            variant: "contained",
            class: "buttonFill",
            callback: () => {
              history.push("/createMeeting");
            },
          },
        ],
  });

  const handleExport = async () => {
    setLoader(true);
    const res = await http.postApi(t, `${endPoint}/export`);
    setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  /**
   * get meeting history data
   */
  const getMeetingHistory = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `${endPoint}/?page=${pageIndex + 1}&size=${pageSize}&search=${search}`
    );
    setLoader(false);
    if (!!res?.data) {
      setRows(res?.data);
      setTotalrecords(res?.total_count);
    }
  };

  useEffect(() => {
    getMeetingHistory(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  return (
    <div className="mainContainer PartnerTable">
      <ComponentTable
        rows={rows}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        defaultstatus={false}
      />
    </div>
  );
}
