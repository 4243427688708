import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import AllConsultations from "./AllConsultations";
import MyTask from "./Received";
import SendTask from "./Sent";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import "../../../../assets/css/style.css";
import http, { baseURL, getAllDataApi } from "../../../../api/axios";
import ComponentButton from "../../../componentGroups/componentButton";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import PermisisionDenied from "../../../routing/PermisisionDenied";
import { useHistory } from "react-router-dom";
import TabPanel, { a11yProps } from "../../Allocation/TabPanel";
import Archived from "./Archived";

export default function Tasks(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [filterData, setFilterData] = useState({ filter: false });
  const [value, setValue] = React.useState(0);
  const [totalRecords, setTotalrecords] = useState(0);
  const [allTaskData, setAllTaskData] = useState([]);
  const [dropdownOptions, setDropdwonOptions] = useState({});
  const [search, setSearch] = useState("");

  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    type = ""
  ) => {

    props.setLoader(true);
    let exportUrl = "/api/consultation/export";
    const res = await http.getAllDataApi(
      t,
      `${exportUrl}?access=${type}`,

    );
    props.setLoader(false);
    if (!!res?.link) {
      const url = `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    let formdata = new FormData();
    // if (filterData?.filter == true) {
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate ? filterData?.dueDate : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    formdata.append(
      "filter[projectName]",
      filterData?.projectName ? filterData?.projectName : ""
    );
    const res = await getAllDataApi(
      t,
      `/api/consultation/all`,
      formdata,
      {
        page: pageIndex + 1,
        size: pageSize,
        search
      }
    );
    // console.log(res?.data);
    setTotalrecords(res?.data_count);
    setAllTaskData(res?.data);
    setFilterData({ ...filterData, filter: false });
    // TODO: if code is independent need to be added to another function
    props.setLoader(false);
  };
  // const getDataApi = async (key = false) => {
  //   let s1 = { ...modalData };
  //   let res = await loadOptions(1, 1);
  //   let res1 = await loadOptions(1, 2);
  //   let res2 = await loadOptions(1, undefined, "post", "/api/employee");
  //   let res3 = await loadOptions(1, undefined, "post", "/api/project/details");
  //   console.log(res2, "rew2");
  //   setDropdwonOptions({
  //     ...dropdownOptions,
  //     taskType: res,
  //     employess: res2,
  //     priority: res1,
  //   });
  //   s1.data[1].field.option = res;
  //   s1.data[4].field.option = res1;
  //   s1.data[3].field.option = res2;
  //   s1.data[6].field.option = res2;
  //   s1.data[7].field.option = res2;
  //   s1.data[2].field.option = res3;

  //   s1.model = key;
  //   setModelData(s1);
  // };

  // useEffect(() => {
  //   getDataApi();
  // }, [search]);

  useEffect(() => {
    getApiData();
    // getDataApi();
  }, []);
  // console.log();

  return (
    <div className="CommonPd">
      {/* <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setTaskState}
        perantState={taskState}
      /> */}

      <div className="latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("labels.consultation")}
              </Typography>
            </Grid>
            <Grid className="btnStyle">
              <ComponentButton
                icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
                value={t("labels.add_new")}
                variant={"contained"}
                class={"buttonFill"}
                callback={() => {
                  if (getUserHasAccess("consultation", 170)) {
                    history.push("/ask-consultation/consultation-form");
                  } else {
                    setAuthorityModal(true);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Box
            className="TaskTab TabActive"
            sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="iconText"
                label={t("labels.all_requests")}
                {...a11yProps(0)}
              />
              <Tab
                className="iconText"
                label={t("labels.sent_requests")}
                {...a11yProps(1)}
              />
              <Tab
                className="iconText"
                label={t("labels.received_requests")}
                {...a11yProps(2)}
              />
              <Tab
                className="iconText"
                label={t("labels.archived")}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AllConsultations
              props={props}
              filterData={filterData}
              setFilterData={setFilterData}
              getApiData={getApiData}
              search={search}
              dropdownOptions={dropdownOptions}
              setLoader={props.setLoader}
              allTaskData={allTaskData}
              totalRecords={totalRecords}
              setSearch={setSearch}
              handleExport={handleExport}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <SendTask
              setLoader={props.setLoader}
              dropdownOptions={dropdownOptions}
              handleExport={handleExport}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MyTask
              setLoader={props.setLoader}
              dropdownOptions={dropdownOptions}
              handleExport={handleExport}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Archived
              setLoader={props.setLoader}
              dropdownOptions={dropdownOptions}
              handleExport={handleExport}
            />
          </TabPanel>
        </Grid>
      </div>
    </div>
  );
}
