import React from "react";
import ComponentAccordion from "../componentAccordion";
import ComponentLogNote from "./componentLogNote";
import { useTranslation } from "react-i18next";

const LogNoteAccordion = ({ id, labelKeys = {}, flag }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="logNotes">
        <ComponentAccordion
          variant="h4"
          addedClassName={true}
          data={{
            heading: t("titles.log_notes"),
          }}
          className="bgColor"
          editIcon={false}
          component={
            <ComponentLogNote id={id} labelKeys={labelKeys} flag={flag} />
          }
        />
      </div>
    </>
  );
};

export default LogNoteAccordion;
