import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import BodMeetingCard from "./BODMeeting/BodMeetingCard";
import BodDecisions from "./BODMeeting/BodDecisions";
import http from "../../../api/axios";
import "./style.css";
import LoadingContext from "../../../context/LoaderContext";

const BodDecision = (props) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [showAll, setShowAll] = useState(false);
  const [dataToView, setDataToView] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showAll) {
      setDataToView(data);
    } else {
      if (data?.length > 5) {
        let sliced = [...data]?.slice(0, 5);
        setDataToView(sliced);
      } else {
        setDataToView(data);
      }
    }
  }, [showAll, data]);

  const getBodDecisions = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/meeting/meeting-decision/pb/list");
    setLoader(false);
    if (!!res && res?.length > 0) {
      setData(res);
    }
  };

  useEffect(() => {
    getBodDecisions();
  }, []);

  return (
    <div className="BodDecisionPage">
      <BodMeetingCard />
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "0 !important",
            }}
          >
            <Grid className="gridLeft" style={{ padding: "20px 10px" }}>
              <Typography className="DashTitle" variant="h4">
                {t("partner.bod_decision")}
              </Typography>
            </Grid>

            {data?.length > 5 && (
              <Grid className="gridLeftbtn">
                <ComponentButton
                  value={showAll ? t("labels.show_less") : t("labels.view_all")}
                  variant={"outlined"}
                  class={"buttonBlank"}
                  callback={() => setShowAll(!showAll)}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "0 !important",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <div>
                {dataToView?.map((item, index) => (
                  <BodDecisions
                    data={item}
                    index={index}
                    key={item?.id}
                    getBodDecisions={getBodDecisions}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BodDecision;
