import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import DocumentModal from "./documentModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Svg from "../../../../../assets/svg/svg";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { baseURL } from "../../../../../api/axios";

const DocFiles = ({ item, getFiles, getFoldersByRow }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modalData, setModalData] = useState({
    operationId: "",
    open: false,
  });
  const [users, setUsers] = useState([]); //assigned to users
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = (operationId) => {
    setModalData({
      open: true,
      operationId: operationId,
      name: item?.name ?? item?.title ?? "",
    });
    handleClick();
  };

  useEffect(() => {
    if (item?.users) {
      const assigntousers = [...item.users];
      const users =
        item.users?.length > 3 ? assigntousers?.splice(0, 3) : item?.users;
      setUsers(users);
    }
  }, [item?.users]);

  return (
    <>
      <Card className="docFileCard docCardBg">
        <div style={{ display: "flex" }}>
          <div className="docIcons">
            <Svg type={item?.type === "doc" ? "pdfFile" : "document"} />
          </div>

          <div style={{ width: "100%", marginLeft: "10px" }}>
            <div
              style={{
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ margin: 0 }}>{item?.name ?? item?.title} </p>
              <MoreHorizIcon fontSize="medium" onClick={handleClick} />
            </div>
            <div style={{ fontSize: "0.7rem" }}>
              {t("labels.last_update")}:{" "}
              {moment(item?.updatedAt ?? item?.createdAt).format("LLL")}
            </div>
            <div style={{ fontSize: "0.7rem" }}>
              {item?.users?.length === 0 ? (
                t("labels.not_assigned_to_any_one")
              ) : (
                <div className="avatarcontainer">
                  {t("labels.assign_to")}:
                  <AvatarGroup max={3} className="avatargroup">
                    {users?.map((item) => {
                      return (
                        <Avatar
                          key={item?.id}
                          alt={item?.name}
                          src={`${baseURL}/${item?.UserProfilePic}`}
                          className="avatar"
                        />
                      );
                    })}
                  </AvatarGroup>
                  {item?.users?.length > users?.length &&
                    t("labels.other_people", {
                      count: item?.users?.length - 3,
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
        <Menu
          className="Action0con"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {item?.type === "doc" && (
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                handleModal(6);
              }}
            >
              {t("labels.edit_file_name")}
            </MenuItem>
          )}
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              if (item?.type === "doc") {
                history.push(`/form_preview/client/${"assigned"}/${item.id}s`);

              } else {
                window.open(`${baseURL}/${item?.path}`, "_blank");
              }
            }}
          >
            Preview File
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              handleModal(8);
            }}
          >
            {t("labels.assign_to")}
          </MenuItem>
        </Menu>
      </Card>
      <DocumentModal
        open={modalData.open}
        operationId={parseInt(modalData?.operationId)}
        onCLose={() => {
          setModalData({
            open: false,
            operationId: "",
            name: "",
          });
          handleClose();
        }}
        fileId={item?.id}
        getFiles={getFiles}
        getFoldersByRow={getFoldersByRow}
        filetype={item?.type === "doc" ? 3 : 2}
        fileName={modalData?.name}
      />
    </>
  );
};

export default DocFiles;
