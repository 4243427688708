import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LeaveApprovalModal from "./LeaveApprovalModal";

const LatestItem = ({ item, getLatestRequests }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });

  return (
    <>
      <LeaveApprovalModal
        open={modal?.open}
        id={modal?.id}
        onCLose={() => {
          setModal({
            open: false,
            id: "",
          });
        }}
        getLatestRequests={getLatestRequests}
      />
      <div className="dashboard Btn">
        <Grid className="gridLeft">
          <Typography component="span" variant="body2">
            {item?.type} From {item?.name}
          </Typography>
        </Grid>
        <Grid className="gridLeftbtn">
          <ComponentButton
            value={t("labels.view_details")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
            callback={() => {
              history.push(`/addEmployee/${item?.user_id}`);
            }}
          />
          <ComponentButton
            value={t("labels.approve")}
            variant={"contained"}
            class={"buttonFill"}
            callback={() =>
              setModal({
                open: true,
                id: item?.id,
              })
            }
          />
        </Grid>
      </div>
    </>
  );
};

export default LatestItem;
