import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentButton from "../../../componentGroups/componentButton";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import ComponentSelect from "../../../componentGroups/componentSelect";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";
import { loadOptions } from "../../../../api/utils/common";
import axios from "axios";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import { JOBPOSITION_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/jobPosition_key_lable";

// predeined values to reuse the same component while in add and edit
const PAGE_VALUES = {
  add: "routes.add_job_position",
  edit: "routes.edit_job_position",
  addApi: "/api/setting/job-position",
  getDataApi: "/api/setting/job-position/findOne",
};

export default function AddEditJobPosition() {
  const { jobPositionID } = useParams();
  const id = jobPositionID;
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { setPathValue } = useContext(BreadCrumbContext);
  const { setLoader } = useContext(LoadingContext);
  const [errors, setErrors] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    department: [],
  });
  const [jobPositionDetail, setJobPosition] = useState({
    title: "",
    departmentId: "",
  });
  const [flag, setFlag] = useState(false);

  const getBonusData = async () => {
    const api = `${PAGE_VALUES.getDataApi}/${id}`;
    setLoader(true);
    const res = await http.getApi(t, `${api}`);
    setJobPosition({
      ...res,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      getBonusData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateErrors = (data) => {
    let error = {};
    if (data.departmentId === undefined || data.departmentId === "") {
      error.departmentId = "errors.select_department";
    }
    if (data.title === undefined || data.title === "") {
      error.title = "errors.select_job_position";
    }
    if (data.intialScore === undefined || data.intialScore === "") {
      error.intialScore = "errors.intialScore";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(jobPositionDetail);
      setErrors(error);
    }
  }, [jobPositionDetail]);

  //used to change breadcumb name
  useEffect(() => {
    setPathValue([
      {
        path: pathname,
        name: id ? PAGE_VALUES.edit : PAGE_VALUES.add,
      },
      {
        name: PAGE_VALUES.title,
        path: PAGE_VALUES.path,
      },
    ]);
    axios
      .all([loadOptions(t, "departments", "", "get", `/api/department`)])
      .then((res) => {
        const department = res[0]?.map((item) => {
          return { ...item, name: item?.departmentName };
        });
        setDropDownOptions({
          department: department,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleAddEdit = async () => {
    let error = validateErrors(jobPositionDetail);
    if (Object.keys(error).length === 0) {
      const api = id ? `${PAGE_VALUES.addApi}/${id}` : PAGE_VALUES.addApi;
      setLoader(true);
      const formData = new FormData();
      formData.append("departmentId", jobPositionDetail.departmentId);
      formData.append("title", jobPositionDetail.title);
      formData.append("intialScore", jobPositionDetail.intialScore);
      await http.postApi(t, api, formData);
      setFlag(!flag);
      setLoader(false);
      history.goBack();
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(id ? PAGE_VALUES.edit : PAGE_VALUES.add)}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.job_position")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="title"
                    value={jobPositionDetail.title}
                    callback={(e) =>
                      setJobPosition({
                        ...jobPositionDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.title && (
                    <span className="validate">{t(errors.title)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.department")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    value={jobPositionDetail?.departmentId}
                    name="departmentId"
                    option={dropDownOptions.department}
                    callback={(e) =>
                      setJobPosition({
                        ...jobPositionDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.departmentId && (
                    <span className="validate">{t(errors.departmentId)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("titles.intial_score")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="intialScore"
                    value={jobPositionDetail.intialScore}
                    callback={(e) =>
                      setJobPosition({
                        ...jobPositionDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.intialScore && (
                    <span className="validate">{t(errors.intialScore)}</span>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                style={{ margin: "16px 0", justifyContent: "center" }}
              >
                <Grid item>
                  <ComponentButton
                    value={t("labels.discard")}
                    variant={"outlined"}
                    class={"buttonBlank outlineBtn Mright"}
                    callback={() => history.goBack()}
                  />
                </Grid>
                <Grid item>
                  <ComponentButton
                    value={t("labels.save")}
                    variant="contained"
                    class="buttonFill FillBtn"
                    callback={() => {
                      handleAddEdit();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {jobPositionID && (
              <LogNoteAccordion
                id={`JOB${jobPositionID}`}
                flag={flag}
                labelKeys={JOBPOSITION_KEY_LABELS}
              />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
