import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography
} from "@mui/material";
import { useHistory } from "react-router";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from "react-i18next";
// import { withRouter } from "react-router-dom";

const Breadcrumbs = props => {
  const history = useHistory()
  const { t } = useTranslation();

  // const {
  //   history,
  //   location: { pathname }
  // } = props;

  const pathnames = props.pathnames

  // pathname = {
  //   name: 'displayed name',
  //   path:'/link to go to'
  // }

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} sx={{ margin: "1.5rem" }}>

      {pathnames?.map((pathname, index) => {

        const isLast = index === pathnames?.length - 1;
        return isLast ? (
          <Typography key={pathname.name} style={{ fontSize: "1rem", fontWeight: 'bold' }} color="text.primary">{t(pathname.name)}</Typography>
        ) : (
          <Link key={pathname.name} underline="hover" color="inherit"
            onClick={() => history.push(pathname.path)}
            style={{
              // fontWeight: 'bold',
              cursor: 'pointer',
              fontSize: "1rem",
              color: "#444"
            }}>
            {t(pathname.name)}
          </Link>
        );
      })}


      {/* {pathnames.length > 0 ? (
        <Link onClick={() => history.push("/")}>Home</Link>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name}>{name}</Typography>
        ) : (
          <Link key={name} onClick={() => history.push(routeTo)} color='inherit'>
            {name}
          </Link>
        );
      })} */}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;

// export default withRouter(Breadcrumbs); => this was to get it auto from url
