import React from "react";
import DashboardMeeting from "../../DashBoard/DashboardMeeting";
import LatestRequest from "./dashboard/latestRequest";
import DashBoardForgotRequests from "../../DashBoard/DashboardForgotpassword";
import Greeting from "../../DashBoard/Greeting";
import DashBoardPendingRequests from "../../DashBoard/DashBoardPendingRequests";
import DashBoardAttendanceRequest from "../../DashBoard/DashBoardAttendanceRequest";

const HRDashboard = () => {
  return (
    <div>
      <Greeting />
      <LatestRequest endPoint="/api/leave-request/dashboardrequestlist" />
      <DashBoardAttendanceRequest />
      <DashBoardPendingRequests />
      <DashBoardForgotRequests />
      <DashboardMeeting />
    </div>
  );
};

export default HRDashboard;
