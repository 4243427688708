import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ComponentButton from "../componentGroups/componentButton";

const PermisisionDenied = ({ removeBack = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid container>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography className="morningText" variant="h4">
              {t("Access Denied")}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>{t("titles.contact_your_administrator")}</div>
          </Grid>
          {!removeBack && (
            <Grid
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ComponentButton
                value={t("Go Back")}
                variant={"outlined"}
                class={"buttonFill outlineBtn ml-10"}
                callback={() => {
                  history.goBack();
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default PermisisionDenied;
