import React, { useEffect, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  CardActions,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import "../style.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useHistory, Link } from "react-router-dom";
import http from "../../../../api/axios";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import ComponentModal from "../../../componentGroups/componectModel";
import { Category } from "@mui/icons-material";
import Hooks from "../../../../Hooks/hooks";
import validate from "../../../../Hooks/form_doc_validcation";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

import Breadcrumbs from "../../../comman/Breadcrumb";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Forms(props) {
  const { t, i18n } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [categoryState, setCategoryState] = useState({});
  const [departmentData, setDepartmentData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [dept, setDept] = useState(null);
  const [modalData, setModelData] = useState({
    model: false,
    data: [],
    title: "",
  });

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/form_doc/category/findAll`);
    let res1 = await http.getApi(t, "/api/department");
    setDepartmentData(res1);
    setCategoryData(res);
    props.setLoader(false);
  };
  useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, [props.langId]);

  useEffect(() => {
    if (
      categoryState?.departmentName &&
      dept != categoryState?.departmentName
    ) {
      setDept(categoryState?.departmentName);
      setCategoryState({
        ...categoryState,
        departmentCode: Hooks.sendId(
          departmentData,
          "departmentCode",
          categoryState.departmentName,
          "id"
        ),
      });
    }
    if (Object.keys(errors).length != 0) {
      let error = validate.categoryValidate(categoryState);
      setErrors(error);
    }
  }, [categoryState]);

  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };

  const handleCategoryApi = async (val) => {
    let error = validate.categoryValidate(val);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      setModelData({ ...modalData, model: false });
      let formData = new FormData();
      formData.append("category", val?.category);
      formData.append("department_id", val?.departmentName);
      let res = await http.postApi(
        t,
        "/api/form_doc/create/category",
        formData
      );
      getApiData();
      setCategoryState({});
      props.setLoader(false);
    } else {
      setErrors(error);
    }
  };

  const handleModel = (val) => {
    if (val == "addNew") {
      setModelData({
        ...modalData,
        model: true,
        title: t("titles.add_new_form_category"),
        data: [
          {
            lable: "labels.name",
            field: {
              required: true,
              type: "text",
              placeholder: t("placeholder.category_name"),
              // },
              // callback: (e) => {
              //     setcategory(e.target.value)
            },
            name: "category",
          },
          {
            lable: t("titles.department_name"),
            field: {
              required: true,
              type: "dropdown",
              findLable: "departmentName",
              option: departmentData,
              placeholder: t("placeholder.enter_the_department_name"),
            },
            name: "departmentName",
          },
          {
            lable: t("titles.department_code"),
            field: {
              required: true,
              type: "text",
              placeholder: t("placeholder.enter_the_department_code"),
              disable: true,
            },
            name: "departmentCode",
          },
        ],
        buttonGroup: [
          {
            value: t("labels.discard"),
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              setCategoryState({});
              handleCloseModel();
            },
          },
          {
            value: "Create",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (value) => {
              handleCategoryApi(value);
            },
          },
        ],
      });
    }
  };
  const BreadcrumbsPathnames = [
    {
      name: t("labels.forms_and_docs"),
      path: "/formDocuments",
    },
    {
      name: t("labels.forms"),
      path: "/forms",
    },
  ];

  return (
    <div className="FormDocPg">
      <ComponentModal
        modalData={modalData}
        setPerantState={setCategoryState}
        perantState={categoryState}
        errors={errors}
      />
      <div className="CommonPd">
        <Breadcrumbs pathnames={BreadcrumbsPathnames} />

        <div className="latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" className="morningText">
                {t("titles.forms")}
              </Typography>

              <ComponentButton
                icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
                value={t("labels.add_new")}
                variant={"contained"}
                class={"buttonFill"}
                callback={() => {
                  if (getUserHasAccess("form", 42)) {
                    handleModel("addNew");
                  } else {
                    setAuthorityModal(true);
                  }
                }}
              />
            </Grid>
            <Grid container spacing={1}>
              {categoryData?.map((op) => (
                <Grid item xs={12} md={6}>
                  <Card className="FormDoc-Card">
                    <CardHeader title={op.category} />

                    <CardContent sx={{ display: "flex" }}>
                      <div className="cardContant">
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="textFont"
                        >
                          {op?.forms_has_categories?.length} {t("labels.files")}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="textFont"
                        >
                          {/* {t('labels.last_update')} :    January 02 , 2023 at 03 pm */}
                          {t("titles.department_name")} : {op.departmentName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="textFont"
                        >
                          {/* {t('labels.last_update')} :    January 02 , 2023 at 03 pm */}
                          {t("titles.department_code")} : {op.departmentCode}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="textFont"
                        >
                          {/* {t('labels.last_update')} :    January 02 , 2023 at 03 pm */}
                          {t("labels.last_update")} :{" "}
                          {op.updatedAt != null &&
                            `${moment(op.updatedAt).format(
                              "MMMM DD , YYYY"
                            )} at ${moment(op.updatedAt).format("hh A")}`}
                        </Typography>
                      </div>
                    </CardContent>

                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded}
                        onClick={() =>
                          history.push(`/forms/${op.category}/${op.id}`)
                        }
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <Button
                          variant="span"
                          color="text.secondary"
                          className="textFont OpenBtn"
                          style={{ display: "flex" }}
                        >
                          {t("labels.open")} <ArrowRightAltIcon />
                        </Button>
                      </ExpandMore>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
