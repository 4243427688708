const sendName = (data, lable) => {
    let nameData = [];
    if (data && data.length != 0) {
        data?.map(op => {
            nameData.push({ id: op.id, name: op[lable] })
        })
    }

    return nameData;
};

const sendId = (data, getlable, value, checkLable) => {
    let nameData = data?.find(op => op[checkLable] == value)
    return nameData[getlable];
};

export default {
    sendName,
    sendId,
};