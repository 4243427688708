import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import AddIcon from "@mui/icons-material/Add";
import ComponentModal from "../../componentGroups/componectModel";
import { useTranslation } from "react-i18next";
import http, { baseURL } from "../../../api/axios";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import validate from "../../../Hooks/coordinator_validaction";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import { loadOptions } from "../../../api/utils/common";
import axios from "axios";

export default function Coordinators(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState([]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("coordinator", 86)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`employee[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/employee/delete/multiple",
          formData,
          "labels.coordinator_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };
  const [data, setData] = useState({
    column: [
      {
        lable: "",
        name: "checkbox",
        width: "10px",
        align: "center",
        minWidth: 20,
      },
      {
        lable: "labels.name",
        name: "name",
        width: "10px",
        align: "left",
        minWidth: 20,
      },
      {
        lable: "labels.code",
        name: "code",
        width: "10px",
        align: "left",
        minWidth: 20,
      },
      {
        lable: "labels.email",
        name: "email",
        width: "10px",
        align: "left",
        minWidth: 20,
      },
      {
        lable: "labels.phone_number",
        name: "phone",
        width: "10px",
        align: "left",
        minWidth: 20,
      },
      {
        lable: "labels.assigned_to",
        name: "assignTo",
        width: "10px",
        align: "left",
        minWidth: 20,
      },
    ],
    pageData: {
      title: "tableTitle.1.list_of_coordinators",
      page: "Coordinators",
      infoURL: "/coordinators/edit-coordinators",
      hasVariable: true,
      handlExport: (page, size) => handleExport(page, size),
      buttonGroup: [
        {
          title: "labels.action",
          type: "actions",
          icon: <AddIcon />,
          variant: "outlined",
          class: "buttonFill ml-0",
          callback: () => {},
        },
        {
          title: "titles.add_new",
          icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
          class: "buttonFill",
          variant: "contained",
          callback: () => {
            if (getUserHasAccess("coordinator", 84)) {
              handleModel("addCoordinator");
            } else {
              setAuthorityModal(true);
            }
          },
        },
      ],
    },
  });
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_coordinators",
    data: [
      {
        lable: "labels.name",
        field: { type: "text", required: true },
        name: "name",
      },
      {
        lable: "labels.email",
        field: {
          type: "text",
          required: true,
        },
        name: "email",
      },
      {
        lable: "labels.password",
        field: { type: "text", required: true },
        name: "password",
      },
      {
        lable: "labels.branch",
        field: {
          type: "dropdown",
          findLable: "branchName",
          required: true,
          option: [],
        },
        name: "branch_id",
      },
      {
        lable: "labels.assign_to",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
        name: "assignTo",
      },
      {
        lable: "labels.job_position",
        name: "jobId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "title",
          option: [],
        },
      },
      {
        lable: "labels.responsible_hr",
        name: "responsibleHrId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
      {
        lable: "labels.direct_manager",
        name: "directManagerId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setCoordinatorState({});
          setErrors({});
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addCoordinator(val);
        },
      },
    ],
  });
  const [coordinatorData, setCoordinatorData] = useState();
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [search, setSearch] = useState("");
  const [coordinatorState, setCoordinatorState] = useState({});
  const [totalRecords, setTotalrecords] = useState(0);
  const [errors, setErrors] = useState({});
  const handleModel = (val) => {
    if (val == "addCoordinator") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
    if (val == "executing") {
      setModelData({
        ...modalData,
        title: "titles.change_coordinators_to_partner",
        model: true,
        data: [
          {
            lable: "labels.name",
            field: {
              type: "dropdown",
              option: ["Mohamed Ahmed", "Mohamed Ahmed"],
            },
          },
        ],
        buttonGroup: [
          {
            value: "labels.discard",
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              handleCloseModel();
            },
          },
          {
            value: "labels.save",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: () => {
              handleCloseModel();
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.coordinatorValidate(coordinatorState);
      setErrors(error);
    }
  }, [coordinatorState]);

  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/employee/exportcoordinators/`
    );
    props.setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    //TODO: API needs to be updated
    getJobPositions();
    const res = await http.getAllDataApi(
      t,
      `/api/employee/all/coordinators/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count);
    setCoordinatorData(res?.data);
    props.setLoader(false);
  };

  const modalApiData = async () => {
    let res = await http.getApi(t, `/api/employee/dropdown/all`);
    let allBranches = await loadOptions(
      t,
      "",
      null,
      "post",
      "/api/branch/details"
    );
    let s1 = { ...modalData };
    s1.data[3].field.option = allBranches;
    s1.data[4].field.option = res;
    setModelData(s1);
  };

  useEffect(() => {
    modalApiData();
  }, []);

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  const getJobPositions = async () => {
    props.setLoader(true);
    axios
      .all([
        loadOptions(
          t,
          "",
          null,
          "get",
          `/api/setting/department/job-position/3`
        ),
        loadOptions(t, "", null, "get", "/api/employee/all/department/8"),
        loadOptions(t, "", null, "get", `/api/employee/all/department/3`),
      ])
      .then((res) => {
        let s1 = { ...modalData };
        s1.data[5].field.option = res[0];
        s1.data[6].field.option = res[1];
        s1.data[7].field.option = res[2];
        setModelData(s1);
      });

    props.setLoader(false);
  };

  const addCoordinator = async (obj) => {
    let error = validate.coordinatorValidate(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("password", obj.password);
      formData.append("branchId", obj.branch_id);
      formData.append("departmentId", 3); //dept id for coordinator
      formData.append("assignTo", obj.assignTo);
      formData.append("email", obj.email);
      formData.append("langId", "1");
      formData.append("jobId", obj.jobId);
      formData.append("responsibleHrId", obj.responsibleHrId);
      formData.append("directManagerId", obj.directManagerId);
      const res = await http.postApi(t, "/api/employee/create", formData);
      if (res) {
        getApiData();
        handleCloseModel();
      }
    } else {
      setErrors(error);
    }
    props.setLoader(false);
  };

  return (
    <div className="">
      {/* <ComponentModal modalData={data.modalData} /> */}
      <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setCoordinatorState}
        perantState={coordinatorState}
      />
      {/* <div className="CommonPd">
        <div className="dashTopGrid pending_req_box">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("titles.pending_requests")}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid className="gridLeft">
              <Typography component="span" variant="body2">
                {t("titles.validation_request_pending")}
              </Typography>
            </Grid>
            <Grid className="gridLeftbtn">
              <ComponentButton
                value={t("labels.execute")}
                variant={"contained"}
                class={"buttonFill FillBtn"}
                callback={() => {
                  handleModel("executing");
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid className="gridLeft">
              <Typography component="span" variant="body2">
                {t("titles.request_assigning_coordinator")}
              </Typography>
            </Grid>

            <Grid className="gridLeftbtn">
              <ComponentButton
                value={t("labels.execute")}
                variant={"contained"}
                class={"buttonFill FillBtn"}
                callback={() => {
                  handleModel("executing");
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div> */}
      {/* {coordinatorData && ( */}
      <ComponentTable
        rows={coordinatorData}
        columns={data.column}
        pageData={data.pageData}
        page={page}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
        selected={selectedData}
        handleSelected={handleSelected}
        handleAllselect={handleAllselect}
        handleDelete={handleDelete}
      />
      {/* )} */}
    </div>
  );
}
