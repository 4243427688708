import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal, Grid, Button } from "@mui/material";
import ComponentButton from "../componentGroups/componentButton";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { useTranslation } from "react-i18next";
import Svg from "../../assets/svg/svg";
import { Avatar } from "@mui/material";
import profile from "../../assets/images/profile-edit.png";
import EditIcon from "@mui/icons-material/Edit";
import http from "../../api/axios";
import ComponentCaptureImage from "../componentGroups/componentCaptureImage";
const LogoutModal = ({ modalData, logOut, closeModal, setModalData }) => {
  const { t } = useTranslation();
  const [openCapture, setOpenCapture] = useState(false);
  const [state, setState] = useState({
    nickname: "",
    show_image: null,
    uploadFile: null,
    profile_pic_path: "",
    logoutTimer: {},
  });
  const [openImage, setOpenImage] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 20,
  };

  const setProfile = async () => {
    var formdata = new FormData();
    formdata.append("pic", state?.uploadFile);
    if (state?.profile_pic_path != "") {
      let res = await http.putApi(t, `/api/user/profile/pic/`, formdata);
    } else {
      let res = await http.postApi(t, `/api/user/profile/pic/`, formdata);
    }
    closeModal();
  };

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/user/profile/get`);
    let res1 = await http.getApi(t, `/api/user/logouttimer`);
    let data = {
      nickname: res?.nickname ?? "",
      profile_pic_path: res?.profile_pic_path ?? "",
      logoutTimer: res1,
    };
    setState(data);
  };

  const handleCapture = () => {
    setOpenCapture(true);
    setOpenImage(false);
  };

  const uploadFile = (e, type = 2) => {
    if (type == 1) {
      setState({
        ...state,
        uploadFile: e,
        show_image: URL.createObjectURL(e),
      });
    } else if (type == 2) {
      setState({
        ...state,
        uploadFile: e.target.files[0],
        show_image: URL.createObjectURL(e.target.files[0]),
      });
    }
    setOpenCapture(false);
    setOpenImage(!openImage);
  };

  useEffect(() => {
    getApiData();
  }, [modalData]);

  return (
    <>
      <Modal
        className="MainModal"
        open={modalData.model}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="ModalBG" sx={style}>
          <div className="ModalHeading">
            <Typography
              variant="h4"
              sx={{ paddingTop: "1px !important" }}
              className="logoutHead"
            >
              {modalData.title}
            </Typography>
          </div>

          {modalData?.type == "nickName" ? (
            <div>
              {openCapture ? (
                <div className="videoClass">
                  <ComponentCaptureImage uploadFile={uploadFile} />
                </div>
              ) : (
                <div className="proImg">
                  <div className="profile-ImGsec">
                    {state?.show_image != null ? (
                      <img
                        className="ProEditImg"
                        // alt="ProfileEditPicture"
                        src={state?.show_image}
                      />
                    ) : (
                      <img
                        className="ProEditImg"
                        // alt="ProfileEditPicture"
                        src={`${http.baseURL}/${state?.profile_pic_path}`}
                      />
                    )}
                    <div className="ProfileEditIocon">
                      <EditIcon
                        className="editI"
                        onClick={() => setOpenImage(!openImage)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="ProifileSection">
                {openImage && (
                  <div className="ProfileditsEc uploade_imagee">
                    <ul className="menuTableListP  take_a_picture">
                      {/* <li><a ><Svg type={"change_profile_picture"} /><span class=""> {t("titles.take_a_picture")}</span></a></li> */}
                      <Button variant="outlined" component="label" className="">
                        <Typography sx={{ pr: 1 }}>
                          {" "}
                          <Svg type="uplod_pic" />
                        </Typography>{" "}
                        <span
                          className="textLabel"
                          style={{ marginLeft: "8px" }}
                        >
                          {t("labels.upload_image")}
                        </span>
                        <input
                          hidden
                          accept="image/png, image/jpeg"
                          type="file"
                          onChange={(e) => uploadFile(e)}
                          multiple
                        />
                      </Button>
                      <Button
                        variant="outlined"
                        component="label"
                        className=""
                        onClick={() => handleCapture()}
                      >
                        <Typography sx={{ pr: 1 }}>
                          {" "}
                          <Svg type="take_a_pic" />
                        </Typography>{" "}
                        <span className="textLabel">
                          {t("labels.capture_image")}
                        </span>
                      </Button>
                    </ul>
                  </div>
                )}
              </div>

              <Grid className="gridLeftbtnP">
                <ComponentButton
                  value="Discard"
                  variant={"outlined"}
                  class={"buttonBlank Mright"}
                  callback={() => setModalData({ model: false })}
                />
                <ComponentButton
                  value="Save Changes"
                  variant={"contained"}
                  class={"buttonFill"}
                  callback={() => setProfile()}
                />
              </Grid>
            </div>
          ) : (
            <div>
              <p className="logoutP">{modalData.description}</p>
              <div className="timerBlock">
                <span>{state?.logoutTimer?.hour}</span>
                <span>{state?.logoutTimer?.min}</span>
                <span>{state?.logoutTimer?.sec}</span>
              </div>
              <div className="logoutConfirm">
                <ComponentButton
                  value={t("titles.keep_me_logedin")}
                  variant="outlined"
                  class="buttonBlank outlineBtn Mright "
                  callback={() => closeModal()}
                />
                <ComponentButton
                  value={t("titles.logout")}
                  variant="contained"
                  class="buttonFill outlineBtn"
                  callback={() => logOut()}
                />
              </div>
            </div>
          )}
          {/* dddddddddddddddddddddddddddddddddddddddddddddddd */}
        </Box>
      </Modal>
    </>
  );
};

export default LogoutModal;
