import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import GenericModal from "../../componentGroups/genericModal";
import useFormatTime from "../../../Hooks/useFormatTime";

const DashBoardAttendanceRequest = (props) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const { globalToLocal } = useFormatTime();
  const [attendanceRequest, setAttendanceRequest] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [attendanceRequestToShow, setAttendanceRequestToShow] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [attendanceData, setAttendanceData] = useState({
    name: "",
    code: "",
    departmentId: "",
    checkIn: "",
    checkOut: "",
  });
  const getRequests = async () => {
    setLoader(true);
    const res = await http.getApi(t, `/api/employee/attendence/request`);
    setLoader(false);
    if (!!res && res?.length > 0) {
      setAttendanceRequest(res);
    } else {
      setAttendanceRequest([]);
    }
  };

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setAttendanceRequestToShow(attendanceRequest);
    } else {
      if (attendanceRequest?.length > 5) {
        let sliced = [...attendanceRequest]?.slice(0, 5);
        setAttendanceRequestToShow(sliced);
      } else {
        setAttendanceRequestToShow(attendanceRequest);
      }
    }
  }, [showAll, attendanceRequest]);

  const getEmployeeAttendanceData = async () => {
    setLoader(true);
    let res = await http.getAllDataApi(
      t,
      `/api/employee/attendence-detail/${selectedId}`
    );
    setAttendanceData({
      ...attendanceData,
      checkIn:
        res?.data?.checkIn && res?.data?.checkIn !== "INVALID DATE"
          ? globalToLocal(res?.data?.checkIn, "HH:mm:ss")
          : "-",
      checkOut:
        res?.data?.checkOut && res?.data?.checkOut !== "INVALID DATE"
          ? globalToLocal(res?.data?.checkOut, "HH:mm:ss")
          : "-",
      updatedCheckIn:
        res?.data?.updatedCheckIn &&
        res?.data?.updatedCheckIn !== "INVALID DATE"
          ? globalToLocal(res?.data?.updatedCheckIn, "HH:mm:ss")
          : "-",
      updatedCheckOut:
        res?.data?.updatedCheckOut &&
        res?.data?.updatedCheckOut !== "INVALID DATE"
          ? globalToLocal(res?.data?.updatedCheckOut, "HH:mm:ss")
          : "-",
      date: globalToLocal(res?.data?.date, "DD-MM-YYYY"),
      departmentId: res?.data?.departmentId,
      code: res?.data?.code,
      name: res?.data?.name,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (selectedId) {
      getEmployeeAttendanceData();
    }
  }, [selectedId]);

  const handlClose = () => {
    setOpen(false);
    setSelectedId("");
    setRequestId("");
  };

  const handleApproval = async (status) => {
    setLoader(true);
    handlClose();
    let data = {
      status: status,
    };
    await http.postApi(
      t,
      `/api/employee/attendence/approve-request/${requestId}`,
      data
    );
    getRequests();
    setLoader(false);
    handlClose();
  };

  return (
    <>
      <GenericModal
        title={"Attendance Update Request"}
        open={open}
        enableCloseIcon={true}
        onClose={handlClose}
      >
        <div
          className="scroll-auto ModalContent"
          style={{ minHeight: "150px", padding: "0 30px", marginTop: "10px" }}
        >
          <div>
            <div className="flex-space-between">
              {t("labels.employee_name")}
              <span className="atStyle">{attendanceData?.name}</span>
            </div>
            <div className="flex-space-between">
              {t("labels.date")}
              <span className="atStyle"> {attendanceData?.date}</span>
            </div>
          </div>
          <div>
            <div className="flex-space-between">
              {t("labels.previous_checkin_time")}:
              <span className="atStyle">{attendanceData?.checkIn}</span>
            </div>
            <div className="flex-space-between">
              {t("labels.previous_checkout_time")}:
              <span className="atStyle"> {attendanceData?.checkOut}</span>
            </div>
          </div>
          <div>
            <div className="flex-space-between">
              {t("labels.updated_checkin_time")}:
              <span className="atStyle">{attendanceData?.updatedCheckIn} </span>
            </div>
            <div className="flex-space-between">
              {t("labels.updated_checkout_time")}:
              <span className="atStyle">
                {" "}
                {attendanceData?.updatedCheckOut}
              </span>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid
            container
            className="modalGroup"
            style={{
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <ComponentButton
                value={t("labels.reject")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => handleApproval(3)}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.approve")}
                variant="contained"
                class="buttonFill FillBtn"
                style={{ marginLeft: "10px" }}
                callback={() => handleApproval(1)}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      <div className="CommonPd">
        <div className="dashTopGrid pending_req_box">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("Attendance Request")}
              </Typography>
            </Grid>
            <Grid className="btnStyle">
              {attendanceRequest?.length > 5 && (
                <ComponentButton
                  value={showAll ? t("labels.show_less") : t("labels.view_all")}
                  variant={"outlined"}
                  class={"buttonBlank outlineBtn "}
                  callback={() => {
                    setShowAll(!showAll);
                  }}
                />
              )}
            </Grid>
          </Grid>

          {attendanceRequest?.length > 0 ? (
            attendanceRequestToShow?.map((op) => (
              <div className="dashboard Btn">
                <Grid className="gridLeft">
                  <Typography component="span" variant="body2">
                    {op?.name} Requested Attendance Update on {op?.date}
                  </Typography>
                </Grid>
                <Grid className="gridLeftbtn">
                  <ComponentButton
                    value={t("labels.view_details")}
                    variant={"outlined"}
                    class={"buttonBlank Mright"}
                    callback={() => {
                      setOpen(true);
                      setSelectedId(op?.attendanceId);
                      setRequestId(op?.id);
                    }}
                  />
                </Grid>
              </div>
            ))
          ) : (
            <span className="noDataFound">
              {" "}
              {t("labels.no_pending _requests_found")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default DashBoardAttendanceRequest;
