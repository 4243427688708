import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import ComponentTable from "../../componentGroups/componentTable";
import ComponentModal from "../../componentGroups/componectModel";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import http from "../../../api/axios";
import MatterEditView from "./matterEditView";
import validate from "../../../Hooks/client_validation";
import { loadOptions } from "../../../api/utils/common";
import axios from "axios";
import LoadingContext from "../../../context/LoaderContext";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import TabPanel from "../Allocation/TabPanel";
import ArchivedMatters from "./ArchivedMatters";

export default function Matters() {
  const { setLoader } = useContext(LoadingContext);
  const { t, i18n } = useTranslation();
  const [matterModal, setMatterModal] = useState({
    open: false,
    id: "",
  });
  const { setAuthorityModal } = useContext(AuthorityContext);

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(rows?.length);
  const [column, setColumn] = useState([
    {
      lable: "labels.matter_name",
      name: "matterName",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.matter_code",
      name: "matterCode",
      width: "10px",
      align: "left",
    },
    { lable: "labels.lawyer", name: "lawyer", width: "10px", align: "left" },
    {
      lable: "labels.department",
      name: "departmentName",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.status",
      name: "status",
      width: "10px",
      align: "left",
    },
  ]);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [data, setData] = useState({
    // title: "labels.matters",
    page: "Matters",
    infoCallback: (row) => {
      if (getUserHasAccess("matters", 212)) {
        setMatterModal({
          open: true,
          id: row?.id,
        });
      } else {
        setAuthorityModal(true);
      }
    },
    buttonGroup: [
      {
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("matters", 211)) {
            handleModel("addMatter");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });
  const [dropDownOptions, setDropDownOptions] = useState([])
  const [errors, setErrors] = useState({});
  const [matterData, setMatterData] = useState({
    matterName: "",
    lawyer: "",
    department: "",
    client: "",
    contract: [],
  });

  const [modalData, setmodelData] = useState({
    model: false,
    title: "titles.add_new_matter",
    data: [
      {
        lable: "labels.matter_name",
        field: { type: "text", required: true },
        name: "matterName",
      },
      {
        lable: "labels.client",
        field: {
          type: "dropdown",
          option: [],
          required: true,
          findLable: "name",
        },
        name: "client",
      },
      {
        lable: "labels.lawyer",
        field: {
          type: "dropdown",
          option: [],
          required: true,
          findLable: "option",
        },
        name: "lawyer",
      },
      {
        lable: "labels.department",
        field: {
          type: "dropdown",
          option: [],
          required: true,
          findLable: "departmentName",
        },
        name: "department",
      },
      {
        lable: "labels.contract",
        field: {
          type: "multiple",
          required: true,
          multiple: true,
          setlable: "name",
          option: [],
        },
        name: "contract",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (obj) => {
          addMatter(obj);
        },
      },
    ],
  });

  const handleCloseModel = () => {
    setMatterData({
      matterName: "",
      lawyer: "",
      department: "",
      client: "",
      contract: [],
    });
    setErrors({});
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addMatter") {
      setmodelData({
        ...modalData,
        model: true,
      });
    }
  };
  const getDropDownOptions = async (clientId, val = false) => {
    setLoader(true);
    const contracts = await http.getApi(
      t,
      `/api/matter/client/contracts/${clientId}`
    );
    const departments = await http.getApi(t, `/api/department`);
    // console.log(departments);
    axios
      .all([
        loadOptions(
          t,
          "lawyer",
          "",
          "get",
          "/api/employee/dropdown/all/attorney"
        ),

      ])
      .then((res) => {
        const lawyers = res[0]?.map((item) => {
          return { ...item, option: item?.name };
        });
        let s1 = { ...modalData };
        s1.data[2].field.option = lawyers;
        s1.data[3].field.option = departments;
        s1.data[4].field.option = contracts;
        setmodelData(s1);
        setDropDownOptions(s1)
        setLoader(false);
      });
  };

  const getClientsData = async (val = false) => {
    setLoader(true);
    const clients = await http.postApi(
      t,
      `/api/client`,
      undefined, false
    );
    console.log(clients?.data);
    if (clients?.data?.length > 0) {
      let s1 = { ...modalData };
      s1.data[1].field.option = clients?.data;
      setmodelData(s1);
      setLoader(false);
    }
  }
  useEffect(() => {
    if (matterData?.client) getDropDownOptions(matterData?.client);
  }, [matterData?.client]);

  useEffect(() => {
    getClientsData();
  }, []);


  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.matterValidate(matterData);
      setErrors(error);
    }
  }, [matterData]);

  const addMatter = async (obj) => {


    let error = validate.matterValidate(obj);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      let formData = new FormData();
      formData.append("matterName", obj?.matterName);
      formData.append("lawyer", obj?.lawyer);
      formData.append("department", obj?.department);
      obj?.contract?.forEach((element, index) => {
        formData.append(`contract[${index}]`, element?.id);
      });
      formData.append("client", obj?.client);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.postApi(t, "/api/matter/create", formData);
      if (!!res) {
        handleCloseModel();
        getApiData();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }

  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/matter/all?page=${pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count);
    setRows(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  return (
    <>
      <ComponentModal
        modalData={modalData}
        setPerantState={setMatterData}
        perantState={matterData}
        errors={errors}
        MHeight={"270px"}
      />
      <MatterEditView
        open={matterModal?.open}
        onCLose={() =>
          setMatterModal({
            open: false,
            id: "",
          })
        }
        id={matterModal?.id}
        setLoader={setLoader}
        getApiData={getApiData}
        clientId={matterData?.client}
        dropDownOption={dropDownOptions}
      />
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.matters")}

              </Typography>
            </Grid>
          </Grid>

          <Box
            className="TaskTab TabActive"
            sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >

              <Tab
                className="iconText"
                label={t("labels.all_requests")}
                value={0}
              />


              <Tab
                className="iconText"
                label={t("labels.archived")}
                value={1}
              />

            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ComponentTable
              rows={rows}
              columns={column}
              pageData={data}
              page={page}
              setPage={setPage}
              rowsPerPage={size}
              setRowsPerPage={setSize}
              search={search}
              setSearch={setSearch}
              totalRecords={totalRecords}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ArchivedMatters />
          </TabPanel>
        </Grid>
      </div >
    </>
  );
}
