import React from "react";
import { LoadingProvider } from "./LoaderContext";
import { AuthorityProvider } from "./AuthorityContext";
import { BreadCrumbNameProvider } from "./BreadCrumbContext";

const ContextProvider = ({ children }) => {
  return (
    <LoadingProvider>
      <AuthorityProvider>
        <BreadCrumbNameProvider>{children}</BreadCrumbNameProvider>
      </AuthorityProvider>
    </LoadingProvider>
  );
};

export default ContextProvider;
