import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import "./style.css";
import { Link } from "react-router-dom";
import Info from "../../../assets/images/info.png";
import IosShareIcon from "@mui/icons-material/IosShare";
import FilterListIcon from "@mui/icons-material/FilterList";
import Paper from "@mui/material/Paper";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ComponentButton from "../../componentGroups/componentButton";
import ComponentTable from "../../componentGroups/componentTable";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";

const ExpensesProfit = () => {
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState([
    ["October 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["September 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["August 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["July 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["June 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["May 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["April 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["March 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["February 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
    ["January 2022", "1,250,000 EG", "1,250,000 EG", "-650.000 EG"],
  ]);
  const [column, setColumn] = React.useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 20,
    },
    {
      lable: "labels.month",
      name: "month",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.expenses",
      name: "expenses",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.date",
      name: "date",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.difference",
      name: "dept",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
  ]);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(rows.length);
  const [data, setData] = React.useState({
    title: "tableTitle.9.show_result_from",
    page: "Expense and Profit",
    buttonGroup: [
      {
        title: "labels.export",
        icon: <IosShareIcon sx={{ color: "white", paddingRight: "10px" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {},
      },
    ],
  });

  return (
    <>
      <div class="ExpensesProfitPage">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardContent>
                <div className="progress">
                  <div className="cardContact1">
                    <Typography
                      className="expenseProfitTophead"
                      sx={{ color: "#2B564C", marginBottom: "30px" }}
                    >
                      {t("partner.expenses")}
                    </Typography>
                    <Typography sx={{ color: "#2B564C !important" }}>
                      {t("partner.eg")}
                    </Typography>
                  </div>
                  <div className="progressBar">
                    <CircularProgressbar
                      value={12.5}
                      text={`${12.5}%`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#2B564C`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d6d6d6",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "rgba(183, 161, 96, 1)",
                          // Text size
                          fontSize: "25px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardContent>
                <div className="progress">
                  <div className="cardContact1">
                    <Typography
                      className="expenseProfitTophead"
                      sx={{ color: "#2B564C", marginBottom: "30px" }}
                    >
                      {t("partner.profits")}
                    </Typography>
                    <Typography sx={{ color: "#2b564c !important" }}>
                      {t("partner.eg")}
                    </Typography>
                  </div>
                  <div className="progressBar">
                    <CircularProgressbar
                      value={12.5}
                      text={`${12.5}%`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#56332B`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d6d6d6",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "rgba(183, 161, 96, 1)",
                          // Text size
                          fontSize: "25px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardContent>
                <div className="progress">
                  <div className="cardContact1">
                    <Typography
                      className="expenseProfitTophead"
                      sx={{ color: "#2B564C", marginBottom: "30px" }}
                    >
                      {t("partner.difference")}
                    </Typography>
                    <Typography sx={{ color: "#2B564C !important" }}>
                      {t("partner.eg")}
                    </Typography>
                  </div>
                  <div className="progressBar">
                    <CircularProgressbar
                      value={12.5}
                      text={`${12.5}%`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `rgba(183, 161, 96, 1)`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d6d6d6",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "rgba(183, 161, 96, 1)",
                          // Text size
                          fontSize: "25px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <div className="mainContainer">
        <ComponentTable
          rows={rows}
          columns={column}
          pageData={data}
          page={page}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          totalRecords={totalRecords}
        />
      </div>
    </>
  );
};

export default ExpensesProfit;
