import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import LoadingContext from "../../../context/LoaderContext";
import http from "../../../api/axios";
import moment from "moment";
import useFormatTime from "../../../Hooks/useFormatTime";

const DashboardMeeting = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { globalToLocal } = useFormatTime();
  const { setLoader } = useContext(LoadingContext);
  const [meetings, setMeetings] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [meetingsToShow, setMeetingsToShow] = useState([]);

  const getLatestDecisions = async () => {
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/meeting/list?date=${moment(new Date()).format("YYYY-MM-DD")}`
    );
    setLoader(false);
    if (!!res?.data && res?.data?.length > 0) {
      const data = res?.data?.map((item) => {
        return {
          ...item,
          date: globalToLocal(res?.date, "DD-MM-YYYY"),
          start_time: globalToLocal(item?.start_time, "h:mm a"),
        };
      });
      setMeetings(data);
    } else {
      setMeetings([]);
    }
  };

  useEffect(() => {
    getLatestDecisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setMeetingsToShow(meetings);
    } else {
      if (meetings?.length > 5) {
        let sliced = [...meetings]?.slice(0, 5);
        setMeetingsToShow(sliced);
      } else {
        setMeetingsToShow(meetings);
      }
    }
  }, [showAll, meetings]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography className="morningText" variant="h4">
              {t("titles.meeting_reminder")}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {meetings?.length > 5 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
          </Grid>
        </Grid>

        {meetings?.length !== 0 ? (
          meetingsToShow?.map((op) => (
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid className="gridLeft">
                <Typography component="span" variant="body2">
                  {op.title} schedule for today at {op?.start_time}
                </Typography>
              </Grid>
              <Grid className="gridLeftbtn">
                <ComponentButton
                  value={t("labels.view_details")}
                  variant={"outlined"}
                  class={"buttonBlank "}
                  callback={() =>
                    history.push({
                      pathname: "/meetingDetails",
                      state: { id: op?.id },
                    })
                  }
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <span className="noDataFound"> {t("labels.no_meetings_today")}</span>
        )}
      </div>
    </div>
  );
};

export default DashboardMeeting;
