import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import useFormatTime from "../../../../Hooks/useFormatTime";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";

// predeined values to reuse the same component while in add and edit
const PAGE_VALUES = {
  title: "routes.task_working_details",
  endTimeApi: "/api/task/end",
  getDataApi: "/api/task/prev-hour-working-detail",
  saveDetialsApi: "/api/task/save-hours-details",
};

export default function AddEditTaskLog() {
  const { taskId, logId } = useParams();
  const { t } = useTranslation();
  const { globalToLocal, localToGlobal } = useFormatTime();
  const { setLoader } = useContext(LoadingContext);
  const [taskLogDetials, setTaskLogDetials] = useState({
    details: "",
    startDateTime: "",
    endDateTime: "",
  });
  const [viewMode, setViewMode] = useState(false);

  const getLogDetails = async () => {
    const api = `${PAGE_VALUES.getDataApi}/${logId}`;
    setLoader(true);
    const res = await http.getApi(t, `${api}`);
    setTaskLogDetials({
      ...res,
      startDateTime: globalToLocal(res?.startTime, "DD-MM-YYYY h:mm:a"),
      endDateTime: globalToLocal(res?.endTime, "DD-MM-YYYY h:mm:a"),
    });
    setLoader(false);
  };

  useEffect(() => {
    if (logId) {
      getLogDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logId]);

  useEffect(() => {
    if (taskId && !logId) {
      setEndTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const setEndTime = async () => {
    setLoader(true);
    const time = new Date();
    const endTime = localToGlobal(time);
    const formdata = new FormData();
    formdata.append("task_id", taskId);
    formdata.append("status", 1);
    formdata.append("time", endTime);
    const res = await http.postApi(
      t,
      `${PAGE_VALUES.endTimeApi}/${taskId}`,
      formdata
    );
    if (res?.data?.id) {
      setTaskLogDetials({
        ...res.data,
        startDateTime: globalToLocal(res?.data?.startTime, "DD-MM-YYYY h:mm:a"),
        endDateTime: globalToLocal(res?.data?.endTime, "DD-MM-YYYY h:mm:a"),
      });
    }
    setLoader(false);
  };

  const updateTasklog = async () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("details", taskLogDetials?.details ?? "");
    const res = await http.postApi(
      t,
      `${PAGE_VALUES.saveDetialsApi}/${logId ?? taskLogDetials?.id}`,
      formdata
    );
    if (res?.data?.id) {
      setTaskLogDetials({
        ...res.data,
        startDateTime: globalToLocal(res?.data?.startTime, "DD-MM-YYYY h:mm:a"),
        endDateTime: globalToLocal(res?.data?.endTime, "DD-MM-YYYY h:mm:a"),
      });
    }
    setLoader(false);
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                justifyContent: "space-between",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(PAGE_VALUES.title)}
                </Typography>
              </Grid>
              {!viewMode && (
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "50px" }}
                    className="buttonFill"
                    onClick={() => updateTasklog()}
                  >
                    {t("labels.save")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.start_date")}/{t("labels.time")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="startTime"
                    disable={true}
                    value={taskLogDetials.startDateTime}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.end_date")}/{t("labels.time")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="startTime"
                    disable={true}
                    value={taskLogDetials.endDateTime}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.working_details")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="details"
                    minRows={10}
                    disable={viewMode}
                    placeholder={t("placeholder.enter_details")}
                    value={taskLogDetials?.details}
                    callback={(e) =>
                      setTaskLogDetials({
                        ...taskLogDetials,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}
