import React, { useContext, useEffect, useState } from "react";
import LoadingContext from "../../../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import { putApi } from "../../../../api/axios";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ComponentTextString from "../../../componentGroups/componentTextString";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentRadioGroup from "../../../componentGroups/componentRadioGroup";
import FileView from "../../PartnerScreens/Documents/FileView";

const INITIAL_VALUE = {
  sendTo: "",
  sendFrom: "",
  description: "",
  departmentName: "",
  title: "",
  clientName: "",
  matterName: "",
  periority: "",
  docTitle: "",
  docFolder: "",
  docName: "",
  attachment: "",
  status: 1,
};

const ConsultationInfo = () => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const consultation = JSON.parse(localStorage.getItem("consultation"));
  const [reqData, setReqData] = useState(INITIAL_VALUE);

  const [statusValue, setStatusValue] = useState(consultation.status);

  const updateStatus = async () => {
    putApi(t, `/api/consultation/changestatus/${reqData?.id}`, {
      status: statusValue,
    }).then(() => {
      history.push("/ask-consultation");
    });
  };

  // const handleStatusChange = (event) => {
  //   setStatusValue(event.target.value);
  // };

  const statusOptions = [
    { value: 1, label: t('labels.not_started') },
    { value: 2, label: t('labels.started') },
    { value: 3, label: t('labels.hold') },
    { value: 4, label: t('labels.in_progress') },
    { value: 5, label: t('labels.done') },
  ];

  // console.log(statusValue);
  useEffect(() => {
    setLoader(true);
    if (consultation) setReqData(consultation);
    setLoader(false);
    return () => {
      localStorage.removeItem("consultation");
    };
  }, []);

  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography
              className="DashTitle" variant="h4">
              {t("labels.consultation_req")}
            </Typography>
          </div>
          <div
            style={{
              marginLeft: "10px",
              marginTop: "40px",
              paddingInline: "20px",
            }}
          >
            <Grid className="AddEmpGenInfo" container rowSpacing={4}>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.title")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="title"
                    value={reqData?.title}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("titles.department_name")}{" "}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="departmentName"
                    value={reqData?.department}
                    disable={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.send_to")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="sendTo"
                    value={reqData?.sendTo}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.client_name")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="clientName"
                    value={reqData?.client}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.matter_name")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="matterName"
                    value={reqData?.matter}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.priority")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="periority"
                    value={reqData?.periority}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document_title")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="docTitle"
                    value={reqData?.docTitle}
                    disable={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document_folder")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="docFolder"
                    value={reqData?.docFolder}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document_name")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="docName"
                    value={reqData?.docName}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.status")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentRadioGroup
                    options={statusOptions}
                    selectedOption={statusValue}
                    setSelectedOption={setStatusValue}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.description")}{" "}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="subject"
                    minRows={4}
                    placeholder={"Enter the description"}
                    value={reqData?.description}
                    disable={true}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.attachments")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={10} sx={{ paddingTop: 0 }}>

                {reqData?.file ? <FileView index={1} item={reqData?.file} /> : <div
                  className="Mright"
                  style={{
                    width: "fit-content",
                  }}
                >
                  <ComponentTextString
                    className="GenDropdown"
                    name="attachment"
                    value={

                      "No Attachments"
                    }
                    disable={true}
                  />
                </div>}
              </Grid>

            </Grid>

            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{ marginTop: "5px" }}
              >
                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    value={t("labels.go_back")}
                    variant="outlined"
                    class="buttonBlank Mright delbtnRefrence"
                    callback={() => {
                      history.push(`/ask-consultation`);
                    }}
                  />
                </Grid>

                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    value={t("labels.update")}
                    variant="contained"
                    class="buttonFill FillBtn"
                    callback={() => {
                      updateStatus();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default ConsultationInfo;
