/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ComponentTextString from "../../componentGroups/componentTextString";
import ComponentSelect from "../../componentGroups/componentSelect";
import ComponentButton from "../../componentGroups/componentButton";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import { loadOptions } from "../../../api/utils/common";
import MinHeightTextarea from "../../componentGroups/componentTextarea";
import GenericModal from "../../componentGroups/genericModal";
import LoanInstallments from "./LoanInstallments";
import { getUserHasAccess } from "../../../utils/common";
import LogNoteAccordion from "../../componentGroups/logNotes/logNoteAccordian";
import { Client_KEY_LABELS } from "../../componentGroups/logNotes/Labels/client_key_lable";
import AuthorityContext from "../../../context/AuthorityContext";

const PAGE_VALUES = {
  add: "Add New Loan Request",
  edit: "Loan Detail",
  addApi: "/api/employee/loan-request",
  getDataApi: "/api/employee/loan-request",
};

const AddEditLoanRequest = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { loanId } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [errors, setErrors] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [disableEmployee, setDisableEmployee] = useState(false);
  const [loanData, setLoanData] = useState({
    empId: "",
    department: "",
    job: "",
    directManager: "",
    amount: "",
    period: "",
    installment: "",
    reason: "",
    status: "",
  });
  const [dropDownOptions, setDropDownOptions] = useState({
    employee: [],
  });

  const [open, setOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [managerId, setManagerId] = useState("");
  const [isHr, setIsHr] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [statusByUser, setStatusByUser] = useState({ 1: "", 2: "" });
  const [flag, setFlag] = useState(false);

  const getLoanData = async () => {
    setLoader(true);
    let res = await http.getExportApi(t, `${PAGE_VALUES.getDataApi}/${loanId}`);
    let users = {};
    const approvedBy = [];
    const rejectedBy = [];
    res?.data?.approvals?.map((item) => {
      users[item?.id] = item.status;
      if (!item?.isHr) {
        setManagerId(item?.id);
        if (user?.id === item?.id) {
          setIsManager(true);
        }
      } else if (user?.id === item?.id) {
        setIsHr(true);
      }
      if (item?.status === 1) {
        approvedBy.push(item?.name);
      }
      if (item?.status === 3) {
        rejectedBy.push(item?.name);
      }
      return item?.name;
    });
    const statusByUser = {
      1: approvedBy?.join(","),
      2: rejectedBy?.join(","),
    };
    setStatusByUser(statusByUser);
    setLoanData({
      ...loanData,
      empId: res?.data?.user_id ?? "",
      amount: res?.data?.amount ?? "",
      installment: res?.data?.installment ?? "",
      period: res?.data?.period ?? "",
      reason: res?.data?.reason ?? "",
      approvalUsers: users,
      status: res?.data?.status ?? "",
    });
    setViewMode(true);
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .all([loadOptions(t, "employee", "", "post", `/api/employee`)])
      .then((res) => {
        setDropDownOptions({
          ...dropDownOptions,
          employee: res[0],
        });
      })
      .finally(() => {
        const hasAllAcsess = getUserHasAccess("loan", 197);
        if (!hasAllAcsess) {
          setLoanData({
            ...loanData,
            empId: user?.id,
          });
          setDisableEmployee(true);
        }
        setLoader(false);
        if (loanId) {
          getLoanData();
        }
      });
  }, [loanId]);

  const validateErrors = (data) => {
    let error = {};
    if (data.reason === undefined || data.reason === "") {
      error.reason = "errors.enter_description";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(loanData);
      setErrors(error);
    }
  }, [loanData]);

  useEffect(() => {
    if (loanData?.empId && dropDownOptions?.employee?.length > 0) {
      const selectedEmp = dropDownOptions?.employee?.find(
        (item) => parseInt(item?.id) === parseInt(loanData?.empId)
      );
      if (selectedEmp) {
        setLoanData({
          ...loanData,
          department: selectedEmp?.departmentName ?? "",
          job: selectedEmp?.jobTitle ?? "",
          directManager: selectedEmp?.directManager ?? "",
        });
      }
    }
  }, [loanData?.empId]);

  const calculateInstallment = (amount, period) => {
    if (amount && period && amount !== 0 && period !== 0) {
      let installment = amount / period;
      setLoanData({
        ...loanData,
        amount: amount,
        period: period,
        installment: installment,
      });
    } else {
      setLoanData({
        ...loanData,
        amount: amount,
        period: period,
      });
    }
  };

  const handleAddEdit = async () => {
    let error = validateErrors(loanData);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      formData.append("user_id", loanData.empId);
      formData.append("amount", loanData.amount);
      formData.append("period", loanData.period);
      formData.append("installment", loanData.installment);
      formData.append("reason", loanData.reason);
      const res = await http.postApi(t, PAGE_VALUES?.addApi, formData);
      if (res?.status) {
        history.goBack();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setRejectionReason("");
  };

  const handleApproval = async (status, rejectionReason) => {
    setLoader(true);
    let data = {
      status: status,
    };
    if (status === 2) {
      data = { ...data, statusReason: rejectionReason };
    }
    await http.postApi(t, `/api/employee/loan-request-status/${loanId}`, data);
    setLoader(false);
    getLoanData();
    handleClose();
    setFlag(!flag);
  };

  return (
    <>
      <GenericModal title={"labels.rejection_reason"} open={open}>
        <div
          className="scroll-auto ModalContent"
          style={{ minHeight: "200px" }}
        >
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.rejection_reason")}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <MinHeightTextarea
                name="rejectionReason"
                value={rejectionReason}
                minRows={10}
                callback={(e) => setRejectionReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.send")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => handleApproval(2, rejectionReason)}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <Typography variant="h6" className="morningText">
                    {t(loanId ? PAGE_VALUES.edit : PAGE_VALUES.add)}
                  </Typography>
                </Grid>
                {loanData?.status !== "" && (
                  <Grid className="CustomWidth" item xs={11} md={9}>
                    <div
                      className="promotionStatus"
                      style={{
                        background:
                          loanData?.status === 1
                            ? "#02b935"
                            : loanData?.status === 2
                            ? "#FF0101"
                            : "#b5b1b1",
                      }}
                    >
                      {loanData?.status === 1
                        ? t("labels.approved")
                        : loanData?.status === 2
                        ? t("labels.rejected")
                        : t("labels.pending")}
                    </div>
                  </Grid>
                )}
              </div>

              <div>
                {loanData?.status !== 0 && (
                  <Grid className="CustomWidth" item xs={11} md={9}>
                    <div>
                      {console.log("here", statusByUser)}
                      {!!statusByUser[1] && (
                        <div>Approved By: {statusByUser[1]}</div>
                      )}
                      {!!statusByUser[2] && (
                        <div>Rejected By: {statusByUser[2]}</div>
                      )}
                    </div>
                  </Grid>
                )}
              </div>
            </Grid>

            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.employee")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    className="GenDropdown"
                    disable={viewMode || disableEmployee}
                    name="empId"
                    value={loanData?.empId}
                    option={dropDownOptions?.employee}
                    callback={(evt) => {
                      setLoanData({
                        ...loanData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.department")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="department"
                      value={loanData?.department}
                      disable={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.job_position")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="job"
                      value={loanData?.job}
                      disable={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.manager")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="directManager"
                      value={loanData?.directManager}
                      disable={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Loan Amount")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="amount"
                      value={loanData?.amount}
                      disable={viewMode}
                      callback={async (evt) => {
                        if (!isNaN(evt.target.value)) {
                          calculateInstallment(
                            evt.target.value,
                            loanData?.period
                          );
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Installment Period")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      type="number"
                      name="period"
                      value={loanData?.period}
                      disable={viewMode}
                      callback={async (evt) => {
                        if (!isNaN(evt.target.value)) {
                          calculateInstallment(
                            loanData?.amount,
                            evt.target.value
                          );
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Monthly Installment")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="installment"
                      value={loanData?.installment}
                      disable={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.reason")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <MinHeightTextarea
                      class={"custonInput"}
                      name="reason"
                      minRows={4}
                      placeholder={"Enter the reason"}
                      value={loanData?.reason}
                      disable={viewMode}
                      callback={(evt) => {
                        setLoanData({
                          ...loanData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.reason && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.reason)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              {!viewMode && (
                <Grid
                  container
                  style={{ margin: "16px 0", justifyContent: "center" }}
                >
                  <Grid item>
                    <ComponentButton
                      value={t("labels.discard")}
                      variant={"outlined"}
                      class={"buttonBlank outlineBtn Mright"}
                      callback={() => history.goBack()}
                    />
                  </Grid>
                  <Grid item>
                    <ComponentButton
                      value={t("labels.save")}
                      variant="contained"
                      class="buttonFill FillBtn"
                      callback={() => {
                        handleAddEdit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {!!loanData?.approvalUsers?.hasOwnProperty(user.id) &&
                ((isManager && loanData?.approvalUsers[user.id] === 0) ||
                  (isHr &&
                    loanData?.approvalUsers[user.id] === 0 &&
                    loanData?.approvalUsers[managerId] === 1)) && (
                  <Grid
                    container
                    style={{ margin: "16px 0", justifyContent: "center" }}
                  >
                    <Grid item>
                      <ComponentButton
                        value={t("labels.reject")}
                        variant={"outlined"}
                        class={"buttonBlank outlineBtn"}
                        callback={() => setOpen(true)}
                      />
                    </Grid>
                    <Grid item>
                      <ComponentButton
                        value={t("labels.approve")}
                        variant="contained"
                        class="buttonFill FillBtn ml-3"
                        callback={() => {
                          if (getUserHasAccess("loan", 200)) {
                            handleApproval(1);
                          } else {
                            setAuthorityModal(true);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
            </div>
          </Grid>
        </div>
      </div>
      {loanId && <LoanInstallments loanId={loanId} />}
      {loanId && (
        <LogNoteAccordion
          id={`Loan-Status${loanId}`}
          labelKeys={Client_KEY_LABELS}
          flag={flag}
        />
      )}
    </>
  );
};

export default AddEditLoanRequest;
