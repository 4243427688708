const useBranchCreateValidate = (data, moment) => {
  let error = {};
  if (data.branchName == undefined || data.branchName == "") {
    error.branchName = "errors.select_branch";
  }
  if (data.country == undefined || data.country == "") {
    error.country = "errors.select_country";
  }
  if (data.city == undefined || data.city == "") {
    error.city = "errors.select_city";
  }
  if (data.departments == undefined || data.departments == "") {
    error.departments = "errors.select_department";
  }
  return error;
};

const useBranchEditValidate = (data) => {
  let error = {};
  if (data.branchName == undefined || data.branchName == "") {
    error.branchName = "errors.select_branch";
  }
  if (data.countryName == undefined || data.countryName == "") {
    error.countryName = "errors.select_country";
  }
  if (data.cityName == undefined || data.cityName == "") {
    error.cityName = "errors.select_city";
  }
  if (data.branches_addresses.length != 0) {
    error.addressArr = [];
    data?.branches_addresses.map((op, index) => {
      if (op.address == "" || op.address == undefined) {
        error.addressArr.push(`*Please enter the address ${index + 1}`);
      }
    });
    if (error.addressArr.length == 0) {
      delete error["addressArr"];
    }
  }

  return error;
};

export default {
  useBranchCreateValidate,
  useBranchEditValidate,
};
