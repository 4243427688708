import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import SegmentIcon from "@mui/icons-material/Segment";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import ComponentButton from "./componentButton";
import ComponentSearch from "./componentSearch";
import { useTableSearch } from "./useTableSearch";
import FilterListIcon from "@mui/icons-material/FilterList";
import Svg from "../../assets/svg/svg";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router";
import SuggestionCard from "./SuggestionCard";
import ReferenceCard from "./ReferenceCard";
import { getAllDataApi, getApi } from "../../api/axios";
import AddIcon from "@mui/icons-material/Add";
import { getUserHasAccess } from "../../utils/common";
import AuthorityContext from "../../context/AuthorityContext";
import http from "../../api/axios";
export default function ReferenceCategoryCard(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [searchVal, setSearchVal] = useState('');
  const { filteredData, loading } = useTableSearch({
    searchVal,
    retrieve: props.rows,
  });
  const category = JSON.parse(localStorage.getItem("category"));
  const [suggestions, setSuggestions] = useState();
  const [references, setReferences] = useState();
  const history = useHistory();

  const getApiData = async () => {
    getApi(t, `/api/reference-suggestions?categoryId=${category?.id}`).then(
      (res) => setSuggestions(res)
    );

  };
  const getReferenceData = async () => {
    getApi(t, `/api/references?categoryId=${category?.id}&search=${searchVal}`).then((res) =>
      setReferences(res)
    );
  }

  useEffect(() => {
    getApiData();
  }, []);

  useEffect(() => {
    getReferenceData();
  }, [searchVal]);

  const deleteReferences = async (id) => {
    await http.deleteApi(t, `/api/references/delete/${id}`);
    getApiData();
  };


  const categoryName = category?.name;

  return (
    <div className="pending_suggestions_reqPG">
      <div className="CommonPd">
        <div className="dashTopGrid pending_req_box">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className="morningText" variant="h4">
                {t("titles.pending_suggestions_requests")}
              </Typography>

              {references?.length > 0 ? <ComponentButton
                sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                value={t("labels.add_new")}
                callback={() => {
                  if (getUserHasAccess("reference", 162)) {
                    history.push(`/reference-categories/references/suggestion-form`);
                  } else {
                    setAuthorityModal(true);
                  }
                }}
                icon={<AddIcon sx={{ paddingRight: "4px", color: "#fff" }} />}
                variant={"contained"}
                class={"buttonFill"}
              /> : ''}
            </Grid>
          </Grid>

          {
            getUserHasAccess("reference", 163) ?
              suggestions?.map((e, i) => (
                <SuggestionCard
                  key={i}
                  data={e}
                  categoryName={categoryName}
                  getApiData={getApiData}
                />
              ))
              :
              ''
          }
        </div>
      </div>
      <div className="CommonPd latest_des_box">
        <div className="dashTopGrid ">
          <Grid className=" Prophetic-Refertop">
            <Grid
              className="tableTopLaw TableLayout"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="tableHeading">
                <Typography
                  variant="h3"
                  sx={{ paddingTop: "5px !important" }}
                  className="TableHeading"
                >
                  {t("titles.prophetic_tradition_reference")}
                </Typography>
              </div>
              <Grid className="searchResponsiveLaw">
                <ComponentSearch value={searchVal}
                  callback={(e) => setSearchVal(e.target.value)} />
              </Grid>
              <Grid
                className="gridLeftbtn"
                sx={{
                  gap: "1rem",
                }}
              >
                {/* <ComponentButton icon={<FilterListIcon />}
                  value="FILTER BY" variant="outlined"
                  class="buttonBlank outlineBtn" /> */}
                <ComponentButton
                  sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                  value={t("labels.add_new")}
                  callback={() => {
                    if (getUserHasAccess("reference", 161)) {
                      history.push(`/reference-categories/references/reference-form`);
                    } else {
                      setAuthorityModal(true);
                    }
                  }}
                  icon={<AddIcon sx={{ paddingRight: "4px", color: "#fff" }} />}
                  variant={"contained"}
                  class={"buttonFill"}
                />
              </Grid>
            </Grid>
          </Grid>

          {references?.map((e, i) => (
            <ReferenceCard key={i} data={e} getApiData={getApiData} />
          ))}
        </div>
      </div>
    </div>
  );
}
