import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./logNote.css";
export const convertMentions = (finalText) => {
  // mentioned user will be like @user-name-1 in text string
  const mentionedUserNames = finalText.match(/@(\S+)/g) || [];

  // console.log(mentionedUserNames);
  const originalText = mentionedUserNames.reduce((text, mentionedUserName) => {
    const originalUserName = mentionedUserName
      .replace(/^@/, "")
      .replace(/-/g, " ");

    // console.log(originalUserName);

    const spannedUserName = `<span style="font-weight: bold; color: #4599FF; font-style: italic; font-size: 13px; cursor: pointer">${originalUserName}</span>`;

    return text.replace(mentionedUserName, spannedUserName);
  }, finalText);

  return originalText;
};

const LogReply = ({ data, hasMore, fetchData }) => {
  const { t } = useTranslation();
  return (
    <>
      {data.length > 0 ? (
        <div>
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            height={100}
          >
            <div>
              {data.map((data) => (
                <>
                  <div className="replyList">
                    <Avatar
                      key={""}
                      alt="Shubham"
                      src={data?.imagePath}
                      className="avatar-lg"
                    />
                    <div className="logNoteList">
                      <h2>{data?.repliedBy}</h2>
                      <Typography
                        variant="body1"
                        component="p"
                        dangerouslySetInnerHTML={{
                          __html: data?.text ? convertMentions(data?.text) : "",
                        }}
                      ></Typography>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <h3 className="textCenter">{t("titles.no_replies_available")}</h3>
      )}
    </>
  );
};
export default LogReply;
