import React from "react";
import { Button } from "@mui/material";

export default function ComponentButton(props) {
  return (
    <Button
      variant={props.variant}
      className={`${props.class} ${props?.disabled ? "btnDisabled" : ""}`}
      onClick={props.callback}
      startIcon={props.icon}
      disabled={props?.disabled}
      style={props.style}
    >
      {props.value}
    </Button>
  );
}
