import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import http from "../../api/axios";
import PrivateRoute from "./PrivateRoute";
import HeaderBreadCrumbs from "./HeaderBreadCrumbs";
import { NAVIGATION_ROUTES } from "./ComponentRoutes";

export default function RoutingComp(props) {
  const { t } = useTranslation();
  const [journy, setJourny] = useState({});
  const langId = props.langId;
  const setLoader = (value) => {
    props.setLoader(value);
  };

  const getBranchDept = async () => {
    const branchRes = await http.getAllDataApi(t, "/api/branch/details");
    const departmentRes = await http.getApi(t, "/api/department");
    // const employeRes = await http.getAllDataApi(t,"/api/employee");
    setJourny({
      ...journy,
      allBranches: branchRes?.data,
      allDepartments: departmentRes,
      // allEmployess: employeRes?.data,
    });
  };

  useEffect(() => {
    getBranchDept();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let nav_routes = [];
  NAVIGATION_ROUTES.forEach((item) => {
    if (item.paths) {
      const newRoutes = item?.paths.map((el) => {
        return {
          componentName: item.componentName,
          name: el.name,
          path: el.path,
          hasAuthority: el.hasAuthority,
          authorities: [...el.authorities],
        };
      });
      nav_routes.push(...newRoutes);
    } else {
      nav_routes.push(item);
    }
  });

  return (
    <div className="MainRouting">
      <main>
        {nav_routes.map((comp) => {
          if (comp?.hasAuthority) {
            return (
              <PrivateRoute
                path={comp?.path}
                component={comp?.componentName}
                authorities={comp?.authorities}
                routes={nav_routes}
                componentProps={{
                  setLoader: setLoader,
                  journy: journy,
                }}
              />
            );
          } else {
            return (
              <Route
                exact
                path={comp?.path}
                render={(props) => {
                  return (
                    <>
                      <HeaderBreadCrumbs
                        routes={nav_routes}
                        match={props.match}
                      />
                      <comp.componentName
                        setLoader={setLoader}
                        journy={journy}
                        langId={langId}
                        {...props}
                      />
                    </>
                  );
                }}
              />
            );
          }
        })}
      </main>
    </div>
  );
}
