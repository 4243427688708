import React, { useState, useEffect, useContext } from "react";
import Info from "../../../../assets/images/info.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../../componentGroups/componectModel";
import http, { baseURL } from "../../../../api/axios";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import { loadOptions } from "../../../../api/utils/common";
import validate from "../../../../Hooks/tasks.validaction";
import moment from "moment";
import hooks from "../../../../Hooks/hooks";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

export default function ProjectTasks({ setLoader }) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const location = useLocation();
  location?.state?.id &&
    localStorage.setItem("projectTask", JSON.stringify(location?.state?.id));
  const Id = JSON.parse(localStorage.getItem("projectTask"));
  const [projectTaskData, setProjectTaskData] = useState([]);
  const [taskState, setTaskState] = useState({ related_project_id: Id });
  const [filterData, setFilterData] = useState({ filter: false });
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [modalData, setModelData] = useState({
    model: false,
    title: t("titles.add_new_task"),
    data: [
      {
        lable: t("labels.task_title"),
        field: { type: "text", required: true },
        name: "taskTitle",
      },
      {
        lable: t("labels.task_type"),
        field: {
          type: "dropdown_plus",
          option: [],
          findLable: "option",
          required: true,
        },
        button: {
          name: "type_drop",
          callback: (val) => {
            addDropdownOptions(val, "type_drop", 1);
          },
        },
        name: "taskType",
      },
      {
        lable: "Project",
        field: {
          type: "dropdown",
          required: true,
          disable: "true",
          findLable: "projectName",
          option: [],
        },
        name: "related_project_id",
      },
      {
        lable: t("labels.assigned_to"),
        field: {
          type: "multiple",
          required: true,
          setlable: "name",
          option: [],
        },
        name: "assignedTO",
      },
      {
        lable: t("labels.priority"),
        field: {
          type: "dropdown",
          required: true,
          findLable: "option",
          option: [],
        },
        name: "priority",
      },
      {
        lable: t("labels.due_date"),
        field: {
          type: "date",
          min: moment(new Date()).format("YYYY-MM-DD"),
          required: true,
        },
        name: "dueDate",
      },
      {
        lable: t("labels.request_by"),
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: ["Riyad", "Riyad"],
        },
        name: "requestBy",
      },
      {
        lable: t("labels.shared_with"),
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
          value: "",
        },
        name: "sharedWith",
      },
      {
        lable: t("labels.description"),
        field: { type: "ck", required: true },
        name: "description",
      },
    ],
    buttonGroup: [
      {
        value: t("labels.discard"),
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: t("labels.create"),
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addTask(val);
        },
      },
    ],
  });
  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.task_number",
      name: "taskNumber",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.task_title",
      name: "taskTitle",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.project_name",
      name: "projectName",
      width: "10px",
      type: "dropDown",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.assigned_to",
      name: "assigned_to",
      width: "10px",
      type: "dropDown",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.assigned_from",
      name: "assignedFrom",
      width: "10px",
      type: "dropDown",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.due_date",
      name: "dueDate",
      width: "10px",
      align: "left",
      type: "date",
      minWidth: 150,
    },
    {
      lable: "labels.task_status",
      name: "taskStatus",
      type: "dropDown",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.priority",
      name: "priority",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
    // { lable: "", name: "info", width: "10px", align: "left" },
  ]);
  const [data, setData] = useState({
    title: "tableTitle.5.all_tasks",
    page: "Tasks",
    infoURL: "/taskInfo",
    handlExport: (page, size, filterData) =>
      handleExport(page, size, filterData),
    buttonGroup: [
      {
        type: "add",
        title: "labels.add",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {
          if (getUserHasAccess("task", 118)) {
            handleModel("addTask");
          } else {
            setAuthorityModal(true);
          }
        },
      },
      {
        type: "actions",
        title: "labels.action",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {
          handleModel("addTask");
        },
      },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });
  const handleCloseModel = () => {
    setErrors({});
    setModelData({ ...modalData, model: false });
  };

  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val?.[key]);
      formData.append("langId", 1);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        setTaskState({ ...taskState, [key]: "" });
        getDataApi(true);
      }
    }
    setLoader(false);
  };

  const getDataApi = async (key = false) => {
    let s1 = { ...modalData };
    let s2 = [...column];
    let res = await loadOptions(t, 1, 1);
    let res1 = await loadOptions(t, 1, 2);
    let res2 = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let res3 = await loadOptions(
      t,
      1,
      undefined,
      "post",
      "/api/project/details"
    );
    s2[3].options = hooks.sendName(res3, "projectName");
    s2[4].options = res2;
    s2[5].options = res2;
    // s2[9].options = hooks.sendName(res1, "option");
    setColumn(s2);
    s1.data[1].field.option = res;
    s1.data[4].field.option = res1;
    s1.data[3].field.option = res2;
    s1.data[6].field.option = res2;
    s1.data[7].field.option = res2;
    s1.data[2].field.option = res3;

    s1.model = key;
    setModelData(s1);
  };
  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.tasksValidate(taskState);
      setErrors(error);
    }
  }, [taskState]);

  const handleModel = (val) => {
    if (val == "addTask") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };
  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    // if (filterData?.filter == true) {
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate
        ? moment(filterData?.dueDate).format("YYYY-MM-DD")
        : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    let result = await http.getAllDataApi(
      t,
      `/api/task/projects/${Id}/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(result?.data_count);
    setProjectTaskData(result?.data);
    setFilterData({ ...filterData, filter: false });
    setLoader(false);
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    let formdata = new FormData();
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate ? filterData?.dueDate : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/task/export-task/?Project_id=${Id}`,
      formdata
    );
    setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    getApiData();
    getDataApi();
  }, [search, page, size, filterData?.filter == true]);

  const addTask = async (obj) => {
    let error = validate.tasksValidate(obj);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      formData.append("taskTitle", obj.taskTitle);
      formData.append("taskType", obj.taskType);
      obj?.assignedTO?.map((op, index) => {
        formData.append(`assignedTo[${index}]`, op.id);
      });
      formData.append("priority", obj.priority);
      formData.append("dueDate", obj.dueDate);
      formData.append("requestBy", obj.requestBy);
      formData.append("sharedWith", obj.sharedWith);
      formData.append("description", obj.description);
      formData.append("related_project_id", obj.related_project_id);
      formData.append("langId", "1");
      const res = await http.postApi(t, "/api/task/create", formData);
      if (res) {
        setTaskState({});
        getApiData();
        setErrors({});
        handleCloseModel();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("task", 120)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          `/api/task/delete`,
          formData,
          "labels.task_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };
  return (
    <>
      <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setTaskState}
        perantState={taskState}
      />
      <ComponentTable
        rows={projectTaskData}
        columns={column}
        filterData={filterData}
        setFilterData={setFilterData}
        pageData={data}
        setSearch={setSearch}
        search={search}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
        selected={selectedData}
        handleSelected={handleSelected}
        handleAllselect={handleAllselect}
        handleDelete={handleDelete}
        enableReset={true}
        resetdata={{
          status: "",
        }}
      />
    </>
  );
}
