import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import http, { baseURL } from "../../../../api/axios";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import hooks from "../../../../Hooks/hooks";
import { loadOptions } from "../../../../api/utils/common";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import auth from "../../../../services/auth";
import { useTranslation } from "react-i18next";

export default function AllConsultations({
  dropdownOptions,
  filterData,
  setFilterData,
  getApiData,
  allTaskData,
  totalRecords,
  search,
  setSearch,
  handleExport,
}) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  let user = auth.getUser("user");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [selectedData, setSelectedData] = useState([]);
  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.request_title",
      name: "title",
      // hide: user?.department_id == 8 ? true : false,
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    // {
    //   lable: "labels.request_number",
    //   name: "code",
    //   width: "10px",
    //   align: "left",
    //   minWidth: 110,
    // },
    {
      lable: "labels.matter_name",
      name: "matter",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.assigned_to",
      name: "sendTo",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.assigned_from",
      name: "sendFrom",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 130,
    },

    {
      lable: "labels.attachments",
      name: "attachment",
      // hide: user?.department_id == 8 ? true : false,

      width: "10px",
      align: "left",
      minWidth: 50,
    },
    {
      lable: "labels.request_status",
      name: "status",
      // hide: user?.department_id == 8 ? true : false,

      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.priority",
      name: "periority",
      // hide: user?.department_id == 8 ? true : false,

      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
    // { lable: "", name: "info", width: "10px", align: "left" },
  ]);
  const [data, setData] = useState({
    title: "labels.all_requests",
    page: "consultation",
    infoURL: "/ask-consultation/consultationInfo",
    setRowToLocal: "consultation",
    handlExport: (page, size, filterData) => {
      handleExport(page, size, filterData)
    },
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => { },
      },
      //   {
      //     type: "filter",
      //     title: "labels.filter_by",
      //     icon: <FilterListIcon />,
      //     variant: "outlined",
      //     class: "buttonBlank",
      //     callback: () => { },
      //   },
    ],
  });

  // const setColumnData = async () => {
  //   let s1 = [...column];
  //   let res1 = await loadOptions(t, 1, 2);
  //   let res2 = await loadOptions(t, 1, undefined, "post", "/api/employee");
  //   let res3 = await loadOptions(
  //     t,
  //     1,
  //     undefined,
  //     "post",
  //     "/api/project/details"
  //   );
  //   s1[3].options = hooks?.sendName(res3, "projectName");
  //   s1[4].options = res2;
  //   s1[5].options = res2;
  //   // s1[9].options = hooks?.sendName(res1, "option");
  //   setColumn(s1);
  // };



  useEffect(() => {
    // setColumnData();
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };
  // console.log(selectedData);

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("consultation", 220)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/consultation/archive",
          formData

        );
        getApiData(page, size);
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };
  // console.log(dropdownOptions, "dropdownOptions");

  return (
    <ComponentTable
      rows={allTaskData}
      // filterData={filterData}
      // setFilterData={setFilterData}
      columns={column}
      pageData={data}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      setSearch={setSearch}
      search={search}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{
        status: "",
      }}
    />
  );
}
