/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ComponentTextString from "../../../../componentGroups/componentTextString";
import ComponentSelect from "../../../../componentGroups/componentSelect";
import ComponentButton from "../../../../componentGroups/componentButton";
import http from "../../../../../api/axios";
import LoadingContext from "../../../../../context/LoaderContext";
import { loadOptions } from "../../../../../api/utils/common";
import ComponentDatePicker from "../../../../componentGroups/componentDatepicker";
import MinHeightTextarea from "../../../../componentGroups/componentTextarea";
import moment from "moment";
const PAGE_VALUES = {
  add: "titles.add_new_penalty",
  edit: "Penalty Detail",
  addApi: "/api/employee/penalty",
  getDataApi: "/api/employee/penalty",
  getEmpDataApi: "/api/employee/find",
};

const AddEditPenalty = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const { empId, penaltyId } = useParams();
  const [errors, setErrors] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [penaltyData, setPenaltyData] = useState({
    name: "",
    code: "",
    departmentId: "",
    jobId: "",
    directManager: "",
    penalty_type: "",
    date: "",
    applies_on: "",
    description: "",
    amount: "",
  });
  const [dropDownOptions, setDropDownOptions] = useState({
    department: [],
    jobPositions: [],
    penaltyType: [],
    appliedOn: [
      {
        name: "Net Salary",
        id: "net-salary",
      },
      {
        name: "Gross Salary",
        id: "gross-salary",
      },
    ],
  });

  const getEmpData = async () => {
    setLoader(true);
    let res = await http.getApi(t, `${PAGE_VALUES?.getEmpDataApi}/${empId}`);
    const data = {
      code: res?.code ?? "",
      name: res?.name ?? "",
      departmentId: res?.departmentId ?? "",
      jobId: res?.jobId ?? "",
      directManager: res?.directManager ?? "",
    };
    setPenaltyData({
      ...penaltyData,
      ...data,
    });
    setLoader(false);
  };

  const getPenaltyType = async () => {
    const formData = new FormData();
    formData.append("filter[departmentId]", penaltyData?.departmentId);
    formData.append("filter[jobId]", penaltyData?.jobId);
    let res = await http.getAllDataApi(
      t,
      "/api/setting/penalties/findAll",
      formData
    );
    const data = res?.data?.map((item) => {
      return {
        ...item,
        name: item?.type,
      };
    });
    return data;
  };

  const getPenaltyData = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("id", penaltyId);
    let res = await http.getAllDataApi(t, PAGE_VALUES.getDataApi, formData);
    setPenaltyData({
      ...penaltyData,
      ...res?.data,
      date: res?.data?.apply ?? "",
      penalty_type: res?.data?.penalty_id ?? "",
    });
    setLoader(false);
  };

  useEffect(() => {
    if (penaltyData?.penalty_type && dropDownOptions?.penaltyType?.length > 0) {
      const selectedPenaltyaType = dropDownOptions?.penaltyType?.filter(
        (item) => parseInt(item?.id) === parseInt(penaltyData?.penalty_type)
      )[0];
      setPenaltyData({
        ...penaltyData,
        amount: selectedPenaltyaType?.amount,
      });
    }
  }, [penaltyData?.penalty_type, dropDownOptions?.penaltyType]);

  useEffect(() => {
    if (penaltyData?.departmentId) {
      setLoader(true);
      axios
        .all([
          loadOptions(
            t,
            "",
            null,
            "get",
            `/api/setting/department/job-position/${penaltyData?.departmentId}`
          ),
        ])
        .then((res) => {
          const jobPositions = res[0]?.map((item) => {
            return { ...item, name: item?.title };
          });
          getPenaltyType().then((data) => {
            setDropDownOptions({
              ...dropDownOptions,
              jobPositions: jobPositions,
              penaltyType: data,
            });
          });
        })
        .finally(() => {
          setLoader(false);
          if (penaltyId) {
            setViewMode(true);
            getPenaltyData();
          }
        });
    }
  }, [penaltyData?.departmentId, penaltyId]);

  useEffect(() => {
    setLoader(true);
    axios
      .all([loadOptions(t, "departments", "", "get", `/api/department`)])
      .then((res) => {
        const department = res[0]?.map((item) => {
          return { ...item, name: item?.departmentName };
        });
        setDropDownOptions({
          ...dropDownOptions,
          department: department,
        });
      })
      .finally(() => {
        setLoader(false);
        if (empId) {
          getEmpData();
        }
      });
  }, [empId]);

  const validateErrors = (data) => {
    let error = {};
    if (data.penalty_type === undefined || data.penalty_type === "") {
      error.penalty_type = "errors.enter_penalty_type";
    }
    if (data.applies_on === undefined || data.applies_on === "") {
      error.applies_on = "errors.enter_action_applies_on";
    }
    if (data.date === undefined || data.date === "") {
      error.date = "errors.enter_applies_on";
    }
    if (data.description === undefined || data.description === "") {
      error.description = "errors.enter_description";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(penaltyData);
      setErrors(error);
    }
  }, [penaltyData]);

  const handleAddEdit = async () => {
    let error = validateErrors(penaltyData);
    if (Object.keys(error).length === 0) {
      const api = `${PAGE_VALUES.addApi}/${empId}`;
      setLoader(true);
      const formData = new FormData();
      formData.append("penalty_type", penaltyData.penalty_type);
      formData.append("date", penaltyData.date);
      formData.append("description", penaltyData.description);
      formData.append("applies_on", penaltyData.applies_on);
      const res = await http.postApi(t, api, formData);
      if (res?.status) {
        history.goBack();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(penaltyId ? PAGE_VALUES.edit : PAGE_VALUES.add)}
                </Typography>
              </Grid>
            </Grid>

            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.employee")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="name"
                    value={penaltyData?.name}
                    disable={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.department")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      disable={true}
                      name="departmentId"
                      value={penaltyData?.departmentId}
                      option={dropDownOptions?.department}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.job_position")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      disable={true}
                      value={penaltyData?.jobId}
                      option={dropDownOptions?.jobPositions}
                      name="jobId"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.manager")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="directManager"
                      value={penaltyData?.directManager}
                      disable={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.penalty_type")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      value={penaltyData?.penalty_type}
                      option={dropDownOptions?.penaltyType}
                      name="penalty_type"
                      disable={viewMode}
                      callback={(evt) => {
                        setPenaltyData({
                          ...penaltyData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.penalty_type && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.penalty_type)}`}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.action_applied_on")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      value={penaltyData?.applies_on}
                      option={dropDownOptions?.appliedOn}
                      disable={viewMode}
                      name="applies_on"
                      callback={(evt) => {
                        setPenaltyData({
                          ...penaltyData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.applies_on && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.applies_on)}`}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.applied_on")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentDatePicker
                      class={"custonInput"}
                      placeholder=""
                      value={penaltyData?.date}
                      name="date"
                      disable={viewMode}
                      callback={(evt) => {
                        setPenaltyData({
                          ...penaltyData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.date && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.date)}`}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.amount")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentTextString
                      class={"custonInput"}
                      placeholder=""
                      name="amount"
                      value={penaltyData?.amount}
                      disable={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.description")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <MinHeightTextarea
                      class={"custonInput"}
                      name="description"
                      minRows={4}
                      placeholder={"Enter the description"}
                      value={penaltyData?.description}
                      disable={viewMode}
                      callback={(evt) => {
                        setPenaltyData({
                          ...penaltyData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.description && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.description)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              {!viewMode && (
                <Grid
                  container
                  style={{ margin: "16px 0", justifyContent: "center" }}
                >
                  <Grid item>
                    <ComponentButton
                      value={t("labels.discard")}
                      variant={"outlined"}
                      class={"buttonBlank outlineBtn Mright"}
                      callback={() => history.goBack()}
                    />
                  </Grid>
                  <Grid item>
                    <ComponentButton
                      value={t("labels.save")}
                      variant="contained"
                      class="buttonFill FillBtn"
                      callback={() => {
                        handleAddEdit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddEditPenalty;
