import React, { useState, useEffect, useContext } from "react";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import "react-circular-progressbar/dist/styles.css";
import Collapse from "@mui/material/Collapse";
import http from "../../../../../api/axios";
import { Grid, Menu, MenuItem } from "@mui/material";
import DocumentModal from "./documentModal";
import DocFiles from "./docFIles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Svg from "../../../../../assets/svg/svg";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { baseURL } from "../../../../../api/axios";
import LoadingContext from "../../../../../context/LoaderContext";
import { getUserHasAccess } from "../../../../../utils/common";
import AuthorityContext from "../../../../../context/AuthorityContext";

const DocumentDetails = ({
  folder,
  loadDropdownOptions,
  clientId,
  getFolders,
  fileAddedToFolder,
}) => {
  const { setLoader } = useContext(LoadingContext);
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t } = useTranslation();
  const [modalData, setModalData] = useState({
    operationId: "",
    open: false,
  });
  const [showAll, setShowAll] = useState(false);
  const [filesToView, setFilesToView] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [files, setFiles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]); //assigned to users

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = (operationId) => {
    setModalData({
      open: true,
      operationId: operationId,
      name: folder?.name ?? "",
    });
    handleClick();
  };

  /**
   * returns folder files associated with selected folder
   * @param {*} rowId
   */
  const getFolderFiles = async (folderId = folder?.id) => {
    setLoader(true);
    let res = await http.getApi(
      t,
      `/api/client/findallfilesoffolders/${folderId}`
    );
    setLoader(false);
    if (!!res) {
      const formDocfiles = res[0]?.form_documents?.map((item) => {
        return { ...item, type: "doc" };
      });
      setFiles([...formDocfiles, ...res[0]?.client_files]);
    }
  };

  useEffect(() => {
    if (folder?.id) {
      getFolderFiles();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder?.id]);

  useEffect(() => {
    if (folder?.assigntousers) {
      const assigntousers = [...folder.assigntousers];
      const users =
        folder?.assigntousers?.length > 3
          ? assigntousers?.splice(0, 3)
          : folder?.assigntousers;
      setUsers(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder?.assigntousers]);

  useEffect(() => {
    if (parseInt(fileAddedToFolder) === parseInt(folder.id)) {
      getFolderFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder?.id, fileAddedToFolder]);

  useEffect(() => {
    if (showAll) {
      setFilesToView(files);
    } else {
      if (files?.length > 3) {
        let sliced = files?.slice(0, 3);
        setFilesToView(sliced);
      } else {
        setFilesToView(files);
      }
    }
  }, [showAll, files]);

  return (
    <>
      <Card sx={{ maxWidth: 500 }} className="docCardBg">
        <div className="folderSection">
          <div className="folderSection1">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Svg type="folder" />
              <Typography
                variant="body"
                fontSize={"1rem"}
                style={{ marginLeft: "10px" }}
              >
                {folder?.name}
              </Typography>
            </div>

            <EditIcon
              className="editI"
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Menu
            className="Action0con"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                if (getUserHasAccess("client", 94)) {
                  handleModal(5);
                } else {
                  setAuthorityModal(true);
                }
              }}
            >
              {t("labels.edit_folder_name")}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                handleModal(7);
              }}
            >
              {t("labels.assign_to")}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                if (getUserHasAccess("client", 95)) {
                  handleModal(3);
                } else {
                  setAuthorityModal(true);
                }
              }}
            >
              {t("labels.upload_files")}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                if (getUserHasAccess("client", 96)) {
                  handleModal(4);
                } else {
                  setAuthorityModal(true);
                }
              }}
            >
              {t("labels.generate_doc")}
            </MenuItem>
          </Menu>
          <div
            onClick={() => setExpanded(!expanded)}
            style={{ cursor: "pointer" }}
          >
            {expanded ? (
              <KeyboardArrowDownIcon fontSize="medium" />
            ) : (
              <KeyboardArrowUp fontSize="medium" />
            )}
          </div>
        </div>
        <CardContent sx={{ display: "flex", gap: "30px" }}>
          <div className="cardContant">
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"1rem"}
            >
              {folder?.file_count} {t("labels.files")}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="textFont"
            >
              {t("labels.last_update")} :{" "}
              {moment(folder?.updatedAt ?? folder?.createdAt).format("LLL")}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="textFont"
            >
              {!folder?.assigntousers || folder?.assigntousers?.length === 0 ? (
                t("labels.not_assigned_to_any_one")
              ) : (
                <div className="avatarcontainer">
                  {t("labels.assign_to")}:
                  <AvatarGroup max={3} className="avatargroup">
                    {users?.map((item) => {
                      return (
                        <Avatar
                          key={item?.id}
                          alt={item?.name}
                          src={`${baseURL}/${item?.path}`}
                          className="avatar"
                        />
                      );
                    })}
                  </AvatarGroup>
                  {folder?.assigntousers?.length > 3 &&
                    t("labels.other_people", {
                      count: folder?.assigntousers?.length - 3,
                    })}
                </div>
              )}
            </Typography>
          </div>
        </CardContent>
        <CardActions disableSpacing></CardActions>
      </Card>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className="docfileContainer">
          {filesToView.map((item) => {
            return (
              <DocFiles
                item={item}
                setLoader={setLoader}
                getFiles={getFolderFiles}
              />
            );
          })}
        </div>
        {files?.length > 4 && (
          <Grid item xs={12}>
            <div onClick={() => setShowAll(!showAll)}>
              <button variant="contained" className="centerBtn newBtn1 new">
                {showAll ? t("labels.show_less") : t("labels.view_all")}
              </button>
            </div>
          </Grid>
        )}
      </Collapse>
      <DocumentModal
        open={modalData.open}
        operationId={parseInt(modalData?.operationId)}
        onCLose={() => {
          setModalData({
            open: false,
            operationId: "",
            name: "",
          });
          handleClose();
        }}
        loadDropdownOptions={loadDropdownOptions}
        clientId={clientId}
        folderId={folder?.id}
        getFiles={getFolderFiles}
        getFoldersByRow={getFolders}
        folderName={modalData?.name}
      />
    </>
  );
};

export default DocumentDetails;
