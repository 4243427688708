import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Modal,
  Grid,
  TextField,
  FormControl,
  Select,
  Chip,
  Stack,
  Autocomplete,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ComponentButton from "./componentButton";
import ComponentUpload from "./componentUpload";
import ComponentTextString from "./componentTextString";
import ComponentSelect from "./componentSelect";
import ComponentCKEditer from "./componentCKEditer";
import ComponentDatePicker from "./componentDatepicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MinHeightTextarea from "./componentTextarea";
import DescriptionIcon from "@mui/icons-material/Description";
import { formatBytes } from "../comman/pxToRem";
import hooks from "../../Hooks/hooks";
import Svg from "../../assets/svg/svg";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../utils/common";
import AuthorityContext from "../../context/AuthorityContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  // borderRadius: "2px solid red",
  // border: "2px solid ",
  boxShadow: "20",
  // p: 4,
};

export default function ComponentModal(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t } = useTranslation();
  const [show, setShow] = useState({});
  const styleHT = props.MHeight ? props.MHeight : 0;
  const [selectedFiles, setSelectedFiles] = React.useState("");

  return (
    <Modal
      className="MainModal"
      open={props.modalData.model}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="ModalBG" sx={style}>
        <div className="ModalHeading">
          <Typography
            variant="h4"
            sx={{ paddingTop: "1px !important" }}
            className="morningText"
          >
            {t(props.modalData.title)}
          </Typography>
          {props.modalData.desc && (
            <Typography
              variant="body2"
              sx={{ marginTop: "20px", textAlign: "center" }}
              className="morningText1"
            >
              {t(props.modalData.desc)}
            </Typography>
          )}
        </div>
        <div className="HideScroll">
          <div
            className="scroll-auto ModalContent"
            style={{ minHeight: styleHT }}
          >
            {props.modalData.data &&
              props.modalData.data?.map((op, index) => (
                <Grid
                  container
                  md={12}
                  className="modalGroup"
                  key={`${op?.label}-${index}`}
                >
                  <Grid item xs={12} md={3} sx={{ paddingTop: 0 }}>
                    <Typography variant="h1" className="lable">
                      {t(op.lable)}
                      {op.field.required == true && (
                        <span style={{ color: "red", paddingLeft: "5px" }}>
                          *
                        </span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid className="CustomWidth" item xs={11} md={9}>
                    {op.field.type == "text" ? (
                      <ComponentTextString
                        type={op?.field?.fieldType}
                        placeholder={t(op.field.placeholder)}
                        disable={op.field.disable}
                        lableValue={op?.field?.lableValue}
                        showLable={op?.field?.showLable}
                        value={props?.perantState?.[op?.name]}
                        callback={(e) =>
                          props.setPerantState({
                            ...props.perantState,
                            [op.name]: e.target.value,
                          })
                        }
                      />
                    ) : op.field.type == "contact" ? (
                      <ComponentTextString
                        placeholder={t(op.field.placeholder)}
                        disable={op.field.disable}
                        lableValue={op?.field?.lableValue}
                        showLable={op?.field?.showLable}
                        value={props?.perantState?.[op?.name]}
                        callback={(e) => {
                          const reg = /^[\u0660-\u06690-9]*$/;
                          if (reg.test(e.target.value)) {
                            props.setPerantState({
                              ...props.perantState,
                              [op.name]: e.target.value,
                            });
                          }
                        }}
                      />
                    ) : op.field.type == "dropdown" ? (
                      <ComponentSelect
                        disable={op.field.disable}
                        value={props?.perantState?.[op?.name]}
                        option={
                          op?.field?.option &&
                          hooks.sendName(op.field.option, op?.field?.findLable)
                        }
                        callback={(e) =>
                          props.setPerantState({
                            ...props.perantState,
                            [op.name]: e.target.value,
                          })
                        }
                      />
                    ) : op.field.type == "dropdown_plus" ? (
                      <>
                        <div className="centerFlex" style={{ width: "100%" }}>
                          <ComponentSelect
                            style={{ width: "100%" }}
                            disable={op.field.disable}
                            value={props?.perantState?.[op?.name]}
                            option={
                              op?.field?.option &&
                              hooks.sendName(
                                op?.field?.option,
                                op?.field?.findLable
                              )
                            }
                            callback={(e) =>
                              props.setPerantState({
                                ...props.perantState,
                                [op.name]: e.target.value,
                              })
                            }
                            addIconClass="allmodalAddbtn"
                          />
                          {show[op.name] ? (
                            <RemoveCircleOutlineIcon
                              sx={{
                                color: "#367AF6",
                                marginLeft: "10px",
                                cursor: "pointer",
                                // position: "absolute",
                                // right: "-25px",
                                // zIndex: "100",
                              }}
                              onClick={() => {
                                setShow({ ...show, [op.name]: !show[op.name] });
                              }}
                            />
                          ) : (
                            <AddCircleOutlineIcon
                              sx={{
                                color: "#367AF6",
                                marginLeft: "10px",
                                cursor: "pointer",
                                // position: "absolute",
                                // right: "-25px",
                                // zIndex: "100",
                              }}
                              onClick={() => {
                                if (getUserHasAccess("other", 157)) {
                                  setShow({
                                    ...show,
                                    [op.name]: !show[op.name],
                                  });
                                } else {
                                  setAuthorityModal(true);
                                }
                              }}
                            />
                          )}
                        </div>
                        {show[op.name] && (
                          <>
                            <div className="centerFlex mtt">
                              <ComponentTextString
                                placeholder={t(op?.button?.placeholder)}
                                disable={op?.button?.disable}
                                name={op?.button?.name}
                                value={props.perantState[op?.button?.name]}
                                callback={(e) => {
                                  const reg = /^[a-zA-Z0-9\u0600-\u06FF_ ]*$/;
                                  if (reg.test(e.target.value)) {
                                    e.preventDefault();
                                    props.setPerantState({
                                      ...props.perantState,
                                      [op?.button?.name]: e.target.value,
                                    });
                                  }
                                }}
                              />
                              <ComponentButton
                                value={t("labels.save")}
                                variant="contained"
                                class="buttonFill FillBtn saveBtn"
                                callback={() => {
                                  if (
                                    props.perantState[
                                      op?.button?.name
                                    ]?.trim() !== ""
                                  ) {
                                    op?.button?.callback(props.perantState);
                                    setShow({
                                      ...show,
                                      [op.name]: !show[op.name],
                                    });
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    ) : op.field.type == "file" ? (
                      <>
                        <ComponentUpload
                          lable={t("labels.upload_file")}
                          callback={(e) => {
                            props.setPerantState({
                              ...props.perantState,
                              [op.name]: e.target.files[0],
                            });
                            // setSelectedFiles(e.target.files);
                          }}
                          accept={op?.field?.accept}
                        />
                        {props?.perantState?.[op.name] && (
                          <div className="fileDelete">
                            <span>{props?.perantState?.[op.name]?.name}</span>
                            <span
                              onClick={() => {
                                props.setPerantState({
                                  ...props.perantState,
                                  [op.name]: "",
                                });
                              }}
                            >
                              <Svg type="close" style={{ cursor: "pointer" }} />
                            </span>
                            {/* {props?.perantState?.[op.name] && Object.values(props?.perantState?.[op.name])?.map((file, index) => {
                          return (
                            <>
                              <div key={index} className="flexJustify">
                                <DescriptionIcon />
                                <div>
                                  <small>{file.name}</small>
                                  <small>{formatBytes(file.size)}</small>
                                </div>
                                <div
                                  className="FileCrossIcon"
                                  onClick={() => {
                                    // filteredFiles(index);
                                  }}
                                ></div>
                              </div>
                            </>
                          );
                        })} */}
                          </div>
                        )}
                      </>
                    ) : op.field.type == "multiplefile" ? (
                      <>
                        <ComponentUpload
                          lable={t("labels.upload_file")}
                          callback={(e) => {
                            props.setPerantState({
                              ...props.perantState,
                              [op.name]: [
                                ...props?.perantState?.[op.name],
                                e.target.files[0],
                              ],
                            });
                          }}
                          accept={op?.field?.accept}
                        />

                        {props?.perantState?.[op.name] &&
                          Object.values(props?.perantState?.[op.name])?.map(
                            (file, index) => {
                              return (
                                <>
                                  <div key={index} className="fileDelete">
                                    <div>
                                      <small>{file.name}</small>
                                      <span
                                        onClick={() => {
                                          const docs =
                                            props?.perantState?.[op.name];
                                          docs.splice(index, 1);
                                          props.setPerantState({
                                            ...props?.perantState,
                                            contract: docs,
                                          });
                                        }}
                                      >
                                        <Svg
                                          type="close"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                      </>
                    ) : op.field.type == "ck" ? (
                      <ComponentCKEditer
                        style={{ marginRight: "25px" }}
                        value={props.perantState[op.name]}
                        callback={(data) =>
                          props.setPerantState({
                            ...props.perantState,
                            [op.name]: data,
                          })
                        }
                      />
                    ) : op.field.type == "date" ? (
                      <ComponentDatePicker
                        name={op.name}
                        min={op.field.min}
                        value={props.perantState[op.name]}
                        callback={(e) =>
                          props.setPerantState({
                            ...props.perantState,
                            [op.name]: e.target.value,
                          })
                        }
                      />
                    ) : op.field.type == "textArea" ? (
                      <MinHeightTextarea
                        class={"custonInput"}
                        placeholder=""
                        name="description"
                        value={props.perantState[op.name]}
                        minRows={op.field.minRows}
                        callback={(e) =>
                          props.setPerantState({
                            ...props.perantState,
                            [op.name]: e.target.value,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            className="textF"
                            {...params}
                            sx={{ paddingLeft: "2px" }}
                            variant="standard"
                            // label="Multiple values"
                            placeholder={t("placeholder.type_atleast_1_letter")}
                          />
                        )}
                      />
                    ) : op.field.type == "multiple" ? (
                      <Stack
                        spacing={3}
                        sx={{ width: "auto" }}
                        className="authoritiesGroup multiselect"
                      >
                        <Autocomplete
                          multiple
                          className="AutoComp"
                          id="tags-standard"
                          disabled={op?.field?.disable}
                          options={
                            op?.field?.option && op?.field?.option?.length != 0
                              ? op?.field?.option
                              : []
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id == value.id
                          }
                          value={props?.perantState?.[op.name]}
                          getOptionLabel={(option) => option[op.field.setlable]}
                          onChange={(e, value) =>
                            props.setPerantState({
                              ...props.perantState,
                              [op.name]: value,
                            })
                          }
                          renderTags={(value, getTagProps) =>
                            props?.perantState?.[op.name]?.map(
                              (option, index) => (
                                <Chip
                                  className="chipstyle "
                                  variant="outlined"
                                  label={option[op.field.setlable]}
                                  {...getTagProps({ index })}
                                />
                              )
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              className="textF"
                              {...params}
                              sx={{ paddingLeft: "2px" }}
                              variant="standard"
                              // label="Multiple values"
                              placeholder={t(
                                "placeholder.type_atleast_1_letter"
                              )}
                            />
                          )}
                        />
                      </Stack>
                    ) : op.field.type === "time" ? (
                      <div className="noteremider">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            className="timePicker"
                            value={
                              props?.perantState?.[op.name]
                                ? moment(props?.perantState?.[op.name])
                                : null
                            }
                            onChange={(time) => {
                              let value = null;
                              if (!!time?.$d && time?.$d !== "INVALID DATE") {
                                value = moment(time?.$d);
                              }
                              props.setPerantState({
                                ...props.perantState,
                                [op.name]: value,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            minTime={
                              props.perantState[op.field.minTime]
                                ? moment(props.perantState[op.field.minTime])
                                : null
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    ) : op.field.type === "showFields" ? (
                      <div></div>
                    ) : (
                      ""
                    )}
                    <div className="validationRed">
                      {props.errors?.[op.name] && (
                        <span className="validate">
                          {t(props.errors[op.name])}
                        </span>
                      )}
                    </div>
                    {/* {props.errors?.[op.name]&&<span className="validate">{props.errors[op.name]}</span>} */}
                  </Grid>
                </Grid>
              ))}
          </div>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid container className="modalGroup" style={{ marginTop: "5px", gap: "1rem" }}>
            {props.modalData.buttonGroup &&
              props.modalData.buttonGroup.map((op, index) => (
                <Grid item key={index}>
                  <ComponentButton
                    value={t(op.value)}
                    variant={op.variant}
                    class={op.class}
                    callback={() => {
                      op.callback(props.perantState, props?.optionalVal);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}
