import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Svg from "../../../../assets/svg/svg";
import SubmitToModal from "./SubmitToModal";
import { baseURL } from "../../../../api/axios";
import http from "../../../../api/axios";
import auth from "../../../../services/auth";

const FileItem = ({ item, enablArchive, enableSubmitTo, getFiles }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const user = auth.getUser("user");
  const [submitModal, serSubmitModal] = useState({
    open: false,
    id: "",
  });

  const handlesArchive = async () => {
    const formdata = new FormData();
    formdata.append("userId", user.id);
    formdata.append("fieldId", item.id);
    await http.postApi(
      t,
      `/api/form_doc/form/sendAchirveDocument`,
      formdata,
      "labels.save_document_as_archive"
    );
    getFiles && getFiles();
  };

  return (
    <>
      {enableSubmitTo && (
        <SubmitToModal
          id={submitModal?.id}
          open={submitModal?.open}
          onCLose={() =>
            serSubmitModal({
              id: "",
              open: false,
            })
          }
        />
      )}
      <Grid
        style={{ padding: "10px 10px !important" }}
        className="perviewBox"
        container
        spacing={3}
      >
        <Grid item xs={6} md={6}>
          <div className="detailsBox">
            <div>
              <Svg
                type={
                  item?.type === "pdf"
                    ? "pdf"
                    : item?.type === "doc"
                    ? "doc"
                    : item?.type === "xls"
                    ? "xls"
                    : "document"
                }
              />
            </div>
            <div>
              <Typography variant="h6" className="title iconText">
                {item?.name ?? item?.title}
              </Typography>
              <Typography variant="body2" className="details">
                {t("labels.date")} :{" "}
                {moment(item?.created_at ?? item?.created_at).format("LLL")}{" "}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className=""
          item
          xs={6}
          md={6}
          sm={12}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <ComponentButton
            value={t("labels.preview")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
            callback={() => {
              if (item?.type) {
                window.open(`${baseURL}/${item?.path}`, "_blank");
              } else {
                history.push(`/form_preview/client/assigned/${item?.id}`);
              }
            }}
          />
          {enablArchive && item?.type && (
            <ComponentButton
              value={t("labels.archive_now")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                handlesArchive();
              }}
            />
          )}
          {enableSubmitTo && (
            <ComponentButton
              value={t("labels.submit_to")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                serSubmitModal({
                  id: item?.id,
                  open: false,
                });
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FileItem;
