import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Checkbox,
  TableContainer,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import Info from "../../assets/images/info.png";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ComponentSearch from "./componentSearch";
import TablePagination from "@mui/material/TablePagination";
import { Menu, MenuItem } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import IosShareIcon from "@mui/icons-material/IosShare";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import ComponentFilterModal from "./componectFilterModel";
import { Link } from "react-router-dom";
import Svg from "../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import ComponentDatePicker from "./componentDatepicker";
import CoulmnFilterModel from "./coulmnFilterModel";

export default function ComponentTable(props) {
  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalRecords,
    defaultstatus = true,
  } = props;

  const [contentPrint, setContantPrint] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterDisplay, setfilterDisplay] = useState([]);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [modalData, setModelData] = useState({ model: false });
  const [selectall, setSelectall] = useState(false);

  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setRows(props?.rows);
    if (props?.columns) {
      const columns = props?.columns?.map((item) => {
        return { ...item, isActive: true };
      });
      setColumns(columns);
      setVisibleColumns(columns);
    }
  }, [props?.rows, props?.columns]);

  const handleSelectAll = (e) => {
    setSelectall(e.target.checked);
    const ids = rows?.map((item) => item?.id);
    props?.handleAllselect && props?.handleAllselect(e, ids);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let BtnClass;

  return (
    <div className="TableALLcommon dashTopGrid">
      {props?.filterData && (
        <ComponentFilterModal
          title={props.pageData.title}
          filterData={props.filterData}
          setFilterData={props.setFilterData}
          modalData={modalData}
          setModelData={setModelData}
          columns={props.columns}
          setfilterDisplay={setfilterDisplay}
          filterDisplay={filterDisplay}
          enableReset={props?.enableReset}
          resetdata={props?.resetdata}
        />
      )}
      <div className="CommonPd latest_des_box">
        <Grid
          className="TableALLcommonTopLaw hdkkos"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { sm: "left", lg: "center" },
            flexdirection: { lg: "column" },
          }}
        >
          <Grid
            className="tableTopLaw TableLayout"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.pageData.title && (
              <div className="tableHeading">
                {props.pageData.hideTitle &&
                  !props.pageData.showReplaceTitle ? (
                  ""
                ) : props.pageData.hideTitle &&
                  props.pageData.showReplaceTitle ? (
                  <div className="dateMonth">
                    <ComponentDatePicker
                      class={"custonInput"}
                      type={"month"}
                      placeholder=""
                      name={props.pageData.showReplaceTitle.name}
                      value={
                        props?.filterData[props.pageData.showReplaceTitle.name]
                      }
                      callback={(evt) => {
                        props?.setFilterData({
                          ...props.filterData,
                          filter: true,
                          [props.pageData.showReplaceTitle.name]:
                            evt.target.value,
                        });
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h3"
                    sx={{ paddingTop: "5px !important" }}
                    className="TableHeading"
                  >
                    {t(props.pageData.title)}
                  </Typography>
                )}
              </div>
            )}
            <Grid item className="searchResponsiveLaw">
              {props.pageData.hideSearch ? (
                ""
              ) : (
                <ComponentSearch
                  value={props.search}
                  callback={(e) => props.setSearch(e.target.value)}
                />
              )}
            </Grid>
          </Grid>

          <Grid className="tableTopbtnLaw">
            <Stack direction="row" className="btn_groupLaw" spacing={2}>
              <CoulmnFilterModel
                columns={visibleColumns}
                setColumns={setVisibleColumns}
              />
              {props.pageData?.buttonGroup?.map((op, index, cindex) => {
                {
                  BtnClass = op.class + " MrightMTestRt";
                }
                return op.type == "actions" ? (
                  <Button
                    variant="contained"
                    onClick={handleClick}
                    endIcon={
                      <KeyboardArrowDownIcon
                        sx={{ color: "white", paddingLeft: "20px" }}
                      />
                    }
                    className="buttonFill Mright1"
                  >
                    {t(op.title)}
                  </Button>
                ) : op.type == "filter" ? (
                  <Button
                    key={index}
                    variant={op.variant}
                    startIcon={op.icon}
                    className={" " + BtnClass}
                    onClick={() => setModelData({ model: true })}
                  >
                    {t(op.title)}
                  </Button>
                ) : op.type == "export" ? (
                  <Pdf targetRef={contentPrint} filename="export.pdf">
                    {({ toPdf }) => {
                      rows?.length > 0 && (
                        <Button
                          key={index}
                          variant={op.variant}
                          startIcon={op.icon}
                          className={op.class}
                          onClick={toPdf}
                        >
                          {t(op.title)}
                        </Button>
                      );
                    }}
                  </Pdf>
                ) : (
                  <Button
                    key={index}
                    variant={op.variant}
                    startIcon={op.icon}
                    className={" " + BtnClass}
                    onClick={op.callback}
                  >
                    {t(op.title)}
                  </Button>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
        {props.pageData.hideSearch &&
          props.pageData.showReplaceSearch &&
          props.pageData.hideSearch &&
          props.pageData.showReplaceSearch.type == "attendance" ? (
          <div class="workingHorusGird">
            <div class="workingHrsItems">
              <span class="iconLeft iconHrs1">
                <Svg type="clock" />
              </span>
              <div class="hrsTimeWorks">
                <h3 class="timeHeadings">{props.filterData?.average_count}</h3>
                <p>{t("titles.avg_working_hours")}</p>
              </div>
            </div>
            <div class="workingHrsItems">
              <span class="iconLeft iconHrs1">
                <Svg type="logout_attendence" />
              </span>
              <div class="hrsTimeWorks">
                <h3 class="timeHeadings">
                  {props.filterData?.average_checkIn}
                </h3>
                <p>{t("titles.avg_checkin_hour")}</p>
              </div>
            </div>
            <div class="workingHrsItems">
              <span class="iconLeft iconHrs1">
                <Svg type="logout_attendence1" />
              </span>
              <div class="hrsTimeWorks">
                <h3 class="timeHeadings">
                  {props.filterData?.average_checkOut}
                </h3>
                <p>{t("titles.avg_checkout_hour")}</p>
              </div>
            </div>
            <div class="workingHrsItems">
              <span class="iconLeft iconHrs1">
                <Svg type="logout_attendence1" />
              </span>
              <div class="hrsTimeWorks">
                <h3 class="timeHeadings">
                  {props.filterData?.average_offDays}
                </h3>
                <p>{t("titles.days_Off")}</p>
              </div>
            </div>
          </div>
        ) : props.pageData.hideSearch &&
          props.pageData.showReplaceSearch &&
          props.pageData.hideSearch &&
          props.pageData.showReplaceSearch.type == "leave" ? (
          <div>
            <span className="sickLeave">
              {props.leaveBalance?.sick_leave}{" "}
              {t("titles.days_available_sick_leaves")}
            </span>
            <span className="casual_leave">
              {props.leaveBalance?.casual_leave}{" "}
              {t("titles.days_available_casual_leaves")}
            </span>
            <span className="annual_leave">
              {props.leaveBalance?.annual_leave}{" "}
              {t("titles.days_available_annual_leaves")}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <Menu
        className="Action0con"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props?.selected && props.handleDelete ? (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              props.handleDelete();
              handleClose();
            }}
          >
            <div className="icon">
              <Svg type="delete" />
            </div>
            <span> {t("labels.delete")} </span>
          </MenuItem>
        ) : ''}

        <ReactToPrint
          trigger={() => (
            <MenuItem onClick={handleClose}>
              <LocalPrintshopIcon className="icon" />
              <span> {t("labels.print")} </span>
            </MenuItem>
          )}
          content={() => contentPrint}
        />
        <Pdf targetRef={contentPrint} filename="code-example.pdf">
          {({ toPdf }) => (
            <MenuItem
              onClick={(e) =>
                props?.pageData?.handlExport
                  ? props?.pageData?.handlExport(
                    page,
                    rowsPerPage,
                    props.filterData
                  )
                  : toPdf
              }
            >
              <IosShareIcon className="icon" />
              <span> {t("labels.export_as")} </span>{" "}
            </MenuItem>
          )}
        </Pdf>
      </Menu>
      <Box sx={{ width: "100%", mt: 3 }}>
        {rows?.length === 0 ? (
          <div className="centerFlex noDataFound">{t("labels.no_data")}</div>
        ) : (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                ref={(el) => setContantPrint(el)}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow style={{ background: "#FFFEFD" }}>
                    {visibleColumns &&
                      visibleColumns
                        .filter((item) => item.isActive)
                        .map((op, index) =>
                          op.name == "checkbox" ? (
                            <TableCell
                              key={`${op?.name}-${index}`}
                              align={op.align}
                              padding="checkbox"
                              style={{
                                padding: "15px",
                                color: "#B7A160",
                                minWidth: op.minWidth,
                                position: "sticky",
                                left: 0,
                                background: "white",
                                zIndex: 3,
                              }}
                            >
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                                checked={selectall}
                                onChange={(e) => handleSelectAll(e)}
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              key={`${op?.name}-${index}`}
                              style={{
                                display: filterDisplay.find((op) => op == index)
                                  ? "none"
                                  : "revert",
                                padding: "15px",
                                color: "#B7A160",
                                minWidth: op.minWidth,
                              }}
                              align={op.align}
                              padding="normal"
                              sortDirection="false"
                            >
                              {t(op.lable)}
                            </TableCell>
                          )
                        )}
                    {(props?.pageData?.infoURL ||
                      props?.pageData?.infoCallback ||
                      props?.pageData?.deleteCallback) && (
                        <TableCell
                          key={`info`}
                          style={{
                            padding: "15px",
                            color: "#B7A160",
                            minWidth: "10px",
                            position: "sticky",
                            right: 0,
                            background: "white",

                          }}
                          align="right"
                          padding="normal"
                          sortDirection="false"
                        >
                          <TableSortLabel></TableSortLabel>
                        </TableCell>
                      )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows &&
                    rows?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        style={{
                          padding: "15px",
                          display: filterDisplay.find((op) => op == index)
                            ? "none"
                            : "revert",
                          background:
                            index % 2 == 0
                              ? "rgba(183, 161, 96, 0.04)"
                              : "#FFFFFF",
                        }}
                      >
                        {visibleColumns &&
                          visibleColumns
                            .filter((item) => item.isActive)
                            .map((col, ind) =>
                              col.name == "checkbox" ? (
                                <TableCell
                                  padding="checkbox"
                                  align={col.align}
                                  style={{
                                    padding: "15px",
                                    display: filterDisplay.find(
                                      (op) => op == ind
                                    )
                                      ? "none"
                                      : "revert",
                                    position: "sticky",
                                    left: 0,
                                    background: "white",
                                  }}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      props?.selected &&
                                      props?.selected?.includes(row?.id)
                                    }
                                    onChange={(e) =>
                                      props?.handleSelected(e, row?.id)
                                    }
                                  />
                                </TableCell>
                              ) :
                                props?.pageData?.page === "Matters" && col.name === "status" ? (<TableCell
                                  align={col.align}
                                  style={{
                                    padding: "15px",
                                    display: filterDisplay.find(
                                      (op) => op == ind
                                    )
                                      ? "none"
                                      : "revert",
                                    color:
                                      parseInt(row?.status) === 0 ? '#FF993A' : parseInt(row?.status) === 1 ? '#02B935' : parseInt(row?.status) === 2 ? '#FF0101' : ''
                                    ,
                                  }}
                                >
                                  {parseInt(row?.status) === 0 ? 'Active' : parseInt(row?.status) === 1 ? 'Done' : parseInt(row?.status) === 2 ? 'Archived'
                                    // : parseInt(row?.status) === 3 ? 'Archived'
                                    : ''
                                  }
                                  {/* {console.log(row)} */}
                                </TableCell>)
                                  :
                                  props?.pageData?.page === "allocations" && col.name == "status" ? (
                                    <TableCell
                                      align={col.align}
                                      style={{
                                        padding: "15px",
                                        display: filterDisplay.find(
                                          (op) => op == ind
                                        )
                                          ? "none"
                                          : "revert",
                                        color:
                                          parseInt(row?.status) === 0 ? '#969595' : parseInt(row?.status) === 1 ? '#FF993A' : parseInt(row?.status) === 2 ? '#02B935' : parseInt(row?.status) === 3 ? '#FF0101' : ''
                                        ,
                                      }}
                                    >
                                      {parseInt(row?.status) === 0 ? 'pending' : parseInt(row?.status) === 1 ? 'progress' : parseInt(row?.status) === 2 ? 'Approved' : parseInt(row?.status) === 3 ? 'Not approved' : ''}
                                    </TableCell>
                                  )
                                    :
                                    props?.pageData?.page === "consultation" && col.name == "status" ? (
                                      <TableCell
                                        align={col.align}
                                        style={{
                                          padding: "15px",
                                          display: filterDisplay.find(
                                            (op) => op == ind
                                          )
                                            ? "none"
                                            : "revert",
                                          color:
                                            parseInt(row?.status) === 1 ? '#969595' : parseInt(row?.status) === 3 ? '#FF993A' : parseInt(row?.status) === 5 ? '#02B935' : parseInt(row?.status) === 4 ? '#02B935' : parseInt(row?.status) === 2 ? '#FF0101' : ''
                                          ,
                                        }}
                                      >
                                        {parseInt(row?.status) === 1 ? t('labels.not_started') : parseInt(row?.status) === 2 ? t('labels.started') : parseInt(row?.status) === 3 ? t('labels.hold') : parseInt(row?.status) === 4 ? t('labels.in_progress') : parseInt(row?.status) === 5 ? t('labels.done') : ''}
                                      </TableCell>
                                    )
                                      :
                                      props?.pageData?.page === "Tasks" && col.name == "taskStatus" ? (
                                        <TableCell
                                          align={col.align}
                                          style={{
                                            padding: "15px",
                                            display: filterDisplay.find(
                                              (op) => op == ind
                                            )
                                              ? "none"
                                              : "revert",
                                            color:
                                              parseInt(row?.taskStatus) === 1 ? '#969595' : parseInt(row?.taskStatus) === 2 ? '#FF993A' : parseInt(row?.taskStatus) === 5 ? '#02B935' : parseInt(row?.taskStatus) === 4 ? '#02B935' : parseInt(row?.taskStatus) === 3 ? '#FF0101' : ''
                                            ,
                                          }}
                                        >
                                          {parseInt(row?.taskStatus) === 1 ? t('labels.not_started') : parseInt(row?.taskStatus) === 2 ? t('labels.started') : parseInt(row?.taskStatus) === 3 ? t('labels.hold') : parseInt(row?.taskStatus) === 4 ? t('labels.in_progress') : parseInt(row?.taskStatus) === 5 ? t('labels.done') : ''}

                                        </TableCell>

                                      )
                                        :
                                        col.name == "status" ? (
                                          <TableCell
                                            align={col.align}
                                            style={{
                                              padding: "15px",
                                              display: filterDisplay.find(
                                                (op) => op == ind
                                              )
                                                ? "none"
                                                : "revert",
                                              color:
                                                parseInt(row?.isActive) === 1 ||
                                                  parseInt(row?.status) === 1 ||
                                                  [
                                                    "Promotion Applied",
                                                    "Approved",
                                                    "Validated",
                                                    "Done",
                                                  ].includes(row[col.name])
                                                  ? "#02B935"
                                                  : row[col.name] === "Pending Approval"
                                                    ? "#FF9900"
                                                    : "#FF0101",
                                            }}
                                          >
                                            {defaultstatus
                                              ? parseInt(row?.isActive) === 1 ||
                                                parseInt(row?.status) === 1
                                                ? "Active"
                                                : "Not Active"
                                              : row[col.name]}
                                          </TableCell>
                                        ) : col.name == "task_status" ? (
                                          <TableCell
                                            align={col.align}
                                            style={{
                                              padding: "15px",
                                              display: filterDisplay.find(
                                                (op) => op == ind
                                              )
                                                ? "none"
                                                : "revert",
                                              color:
                                                row.branchName == "Open"
                                                  ? "#02B935"
                                                  : row.branchName == "In Progress"
                                                    ? "#FF9900"
                                                    : row.branchName == "Not Started"
                                                      ? "#FF0000"
                                                      : "#FF0101",
                                            }}
                                          >
                                            {row[col.name]}
                                          </TableCell>
                                        ) : col.name == "approval" ? (
                                          <TableCell
                                            align={col.align}
                                            style={{
                                              padding: "15px",
                                              display: filterDisplay.find(
                                                (op) => op == ind
                                              )
                                                ? "none"
                                                : "revert",
                                              color:
                                                row[col.name] == "Approved"
                                                  ? "#02B935"
                                                  : row[col.name] == "Pending Approval"
                                                    ? "#FF9900"
                                                    : "#FF0101",
                                            }}
                                          >
                                            {row[col.name]}
                                          </TableCell>
                                        ) : col.name == "priority" ? (
                                          <TableCell
                                            align={col.align}
                                            style={{
                                              padding: "15px",
                                              display: filterDisplay.find(
                                                (op) => op == ind
                                              )
                                                ? "none"
                                                : "revert",
                                              color:
                                                row.branchName == "Critical"
                                                  ? "#FF0000"
                                                  : "#484848",
                                            }}
                                          >
                                            {row[col.name]}
                                          </TableCell>
                                        )
                                          // : col.name == "taskStatus" ? (
                                          //   <TableCell
                                          //     align={col.align}
                                          //     style={{
                                          //       padding: "15px",
                                          //       display: filterDisplay.find(
                                          //         (op) => op == ind
                                          //       )
                                          //         ? "none"
                                          //         : "revert",
                                          //       color:
                                          //         row[col.name] == "Not Started"
                                          //           ? "red"
                                          //           : row[col.name] == "In Progress"
                                          //             ? "yellow"
                                          //             : "green",
                                          //     }}
                                          //   >
                                          //     {row[col.name]}
                                          //   </TableCell>
                                          // )
                                          :
                                          col.name ==
                                            Object.keys(row).find(
                                              (op) => op == col.name
                                            ) ? (
                                            <TableCell
                                              align={col.align}
                                              style={{
                                                padding: "15px",
                                                display: filterDisplay.find(
                                                  (op) => op == ind
                                                )
                                                  ? "none"
                                                  : "revert",
                                              }}
                                            >
                                              {row[col.name]}
                                            </TableCell>
                                          ) : (
                                            <TableCell
                                              align={col.align}
                                              style={{
                                                padding: "15px",
                                                display: filterDisplay.find(
                                                  (op) => op == ind
                                                )
                                                  ? "none"
                                                  : "revert",
                                              }}
                                            >
                                              NA
                                            </TableCell>
                                          )
                            )}
                        {(props?.pageData?.infoURL ||
                          props?.pageData?.infoCallback ||
                          props?.pageData?.deleteCallback) && (
                            <TableCell
                              align="right"
                              style={{
                                padding: "15px",
                                position: "sticky",
                                right: 0,
                                background: "white",
                              }}
                            >
                              <div
                                style={{ display: "flex", justifyContent: "end" }}
                              >
                                {props.pageData.infoURL && (
                                  <Link
                                    to={
                                      props.pageData.hasVariable
                                        ? `${props.pageData.infoURL}/${row.id}`
                                        : {
                                          pathname: props.pageData.infoURL,
                                          state: { id: row.id },
                                        }
                                    }
                                    onClick={
                                      props.pageData?.setRowToLocal
                                        ? () => {
                                          localStorage.setItem(
                                            props.pageData?.setRowToLocal,
                                            JSON.stringify(row)
                                          );
                                        }
                                        : null
                                    }
                                  >
                                    {props?.pageData?.infoIcon ? (
                                      <div className="icon">
                                        <Svg type={props?.pageData?.infoIcon} />
                                      </div>
                                    ) : (
                                      <img className="icon" src={Info} alt="" />
                                    )}
                                  </Link>
                                )}

                                {props?.pageData?.infoCallback && (
                                  <div
                                    onClick={() =>
                                      props?.pageData?.infoCallback(row)
                                    }
                                  >
                                    {props?.pageData?.infoIcon ? (
                                      <div className="icon" style={{ cursor: 'pointer' }}>
                                        <Svg type={props?.pageData?.infoIcon} />
                                      </div>
                                    ) : (
                                      <img className="icon" src={Info} alt="" style={{ cursor: 'pointer' }} />
                                    )}{" "}
                                  </div>
                                )}

                                {props.pageData.deleteCallback && (
                                  <div
                                    onClick={() =>
                                      props?.pageData?.deleteCallback(row)
                                    }
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <div className="icon">
                                      <Svg type="delete" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 15, 25]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("labels.row_per_page")}
            />
          </Paper>
        )}
      </Box>
    </div>
  );
}
