import React from "react";
import "./style.css";
import UpComingAppointments from "./PartnerMeeting/UpComingAppointments";
import PastDecisions from "./PartnerMeeting/PastDecisions";
import MeetingHistory from "./PartnerMeeting/MeetingHistory";

export default function PartnerMeeting() {
  return (
    <div className="PartnerMeetPg">
      <PastDecisions modulePath={"partnerMeetings"} />
      <UpComingAppointments modulePath={"partnerMeetings"} />
      <MeetingHistory modulePath={"partnerMeetings"} />
    </div>
  );
}
