import React, { useState, useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import http from "../../../../api/axios";
import PastDecisionsCard from "./PastDecisionsCard";
import "../style.css";
import LoadingContext from "../../../../context/LoaderContext";

const PastDecisions = ({ modulePath = "" }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [pendingDecisions, setPendingDecisions] = useState([]);

  const getPastDecisions = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/meeting/validation/waiting/list");
    setLoader(false);
    if (!!res) {
      setPendingDecisions([...res, ...res, ...res]);
    }
  };

  useEffect(() => {
    getPastDecisions();
  }, []);

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Typography className="DashTitle" variant="h4">
              {t("partner.partner_meeting")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {pendingDecisions?.map((item) => (
            <PastDecisionsCard
              modulePath={modulePath}
              item={item}
              key={item?.id}
              getPastDecisions={getPastDecisions}
            />
          ))}
          {pendingDecisions?.length === 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <div className="noDataFound">
                {t("labels.no_pending_decisions")}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PastDecisions;
