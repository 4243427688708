import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import * as YUP from "yup";
import ComponentButton from "../../componentGroups/componentButton";
import { getAllDataApi, getApi, patchApi, postApi } from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import FormInput from "../ProfileModule/ProfileTechnicalDepartment/FormInput";
import ComponentSelect from "../../componentGroups/componentSelect";


const VacancyForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);

  // to set branches state
  const [branches, setBranches] = useState();
  const getBranchesData = async () => {
    setLoader(true)
    const branchesRes = await getAllDataApi(t, `/api/branch/details`)
    const branchesOptions = branchesRes?.data?.map(op => {
      return {
        name: op?.branchName,
        id: op?.id
      }
    })
    setBranches(branchesOptions);
    setLoader(false)
  }
  useEffect(() => {
    getBranchesData()
  }, []);

  // to set departments state
  const [departments, setDepartments] = useState();
  const getDepartmentsOptions = async (branchId) => {
    // props.setLoader(true)
    if (branchId) {
      const departmentsRes = await getApi(t, `/api/department/branch-basis/${branchId}`);
      const departmentsOptions = departmentsRes?.map(op => {
        return {
          name: op?.departmentName,
          id: op?.id
        }
      })
      setDepartments(departmentsOptions)
    }
    // props.setLoader(false)
  }


  const formik = useFormik({
    initialValues: {
      jobTitle: "",
      candidatesNumber: "",
      branch: "",
      department: '',
    },
    // validateOnMount: true,
    enableReinitialize: true,

    validationSchema: YUP.object({
      jobTitle: YUP.string()
        .required("jobTitle is required.")
        .min(2, "must be at least 2 characters"),
      candidatesNumber: YUP.number()
        .required("candidatesNumber is required.")
      ,
      branch: YUP.number().required("branch is required."),
      department: YUP.number().required("department is required."),
    }),
    onSubmit: async (values) => {

      // add request
      let fd = new FormData();
      fd.append("jobTitle", values.jobTitle);
      fd.append("candidatesNumber", values.candidatesNumber);
      fd.append("branchId", values.branch);
      fd.append("departmentId", values.department);

      const res = await postApi(t, `/api/vacancies`, fd);
      if (res.status === 200) { history.push(`/recruitments`); }

    },
  });

  // to get depts data
  useEffect(() => {
    if (formik.values?.branch) getDepartmentsOptions(formik.values?.branch);
  }, [formik.values?.branch]);



  return (
    <div className="SuggestDetailPg">
      <div class="CommonPd">
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.create_recruitment")}

              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            {/* branch */}


            <div className="FormsuggestForm">
              <label for="branch">
                {t("labels.branch")}
              </label>
              <div style={{
                flex: 1
              }}>
                <ComponentSelect
                  className="GenDropdown"
                  option={branches}

                  name="branch"
                  value={formik.values.branch}
                  callback={(e) => {
                    formik.setFieldValue('branch', e.target.value)
                  }}
                />
              </div>
            </div>

            {(formik.errors.branch && formik.touched.branch) ? (
              <p

                style={{
                  color: "#ff0101",
                  fontSize: "13px",
                  marginBottom: "-5px",
                  marginInlineStart: "16%"
                }}
              >
                *{formik.errors.branch}
              </p>
            ) : ''}






            {/* department */}
            <div className="FormsuggestForm">
              <label for="department">
                {t("labels.department")}
              </label>
              <div style={{
                flex: 1
              }}>
                <ComponentSelect
                  className="GenDropdown"
                  option={departments}

                  name="department"
                  value={formik.values.department}
                  callback={(e) => {
                    formik.setFieldValue('department', e.target.value)
                  }}
                />
              </div>
            </div>

            {(formik.errors.department && formik.touched.department) ? (
              <p

                style={{
                  color: "#ff0101",
                  fontSize: "13px",
                  marginBottom: "-5px",
                  marginInlineStart: "16%"
                }}
              >
                *{formik.errors.department}
              </p>
            ) : ''}

            {/* jobTitle */}
            <FormInput
              name="jobTitle"
              label={t("labels.job_title")}
              value={formik.values.jobTitle}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.errors.jobTitle && formik.touched.jobTitle
                  ? formik.errors.jobTitle
                  : null
              }
              required={true}
            />

            {/* No. Candidate */}
            <FormInput
              name="candidatesNumber"
              label={t("labels.candidate_number")}
              value={formik.values.candidatesNumber}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='number'
              error={
                formik.errors.candidatesNumber && formik.touched.candidatesNumber
                  ? formik.errors.candidatesNumber
                  : null
              }
              required={true}
            />

          </form>
          <div className="RefresugstBtn" style={{
            gap: '1rem',
            display: "flex"
          }}>




            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.discard")}
              variant={"outlined"}
              class={"buttonBlank delbtnRefrence"}
              callback={() => history.push(`/recruitments`)}
            />

            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.add")}
              variant={"contained"}
              class={"buttonFill"}
              callback={formik.submitForm}
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default VacancyForm