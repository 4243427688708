/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import moment from "moment";
import { loadOptions } from "../../../../api/utils/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";

export default function LateAttendance(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [lateAttendance, setLateAttendance] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.name",
      name: "name",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.code",
      name: "code",
      width: "10px",
      align: "left",
      minWidth: 110,
    },

    {
      lable: "labels.date",
      name: "date",
      width: "10px",
      align: "left",
      minWidth: 110,
      hide: true,
    },
    {
      lable: "labels.check_in",
      name: "checkIn",
      width: "10px",
      align: "left",
      minWidth: 130,
      hide: true,
    },
    {
      lable: "labels.check_out",
      name: "checkOut",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.late_duration",
      name: "lateDuration",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 50,
    },
    {
      lable: "labels.branch",
      name: "branchName",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
  ]);
  const [data, setData] = useState({
    title: "titles.late_attendance",
    page: "Late Attendance",
    hideSearch: true,
    infoURL:
      "/reports/performance-report/late-attandance/late-attendance-detail",
    handlExport: (page, size, filterData) =>
      handleExport(page, size, filterData),
    hasVariable: true,
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });
  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    let formdata = new FormData();
    formdata.append("filter[branchName]", filterData?.branchName ?? "");
    formdata.append("filter[name]", filterData?.name ?? "");
    formdata.append("filter[code]", filterData?.code ?? "");
    const res = await http.getAllDataApi(
      t,
      `/api/employee/late-attendence/export/`,
      formdata
    );
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[branchName]", filterData?.branchName ?? "");
    formdata.append("filter[name]", filterData?.name ?? "");
    formdata.append("filter[code]", filterData?.code ?? "");
    const res = await http.getAllDataApi(
      t,
      `/api/employee/attendence/late?&page=${pageIndex + 1}&size=${pageSize}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setFilterData({ ...filterData, filter: false });
    setLateAttendance(res.data);
    setLoader(false);
  };

  const setColumnData = async () => {
    let s1 = [...column];
    let employee = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let branches = await loadOptions(
      t,
      "branch",
      undefined,
      "post",
      "/api/branch/details"
    );
    const branch = branches.map((item) => {
      return {
        ...item,
        name: item?.branchName,
      };
    });
    s1[1].options = employee;
    s1[7].options = branch;
    setColumn(s1);
  };

  useEffect(() => {
    setColumnData();
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("task", 120)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(t, "", formData, "");
        getApiData(page, size);
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <ComponentTable
      rows={lateAttendance}
      columns={column}
      pageData={data}
      filterData={filterData}
      setFilterData={setFilterData}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{}}
    />
  );
}
