import React, { useState, useContext } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ComponentTextString from "./componentTextString";
import { useTranslation } from "react-i18next";
import ComponentButton from "./componentButton";
import { getUserHasAccess } from "../../utils/common";
import AuthorityContext from "../../context/AuthorityContext";

export default function ComponentSelect(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [addedLabel, setAddedlable] = useState("");
  return (
    <>
      <div className="flex-center" style={{ position: "relative" }}>
        <FormControl fullWidth disabled={props?.disabled}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={`selectBox ${i18n.language === "ar" ? "arabic" : ""} `}
            onChange={props?.callback}
            disabled={props?.disable}
            name={props.name}
            value={props.value}
            // defaultValue={props?.value}
          >
            {props?.option?.length != 0 &&
              props?.option?.map((op, index) => (
                <MenuItem value={op.id} key={index}>
                  {t(op.name)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {props?.enableDynamicOptionInput && (
          <AddCircleOutlineIcon
            className={props?.addIconClass ?? "onclickAdd"}
            sx={{
              color: "#367AF6",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            fontSize="medium"
            onClick={() => {
              if (getUserHasAccess("other", 157)) {
                setShow(!show);
                setAddedlable("");
              } else {
                setAuthorityModal(true);
              }
            }}
          />
        )}
      </div>
      {props?.enableDynamicOptionInput && show && (
        <>
          <div className="flex-center mtt">
            <ComponentTextString
              callback={(e) => {
                const reg = /^[a-zA-Z0-9\u0600-\u06FF_ ]*$/;
                if (reg.test(e.target.value)) {
                  setAddedlable(e.target.value);
                }
              }}
              value={addedLabel}
              class={props.optionInputClass}
            />
            <ComponentButton
              value={t("labels.save")}
              variant="contained"
              class="buttonFill FillBtn saveBtn"
              callback={(e) => {
                if (addedLabel?.trim() !== "") {
                  setShow(false);
                  props.onOptionSave(e, addedLabel);
                  setAddedlable("");
                }
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
