/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumbContext from "../../context/BreadCrumbContext";

const HeaderBreadCrumbs = ({ routes, match }) => {
  const { t } = useTranslation();
  const { pathValue } = useContext(BreadCrumbContext);
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    const crumbs = routes
      // Get all routes that contain the current one.
      .filter(({ path }) => match.path.includes(path))
      // Swap out any dynamic routes with their param values.
      .map(({ path, ...rest }) => ({
        path: Object.keys(match.params).length
          ? Object.keys(match.params).reduce(
              (path, param) => path.replace(`:${param}`, match.params[param]),
              path
            )
          : path,
        ...rest,
      }));
    if (pathValue[0]?.path && match.url === pathValue[0]?.path) {
      if (pathValue.length === 1) {
        const updated = crumbs.map((item) => {
          if (item.path === pathValue[0].path) {
            return { ...item, name: pathValue[0].name };
          } else {
            return item;
          }
        });
        setCrumbs(updated);
      } else {
        let updated = [...crumbs];
        pathValue.forEach((element) => {
          for (let index = updated.length - 1; index >= 0; index--) {
            if (updated[index].path === element.path) {
              updated[index] = { ...updated[index], name: element.name };
              break;
            }
          }
        });
        setCrumbs(updated);
      }
    } else {
      setCrumbs(crumbs);
    }
  }, [match.path, match.url, pathValue]);

  return (
    <div className="CommonPd">
      <Grid
        className="dashTopGrid"
        spacing={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack spacing={2}>
          <Breadcrumbs
            maxItems={4}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {crumbs.map(({ name, path }, index) => {
              const isLast = index === crumbs.length - 1;
              return isLast ? (
                <Typography style={{ fontSize: "1rem" }} key={name}>
                  {t(name)}
                </Typography>
              ) : (
                <Link key={name} to={path}>
                  {t(name)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Stack>
      </Grid>
    </div>
  );
};

export default HeaderBreadCrumbs;
