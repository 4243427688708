import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import ComponentButton from '../../../componentGroups/componentButton';

export default function HrProfileTasks() {
  const { t } = useTranslation();

  return (
    <div className="CommonPd">
      <div className="dashTopGrid">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >  <Grid>
            <Typography className="morningText" variant="h4">
            {t("labels.task_title ")}  
            </Typography>
          </Grid>
          <div className='ProfiletaskBtn'>
            <Button variant="span" color="text.secondary" className="Protaskbtn" >11 November 2022</Button>
          </div>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid className="ProTaskInner">

            <div className="protaskDescription">
              <ul>
                <li className='LText'> Assigned From</li>
                <li className='LText1'> Ahmed Mostafa (You)</li>
                <li className='LText2'>  Assigned To</li>
                <li className='LText3'> Omar Mohamed</li>
              </ul>
              <ul>
                <li className='LText'> Subject</li>
                <li className='LText1'> Hr Needs</li>
                <li className='LText2'>Attachment</li>
                <li className='LText3'>No Attachment Uploaded</li>
              </ul>
              <div className='descriptionTasks'>
                <span> Description</span>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
              </div>
            </div>


            <div className="right-taskBtn">
              <ComponentButton sx={{ color: '#fff', fontSize: 34, fontWeight: 'medium' }}
                value='Approve'
                variant={"contained"}
                class={"buttonFill"} />

              <ComponentButton sx={{ color: '#fff', fontSize: 34, fontWeight: 'medium' }}
                value='Comment'
                variant={"outlined"}
                class={"buttonBlank Mright"} />
              <ComponentButton sx={{ color: '#fff', fontSize: 34, fontWeight: 'medium' }}
                value='Assign To'
                variant={"outlined"}
                class={"buttonBlank Mright"} />
              <ComponentButton sx={{ color: '#fff', fontSize: 34, fontWeight: 'medium' }}
                value='Reject'
                variant={"outlined"}
                class={"buttonBlank Mright delbtnRefrence"} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>

  );
}