import React, { useRef, useContext } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "../style.css";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

const FileUpload = ({ item, buttonLabel, handleChange, authId, module }) => {
  const hiddenFileInput = useRef(null);
  const { setAuthorityModal } = useContext(AuthorityContext);

  return (
    <div className="flex-center">
      <input
        hidden
        name={item.category}
        className="file"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="application/pdf,application/vnd.ms-excel"
      />
      <button
        variant="contained"
        className=" addMoreFiles attachBtn"
        onClick={(e) => {
          e.preventDefault();
          if (getUserHasAccess(module, authId)) {
            hiddenFileInput.current.click();
          } else {
            setAuthorityModal(true);
          }
        }}
        style={{
          position: "relative",
          right: 0,
          top: 0,
          marginRight: "10px",
        }}
      >
        <AttachFileIcon
          sx={{
            color: "#2B564C",
            transform: "rotate(30deg)",
            width: "22px",
            height: "22px",
          }}
        />{" "}
        {buttonLabel}
      </button>
    </div>
  );
};

export default FileUpload;
