/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TextField, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import ComponentButton from "../../../componentGroups/componentButton";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import { loadOptions } from "../../../../api/utils/common";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import useFormatTime from "../../../../Hooks/useFormatTime";

const PAGE_VALUES = {
  add: "Add Attendance Detail",
  edit: "Edit Attendance Detail",
  view: "View Attendance Detail",
  getDataApi: "/api/employee/attendence-detail",
  getEmpDataApi: "/api/employee/find",
};

const EditEmployeeAttandance = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const { empId, type, attendanceId } = useParams();
  const { globalToLocal, customDateTimeToGlobal } = useFormatTime();
  const [errors, setErrors] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [attendanceData, setAttendanceData] = useState({
    name: "",
    code: "",
    departmentId: "",
    checkIn: "",
    checkOut: "",
    status: "",
  });
  const [dropDownOptions, setDropDownOptions] = useState({
    department: [],
  });

  const getEmployeeAttendanceData = async () => {
    setLoader(true);
    let res = await http.getAllDataApi(
      t,
      `${PAGE_VALUES.getDataApi}/${attendanceId}`
    );
    setAttendanceData({
      ...attendanceData,
      checkIn: res?.data?.updatedCheckIn
        ? globalToLocal(res?.data?.updatedCheckIn, "HH:mm:ss")
        : !!res?.data?.checkIn
        ? globalToLocal(res?.data?.checkIn, "HH:mm:ss")
        : "",
      checkOut: res?.data?.updatedCheckOut
        ? globalToLocal(res?.data?.updatedCheckOut, "HH:mm:ss")
        : res?.data?.checkOut
        ? globalToLocal(res?.data?.checkOut, "HH:mm:ss")
        : "",
      date: res?.data?.date,
      departmentId: res?.data?.departmentId,
      code: res?.data?.code,
      name: res?.data?.name,
      status: res?.data?.status,
    });
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .all([loadOptions(t, "departments", "", "get", `/api/department`)])
      .then((res) => {
        const department = res[0]?.map((item) => {
          return { ...item, name: item?.departmentName };
        });
        setDropDownOptions({
          ...dropDownOptions,
          department: department,
        });
      })
      .finally(() => {
        setLoader(false);
        if (attendanceId) {
          getEmployeeAttendanceData();
          setViewMode(type === "view");
        }
      });
  }, [attendanceId]);

  const validateErrors = (data) => {
    let error = {};
    // if (data.type === undefined || data.type === "") {
    //   error.type = "errors.enter_penalty_type_name";
    // }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(attendanceData);
      setErrors(error);
    }
  }, [attendanceData]);

  const handleAddEdit = async () => {
    let error = validateErrors(attendanceData);
    if (Object.keys(error).length === 0) {
      const api = `/api/employee/attendence/request/${attendanceId}`;
      setLoader(true);
      let checkIn = customDateTimeToGlobal(
        `${attendanceData?.date} ${attendanceData.checkIn}`
      );
      let checkOut = customDateTimeToGlobal(
        `${attendanceData?.date} ${attendanceData.checkOut}`
      );
      const formData = new FormData();
      formData.append("checkIn", checkIn);
      formData.append("checkOut", checkOut);
      formData.append("userId", empId);
      const res = await http.postApi(t, api, formData);
      if (res?.status) {
        history.goBack();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(type === "view" ? PAGE_VALUES.view : PAGE_VALUES.edit)}
                </Typography>
              </Grid>
              {!!attendanceData?.status && (
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div
                    className="promotionStatus"
                    style={{
                      background:
                        attendanceData?.status === 1
                          ? "#02b935"
                          : attendanceData?.status === 3
                          ? "#FF0101"
                          : "#b5b1b1",
                    }}
                  >
                    {attendanceData?.status === 1
                      ? t("labels.approved")
                      : attendanceData?.status === 3
                      ? t("labels.rejected")
                      : t("labels.pending")}
                  </div>
                </Grid>
              )}
            </Grid>

            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Employee Name")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="name"
                    value={attendanceData?.name}
                    disable={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Employee Code")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="name"
                    value={attendanceData?.code}
                    disable={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("DepartMent")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      disable={true}
                      name="departmentId"
                      value={attendanceData?.departmentId}
                      option={dropDownOptions?.department}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Check In")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        className="timePicker"
                        value={
                          attendanceData.checkIn
                            ? moment(attendanceData.checkIn, "HH:mm:ss")
                            : null
                        }
                        onChange={(time) => {
                          let value = null;
                          if (!!time?.$d && time?.$d !== "INVALID DATE") {
                            value = moment(time?.$d).format("HH:mm:ss");
                          }
                          setAttendanceData({
                            ...attendanceData,
                            checkIn: value,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  {errors?.checkIn && (
                    <span className="validate">{t(errors.checkIn)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Check Out")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        className="timePicker"
                        value={
                          attendanceData.checkOut
                            ? moment(attendanceData.checkOut, "HH:mm:ss")
                            : null
                        }
                        onChange={(time) => {
                          let value = null;
                          if (!!time?.$d && time?.$d !== "INVALID DATE") {
                            value = moment(time?.$d).format("HH:mm:ss");
                          }
                          setAttendanceData({
                            ...attendanceData,
                            checkOut: value,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minTime={
                          attendanceData.checkIn
                            ? moment(attendanceData.checkIn, "HH:mm:ss")
                            : null
                        }
                      />
                    </LocalizationProvider>
                  </div>
                  {errors?.endTime && (
                    <span className="validate">{t(errors.endTime)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("Date")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentDatePicker
                      class={"custonInput"}
                      placeholder=""
                      value={attendanceData?.date}
                      name="date"
                      disable={true}
                    />
                  </div>
                </Grid>
              </Grid>
              {!viewMode && !attendanceData?.status && (
                <Grid
                  container
                  style={{ margin: "16px 0", justifyContent: "center" }}
                >
                  <Grid item>
                    <ComponentButton
                      value={t("labels.discard")}
                      variant={"outlined"}
                      class={"buttonBlank outlineBtn"}
                      callback={() => history.goBack()}
                    />
                  </Grid>
                  <Grid item>
                    <ComponentButton
                      value={t("labels.save")}
                      variant="contained"
                      class="buttonFill FillBtn ml-3"
                      callback={() => {
                        handleAddEdit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default EditEmployeeAttandance;
