
const requestValidate = (data) => {
    let error = {};
    if (data.request == undefined || data.request == "") {
        error.request = "errors.enter_email";
    }
    return error;
};

export default {
    requestValidate
};
