import moment from "moment";

const useFormatTime = () => {
  const localToGlobal = (time) => {
    return moment(time).utc().format();
  };
  const globalToLocal = (time, format = "") => {
    const local = moment.utc(time).local();
    return !!format ? local.format(format) : local.format();
  };

  const customDateTimeToGlobal = (dateTime) => {
    const combinedDateTime = moment(dateTime, "YYYY-MM-DD HH:mm:ss");
    return moment.utc(combinedDateTime).format();
  };

  return {
    localToGlobal,
    globalToLocal,
    customDateTimeToGlobal,
  };
};

export default useFormatTime;
