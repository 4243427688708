/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import AddIcon from "@mui/icons-material/Add";
import ComponentModal from "../../componentGroups/componectModel";
import http, { baseURL } from "../../../api/axios";
import validate from "../../../Hooks/branch_validacion";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import { Country, City } from "country-state-city";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import LoadingContext from "../../../context/LoaderContext";
import moment from "moment";
import { getDuration } from "../../../utils/common";
import useFormatTime from "../../../Hooks/useFormatTime";
import { loadOptions } from "../../../api/utils/common";

export default function Branches(props) {
  const { t, i18n } = useTranslation();
  const { localToGlobal } = useFormatTime();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [branchState, setBranchState] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [errors, setErrors] = useState({});
  const [selectedData, setSelectedData] = useState([]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("branch", 67)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`branch[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/branch/delete/multiple",
          formData,
          "labels.branch_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  const column = [
    { lable: "", name: "checkbox", width: "10px", align: "center" },
    {
      lable: "labels.branch_name",
      name: "branchName",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.branch_code",
      name: "branchCode",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.employees_no",
      name: "empCount",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.clients_no",
      name: "clientCount",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.address",
      name: "branchesAddresses",
      width: "10px",
      align: "left",
    },
  ];

  const [branchData, setBranchData] = useState([]);
  const [data, setData] = useState({
    title: "tableTitle.4.branches",
    infoURL: "/branches/branchInfo",
    hasVariable: true,
    handlExport: (page, size) => handleExport(page, size),
    page: "Branches",
    search: search,
    setSearch: setSearch,
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("branch", 61)) {
            handleModel("addBranch");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_branch",
    data: [
      {
        lable: "labels.branch_name",
        field: { type: "text", required: true },
        name: "branchName",
      },
      {
        lable: "labels.department",
        name: "departments",
        field: {
          type: "multiple",
          required: true,
          setlable: "departmentName",
          option: [],
        },
      },
      {
        lable: "labels.country",
        name: "country",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
      {
        lable: "labels.city",
        name: "city",
        field: {
          type: "dropdown",
          findLable: "name",
          required: true,
          option: [],
        },
      },
      // {
      //   lable: "labels.start_time",
      //   name: "startTime",
      //   field: {
      //     type: "time",
      //     findLable: "startTime",
      //     required: true,
      //     format: "HH:mm:ss",
      //   },
      // },
      // {
      //   lable: "labels.end_time",
      //   name: "endTime",
      //   field: {
      //     type: "time",
      //     findLable: "endTime",
      //     required: true,
      //     format: "HH:mm:ss",
      //     minTime: "startTime",
      //   },
      // },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addBranch(value);
          // handleCloseModel();
        },
      },
    ],
  });

  const handleModel = (val) => {
    if (val == "addBranch") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getAllDataApi(t, `/api/branch/exportAllbranches`);
    setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/branch/details/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res.data_count);
    setBranchData(res.data);
    setLoader(false);
  };

  const removeDuplicateNames = (dataArray) => {
    const uniqueNamesSet = new Set();
    return dataArray.filter((obj) => {
      if (!uniqueNamesSet.has(obj.name)) {
        uniqueNamesSet.add(obj.name);
        return true;
      }
      return false;
    });
  };

  const getCity = async () => {
    const citiesRes = City.getCitiesOfCountry(branchState?.country)?.map(
      (item, index) => {
        return {
          ...item,
          id: `${index}-${item?.stateCode}-${item?.countryCode}`,
        };
      }
    );

    let s1 = { ...modalData };
    s1.data[3].field.option = removeDuplicateNames(citiesRes);
    setModelData(s1);
  };

  const setModalDropDown = async () => {
    let s1 = { ...modalData };
    const countryRes = Country.getAllCountries()?.map((item) => {
      return { ...item, id: item?.isoCode };
    });
    let allDepartments = await loadOptions(
      t,
      "",
      null,
      "get",
      "/api/department"
    );
    s1.data[1].field.option = allDepartments;
    s1.data[2].field.option = countryRes;
    setModelData(s1);
  };

  useEffect(() => {
    setModalDropDown();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.useBranchCreateValidate(branchState, moment);
      setErrors(error);
    }
  }, [branchState]);

  useEffect(() => {
    if (branchState?.country) {
      getCity();
    }
  }, [branchState?.country]);

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);
  useEffect(() => {
    getApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var endTime = moment(new Date()).add(10, "minutes");
    setBranchState({
      ...branchState,
      startTime: moment(new Date(), "HH:mm:ss"),
      endTime: moment(endTime, "HH:mm:ss"),
    });
  }, []);

  const addBranch = async (obj) => {
    let error = validate.useBranchCreateValidate(obj, moment);
    if (Object.keys(error).length === 0) {
      // setLoader(true);
      const countryRes = Country.getAllCountries()?.filter(
        (item) => item?.isoCode === obj?.country
      )[0];
      const timezone = {
        zone: countryRes?.timezones[0]?.zoneName,
        gmtOffset: countryRes?.timezones[0]?.gmtOffset,
        gmtOffsetName: countryRes?.timezones[0]?.gmtOffsetName,
      };
      const startMoment = moment(obj.startTime, "YYYY-MM-DD HH:mm:ss");
      const endMoment = moment(obj.endTime, "YYYY-MM-DD HH:mm:ss");
      const duration = getDuration(startMoment, endMoment);
      const formData = new FormData();
      formData.append("branchName", obj?.branchName);
      formData.append("branchCity", obj?.city);
      formData.append("branchCountry", obj?.country);
      obj?.departments?.map((op, index) => {
        formData.append(`departments[${index}]`, op.id);
      });
      // formData.append("startTime", localToGlobal(obj.startTime));
      // formData.append("endTime", localToGlobal(obj.endTime));
      // formData.append("duration", duration);
      formData.append("timezone[zone]", timezone?.zone);
      formData.append("timezone[gmtOffset]", timezone?.gmtOffset);
      formData.append("timezone[gmtOffsetName]", timezone?.gmtOffsetName);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.postApi(t, "/api/branch/create", formData);
      setLoader(false);
      getApiData();
      handleCloseModel();
    } else {
      setErrors(error);
    }
  };

  const handleCloseModel = () => {
    setBranchState({});
    setModelData({ ...modalData, model: false });
    setErrors({});
  };

  return (
    <div className="mainContainer">
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setBranchState}
        perantState={branchState}
      />
      {branchData && (
        <ComponentTable
          rows={branchData}
          columns={column}
          filterData={filterData}
          setFilterData={setFilterData}
          page={page}
          setSearch={setSearch}
          search={search}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          pageData={data}
          totalRecords={totalRecords}
          selected={selectedData}
          handleSelected={handleSelected}
          handleAllselect={handleAllselect}
          handleDelete={handleDelete}
          enableReset={true}
          resetdata={{}}
        />
      )}
    </div>
  );
}
