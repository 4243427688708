import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../../componentGroups/componentButton";
import ComponentUpload from "../../../../componentGroups//componentUpload";
import ComponentTextString from "../../../../componentGroups/componentTextString";
import ComponentSelect from "../../../../componentGroups/componentSelect";
import GenericModal from "../../../../componentGroups/genericModal";
import Svg from "../../../../../assets/svg/svg";
import http from "../../../../../api/axios";
import ReactChipInput from "react-chip-input";
import validate from "../../../../../Hooks/client_validation";
import LoadingContext from "../../../../../context/LoaderContext";

const INITIAL_VALUES = {
  name: "",
  selectedFolder: "",
  selectedRow: "",
  documents: [],
  keywords: [],
  assignTo: [],
  selectedDoc: "",
  department: "",
  empForApproval: "",
};

/**
 * defines related operation and label with it's related api
 */
const labelId = {
  1: { label: "labels.create_a_label", api: "/api/client/row/create" },
  2: { label: "labels.create_a_folder", api: "/api/client/folder/create" },
  3: { label: "labels.upload_doc", api: "/api/client/fileuploadinfolder" },
  4: {
    label: "labels.generate_doc",
    api: "/api/client/uploaddocumentinfolder",
  },
  5: {
    label: "labels.rename_folder",
    api: "/api/client/renamefoldername",
    type: "put",
  },
  6: {
    label: "labels.rename_file",
    api: "/api/client/updatefilename",
    type: "put",
  },
  7: {
    label: "Assigning Folder",
    api: "/api/client/document/assignto", //assign to folder
  },
  8: {
    label: "Assigning File",
    api: "/api/client/document/assignto", //assign To file
  },
};

const DocumentModal = ({
  operationId,
  clientId,
  open,
  onCLose,
  getApiData,
  dropDownOptions,
  loadDropdownOptions,
  folderId,
  fileId,
  getFiles,
  getFoldersByRow,
  setFileAddedToFolder,
  filetype,
  fileName,
  folderName,
}) => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [docValues, setDocValues] = useState({
    ...INITIAL_VALUES,
    name: folderName ?? fileName ?? "",
  });
  const [folderList, setFolderList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [depList, setDepList] = useState([]);
  const [depEmpList, setDepEmpList] = useState([]);

  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setErrors({});
    setDocValues(INITIAL_VALUES);
    onCLose();
  };

  /**
   * returns folder associated with selected row
   * @param {*} rowId
   */
  const getFolders = async (rowId) => {
    let res = await http.getApi(t, `/api/client/findallfolderbyrows/${rowId}`);
    if (res?.length > 0) {
      setFolderList(res);
    }
  };

  /**
   * returns  all department
   */
  const getAllDept = async () => {
    setLoader(true);
    let res = await http.getApi(t, `/api/department`);
    setLoader(false);

    const options = res?.map((op) => {
      return {
        ...op,
        name: op.departmentName ?? op?.option,
      };
    });
    if (res?.length > 0) {
      setDepList(options);
    }
  };

  /**
   * returns  all department employees
   */
  const getAllDeptEmployees = async (depId) => {
    setLoader(true);
    let res = await http.getApi(t, `/api/employee/all/department/${depId}`);
    setLoader(false);
    if (res?.length > 0) {
      setDepEmpList(res);
    }
  };

  /**
   * returns all employess
   */
  const getEmployees = async () => {
    let res = await http.getApi(t, "/api/employee/dropdown/all");
    if (res?.length > 0) {
      setEmpList(res);
    }
  };

  /**
   * returns all documents
   */
  const getDocs = async () => {
    let res = await http.getApi(t, "/api/form_doc/forms/getAll/forms");
    if (res?.length > 0) {
      const docs = res?.map((item) => {
        return { ...item, name: item?.title };
      });
      setDocList(docs);
    }
  };

  /**
   * used to fetch related folders to selcted row while uploading a dcoument to a folder
   */
  useEffect(() => {
    if (operationId === 3 || operationId === 4) {
      getFolders(docValues?.selectedRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docValues?.selectedRow]);

  useEffect(() => {
    if ([7, 8].includes(operationId)) {
      getEmployees();
    }
    if (operationId === 4) {
      // getAllFolders();
      getDocs();
      getAllDept();
    }
    if (operationId === 5) {
      setDocValues({ ...docValues, name: folderName });
    }
    if (operationId === 6) {
      setDocValues({ ...docValues, name: fileName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationId]);

  useEffect(() => {
    if (docValues?.department) {
      getAllDeptEmployees(docValues?.department);
    }
  }, [docValues?.department]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.docOperationModal(
        docValues,
        operationId,
        folderId,
        fileId
      );
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docValues]);

  /**
   * used to create labesl,folder based on operation Id
   */
  const createDocData = async () => {
    let error = validate.docOperationModal(
      docValues,
      operationId,
      folderId,
      fileId
    );
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      switch (operationId) {
        case 1:
          formData.append("name", docValues?.name);
          formData.append("clientId", clientId);
          break;
        case 2:
          formData.append("name", docValues?.name);
          formData.append("rowId", docValues?.selectedRow);
          break;
        case 3:
          formData.append("folderId", folderId ?? docValues?.selectedFolder);
          docValues?.keywords.forEach((element, index) => {
            formData.append(`keywords[${index}]`, element);
          });
          docValues?.documents.forEach((element, index) => {
            formData.append(`documents[${index}]`, element);
          });
          break;
        case 5:
        case 6:
          formData.append("name", docValues?.name);
          break;
        case 7:
        case 8:
          formData.append("shareType", operationId === 7 ? 1 : filetype);
          formData.append("shareId", operationId === 7 ? folderId : fileId);
          docValues?.assignTo?.forEach((item, index) => {
            formData.append(`userId[${index}]`, item?.id);
          });
          break;
        case 4:
          formData.append("folderId", folderId ?? docValues?.selectedFolder);
          formData.append("formdocumentId", docValues?.selectedDoc);
          formData.append("reviewer_id", docValues?.empForApproval);
          break;
        default:
          break;
      }
      let endPoint = labelId[operationId]?.api;
      let res = {};
      if (labelId[operationId]?.type === "put") {
        endPoint =
          operationId === 6
            ? `${endPoint}/${fileId}`
            : `${endPoint}/${folderId}`;
        res = await http.putApi(t, endPoint, formData);
      } else {
        res = await http.postApi(t, endPoint, formData);
      }
      setLoader(false);
      if (!!res) {
        if ([3, 4].includes(operationId) && !folderId) {
          setFileAddedToFolder &&
            setFileAddedToFolder(docValues?.selectedFolder);
        }
        if (
          [6, 7, 8].includes(operationId) ||
          ([3, 4].includes(operationId) && folderId)
        ) {
          // fetch files when either file date is updated or a file is added to that specified folder
          getFoldersByRow && getFoldersByRow();
          getFiles && getFiles();
        } else {
          loadDropdownOptions(
            "docRows",
            "",
            "get",
            `/api/client/findallrows/${clientId}`
          );
        }
        handleClose();
      }
    } else {
      setErrors(error);
    }
  };

  return (
    <GenericModal title={t(labelId[operationId]?.label)} open={open}>
      <div className="scroll-auto ModalContent" style={{ minHeight: "80px" }}>
        {[1, 2, 5, 6].includes(operationId) && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {operationId === 6
                  ? t("labels.file_name")
                  : operationId === 1
                  ? t("labels.label_name")
                  : t("labels.folder_name")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.name && (
                <span className="validate">{t(errors.name)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentTextString
                  placeholder={""}
                  name="name"
                  value={docValues?.name}
                  callback={(e) => {
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                {errors?.name && (
                  <span className="validate">{t(errors.name)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {(operationId === 2 || ([3, 4].includes(operationId) && !folderId)) && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.label_name")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.selectedRow && (
                <span className="validate">{t(errors.selectedRow)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentSelect
                  value={docValues?.selectedRow}
                  name="selectedRow"
                  option={dropDownOptions?.docRows}
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                {errors?.selectedRow && (
                  <span className="validate">{t(errors.selectedRow)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {operationId === 3 && !folderId && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.folder_name")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.selectedFolder && (
                <span className="validate">{t(errors.selectedFolder)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentSelect
                  value={docValues?.selectedFolder}
                  name="selectedFolder"
                  option={folderList}
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                {errors?.selectedFolder && (
                  <span className="validate">{t(errors.selectedFolder)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {operationId === 3 && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.upload_doc")}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.documents && (
                <span className="validate">{t(errors.documents)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentUpload
                  lable={t("upload_file")}
                  name="documents"
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: [
                        ...docValues.documents,
                        e.target.files[0],
                      ],
                    })
                  }
                  // accept="image/png, image/gif, image/jpeg" //enable if needed to acept only images
                />
                {docValues?.documents.map((item, index) => {
                  return (
                    <div className="fileDelete">
                      <span>{item.name}</span>
                      <span
                        onClick={() => {
                          const docs = docValues?.documents;
                          docs.splice(index, 1);
                          setDocValues({ ...docValues, documents: docs });
                        }}
                      >
                        <Svg type="close" style={{ cursor: "pointer" }} />
                      </span>
                    </div>
                  );
                })}
                {errors?.documents && (
                  <span className="validate">{t(errors.documents)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {operationId === 3 && (
          <Grid container md={12} className="modalGroup chipInput">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.keywords")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.keywords && (
                <span className="validate">{t(errors.keywords)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ReactChipInput
                  name="keywords"
                  chips={docValues?.keywords}
                  onSubmit={(chip) => {
                    setDocValues({
                      ...docValues,
                      keywords: [...docValues.keywords, chip],
                    });
                  }}
                  onRemove={(index) => {
                    const docs = docValues?.keywords;
                    docs.splice(index, 1);
                    setDocValues({ ...docValues, keywords: docs });
                  }}
                />
                {errors?.keywords && (
                  <span className="validate">{t(errors.keywords)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {operationId === 4 && !folderId && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.assign_to_folder")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.selectedFolder && (
                <span className="validate">{t(errors.selectedFolder)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentSelect
                  value={docValues?.selectedFolder}
                  name="selectedFolder"
                  option={folderList}
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                {errors?.selectedFolder && (
                  <span className="validate">{t(errors.selectedFolder)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {operationId === 4 && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.document")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.selectedDoc && (
                <span className="validate">{t(errors.selectedDoc)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentSelect
                  value={docValues?.selectedDoc}
                  name="selectedDoc"
                  option={docList}
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                {errors?.selectedDoc && (
                  <span className="validate">{t(errors.selectedDoc)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {operationId === 4 && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.department_for_approval")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.department && (
                <span className="validate">{t(errors.department)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentSelect
                  value={docValues?.department}
                  name="department"
                  option={depList}
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                      empForApproval: "",
                    })
                  }
                />
                {errors?.department && (
                  <span className="validate">{t(errors.department)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {operationId === 4 && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.reviewer_for_approval")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.empForApproval && (
                <span className="validate">{t(errors.empForApproval)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <ComponentSelect
                  value={docValues?.empForApproval}
                  name="empForApproval"
                  option={depEmpList}
                  callback={(e) =>
                    setDocValues({
                      ...docValues,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                {errors?.empForApproval && (
                  <span className="validate">{t(errors.empForApproval)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}

        {[7, 8].includes(operationId) && (
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={3}>
              <Typography variant="h1" className="lable">
                {t("labels.assign_to")}{" "}
                <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              </Typography>
              {/* {errors?.assignTo && (
                <span className="validate">{t(errors.assignTo)}</span>
              )} */}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <Autocomplete
                  name="assignTo"
                  className="AutoComp"
                  multiple
                  value={docValues?.assignTo}
                  options={empList || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setDocValues({
                      ...docValues,
                      assignTo: [...value],
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Type at least 1 letter"
                    />
                  )}
                />
                {errors?.assignTo && (
                  <span className="validate">{t(errors.assignTo)}</span>
                )}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.discard")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn"}
              callback={() => {
                handleClose();
              }}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t("labels.save")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => createDocData()}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default DocumentModal;
