const tasksValidate = (data, description = undefined) => {
  let error = {};
  if (data.taskTitle == undefined || data.taskTitle == "") {
    error.taskTitle = " *Please enter the title";
  }
  if (data.taskType == undefined || data.taskType == "") {
    error.taskType = " *Please select the tasks type";
  }
  if (data.assignTo == undefined) {
    if (data.assignedTO == undefined || data.assignedTO == "") {
      error.assignedTO = " *Please select the assigned to";
    }
  } else {
    if (data.assignTo == undefined || data.assignTo == "") {
      error.assignTo = " *Please select the assigned to";
    }
  }
  if (data.priority == undefined || data.priority == "") {
    error.priority = " *Please select the priority";
  }
  if (data.dueDate == undefined || data.dueDate == "") {
    error.dueDate = " *Please enter the dueDate";
  }
  if (data.related_project_id == undefined || data.related_project_id == "") {
    error.related_project_id = " *Please select the project";
  }
  if (data.requestBy == undefined || data.requestBy == "") {
    error.requestBy = " *Please select the requestBy";
  }
  if (data.sharedWith == undefined || data.sharedWith == "") {
    error.sharedWith = " *Please select the sharedWith";
  }
  if (description) {
    if (description == undefined || description == "") {
      error.description = " *Please enter the description";
    }
  } else {
    if (data.description == undefined || data.description == "") {
      error.description = " *Please enter the description";
    }
  }

  return error;
};

export default {
  tasksValidate,
};
