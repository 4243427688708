import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";

const LateMatterDetail = () => {
  const { matterId } = useParams();
  const id = matterId;
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [lateMatterDetail, setlateMatterDetail] = useState({
    name: "",
    code: "",
    date: "",
    checkIn: "",
    checkOut: "",
    branchName: "",
  });

  const getLateMatterDetail = async () => {
    setLoader(true);
    const res = await http.getApi(t, `/api/task/late-matter/${id}`);
    setlateMatterDetail({
      ...res,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      getLateMatterDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t("titles.late_matters")}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.matter_name")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="name"
                    value={lateMatterDetail.name}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.matter_code")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="code"
                    value={lateMatterDetail.code}
                    disab
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.responsible_emp")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="assigned_to"
                    value={lateMatterDetail.assigned_to}
                    disab
                  />
                </Grid>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.start_date")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="startDate"
                    value={lateMatterDetail?.startDate}
                    disab
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.end_date")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="endDate"
                    value={lateMatterDetail?.endDate}
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.late_duration")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    disable={true}
                    class={"custonInput"}
                    placeholder=""
                    name="late_duration"
                    value={lateMatterDetail?.late_duration}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default LateMatterDetail;
