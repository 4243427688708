import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { baseURL } from "../../../../../api/axios";
import ComponentButton from "../../../../componentGroups/componentButton";

const DecisionItem = ({
  op,
  index,
  rejectDecision,
  handleSelectDecision,
  disableUpdates,
}) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [rejectedUsers, setRejectedUsers] = useState(new Set([]));

  useEffect(() => {
    const users = op?.meeting_decision_rejections?.map((item) => item?.userId);
    if (users && users?.length > 0) {
      setRejectedUsers(new Set([...users]));
    }
  }, [op?.meeting_decision_rejections]);

  return (
    <li key={op.id || index}>
      <div className="Meeting_decisionRoot_container">
        {!!op?.id && (
          <Checkbox
            disabled={disableUpdates}
            color="primary"
            inputProps={{
              "aria-label": "select all desserts",
            }}
            checked={op.checked}
            onChange={(e) => handleSelectDecision(e.target.checked, op.id)}
          />
        )}
        <span for="decision" className="Meeting_decisionRoot_label">
          {t("labels.decision")} {index + 1}
        </span>
      </div>

      <div className="Meeting_decisionRoot_Title">
        <span>{op.title}</span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {op?.meeting_decision_rejections?.length > 0 && (
              <div className="avatarcontainer">
                Rejected By :
                <AvatarGroup max={3} className="avatargroup">
                  {op?.meeting_decision_rejections?.map((item) => {
                    return (
                      <Avatar
                        key={item?.id}
                        alt={item?.name}
                        src={`${baseURL}/${item?.imagePath}`}
                        className="avatar"
                      />
                    );
                  })}
                </AvatarGroup>
                <span>
                  {op?.meeting_decision_rejections[0]?.name}{" "}
                  {op?.meeting_decision_rejections?.length > 1 &&
                    t("labels.and")}
                  {op?.meeting_decision_rejections?.length > 1 && (
                    <>
                      <small>
                        {t("labels.more_people", {
                          count: op?.meeting_decision_rejections?.length - 1,
                        })}
                      </small>
                    </>
                  )}
                </span>
              </div>
            )}
          </div>
          {!!op?.id && !rejectedUsers.has(user?.id) && !disableUpdates && (
            <ComponentButton
              value={t("labels.reject")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn"}
              callback={() => rejectDecision(op.id)}
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default DecisionItem;
