import { Grid, Typography } from '@mui/material'
import React from 'react'
import ComponentButton from './componentButton';
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { getApi, postApi } from '../../api/axios';
import AvailableLeaveBalanceCard from './AvailableLeaveBalanceCard';
import { useContext } from 'react';
import AuthorityContext from '../../context/AuthorityContext';
import { getUserHasAccess } from '../../utils/common';

import validate from "../../Hooks/timeoffReq_validaction";
import ComponentModal from './componectModel';
import LoadingContext from '../../context/LoaderContext';

const AvailableLeaves = ({ empId }) => {
  const { t } = useTranslation();
  const [empVacationBalane, setEmpVacationBalane] = useState();
  const [leaveTypes, setLeaveTypes] = useState()
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { loader, setLoader } = useContext(LoadingContext);

  const [errors, setErrors] = useState({});

  const ASSIGN_VALUE = {
    leave_type: "",
    days: "",
    start_date: "",
    end_date: "",
    document: "",
  };

  const [timeoffState, setTimeoffState] = useState(ASSIGN_VALUE)

  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_request_timeoff",
    data: [
      {
        lable: "titles.leave_type",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: leaveTypes
        },
        name: "leave_type",
      },
      {
        lable: "labels.days_number",
        field: {
          type: "text",
          disable: true,
          required: true,
        },
        name: "days",
      },
      {
        lable: "labels.start_from",
        field: {
          type: "date",
          required: true,
        },
        name: "start_date",
      },
      {
        lable: "labels.to",
        field: {
          type: "date",
          required: true,
        },
        name: "end_date",
      },
      {
        lable: "labels.documents",
        name: "document",
        field: {
          type: "file",
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setErrors({});
          setTimeoffState(ASSIGN_VALUE);
          handleCloseModel();
        },
      },
      {
        value: "labels.save",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addTimeoff(value);
        },
      },
    ],

  })

  const addTimeoff = async (obj) => {
    console.log(obj, "er");
    let error = validate.timeoffValidation(obj);
    console.log(error, "er");
    if (Object.keys(error).length === 0) {
      setLoader(true);
      var formdata = new FormData();
      formdata.append("leave_type", obj?.leave_type);
      formdata.append("days", obj?.days?.split(" days")[0]);
      formdata.append("start_date", obj?.start_date);
      formdata.append("end_date", obj?.end_date);
      // obj?.approval_from?.map((op, index) => {
      //   formdata.append(`approvalFrom[${index}]`, op?.id);
      // });
      formdata.append("document", obj?.document);
      const res = await postApi(t, "/api/leave/timeoff", formdata);
      if (res) {
        setTimeoffState(ASSIGN_VALUE);
        getData();
        handleCloseModel();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };


  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addNew") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };

  const getAllData = async () => {
    let s1 = { ...modalData };
    let resTypes = await getApi(t, `/api/leave/type/available`);

    s1.data[0].field.option = resTypes;
    setModelData(s1);
  };

  const getData = async () => {
    let resBalance = await getApi(t, `/api/leave/emp-vacations/${empId}`);
    setEmpVacationBalane(resBalance)
  }


  useEffect(() => {
    let s1 = { ...modalData };

    if (timeoffState?.days || timeoffState?.end_date) setTimeoffState({ ...timeoffState, end_date: "", days: "" });

    if (timeoffState?.start_date != "") {
      s1.data[3].field.min = timeoffState?.start_date;
    }

    if (timeoffState?.end_date != "" && timeoffState?.start_date != "") {
      setTimeoffState({
        ...timeoffState,
        days: `${(new Date(timeoffState?.end_date) -
          new Date(timeoffState?.start_date)) /
          86400000 +
          1
          } days`,
      });
    }

    if (Object.keys(errors).length != 0) {
      let error = validate.timeoffValidation(timeoffState);
      setErrors(error);
    }

    setModelData(s1);
  }, [timeoffState?.start_date, timeoffState?.end_date]);


  useEffect(() => {
    getData()
  }, []);
  useEffect(() => {
    getAllData()
  }, []);
  // console.log(timeoffState, "holidayState");

  // console.log(empVacationBalane);
  return (
    <div className="CommonPd latest_des_box">
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setTimeoffState}
        perantState={timeoffState}
      />
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="morningText" variant="h4">
            {t("titles.availableLeaves")}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1.5rem"
            }}
          >
            {empVacationBalane?.length >= 1 ? <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.Request")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                if (getUserHasAccess("vacation", 151)) {
                  handleModel("addNew");
                } else {
                  setAuthorityModal(true);
                }
              }}
            /> : ''}
          </Grid>

        </Grid>

        {/* leaves balance */}
        <Grid
          sx={{
            display: "flex", flexWrap: "wrap",
            // justifyContent: "space-between",
            // alignItems: "center",
            gap: "1.5rem",
            marginTop: "2rem"
          }}
        >
          {
            empVacationBalane?.length >= 1 ? empVacationBalane?.map((v, i) => <AvailableLeaveBalanceCard key={i} days={v?.days} type={v?.leave_type} />) : <span className="noDataFound">
              {t('labels.no_data')}
            </span>
          }

        </Grid>
      </Grid>
    </div>
  )
}

export default AvailableLeaves