import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Grid, Avatar, AvatarGroup } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import Svg from "../../../../assets/svg/svg";
import { useHistory } from "react-router-dom";
import ComponentModal from "../../../componentGroups/componectModel";
import People from "../../../../assets/images/people.png";
import { useTranslation } from "react-i18next";
import moment from "moment";
import http from "../../../../api/axios";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

const DocumentForm = ({
  checkboxStatus,
  setmodalData,
  handleActions,
  modalData,
  modelState,
  setModelState,
  getApiData,
  op,
  setLoader,
  disablePreview = false,
}) => {
  const { t, i18n } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const history = useHistory();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const handleCloseModel = () => {
    setmodalData({
      ...modalData,
      model: false,
    });
  };

  const addComment = async (obj) => {
    if (obj?.comment == undefined || obj?.comment == "") {
      setErrors({ comment: "errors.error_comment" });
    } else {
      setLoader(true);
      console.log(obj, "datadatad");
      var formdata = new FormData();
      formdata.append("shareType", obj?.data.shareType);
      formdata.append("fileId", obj?.data.id);
      formdata.append("comments", obj?.comment);
      let res = await http.postApi(
        t,
        "/api/form_doc/form/addComment",
        formdata,
        "labels.comment_added_successfully"
      );
      if (res) {
        setErrors({});
        setModelState({});
        getApiData();
        handleCloseModel();
      }
      setLoader(false);
    }
  };

  const handleModal = (obj) => {
    setModelState({ ...modelState, data: obj });
    setmodalData({
      model: true,
      title: "labels.comment_on_document",
      data: [
        { lable: "titles.comment", name: "comment", field: { type: "text" } },
      ],
      buttonGroup: [
        {
          value: "labels.discard",
          variant: "outlined",
          class: "buttonBlank outlineBtn",
          callback: () => {
            handleCloseModel();
          },
        },
        {
          value: "labels.save",
          variant: "contained",
          class: "buttonFill FillBtn",
          callback: (value) => {
            addComment(value);
          },
        },
      ],
    });
  };

  return (
    <div className="folder">
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setModelState}
        perantState={modelState}
      />
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid>
          {op.shareType == 3 ? (
            <div className="detailsBox">
              {checkboxStatus?.files &&
                <span style={{ marginTop: "10px", marginRight: "10px" }}>
                  <input type="checkbox"
                    style={{
                      padding: "15px",
                      color: "#B7A160",
                      minWidth: op.minWidth,
                    }}
                    onChange={(e) => handleActions(e, op)} />
                </span>}
              <div className="pdfimgA">
                <Svg className="pdfimg" type="pdf" />
              </div>
              <div>
                <Typography variant="body2" className="iconText">
                  {op?.title} ({op?.fields_count})
                </Typography>
                <Typography
                  variant="body2"
                  className="details"
                  style={{ fontSize: "12px" }}
                >
                  {" "}
                  {t("labels.modified")} :{" "}
                  {op?.updatedAt &&
                    moment(op.updatedAt).format("MMMM DD , YYYY")}{" "}
                  {op?.updatedAt && "at"}{" "}
                  {op?.updatedAt && moment(op.updatedAt).format("hh A")}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="detailsBox">
              {checkboxStatus?.files &&
                <span style={{ marginTop: "10px", marginRight: "10px" }}>
                  <input type="checkbox"
                    style={{
                      padding: "15px",
                      color: "#B7A160",
                      minWidth: op.minWidth,
                    }}
                    onChange={(e) => handleActions(e, op)} />
                </span>}
              <div className="pdfimgA">
                <Svg
                  className="pdfimg"
                  type={op?.type === "doc" ? "pdfFile" : "document"}
                />
              </div>
              <div>
                <Typography variant="body2" className="iconText">
                  {op?.name}
                </Typography>
                <Typography variant="body2" className="details">
                  {" "}
                  {t("labels.modified")} :{" "}
                  {op?.updatedAt &&
                    moment(op.updatedAt).format("MMMM DD , YYYY")}{" "}
                  {op?.updatedAt && "at"}{" "}
                  {op?.updatedAt && moment(op.updatedAt).format("hh A")}
                </Typography>
              </div>
            </div>
          )}
        </Grid>
        {!disablePreview && (
          <Grid item xs={12} lg={4} md={4} className="buttonGroup1">
            {op.shareType == 3 ? (
              <ComponentButton
                value={t("labels.preview")}
                variant={"outlined"}
                class={"buttonBlank"}
                sx={{ paddingRight: "0px !important" }}
                callback={() =>
                  op.shareType == 3
                    ? history.push(`/form_preview/client/${"assigned"}/${op.id}`)
                    : ""
                }
              />
            ) : (
              <a href={`${http.baseURL}/${op?.path}`} target="_blank">
                <ComponentButton
                  value={t("labels.preview")}
                  variant={"outlined"}
                  class={"buttonBlank"}
                  sx={{ paddingRight: "0px !important" }}
                  callback={() =>
                    op.shareType == 3
                      ? history.push(`/form_preview/client/${"assigned"}/${op.id}`)
                      : ""
                  }
                />
              </a>
            )}
            <ComponentButton
              value={t("labels.add_comment")}
              variant={"contained"}
              class={"buttonFill"}
              sx={{ paddingRight: "0px !important" }}
              callback={() => {
                if (getUserHasAccess("form", 52)) {
                  handleModal(op);
                } else {
                  setAuthorityModal(true);
                }
              }}
            />
          </Grid>
        )}
      </Grid>
      <div className="commentSection">
        <div className="commentBox">
          <div className="avatarcontainer avtarBg">
            {/* {t("labels.assign_to")}: */}
            <AvatarGroup max={3} className="avatargroup">
              {op?.forms_comments?.map((item) => {
                return (
                  <Avatar
                    key={item?.UserProfilePic?.id}
                    alt={item?.UserProfilePic?.item?.name}
                    src={`${http.baseURL}/${item?.UserProfilePic?.path}`}
                    className="avatar"
                  />
                );
              })}
            </AvatarGroup>
            <span
              onClick={() => {
                if (!disablePreview) {
                  history.push(`/allComments/${op?.shareType}/${op?.id}`);
                }
              }}
              className="commentCursor"
            >
              {op?.forms_comments[0]?.comments}
              {op?.forms_comments?.length > 3 && (
                <small>
                  {t("labels.other_people", {
                    count: op?.forms_comments?.length - 3,
                  })}
                </small>
              )}
            </span>
          </div>

          {/* // <img src={People} />
                    // <span>
                    //     Mohamed commented on this Decision and
                    //     <small>3 People more</small>
                    // </span> */}
        </div>
      </div>
    </div>
  );
};
export default DocumentForm;
