import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import Svg from "../../../../assets/svg/svg";
// import CommentsView from "./CommentsView";
import http from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../componentGroups/componentButton";
import CommentsView from "../../PartnerScreens/BODMeeting/CommentsView";
import ComponentModal from "../../../componentGroups/componectModel";
import moment from "moment";
import { useHistory } from "react-router-dom";
const AllComments = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [comments, setComments] = useState([]);
  const [state, setState] = useState({});
  const [op, setOp] = useState({});
  const shareType = props.match.params.shareType;
  const fileId = props.match.params.fileId;
  const getApiData = async () => {
    let res = await http.getApi(
      t,
      `/api/form_doc/form/allComments/${shareType}/${fileId}`
    );
    let res1 = await http.getApi(
      t,
      `/api/form_doc/form/findOneDocOrfile/${shareType}/${fileId}`
    );
    setOp(res1);
    setComments(res);
  };
  useEffect(() => {
    getApiData();
  }, []);

  const [errors, setErrors] = useState({});
  const [modalData, setmodalData] = useState([]);
  const handleCloseModel = () => {
    setmodalData({
      ...modalData,
      model: false,
    });
  };

  const addComment = async (obj) => {
    if (obj?.comment == undefined || obj?.comment == "") {
      setErrors({ comment: "errors.error_comment" });
    } else {
      props.setLoader(true);
      console.log(obj, "datadatad");
      var formdata = new FormData();
      formdata.append("shareType", obj?.data.shareType);
      formdata.append("fileId", obj?.data.id);
      formdata.append("comments", obj?.comment);
      let res = await http.postApi(
        t,
        "/api/form_doc/form/addComment",
        formdata,
        "labels.comment_added_successfully"
      );
      if (res) {
        setErrors({});
        setState({});
        getApiData();
        handleCloseModel();
      }
      props.setLoader(false);
    }
  };

  const handleModal = (obj) => {
    setState({ ...state, data: obj });
    setmodalData({
      model: true,
      title: "labels.comment_on_document",
      data: [
        { lable: "titles.comment", name: "comment", field: { type: "text" } },
      ],
      buttonGroup: [
        {
          value: "labels.discard",
          variant: "outlined",
          class: "buttonBlank outlineBtn",
          callback: () => {
            handleCloseModel();
          },
        },
        {
          value: "labels.save",
          variant: "contained",
          class: "buttonFill FillBtn",
          callback: (value) => {
            addComment(value);
          },
        },
      ],
    });
  };

  return (
    <div className="DocPage allCommentPg">
      <div className="CommonPd">
        <ComponentModal
          errors={errors}
          modalData={modalData}
          setPerantState={setState}
          perantState={state}
        />
        <div className="latest_des_box allCommentPg">
          <Grid className="dashTopGrid ">
            <Box sx={{ mt: 2, p: 2 }} className="box">
              <div
                sx={{ display: "flex", justifyContent: "right" }}
                className=""
                container
                spacing={1}
              >
                <Grid sx={{ cursor: "pointer" }}>
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid>
                      {op.shareType == 3 ? (
                        <div className="detailsBox">
                          <div className="pdfimgA">
                            <Svg className="pdfimg" type="pdf" />
                          </div>
                          <div>
                            <Typography
                              variant="body2"
                              className="title iconText"
                            >
                              {op?.title} ({op?.fields_count})
                            </Typography>
                            <Typography variant="body2" className="details">
                              {" "}
                              {t("labels.modified")} :{" "}
                              {op?.updatedAt &&
                                moment(op.updatedAt).format(
                                  "MMMM DD , YYYY"
                                )}{" "}
                              {op?.updatedAt && "at"}{" "}
                              {op?.updatedAt &&
                                moment(op.updatedAt).format("hh A")}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div className="detailsBox">
                          <div>
                            <Svg
                              type={op?.type === "doc" ? "pdfFile" : "document"}
                            />
                          </div>
                          <div>
                            <Typography
                              variant="body2"
                              className="title iconText"
                            >
                              {op?.name}
                            </Typography>
                            <Typography variant="body2" className="details">
                              {" "}
                              {t("labels.modified")} :{" "}
                              {op?.updatedAt &&
                                moment(op.updatedAt).format(
                                  "MMMM DD , YYYY"
                                )}{" "}
                              {op?.updatedAt && "at"}{" "}
                              {op?.updatedAt &&
                                moment(op.updatedAt).format("hh A")}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Grid>
                    <Grid>
                      {op.shareType == 3 ? (
                        <ComponentButton
                          value={t("labels.preview")}
                          variant={"outlined"}
                          class={"buttonBlank Mright"}
                          sx={{ paddingRight: "0px !important" }}
                          callback={() =>
                            op.shareType == 3
                              ? history.push(`/form_preview/client/assigned/${op.id}`)
                              : ""
                          }
                        />
                      ) : (
                        <a href={`${http.baseURL}/${op?.path}`} target="_blank">
                          <ComponentButton
                            value={t("labels.preview")}
                            variant={"outlined"}
                            class={"buttonBlank Mright"}
                            sx={{ paddingRight: "0px !important" }}
                            callback={() =>
                              op.shareType == 3
                                ? history.push(
                                    `/form_preview/client/assigned/${op.id}`
                                  )
                                : ""
                            }
                          />
                        </a>
                      )}
                      <ComponentButton
                        value={t("labels.add_comment")}
                        variant={"contained"}
                        class={"buttonFill  "}
                        sx={{ paddingRight: "0px !important" }}
                        callback={() => {
                          handleModal(op);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <CommentsView comments={op?.forms_comments} />
            </Box>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AllComments;
