import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../api/axios";
import ComponentButton from "../../componentGroups/componentButton";
import LoadingContext from "../../../context/LoaderContext";

const LoanRequest = ({ refetch = false }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const history = useHistory();
  const [loanRequest, setLoanRequest] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [loanRequestsToVIew, setLoanRequestsToVIew] = useState([]);

  const getLoanRequest = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/employee/loan-request");
    setLoader(false);
    if (!!res && res?.length > 0) {
      setLoanRequest(res);
    } else {
      setLoanRequest([]);
    }
  };

  useEffect(() => {
    getLoanRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setLoanRequestsToVIew(loanRequest);
    } else {
      if (loanRequest?.length > 3) {
        let sliced = loanRequest?.slice(0, 3);
        setLoanRequestsToVIew(sliced);
      } else {
        setLoanRequestsToVIew(loanRequest);
      }
    }
  }, [showAll, loanRequest]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid className="TxtMbottom">
            <Typography className="morningText" variant="h4">
              {t("Loan Request")}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {loanRequest?.length > 3 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank Mright outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
          </Grid>
        </Grid>
        {loanRequestsToVIew?.map((item) => {
          return (
            <div className="dashboard Btn">
              <Grid className="gridLeft">
                <Typography component="span" variant="body2">
                  {item?.name} Sent You a Loan Request, &nbsp; Which needs to be
                  approved for amount
                  <spam className="highlight">{" " + item.amount}</spam>
                </Typography>
              </Grid>
              <Grid className="gridLeftbtn">
                <ComponentButton
                  value={t("labels.view_details")}
                  variant={"contained"}
                  class={"buttonFill FillBtn"}
                  callback={() => {
                    history.push(`/loan/loam-details/${item?.id}`);
                  }}
                />
              </Grid>
            </div>
          );
        })}
        {loanRequest?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">
              {t("titles.no_promotion_available")}
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default LoanRequest;
