import React, { useState } from "react";
import { Typography, Box, Grid, Tabs, Tab } from "@mui/material";
import Svg from "../../../../assets/svg/svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MeetingMints from "./meetingMints/index";
import Decision from "./meetingDecisions";
import http from "../../../../api/axios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="Tasksplpage">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MeetingDecision = (props) => {
  const { t, i18n } = useTranslation();
  const Id = props?.match?.params;
  const [value, setValue] = useState(0);
  const [meetData, setMeetData] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getApiData = async () => {
    if (Id?.id) {
      let res = await http.getApi(t, `/api/meeting/view/${Id?.id}`);
      setMeetData(res);
    }
    props.setLoader(false);
  };

  React.useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Box
          className="TaskTab TabActive"
          sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="iconText"
              label={t("sideBar.16.meeting_decision")}
              {...a11yProps(0)}
            />
            <Tab
              className="iconText"
              label={t("labels.meeting_minuts")}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Decision
            Id={Id}
            setLoader={props.setLoader}
            meetingData={meetData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MeetingMints
            Id={Id}
            setLoader={props.setLoader}
            meetingData={meetData}
          />
        </TabPanel>
      </div>
    </div>
  );
};
export default MeetingDecision;
