import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Grid } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import Svg from "../../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../../api/axios";
import moment from "moment";
import LoadingContext from "../../../../context/LoaderContext";

const DocumnetFolder = () => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setdata] = useState([]);

  const getFolders = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/meeting/folders");
    setLoader(false);
    if (!!res && res?.length > 0) {
      setdata(res);
    }
  };

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <div className="mainContainer  MeetingFolderPg">
      <Typography className="morningText" variant="h4">
        {t("titles.meeting_folders")}
      </Typography>
      <div>
        {data?.map((item, index) => (
          <Box sx={{ mt: 2, p: 2 }} className="box box1" key={index}>
            <Grid
              style={{
                padding: "10px 10px !important",
                marginTop: "0px",
                marginBottom: "0px",
              }}
              className="perviewBox"
              container
              spacing={3}
            >
              <Grid item xs={6} md={6} sm={12}>
                <div className="detailsBox">
                  <div className="FolderIcon">
                    <Svg type="folder" />{" "}
                  </div>
                  <div className="FolderN">
                    <Typography variant="h6" className="title iconText">
                      {item?.folder_name}
                    </Typography>
                    <Typography variant="body2" className="details">
                      {t("labels.date")} :{" "}
                      {moment(item?.created_at ?? item?.created_at).format(
                        "LLL"
                      )}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid
                className=""
                item
                xs={6}
                md={6}
                sm={12}
                sx={{
                  textAlign: "right",
                  gap: "5px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <ComponentButton
                  value={t("labels.open")}
                  variant={"contained"}
                  class={"buttonFill"}
                  sx={{ paddingRight: "0px !important" }}
                  callback={() =>
                    history.push(`/partnerBoard/documents/docFiles/${item?.id}`)
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default DocumnetFolder;
