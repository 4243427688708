import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  Grid,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../style.css";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import AddressesSet from "../../employees/addEmployees/addresses";
import hooks from "../../../../Hooks/hooks";
import ComponentSwitch from "../../../componentGroups/componentSwitch";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import Comments from "../../../componentGroups/Comments";

export default function GeneralInfo({
  initialAddressField,
  dropDownOptions,
  clientState,
  setClientState,
  errors,
  addDropdownOptions,
  setLoader

}) {
  const { t } = useTranslation();

  return (
    <>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.title")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            option={dropDownOptions.title}
            name="titleId"
            value={clientState?.titleId}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) => addDropdownOptions(val, "title", 7)}
          />{" "}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.name")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.name}
            name="name"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
          {errors?.name && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.name)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.phone")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.phoneNo}
            name="phoneNo"
            callback={(evt) => {
              if (!isNaN(evt.target.value)) {
                setClientState({
                  ...clientState,
                  [evt.target.name]: evt.target.value,
                });
              }
            }}
          />
          {errors?.phoneNo && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.phoneNo)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.clients_type")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="clientType"
            value={clientState?.clientType}
            option={dropDownOptions?.clientType}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
          {errors?.clientType && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.clientType)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.email")} <span style={{ color: "red" }}>*</span>{" "}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.email}
            name="email"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
          {errors?.email && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.email)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.category")}
            {/* <span style={{ color: "red" }}>*</span>{" "} */}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="categoryId"
            value={clientState?.categoryId}
            option={dropDownOptions?.category}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) => addDropdownOptions(val, "category", 12)}
          />
          {errors?.categoryId && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.categoryId)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.sub_category")}
            {/* <span style={{ color: "red" }}>*</span>{" "} */}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="subCategoryId"
            value={clientState?.subCategoryId}
            option={dropDownOptions?.subCategory}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) =>
              addDropdownOptions(val, "subCategory", 13)
            }
          />
          {errors?.subCategoryId && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.subCategoryId)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.relationship_type")}{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            name="relationshipType"
            value={clientState?.relationshipType}
            option={dropDownOptions?.relationshipType}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) =>
              addDropdownOptions(val, "relationshipType", 4)
            }
          />
          {errors?.relationshipType && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.relationshipType)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.branch")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            value={clientState?.branchId}
            option={hooks.sendName(dropDownOptions?.allBranches, "branchName")}
            name="branchId"
            callback={(evt) => {
              const selectedBranch = dropDownOptions?.allBranches.find(
                (item) => parseInt(item.id) === parseInt(evt.target.value)
              );
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
                branchCode: selectedBranch?.branchCode,
              });
            }}
          />
          {errors?.branchId && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.branchId)}`}
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.contract_no")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.contractNumber}
            name="contractNumber"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
          {errors?.contractNumber && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.contractNumber)}`}
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.contract_date")} <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentDatePicker
            class={"custonInput"}
            placeholder=""
            value={clientState?.contractDate}
            name="contractDate"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />
          {errors?.contractDate && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {`* ${t(errors?.contractDate)}`}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.procuration")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.procuration}
            name="procuration"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />{" "}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.reference")} #
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.refrence}
            name="refrence"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />{" "}
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body1" className="customLabel">
            {" "}
            {t("labels.tax")} #
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.tax}
            name="tax"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />{" "}
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body1" className="customLabel">
            {" "}
            {t("labels.additional_ids")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            option={dropDownOptions.idTypes}
            name="additionalId"
            value={clientState?.additionalId}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
            enableDynamicOptionInput={true}
            optionInputClass={"custonInput"}
            onOptionSave={(e, val) => addDropdownOptions(val, "idTypes", 14)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body1" className="customLabel">
            {" "}
            {t("labels.additional_ids")} #
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.additionalId2}
            name="additionalId2"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />{" "}
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.company_group")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            value={clientState?.companyGroup}
            name="companyGroup"
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />{" "}
        </Grid>
        {/* <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.country")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSelect
            className="GenDropdown"
            option={dropDownOptions.country}
            name="country"
            value={clientState?.country}
            callback={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.value,
              });
            }}
          />{" "}
        </Grid> */}
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
            {t("labels.status")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentSwitch
            className="GenDropdown"
            name="isActive"
            value={clientState?.isActive}
            leftLabel={t("labels.inActive")}
            rightLabel={t("labels.active")}
            handleChange={(evt) => {
              setClientState({
                ...clientState,
                [evt.target.name]: evt.target.checked,
              });
            }}
          />
        </Grid>
      </Grid>

      <div className="jobSection">
        <Typography variant="h6" className="morningText">
          {t("labels.job_details")}
        </Typography>
        <Grid
          className="AddEmpGenInfo"
          container
          spacing={3}
          sx={{ padding: "10px" }}
        >
          <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
            <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
              {t("labels.job_title")}
            </Typography>
          </Grid>
          <Grid className="CustomWidth" item xs={11} md={9}>
            <ComponentTextString
              class={"custonInput"}
              value={clientState?.jobTitle}
              name="jobTitle"
              callback={(evt) => {
                setClientState({
                  ...clientState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />{" "}
          </Grid>
          <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
            <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
              {t("labels.phone")}
            </Typography>
          </Grid>
          <Grid className="CustomWidth" item xs={11} md={9}>
            <ComponentTextString
              class={"custonInput"}
              value={clientState?.jobPhone}
              name="jobPhone"
              callback={(evt) => {
                if (!isNaN(evt.target.value)) {
                  setClientState({
                    ...clientState,
                    [evt.target.name]: evt.target.value,
                  });
                }
              }}
            />
            {errors?.jobPhone && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {`* ${t(errors?.jobPhone)}`}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
            <Typography variant="body1" className="customLabel" sx={{ pt: 1 }}>
              {t("labels.email")}
            </Typography>
          </Grid>
          <Grid className="CustomWidth" item xs={11} md={9}>
            <ComponentTextString
              class={"custonInput"}
              value={clientState?.jobEmail}
              name="jobEmail"
              callback={(evt) => {
                setClientState({
                  ...clientState,
                  [evt.target.name]: evt.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </div>

      {/* Address Section */}
      <div className="jobSection">
        <Typography variant="h6" className="morningText">
          {t("labels.address")}
        </Typography>
      </div>
      <AddressesSet
        empState={clientState}
        setEmpState={setClientState}
        dropDownOptions={dropDownOptions}
        initialAddressFields={initialAddressField}
      />
      <Comments setLoader={setLoader} viewId={clientState?.contractNumber} />
    </>
  );
}
