
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Grid } from '@mui/material';
import { t } from 'i18next';

import { useHistory } from "react-router-dom";


const BroadcastCard = ({ data }) => {
  // console.log(data);
  const outputDateFormat = 'Do MMMM YYYY [at] h.mm A';
  const convertedTime = moment.utc(data?.created_at).local().format(outputDateFormat);

  const main_text_styles = {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "rgb(43, 86, 76)"
  }
  const history = useHistory();

  const cickOnCard = () => {
    if (data) localStorage.setItem('broadcast', JSON.stringify(data));
    history.push(`/broadcasts/broadcast-info`);
  }

  return (
    <Card style={{ width: '100%', cursor: "pointer" }} onClick={cickOnCard}>
      <CardContent style={{ display: 'flex', flexDirection: 'column', gap: "1rem", padding: "1rem" }}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >

          <Typography style={{ fontSize: 15, ...main_text_styles }} >
            {data.title}
          </Typography>
          <Typography style={{ color: "rgba(43, 86, 76, .7)", fontWeight: "500" }}>
            {convertedTime}
          </Typography>
        </Grid>

        {data?.created_by ? <Grid
          sx={{
            display: "flex",
            gap: "1.2rem",
            alignItems: "center",
            marginInlineStart: "1rem"
          }}
        >

          <Typography style={{ fontSize: 13, ...main_text_styles, color: "#B7A160" }} >
            {t('labels.from')}
          </Typography>
          <Typography style={{ color: "rgb(43, 86, 76)", ...main_text_styles, fontWeight: "500", fontSize: 15 }}>
            {data?.created_by}
          </Typography>
        </Grid> : ''}




        {(data?.employeeName || data?.branchName || data?.departmentName) ? < Grid
          sx={{
            display: "flex",
            gap: "1.2rem",
            alignItems: "center",
            marginInlineStart: "1rem"
          }}
        >

          <Typography style={{ fontSize: 13, ...main_text_styles, color: "#B7A160" }} >
            {t('labels.type')}
          </Typography>
          <Typography style={{ color: "rgb(43, 86, 76)", ...main_text_styles, fontWeight: "500", fontSize: 15 }}>
            {
              data?.employeeName ? t('labels.employee') : data?.branchName ? t('labels.branch') : data?.departmentName ? t('labels.department') : ''
            }

          </Typography>
        </Grid> : ''}
        {(data?.employeeName || data?.branchName || data?.departmentName) ? < Grid
          sx={{
            display: "flex",
            gap: "1.2rem",
            alignItems: "center",
            marginInlineStart: "1rem"
          }}
        >

          <Typography style={{ fontSize: 13, ...main_text_styles, color: "#B7A160" }} >
            {t('labels.to')}
          </Typography>
          <Typography style={{ color: "rgb(43, 86, 76)", ...main_text_styles, fontWeight: "500", fontSize: 15 }}>

            {data?.employeeName || data?.branchName || data?.departmentName}
          </Typography>
        </Grid> : ''}

        <Typography style={{
          marginInlineStart: "1rem", color: "rgb(43, 86, 76)", fontSize: 13, fontWeight: "500",
          lineHeight: "1.6",
          /* Set the height for three lines of text */
          overflow: 'hidden', /* Hide any text that exceeds the maximum height */
          display: '-webkit-box', /* Enable the flexbox layout */
          WebkitBoxOrient: 'vertical', /* Make the box layout vertical */
          WebkitLineClamp: "3", /* Limit the text to three lines */
          textOverflow: 'ellipsis', /* Add three dots at the end of the third line */
          lineClamp: "3"
        }}
          component="div" variant="body1">
          {data.description}
        </Typography>
      </CardContent>
    </Card >
  );
};

export default BroadcastCard;