import React, { useEffect, useState, useContext } from "react";
import { Stack, Typography, Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import ComponentCKEditer from "../../../componentGroups/componentCKEditer";
import { useLocation, Link } from "react-router-dom";
import auth from "../../../../services/auth";
import http from "../../../../api/axios";
import { loadOptions } from "../../../../api/utils/common";
import hooks from "../../../../Hooks/hooks";
import { useTranslation } from "react-i18next";
import validate from "../../../../Hooks/tasks.validaction";
import ComponentSwitch from "../../../componentGroups/componentSwitch";
import ComponentAutoComplete from "../../../componentGroups/componectAutoComplete";
import moment from "moment";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import { TASK_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/task_key_lable";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import "./style.css";
import Svg from "../../../../assets/svg/svg";
import useFormatTime from "../../../../Hooks/useFormatTime";
import GenericModal from "../../../componentGroups/genericModal";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentRadioGroup from "../../../componentGroups/componentRadioGroup";
import Comments from "../../../componentGroups/Comments";

export default function TaskInfo(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { localToGlobal } = useFormatTime();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  location?.state?.id && auth.login("taskId", location?.state?.id);
  const Id = auth.getUser("taskId");
  const user = JSON.parse(localStorage.getItem("user"));
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState(false);
  const [taskUsers, setTaskUsers] = useState([]);
  const [viewMode, setViewMode] = useState(false);

  const [taskState, setTaskState] = useState({
    taskTitle: "",
    taskType: "",
    taskNumber: "",
    assignedFrom: "",
    priority: "",
    dueDate: "",
    requestBy: "",
    sharedWith: "",
    notifyMeBefore: "",
    format: "",
    byReminder: "",
    description: "",
    assignTo: [],
    clients_attachements: [],
    matters: [],
    notificationByMail: "",
    related_project_id: "",
    status: 1,
  });
  const [statusValue, setStatusValue] = useState(taskState?.status);
  const [options, setOptions] = useState({
    employees: [],
    projects: [],
    matters: [],
    contracts: [],
    duractions: [
      { id: 1, name: "Days" },
      { id: 2, name: "Hours" },
      { id: 3, name: "Minutes" },
    ],
    reminders: [
      { id: "1", name: "titles.in_system" },
      // { id: 2, name: "via mail" },
    ],
  });
  const [open, setOpen] = useState(false);

  const statusOptions = [
    { value: 1, label: t('labels.not_started') },
    { value: 2, label: t('labels.started') },
    { value: 3, label: t('labels.hold') },
    { value: 4, label: t('labels.in_progress') },
    { value: 5, label: t('labels.done') },
  ];

  const getOptionData = async () => {
    props.setLoader(true);
    let res = await loadOptions(t, 1, 1);
    let priorityRes = await loadOptions(t, 1, 2);
    let empRes = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let matterRes = await loadOptions(
      t,
      1,
      undefined,
      "get",
      "/api/task/matters"
    );
    let contractRes = await loadOptions(
      t,
      1,
      undefined,
      "get",
      "/api/task/contracts"
    );
    let projectRes = await loadOptions(
      t,
      1,
      undefined,
      "post",
      "/api/project/details"
    );
    setOptions({
      ...options,
      taskType: res,
      employees: empRes,
      projects: projectRes,
      priorities: priorityRes,
      contracts: contractRes,
      matters: matterRes,
    });
    props.setLoader(false);
  };

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/task/view/${Id}`);
    const taskData = {
      ...taskState,
      status: res?.taskStatus,
      taskTitle: res?.taskTitle ?? "",
      taskType: res?.taskType ?? "",
      taskNumber: res?.taskNumber ?? "",
      assignedFrom: res?.assignedFrom ?? "",
      priority: res?.priority ?? "",
      dueDate: res?.dueDate ?? "",
      requestBy: res?.requestBy ?? "",
      sharedWith: res?.sharedWith ?? "",
      notifyMeBefore: res?.notifyMeBefore ?? "",
      related_project_id: res?.related_project_id ?? "",
      byReminder: res?.byReminder ?? "",
      description: res?.description ?? "",
      assignTo: res?.assignTo ?? [],
      clients_attachements: res?.clients_attachements ?? [],
      matters: res?.matters ?? [],
      totalDuration: res?.totalDuration ?? "",
    };
    let userIds = res?.assignTo?.map((item) => item?.id);
    setTaskUsers([parseInt(res?.sharedWith), ...userIds]);
    setDescription(res?.description ?? "");
    setTaskState(taskData);
    setStatusValue(res?.status ?? taskState.status);
    setViewMode(res?.status === 4);
  };
  useEffect(() => {
    props.setLoader(true);
    getApiData();
    getOptionData();
  }, []);

  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    props.setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", 1);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        getOptionData();
      }
    }
    props.setLoader(false);
  };

  const updateTask = async () => {
    setFlag(false);
    let error = validate.tasksValidate(taskState, description);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      var formdata = new FormData();
      formdata.append("taskTitle", taskState?.taskTitle);
      formdata.append("taskType", taskState?.taskType);
      formdata.append("priority", taskState?.priority);
      formdata.append(
        "dueDate",
        moment(taskState?.dueDate).format("YYYY-MM-DD")
      );
      formdata.append("taskStatus", statusValue);
      formdata.append("requestBy", taskState?.requestBy);
      formdata.append("sharedWith", taskState?.sharedWith);
      formdata.append("notifyMeBefore", taskState?.notifyMeBefore);
      // formdata.append("taskStatus", taskState?.status);
      formdata.append("format", 1);
      formdata.append("related_project_id", taskState?.related_project_id);

      formdata.append("byReminder", taskState?.byReminder);
      taskState?.matters?.map((op, index) => {
        formdata.append(`relatedMatters[${index}]`, op?.id);
      });
      taskState?.clients_attachements?.map((op, index) => {
        formdata.append(`relatedContracts[${index}]`, op?.id);
      });
      taskState?.assignTo?.map((op, index) => {
        formdata.append(`assignedTo[${index}]`, op?.id);
      });
      formdata.append("notificationByMail", taskState?.notificationByMail);

      formdata.append("assignedFrom", taskState?.assignedFrom);
      formdata.append("langId", "1");
      formdata.append("description", description);
      let res = await http.putApi(t, `/api/task/update/${Id}`, formdata);
      props.setLoader(false);
      getApiData();
      setFlag(true);
    } else {
      setErrors(error);
    }
  };
  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.tasksValidate(taskState, description);
      setErrors(error);
    }
  }, [taskState]);

  const handleStartStopTime = async () => {
    if (taskState.status === 2) {
      setOpen(true);
    } else if (taskState.status === 1 || taskState.status === 3) {
      props.setLoader(true);
      const time = new Date();
      const startTime = localToGlobal(time);
      const formdata = new FormData();
      formdata.append("task_id", Id);
      formdata.append("status", 2);
      formdata.append("time", startTime);
      const res = await http.postApi(t, `/api/task/start/${Id}`, formdata);
      props.setLoader(false);
      if (res?.status) {
        getApiData();
      }
    }
  };

  const handleTaskContinue = () => {
    history.push(`/taskInfo/${Id}/add-logDetail`);
  };
  const handleTaskComplete = async () => {
    props.setLoader(true);
    const time = new Date();
    const endTime = localToGlobal(time);
    const formdata = new FormData();
    formdata.append("task_id", Id);
    formdata.append("status", 4);
    formdata.append("time", endTime);
    const res = await http.postApi(t, `/api/task/end/${Id}`, formdata);
    if (res?.data?.id) {
      history.push(`/taskInfo/${Id}/taskLogDetail/${res?.data?.id}`);
    }
    props.setLoader(true);
    setOpen(false);
  };

  return (
    <>
      <GenericModal
        title={`Complete Task`}
        open={open}
        enableCloseIcon={true}
        onClose={() => setOpen(false)}
      >
        <div className="scroll-auto ModalContent" style={{ minHeight: "80px" }}>
          <Grid container md={12} className="modalGroup">
            <Grid item sm={12}>
              <Typography textAlign={"center"}>
                Do you want To complete the Task
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "20px" }} className="ModalFooter">
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            <Grid item>
              <ComponentButton
                value={t("Complete")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => handleTaskComplete()}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("Continue")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => handleTaskContinue()}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div className="tasktitle-container">
                  <Typography
                    variant="h6"
                    className="morningText"
                    style={{ marginRight: "20px" }}
                  >
                    {taskState?.taskTitle} / {taskState?.taskNumber}
                  </Typography>
                  {taskUsers?.includes(user?.id) && taskState?.status !== 4 && (
                    <Button
                      key={"folterColumns"}
                      variant={"outlined"}
                      className={"buttonBlank"}
                      onClick={() => {
                        handleStartStopTime();
                      }}
                      startIcon={
                        <Svg
                          type="timer"
                          fill="#2b564c"
                          style={{ height: "22px" }}
                        />
                      }
                    >
                      {taskState.status === 1 || taskState.status === 3
                        ? "Start Count"
                        : "Stop Count"}{" "}
                    </Button>
                  )}
                </div>
              </Grid>
              {!viewMode && (
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "50px" }}
                    className="buttonFill"
                    onClick={() => updateTask()}
                  >
                    {t("labels.save")}
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid className="AddEmpGenInfo" container rowSpacing={2}>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.task_title")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentTextString
                  class={"custonInput"}
                  placeholder=""
                  name="taskTitle"
                  value={taskState?.taskTitle}
                  callback={(e) =>
                    setTaskState({ ...taskState, taskTitle: e.target.value })
                  }
                  disable={viewMode}
                />
                {errors?.taskTitle ? (
                  <span className="validateError">{errors?.taskTitle}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.task_number")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentTextString
                  class={"custonInput"}
                  value={taskState?.taskNumber}
                  disable={true}
                  name="taskNumber"
                />
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.task_type")}{" "}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentSelect
                  className="GenDropdown"
                  enableDynamicOptionInput={true}
                  name={"taskType"}
                  option={
                    options?.taskType &&
                    hooks.sendName(options?.taskType, "option")
                  }
                  value={taskState?.taskType}
                  callback={(e) =>
                    setTaskState({ ...taskState, taskType: e.target.value })
                  }
                  onOptionSave={(e, val) =>
                    addDropdownOptions(val, "taskType", 1)
                  }
                  disable={viewMode}
                />
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.assigned_to")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Stack
                  spacing={3}
                  sx={{ width: "auto" }}
                  className="authoritiesGroup multiselect"
                >
                  <ComponentAutoComplete
                    options={options?.employees}
                    values={taskState?.assignTo}
                    findLable={"name"}
                    optionName={"name"}
                    callback={(e, value) => {
                      if (getUserHasAccess("task", 121)) {
                        setTaskState({ ...taskState, assignTo: value });
                      } else {
                        setAuthorityModal(true);
                      }
                    }}
                    disable={viewMode}
                  />
                </Stack>
                {errors?.assignTo ? (
                  <span className="validateError">{errors?.assignTo}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.priority")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentSelect
                  className="GenDropdown custonInput"
                  enableDynamicOptionInput={true}
                  value={taskState?.priority}
                  option={
                    options?.priorities &&
                    hooks.sendName(options?.priorities, "option")
                  }
                  name="priority"
                  callback={(e) => {
                    if (getUserHasAccess("task", 121)) {
                      setTaskState({ ...taskState, priority: e.target.value });
                    } else {
                      setAuthorityModal(true);
                    }
                  }}
                  onOptionSave={(e, val) =>
                    addDropdownOptions(val, "priority", 2)
                  }
                  disable={viewMode}
                />
                {errors?.priority ? (
                  <span className="validateError">{errors?.priority}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.due_date")}
                </Typography>
              </Grid>
              <Grid className="DueDate" item xs={11} md={9}>
                <ComponentDatePicker
                  value={taskState?.dueDate}
                  name={"dueDate"}
                  callback={(e) => {
                    if (getUserHasAccess("task", 121)) {
                      setTaskState({ ...taskState, dueDate: e.target.value });
                    } else {
                      setAuthorityModal(true);
                    }
                  }}
                  disable={viewMode}
                />
                {errors?.dueDate ? (
                  <span className="validateError">{errors?.dueDate}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.request_by")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentSelect
                  option={
                    options?.employees &&
                    hooks.sendName(options?.employees, "name")
                  }
                  value={taskState?.requestBy}
                  callback={(e) =>
                    setTaskState({ ...taskState, requestBy: e.target.value })
                  }
                  name="requestBy"
                  disable={viewMode}
                />
                {errors?.requestBy ? (
                  <span className="validateError">{errors?.requestBy}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.related_project")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentSelect
                  option={
                    options?.projects &&
                    hooks.sendName(options?.projects, "projectName")
                  }
                  value={taskState?.related_project_id}
                  callback={(e) =>
                    setTaskState({
                      ...taskState,
                      related_project_id: e.target.value,
                    })
                  }
                  name="related_project_id"
                  disable={viewMode}
                />
                {errors?.related_project_id ? (
                  <span className="validateError">
                    {errors?.related_project_id}
                  </span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.shared_with")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentSelect
                  option={
                    options?.employees &&
                    hooks.sendName(options?.employees, "name")
                  }
                  value={taskState?.sharedWith}
                  callback={(e) =>
                    setTaskState({ ...taskState, sharedWith: e.target.value })
                  }
                  name="sharedWith"
                  disable={viewMode}
                />
                {errors?.sharedWith ? (
                  <span className="validateError">{errors?.sharedWith}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.notify_me_before")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div className="notifyme">
                  <ComponentTextString
                    class={"custonInput notifyinput"}
                    type="number"
                    name="notifyMeBefore"
                    value={taskState?.notifyMeBefore}
                    callback={(e) =>
                      setTaskState({
                        ...taskState,
                        notifyMeBefore: e.target.value,
                      })
                    }
                    disable={viewMode}
                  />

                  <Typography className="ByTxt">{t("labels.days")}</Typography>

                  <Typography className="ByTxt"> {t("labels.by")}</Typography>
                  <ComponentSelect
                    className="Notifythird"
                    option={
                      options?.reminders &&
                      hooks.sendName(options?.reminders, "name")
                    }
                    name="byReminder"
                    callback={(e) =>
                      setTaskState({ ...taskState, byReminder: e.target.value })
                    }
                    value={taskState?.byReminder}
                    disable={viewMode}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.related_matters")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Stack
                  spacing={3}
                  sx={{ width: "auto" }}
                  className="authoritiesGroup multiselect"
                >
                  <ComponentAutoComplete
                    options={options?.matters}
                    values={taskState?.matters}
                    findLable={"matterName"}
                    optionName={"matterName"}
                    callback={(e, value) =>
                      setTaskState({ ...taskState, matters: value })
                    }
                    disable={viewMode}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.related_contracts")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Stack
                  spacing={3}
                  sx={{ width: "auto" }}
                  className="authoritiesGroup multiselect"
                >
                  <ComponentAutoComplete
                    options={options?.contracts}
                    values={taskState?.clients_attachements}
                    findLable={"name"}
                    optionName={"name"}
                    callback={(e, value) => {
                      if (getUserHasAccess("task", 123)) {
                        setTaskState({
                          ...taskState,
                          clients_attachements: value,
                        });
                      } else {
                        setAuthorityModal(true);
                      }
                    }}
                    disable={viewMode}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.total_duration")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentTextString
                  class={"custonInput"}
                  value={taskState?.totalDuration}
                  disable="true"
                  name="taskNumber"
                />
              </Grid>
              <Grid className="CustomWidth" item xs={1} md={1}>
                <div
                  onClick={() => history.push(`/taskInfo/${Id}/task-log`)}
                  style={{
                    paddingLeft: "10px",
                    fontSize: "12px",
                    color: "blue",
                    cursor: "pointer",
                    marginTop: "8px",
                  }}
                >
                  {t("labels.more_details")}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.status")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentRadioGroup
                    options={statusOptions}
                    selectedOption={statusValue}
                    setSelectedOption={setStatusValue}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.description")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <ComponentCKEditer
                  value={description}
                  callback={(data) => setDescription(data)}
                  disable={viewMode}
                />
                {errors?.description ? (
                  <span className="validateError">{errors?.description}</span>
                ) : (
                  ""
                )}
              </Grid>

              {/* <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
              <Typography variant="body1" className="customLabel">
                {t("labels.send_notification_by_mails")}
              </Typography>
            </Grid>
            <Grid className="CustomWidth" item xs={11} md={9}>
              <ComponentSwitch
                className="GenDropdown"
                name="notificationByMail"
                value={taskState?.notificationByMail == 1 ? true : false}
                // leftLabel={t("inActive")}
                // rightLabel={t("active")}
                handleChange={(evt) => {
                  setTaskState({
                    ...taskState,
                    notificationByMail: evt.target.checked ? 1 : 0,
                  });
                }}
              />
            </Grid> */}
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid
        className="dashTopGrid"
        style={{
          marginBottom: "2rem",
        }}
      >
        {taskState?.taskNumber && (
          <LogNoteAccordion
            id={taskState?.taskNumber}
            labelKeys={TASK_KEY_LABELS}
            flag={flag}
          />
        )}
        {taskState?.taskNumber && (
          <Comments
            setLoader={props.setLoader}
            viewId={`TASK-${taskState?.taskNumber}`}
          />
        )}
      </Grid>
    </>
  );
}
