import React, { useEffect, useState, useContext } from "react";
import http from "../../../api/axios";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Calander from "./calander";
import ComponentAccordion from "../../componentGroups/componentAccordion";
import AccordionForm from "./AddMeetingAccordian/AddMeetingAccordionForm";
import ComponentButton from "../../componentGroups/componentButton";
import MeetingDetails from "./meetingDetail";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import MeetingDetails1 from "../companyDecision/meetingEmp/meetingDetails";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import { PickersDay, pickersDayClasses } from "@mui/x-date-pickers";
import "dayjs/locale/en";
import "dayjs/locale/ar";

export default function CreateMeeting(props) {
  const { t, i18n } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [load, setLoad] = useState(0);
  const [events, setEvents] = React.useState([]);
  const [values, setValues] = React.useState("");
  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  const data = {
    heading: `${t("titles.appointments_of")}  ${moment(values.$d).format(
      "MMMM DD, YYYY"
    )}`,
    panel: "panel1",
  };
  const [meetingData, setMeetingData] = useState([]);

  const getApiData = async () => {
    props.setLoader(true);
    const res1 = await http.getApi(t, `/api/meeting/events`);
    const res = await http.getAllDataApi(
      t,
      `/api/meeting/list?date=${moment(values.$d).format("YYYY-MM-DD")}`
    );
    setEvents(res1 || []);
    setMeetingData(res?.data || []);
    props.setLoader(false);
  };
  useEffect(() => {
    getApiData();
  }, [values, load]);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    let obj = {};
    events.map((a) => {
      if (
        moment(date.$d).format("DD-MM-YYYY") ==
        moment(a.date, "YYYY-MM-DD").format("DD-MM-YYYY")
      ) {
        obj = {
          backgroundColor: "red",
          color: "white",
        };
      }
    });
    let obj1 =
      moment(new Date()).format("DD-MM-YYYY") ==
      moment(date.$d, "YYYY-MM-DD").format("DD-MM-YYYY")
        ? { backgroundColor: "green", color: "white" }
        : "";
    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          ...obj,
          ...obj1,
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "blue",
          },
        }}
      />
    );
  };
  return (
    <>
      <div className="CommonPd">
        <div className="dashTopGrid">
          <Grid item xs={12}>
            <Typography
              className="DashTitle"
              variant="h6"
              sx={{ fontWeight: "600 !important" }}
            >
              {t("sideBar.15.calender")}
            </Typography>
          </Grid>
          {/* <h1>Calendar</h1> */}
          <div className="CalenderWrapper">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language === "en" ? "en" : "ar"}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={values}
                onChange={(newValue) => {
                  setValues(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                renderDay={renderWeekPickerDay}
              />
            </LocalizationProvider>
            <ComponentAccordion
              variant="h4"
              data={data}
              addedClassName={false}
              editIcon={false}
              component={
                <>
                  <MeetingDetails
                    setLoader={props.setLoader}
                    meetingData={meetingData}
                    values={values}
                  />
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <ComponentButton
                      value={t("labels.add_new")}
                      callback={() => {
                        if (getUserHasAccess("meeting", 103)) {
                          toggleAcordion(true);
                        } else {
                          setAuthorityModal(true);
                        }
                      }}
                      icon={
                        <AddIcon sx={{ paddingRight: "4px", color: "white" }} />
                      }
                      class="buttonFill"
                      variant="contained"
                    />
                  </Grid>
                </>
              }
            />
            <Accordion expanded={expand}>
              <AccordionSummary
                onClick={() => {
                  if (getUserHasAccess("meeting", 103)) {
                    toggleAcordion();
                  } else {
                    setAuthorityModal(true);
                  }
                }}
                className="clientInfoDocAccodian"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{
                  onClick: toggleAcordion,
                }}
              >
                <Typography variant="h4">{t("titles.add_meeting")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionForm
                  setLoader={props.setLoader}
                  setLoad={setLoad}
                  toggleAcordion={toggleAcordion}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}
