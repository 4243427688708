import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import AddIcon from "@mui/icons-material/Add";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@mui/icons-material/FilterList";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";

export default function Exective(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);

  const rows = [];

  const column = [
    { lable: "", name: "checkbox", width: "10px", align: "center" },
    {
      lable: t("labels.branch_name"),
      name: "branchName",
      width: "10px",
      align: "left",
    },
    {
      lable: t("labels.branch_code"),
      name: "branchCode",
      width: "10px",
      align: "left",
    },
    {
      lable: t("labels.employees_no"),
      name: "empCount",
      width: "10px",
      align: "left",
    },
    {
      lable: t("labels.clients_no"),
      name: "clientCount",
      width: "10px",
      align: "left",
    },
    {
      lable: t("labels.address"),
      name: "branchesAddresses",
      width: "10px",
      align: "left",
    },
  ];

  const [data, setData] = useState({
    title: "titles.managerial_report",
    infoURL: "/branches/branchInfo",
    hasVaribale: true,
    page: "Branches",
    search: search,
    setSearch: setSearch,
    buttonGroup: [
      {
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
    ],
  });

  return (
    <div className="mainContainer">
      <ComponentTable
        rows={rows}
        columns={column}
        page={page}
        setSearch={setSearch}
        search={search}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        pageData={data}
        totalRecords={totalRecords}
      />
    </div>
  );
}
