import React, { useState } from 'react';
import { Button, MenuItem, Dialog, DialogActions, InputLabel, Radio, RadioGroup, FormControl, FormLabel, DialogContent, DialogTitle, Grid, Select, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material/';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ComponentSelect from '../../../../../componentGroups/componentSelect';
import ComponentTextString from '../../../../../componentGroups/componentTextString';
import ComponentButton from '../../../../../componentGroups/componentButton';
import { useTranslation } from 'react-i18next';

export default function ResponsiveDialog(props) {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState("")
    const [option, setoption] = useState([]);
    const [textValue, setValueText] = useState("");
    const [table, settable] = useState("");
    const [checkArr, setCheckArr] = useState([{ field: "", lable: "1" }])
    const [radioArr, setradioArr] = useState([{ field: "", lable: "1" }, { field: "", lable: "2" }]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAdd = () => {
        if (props.data.type == "radio") {
            let error = null
            radioArr.map(op => error = op.field == '' ? true : false)
            setError(error ? "Please add the lable for radio" : "")
            if (!error) {
                let arr = { type: props.data.type, selected: radioArr[0].lable, fields: radioArr }
                setradioArr([{ field: "", lable: "1" }, { field: "", lable: "2" }])
                props.modelCallback(arr)
            }
        } else if (props.data.type == "dropdown") {
            let error = null
            error = option.length == 0 ? true : false
            setError(error ? "Please add the Option" : "")
            if (!error) {
                let arr = { type: props.data.type, fields: option }
                setoption([])
                props.modelCallback(arr)
            }
        } else if (props.data.type == "checkbox") {
            let error = null
            checkArr.map(op => error = op.field == '' ? true : false)
            setError(error ? "Please add the lable for checkbox" : "")
            if (!error) {
                let arr = { type: props.data.type, fields: checkArr }
                setCheckArr([{ field: "", lable: "1" }, { field: "", lable: "2" }])
                props.modelCallback(arr)
            }
        } else if (props.data.type == "table") {
            let rows = []
            let columns = []
            for (let i = 0; i < table.rows; i++) {
                rows.push(i)
            }
            for (let i = 0; i < table.columns; i++) {
                columns.push("header")
            }
            let error = null
            error = rows.length == 0 ? true : false
            error = columns.length == 0 ? true : false
            setError(error ? "Please add the Rows And Columns" : "")
            if (!error) {
                let arr = { type: props.data.type, fields: { rows: rows, columns: columns } }
                settable({})
                props.modelCallback(arr)
            }
        }
    };
    const handleChangeCheckBox = (e) => {
        const { name, value, checked } = e.target

    }
    const handleAddButton = () => {
        if (props.data.type == "dropdown") {
            if (textValue == "") {
                setError("Please enter any value")
            } else {
                setError("")
                let arr = []
                arr.push(textValue)
                setValueText("")
                setoption([...option, textValue])
            }
        } else if (props.data.type == "radio") {
            let arr = { field: "", lable: radioArr.length + 1 }
            setradioArr([...radioArr, arr])
        } else if (props.data.type == "checkbox") {
            let arr = { field: "", lable: checkArr.length + 1 }
            setCheckArr([...checkArr, arr])
        }
    }
    const handleRadioFields = (e, index) => {
        if (props.data.type == "radio") {
            let arr = [...radioArr]
            arr[index].field = e.target.value
            setradioArr(arr)
        } else {
            let arr = [...checkArr]
            arr[index].field = e.target.value
            setCheckArr(arr)
        }
    }
    const handleAddMoreRadio = (index) => {

    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography
                        variant="h6"
                        sx={{ paddingTop: "5px !important" }}
                        className="morningText"
                    >
                        {props.data.title}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {props.data.type == "dropdown"
                        ? <>
                            <FormControl fullWidth disabled={props?.disabled}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className="selectBox"
                                // defaultValue={props?.value}
                                >
                                    {option?.length != 0 &&
                                        option?.map((op, index) => (
                                            <MenuItem value={op} key={index}>
                                                {op}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {/* <ComponentSelect option={option} placeholder={"Select fields"} /> */}
                            <Grid class="box">
                                <ComponentTextString placeholder={"Enter the option "} value={textValue} callback={(e) => setValueText(e.target.value)} />
                                <Button sx={{ position: "relative" }} onClick={() => handleAddButton()}>+</Button>
                            </Grid>
                        </>
                        : props.data.type == "checkbox"
                            ?
                            <>
                                <div className="rootRadio">
                                    <FormGroup sx={{ alignItems: "center" }}>
                                        {checkArr.map(op => (
                                            <FormControlLabel value={op.field} control={<Checkbox defaultChecked checked={false} onChange={handleChangeCheckBox} />} label={op.field} />
                                        ))}
                                    </FormGroup>
                                    <span className="addRadio" onClick={() => handleAddButton()}>+</span>
                                </div>
                                <Grid class="box">
                                    <Grid class="boxRadio">
                                        {checkArr.map((op, index) => (
                                            <div key={index}>
                                                <InputLabel shrink htmlFor="bootstrap-input">
                                                    {op.lable} CheckBox
                                                </InputLabel>
                                                <ComponentTextString placeholder={"Enter the checbox Lable"} name={op.field} value={op.field} callback={(e) => handleRadioFields(e, index)} key={index} />
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                            </>
                            : props.data.type == "radio" ?
                                <>
                                    <div className="rootRadio">
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                {radioArr.map(op => (
                                                    <FormControlLabel value={op.field} control={<Radio />} label={op.field} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <span className="addRadio" onClick={() => handleAddButton()}>+</span>
                                    </div>
                                    <Grid class="boxRadio">
                                        {radioArr.map((op, index) => (
                                            <div key={index}>
                                                <InputLabel shrink htmlFor="bootstrap-input">
                                                    {op.lable} Radio
                                                </InputLabel>
                                                <ComponentTextString placeholder={"Enter the Radio Lable"} name={op.field} value={op.field} callback={(e) => handleRadioFields(e, index)} />
                                            </div>
                                        ))}
                                    </Grid>
                                </>
                                : props.data.type == "table"
                                    ? <Grid class="box">
                                        <Grid class="boxRadio">
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Rows
                                            </InputLabel>
                                            <ComponentTextString placeholder={"Enter the Rows "} type="number" value={table.rows} callback={(e) => settable({ ...table, rows: e.target.value })} />
                                            <InputLabel shrink htmlFor="bootstrap-input" sx={{ pt: 2 }}>
                                                Columns
                                            </InputLabel>
                                            <ComponentTextString placeholder={"Enter the Columns "} type="number" value={table.columns} callback={(e) => settable({ ...table, columns: e.target.value })} />
                                        </Grid>
                                    </Grid>
                                    : ""
                    }
                </DialogContent>
                {error ? <InputLabel sx={{ color: "red", fontSize: "12px", padding: "2px 2px", textAlign: "center" }}>{error}</InputLabel> : ""}
                <DialogActions>
                    <ComponentButton
                        value={t('labels.discard')}
                        variant="outlined"
                        class="buttonBlank outlineBtn"
                        callback={(index, fields) => {
                            setError("")
                            setCheckArr([{ field: "", lable: "1" }])
                            setradioArr([{ field: "", lable: "1" }, { field: "", lable: "2" }])
                            setoption([])
                            props.setchildModel(false)
                        }}
                    />
                    <ComponentButton
                        value="ADD NEW"
                        variant="contained"
                        class="buttonFill"
                        callback={() => {
                            handleAdd()
                        }}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}