/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import auth from "../../../services/auth";
import "./style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { gotoDetails } from "../../../utils/common";

const SearchResult = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("value");
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [hasMore, sethasMore] = useState(true);

  const getSearchResults = async () => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/user/search/${searchQuery}?page=${page + 1}&size=10`
    );
    setLoader(false);
    if (res?.dataSet) {
      let data = [...searchResult, ...res?.dataSet];
      setSearchResult(data);
      if (data.length < res?.data_count) {
        setPage(page + 1);
      }
      if (data.length >= res?.data_count) {
        sethasMore(false);
      }
    }
  };

  useEffect(() => {
    if (searchQuery && searchResult?.length > 0) {
      setSearchResult([]);
      sethasMore(true);
      setPage(0);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchResult?.length === 0 && searchQuery) {
      getSearchResults();
    }
  }, [searchResult?.length]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid className="TxtMbottom">
            <Typography className="morningText" variant="h4">
              {t("labels.serach_results_for")} "{searchQuery}"
            </Typography>
          </Grid>
        </Grid>
        <div id="scrollableDiv">
          <InfiniteScroll
            dataLength={searchResult.length}
            next={getSearchResults}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p style={{ textAlign: "center" }}></p>}
            scrollableTarget="scrollableDiv"
          >
            <Grid container>
              {searchResult?.map((item) => (
                <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                  <div className="searchinfo">
                    <div
                      onClick={() => gotoDetails(history, item.id, item.type)}
                      style={{ cursor: "pointer", display: "inline-block" }}
                    >
                      <div className="searchtitle">{item?.code}</div>
                      <div className="searchdescription">{item?.title}</div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </div>
        {searchResult?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">{t("labels.no_results_found")}</div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default SearchResult;
