export const Client_KEY_LABELS = {
  title: "labels.title",
  tax: "labels.tax",
  name: "labels.name",
  jobTitle: "labels.job_title",
  jobPhone: "labels.phone",
  jobEmail: "labels.email",
  category: "labels.category",
  clientType: "labels.clients_type",
  subCategory: "labels.sub_category",
  relationType: "labels.relationship_type",
  procuration: "labels.procuration",
  phoneNo: "labels.phone",
  companyGroup: "labels.company_group",
  zip: "labels.zip",
  state: "labels.state",
  country: "labels.country",
  city: "labels.company_group",
  address1: "labels.address_1",
  updatedBy: "titles.updated_by",
};
