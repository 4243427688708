import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";

const ComponentAccordion = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === props?.data?.panel}
        onChange={handleChange(props?.data?.panel)}
      >
        <AccordionSummary
          className={
            props.addedClassName ? "GreyBackground" : "clientInfoDocAccodian"
          }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography variant="h4" sx={{ width: "33%", flexShrink: 0 }}>
            {props?.data?.heading}
            {props?.editIcon && <EditIcon />}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {props?.data?.userName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={props.className}>
          {props?.component}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ComponentAccordion;
