import React, { useState, useContext } from "react";
import "../style.css";
import { Box, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentTextString from "../../../componentGroups/componentTextString";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../../api/axios";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { baseURL } from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

const BodDecisions = ({ data, index, getBodDecisions }) => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);

  const history = useHistory();
  const [openCommentBox, setOpenCommentBox] = useState(false);
  const [comment, setComment] = useState("");

  const handleCommentBox = () => {
    if (getUserHasAccess("meeting", 106)) {
      setOpenCommentBox(!openCommentBox);
      setComment("");
    } else {
      setAuthorityModal(true);
    }
  };

  const addCommnet = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("comment", comment);
    const res = await http.postApi(
      t,
      `/api/meeting/meeting-decision/${data?.id}/comment/add`,
      formData
    );
    getBodDecisions && getBodDecisions();
    setLoader(false);
    if (!!res) {
      handleCommentBox();
    }
  };

  return (
    <Box sx={{ mt: 2, p: 2 }} className="box" key={index}>
      <Grid
        style={{ padding: "10px 10px !important" }}
        className="perviewBox"
        container
        spacing={3}
      >
        <Grid item xs={6} md={12}>
          <div className="detailsBox">
            <div>
              <Typography variant="h5" className="Yellowtext">
                {data?.title}
              </Typography>
              <Typography variant="body2" className="details">
                {data?.description}
              </Typography>
            </div>
          </div>
          <div className="commentSection">
            <div className="commentBox">
              {!data?.comments || data?.comments?.length === 0 ? (
                <span className="noDataFound">
                  {t("labels.no_comments_available")}
                </span>
              ) : (
                <div
                  className="avatarcontainer"
                  onClick={() =>
                    history.push(`/bodDecision/decionDetails/${data.id}`)
                  }
                >
                  <AvatarGroup max={3} className="avatargroup">
                    {data?.comments?.map((item) => {
                      return (
                        <Avatar
                          key={item?.id}
                          alt={item?.name}
                          src={`${baseURL}/${item?.pic}`}
                          className="avatar"
                        />
                      );
                    })}
                  </AvatarGroup>
                  <span>
                    {data?.comments[0]?.name}{" "}
                    {t("labels.commented_on_decision")}{" "}
                    {data?.comments?.length > 1 && t("labels.and")}
                    {data?.comments?.length > 1 && (
                      <>
                        <small>
                          {t("labels.more_people", {
                            count: data?.comments?.length - 1,
                          })}
                        </small>
                      </>
                    )}
                  </span>
                </div>
              )}
            </div>
            {openCommentBox ? (
              <ComponentButton
                value={t("labels.discard")}
                variant="outlined"
                class="buttonBlank outlineBtn"
                callback={() => {
                  handleCommentBox();
                }}
              />
            ) : (
              <ComponentButton
                value={t("labels.add_comment")}
                variant={"contained"}
                class="buttonFill FillBtn"
                callback={() => {
                  handleCommentBox();
                }}
              />
            )}
          </div>
          {openCommentBox && (
            <div className="commentBox addComment">
              <ComponentTextString
                placeholder={t("labels.write_comment")}
                class="inputComment"
                value={comment}
                callback={(e) => {
                  setComment(e.target.value);
                }}
              />
              <ComponentButton
                value={t("labels.add_comment")}
                variant={"contained"}
                class="buttonFill FillBtn"
                callback={() => {
                  addCommnet();
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BodDecisions;
