const clientValidate = (data, type = "add") => {
  let error = {};

  if (data.name == undefined || data?.name?.trim() == "") {
    error.name = "errors.enter_name";
  }
  if (data.branchId == undefined || data.branchId == "") {
    error.branchId = "errors.select_branch";
  }
  if (data.email == undefined || data.email == "") {
    error.email = "errors.enter_email";
  }
  if (
    data?.email &&
    !/^[A-Z0-9\u0600-\u06FF._%+-]+@[A-Z0-9\u0600-\u06FF.-]+\.[A-Z]{2,4}$/i.test(
      data.email
    )
  ) {
    error.email = "errors.enter_valid_email";
  }

  if (data.clientType == undefined || data.clientType == "") {
    error.clientType = "errors.select_client_type";
  }
  if (data.relationshipType == undefined || data.relationshipType == "") {
    error.relationshipType = "errors.select_relationship_type";
  }

  if (data.contractNumber == undefined || data.contractNumber == "") {
    error.contractNumber = "errors.enter_contract_number";
  }
  if (data.contractDate == undefined || data.contractDate == "") {
    error.contractDate = "errors.enter_contract_date";
  }
  if (data.phoneNo == undefined || data.phoneNo == "") {
    error.phoneNo = "errors.enter_phone_number";
  }
  if (
    (data.phoneNo !== undefined || data.phoneNo !== "") &&
    data.phoneNo?.length < 10
  ) {
    error.phoneNo = "errors.enter_phone_number_min_length";
  }
  if (
    (data.phoneNo !== undefined || data.phoneNo !== "") &&
    data.phoneNo?.length > 20
  ) {
    error.phoneNo = "errors.enter_phone_number_max_length";
  }
  if (type === "add") {
    if (data.upload_cotract == undefined || data.upload_cotract == "") {
      error.upload_cotract = "errors.select_file";
    }
    if (data.jobTitle == undefined || data.jobTitle == "") {
      error.jobTitle = "errors.enter_job_description";
    }
  } else {
    // if (data.categoryId == undefined || data.categoryId == "") {
    //   error.categoryId = "errors.select_category_type";
    // }
    // if (data.subCategoryId == undefined || data.subCategoryId == "") {
    //   error.subCategoryId = "errors.select_category_type";
    // }
    // if (data.additionalId == undefined || data.additionalId == "") {
    //   error.additionalId = "Select Additional Id";
    // }
    // if (
    //   (data.jobPhone !== undefined || data.jobPhone !== "") &&
    //   data.jobPhone?.length < 10
    // ) {
    //   error.phoneNo = "errors.enter_phone_number_min_length";
    // }
    // if (
    //   (data.jobPhone !== undefined || data.jobPhone !== "") &&
    //   data.jobPhone?.length > 20
    // ) {
    //   error.jobPhone = "errors.enter_phone_number_max_length";
    // }
  }

  return error;
};
const matterValidate = (data) => {
  let error = {};
  if (data.matterName == undefined || data?.matterName?.trim() == "") {
    error.matterName = "errors.enter_name";
  }
  if (data.department == undefined || data.department == "") {
    error.department = "errors.select_department";
  }
  if (data.lawyer == undefined || data.lawyer == "") {
    error.lawyer = "errors.select_lawyer";
  }
  if (data.contract == undefined || data.contract?.length == 0) {
    error.contract = "errors.select_file";
  }
  return error;
};

const reminderValidate = (data, moment) => {
  let error = {};
  if (data.title == undefined || data?.title?.trim() == "") {
    error.title = "errors.enter_reminder_title";
  }
  if (data.typeId == undefined || data.typeId == "") {
    error.typeId = "errors.enter_reminder_type";
  }
  if (data.reminderDate == undefined || data.reminderDate == "") {
    error.reminderDate = "errors.enter_reminder_date";
  }

  if (data.reminderTime == undefined || data.reminderTime == "") {
    error.reminderTime = "errors.select_reminder_time";
  }
  if (data.repeatId == undefined || data.repeatId == "") {
    error.repeatId = "errors.enter_repeat_type";
  }

  if (data.notifyMeText == undefined || data.notifyMeText == "") {
    error.notifyMeText = "errors.select_notification_type";
  }
  if (data.notifyMeTime == undefined || data.notifyMeTime == "") {
    error.notifyMeTime = "errors.enter_notify_time";
  }
  if (
    data.notifyMeTime != "" &&
    data.notifyMeTime != null &&
    data.reminderTime != "" &&
    data.reminderTime != null &&
    data.reminderTime === data.notifyMeTime
  ) {
    error.notifyMeTime = "errors.notify_time_cant_be_same_as_reminder_time";
  }

  if (data.notifyMeTime != "" && data.reminderTime != "") {
    const reminderTime = moment(data.reminderDate).add(
      moment.duration(data.reminderTime)
    );
    const notifyMeTime = moment(data.reminderDate).add(
      moment.duration(data.reminderTime)
    );
    console.log(
      "here",
      moment(data.reminderTime, "h:mm:ss a"),
      moment(data.notifyMeTime, "h:mm:ss a"),
      moment(data.reminderTime, "h:mm:ss a").isBefore(
        moment(data.notifyMeTime, "h:mm:ss a")
      )
      // reminderTime,
      // notifyMeTime,
    );
    if (
      moment(data.reminderTime, "h:mm:ss a").isBefore(
        moment(data.notifyMeTime, "h:mm:ss a")
      )
    ) {
      error.notifyMeTime =
        "errors.notify_time_cant_be_greater_than_reminder_time";
    }
  }

  if (data.matters == undefined || data.matters?.length == 0) {
    error.matters = "errors.select_employee";
  }

  if (data.employees == undefined || data.employees?.length == 0) {
    error.employees = "errors.select_employee";
  }

  if (data.documents == undefined || data.documents?.length == 0) {
    error.documents = "errors.select_file";
  }
  if (data.description == undefined || data?.description.trim() == "") {
    error.description = "errors.enter_description";
  }
  return error;
};

const docOperationModal = (data, operationId, folderId = "", fileId = "") => {
  let error = {};
  if ([1, 2, 5, 6].includes(operationId)) {
    if (data.name == undefined || data?.name.trim() == "") {
      error.name = `${
        operationId === 6
          ? "errors.enter_file_name"
          : operationId === 1
          ? "errors.enter_label_name"
          : "errors.enter_folder_name"
      }`;
    }
  }
  if ([1, 2, 5, 6].includes(operationId)) {
    if (data?.name !== undefined && data?.name?.length > 25) {
      error.name = "errors.max_length_25";
    }
  }
  if ([3, 4].includes(operationId) && !folderId) {
    if (data.selectedFolder == undefined || data.selectedFolder == "") {
      error.selectedFolder = "errors.select_folder_name";
    }
  }
  if (operationId === 2 || (operationId === 3 && !folderId)) {
    if (data.selectedRow == undefined || data.selectedRow == "") {
      error.selectedRow = "errors.select_label_name";
    }
  }
  if (operationId === 3) {
    if (data?.documents == undefined || data?.documents?.length === 0) {
      error.documents = "errors.select_add_doc";
    }
    if (data?.keywords == undefined || data?.keywords?.length === 0) {
      error.keywords = "errors.enter_keyword";
    }
  }
  if (operationId === 4) {
    if (data.selectedDoc == undefined || data.selectedDoc == "") {
      error.selectedDoc = "errors.select_single_doccument";
    }
    if (data.department == undefined || data.department == "") {
      error.department = "errors.select_department";
    }
    if (data.empForApproval == undefined || data.empForApproval == "") {
      error.empForApproval = "errors.select_employee";
    }
  }
  if ([7, 8].includes(operationId)) {
    if (data.assignTo == undefined || data.assignTo == "") {
      error.assignTo = "errors.select_assign_to";
    }
  }

  return error;
};
export default {
  clientValidate,
  matterValidate,
  reminderValidate,
  docOperationModal,
};
