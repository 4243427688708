const coordinatorValidate = (data) => {
  let error = {};
  if (data.name == undefined || data?.name?.trim() == "") {
    error.name = "errors.enter_name";
  }
  if (data.email == undefined || data.email == "") {
    error.email = "errors.enter_email";
  }
  if (
    data?.email &&
    !/^[A-Z0-9\u0600-\u06FF._%+-]+@[A-Z0-9\u0600-\u06FF.-]+\.[A-Z]{2,4}$/i.test(
      data.email
    )
  ) {
    error.email = "errors.enter_valid_email";
  }
  if (data.password == undefined || data?.password?.trim() == "") {
    error.password = "errors.enter_password";
  }
  if (data.password && data.password.length < 8) {
    error.password = "errors.pass_min_length";
  }
  if (data.branch_id == undefined || data.branch_id == "") {
    error.branch_id = "errors.select_branch";
  }
  if (data.assignTo == undefined || data.assignTo == "") {
    error.assignTo = "errors.select_assign_employee";
  }
  if (data.jobId == undefined || data.jobId == "") {
    error.jobId = "errors.select_job_position";
  }
  if (data.responsibleHrId == undefined || data.responsibleHrId == "") {
    error.responsibleHrId = "errors.select_responsible_hr";
  }
  if (data.directManagerId == undefined || data.directManagerId == "") {
    error.directManagerId = "errors.select_direct_manager";
  }
  return error;
};

export default {
  coordinatorValidate,
};
