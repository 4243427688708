import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import http from "../../../api/axios";
import ComponentButton from "../../componentGroups/componentButton";
import LoadingContext from "../../../context/LoaderContext";
import ProjectItem from "./ProjectItem";

const AssignedProjects = ({ endPoint = "" }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [projects, setProjects] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [projectsToView, setProjectsToView] = useState([]);

  const getProjects = async () => {
    setLoader(true);
    const res = await http.getApi(t, endPoint);
    setLoader(false);
    if (!!res && res?.length > 0) {
      setProjects(res);
    } else {
      setProjects([]);
    }
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setProjectsToView(projects);
    } else {
      if (projects?.length > 3) {
        let sliced = [...projects]?.slice(0, 3);
        setProjectsToView(sliced);
      } else {
        setProjectsToView(projects);
      }
    }
  }, [showAll, projects]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography className="morningText" variant="h4">
              {t("titles.assigned_projects")}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {projects?.length > 3 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
          </Grid>
        </Grid>
        {projectsToView?.map((item) => {
          return <ProjectItem item={item} />;
        })}
        {projects?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">
              {t("labels.no_assigned_projects")}
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default AssignedProjects;
