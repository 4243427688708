import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import LoadingContext from "../../../context/LoaderContext";
import http from "../../../api/axios";

const LatestDecision = () => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [decisions, setDecisions] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [decisionToShow, setDecisionToshow] = useState([]);

  const getLatestDecisions = async () => {
    setLoader(true);
    const res = await http.getApi(t, `/api/meeting/meeting-decision/latest`);
    setLoader(false);
    if (!!res && res?.length > 0) {
      setDecisions(res);
    } else {
      setDecisions([]);
    }
  };

  useEffect(() => {
    getLatestDecisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setDecisionToshow(decisions);
    } else {
      if (decisions?.length > 5) {
        let sliced = [...decisions]?.slice(0, 5);
        setDecisionToshow(sliced);
      } else {
        setDecisionToshow(decisions);
      }
    }
  }, [showAll, decisions]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography className="morningText" variant="h4">
              {t("titles.latest_decisions")}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {decisions?.length > 5 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
          </Grid>
        </Grid>
        {decisions?.length !== 0 ? (
          decisionToShow?.map((op, index) => (
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid className="gridLeft">
                <Typography component="span" variant="body2">
                  {op?.title}
                </Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <span className="noDataFound">
            {" "}
            {t("labels.no_latest_decision_found")}
          </span>
        )}
      </div>
    </div>
  );
};

export default LatestDecision;
