const vactionValidaction = (data, description) => {
    let error = {};
    if (data.holiday_title == undefined || data.holiday_title == "") {
        error.holiday_title = "errors.holiday_title";
    }
    if (data.holiday_period == undefined || data.holiday_period == "") {
        error.holiday_period = "errors.holiday_period";
    }
    if (data.start_date == undefined || data.start_date == "") {
        error.start_date = "errors.start_date";
    }
    if (data.approval_from == undefined || data.approval_from.length == 0) {
        error.approval_from = "errors.approval_from";
    }
    if (data.end_date == undefined || data.end_date == "") {
        error.end_date = "errors.end_date";
    }
    if (data.notify_me == undefined || data.notify_me == "") {
        error.notify_me = "errors.notify_me";
    }


    return error;
};

export default {
    vactionValidaction,
};
