import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../../componentGroups/componectModel";
import ComponentTable from "../../../componentGroups/componentTable";
import { loadOptions } from "../../../../api/utils/common";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import validate from "../../../../Hooks/promotions_validaction";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import PromotionRequests from "./PromotionRequests";

export default function Promotions(props) {
  const { t, i18n } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [promactionTable, setPromactionTable] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const ASSIGN_VALUE = {
    select_employee: "",
    promoted_to: "",
    start_date: "",
    approval_from: [],
    new_salary: "",
  };
  const [errors, setErrors] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [promotionState, setPromotionState] = useState(ASSIGN_VALUE);
  const [employee, setEmployees] = useState([]);
  const [promotionRequestKey, setPromotionRequestKey] = useState(false);

  const column = [
    { lable: "", name: "checkbox", width: "10px", align: "center" },
    {
      lable: "labels.promoted_employee",
      name: "employee",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.promoted_from",
      name: "promated_from",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.promotion_to",
      name: "promated_to",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.promotion_date",
      name: "promotion_date",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.status",
      name: "status",
      width: "10px",
      align: "left",
    },
  ];
  const [data, setData] = useState({
    title: "sideBar.22.promotions",
    page: "Branches",
    hideSearch: true,
    infoURL: "/promotions/promotion-detail",
    hasVariable: true,
    handlExport: () => handleExport(),
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("promotion", 153)) {
            handleModel();
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });
  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_promotions",
    data: [
      {
        lable: "labels.employee",
        name: "select_employee",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
      {
        lable: "labels.promoted_to",
        name: "promoted_to",
        field: {
          type: "dropdown",
          required: true,
          findLable: "title",
          option: [],
        },
      },

      {
        lable: "labels.start_from",
        field: {
          type: "date",
          required: true,
        },
        name: "start_date",
      },

      {
        lable: "labels.new_salary",
        field: {
          type: "text",
          required: true,
        },
        name: "new_salary",
      },
      {
        lable: "labels.request_approval_from",
        field: {
          type: "dropdown",
          findLable: "name",
          required: true,
          option: [],
        },
        name: "approval_from",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setErrors({});
          setPromotionState(ASSIGN_VALUE);
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addPromotions(value);
        },
      },
    ],
  });
  const handleModel = (val) => {
    setModelData({
      ...modalData,
      model: true,
    });
  };

  const handleExport = async () => {
    props.setLoader(true);
    const res = await http.getExportApi(t, `/api/promotions/export-promotions`);
    props.setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    let res = await http.getAllDataApi(
      t,
      `/api/promotions/details/?page=${pageIndex + 1}&size=${pageSize}`
    );
    const data = res?.data?.map((item) => {
      return {
        ...item,
        status:
          parseInt(item?.status) === 0
            ? "Pending Approval"
            : parseInt(item?.status) === 1
            ? "Approved"
            : parseInt(item?.status) === 2
            ? "Promoted"
            : "Rejected",
      };
    });
    props.setLoader(false);
    setTotalrecords(res?.total_count);
    setPromactionTable(data);
  };

  const getAllData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let s1 = { ...modalData };
    let employes = await loadOptions(t, 1, undefined, "post", "/api/employee");

    let executiveDirectors = await loadOptions(
      t,
      1,
      undefined,
      "get",
      "/api/employee/all/department/1"
    );
    employes = employes?.filter((item) => item?.id !== user?.id);
    setEmployees(employes);
    s1.data[0].field.option = employes;
    s1.data[4].field.option = executiveDirectors;
    setModelData(s1);
  };

  const filterDepartment = async (id) => {
    const selectedEmp = await employee.filter((emp) => emp.id === id)[0];
    console.log("here", selectedEmp);
    if (selectedEmp?.id) {
      let s1 = { ...modalData };
      let jobTitles = await loadOptions(
        t,
        "",
        null,
        "get",
        `/api/setting/department/job-position/${selectedEmp.departmentId}`
      );
      const empJobpositons = jobTitles?.filter(
        (item) => item?.id !== selectedEmp?.jobId
      );
      s1.data[1].field.option = empJobpositons;
      setModelData(s1);
    }
  };

  useEffect(() => {
    if (promotionState?.select_employee) {
      filterDepartment(promotionState?.select_employee);
      setPromotionState((_promotionState) => {
        return { ..._promotionState, promoted_to: "" };
      });
    }
  }, [promotionState?.select_employee]);

  const addPromotions = async (obj) => {
    console.log(obj, "er");
    let error = validate.promotionValidaction(obj);
    console.log(error, "er");
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      var formdata = new FormData();
      formdata.append("employee", obj?.select_employee);
      formdata.append("promotedTo", obj?.promoted_to);
      formdata.append("startDate", obj?.start_date);
      formdata.append("newSalary", obj?.new_salary);
      formdata.append("departmentId", "8");
      formdata.append(`approvalFrom[0]`, obj.approval_from);
      formdata.append("langId", i18n.language === "en" ? `1` : `2`);

      const res = await http.postApi(t, "/api/promotions/create", formdata);
      if (res) {
        setPromotionState({});
        getApiData();
        handleCloseModel();
      }
      props.setLoader(false);
      setPromotionRequestKey(!promotionRequestKey);
    } else {
      setErrors(error);
    }
  };

  useEffect(() => {
    getApiData(page, size);
    getAllData();
  }, [page, size]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.promotionValidaction(promotionState);
      setErrors(error);
    }
  }, [promotionState]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("promotion", 154)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`promotion[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/promotions/delete/multiple",
          formData,
          "labels.promotion_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <>
      <PromotionRequests key={promotionRequestKey} />
      <div className="CommonPd latest_des_box">
        <ComponentModal
          errors={errors}
          modalData={modalData}
          setPerantState={setPromotionState}
          perantState={promotionState}
        />
        <ComponentTable
          rows={promactionTable}
          columns={column}
          page={page}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          pageData={data}
          totalRecords={totalRecords}
          selected={selectedData}
          handleSelected={handleSelected}
          handleAllselect={handleAllselect}
          handleDelete={handleDelete}
          defaultstatus={false}
        />
      </div>
    </>
  );
}
