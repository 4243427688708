import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper, Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import http from "../../../../api/axios";
import ProjectDetails from "./projectDetails";
import ProjectTasks from "./projectTasks";
import ProjectDoc from "./documents";
import { INITIAL_PAGESIZE, INITIAL_PAGEINDEX } from "../../../../constant";
import auth from "../../../../services/auth";
import { loadOptions } from "../../../../api/utils/common";
import validate from "../../../../Hooks/project_validaction";
import { useTranslation } from "react-i18next";
import ComponentAccordion from "../../../componentGroups/componentAccordion";
import ComponentLogNote from "../../../componentGroups/logNotes/componentLogNote";
import Comments from "../../../componentGroups/Comments";
import { PROJECT_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/project_key_lable";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProjectInfo(props) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const Id = projectId;
  const [projectState, setProjectState] = React.useState({
    projectName: "",
    caseId: "",
    createdById: "",
    createdAt: "",
    assign_to_users: [],
    sharedWith: [],
    description: "",
    documents: [],
  });
  const [description, setDescription] = React.useState("");
  const [options, setOptions] = useState({ employess: [], caseOption: [] });
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [value, setValue] = React.useState(0);
  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);

  /**
   *change the tabs
   */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * fetch Project  data and intialize intial Project data
   */

  const getApiData = async () => {
    props.setLoader(true);
    //TODO: api needs to be updated
    let loadOp = await loadOptions(t, 1, 10);
    let empRes = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let res = await http.getApi(t, `/api/project/${Id}`);
    console.log(res, "loplop");
    props.setLoader(false);
    const projectData = {
      ...projectState,
      id: res?.id ?? "",
      projectName: res?.projectName ?? "",
      caseId: res?.caseId ?? "",
      createdById: res?.createdById ?? "",
      createdAt: res?.createdAt ?? "",
      assign_to_users: res?.assign_to_users ?? [],
      sharedWith: res?.sharedWith ?? [],
      documents: res?.documents ?? [],
    };
    setDescription(res?.description ?? "");
    setProjectState(projectData);
    setOptions({
      ...options,
      employess: empRes,
      caseOption: loadOp,
    });
  };

  /**
   * validate the project fields onchange time
   */

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.projectEditValidaction(projectState, description);
      setErrors(error);
    }
  }, [projectState]);

  /**
   * update the project
   */

  const updateDoc = async () => {
    setFlag(false);
    let error = validate.projectEditValidaction(projectState, description);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      var formdata = new FormData();
      formdata.append("projectName", projectState?.projectName);
      formdata.append("caseNo", projectState?.caseId);
      formdata.append("createdBy", projectState?.createdById);
      formdata.append("createdAt", projectState?.createdAt);
      projectState?.assign_to_users?.map((op, index) => {
        formdata.append(`assignedTo[${index}]`, op?.id);
      });
      projectState?.sharedWith?.map((op, index) => {
        formdata.append(`sharedWith[${index}]`, op?.id);
      });
      formdata.append("description", description);
      formdata.append("langId", "1");
      projectState?.documents?.map((op, index) => {
        if (op?.id) {
        } else {
          formdata.append(`documents[${index}]`, op);
        }
      });
      let res = await http.putApi(t, `/api/project/update/${Id}`, formdata);
      props.setLoader(false);
      getApiData();
      setFlag(true);
    } else {
      setErrors(error);
    }
  };

  /**
   * Remove the documents
   */

  const removeDoc = async (index) => {
    let s1 = { ...projectState };
    if (projectState?.documents[index]?.id) {
      let res = await http.deleteApi(
        t,
        `/api/project/delete/${projectState?.documents[index]?.id}`
      );
      s1.documents.splice(index, 1);
    } else {
      s1.documents.splice(index, 1);
    }
    setProjectState(s1);
  };

  React.useEffect(() => {
    getApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={9}>
            <Typography variant="h6" className="morningText">
              {projectState?.projectName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              sx={{ marginLeft: "50px" }}
              className="buttonFill"
              onClick={() => updateDoc()}
            >
              {t("labels.save")}
            </Button>
          </Grid>
        </Grid>
        <Box
          className="TaskTab TabActive"
          sx={{
            borderBottom: 1,
            borderColor: "rgba(183, 161, 96, 0.26)",
            pt: 6,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="iconText"
              label={t("labels.project_details")}
              {...a11yProps(1)}
            />
            <Tab
              className="iconText"
              label={t("labels.documents")}
              {...a11yProps(0)}
            />

            <Tab
              className="iconText"
              label={t("labels.tasks")}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ProjectDetails
            errors={errors}
            projectState={projectState}
            setProjectState={setProjectState}
            options={options}
            description={description}
            setDescription={setDescription}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProjectDoc
            errors={errors}
            removeDoc={removeDoc}
            projectState={projectState}
            setProjectState={setProjectState}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProjectTasks
            setLoader={props.setLoader}
            page={page}
            setPage={setPage}
            rowsPerPage={size}
            setRowsPerPage={setSize}
            totalRecords={totalRecords}
          />
        </TabPanel>
        <Comments setLoader={props.setLoader} viewId={`PRJT${Id}`} />
        {projectState?.id && (
          <LogNoteAccordion
            id={`PRJT${projectState?.id}`}
            labelKeys={PROJECT_KEY_LABELS}
            flag={flag}
          />
        )}
      </Grid>
    </div>
  );
}
