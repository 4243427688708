import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import * as YUP from "yup";
import ComponentButton from "../../componentGroups/componentButton";
import { postApi } from "../../../api/axios";

import FormInput from "../ProfileModule/ProfileTechnicalDepartment/FormInput";

import FileView from "../PartnerScreens/Documents/FileView";
import ComponentUpload from "../../componentGroups/componentUpload";

const CandidateForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [cvError, setCvError] = useState('')
  // vacancy state
  const vacancy = JSON.parse(localStorage.getItem("vacancy"));

  const formik = useFormik({
    initialValues: {
      vacancyId: vacancy?.id,
      name: "",
      age: "",
      college: "",
      university: '',
      degree: '',
      grade: '',
      cityOfResidence: '',
      expectedSalary: '',
      expirence: '',
      cv: '',
      coverLetter: '',
    },
    // validateOnMount: true,
    enableReinitialize: true,

    validationSchema: YUP.object({
      name: YUP.string()
        .required("name is required.")
        .min(2, "must be at least 2 characters"),
      age: YUP.number()
        .required("age is required.").max(60, 'this age is not allowed').min(20, 'this age is not allowed')
      ,
      college: YUP.string().required("college is required."),
      university: YUP.string().required("university is required."),
      cityOfResidence: YUP.string().required("city is required."),
      expectedSalary: YUP.number().required("expected salary is required."),
      degree: YUP.string().required("degree is required."),
      grade: YUP.number().required("grade is required.").max(5, 'max grade is 5').min(1, 'min grade is 1'),
      expirence: YUP.number().required("expirence is required. (in years)"),
    }),
    onSubmit: async (values) => {

      // add request
      if (formik.values.cv) {

        let fd = new FormData();
        fd.append("vacancyId", values.vacancyId);
        fd.append("name", values.name);
        fd.append("age", values.age);
        fd.append("college", values.college);
        fd.append("university", values.university);
        fd.append("degree", values.degree);
        fd.append("grade", values.grade);
        fd.append("cityOfResidence", values.cityOfResidence);
        fd.append("expectedSalary", values.expectedSalary);
        fd.append("expirence", values.expirence);
        fd.append("cv", values.cv);
        fd.append("coverLetter", values.coverLetter);

        const res = await postApi(t, `/api/vacancies/candidates`, fd);
        if (res.status === 200) { history.push(`/recruitments/recruitment-info`); }
      } else {
        setCvError('CV is required.')
      }

    },
  });


  console.log(formik.values.cv);
  return (
    <div className="SuggestDetailPg">
      <div class="CommonPd">
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.add_candidate")}

              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            {/* branch */}
            <FormInput
              value={formik.values?.name}
              name="name"
              label={t("labels.name")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : null
              }
            />


            {/* age */}

            <FormInput
              value={formik.values?.age}
              name="age"
              label={t("labels.age")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              type='number'
              error={
                formik.errors.age && formik.touched.age
                  ? formik.errors.age
                  : null
              }
            />

            {/* cityOfResidence */}
            <FormInput
              value={formik.values?.cityOfResidence}
              name="cityOfResidence"

              label={t("labels.city")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.cityOfResidence && formik.touched.cityOfResidence
                  ? formik.errors.cityOfResidence
                  : null
              }
            />

            {/* university */}
            <FormInput
              value={formik.values?.university}
              name="university"

              label={t("labels.university")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.university && formik.touched.university
                  ? formik.errors.university
                  : null
              }
            />

            {/* college */}
            <FormInput
              value={formik.values?.college}
              name="college"

              label={t("labels.college")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.college && formik.touched.college
                  ? formik.errors.college
                  : null
              }
            />

            {/* degree */}
            <FormInput
              value={formik.values?.degree}
              name="degree"

              label={t("labels.degree")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.degree && formik.touched.degree
                  ? formik.errors.degree
                  : null
              }
            />

            {/* grade */}
            <FormInput
              value={formik.values?.grade}
              name="grade"
              label={t("labels.grade")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              type='number'
              error={
                formik.errors.grade && formik.touched.grade
                  ? formik.errors.grade
                  : null
              }
            />

            {/* expirence */}
            <FormInput
              value={formik.values?.expirence}
              name="expirence"
              type='number'
              label={t("labels.experience")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.expirence && formik.touched.expirence
                  ? formik.errors.expirence
                  : null
              }
            />

            {/* expectedSalary */}
            <FormInput
              value={formik.values?.expectedSalary}
              name="expectedSalary"
              type='number'
              label={t("labels.expected_salary")}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.expectedSalary && formik.touched.expectedSalary
                  ? formik.errors.expectedSalary
                  : null
              }
            />

            <div className="FormsuggestForm">
              <label for="cv">{t("labels.cv")}</label>
              <div className="">

                <ComponentUpload
                  lable={t("labels.upload_files")}
                  name="cv"
                  accept='application/pdf'
                  required={true}
                  callback={(e) => {
                    formik.setFieldValue('cv', e.target.files[0])
                    // s1.documents.push(e.target.files[0])
                  }}
                />
                {cvError ?
                  <p style={{
                    color: "#ff0101",
                    fontSize: "13px",
                  }}>*{cvError}</p>
                  : ''}
              </div>
            </div>

            <div className="FormsuggestForm">
              <label for="cover">
                {t("labels.cover_letter")}
              </label>
              <div className="">

                <ComponentUpload
                  lable={t("labels.upload_files")}
                  name="cover"
                  accept='application/pdf'
                  callback={(e) => {
                    formik.setFieldValue('coverLetter', e.target.files[0])
                    // s1.documents.push(e.target.files[0])
                  }}
                />
              </div>
            </div>


          </form>
          <div className="RefresugstBtn" style={{
            gap: '1rem',
            display: "flex"
          }}>

            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.go_back")}
              variant={"outlined"}
              class={"buttonBlank delbtnRefrence"}
              callback={() => history.push(`/recruitments/recruitment-info`)}
            />
            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.add_candidate")}
              variant={"contained"}
              class={"buttonFill"}
              callback={formik.submitForm}
            />

          </div>

        </div>
      </div>
    </div >
  )
}

export default CandidateForm