
import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ComponentButton from "../../componentGroups/componentButton";
import { getApi, patchApi } from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import FormInput from "../ProfileModule/ProfileTechnicalDepartment/FormInput";

import ComponentTable from "../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";

const VacancyInfo = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);

  const [vacancyState, setVacancyState] = useState();
  useEffect(() => {
    if (localStorage.getItem("vacancy"))
      setVacancyState(JSON.parse(localStorage.getItem("vacancy")));
  }, []);

  const closeRecruitment = async () => {
    const res = await patchApi(t, `/api/vacancies/${vacancyState?.id}`)
    if (res.status === 200) { history.push(`/recruitments`); }
  }


  // for candidates data
  const [column] = useState([
    // {
    //   lable: "",
    //   name: "checkbox",
    //   width: "10px",
    //   align: "center",
    //   minWidth: 10,
    // },
    {
      lable: "labels.name",
      name: "name",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.age",
      name: "age",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.college",
      name: "college",
      width: "10px",
      align: "left",
      minWidth: 80,
    },
    {
      lable: "labels.experience",
      name: "expirence",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.attached_cV",
      name: 'fileName',
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.expected_salary",
      name: "expectedSalary",
      width: "10px",
      align: "left",
      minWidth: 110,
    },

  ]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [candidates, setCandidates] = useState();
  const getCandidatesData = async (vacancyId) => {
    setLoader(true)
    const candidatesRes = await getApi(t, `/api/vacancies/candidates/${vacancyId}`)
    setCandidates(candidatesRes);
    setTotalrecords(candidatesRes?.length);
    setLoader(false)
  }
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    title: "labels.candidates_details",
    page: "Candidates",
    infoURL: "/recruitments/candidate-info",
    hideSearch: true,
    // hasVariable: true,
    setRowToLocal: "candidate"
  })
  // to get candidates data
  useEffect(() => {
    if (vacancyState?.id) getCandidatesData(vacancyState?.id);
  }, [vacancyState?.id]);

  // console.log(vacancyState?.candidatesNumber);
  // console.log(candidates?.length);


  return (
    <div className="SuggestDetailPg">
      <div class="CommonPd">
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.recruitment")}

              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            {/* branch */}
            {vacancyState?.branch ? <FormInput
              value={vacancyState?.branch?.branchName}
              name="branch"
              label={t("labels.branch")}
              required={true}
              disabled={true}
            /> : ''}


            {/* department */}

            {vacancyState?.department ? <FormInput
              value={vacancyState?.department?.departmentName}
              name="department"

              label={t("labels.department")}
              required={true}
              disabled={true}
            /> : ''}



            {/* jobTitle */}
            <FormInput
              name="jobTitle"
              label={t("labels.job_title")}
              value={vacancyState?.jobTitle}

              required={true}
              disabled={vacancyState?.jobTitle ? true : false}
            />

            {/* No. Candidate */}
            <FormInput
              name="candidatesNumber"
              label={t("labels.candidate_number")}
              value={vacancyState?.candidatesNumber}

              type='number'

              required={true}
              disabled={vacancyState?.candidatesNumber ? true : false}
            />

          </form>
          <div className="RefresugstBtn" style={{
            gap: '1rem',
            display: "flex"
          }}>


            {(vacancyState && vacancyState?.status === 1) ? (
              <>
                {vacancyState?.candidatesNumber > candidates?.length ? <ComponentButton
                  sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                  value={t("labels.add_candidate")}
                  variant={"contained"}
                  class={"buttonFill"}
                  callback={() => history.push(`/recruitments/create-candidate`)}
                /> : ''}
                <ComponentButton
                  sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                  value={t("labels.close_recruitment")}
                  variant={"contained"}
                  class={"buttonFill"}
                  callback={closeRecruitment}
                />
              </>
            ) : ''}

          </div>
          {vacancyState ?

            <ComponentTable
              rows={candidates}
              columns={column}
              page={page}
              setSearch={setSearch}
              search={search}
              // hideSearch={true}
              setPage={setPage}
              rowsPerPage={size}
              setRowsPerPage={setSize}
              pageData={data}
              totalRecords={totalRecords}
            />

            : ''}
        </div>
      </div>
    </div>
  )
}

export default VacancyInfo