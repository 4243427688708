import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import http, { baseURL } from "../../../../api/axios";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import hooks from "../../../../Hooks/hooks";
import { loadOptions } from "../../../../api/utils/common";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import auth from "../../../../services/auth";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Archived = (props) => {

  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t } = useTranslation();
  const user = auth.getUser("user");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [setTask, setSendTask] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);

  const [column, setColumn] = useState([
    // {
    //   lable: "",
    //   name: "checkbox",
    //   width: "10px",
    //   align: "center",
    //   minWidth: 10,
    // },
    {
      lable: "labels.request_title",
      name: "title",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    // {
    //   lable: "labels.request_number",
    //   name: "code",
    //   width: "10px",
    //   align: "left",
    //   minWidth: 110,
    // },
    {
      lable: "labels.matter_name",
      name: "matter",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.assigned_to",
      name: "sendTo",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 130,
    },

    {
      lable: "labels.attachments",
      name: "attachment",
      width: "10px",
      align: "left",
      minWidth: 50,
    },
    {
      lable: "labels.request_status",
      name: "status",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.priority",
      name: "periority",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
    // { lable: "", name: "info", width: "10px", align: "left" },
  ]);

  const [data, setData] = useState({
    title: "labels.sent_requests",
    page: "Consultations",
    // infoURL: "/ask-consultation/consultationInfo",
    // setRowToLocal: "consultation",
    handlExport: (page, size) =>
      props.handleExport(page, size, 1),
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => { },
      },
      //   {
      //     type: "filter",
      //     title: "labels.filter_by",
      //     icon: <FilterListIcon />,
      //     variant: "outlined",
      //     class: "buttonBlank",
      //     callback: () => { },
      //   },
    ],
  });


  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    let formdata = new FormData();
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate
        ? moment(filterData?.dueDate).format("YYYY-MM-DD")
        : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/consultation/all-archived`,
      formdata,
      {
        page: pageIndex + 1,
        size: pageSize,
        search,
        // access: 1
      }
    );
    // console.log(res);
    setTotalrecords(res.data_count);
    setSendTask(res.data);
    setFilterData({ ...filterData, filter: false });

    props.setLoader(false);
    // TODO: if code is independent need to be added to another function
    // props.setLoader(false);
  };

  useEffect(() => {
    // setColumnData();
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);


  return (
    <ComponentTable
      rows={setTask}
      columns={column}
      pageData={data}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      setSearch={setSearch}
      search={search}
      selected={selectedData}
      enableReset={true}
      resetdata={{
        status: "",
      }}
    />
  );
}

export default Archived