import React, { useState, useEffect, useContext } from "react";
import "../style.css";
import { useHistory } from "react-router-dom";
import calender from "../../../../assets/images/calender.png";
import { Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import http from "../../../../api/axios";
import moment from "moment";
import LoadingContext from "../../../../context/LoaderContext";
import useFormatTime from "../../../../Hooks/useFormatTime";

const UpComingAppointments = ({ modulePath = "" }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { globalToLocal } = useFormatTime();
  const { setLoader } = useContext(LoadingContext);
  const [upcomingMeeting, setUpcomingMeeting] = useState([]);

  const getPastDecisions = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/meeting/upcoming/list");
    setLoader(false);
    if (!!res) {
      setUpcomingMeeting(res);
    }
  };

  useEffect(() => {
    getPastDecisions();
  }, []);

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Typography className="DashTitle" variant="h4">
              {t("partner.appointments")}
            </Typography>
          </Grid>
        </Grid>
        <Grid className="UpcomeAppointmnt" container spacing={2}>
          {upcomingMeeting?.map((item) => (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card>
                <CardContent>
                  <div className="calenderBox">
                    <div
                      className="flex-align-start"
                      style={{ flex: "0 0 80%", wordBreak: "break-all" }}
                    >
                      <img src={calender} alt="cal" />
                      <div
                        className="cardContant"
                        style={{
                          height: "150px",
                          overflowY: "auto",
                        }}
                      >
                        <Typography variant="h6" className="folderSection1">
                          {" "}
                          {item?.title}{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="textFont "
                        >
                          {item?.description}
                        </Typography>
                      </div>
                    </div>
                    <span
                      style={{ minWidth: "fit-content", flex: "0 0 20%" }}
                      className="ml-10"
                    >
                      {globalToLocal(item?.start_time, "h:mm a")}
                    </span>
                  </div>
                  <div className="btnContainer folderSection">
                    <ComponentButton
                      value={t("labels.view_details")}
                      variant={"contained"}
                      class={"buttonFill"}
                      callback={() => {
                        history.push({
                          pathname: modulePath
                            ? `/${modulePath}/meeting-detail`
                            : "/meetingDetails",
                          state: { id: item?.id },
                        });
                      }}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {upcomingMeeting?.length === 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <div className="noDataFound">
                {t("labels.no_upcoming_meetings")}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UpComingAppointments;
