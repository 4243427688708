import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Grid } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./style.css";
import { useTranslation } from "react-i18next";
import http from "../../../api/axios";
import { useHistory } from "react-router-dom";
import ComponentFilterModal from "../../componentGroups/componectFilterModel";
import LoadingContext from "../../../context/LoaderContext";

const RequestAssign = () => {
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    filter: false,
    status: "",
    subject: "",
    sendTo: "",
  });
  const [modalData, setModelData] = useState({ model: false });

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 20,
    },
    {
      lable: "labels.send_to",
      name: "sendTo",
      type: "dropDown",
      options: [],
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.request_subject",
      name: "subject",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.status",
      name: "status",
      type: "dropDown",
      options: [
        {
          id: 1,
          name: "Pending",
        },
        {
          id: 2,
          name: "Done",
        },
        {
          id: 3,
          name: "All",
        },
      ],
      width: "10px",
      align: "left",
      minWidth: 20,
    },
  ]);

  const getAllRequests = async () => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[sendTo]", filterData?.sendTo ?? "");
    formdata.append("filter[subject]", filterData?.subject ?? "");
    formdata.append(
      "filter[status]",
      filterData?.status !== 3 ? filterData?.status : ""
    );
    const res = await http.getAllDataApi(
      t,
      "/api/requestandassign/all",
      formdata
    );
    setLoader(false);
    if (!!res?.data) {
      setData(res?.data);
    }
    setFilterData({ ...filterData, filter: false });
  };

  useEffect(() => {
    getAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData?.filter == true]);

  const getEmployess = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/employee/dropdown/all");
    setLoader(false);
    if (!!res && res?.length > 0) {
      const col = [...column];
      col[1].options = res;
      setColumn(col);
    }
  };

  useEffect(() => {
    getEmployess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ReqstAsignPage dashTopGrid">
      <div className="CommonPd latest_des_box">
        <div className="mainCon">
          <div className="mainConinner" style={{ alignItems: "center" }}>
            <div>
              <div className="reqbtnContainer">
                <div className="reqHeader">
                  <Typography variant="h4" className="morningText">
                    {t("partner.requests_assign")}
                  </Typography>
                  <ComponentButton
                    value={t("labels.create_request")}
                    variant={"contained"}
                    class={"buttonFill request MobileCreate Mright reqaddbtn"}
                    icon={<AddIcon sx={{ color: "white" }} />}
                    callback={() => {
                      history.push("/requestAssign/addrequest");
                    }}
                  />
                </div>
                <ComponentButton
                  icon={<FilterListIcon />}
                  value={t("labels.filter")}
                  variant={"outlined"}
                  class={"buttonBlank MobileFilter"}
                  callback={(e) => {
                    setModelData({ model: true });
                  }}
                />
              </div>

              <ComponentFilterModal
                className=""
                title={"Filter"}
                filterData={filterData}
                setFilterData={setFilterData}
                modalData={modalData}
                setModelData={setModelData}
                columns={column}
                enableReset={true}
                resetdata={{ status: "", subject: "", sendTo: "" }}
              />
            </div>
          </div>
        </div>
        <div>
          {data?.map((item, index) => (
            <Box sx={{ mt: 2, p: 2 }} className="box box1" key={item?.id}>
              <div className="perviewBox">
                <div className="detailsBox">
                  <div>
                    <Typography
                      variant="h5"
                      className="detailboxHead Yellowtext"
                    >
                      {item?.subject}{" "}
                      <span className="to"> {t("lorem.dummy2")}</span>
                      {item?.name}
                    </Typography>
                    <Typography variant="body2" className="details">
                      {item?.description}
                    </Typography>
                  </div>
                </div>
                <div >
                  <ComponentButton
                    value={t("labels.view_document")}
                    variant={"contained"}
                    class={"viewDoc"}
                    callback={() => {
                      history.push(`/preview/requestAssigined/document/${item?.request_assigin_id}`);

                    }}
                  />
                  <ComponentButton
                    value={t(
                      item?.status === 1 ? "labels.pending" : "labels.done"
                    )}
                    variant={"contained"}
                    class={item?.status === 1 ? "pending" : "done"}
                  />
                </div>
              </div>

            </Box>
          ))}
          {data?.length === 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <div className="noDataFound">
                {t("labels.no_avilable_requests")}
              </div>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestAssign;
