import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import ComponentButton from "../componentButton";
import { Avatar } from "@mui/material";
import http from "../../../api/axios";
import ComponentTextString from "../componentTextString";
import LoadingContext from "../../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import LogReply from "./logReply";
import "./logNote.css";
import TextInputWithMention from "./AddReply";
import processUpdates from "./valueBind";

const LogDetail = ({ item, labelKeys }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { setLoader } = useContext(LoadingContext);
  const [errors, setErrors] = useState("");
  const [reply, setReply] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [commentPushed, setCommentPushed] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const fetchData = async () => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/logs/find-replies/${item.id}?page=${page}&size=5`
    );
    const newData = [...data, ...res?.data];
    setData(newData);
    setPage((prevPage) => prevPage + 1);
    setHasMore(res?.data_count > newData?.length);
    setLoader(false);
  };

  const getEmployees = async () => {
    let res = await http.getApi(t, "/api/employee/dropdown/all");
    if (res?.length > 0) {
      setEmpList(res);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (data?.length === 0 && page === 1) {
      fetchData();
    }
  }, [data, page]);

  useEffect(() => {
    setErrors("");
  }, [reply]);

  const addReply = async () => {
    if (reply === undefined || reply === "") {
      setErrors(t("errors.error_reply"));
    } else {
      setLoader(true);
      const req = {
        text: reply,
        mentionedUsers: mentionedUsers,
      };
      let res = await http.postApi(t, `/api/logs/reply/${item.id}`, req);
      if (res) {
        setErrors("");
        setReply("");
        setPage(1);
        setData([]);
        setHasMore(true);
        setMentionedUsers([]);
      }
      setLoader(false);
      setOpen(false);
    }
  };

  return (
    <div className="logDetails">
      <div className="noteFlex1 flex-wrap">
        <div className="noteFlex1 noteAvatar">
          <Avatar
            key={""}
            alt="Shubham"
            src={item?.imagePath}
            className="avatar-lg"
          />
          <div className="logNoteList">
            <h3>
              {`${t("titles.updated_by")} ${item?.createdBy} ${t("titles.on")}
                 ${moment(item?.createdAt).format("LLL")}
                `}
            </h3>
            <div>
              {typeof item.updatedData === "string" && (
                <p>{item.updatedData}</p>
              )}
              {labelKeys &&
                typeof item.updatedData !== "string" &&
                Object.entries(item?.updatedData)
                  .filter(([key, value]) => key !== "updatedAt")
                  .map(([key, value]) => (
                    <p key={key}>
                      {`${t(labelKeys[key])} ${t("titles.changed_from")}`}
                      <span className="changeValue">
                        {typeof value.previousValue === "string" ||
                        typeof value.previousValue === "number"
                          ? value.previousValue
                          : typeof value.previousValue === "object"
                          ? processUpdates(value.previousValue)
                          : ""}
                      </span>
                      {`${t("titles.to")}`}
                      <span className="changeValue">
                        {typeof value.updatedValue === "string" ||
                        typeof value.updatedValue === "number"
                          ? value.updatedValue
                          : typeof value.updatedValue === "object"
                          ? processUpdates(value.updatedValue)
                          : ""}
                      </span>
                    </p>
                  ))}
            </div>
          </div>
        </div>
        <div>
          {/* <ComponentButton
            value={t("labels.like")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
          /> */}
          <ComponentButton
            value={open ? t("labels.discard") : t("labels.reply")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
            callback={() => {
              setOpen(!open);
              setReply("");
              setErrors("");
            }}
          />
        </div>
      </div>
      {open && (
        <div className="replySection">
          <div className="replyInput">
            <TextInputWithMention
              mentions={empList}
              inputText={reply}
              setInputText={setReply}
              setMentionedUsers={setMentionedUsers}
              commentPushed={commentPushed}
              setCommentPushed={setCommentPushed}
            />
            {errors && <span className="validate">{errors}</span>}
          </div>
          <ComponentButton
            value="Reply"
            variant={"outlined"}
            class={"buttonFill"}
            callback={addReply}
          />
        </div>
      )}
      <div className="replyContainer">
        <h3>{t("titles.replies")}</h3>
        <LogReply data={data} hasMore={hasMore} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default LogDetail;
