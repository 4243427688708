const projectValidaction = (data) => {
  let error = {};
  if (data.projectName == undefined || data?.projectName?.trim() == "") {
    error.projectName = "errors.select_branch";
  }
  if (data.caseNo == undefined || data.caseNo == "") {
    error.caseNo = "errors.select_caseNo";
  }
  if (data.createdBy == undefined || data.createdBy == "") {
    error.createdBy = "errors.select_createdBy";
  }
  if (data.sharedWith == undefined || data.sharedWith == "") {
    error.sharedWith = "errors.select_sharedwith";
  }
  if (data.description == undefined || data.description == "") {
    error.description = "errors.enter_description";
  }
  if (data.assignedTo == undefined || data.assignedTo.length == 0) {
    error.assignedTo = "errors.select_assignTo";
  }

  return error;
};

const projectEditValidaction = (data, description) => {
  let error = {};
  if (data.projectName == undefined || data.projectName?.trim() == "") {
    error.projectName = "errors.select_branch";
  }
  if (data.caseId == undefined || data.caseId == "") {
    error.caseId = " *Please select caseNo";
  }
  if (data.createdById == undefined || data.createdById == "") {
    error.createdById = "errors.select_createdBy";
  }
  if (data.sharedWith == undefined || data.sharedWith == "") {
    error.sharedWith = "errors.select_sharedwith";
  }
  if (description == undefined || description == "") {
    error.description = "errors.enter_description";
  }
  if (data.assign_to_users == undefined || data.assign_to_users.length == 0) {
    error.assign_to_users = "errors.select_assignTo";
  }
  if (data.createdAt == undefined || data.createdAt == "") {
    error.createdAt = "select_createdAt";
  }

  return error;
};

export default {
  projectValidaction,
  projectEditValidaction,
};
