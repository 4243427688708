import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ComponentModal from "../../../../../componentGroups/componectModel";
import { loadOptions } from "../../../../../../api/utils/common";
import http from "../../../../../../api/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Action = ({ documentSign, status, perarntGetApiData, Id, user }) => {
  const [menuToggle, SetToggle] = useState(false);
  const { t, i18n } = useTranslation();

  const [actionState, setActionState] = useState({});
  const [modalData, setModelData] = useState({
    model: false,
    title: "labels.submit_option",
    data: [
      {
        lable: "labels.submit_dept",
        field: {
          type: "dropdown",
          findLable: "departmentName",
          required: true,
        },
        name: "department",
      },
      {
        lable: "labels.submint_emp",
        field: { type: "dropdown", findLable: "name", required: true },
        name: "employee",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.send",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          submitDocument(value);
        },
      },
    ],
  });

  /////////////
  ///** Get employess using department */
  /////////////////
  const getAllDeptEmployees = async (depId) => {
    let s1 = { ...modalData };
    // setLoader(true)
    let res2 = await loadOptions(
      t,
      1,
      undefined,
      "get",
      `/api/employee/all/department/${depId}`
    );
    // setLoader(false)
    if (res2?.length > 0) {
      s1.data[1].field.option = res2;
      setModelData(s1);
    }
  };

  useEffect(() => {
    getAllDeptEmployees(actionState?.department);
  }, [actionState?.department]);

  /////////////
  ///** Get department */
  /////////////////
  const getApiData = async () => {
    let s1 = { ...modalData };
    // setLoader(true)
    let res2 = await loadOptions(t, 1, undefined, "get", "/api/department");
    if (documentSign?.length > -1) {
      let user = documentSign?.find((op) => op.user_type == "verifier");
      if (user) {
        s1.data[0].field.disable = true;
        s1.data[1].field.disable = true;
        setActionState({
          department: user?.departmentId,
          employee: user?.userId,
        });
      }
      s1.data[0].field.option = res2;
      setModelData(s1);
    }
    // setLoader(false)
  };
  useEffect(() => {
    getApiData();
  }, [documentSign]);
  /////////////
  ///** Submit Form Document */
  /////////////////

  const submitDocument = async (value) => {
    const formdata = new FormData();
    formdata.append("userId", value?.employee);
    formdata.append("fieldId", Id);
    // setLoader(true)
    let res = await http.postApi(
      t,
      `/api/form_doc/form/submitDocument`,
      formdata,
      "labels.document_submitted_successfully"
    );
    perarntGetApiData();
    handleCloseModel();
    // setLoader(false)
  };

  /////////////
  ///** Repeat  Document */
  /////////////////

  const repeatDocument = async (value) => {
    const formdata = new FormData();
    formdata.append("userId", user.id);
    formdata.append("fieldId", Id);
    formdata.append(
      "type",
      documentSign?.find((op) => op.userId == user?.id)?.user_type
    );
    // setLoader(true)
    let res = await http.postApi(
      t,
      `/api/form_doc/form/repeatDocument`,
      formdata,
      "labels.document_repeat"
    );
    perarntGetApiData();
    handleCloseModel();
    // setLoader(false)
  };

  /////////////
  ///** Refuse  Document */
  /////////////////

  const refuseDocument = async (value) => {
    const formdata = new FormData();
    formdata.append("userId", user.id);
    formdata.append("fieldId", Id);
    // setLoader(true)
    let res = await http.postApi(
      t,
      `/api/form_doc/form/refuseDocument`,
      formdata,
      "labels.document_refuse"
    );
    perarntGetApiData();
    handleCloseModel();
    // setLoader(false)
  };

  /////////////
  ///** Compelete  Document */
  /////////////////

  const compeleteDocument = async (value) => {
    const formdata = new FormData();
    formdata.append("userId", user.id);
    formdata.append("fieldId", Id);
    // setLoader(true)
    let res = await http.postApi(
      t,
      `/api/form_doc/form/compeleteDocument`,
      formdata,
      "labels.document_completed"
    );
    if (res?.status == false) {
      toast.warning(res?.message)
    }
    console.log(res, "klklklkl")
    perarntGetApiData();
    handleCloseModel();
    // setLoader(false)
  };

  /////////////
  ///** Archive   Document */
  /////////////////

  const handlesArchive = async () => {
    const formdata = new FormData();
    formdata.append("userId", user.id);
    formdata.append("fieldId", Id);
    // setLoader(true)
    let res = await http.postApi(
      t,
      `/api/form_doc/form/sendAchirveDocument`,
      formdata,
      "labels.save_document_as_archive"
    );
    perarntGetApiData();
    handleCloseModel();
  };

  const handleCloseModel = () => {
    setModelData({
      ...modalData,
      model: false,
    });
  };

  const handleModel = (val) => {
    if (documentSign.find((op) => op.userId == user.id)?.sign == null) {
      toast.error("Please sigure first then submit the doc first");
    } else {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };

  console.log(documentSign, "documentSigndocumentSign");

  return (
    <div className="ActionTopBtn Flagnav menuLanBtn">
      <ComponentModal
        modalData={modalData}
        setPerantState={setActionState}
        perantState={actionState}
      />

      {status == "Complete" ? "" : status != "Achirve" ? (
        <Button
          className="ActionMain buttonBlank"
          size="medium"
          color="inherit"
          onClick={() => SetToggle(true)}
        >
          <a href="javascript:void(0)" className="dropdown-toggle ">
            Action<span>▼</span>
          </a>
        </Button>
      ) : (
        <Button
          className="ActionMain buttonBlank"
          size="medium"
          color="inherit"
          onClick={() => handlesArchive()}
        >
          {t("lables.save_archive")}
        </Button>
      )}
      {menuToggle ? (
        <ul
          class="dropdown-menu createDocMenu"
          onMouseLeave={() => SetToggle(false)}
        >
          {documentSign?.find(
            (op) => op?.user_type == "reviewer" && user.id == op.userId
          ) && (
              <li>
                <div onClick={() => handleModel()} class="rootDiv">
                  {/* <img src={Arabic} class="icon" /> */}
                  <a> {t("lables.submit_document")}</a>
                </div>
              </li>
            )}
          {documentSign?.find(
            (op) => op?.user_type == "reviewer" && user.id == op.userId
          ) && (
              <li>
                <div onClick={() => repeatDocument()} class="rootDiv">
                  {/* <img src={Arabic} class="icon" /> */}
                  <a> {t("lables.request_revision")}</a>
                </div>
              </li>
            )}
          {documentSign?.find(
            (op) => op?.user_type == "verifier" && user.id == op.userId
          ) && (
              <li>
                <div onClick={() => refuseDocument()} class="rootDiv">
                  {/* <img src={Arabic} class="icon" /> */}
                  <a>  {t('lables.refuse')}</a>
                </div>
              </li>
            )}
          {documentSign?.find(
            (op) => op?.user_type == "verifier" && user.id == op.userId
          ) && (
              <li>
                <div onClick={() => repeatDocument()} class="rootDiv">
                  {/* <img src={Arabic} class="icon" /> */}
                  <a>{t("lables.Repeat")}</a>
                </div>
              </li>
            )}
          {documentSign?.find(
            (op) => op?.user_type == "verifier" && user.id == op.userId
          ) && (
              <li>
                <div onClick={() => compeleteDocument()} class="rootDiv">
                  {/* <img src={Arabic} class="icon" /> */}
                  <a>{t('lables.accept')}</a>
                </div>
              </li>
            )}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};
export default Action;
