import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import logo from "../../../../../assets/images/jpg 2.png";
import "../../style.css";
import ComponentButton from "../../../../componentGroups/componentButton";
import ComponentTextString from "../../../../componentGroups/componentTextString";
import ComponentSelect from "../../../../componentGroups/componentSelect";
import { useTranslation } from "react-i18next";
import { useHistory, Link, useLocation } from "react-router-dom";
import Svg from "../../../../../assets/svg/svg";
import Model from "../forms_admin_studies/addDocument/model";
import http from "../../../../../api/axios";
import { encode, decode } from "string-encode-decode";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toDate } from "date-fns";
import html2pdf from 'html2pdf.js';
import QRCode from "react-qr-code";


export default function FormPreview(props) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const ref = React.createRef();

  const Id = props.match.params.id;
  const assigned = props.match.params.assigned;
  const requestAssigined = props.match.params.requestAssigined
  const [formData, setFormData] = useState({ title: "", count: "" });
  const [documentSign, setDocumentSign] = useState([]);
  const history = useHistory();
  const [modalData, setmodelData] = useState({
    model: false,
    data: [],
    title: "",
  });
  const [data, setdata] = useState([]);
  console.log(assigned, requestAssigined, "assignedassigned");
  const getApiData = async () => {
    let res = null;
    if (assigned == "assigned" && requestAssigined == undefined) {
      res = await http.getApi(t, `/api/form_doc/assignedDoc/${Id}`);
    } else if (assigned == undefined && requestAssigined == "requestAssigined") {
      res = await http.getApi(t, `/api/form_doc/requestAssigined/${Id}`);
    } else if (assigned == undefined && requestAssigined == undefined) {
      res = await http.getApi(t, `/api/form_doc/${Id}`);
    }
    props.setLoader(false);
    setFormData({ ...formData, title: res?.title, count: res?.fields_count, status: res?.status });
    setdata(JSON.parse(decode(res?.json_data)));
    setDocumentSign(res?.document_signatures);
  };
  useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  const handleCloseModel = (index, fields) => {
    const arr = [...data];
    if (fields) {
      arr[index].footer[0] = fields;
      arr[index].valueExist = true;
      setdata(arr);
    }
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (val, index) => {
    if (val == "addNew") {
      setmodelData({
        ...modalData,
        model: true,
        fieldIndex: index,
        buttonGroup: [
          {
            value: t("labels.discard"),
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: (index, fields) => {
              handleCloseModel(index, fields);
            },
          },
          {
            value: t("labels.add"),
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (index, fields) => {
              handleCloseModel(index, fields);
            },
          },
        ],
      });
    }
  };

  const handleFormSave = () => {
    if (assigned == "assigned" && requestAssigined == undefined) {
      history.push(`/edit_document/assigned/${Id}`);
    } else if (assigned == undefined && requestAssigined == "requestAssigined") {
      history.push(`/assigined_edit_document/assigned/${Id}`);
    } else if (assigned == undefined && requestAssigined == undefined) {
      history.push(`/edit_document/${Id}`);
    }

  };

  const generatePDF = () => {
    const element = document.getElementById('htmlContent'); // ID of the HTML element you want to convert to PDF
    const options = {
      filename: formData?.title,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf().set(options).from(element).save();
  };

  const Bot = (props) => {
    const op = props.op;
    const index = props.index;
    const rowIndex = props.rowIndex;
    return (
      <div className="form_previewTemplate" style={{ flex: "1 0 15%" }}>
        <div className="headerForm ">
          <Typography variant="body2" className="titleHead menuTableRel">
            <span className="headtxt"> {op.header}</span>
          </Typography>
        </div>
        {op.valueExist == false ? (
          <div
            className="iconTool footerForm"
            onClick={() => handleModel("addNew", index, rowIndex)}
          ></div>
        ) : (
          <div className="footerForm ">
            {op.footer.map((footer, ind) => {
              if (footer.type == "radio") {
                return (
                  <div className="CrossBtn">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={footer.selected}
                      >
                        {footer.fields.map((op, indexCheck) => (
                          <FormControlLabel
                            value={op.field}
                            checked={footer.selected == op.lable}
                            control={<Radio disabled={true} />}
                            label={op.field}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                );
              } else if (footer.type == "dropdown") {
                return (
                  <div className="CrossBtn">
                    {/* <ComponentSelect option={footer.fields} value={footer?.value} disabled={true} /> */}
                    {footer?.value ? (
                      <span>{footer.value}</span>
                    ) : (
                      <ComponentTextString
                        type={"text"}
                        value={footer?.value}
                        disabled={true}
                      />
                    )}
                  </div>
                );
              } else if (footer.type == "checkbox") {
                return (
                  <div className="CrossBtn">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        {footer.fields.map((op, indexCheck) => (
                          <FormControlLabel
                            value={op.field}
                            control={
                              <Checkbox checked={op.checked} disabled={true} />
                            }
                            label={op.field}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                );
              } else if (footer.type == "table") {
                return (
                  <div className="CrossBtn2 table">
                    <div className="clearTable"></div>
                    <table className="templateTable">
                      <tr>
                        <th>No .</th>
                        {footer.fields.columns.map((row) => (
                          <th>{row}</th>
                        ))}
                      </tr>
                      {footer.fields.rows.map((row, tableRowIndex) => (
                        <tr>
                          <td>{tableRowIndex + 1}</td>
                          {row.map((column, tableColIndex) => (
                            <td>
                              <div className="footerForm ">
                                {column.type == "radio" ? (
                                  <div className="CrossBtn">

                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={column.selected}
                                      >
                                        {column.fields.map((op, indexCheck) => (
                                          <FormControlLabel
                                            value={op.field}
                                            checked={column.selected == op.label}
                                            control={<Radio disabled={true} />}
                                            label={op.field}
                                          />
                                        ))}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                ) : column.type == "dropdown" ? (
                                  <div className="CrossBtn">
                                    {/* <ComponentSelect option={column.fields} value={column?.value} disable={true} placeholder={"Select fields"} /> */}
                                    {column?.value ? (
                                      <span>{column.value}</span>
                                    ) : (
                                      <ComponentTextString
                                        type={"text"}
                                        value={column?.value}
                                        disabled={true}
                                      />
                                    )}
                                  </div>
                                ) : column.type == "checkbox" ? (
                                  <div className="CrossBtn">
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                      >
                                        {column.fields.map((op, indexCheck) => (
                                          <FormControlLabel
                                            value={op.field}
                                            control={
                                              <Checkbox checked={op.checked} disabled="true" />
                                            }
                                            label={op.field}
                                          />
                                        ))}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                ) : column.type === "text" ? (
                                  <ComponentTextString value={column.value} disable="true" />
                                ) : (
                                  <div className="CrossBtn">
                                    {column?.value ? (
                                      column.type == "file" ? (
                                        <a
                                          href={`${http.baseURL}/${column.value.path}`}
                                          target="_blank"
                                        >
                                          <span>{column.value.name}</span>
                                        </a>
                                      ) : (
                                        <span>{column.value}</span>
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {/* <input className='outerHeadinput' type={footer.type} value={footer?.value} onChange={(e) => { handleFieldsChange(e, index, ind) }} /> */}
                                  </div>
                                )}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </table>
                  </div>
                );
              } else {
                return (
                  <div className="CrossBtn">
                    {footer.file == "file" ? (
                      <ComponentTextString type={footer.type} disable={true} />
                    ) : footer?.value ? (
                      footer.type == "file" ? (
                        <a
                          href={`${http.baseURL}/${footer.value.path}`}
                          target="_blank"
                        >
                          <span>{footer.value.name}</span>
                        </a>
                      ) : (
                        <span>{footer.value}</span>
                      )
                    ) : (
                      <ComponentTextString
                        type={footer.type}
                        value={footer?.value}
                        disable={true}
                      />
                    )}
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    );
  };



  return (
    <Box className="mainDoc" id="htmlContent">
      <div className="logoCreateDoc">
        <img src={logo} />
      </div>
      <div className="innerDoc">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <Typography
            variant="h6"
            className="morningText"
            sx={{ display: "flex" }}
          >
            <ComponentTextString
              placeholder={"Enter the title"}
              value={formData.title}
              disable={true}
            />
            <span style={{ paddingTop: "5px", paddingLeft: "5px" }}>
              ({formData.count})
            </span>
          </Typography>
          <div style={{ display: "flex", gap: "5px" }}>
            {formData?.status != "Complete" && <ComponentButton
              value={Id ? t("labels.edit") : t("labels.save")}
              class={"buttonBlank"}
              callback={() => {
                handleFormSave();
              }}
            />}
            <ComponentButton
              value={t("labels.export")}
              class={"buttonFill"}
              callback={() => {
                generatePDF();
              }}
            />
          </div>
        </Grid>
        {data.map((o, indd) => {
          return (
            <div style={{ display: "flex", minHeight: "120px" }}>
              {o.data.map((op, index) => (
                <Bot op={op} index={index} rowIndex={indd} />
              ))}
            </div>
          );
        })}
        {documentSign && documentSign.length != 0 && (
          <div style={{ display: "flex", minHeight: "auto" }}>
            {documentSign &&
              documentSign?.map((code, index) => (
                <div
                  style={{ flex: "1 0 15%", height: "auto" }}
                  key={index}
                  className="form_previewTemplate addSignzz"
                >
                  <div className="headerForm ">
                    <Typography
                      variant="body2"
                      className="titleHead menuTableRel"
                    >
                      {code.userName} ( {code.department} )
                    </Typography>
                    <div>
                      <Typography
                        variant="body2"
                        className="titleHead menuTableRel"
                      >
                        ({code.user_type})
                      </Typography>
                    </div>
                  </div>
                  <div className="footerForm ">
                    <div className="CrossBtn cutombtnImg">
                      {code?.sign ? (
                        <div className="qr">
                          <QRCode value={decode(code?.sign)} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </Box>
  );
}
