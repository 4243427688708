import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import ComponentModal from "../../componentGroups/componectModel";
import http, { baseURL } from "../../../api/axios";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import { loadOptions } from "../../../api/utils/common";
import validate from "../../../Hooks/project_validaction";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import hooks from "../../../Hooks/hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Projects(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [dropDown, setDropDown] = useState({});
  const [projectState, setProjectState] = useState({});
  const [empData, setEmpData] = useState([]);
  const [filterData, setFilterData] = useState({ filter: false });
  const [data, setData] = useState({
    column: [
      { lable: "", name: "checkbox", width: "10px", align: "center" },
      {
        lable: "labels.projects",
        name: "projectName",
        width: "10px",
        align: "left",
      },
      {
        lable: "labels.case_no",
        name: "caseNo",
        type: "dropDown",
        options: dropDown?.caseNo,
        width: "10px",
        align: "left",
      },
      {
        lable: "labels.created_by",
        name: "createdBy",
        type: "dropDown",
        options: dropDown?.employess,
        width: "10px",
        align: "left",
      },
      {
        lable: "labels.created_on",
        name: "createdAt",
        width: "10px",
        align: "left",
        type: "date",
      },
      {
        lable: "labels.team_no",
        name: "TeamNo",
        hide: true,
        width: "10px",
        align: "left",
      },
    ],
    pageData: {
      title: "labels.projects",
      page: "projects",
      infoURL: "/projects/edit-project",
      hasVariable: true,
      handlExport: (page, size, filterData) =>
        handleExport(page, size, filterData),
      buttonGroup: [
        {
          title: "labels.filter_by",
          type: "filter",
          icon: <FilterListIcon />,
          class: "buttonBlank",
          variant: "outlined",
          callback: () => {},
        },
        {
          title: "labels.action",
          type: "actions",
          icon: <AddIcon />,
          variant: "outlined",
          class: "buttonFill ml-0",
          callback: () => {},
        },
        {
          title: "labels.add_new",
          icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
          class: "buttonFill",
          variant: "contained",
          callback: () => {
            if (getUserHasAccess("project", 129)) {
              handleModel("addEmp");
            } else {
              setAuthorityModal(true);
            }
          },
        },
      ],
    },
  });
  const [errors, setErrors] = useState({});
  const [empCode, setEmpCode] = useState();
  const [branchData, setBranchData] = useState([]);
  const [modalData, setModalData] = useState({
    model: false,
    title: "titles.add_new_project",
    data: [
      {
        lable: "labels.project_name",
        field: { type: "text", required: true },
        name: "projectName",
      },
      {
        lable: "labels.case_no",
        field: {
          type: "dropdown_plus",
          option: [],
          findLable: "option",
          required: true,
        },
        button: {
          name: "case_drop",
          callback: (val) => {
            addDropdownOptions(val);
          },
        },
        name: "caseNo",
      },
      {
        lable: "labels.assigned_to",
        field: {
          type: "multiple",
          required: true,
          setlable: "name",
          option: [],
        },
        name: "assignedTo",
      },
      {
        lable: "labels.created_by",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: ["Riyad", "Riyad"],
        },
        name: "createdBy",
      },
      {
        lable: "labels.shared_with",
        field: {
          type: "multiple",
          setlable: "name",
          required: true,
          option: ["Riyad", "Riyad"],
          value: "me",
        },
        name: "sharedWith",
      },
      {
        lable: "labels.description",
        field: { type: "ck", required: true },
        name: "description",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setProjectState({});
          setErrors({});
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addProject(val);
        },
      },
    ],
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [selectedData, setSelectedData] = useState([]);

  const handleModel = (val) => {
    if (val == "addEmp") {
      setModalData({ ...modalData, model: true });
    }
  };
  const handleCloseModel = () => {
    setModalData({ ...modalData, model: false });
  };

  const getStatedata = async () => {
    let s1 = { ...modalData };
    let s2 = { ...data };
    let empRes = await loadOptions(t, 1, undefined, "post", "/api/employee");
    s1.data[2].field.option = empRes;
    s1.data[3].field.option = empRes;
    s1.data[4].field.option = empRes;
    s2.column[3].options = hooks.sendName(empRes, "name");
    setData(s2);
    setDropDown({ ...dropDown, employess: empRes });
    setModalData(s1);
  };

  const AddloadOptions = async () => {
    let s1 = { ...modalData };
    let s2 = { ...data };
    let res = await loadOptions(t, 1, 10);
    s1.data[1].field.option = res;
    s2.column[2].options = hooks.sendName(res, "option");
    setData(s2);
    setProjectState({ ...projectState, case_drop: "" });
    setModalData(s1);
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.projectValidaction(projectState);
      setErrors(error);
    }
  }, [projectState]);

  const addDropdownOptions = async (
    val,
    key,
    category_id = 10,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    props.setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val?.case_drop);
      formData.append("langId", 1);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        AddloadOptions();
      }
    }
    props.setLoader(false);
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    let formdata = new FormData();
    formdata.append(
      "filter[projectName]",
      filterData?.projectName ? filterData?.projectName : ""
    );
    formdata.append(
      "filter[caseNo]",
      filterData?.caseNo ? filterData?.caseNo : ""
    );
    formdata.append(
      "filter[createdBy]",
      filterData?.createdBy ? filterData?.createdBy : ""
    );
    formdata.append(
      "filter[createdAt]",
      filterData?.createdAt
        ? moment(filterData?.createdAt).format("YYYY-MM-DD")
        : ""
    );
    console.log("here", filterData);
    props.setLoader(true);
    const res = await http.getAllDataApi(
      t,
      `/api/project/export-projects`,
      formdata
    );
    props.setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    //TODO: Api needs to be updated
    getStatedata();
    let formdata = new FormData();
    // if (filterData?.filter == true) {
    formdata.append(
      "filter[projectName]",
      filterData?.projectName ? filterData?.projectName : ""
    );
    formdata.append(
      "filter[caseNo]",
      filterData?.caseNo ? filterData?.caseNo : ""
    );
    formdata.append(
      "filter[createdBy]",
      filterData?.createdBy ? filterData?.createdBy : ""
    );
    formdata.append(
      "filter[createdAt]",
      filterData?.createdAt
        ? moment(filterData?.createdAt).format("YYYY-MM-DD")
        : ""
    );

    const result = await http.getAllDataApi(
      t,
      `/api/project/details/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(result?.data_count);
    setEmpData(result?.data);
    setFilterData({ ...filterData, filter: false });
    AddloadOptions();
    // TODO: if code is independent need to be added to another function

    props.setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, filterData?.filter == true]);

  const addProject = async (obj) => {
    let error = validate.projectValidaction(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formdata = new FormData();
      formdata.append("projectName", obj?.projectName);
      formdata.append("caseNo", obj?.caseNo);
      formdata.append("createdBy", obj?.createdBy);
      formdata.append("description", obj?.description);
      formdata.append("langId", "1");
      obj?.assignedTo?.map((op, index) => {
        formdata.append(`assignedTo[${index}]`, op?.id);
      });
      obj?.sharedWith?.map((op, index) => {
        formdata.append(`sharedWith[${index}]`, op?.id);
      });
      const res = await http.postApi(t, "/api/project/create", formdata);
      setProjectState({});
      setErrors({});
      props.setLoader(false);
      getApiData();
    } else {
      setErrors(error);
    }
  };

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("project", 131)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/project/delete",
          formData,
          "labels.project_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <div className="">
      <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setProjectState}
        perantState={projectState}
      />
      {empData && (
        <ComponentTable
          filterData={filterData}
          setFilterData={setFilterData}
          rows={empData}
          setSearch={setSearch}
          search={search}
          columns={data.column}
          page={page}
          pageData={data.pageData}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          totalRecords={totalRecords}
          selected={selectedData}
          handleSelected={handleSelected}
          handleAllselect={handleAllselect}
          handleDelete={handleDelete}
          enableReset={true}
          resetdata={{
            status: "",
          }}
        />
      )}
    </div>
  );
}
