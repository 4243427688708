import { Grid, Typography } from '@mui/material'
import React from 'react'
import ComponentButton from './componentButton'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getUserHasAccess } from '../../utils/common';

const SuggestionCard = ({ data, categoryName }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNavigation = () => {
    if (getUserHasAccess("reference", 164)) {

      localStorage.setItem('suggestion', JSON.stringify(data));
      // console.log(data.id);
      history.push(`/reference-categories/references/suggestion-form`);
    }
  }

  const finalDate = new Date(
    data.createdAt
  ).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    hour12: true,
  });

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid className="gridLeft">
        <Typography component="span" variant="body2">
          {`"${data.title}" in "${categoryName}" Sent By "${data.employee?.name}"`}
        </Typography>
        <Typography sx={{ color: '#484848 !important', mx: 0.5, fontSize: 14, }}
          component="span" variant="body2">
          Suggested on : {finalDate}
        </Typography>
      </Grid>
      <Grid className="gridLeftbtn">
        {
          getUserHasAccess("reference", 164) ?
            <ComponentButton
              value={t("labels.view_details")}
              variant={"contained"}
              class={"buttonFill"}
              callback={handleNavigation}
            /> : ''}
      </Grid>
    </Grid>
  )
}

export default SuggestionCard