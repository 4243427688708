import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getAllDataApi, getApi, postApi } from '../../../api/axios';
import ComponentDatePicker from '../../componentGroups/componentDatepicker';
import ComponentSelect from '../../componentGroups/componentSelect';
import MinHeightTextarea from '../../componentGroups/componentTextarea';
import ComponentTextString from '../../componentGroups/componentTextString';
import ComponentButton from '../../componentGroups/componentButton';


const INITIAL_VALUE = {
  timeOff: "",
  type: "",
  departmentId: "",
  branchId: "",
  employeeId: "",
  duration: "",
  startDate: "",
  endDate: "",
  reason: "",
};


const AllocationForm = (props) => {
  const { t } = useTranslation();
  const [reqData, setReqData] = useState(INITIAL_VALUE);
  const user = JSON.parse(localStorage.getItem("user"));

  const [errors, setErrors] = useState({});

  const [dropdownOptions, setDropDownOptions] = useState({
    departments: [],
    branches: [],
    employees: [],
    timeOff: [],
    type: [
      {
        name: "Employee",
        id: 2
      },
      {
        name: "Branch",
        id: 0
      },
      // {
      //   name: "Department",
      //   id: 0
      // },

    ],
  });
  const getDepartmentsOptions = async (branchId) => {
    // props.setLoader(true)
    if (branchId) {
      const departmentsRes = await getApi(t, `/api/department/branch-basis/${branchId}`);
      const departmentsOptions = departmentsRes?.map(op => {
        return {
          name: op?.departmentName,
          id: op?.id
        }
      })
      setDropDownOptions((prevVals) => ({
        ...prevVals,
        departments: departmentsOptions,
      }));
    }
    // props.setLoader(false)
  }


  const loadDropdownOptions = async () => {
    const branchesRes = await getAllDataApi(t, `/api/branch/details`)
    const empRes = await getAllDataApi(t, `/api/employee`)
    // const departmentsRes = await getApi(t, `/api/department`)
    const timeOffRes = await getApi(t, '/api/leave/type');


    const timeOffOptions = timeOffRes?.map(op => { return { id: op?.id, name: op?.name } })
    const branchesOptions = branchesRes?.data?.map(op => {
      return {
        name: op?.branchName,
        id: op?.id
      }
    })

    // const departmentsOptions = departmentsRes?.map(op => {
    //   return {
    //     name: op?.departmentCode,
    //     id: op?.id
    //   }
    // })
    // const empOptions = empRes?.data?.map(op => {
    //   return {
    //     name: op?.name,
    //     id: op?.id
    //   }
    // })

    setDropDownOptions((prevVals) => ({
      ...prevVals,
      // departments: departmentsOptions,
      branches: branchesOptions,
      employees: empRes?.data,
      timeOff: timeOffOptions,
    }));

    props.setLoader(false)
  }

  const validate = (data) => {
    let error = {};

    // this is for duration validation
    const startDate = new Date(data?.startDate);
    const endDate = new Date(data?.endDate);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // console.log('Difference in days:', differenceInDays);



    if (data.type === undefined || data.type === "") {
      error.type = "errors.required_field";
    }
    if (data.timeOff === undefined || data.timeOff === "") {
      error.timeOff = "errors.required_field";
    }
    if (data.duration === undefined || data.duration === "") {
      error.duration = "errors.required_field";
    }

    if (data.duration > differenceInDays) {
      error.duration = "errors.enter_valid_duration";
    }

    if (data.startDate === undefined || data.startDate === "") {
      error.startDate = "errors.required_field";
    }
    if (data.endDate === undefined || data.endDate === "") {
      error.endDate = "errors.required_field";
    }

    if (data.type === 0 && data.departmentId === "") {
      error.departmentId = "errors.required_field";
    }
    if (data.type === 1 && data?.branchId === "") {
      error.branchId = "errors.required_field";
    }
    if (data.type === 2 && data?.employeeId === "") {
      error.employeeId = "errors.required_field";
    }
    if (data.reason === undefined || data?.reason?.trim() === "") {
      error.reason = "errors.required_field";
    }
    return error;
  };

  // console.log(dropdownOptions?.timeOff);
  useEffect(() => {
    props.setLoader(true)
    loadDropdownOptions()
  }, [])

  useEffect(() => {
    // props.setLoader(true)
    getDepartmentsOptions(reqData?.branchId)
  }, [reqData?.branchId])

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validate(reqData);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);


  const createReq = async () => {
    let error = validate(reqData);
    // console.log('great');
    // console.log(Object.keys(error));
    if (Object.keys(error).length === 0) {
      const formData = new FormData();

      formData.append("timeOff", reqData?.timeOff ?? "");

      formData.append("type", reqData?.type ?? "");
      if (reqData.type === 0) formData.append("departmentId", reqData?.departmentId ?? "");
      if (reqData.type === 1) formData.append("branchId", reqData?.branchId ?? "");
      if (reqData.type === 2) formData.append("employeeId", reqData?.employeeId ?? "");

      formData.append("duration", reqData?.duration ?? "");
      formData.append("startDate", reqData?.startDate ?? "");
      formData.append("endDate", reqData?.endDate ?? "");
      formData.append("reason", reqData?.reason ?? "");

      props.setLoader(true);
      const res = await postApi(t, "/api/allocations", formData);

      props.setLoader(false);
      if (!!res) {
        setReqData(INITIAL_VALUE);
      }
    } else {
      setErrors(error);
    }
  };


  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="DashTitle" variant="h4">
              {t("labels.add_allocation")}
            </Typography>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "40px", paddingInline: "20px" }}>

            <Grid className="AddEmpGenInfo" container rowSpacing={4}>

              {/* timeOff */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.timeOff")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.timeOff?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="timeOff"
                    value={reqData?.timeOff}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.timeOff && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.timeOff)}`}
                    </div>
                  )}
                </div>
              </Grid>
              {/* type */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.apply_on")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.type?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="type"
                    value={reqData?.type}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.type && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.type)}`}
                    </div>
                  )}
                </div>
              </Grid>



              {/* branch */}
              {reqData?.type === 0 && <>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>

                  <Typography variant="body1" className="customLabel">
                    {t("labels.branch_name")} <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      class={"custonInput"}
                      className="GenDropdown"
                      option={dropdownOptions?.branches?.filter(
                        (item) => item?.id !== user?.id
                      )}
                      name="branchId"
                      value={reqData?.branchId}
                      callback={(evt) => {
                        setReqData({
                          ...reqData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.branchId && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.branchId)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </>}

              {/* department */}
              {reqData?.branchId && <>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("titles.department_name")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      option={dropdownOptions?.departments}
                      name="departmentId"
                      value={reqData?.departmentId}
                      callback={(evt) => {
                        setReqData({
                          ...reqData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.departmentId && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.departmentId)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </>}

              {/* employee */}
              {reqData?.type === 2 && <>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.employee")} <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      class={"custonInput"}
                      className="GenDropdown"
                      option={dropdownOptions?.employees?.filter(
                        (item) => item?.id !== user?.id
                      )}
                      name="employeeId"
                      value={reqData?.employeeId}
                      callback={(evt) => {
                        setReqData({
                          ...reqData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.employeeId && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.employeeId)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </>}

              {/* title */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.duration")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="duration"
                    type='number'
                    placeholder={t('labels.days_number')}
                    value={reqData?.duration}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.duration && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.duration)}`}
                    </div>
                  )}
                </div>
              </Grid>

              {/* date */}
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography className="customLabel" variant="body1">
                  {t("labels.validity_period")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} sx={{ paddingTop: 0, display: "flex" }}>
                {/* start date */}
                <Grid item xs={12} md={6} sx={{ paddingTop: 0, display: "flex" }}>
                  <Grid item xs={2} sx={{ paddingTop: 0 }}>
                    <Typography variant="body1" className="customLabel">
                      {t("labels.from")}
                    </Typography>
                  </Grid>
                  <Grid className="CustomWidth" item xs={9}>
                    <div>
                      <ComponentDatePicker
                        class={"custonInput"}
                        // placeholder="Date Of Start"
                        value={reqData?.startDate}
                        max={reqData?.endDate}
                        callback={(evt) => {
                          setReqData({
                            ...reqData,
                            startDate: evt.target.value,
                          });
                        }}
                      />
                      {errors?.startDate && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {`* ${t(errors?.startDate)}`}
                        </div>
                      )}
                    </div>

                  </Grid>
                </Grid>

                {/* end date */}
                <Grid item xs={12} md={6} sx={{ paddingTop: 0, display: "flex" }}>
                  <Grid item xs={2} sx={{ paddingTop: 0 }}>
                    <Typography variant="body1" className="customLabel">
                      {t("labels.to")}
                    </Typography>
                  </Grid>
                  <Grid className="CustomWidth" item xs={9} >
                    <div>
                      <ComponentDatePicker
                        class={"custonInput"}
                        placeholder="Date Of Start"
                        value={reqData?.endDate}
                        min={reqData?.startDate}
                        callback={(evt) => {
                          setReqData({
                            ...reqData,
                            endDate: evt.target.value,
                          });
                        }}
                      />
                      {errors?.endDate && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {`* ${t(errors?.endDate)}`}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* reason */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.reason")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="reason"
                    minRows={4}
                    placeholder={" Enter the Reason"}
                    value={reqData?.reason}
                    callback={(e) =>
                      setReqData({
                        ...reqData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {/* <ComponentCKEditer
                    style={{ marginRight: "25px" }}
                    value={reqData?.description}
                    name="description"
                    callback={(data) =>
                      setReqData({
                        ...reqData,
                        description: data,
                      })
                    }
                  /> */}
                  {errors?.reason && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.reason)}`}
                    </div>
                  )}
                </div>
              </Grid>



            </Grid>

            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{ marginTop: "5px" }}
              >

                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    value={t("labels.send")}
                    variant="contained"
                    class="buttonFill FillBtn"
                    callback={() => {
                      createReq();
                    }}
                  />
                </Grid>
              </Grid>
            </div>

          </div>
        </Grid>
      </div >
    </div >

  )
}

export default AllocationForm