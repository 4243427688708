import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import React from 'react'
import ComponentButton from '../../componentGroups/componentButton';
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import ComponentTable from '../../componentGroups/componentTable';
import { useState } from 'react';
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from '../../../constant';
import { useContext } from 'react';
import LoadingContext from '../../../context/LoaderContext';
import { getApi } from '../../../api/axios';
import { useEffect } from 'react';
import AllAllocations from './AllAllocations';
import { getUserHasAccess } from '../../../utils/common';
import TabPanel from './TabPanel';
import WaitingAllocations from './WaitingAllocations';

const Allocations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            padding: "1rem"
          }}
        >
          <Grid style={{
            // marginBottom: "3rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Typography variant="h6" className="morningText">
              {t("labels.allocations")}

            </Typography>

            <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                // if (getUserHasAccess("task", 118)) {
                //   handleModel("addBroadcast");
                // } else {
                //   setAuthorityModal(true);
                // }
                history.push('/allocation/allocation-form');
              }}
            />
          </Grid>
        </Grid>
        <Box
          className="TaskTab TabActive"
          sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {getUserHasAccess("allocation", 193) && (
              <Tab
                className="iconText"
                label={t("labels.all_requests")}
                value={0}
              />
            )}
            {getUserHasAccess("allocation", 193) && (
              <Tab
                className="iconText"
                label={t("labels.waiting_for_approval")}
                value={1}
              />
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AllAllocations />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WaitingAllocations />
        </TabPanel>
      </Grid>
    </div >
  )
}

export default Allocations