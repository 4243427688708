import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ComponentButton from './componentButton';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { postApi } from '../../api/axios';
import TextInputWithMention from './TextInputWithMention/TextInputWithMention';

export const convertMentions = (finalText) => {
  // mentioned user will be like @user-name-1 in text string
  const mentionedUserNames = finalText.match(/@(\S+)/g) || [];

  // console.log(mentionedUserNames);
  const originalText = mentionedUserNames.reduce((text, mentionedUserName) => {
    const originalUserName = mentionedUserName.replace(/^@/, '').replace(/-/g, ' ');

    // console.log(originalUserName);

    const spannedUserName = `<span style="font-weight: bold; color: #4599FF; font-style: italic; font-size: 13px; cursor: pointer">${originalUserName}</span>`;

    return text.replace(mentionedUserName, spannedUserName);
  }, finalText);


  return originalText;
}

function CommentCard({ avatarUrl, data, reply, setLoader, getApiData, mentions, setMentionedUsers }) {
  const { t } = useTranslation();

  const [replyText, setReplyText] = useState('');
  const [replyPushed, setReplyPushed] = useState(false)
  // const [mentionedUsers, setMentionedUsers] = useState(mentions ?? [])
  const [showReplyTextArea, setshowReplyTextArea] = useState(false)
  const [commentId, setCommentId] = useState()



  const handleAddReply = async () => {

    if (replyText.length > 0 && commentId) {
      setLoader(true);
      const res = await postApi(t, `/api/comments/${commentId}/replies`, { text: replyText, mentionedUsers: mentions })
      if (!!res) {
        setReplyPushed(true)
        setshowReplyTextArea(false);
        setCommentId(null);
        getApiData();
        setReplyText('');
      }
    }
  };

  // console.log(commentId, replyText);

  // useEffect(() => {

  // }, [branchId]);

  const initials = data?.user?.name ? data?.user?.name?.charAt(0).toUpperCase() : '';

  return (
    <>
      <Box sx={{
        display: 'flex', alignItems: 'center', marginBottom: "1rem",
        fontFamily: 'Poppins'
      }}>
        <Avatar src={data?.user?.photo ? data?.user?.photo : ""} alt="Avatar" sx={{ marginRight: 1 }} >
          {!data?.user?.photo ? initials : ""}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" component="h3" sx={{ fontWeight: 'bold', marginBottom: 0.5 }}>
            {data?.user?.name}
          </Typography>

          <Typography variant="body1" component="p" dangerouslySetInnerHTML={{ __html: data?.text ? convertMentions(data?.text) : '' }}>
          </Typography>
        </Box>

        {!reply && <Box sx={{ display: 'flex', gap: "1rem", marginInlineStart: "auto" }}>

          <ComponentButton
            sx={{ fontSize: 34, fontWeight: "medium", backgroundColor: "white" }}
            value={t("labels.reply")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
            callback={() => {
              setshowReplyTextArea(prev => !prev)
              setCommentId(data?.id)
            }}
          />
        </Box>}

      </Box>

      {/* add reply */}
      {showReplyTextArea && <Box sx={{ display: 'flex', marginBlock: 3, alignItems: "center", justifyContent: "space-between", marginInline: 1 }}>
        {/* <TextField
          variant="outlined"
          placeholder="Add a Reply"
          value={replyText}
          multiline
          maxRows={4}
          inputProps={{ maxLength: 300 }}
          onChange={(e) => setReplyText(e.target.value)}
          sx={{ flex: "1", marginInlineEnd: "2rem", }}
          style={{
            backgroundColor: "white",

          }}
          size="small"
        /> */}
        <TextInputWithMention
          mentions={mentions}
          setMentionedUsers={setMentionedUsers}

          inputText={replyText}
          setInputText={setReplyText}


          commentPushed={replyPushed}
          setCommentPushed={setReplyPushed}

          placeholder={'Add new reply...'}
        />
        <ComponentButton
          sx={{ color: "#fff", fontSize: 34, fontWeight: "medium", marginInlineStart: "2rem" }}
          value="Add Reply"
          variant={"contained"}
          class={"buttonFill"}
          callback={handleAddReply}
        />


        {/* <AddCommentWithMention mentions={mentions} handleSubmit={handleAddReply} reply /> */}

      </Box>}


      {data?.replies?.length > 0 && <Box sx={{
        marginInlineStart: "4rem",
        marginBlock: "2rem"
      }}>


        {/* view replies */}
        {data?.replies?.map((r, i) =>
        (<CommentCard
          key={i}
          avatarUrl='https://example.com/avatar3.jpg'
          data={r}
          reply
        />))}
      </Box>}
    </>
  );
}

export default CommentCard;