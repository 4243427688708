import { Grid, Typography } from "@mui/material";
import React from "react";
import ComponentButton from "./componentButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import deleteApi from "../../api/axios";
import http from "../../api/axios";
import AuthorityContext from "../../context/AuthorityContext";
import { useContext } from "react";
import { getUserHasAccess } from "../../utils/common";
const ReferenceCard = ({ data, getApiData }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const finalDate = new Date(data.createdAt).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    hour12: true,
  });
  const { setAuthorityModal } = useContext(AuthorityContext);
  const handleNavigationWithData = (reference_info) => {

    localStorage.setItem("reference", JSON.stringify(data));
    if (reference_info === 'preview') localStorage.setItem("reference-info-page", JSON.stringify(true));

    history.push(`/reference-categories/references/reference-form`);

  };

  const deleteReferences = async (id) => {
    if (getUserHasAccess("reference", 215)) {

      await http.deleteApi(t, `/api/references/delete/${id}`);
      history.push('reference-categories/references')
      getApiData();
    } else {
      setAuthorityModal(true);
    }
  };
  console.log(data);
  return (
    <div className="reference_code_box">
      <div className="reference_codeYellow">
        <Typography variant="span">
          {t('labels.reference_code')} : {data.referenceCode}
        </Typography>
      </div>
      <div className="LeftRightContentsec">
        <div className="left-ReferCode">
          <Typography className="morningText" variant="h4">
            {data.title}
          </Typography>
          <Typography component="span" variant="body2">
            {data.description}
          </Typography>
          <div className="ReferDescription">
            <ul style={{
              display: "flex",
              gap: "20px"
            }}>
              <li> {t('labels.author')} : {data.author}</li>
              <li>{t('labels.date')} : {finalDate}</li>
              <li>{t('labels.reviewed_by')} : {data.reviewer} </li>
            </ul>
          </div>
        </div>
        <div className="right-ReferCode">

          <ComponentButton
            sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
            value={t("labels.preview")}
            callback={() => {
              if (getUserHasAccess("reference", 158)) {
                handleNavigationWithData('preview')
              } else {
                setAuthorityModal(true);
              }
            }
            }
            icon={
              <VisibilityIcon sx={{ paddingInlineEnd: "4px", color: "#fff" }} />
            }
            variant={"contained"}
            class={"buttonFill"}
          />

          <ComponentButton
            sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
            value={t("labels.edit")}
            callback={() => {
              if (getUserHasAccess("reference", 216)) {
                handleNavigationWithData()
              } else {
                setAuthorityModal(true);
              }
            }}
            icon={<EditIcon sx={{ paddingInlineEnd: "4px", color: "#2b564c" }} />}
            variant={"outlined"}
            class={"buttonBlank Mright"}
          />

          <ComponentButton
            sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
            value={t("labels.delete")}
            icon={<DeleteIcon sx={{ paddingInlineEnd: "4px", color: "red" }} />}
            variant={"outlined"}
            class={"buttonBlank Mright delbtnRefrence"}
            callback={() => deleteReferences(data.id)}
          />


        </div>
      </div>
    </div>
  );
};

export default ReferenceCard;
