import React, { useState, useEffect, useContext } from "react";
import "../style.css";
import IosShareIcon from "@mui/icons-material/IosShare";
import FilterListIcon from "@mui/icons-material/FilterList";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LoadingContext from "../../../../context/LoaderContext";

export default function MeetingHistory({ modulePath = "" }) {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [rows, setRows] = useState([]);
  const [column, setColumn] = useState([
    {
      lable: "labels.meeting_code",
      name: "meeting_code",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.meeting_title",
      name: "meeting_title",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.date",
      name: "date",
      width: "10px",
      align: "left",
      type: "date",
      minWidth: 20,
    },
    {
      lable: "labels.status",
      type: "dropDown",
      name: "status",
      width: "10px",
      align: "left",
      minWidth: 20,
      options: [
        { id: 1, name: "Validated", option: "Validated" },
        { id: 0, name: "Not Validated", option: "Not Validated" },
      ],
    },
  ]);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(rows.length);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({ filter: false, status: "" });

  const [data, setData] = React.useState({
    title: "tableTitle.8.board_meetings_history",
    page: "BoardDecision",
    infoURL: modulePath ? `/${modulePath}/meeting-detail` : "/meetingDetails",
    buttonGroup: [
      {
        type: "actins",
        title: "labels.export",
        icon: <IosShareIcon sx={{ color: "white", paddingRight: "10px" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => handleExport(),
      },
      {
        type: "filter",
        title: "labels.filter",
        icon: (
          <FilterListIcon
            sx={{ color: "#2B564C", paddingRight: "10px" }}
            className="iconShare"
          />
        ),
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });

  const handleExport = async () => {
    let formdata = new FormData();
    formdata.append(
      "filter[meeting_title]",
      filterData?.meeting_title ? filterData?.meeting_title : ""
    );
    formdata.append(
      "filter[date]",
      filterData?.date ? moment(filterData?.date).format("YYYY-MM-DD") : ""
    );
    formdata.append(
      "filter[status]",
      filterData?.status ? filterData?.status : ""
    );
    setLoader(true);
    const res = await http.postApi(
      t,
      `/api/meeting/meeting-mins/history/export`,
      formdata
    );
    setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  /**
   * get meeting history data
   */
  const getMeetingHistory = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append(
      "filter[meeting_title]",
      filterData?.meeting_title ? filterData?.meeting_title : ""
    );
    formdata.append(
      "filter[date]",
      filterData?.date ? moment(filterData?.date).format("YYYY-MM-DD") : ""
    );
    formdata.append(
      "filter[status]",
      filterData?.status ? filterData?.status : ""
    );

    const res = await http.getAllDataApi(
      t,
      `/api/meeting/meeting-mins/history/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setLoader(false);
    if (!!res?.data) {
      setRows(res?.data);
      setTotalrecords(res?.total_count);
    }
    setFilterData({ ...filterData, filter: false });
  };

  useEffect(() => {
    getMeetingHistory(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, filterData?.filter === true]);

  return (
    <div className="mainContainer PartnerTable">
      <ComponentTable
        rows={rows}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        filterData={filterData}
        setFilterData={setFilterData}
        defaultstatus={false}
        enableReset={true}
        resetdata={{
          status: "",
          date: "",
        }}
      />
    </div>
  );
}
