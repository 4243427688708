const decisionValidaction = (data) => {
    let error = {};
    if (data.sendTo == undefined || data.sendTo == "") {
        error.sendTo = "errors.sendTo";
    }
    if (data.note == undefined || data.note == "") {
        error.note = "errors.noteError";
    }

    return error;
};

const mintsValidaction = (data) => {
    let error = {};
    if (data.mints == undefined || data.mints == "") {
        error.mints = "errors.minuts";
    }

    return error;
};

const otpValidaction = (data) => {
    let error = {};
    if (data.sendTo == undefined || data.sendTo == "") {
        error.mints = "errors.sendTo";
    }
    if (data.otp == undefined || data.otp == "") {
        error.mints = "errors.otp";
    }

    return error;
};
const inSystemValidaction = (data) => {
    let error = {};
    if (data.sendTo == undefined || data.sendTo == "") {
        error.sendTo = "errors.sendTo";
    }
    if (data.subject == undefined || data.subject == "") {
        error.subject = "errors.subject";
    }
    return error;
};
export default {
    decisionValidaction, mintsValidaction, inSystemValidaction, otpValidaction
};
