import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Card, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ComponentButton from "../../componentGroups/componentButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./style.css";
import http from "../../../api/axios";
import moment from "moment";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../comman/Breadcrumb";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FormDocumnent(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [progress, setProgress] = useState({});
  const history = useHistory();

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/form_doc/forms/findAll`);
    let progress = await http.getApi(t, `/api/form_doc/forms/progress`);
    setProgress(progress);
    setFormData(res);
    props.setLoader(false);
  };
  useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  const handleExpandClick = (val) => {
    if (val == "forms") {
      history.push({ pathname: `/${val}`, state: { param: "forms" } });
    } else {
      history.push(`/documents/${val}`);
    }
  };

  const BreadcrumbsPathnames = [
    {
      name: t("labels.forms_and_docs"),
      path: "/formDocuments",
    },
  ];

  return (
    <div className="FormDocPg">
      <div className="CommonPd">
        <Breadcrumbs pathnames={BreadcrumbsPathnames} />
        <div className="latest_des_box">
          <Grid className="dashTopGrid">
            <Typography variant="h4" className="morningText">
              {t("sideBar.2.forms_documents")}{" "}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Card className="FormDoc-Card">
                  <CardHeader
                    // avatar={
                    //     <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    //         R
                    //     </Avatar>
                    // }
                    // action={
                    //     // <ComponentButton value={t('labels.filter_by')} icon={<FilterListIcon />} variant={"outlined"} class={"customButtonBlank  "} />
                    // }
                    title={t("titles.completed_documents")}
                    // subheader="September 14, 2016"
                  />

                  <CardContent sx={{ display: "flex" }}>
                    <div className="progressBar">
                      <CircularProgressbar
                        value={progress?.completeDoc?.progress ?? 0}
                        text={`${progress?.completeDoc?.progress ?? 0}%`}
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the "completed progress"
                          path: {
                            // Path color
                            stroke: `rgba(183, 161, 96, 1)`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Customize transition animation
                            transition: "stroke-dashoffset 0.5s ease 0s",
                            // Rotate the path
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          // Customize the circle behind the path, i.e. the "total progress"
                          trail: {
                            // Trail color
                            stroke: "#d6d6d6",
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Rotate the trail
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          // Customize the text
                          text: {
                            // Text color
                            fill: "rgba(183, 161, 96, 1)",
                            // Text size
                            fontSize: "25px",
                          },
                          // Customize background - only used when the `background` prop is true
                          background: {
                            fill: "#3e98c7",
                          },
                        }}
                      />
                    </div>
                    <div className="cardContant">
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {progress?.completeDoc?.files} {t("labels.files")}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {t("labels.last_update")} :{" "}
                        {moment(progress?.completeDoc?.updatedAt).format(
                          "MMMM DD , YYYY"
                        )}{" "}
                        at{" "}
                        {moment(progress?.completeDoc?.updatedAt).format(
                          "hh A"
                        )}
                      </Typography>
                    </div>
                  </CardContent>

                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={() => handleExpandClick("CompleteDocuments")}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <Button
                        variant="span"
                        color="text.secondary"
                        className="textFont OpenBtn"
                        style={{ display: "flex" }}
                      >
                        {t("labels.open")} <ArrowRightAltIcon />
                      </Button>
                    </ExpandMore>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="FormDoc-Card">
                  <CardHeader
                    // action={
                    //     // <ComponentButton value={t('labels.filter_by')} icon={<FilterListIcon />} variant={"outlined"} class={"customButtonBlank  "} />
                    // }
                    title={t("titles.incompleted_documents")}
                  />
                  <CardContent sx={{ display: "flex" }}>
                    <div className="progressBar">
                      <CircularProgressbar
                        value={progress?.incompleteDoc?.progress ?? 0}
                        text={`${progress?.incompleteDoc?.progress ?? 0}%`}
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the "completed progress"
                          path: {
                            // Path color
                            stroke: `rgba(255, 1, 1, 1)`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Customize transition animation
                            transition: "stroke-dashoffset 0.5s ease 0s",
                            // Rotate the path
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          // Customize the circle behind the path, i.e. the "total progress"
                          trail: {
                            // Trail color
                            stroke: "#d6d6d6",
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Rotate the trail
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          // Customize the text
                          text: {
                            // Text color
                            fill: "rgba(255, 1, 1, 1)",
                            // Text size
                            fontSize: "25px",
                          },
                          // Customize background - only used when the `background` prop is true
                          background: {
                            fill: "rgba(255, 1, 1, 1)",
                          },
                        }}
                      />
                    </div>
                    <div className="cardContant">
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {progress?.incompleteDoc?.files} {t("labels.files")}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {t("labels.last_update")} :{" "}
                        {moment(progress?.incompleteDoc?.updatedAt).format(
                          "MMMM DD , YYYY"
                        )}{" "}
                        at{" "}
                        {moment(progress?.incompleteDoc?.updatedAt).format(
                          "hh A"
                        )}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={() => handleExpandClick("inCompleteDocuments")}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <Button
                        variant="span"
                        color="text.secondary"
                        className="textFont OpenBtn"
                        style={{ display: "flex" }}
                      >
                        {t("labels.open")} <ArrowRightAltIcon />
                      </Button>
                    </ExpandMore>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="FormDoc-Card">
                  <CardHeader
                    // action={
                    //     // <ComponentButton value={t('labels.filter_by')} icon={<FilterListIcon />} variant={"outlined"} class={"customButtonBlank  "} />
                    // }
                    title={t("titles.forms")}
                  />
                  <CardContent sx={{ display: "flex" }}>
                    <div className="cardContant">
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {formData?.fileCount} {t("labels.files")}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {t("labels.last_update")} :{" "}
                        {moment(formData?.updatedAt).format("MMMM DD , YYYY")}{" "}
                        at {moment(formData?.updatedAt).format("hh A")}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={() => handleExpandClick("forms")}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <Button
                        variant="span"
                        color="text.secondary"
                        className="textFont OpenBtn"
                        style={{ display: "flex" }}
                      >
                        {t("labels.open")} <ArrowRightAltIcon />
                      </Button>
                    </ExpandMore>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="FormDoc-Card">
                  <CardHeader
                    // avatar={
                    //     <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    //         R
                    //     </Avatar>
                    // }
                    // action={
                    //     // <ComponentButton value={t('labels.filter_by')} icon={<FilterListIcon />} variant={"outlined"} class={"customButtonBlank  "} />
                    // }
                    title={t("titles.achirve_documents")}
                    // subheader="September 14, 2016"
                  />

                  <CardContent sx={{ display: "flex" }}>
                    <div className="progressBar">
                      <CircularProgressbar
                        value={progress?.archiveDoc?.progress}
                        text={`${progress?.archiveDoc?.progress ?? 0}%`}
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the "completed progress"
                          path: {
                            // Path color
                            stroke: `rgba(183, 161, 96, 1)`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Customize transition animation
                            transition: "stroke-dashoffset 0.5s ease 0s",
                            // Rotate the path
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          // Customize the circle behind the path, i.e. the "total progress"
                          trail: {
                            // Trail color
                            stroke: "#d6d6d6",
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Rotate the trail
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          // Customize the text
                          text: {
                            // Text color
                            fill: "rgba(183, 161, 96, 1)",
                            // Text size
                            fontSize: "25px",
                          },
                          // Customize background - only used when the `background` prop is true
                          background: {
                            fill: "#3e98c7",
                          },
                        }}
                      />
                    </div>
                    <div className="cardContant">
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {progress?.archiveDoc?.files} {t("labels.files")}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textFont"
                      >
                        {t("labels.last_update")} :
                        {moment(progress?.archiveDoc?.updatedAt).format(
                          "MMMM DD , YYYY"
                        )}{" "}
                        at{" "}
                        {moment(progress?.archiveDoc?.updatedAt).format("hh A")}
                      </Typography>
                    </div>
                  </CardContent>

                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={() => handleExpandClick("ArchivedDocuments")}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <Button
                        variant="span"
                        color="text.secondary"
                        className="textFont OpenBtn"
                        style={{ display: "flex" }}
                      >
                        {t("labels.open")} <ArrowRightAltIcon />
                      </Button>
                    </ExpandMore>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
