import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentSelect from "../../componentGroups/componentSelect";
import MinHeightTextarea from "../../componentGroups/componentTextarea";
import ComponentButton from "../../componentGroups/componentButton";
import axios from "axios";
import http, { getApi } from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import ComponentTextString from "../../componentGroups/componentTextString";
import ComponentUpload from "../../componentGroups/componentUpload";

const INITIAL_VALUE = {
  sendTo: "",
  subject: "",
  departmentId: "",
  title: "",
  client: "",
  matter: "",
  periority: "",
  docTitle: "",
  docFolder: "",
  docName: "",
};

const AskForConsultation = () => {
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [reqData, setReqData] = useState(INITIAL_VALUE);
  const [attachment, setAttachment] = useState("");
  // console.log(attachment);
  const [dropdownOptions, setDropDownOptions] = useState({
    departments: [],
    emp: [],
    clients: [],
    matters: [],
    docTitles: [],
    docFolders: [],
    docNames: [],
    periorities: [
      {
        name: "High",
        id: "high",
      },
      {
        name: "Medium",
        id: "medium",
      },
      {
        name: "Low",
        id: "low",
      },
    ],
  });
  const [errors, setErrors] = useState({});

  /**
   *
   * @param {*} key  dropdown options key
   * @param {*} id   category id on dropdown field
   */
  const loadDropdownOptions = async (
    key,
    id,
    type = "post",
    endPoint = "/api/dropdown/show-options"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("id", id);
      res = await http.getAllDataApi(t, endPoint, formData);
      res = res?.data;
    }
    if (type === "get") {
      res = await http.getApi(t, `${endPoint}`);
    }
    const options = res?.map((op) => {
      if (op?.code?.startsWith("CLNT")) {
        return {
          ...op,
          name: op.name ?? op?.option,
        };
      }
      if (op?.matterName) {
        return {
          ...op,
          name: op.matterName ?? op?.option,
        };
      }
      if (op?.departmentName) {
        return {
          ...op,
          name: op.departmentName ?? op?.option,
        };
      } else {
        return op;
      }
    });
    setDropDownOptions((prevVals) => ({
      ...prevVals,
      [key]: options,
    }));
  };

  const getDocTitles = async (id) => {
    const res = await getApi(t, `/api/client/findallrows/${id}`)
    if (res)
      setDropDownOptions((prevVals) => ({
        ...prevVals,
        docTitles: res,
      }));
  }

  const getDocFolders = async (id) => {
    const res = await getApi(t, `/api/client/findallfolderbyrows/${id}`)
    // console.log(res);
    if (res)
      setDropDownOptions((prevVals) => ({
        ...prevVals,
        docFolders: res,
      }));
  }

  const getDocFiles = async (id) => {
    const res = await getApi(t, `/api/client/findallfilesoffolders/${id}`)
    console.log(res[0]?.form_documents);
    const options = res[0]?.form_documents?.map(op => {
      return {
        id: op.id,
        name: op.title
      }
    })
    console.log(options);
    if (options?.length > 0)
      setDropDownOptions((prevVals) => ({
        ...prevVals,
        docNames: options,
      }));
  }

  const validate = (data) => {
    let error = {};
    if (data.title === undefined || data.title === "") {
      error.title = "errors.required_field";
    }
    if (data.departmentId === undefined || data.departmentId === "") {
      error.departmentId = "errors.required_field";
    }
    if (data.sendTo === undefined || data.sendTo === "") {
      error.sendTo = "errors.required_field";
    }
    if (data.client === undefined || data.client === "") {
      error.client = "errors.required_field";
    }
    if (data.matter === undefined || data.matter === "") {
      error.matter = "errors.required_field";
    }
    if (data.periority === undefined || data.periority === "") {
      error.periority = "errors.required_field";
    }
    if (data.subject === undefined || data?.subject?.trim() === "") {
      error.subject = "errors.required_field";
    }
    return error;
  };

  useEffect(() => {
    if (reqData?.departmentId) {
      setLoader(true);
      axios
        .all([
          loadDropdownOptions(
            "emp",
            "",
            "get",
            `/api/employee/all/department/${reqData?.departmentId}`
          ),
        ])
        .finally(() => setLoader(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData?.departmentId]);

  useEffect(() => {
    if (reqData?.client) {
      setLoader(true);
      axios
        .all([
          loadDropdownOptions(
            "matters",
            "",
            "post",
            `/api/matter/all/${reqData?.client}`
          ),
          getDocTitles(reqData?.client)
        ])
        .finally(() => setLoader(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData?.client]);

  useEffect(() => {
    if (reqData?.docTitle) getDocFolders(reqData?.docTitle)
  }, [reqData?.docTitle])

  useEffect(() => {
    if (reqData?.docFolder) getDocFiles(reqData?.docFolder)
  }, [reqData?.docFolder])

  // console.log(reqData?.client);
  // console.log(dropdownOptions?.docTitles);

  useEffect(() => {
    setLoader(true);
    axios
      .all([
        loadDropdownOptions("departments", "", "get", `/api/department`),
        loadDropdownOptions("clients", "", "post", `/api/client`),
      ])
      .finally(() => setLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validate(reqData);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);
  console.log(attachment);
  const createReq = async () => {
    let error = validate(reqData);
    if (Object.keys(error).length === 0) {
      const formData = new FormData();
      formData.append("title", reqData?.title ?? "");
      formData.append("departmentId", reqData?.departmentId ?? "");
      formData.append("sendTo", reqData?.sendTo ?? "");
      formData.append("client", reqData?.client ?? "");
      formData.append("matter", reqData?.matter ?? "");
      formData.append("periority", reqData?.periority ?? "");
      formData.append("docTitle", reqData?.docTitle ?? "");
      formData.append("docFolder", reqData?.docFolder ?? "");
      formData.append("docName", reqData?.docName ?? "");
      formData.append("file", attachment ?? "");
      formData.append("description", reqData?.subject ?? "");
      setLoader(true);
      const res = await http.postApi(t, "/api/consultation/create", formData);
      setLoader(false);
      if (!!res) {
        setReqData(INITIAL_VALUE);
      }
    } else {
      setErrors(error);
    }
  };
  console.log(reqData);
  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="DashTitle" variant="h4">
              {t("labels.consultation_req")}
            </Typography>
          </div>
          <div
            style={{
              marginLeft: "10px",
              marginTop: "40px",
              paddingInline: "20px",
            }}
          >
            <Grid className="AddEmpGenInfo" container rowSpacing={4}>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.title")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="title"
                    value={reqData?.title}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.title && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.title)}`}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("titles.department_name")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    className="GenDropdown"
                    option={dropdownOptions?.departments}
                    name="departmentId"
                    value={reqData?.departmentId}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.departmentId && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.departmentId)}`}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.send_to")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.emp?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="sendTo"
                    value={reqData?.sendTo}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.sendTo && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.sendTo)}`}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.client_name")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.clients}
                    name="client"
                    value={reqData?.client}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.client && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.client)}`}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.matter_name")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.matters?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="matter"
                    value={reqData?.matter}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.matter && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.matter)}`}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.priority")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.periorities?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="periority"
                    value={reqData?.periority}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.periority && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.periority)}`}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document_title")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.docTitles?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="docTitle"
                    value={reqData?.docTitle}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document_folder")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.docFolders?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="docFolder"
                    value={reqData?.docFolder}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document_name")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.docNames?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="docName"
                    value={reqData?.docName}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.description")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="subject"
                    minRows={4}
                    placeholder={"Enter the description"}
                    value={reqData?.subject}
                    callback={(e) =>
                      setReqData({
                        ...reqData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.subject && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.subject)}`}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.attachments")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={10} sx={{ paddingTop: 0 }}>
                <div
                  className="Mright"
                  style={{
                    width: "fit-content",
                  }}
                >
                  <ComponentUpload
                    lable={t("labels.upload_files")}
                    name="documents"
                    callback={(e) => setAttachment(e.target.files[0])}
                  />
                </div>
              </Grid>
            </Grid>

            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{ marginTop: "5px" }}
              >
                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    value={t("labels.send")}
                    variant="contained"
                    class="buttonFill FillBtn"
                    callback={() => {
                      createReq();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AskForConsultation;
