import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import "../style.css";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { AddressSection } from "./addressSection";
import http from "../../../../api/axios";

export default function AddressesSet({
  empState,
  setEmpState,
  dropDownOptions,
  initialAddressFields,
}) {
  const { t } = useTranslation();

  /**
   * add address obj in address array with initial values
   */
  const addAddress = () => {
    const addressLength = empState.addresses.length;
    setEmpState({
      ...empState,
      addresses: [
        ...empState.addresses,
        {
          ...initialAddressFields,
          addressName: `${t("labels.address")} ${addressLength + 1}`,
        },
      ],
    });
  };

  return (
    <>
      {empState.addresses.map((address, index) => (
        <AddressSection
          key={`${index}`}
          address={address}
          empState={empState}
          setEmpState={setEmpState}
          dropDownOptions={dropDownOptions}
          index={index}
        />
      ))}

      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}></Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <Grid className="AddaddressBTN">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="outlineBtn newBtn"
              onClick={addAddress}
            >
              {t("labels.add_new_address")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
