import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import AllTasks from "./allTasks";
import MyTask from "./myTasks";
import SendTask from "./sendTask";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import "../../../assets/css/style.css";
import http, { baseURL } from "../../../api/axios";
import ComponentModal from "../../componentGroups/componectModel";
import ComponentButton from "../../componentGroups/componentButton";
import { loadOptions } from "../../../api/utils/common";
import validate from "../../../Hooks/tasks.validaction";
import moment from "moment";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import PermisisionDenied from "../../routing/PermisisionDenied";
import LateTask from "./lateTask";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="Tasksplpage">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tasks(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [errors, setErrors] = useState({});
  const [filterData, setFilterData] = useState({ filter: false });
  const [value, setValue] = React.useState(0);
  const [totalRecords, setTotalrecords] = useState(0);
  const [allTaskData, setAllTaskData] = useState([]);
  const [taskState, setTaskState] = useState({});
  const [dropdownOptions, setDropdwonOptions] = useState({});
  const [search, setSearch] = useState("");
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_task",
    data: [
      {
        lable: "labels.task_title",
        field: { type: "text", required: true },
        name: "taskTitle",
      },
      {
        lable: "labels.task_type",
        field: {
          type: "dropdown_plus",
          option: [],
          findLable: "option",
          required: true,
        },
        button: {
          name: "type_drop",
          callback: (val) => {
            addDropdownOptions(val, "type_drop", 1);
          },
        },
        name: "taskType",
      },
      {
        lable: "labels.related_project",
        field: {
          type: "dropdown",
          required: true,
          findLable: "projectName",
          option: [],
        },
        name: "related_project_id",
      },
      {
        lable: "labels.assigned_to",
        field: {
          type: "multiple",
          required: true,
          setlable: "name",
          option: [],
        },
        name: "assignedTO",
      },
      {
        lable: "labels.priority",
        field: {
          type: "dropdown_plus",
          required: true,
          findLable: "option",
          option: [],
        },
        button: {
          name: "priority_drop",
          callback: (val) => {
            addDropdownOptions(val, "priority_drop", 2);
          },
        },
        name: "priority",
      },
      {
        lable: "labels.due_date",
        field: {
          type: "date",
          min: moment(new Date()).format("YYYY-MM-DD"),
          required: true,
        },
        name: "dueDate",
      },
      {
        lable: "labels.request_by",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: ["Riyad", "Riyad"],
        },
        name: "requestBy",
      },
      {
        lable: "labels.shared_with",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
          value: "",
        },
        name: "sharedWith",
      },
      {
        lable: "labels.description",
        field: { type: "ck", required: true },
        name: "description",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.create",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addTask(val);
        },
      },
    ],
  });
  console.log(allTaskData);
  const handleCloseModel = () => {
    setErrors({});
    setTaskState({});
    setModelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addTask") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    props.setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val?.[key]);
      formData.append("langId", 1);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        setTaskState({ ...taskState, [key]: "" });
        getDataApi(true);
      }
    }
    props.setLoader(false);
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData,
    type = ""
  ) => {
    let formdata = new FormData();
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate ? filterData?.dueDate : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    props.setLoader(true);
    let exportUrl = "/api/task/export-task";
    const res = await http.getAllDataApi(
      t,
      `${exportUrl}?access=${type}`,
      formdata
    );
    props.setLoader(false);
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    let formdata = new FormData();
    // if (filterData?.filter == true) {
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate ? filterData?.dueDate : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    formdata.append(
      "filter[projectName]",
      filterData?.projectName ? filterData?.projectName : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/task/details?page=${pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setAllTaskData(res.data);
    setFilterData({ ...filterData, filter: false });
    // TODO: if code is independent need to be added to another function
    props.setLoader(false);
  };
  const getDataApi = async (key = false) => {
    let s1 = { ...modalData };
    let res = await loadOptions(t, 1, 1);
    let res1 = await loadOptions(t, 1, 2);
    let res2 = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let res3 = await loadOptions(
      t,
      1,
      undefined,
      "post",
      "/api/project/details"
    );
    console.log(res2, "rew2");
    setDropdwonOptions({
      ...dropdownOptions,
      taskType: res,
      employess: res2,
      priority: res1,
    });
    s1.data[1].field.option = res;
    s1.data[4].field.option = res1;
    s1.data[3].field.option = res2;
    s1.data[6].field.option = res2;
    s1.data[7].field.option = res2;
    s1.data[2].field.option = res3;

    s1.model = key;
    setModelData(s1);
  };

  useEffect(() => {
    getDataApi();
  }, [search]);

  useEffect(() => {
    getApiData();
    getDataApi();
  }, [filterData?.filter == true]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.tasksValidate(taskState);
      setErrors(error);
    }
  }, [taskState]);

  useEffect(() => {
    if (getUserHasAccess("task", 133)) {
      setValue(0);
    } else if (getUserHasAccess("task", 134)) {
      setValue(1);
    } else if (getUserHasAccess("task", 135)) {
      setValue(2);
    }
  }, []);

  const addTask = async (obj) => {
    console.log(obj, "ppppppppppppppppp");
    let error = validate.tasksValidate(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formData = new FormData();
      formData.append("taskTitle", obj.taskTitle);
      formData.append("taskType", obj.taskType);
      obj?.assignedTO?.map((op, index) => {
        formData.append(`assignedTo[${index}]`, op.id);
      });
      formData.append("priority", obj.priority);
      formData.append("dueDate", obj.dueDate);
      formData.append("requestBy", obj.requestBy);
      formData.append("sharedWith", obj.sharedWith);
      formData.append("description", obj.description);
      formData.append("related_project_id", obj.related_project_id);
      formData.append("langId", "1");
      const res = await http.postApi(t, "/api/task/create", formData);
      if (res) {
        setTaskState({});
        getApiData();
        handleCloseModel();
      }
      props.setLoader(false);
    } else {
      setErrors(error);
    }
  };

  return (
    <div className="CommonPd">
      <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setTaskState}
        perantState={taskState}
      />

      <div className="latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("tableTitle.10.tasks")}
              </Typography>
            </Grid>
            <Grid className="btnStyle">
              <ComponentButton
                icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
                value={t("labels.add_new")}
                variant={"contained"}
                class={"buttonFill"}
                callback={() => {
                  if (getUserHasAccess("task", 118)) {
                    handleModel("addTask");
                  } else {
                    setAuthorityModal(true);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Box
            className="TaskTab TabActive"
            sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {getUserHasAccess("task", 133) && (
                <Tab
                  className="iconText"
                  label={t("tableTitle.5.all_tasks")}
                  value={0}
                />
              )}
              {(getUserHasAccess("task", 133) ||
                getUserHasAccess("task", 134)) && (
                  <Tab
                    className="iconText"
                    label={t("tableTitle.6.sent_tasks")}
                    value={1}
                  />
                )}
              {(getUserHasAccess("task", 133) ||
                getUserHasAccess("task", 135)) && (
                  <Tab
                    className="iconText"
                    label={t("tableTitle.7.my_tasks")}
                    value={2}
                  />
                )}
              {(getUserHasAccess("task", 133) ||
                getUserHasAccess("task", 135)) && (
                  <Tab
                    className="iconText"
                    label={t("tableTitle.11.late_tasks")}
                    value={3}
                  />
                )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AllTasks
              props={props}
              filterData={filterData}
              setFilterData={setFilterData}
              getApiData={getApiData}
              search={search}
              dropdownOptions={dropdownOptions}
              setLoader={props.setLoader}
              allTaskData={allTaskData}
              totalRecords={totalRecords}
              setSearch={setSearch}
              handleExport={handleExport}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <SendTask
              setLoader={props.setLoader}
              dropdownOptions={dropdownOptions}
              handleExport={handleExport}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MyTask
              setLoader={props.setLoader}
              dropdownOptions={dropdownOptions}
              handleExport={handleExport}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <LateTask
              setLoader={props.setLoader}
              dropdownOptions={dropdownOptions}
              handleExport={handleExport}
            />
          </TabPanel>
        </Grid>
      </div>
    </div>
  );
}
