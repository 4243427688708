import React, { useContext, useEffect, useState } from 'react'
import { getAllDataApi, getApi, postApi } from '../../../api/axios'
import LoadingContext from '../../../context/LoaderContext';
import ComponentButton from '../../componentGroups/componentButton';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComponentTextString from '../../componentGroups/componentTextString';
import ComponentSelect from '../../componentGroups/componentSelect';
import MinHeightTextarea from '../../componentGroups/componentTextarea';
import ComponentCKEditer from '../../componentGroups/componentCKEditer';


const INITIAL_VALUE = {
  title: "",
  description: "",
  departmentId: "",
  employeeId: "",
  type: "",
  branchId: "",
};


const BroadcastForm = (props) => {
  const { setLoader } = useContext(LoadingContext);
  const [errors, setErrors] = useState({});
  const [reqData, setReqData] = useState(INITIAL_VALUE);
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  const [dropdownOptions, setDropDownOptions] = useState({
    departments: [],
    branches: [],
    employees: [],
    type: [
      {
        name: "Employee",
        id: 2
      },
      {
        name: "Branch",
        id: 1
      },
      {
        name: "Department",
        id: 0
      },

    ],
  });

  const loadDropdownOptions = async () => {
    const branchesRes = await getAllDataApi(t, `/api/branch/details`)
    const departmentsRes = await getApi(t, `/api/department`)
    const empRes = await getAllDataApi(t, `/api/employee`)
    const branchesOptions = branchesRes?.data?.map(op => {
      return {
        name: op?.branchCode,
        id: op?.id
      }
    })
    const departmentsOptions = departmentsRes?.map(op => {
      return {
        name: op?.departmentCode,
        id: op?.id
      }
    })
    setDropDownOptions((prevVals) => ({
      ...prevVals,
      departments: departmentsOptions,
      branches: branchesOptions,
      employees: empRes?.data,
    }));
    setLoader(false)
  }

  const validate = (data) => {
    let error = {};
    if (data.title === undefined || data.title === "") {
      error.title = "errors.required_field";
    }
    if (data.type === 0 && (data.departmentId === undefined || data.departmentId === "")) {
      error.departmentId = "errors.required_field";
    }
    if (data.type === 2 && (data.employeeId === undefined || data.employeeId === "")) {
      error.employeeId = "errors.required_field";
    }

    if (data.type === undefined || data.type === "") {
      error.type = "errors.required_field";
    }
    if (data.description === undefined || data?.description?.trim() === "") {
      error.description = "errors.required_field";
    }
    if (data.type === 1 && (data.branchId === undefined || data?.branchId === "")) {
      error.branchId = "errors.required_field";
    }
    if (data.type === 2 && data?.employeeId === "") {
      error.employeeId = "errors.required_field";
    }
    return error;
  };

  useEffect(() => {
    setLoader(true)
    loadDropdownOptions()
  }, [])

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validate(reqData);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);


  const createReq = async () => {
    let error = validate(reqData);
    if (Object.keys(error).length === 0) {
      const formData = new FormData();
      formData.append("title", reqData?.title ?? "");
      if (reqData.type === 0) formData.append("departmentId", reqData?.departmentId ?? "");
      if (reqData.type === 1) formData.append("branchId", reqData?.branchId ?? "");
      if (reqData.type === 2) formData.append("employeeId", reqData?.employeeId ?? "");
      formData.append("type", reqData?.type ?? "");
      formData.append("description", reqData?.description ?? "");

      setLoader(true);
      const res = await postApi(t, "/api/broadcasts", formData);
      setLoader(false);
      if (!!res) {
        setReqData(INITIAL_VALUE);
      }
    } else {
      setErrors(error);
    }
  };
  console.log(reqData);


  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="DashTitle" variant="h4">
              {t("labels.create_broadcast")}
            </Typography>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "40px", paddingInline: "20px" }}>
            <Grid className="AddEmpGenInfo" container rowSpacing={4}>
              {/* title */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.title")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="title"
                    value={reqData?.title}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.title && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.title)}`}
                    </div>
                  )}
                </div>
              </Grid>

              {/* type */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.type")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.type?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="type"
                    value={reqData?.type}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.type && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.type)}`}
                    </div>
                  )}
                </div>
              </Grid>



              {/* department */}
              {reqData?.type === 0 && <>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("titles.department_name")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      className="GenDropdown"
                      option={dropdownOptions?.departments}
                      name="departmentId"
                      value={reqData?.departmentId}
                      callback={(evt) => {
                        setReqData({
                          ...reqData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.departmentId && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.departmentId)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </>}

              {/* branch */}
              {reqData?.type === 1 && <>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.branch_name")} <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      class={"custonInput"}
                      className="GenDropdown"
                      option={dropdownOptions?.branches?.filter(
                        (item) => item?.id !== user?.id
                      )}
                      name="branchId"
                      value={reqData?.branchId}
                      callback={(evt) => {
                        setReqData({
                          ...reqData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.branchId && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.branchId)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </>}

              {/* employee */}
              {reqData?.type === 2 && <>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.employee")} <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>

                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <ComponentSelect
                      class={"custonInput"}
                      className="GenDropdown"
                      option={dropdownOptions?.employees?.filter(
                        (item) => item?.id !== user?.id
                      )}
                      name="employeeId"
                      value={reqData?.employeeId}
                      callback={(evt) => {
                        setReqData({
                          ...reqData,
                          [evt.target.name]: evt.target.value,
                        });
                      }}
                    />
                    {errors?.employeeId && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {`* ${t(errors?.employeeId)}`}
                      </div>
                    )}
                  </div>
                </Grid>
              </>}


              {/* description */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.description")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="description"
                    minRows={4}
                    placeholder={t("placeholder.enter_the_description")}
                    value={reqData?.description}
                    callback={(e) =>
                      setReqData({
                        ...reqData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {/* <ComponentCKEditer
                    style={{ marginRight: "25px" }}
                    value={reqData?.description}
                    name="description"
                    callback={(data) =>
                      setReqData({
                        ...reqData,
                        description: data,
                      })
                    }
                  /> */}
                  {errors?.description && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.description)}`}
                    </div>
                  )}
                </div>
              </Grid>


            </Grid>



            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{ marginTop: "5px" }}
              >

                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    value={t("labels.send")}
                    variant="contained"
                    class="buttonFill FillBtn"
                    callback={() => {
                      createReq();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  )
}

export default BroadcastForm