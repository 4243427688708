import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../../componentGroups/componentButton";
import ComponentModal from "../../../../componentGroups/componectModel";
import http from "../../../../../api/axios";
import moment from "moment";
import { loadOptions } from "../../../../../api/utils/common";
import validate from "../../../../../Hooks/vactions_validaction";
import { getUserHasAccess } from "../../../../../utils/common";
import AuthorityContext from "../../../../../context/AuthorityContext";
import AvailableLeaves from "../../../../componentGroups/AvailableLeaves";
import Comments from "../../../../componentGroups/Comments";
import { minHeight } from "@mui/system";


export default function Hrholidays(props) {
  const { t } = useTranslation();
  const userId = JSON.parse(localStorage.getItem('user'))?.id
  const { setAuthorityModal } = useContext(AuthorityContext);
  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };
  const [holidayTable, setHolidayTable] = useState([]);
  const [errors, setErrors] = useState({});
  const ASSIGN_VALUE = {
    holiday_title: "",
    holiday_period: "",
    start_date: "",
    end_date: "",
    approval_from: [],
    notify_me: "",
  };
  const [holidayState, setHolidayState] = useState(ASSIGN_VALUE);
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_vacation",
    data: [
      {
        lable: "titles.holiday_title",
        field: { type: "text", required: true },
        name: "holiday_title",
      },
      {
        lable: "labels.start_from",
        field: {
          type: "date",
          required: true,
        },
        name: "start_date",
      },
      {
        lable: "labels.to",
        field: {
          type: "date",
          required: true,
        },
        name: "end_date",
      },
      {
        lable: "errors.holiday_period",
        field: {
          type: "text",
          disable: true,
          required: true,
        },
        name: "holiday_period",
      },
      {
        lable: "labels.request_approval_from",
        field: {
          type: "multiple",
          required: true,
          setlable: "name",
          option: [],
        },
        name: "approval_from",
      },
      {
        lable: "errors.notify_me",
        name: "notify_me",
        field: {
          type: "dropdown",
          findLable: "name",
          required: true,
          option: [
            { id: 1, name: "1 days before" },
            { id: 2, name: "2 days before" },
            { id: 3, name: "3 days before" },
            { id: 4, name: "4 days before" },
            { id: 5, name: "5 days before" },
          ],
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setErrors({});
          setHolidayState(ASSIGN_VALUE);
          handleCloseModel();
        },
      },
      {
        value: "labels.save",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addHoliday(value);
        },
      },
    ],
  });

  const handleModel = (val) => {
    if (val == "addNew") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };

  useEffect(() => {
    let s1 = { ...modalData };
    if (holidayState?.start_date != "") {
      s1.data[2].field.min = holidayState?.start_date;
    }
    if (holidayState?.end_date != "" && holidayState?.start_date != "") {
      setHolidayState({
        ...holidayState,
        holiday_period: `${(new Date(holidayState?.end_date) -
          new Date(holidayState?.start_date)) /
          86400000 +
          1
          } days`,
      });
    }
    if (Object.keys(errors).length != 0) {
      let error = validate.vactionValidaction(holidayState);
      setErrors(error);
    }
    setModelData(s1);
  }, [holidayState]);

  useEffect(() => {
    let s1 = { ...modalData };
    setHolidayState({ ...holidayState, end_date: "", holiday_period: "" });
    if (Object.keys(errors).length != 0) {
      let error = validate.vactionValidaction(holidayState);
      setErrors(error);
    }
    setModelData(s1);
  }, [holidayState?.start_date]);

  const addHoliday = async (obj) => {
    console.log(obj, "er");
    let error = validate.vactionValidaction(obj);
    console.log(error, "er");
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      var formdata = new FormData();
      formdata.append("holidayTitle", obj?.holiday_title);
      formdata.append("holidayPeriod", obj?.holiday_period?.split(" days")[0]);
      formdata.append("startDate", obj?.start_date);
      formdata.append("endDate", obj?.end_date);
      obj?.approval_from?.map((op, index) => {
        formdata.append(`approvalFrom[${index}]`, op?.id);
      });
      formdata.append("notifyMe", obj?.notify_me);
      const res = await http.postApi(t, "/api/holidays/create", formdata);
      if (res) {
        setHolidayState({});
        getApiData();
        handleCloseModel();
      }
      props.setLoader(false);
    } else {
      setErrors(error);
    }
  };

  const getApiData = async () => {
    props.setLoader(true);
    let res = await http.getApi(t, `/api/holidays/list`);
    props.setLoader(false);

    setHolidayTable(res);
  };

  const getAllData = async () => {
    let s1 = { ...modalData };
    let employes = await loadOptions(t, 1, undefined, "post", "/api/employee");
    s1.data[4].field.option = employes;
    setModelData(s1);
  };

  useEffect(() => {
    getApiData();
    getAllData();
  }, []);
  console.log(holidayState, "holidayState");
  return (
    <>
      <div className="CommonPd latest_des_box">
        <ComponentModal
          errors={errors}
          modalData={modalData}
          setPerantState={setHolidayState}
          perantState={holidayState}
        />
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="morningText" variant="h4">
              {t("titles.holidays")}
            </Typography>
            <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                if (getUserHasAccess("vacation", 151)) {
                  handleModel("addNew");
                } else {
                  setAuthorityModal(true);
                }
              }}
            />
          </Grid>
          <div className="HolidayBox" sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {holidayTable?.length != 0
                ? holidayTable?.map((op, index) => (
                  <Grid item xs={12} md={3} key={index}>
                    <div className="holiBox">
                      <div className="holidatTitle">
                        <Typography variant="h5">{op.holidayTitle}</Typography>
                      </div>
                      <div className="holidaycontent">
                        <Typography variant="subtitle2">
                          {moment(op.startDate, "YYYY-MM-DD").format("dddd")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {moment(op.startDate, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                ))
                : ""}
            </Grid>
            {/* <div className="HolidayBox" sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="HolidayBox" sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="holiBox">
                  <div className="holidatTitle">
                    <Typography variant="h5">{t("titles.new_year")}</Typography>
                  </div>
                  <div className="holidaycontent">
                    <Typography variant="subtitle2">Sunday</Typography>
                    <Typography variant="body2" color="text.secondary">
                      1 Jan 2022
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
         */}
          </div>
        </Grid>
      </div>
      <AvailableLeaves empId={userId} />
      <Grid className="dashTopGrid" style={{
        marginBottom: "2rem"
      }}>
        <Comments setLoader={props.setLoader} viewId={`VAC-0`} />
      </Grid>
    </>
  );
}
