import React, { useState, useEffect, useContext } from "react";
import "../style.css";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import http from "../../../../api/axios";
import CommentsView from "./CommentsView";
import LoadingContext from "../../../../context/LoaderContext";
import { useTranslation } from "react-i18next";

const BodDecisionDetails = ({ ...props }) => {
  const { t } = useTranslation();
  const { id } = props?.match?.params;
  const { setLoader } = useContext(LoadingContext);
  const [data, setData] = useState();

  const getDecisionDetails = async () => {
    setLoader(true);
    const res = await http.getApi(
      t,
      `/api/meeting/meeting-decision/comments/${id}`
    );
    setLoader(false);
    if (!!res) {
      setData(res);
    }
  };

  useEffect(() => {
    if (id) {
      getDecisionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "0 !important",
            }}
          >
            <Grid className="gridLeft">
              <div className="detailsBox" style={{ marginLeft: "10px" }}>
                <Typography className="DashTitle" variant="h4">
                  {data?.title}
                </Typography>
              </div>
              <div style={{ marginLeft: "10px", marginTop: "15px" }}>
                <Typography variant="div" className="details">
                  {data?.description}
                </Typography>
              </div>
            </Grid>
            <CommentsView comments={data?.comments} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BodDecisionDetails;
