import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../componentGroups/componentButton";
import GenericModal from "../../componentGroups/genericModal";
import http, { baseURL } from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import { encode } from "string-encode-decode";
import "./style.css";

const MeetingValidationModal = ({
  open,
  id,
  getPendingRequests,
  onCLose,
  meeting_id,
}) => {
  const { t } = useTranslation();
  const { loader, setLoader } = useContext(LoadingContext);
  const [meetingDecisionData, setMeetingDecisionData] = useState({});

  const handleCloseModel = () => {
    setMeetingDecisionData({});
    getPendingRequests && getPendingRequests();
    onCLose();
  };

  const handleValidate = async (validateId) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("meeting_mins_id", id);
    formData.append("validation", validateId);
    const res = await http.postApi(
      t,
      "/api/meeting/meeting-mins/validate",
      formData
    );
    setLoader(false);
    if (res?.status && validateId === 1) {
      handleSignature();
    }
    handleCloseModel();
  };

  // /*
  //  get the meeting validate mins data from  api
  // /*
  const getMeetingMinsData = async () => {
    if (id) {
      setLoader(true);
      let meetingMins = await http.getApi(
        t,
        `/api/meeting/meeting-mins/view/${id}`
      );
      let meetData = {
        title: meetingMins?.title ?? "",
        documents: meetingMins?.documents ?? [],
        form_documents: meetingMins?.form_documents ?? [],
        decisions: meetingMins?.decisions ?? [],
        departments: meetingMins?.departments ?? [],
      };
      setMeetingDecisionData(meetData);
      setLoader(false);
    }
  };

  const handleSignature = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let currentDate = new Date();
    let timezoneName = currentDate.toLocaleString("en-US", {
      timeZoneName: "long",
    });
    let en = encode(
      `Name : ${user?.name} , userCode : ${user?.code} , Department : ${user?.department} Date&Time:${timezoneName}`
    );
    const form_data = new FormData();
    form_data.append("userId", user.id);
    form_data.append("meeting_id", meeting_id);
    form_data.append("sign", en);
    setLoader(true);
    await http.postApi(
      t,
      `/api/meeting/meeting-decision/addSignature`,
      form_data,
      ""
    );
    setLoader(false);
  };

  useEffect(() => {
    getMeetingMinsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <GenericModal
      title={"titles.meeting_validation"}
      open={open}
      enableCloseIcon={true}
      onClose={handleCloseModel}
    >
      <div className="scroll-auto ModalContent" style={{ minHeight: "250px" }}>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.meeting_title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              <p>{meetingDecisionData?.title}</p>
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.department")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              <p>
                {meetingDecisionData?.departments?.map((dep) => {
                  return <span>{dep?.departmentName}</span>;
                })}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.meeting_decision")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
                {meetingDecisionData?.decisions?.map((dec, index) => {
                  return (
                    <li>
                      {index + 1}. {dec?.content}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.document")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="dashboard-meetingDetails">
              {meetingDecisionData?.documents?.map((doc) => {
                return (
                  <p
                    onClick={() => {
                      window.open(`${baseURL}/${doc?.path}`, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {doc?.name}
                  </p>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.validate")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => {
                handleValidate(1);
              }}
              disabled={loader}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t("labels.notvalidate")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn ml-10"}
              callback={() => {
                handleValidate(0);
              }}
              disabled={loader}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default MeetingValidationModal;
