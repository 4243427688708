import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide, Typography } from "@mui/material";
import SignaturePad from "react-signature-canvas";
import http from "../../../../../../api/axios";
import Svg from "../../../../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import { encode, decode } from "string-encode-decode";
import { toast } from "react-toastify";

export default function Sign({
  Id,
  code,
  getApiData,
  setLoader,
  data,
  prveData,
}) {
  const { t, i18n } = useTranslation();

  const handleClickOpen = async () => {
    if (JSON.stringify(data) == JSON.stringify(prveData)) {
      let en = encode(
        `Name : ${code?.userName} , userCode : ${code?.userCode} , Department : ${code?.department}`
      );
      const form_data = new FormData();
      form_data.append("userId", code.userId);
      form_data.append("userType", code.user_type);
      form_data.append("sign_id", code.id);
      form_data.append("sign", en);
      form_data.append("fieldId", Id);
      setLoader(true);
      let res = await http.postApi(
        t,
        `/api/form_doc/addSignture`,
        form_data,
        "labels.signature_added_successfully"
      );
      setLoader(false);
      getApiData();
    } else {
      toast.warning(t("labels.please_save_your_changes"));
    }
  };
  return (
    <div className="Assred">
      <div className="iconTool " onClick={handleClickOpen}>
        <Svg type="addTool" />
        <Typography variant="h6" className="slideText">
          {t("forms.signature")}
        </Typography>
      </div>
    </div>
  );
}
