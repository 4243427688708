import React, { useState, useEffect, useContext } from "react";
import "../style.css";
import { Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";

const BodMeetingCard = () => {
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [bodMeetings, setBodMeetings] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [bodMeetingsToView, seBodMeetingsToView] = useState([]);

  const getBodMeetings = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/meeting/meeting-decision/self/list");
    setLoader(false);
    if (!!res && res?.length > 0) {
      setBodMeetings(res);
    }
  };

  useEffect(() => {
    getBodMeetings();
  }, []);

  useEffect(() => {
    if (showAll) {
      seBodMeetingsToView(bodMeetings);
    } else {
      if (bodMeetings?.length > 0) {
        if (bodMeetings?.length > 3) {
          let sliced = [...bodMeetings]?.slice(0, 3);
          seBodMeetingsToView(sliced);
        } else {
          seBodMeetingsToView(bodMeetings);
        }
      }
    }
  }, [showAll, bodMeetings]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0 !important",
          }}
        >
          <Grid className="gridLeft">
            <Typography className="DashTitle" variant="h4">
              {t("partner.bod_head")}
            </Typography>
          </Grid>

          {bodMeetings?.length > 3 && (
            <Grid className="gridLeftbtn">
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank"}
                callback={() => setShowAll(!showAll)}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0 !important",
          }}
          spacing={2}
        >
          {bodMeetingsToView?.map((item) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={item?.id}>
              <Card className="BodMain">
                <CardContent>
                  <div className="calenderBox">
                    <div
                      className="flex-align-start"
                      style={{ wordBreak: "break-all" }}
                    >
                      <div
                        className="cardContant"
                        style={{
                          height: "150px",
                          overflowY: "auto",
                        }}
                      >
                        <Typography variant="h6" className="folderSection1">
                          {" "}
                          {item?.title}{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="textFont "
                        >
                          {item?.description}
                        </Typography>
                      </div>
                    </div>
                    {/* <span
                      style={{ minWidth: "fit-content", flex: "0 0 20%" }}
                      className="ml-10"
                    >
                      {moment(item?.start_time, "h:mm:ss").format("h:mm a")}
                    </span> */}
                  </div>
                  <div className="btnContainer  folderSection">
                    <ComponentButton
                      value={t("labels.view_details")}
                      variant={"contained"}
                      class={"buttonFill"}
                      callback={() => {
                        history.push({
                          pathname: "/bodDecision/meeting-detail",
                          state: { id: item?.meeting_id },
                        });
                      }}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {bodMeetings?.length === 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <div className="noDataFound">
                {t("labels.no_requests_available")}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default BodMeetingCard;
