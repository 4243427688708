import React from "react";
import { ContentState, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "draft-js-mention-plugin";
// import mentions from "./mentions";
import editorStyles from "./editorStyles.module.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";

class TextInputWithMention extends React.Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin();
  }

  state = {
    editorState: EditorState.createWithContent(
      ContentState.createFromText(this.props.inputText || '')
    ),
    suggestions: this.props.mentions,

  };

  componentDidUpdate(prevProps) {
    if (this.props.commentPushed === true) {
      this.setState({
        editorState: EditorState.createEmpty()
      });
    }
    this.props.setCommentPushed(false)
  }

  onChange = editorState => {
    this.setState({ editorState });
    const contentState = editorState.getCurrentContent();
    // get all text typed in text area
    const plainText = contentState.getPlainText();
    this.props.setInputText(plainText)

    // get all mentioned users
    const raw = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    this.props.setMentionedUsers(mentionedUsers)
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.props.mentions)
    });
  };



  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    // console.log(this.state.textData);

    return (

      <div className={editorStyles.editor}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          placeholder={this.props.placeholder ?? 'Add a comment...'}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}

        />
      </div>

    );
  }
}

export default TextInputWithMention;

// export default function TextInputWithMention(props) {

//   const [editorState, setEditorState] = useState(() =>
//     EditorState.createEmpty()
//   );
//   const [open, setOpen] = useState(false);
//   const [suggestions, setSuggestions] = useState(props.mentions);

//   const editor = React.useRef(null);


//   const { MentionSuggestions, plugins } = useMemo(() => {
//     const mentionPlugin = createMentionPlugin();
//     // eslint-disable-next-line no-shadow
//     const { MentionSuggestions } = mentionPlugin;
//     // eslint-disable-next-line no-shadow
//     const plugins = [mentionPlugin];
//     return { plugins, MentionSuggestions };
//   }, []);

//   const onOpenChange = useCallback((_open) => {
//     setOpen(_open);
//   }, []);
//   const onSearchChange = useCallback(({ value }) => {
//     setSuggestions((prev) => defaultSuggestionsFilter(value, prev));
//   }, []);

//   return (
//     // <div
//     //   style={{ border: "1px solid black", minHeight: "6em", cursor: "text" }}
//     //   onClick={focusEditor}
//     // >
//     //   <Editor
//     //     ref={editor}
//     //     editorState={editorState}
//     //     onChange={setEditorState}
//     //     placeholder="Write something!"
//     //   />
//     // </div>
//     <div
//       className={editorStyles.editor}
//       onClick={() => {
//         editor.current?.focus();
//       }}
//     >
//       <Editor
//         editorKey={'editor'}
//         editorState={editorState}
//         onChange={setEditorState}
//         plugins={plugins}
//         ref={editor}
//       />
//       <MentionSuggestions
//         open={open}
//         onOpenChange={onOpenChange}
//         suggestions={suggestions}
//         onSearchChange={onSearchChange}
//         onAddMention={(e) => {
//           // get the mention object selected
//           console.log(e);
//         }}
//       />
//     </div>
//   )
// }


