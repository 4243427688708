import React from "react";
import "./UploadForm.css";
const LargeText = (props) => {
  const width = props.width ? props.width : "80%";
  const handleInputChange = props.onChange
    ? props.onChange
    : (event) => props.setDesc(event.target.value);
  return (
    // <div className={`input-container input-text-large ${props.classes}`}>
    //   {props.label && (
    //     <div className='input-label pt-[2rem]' style={{
    //       width: props.labelWidth ? props.labelWidth : ''
    //       , alignSelf: 'flex-start'
    //     }} >
    //       <p >{props.label}</p>
    //     </div>
    //   )}

    <div
      className={props.error ? "withError" : ""}
      style={{
        // width: !props.label ? '100%' : width,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        flex: 1,
      }}
    >
      <textarea
        placeholder={props.placeholder}
        value={props.desc}
        required={props.required ? props.required : false}
        style={{
          direction: props.direction ? "rtl" : "ltr",
          height: props.height ? props.height : "15rem",
          width: "96%",
          borderRadius: "7px",
          border: props.error ? "1px solid #ff0101" : "1px solid #d5dbe1",
          padding: "1rem",
          resize: "none",
        }}
        onChange={handleInputChange}
        onBlur={props.onBlur}
        name={props.inputName}
        disabled={props.disabled}
      />
      {props.error && (
        <p
          className=""
          style={{
            color: "#ff0101",
            fontSize: "13px",
            marginBottom: "-5px",
            // marginInlineStart: "16%"
          }}
        >
          *{props.error}
        </p>
      )}
    </div>

    // </div>
  );
};

export default LargeText;
