/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import http from "../../../api/axios";
import { useTranslation } from "react-i18next";
import AuthorityContext from "../../../context/AuthorityContext";
import LoadingContext from "../../../context/LoaderContext";
import useFormatTime from "../../../Hooks/useFormatTime";

export default function LoanInstallments({ loanId }) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { globalToLocal } = useFormatTime();
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [column, setColumn] = useState([
    {
      lable: "InstallMent No",
      name: "id",
      align: "left",
      hide: true,
    },
    {
      lable: "date",
      name: "date",
      align: "left",
      hide: true,
    },
    {
      lable: "Amount",
      name: "amount",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.status",
      name: "status",
      width: "10px",
      align: "left",
    },
  ]);

  const [data, setData] = useState({
    title: "Installments",
    page: "Installments",
    hideSearch: true,
    buttonGroup: [],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/employee/ongoing-loan/${loanId}?page=${
        pageIndex + 1
      }&size=${pageSize}`
    );
    setTotalrecords(res?.data_count);
    const loans = res?.data?.map((item) => {
      return {
        ...item,
        date: globalToLocal(item?.date, "DD-MM-YYYY"),
      };
    });
    setRecords(loans || []);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size]);

  return (
    <>
      <div className="CommonPd latest_des_box">
        <ComponentTable
          rows={records}
          columns={column}
          pageData={data}
          page={page}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          totalRecords={totalRecords}
          defaultstatus={false}
        />
      </div>
    </>
  );
}
