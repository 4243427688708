import React, { useState, useEffect } from "react";
import { Grid, Typography, Chip, Stack, Autocomplete, TextField } from "@mui/material";
import Info from "../../../../assets/images/info.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../../componentGroups/componectModel";
import http from "../../../../api/axios";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import ComponentUpload from "../../../componentGroups/componentUpload";
import Svg from "../../../../assets/svg/svg";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
export default function ProjectDoc({
  errors,
  projectState,
  setProjectState,
  removeDoc,
}) {
  const { t, i18n } = useTranslation();
console.log(projectState?.documents,"lo");
  return (
    <div className="ProjectTabDoc">

      <Grid container className="AddEmpGenInfo doucment" spacing={3}>

        <Grid item xs={12} md={2}>
          <Typography variant="body1" className="customLabel">
            {t("labels.documents")}
          </Typography>
        </Grid>
        <Grid className="fixedV" item xs={12} md={6}>
          {projectState?.documents?.map((op, index) => (
            <div key={index} className="docClose">
              <span className="pdfIconsvg"> <Svg type="pdf" /></span>
              <span className="pdftextm">  {op.name}</span>
              <div className="closeIconRed" onClick={() => removeDoc(index)}>
                <HighlightOffIcon />
              </div>
            </div>))}
        </Grid>
        <Grid className="uploadDocBtn" item xs={12} md={4}>
          <ComponentUpload
            lable={t("labels.upload_files")}
            callback={(e) => {
              setProjectState({
                ...projectState,
                documents: [...projectState.documents, e.target.files[0]],
              });
            }}
          />
        </Grid>
        {errors?.documents && <span className="validateError">{errors?.documents}</span>}
      </Grid>
    </div>
  );
}
