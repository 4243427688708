import React, { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import "../style.css";
import http from "../../../../api/axios";
import Svg from "../../../../assets/svg/svg";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import auth from "../../../../services/auth";
import { getUserHasAccess } from "../../../../utils/common";
import useFormatTime from "../../../../Hooks/useFormatTime";
import { getDuration } from "../../../../utils/common";
import { loadOptions } from "../../../../api/utils/common";

export default function MeetingDetails(props) {
  const { t } = useTranslation();
  const { globalToLocal } = useFormatTime();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  location?.state?.id &&
    localStorage.setItem("meetingId", JSON.stringify(location?.state?.id));
  const Id = JSON.parse(localStorage.getItem("meetingId"));
  const [meetData, setMeetData] = useState();
  const [validated, setValidated] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState({
    allBranches: [],
    allDepartments: [],
  });

  const getApiData = async () => {
    if (Id) {
      let res = await http.getApi(t, `/api/meeting/view/${Id}`);
      let allDepartments = await loadOptions(
        t,
        "",
        null,
        "get",
        "/api/department"
      );
      let allBranches = await loadOptions(
        t,
        "",
        null,
        "post",
        "/api/branch/details"
      );
      setDropDownOptions({
        allBranches: allBranches,
        allDepartments: allDepartments,
      });
      if (!!res) {
        let str = res?.meeting_mins || "";
        if (res?.meeting_mins) {
          str = str.replace(/^<p>&nbsp;<\/p>/, "");
        }
        setMeetData({ ...res, meeting_mins: str });
        const validatedBy = res?.validationsAccepted?.filter(
          (item) => item?.validation === 1
        )?.length;
        setValidated(validatedBy === res?.guests?.length + 1);
      }
    }
    props.setLoader(false);
  };

  const openPreview = async (param) => {
    let res = http.getApi(t, `${param}`);
  };

  React.useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  function getTimeDiff(start, end) {
    let duration = "";
    if (end && start) {
      duration = getDuration(
        moment(globalToLocal(start), "YYYY-MM-DD HH:mm:ss"),
        moment(globalToLocal(end), "YYYY-MM-DD HH:mm:ss")
      );
    }

    return duration;
  }

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={9}>
            <Typography variant="h6" className="morningText">
              {t("labels.meeting_details")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!validated && (
              <ComponentButton
                value={t("labels.edit")}
                variant={"outlined Mright"}
                class={"buttonBlank"}
                callback={() => history.push(`/edit/meeting/${meetData?.id}`)}
                sx={{ paddingRight: "0px !important" }}
              />
            )}
            {getUserHasAccess("meeting", 169) && (
              <ComponentButton
                value={t("labels.add_meeting_mins")}
                variant={"outlined"}
                class={"buttonFill"}
                callback={() =>
                  history.push(`/meetingDetails/meeting-mins/${meetData?.id}`)
                }
                sx={{ paddingRight: "0px !important" }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="customLabel2">
              {t("labels.meeting_title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            <Typography variant="body2" className="meeting">
              {meetData?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="customLabel2">
              {t("labels.date")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="GenDropdown1">
            <div className="groupMeeting1">
              <Typography variant="body2" className="meeting-data">
                {moment(meetData?.date, "YYYY-MM-DD").format("MMM DD, YYYY")}
              </Typography>
              <Typography variant="body2" className="meeting-data">
                {globalToLocal(meetData?.start_time, "h:mm a")}
              </Typography>
              <Typography variant="body2" className="meeting-data">
                {t("labels.to")}
              </Typography>
              <Typography variant="body2" className="meeting-data">
                {globalToLocal(meetData?.end_time, "h:mm a")}
              </Typography>
            </div>
            <div className="groupMeeting2">
              <Typography variant="body2" className="meeting-data">
                {" "}
                {t("labels.time_zone")}
              </Typography>
              <Typography variant="body2" className="meeting-data">
                GMT {t("labels.time_zone")}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="customLabel2">
              {t("labels.location")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            <Typography variant="body2" className="meeting">
              {meetData?.location == "1" ? "Online" : "Offline"}
            </Typography>
            {/* <Typography variant='body2' className="meeting" >{moment(meetData?.start_time)}</Typography> */}
          </Grid>
        </Grid>
        {meetData?.location == "1" ? (
          <Grid container sx={{ padding: "10px" }}>
            <Grid className="customLabel2" item xs={12} md={2}>
              <Typography variant="body2" className="customLabel2">
                {t("labels.meeting_url")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body2" className="meeting">
                {meetData?.url}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container sx={{ padding: "10px" }}>
            <Grid className="" item xs={12} md={2}>
              <Typography variant="body2" className="customLabel2">
                {t("labels.branch")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} className="groupMeeting">
              <Typography variant="body2" className="meeting">
                {
                  dropDownOptions?.allBranches?.find(
                    (op) => op.id == meetData?.branch_id
                  )?.branchName
                }
              </Typography>
              <Typography variant="body2" className="meeting">
                {
                  dropDownOptions?.allBranches?.find(
                    (op) => op.id == meetData?.branch_id
                  )?.branchCode
                }
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="">
              {t("titles.departments")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            {meetData?.departments.map((op, index) => (
              <Typography variant="body2" className="meeting" key={index}>
                {op.departmentName}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="customLabel2">
              {t("labels.notification")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            <Typography variant="body2" className="meeting">
              {meetData?.notification == "1"
                ? t("titles.in_system")
                : t("titles.via_Email")}
            </Typography>
            <Typography variant="body2" className="meeting">
              {getTimeDiff(meetData?.reminder, meetData?.start_time)}
            </Typography>
            <Typography variant="body2" className="meeting">
              {t("labels.before_meeting")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="">
              {t("labels.guests")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            {meetData?.guests.map((op, index) => (
              <Typography variant="body2" className="meeting" key={index}>
                {op.name}
              </Typography>
            ))}
          </Grid>
        </Grid>
        {meetData?.meeting_mins && (
          <Grid container sx={{ padding: "10px" }}>
            <Grid className="customLabel2" item xs={12} md={2}>
              <Typography variant="body2" className="customLabel2">
                {t("labels.meeting_minuts")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} className="groupMeeting">
              <Typography variant="body2" className="meeting">
                <div
                  dangerouslySetInnerHTML={{ __html: meetData?.meeting_mins }}
                />
              </Typography>
            </Grid>
          </Grid>
        )}

        {meetData?.meeting_decisions?.length > 0 && (
          <Grid container sx={{ padding: "10px" }}>
            <Grid className="customLabel2" item xs={12} md={2}>
              <Typography variant="body2" className="customLabel2">
                {t("labels.meeting_decision")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} className="groupMeeting">
              <Typography variant="body2" className="meeting">
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {meetData?.meeting_decisions
                    ?.filter((el) => el.checked === 1)
                    .map((item, index) => (
                      <li key={index}>
                        {index + 1}. {item?.content}
                      </li>
                    ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="customLabel2">
              {t("labels.description")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            <Typography variant="body2" className="meeting">
              {meetData?.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "10px" }}>
          <Grid className="customLabel2" item xs={12} md={2}>
            <Typography variant="body2" className="">
              {t("labels.documents")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className="groupMeeting">
            {meetData?.documents.map((op, index) => (
              <a href={`${http.baseURL}/${op.path}`} download target={"_blank"}>
                <Typography variant="body2" className="meeting">
                  {" "}
                  <Svg type="document" /> {op.name}
                </Typography>
              </a>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
