import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTable from "../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import AddIcon from "@mui/icons-material/Add";
import http, { baseURL } from "../../../api/axios";
import validate from "../../../Hooks/employee_validaction";
import moment from "moment";
import auth from "../../../services/auth";
import useFormatTime from "../../../Hooks/useFormatTime";

const EmpAttendance = ({ dropDownOptions, setLoader, empState, Id }) => {
  const { t } = useTranslation();
  const { globalToLocal } = useFormatTime();
  const user = auth.getUser("user");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [clockStatus, setClockStatus] = useState("");
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [leaveState, setLeaveState] = useState({
    employee: empState?.name,
    employee_id: empState?.id,
  });
  const [errors, setErrors] = useState({});
  const [filterData, setFilterData] = useState({
    filter: false,
    dateFilter: moment(new Date()).format("YYYY-MM"),
  });
  const column = [
    // { lable: "", name: "checkbox", width: "10px", align: "center" },
    {
      lable: "labels.date",
      name: "date",
      type: "date",
      minWidth: "60px",
      align: "center",
    },
    {
      lable: "labels.check_in",
      name: "checkIn",
      type: "dropDown",
      options: dropDownOptions?.leavePeriod,
      minWidth: "60px",
      align: "center",
    },
    {
      lable: "labels.check_out",
      name: "checkOut",
      hide: true,
      minWidth: "60px",
      align: "center",
    },
    {
      lable: "labels.working_hours",
      name: "hoursSpent",
      type: "dropDown",
      options: dropDownOptions?.approvedStatus,
      minWidth: "60px",
      align: "center",
    },
    // {
    //     lable: "labels.attendance",
    //     name: "approval",
    //     type: "dropDown",
    //     options: dropDownOptions?.approvedStatus,
    //     minWidth: "60px",
    //     align: "center",
    // },
  ];
  const [data, setData] = useState({
    title: "tableTitle.4.branches",
    // infoURL: "/branchInfo",
    hideTitle: true,
    showReplaceTitle: { type: "month", name: "dateFilter" },
    showReplaceSearch: { type: "attendance", name: "dateFilter" },
    page: "Branches",
    handlExport: (page, size, filterData) =>
      handleExport(page, size, filterData),
    hideSearch: true,
    // search: search,
    // setSearch: setSearch,
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => { },
      },
    ],
  });

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.leaveValidate(leaveState);
      setErrors(error);
    }
  }, [leaveState]);

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    var formdata = new FormData();
    formdata.append("userId", user?.id);
    let res = await http.getAllDataApi(
      t,
      `/api/employee/auth/attendence?date=${filterData?.dateFilter}&page=${pageIndex + 1
      }&size=${pageSize}`
    );
    let res1 = await http.getAllDataApi(
      t,
      `/api/employee/attendence/statistics?date=${filterData?.dateFilter}`,
      formdata
    );
    let clock = await http.getApi(t, "/api/employee/currentattendence/status");
    const data = res?.data?.map((item) => {
      return {
        ...item,
        checkIn:
          item?.checkIn !== "Invalid date"
            ? globalToLocal(item?.checkIn, "DD-MM-YYYY h:mm:ss a")
            : "-",
        checkOut:
          item?.checkOut !== "Invalid date"
            ? globalToLocal(item?.checkOut, "DD-MM-YYYY h:mm:ss a")
            : "-",
      };
    });
    setRows(data);
    setClockStatus(clock?.status);
    setFilterData({
      ...filterData,
      filter: false,
      average_checkIn: res1?.data?.average_checkIn,
      average_checkOut: res1?.data?.average_checkOut,
      average_count: res1?.data?.average_count,
      average_offDays: res1?.data?.average_offDays,
    });
    setLeaveState({ ...leaveState, employee: empState?.name });
    setTotalrecords(res?.data_count);
    setLoader(false);
  };

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    let formdata = new FormData();
    formdata.append("userId", user?.id);
    const res = await http.getAllDataApi(
      t,
      `/api/employee/attendence/export?date=${filterData?.dateFilter}`,
      formdata
    );
    if (!!res?.link) {
      const url = `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  const addjustClockType = async () => {
    setLoader(true);
    var formdata = new FormData();
    formdata.append("clockType", clockStatus == 1 ? 0 : 1);
    formdata.append("date", new Date());
    let res = await http.postApi(
      t,
      `/api/employee/timer/counter`,
      formdata,
      clockStatus == 1 ? "Clock out " : "Clock In "
    );
    getApiData(page, size);
    setLoader(false);
  };
  return (
    <div>
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              padding: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="morningText" variant="h4">
              {t("titles.employee_attendance_sheet")}{" "}
            </Typography>
            {/* <ComponentSwitch
              value={clockStatus == 1 ? true : false}
              rightLabel={"Clock IN"}
              leftLabel={"Clock Out"}
              handleChange={(evt) => {
                addjustClockType(evt.target.checked);
              }}
            /> */}
          </Grid>
          <ComponentTable
            rows={rows}
            columns={column}
            filterData={filterData}
            setFilterData={setFilterData}
            page={page}
            // setSearch={setSearch}
            // search={search}
            setPage={setPage}
            rowsPerPage={size}
            setRowsPerPage={setSize}
            pageData={data}
            totalRecords={totalRecords}
            // selected={selectedData}
            // handleSelected={handleSelected}
            // handleAllselect={handleAllselect}
            // handleDelete={handleDelete}
            enableReset={true}
            resetdata={{
              filter: false,
              dateFilter: moment(new Date()).format("YYYY-MM"),
            }}
          />
        </Grid>
      </div>
    </div>
  );
};
export default EmpAttendance;
