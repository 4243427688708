import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../../componentGroups/componectModel";
import http from "../../../../api/axios";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import { loadOptions } from "../../../../api/utils/common";
import axios from "axios";
import validate from "../../../../Hooks/client_validation";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

const INITIAL_VALUES = {
  name: "",
  phoneNo: "",
  email: "",
  clientType: "",
  relationshipType: "",
  jobTitle: "",
  contractNumber: "",
  contractDate: "",
  branchId: "",
  upload_cotract: "",
  procuration: "",
};
export default function BranchesClient(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t, i18n } = useTranslation();
  const { branchId } = useParams();
  const Id = branchId;
  const [branchClientData, setBranchClientData] = useState();
  const [branchClient, setBranchClient] = useState({});
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [search, setSearch] = useState("");
  const [column, setColumn] = useState([
    { lable: "labels.name", name: "name", width: "10px", align: "left" },
    { lable: "labels.code", name: "code", width: "10px", align: "left" },
    {
      lable: "labels.phone_number",
      name: "phoneNo",
      width: "10px",
      align: "left",
    },
    { lable: "labels.email", name: "email", width: "10px", align: "left" },
    { lable: "labels.status", name: "status", width: "10px", align: "left" },
  ]);
  const [data, setData] = useState({
    title: "titles.branch_clients",
    infoURL: `/branches/branchInfo/${Id}/edit-client`,
    hasVariable: true,
    page: "Branches",
    buttonGroup: [
      {
        title: "titles.add_new",
        type: "add",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("branch", 68)) {
            handleModel();
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });

  const [modalData, setmodelData] = useState({
    model: false,
    title: "titles.add_new_client",
    data: [
      {
        lable: "labels.clients_type",
        field: {
          type: "dropdown_plus",
          required: true,
          findLable: "option",
          option: [],
        },
        button: {
          name: "client_add",
          callback: (val) => {
            addDropdownOptions(val?.client_add, 3);
          },
        },
        name: "clientType",
      },
      {
        lable: "labels.relationship_type",
        field: {
          type: "dropdown_plus",
          required: true,
          findLable: "option",
          option: [],
        },
        button: {
          name: "relationship_add",
          callback: (val) => {
            addDropdownOptions(val?.relationship_add, 4);
          },
        },
        name: "relationshipType",
      },
      {
        lable: "labels.branch",
        field: {
          type: "dropdown",
          required: true,
          findLable: "branchName",
          option: [],
          disable: true,
        },
        name: "branchId",
      },
      { lable: "labels.client_name", field: { type: "text" }, name: "name" },
      {
        lable: "labels.phone_number",
        field: { type: "contact", required: true },
        name: "phoneNo",
      },
      {
        lable: "labels.email",
        field: { type: "text", required: true },
        name: "email",
      },
      {
        lable: "labels.job",
        field: { type: "text", required: true },
        name: "jobTitle",
      },
      {
        lable: "labels.procuration",
        field: { type: "text", required: false },
        name: "procuration",
      },
      // {
      //   lable: "Address",
      //   field: { type: "text", required: true },
      //   name: "address",
      // },
      {
        lable: "labels.contract_no",
        field: { type: "text", required: true },
        name: "contractNumber",
      },
      {
        lable: "labels.contract_date",
        field: { type: "date", required: true },
        name: "contractDate",
      },
      {
        lable: "labels.upload_contract",
        field: { type: "file", required: true, accept: "image/*" },
        name: "upload_cotract",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setBranchClient(INITIAL_VALUES);
          handleCloseModel();
        },
      },
      {
        value: "labels.create",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addClient(val);
        },
      },
    ],
  });

  const addDropdownOptions = async (
    val,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    props.setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", 1);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        getDropDownOptions(true);
      }
    }
    props.setLoader(false);
  };

  const getDropDownOptions = (val = false) => {
    props.setLoader(true);
    axios
      .all([
        loadOptions(t, "relationshipType", 4),
        loadOptions(t, "clientType", 3),
        loadOptions(t, "", null, "post", "/api/branch/details"),
      ])
      .then((res) => {
        let s1 = { ...modalData, model: val };
        s1.data[0].field.option = res[1];
        s1.data[1].field.option = res[0];
        s1.data[2].field.option = res[2];
        setBranchClient({
          ...branchClient,
          branchId: props.branchData?.id,
        });
        setmodelData(s1);
        props.setLoader(false);
      });
  };

  useEffect(() => {
    getDropDownOptions();
  }, []);

  const handleCloseModel = () => {
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    setmodelData({
      ...modalData,
      model: true,
    });
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    let res = await http.getAllDataApi(
      t,
      `/api/branch/client/all/${Id}/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count || res?.data?.length);
    setBranchClientData(res?.data);
  };

  React.useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.clientValidate(branchClient);
      setErrors(error);
    }
  }, [branchClient]);

  const addClient = async (obj) => {
    let error = validate.clientValidate(obj);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("phoneNo", obj.phoneNo);
      formData.append("email", obj.email);
      formData.append("clientType", obj.clientType);
      formData.append("relationshipType", obj.relationshipType);
      formData.append("jobTitle", obj.jobTitle);
      formData.append("procuration", obj.procuration);
      formData.append("contractNumber", obj.contractNumber);
      formData.append("contractDate", obj.contractDate);
      formData.append("branchId", obj.branchId);
      formData.append("clientNo", obj.clientNo);
      formData.append("uploadcontract", obj?.upload_cotract);
      formData.append("address", "obj?.upload_cotract"); // to beremoved
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.postApi(t, "/api/client/create", formData);
      props.setLoader(false);
      if (!!res) {
        handleCloseModel();
        getApiData();
      }
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setBranchClient}
        perantState={branchClient}
      />
      <ComponentTable
        rows={branchClientData}
        columns={column}
        pageData={data}
        page={page}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
      />
    </>
  );
}
