import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cookie from 'js-cookie';

const Greeting = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));

  const currentLanguageCode = cookie.get('i18next') || 'en';
  const currentDate = new Date();

  function generateGreetings() {
    let currentHour = moment().format('HH');
    if (currentHour >= 3 && currentHour < 12) {
      return 'titles.good_morning';
    } else if (currentHour >= 12 && currentHour < 15) {
      return 'titles.good_afternoon';
    } else if (currentHour >= 15 || currentHour < 3) {
      return 'titles.good_evening';
    }
  }

  const formattedDate = currentDate.toLocaleTimeString(currentLanguageCode, {
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className='CommonPd'>
      <Grid
        className='dashTopGrid'
        spacing={3}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Grid>
          <Typography variant='h6' className='morningText'>
            {t(generateGreetings())}, {user?.name}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant='span' className='morningTextDate'>
            {' '}
            {formattedDate}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Greeting;
