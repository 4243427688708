import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import http from "../../../../../api/axios";
import { Typography } from "@mui/material";
import Svg from "../../../../../assets/svg/svg";
import ComponentButton from "../../../../componentGroups/componentButton";
import ComponentModal from "../../../../componentGroups/componectModel";
import { getUserHasAccess } from "../../../../../utils/common";
import AuthorityContext from "../../../../../context/AuthorityContext";
import Sign from "../Signature";
import QRCode from "react-qr-code";
import { decode } from "string-encode-decode";
import DecisionItem from "./DecisionItem";
import MeetingDecisionPrint from "./decisionPrintTemplate";
import PrintTemplate from "./printTemplate";

export default function Decision({ setLoader, Id, meetingData }) {
  const { t } = useTranslation();
  const meeting_id = Id.id;
  const user = JSON.parse(localStorage.getItem("user"));
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [errors, setErrors] = useState({});
  const [meetingSignature, setMeetingSignature] = useState([]);
  const [initialDecisions, setInitialDecisions] = useState([]);
  const [decisionState, setDecisionState] = useState({
    decisionData: [],
    decision: "",
  });
  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_new_decision",
    data: [
      {
        lable: "labels.decision",
        field: { type: "textArea", minRows: 6, required: true },
        name: "decision",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addDecision(value);
        },
      },
    ],
  });
  //Intial set To true , so a non guest can't modify the meeting decisions
  const [disableUpdates, setDisableUpdates] = useState(false);

  const decisionValidate = (decisionState) => {
    let error = {};
    if (
      decisionState?.decision?.trim() == "" ||
      decisionState?.decision == undefined
    ) {
      error.decision = "errors.meet_decision";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = decisionValidate(decisionState);
      setErrors(error);
    }
  }, [decisionState?.decision]);

  const addDecision = (obj) => {
    let error = decisionValidate(obj);
    if (Object.keys(error).length != 0) {
      setErrors(error);
    } else {
      setDecisionState({
        decision: "",
        decisionData: [...obj.decisionData, { id: "", title: obj?.decision }],
      });
      setErrors({});
      handleCloseModel();
    }
  };

  const saveDecision = async () => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("meeting_id", meeting_id);
    let decisionData = [...decisionState?.decisionData];
    decisionData.splice(0, initialDecisions?.length);
    decisionData?.map((op, index) => {
      formdata.append(`contents[${index}]`, op.title);
    });
    await http.postApi(t, `/api/meeting/meeting-decision/`, formdata);
    setLoader(false);
    getMeetingDecisions();
  };

  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };
  const handleModal = () => {
    setModelData({ ...modalData, model: true });
  };

  const getMeetingDecisions = async () => {
    if (meeting_id) {
      setLoader(true);
      let meetingMins = await http.getApi(
        t,
        `/api/meeting/meeting-decision/${meeting_id}`
      );
      const data = meetingMins.map((el) => {
        return { ...el, checked: !!el.checked ? true : false };
      });
      setInitialDecisions(data);
      setDecisionState({ ...decisionState, decisionData: data });
      setLoader(false);
      getMeetingSignatures();
    }
  };

  const getMeetingSignatures = async () => {
    if (meeting_id) {
      setLoader(true);
      let signatureData = await http.getApi(
        t,
        `/api/meeting/meeting-decision/signatures/${meeting_id}`
      );
      signatureData?.forEach((element) => {
        if (user?.id === element.userId) {
          if (!!element.sign) {
            setDisableUpdates(true);
          } else {
            setDisableUpdates(false);
          }
        }
      });
      setMeetingSignature(signatureData);
      setLoader(false);
    }
  };

  useEffect(() => {
    getMeetingDecisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting_id]);

  const handleSelectDecision = async (checked, id) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("checked", checked ? 1 : 0);
    try {
      await http.postApi(
        t,
        `/api/meeting/meeting-decision/verify/${id}`,
        formData
      );
      const decisions = [...decisionState.decisionData].map((item) => {
        if (item.id === id) {
          return { ...item, checked: checked };
        } else {
          return item;
        }
      });
      setDecisionState({ ...decisionState, decisionData: decisions });
    } catch (error) {}
    setLoader(false);
  };

  const rejectDecision = async (id) => {
    setLoader(true);
    await http.postApi(t, `/api/meeting/meeting-decision/reject/${id}`);
    setLoader(false);
    getMeetingDecisions();
  };

  return (
    <div className="CommonPd_decision">
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setDecisionState}
        perantState={decisionState}
        MHeight="150px"
      />
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className="morningText" variant="h4">
          {meetingData?.title}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PrintTemplate>
            <MeetingDecisionPrint
              meetingData={meetingData}
              detailItem={decisionState}
              meetingSignature={meetingSignature}
            />
          </PrintTemplate>
          {!disableUpdates && (
            <ComponentButton
              value={t("labels.save")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                saveDecision();
              }}
            />
          )}
        </div>
      </Grid>

      <div className="Meeting_decisionRoot">
        <div className="root_decision">
          <ul>
            {decisionState?.decisionData?.map((op, index) => (
              <DecisionItem
                op={op}
                index={index}
                rejectDecision={rejectDecision}
                handleSelectDecision={handleSelectDecision}
                disableUpdates={disableUpdates}
              />
            ))}
          </ul>
        </div>
        {!disableUpdates && (
          <div
            className="Add_New_DecisionBtn"
            onClick={() => {
              if (getUserHasAccess("decision", 114)) {
                handleModal();
              } else {
                setAuthorityModal(true);
              }
            }}
          >
            <div className="Add_DecisionBtn">
              <span classNam="decisionIcon">
                <Svg type="add" /> {t("titles.add_new_decision")}
              </span>
            </div>
          </div>
        )}
      </div>

      {meetingSignature && meetingSignature.length != 0 && (
        <Grid container style={{ marginTop: "20px" }}>
          {meetingSignature &&
            meetingSignature?.map((code, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={
                  meetingSignature?.length === 1
                    ? 12
                    : meetingSignature?.length === 2
                    ? 6
                    : 4
                }
                className="form_previewTemplate addSignzz"
              >
                <div className="headerForm ">
                  <Typography
                    variant="body2"
                    className="titleHead menuTableRel"
                  >
                    {code.userName} ( {code.department} )
                  </Typography>
                </div>
                <div
                  style={{
                    height: "auto",
                    textAlign: "center",
                    minHeight: "50px",
                  }}
                >
                  <div className="CrossBtn cutombtnImg">
                    {code?.sign ? (
                      <div className="qr">
                        <QRCode value={decode(code?.sign)} />
                      </div>
                    ) : (
                      <Sign
                        code={code}
                        meetingId={meeting_id}
                        getData={getMeetingDecisions}
                      />
                    )}
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  );
}
