const promotionValidaction = (data, description) => {
    let error = {};
    if (data.select_employee == undefined || data.select_employee == "") {
        error.select_employee = "errors.select_employee";
    }
    if (data.promoted_to == undefined || data.promoted_to == "") {
        error.promoted_to = "errors.promoted_to";
    }
    if (data.start_date == undefined || data.start_date == "") {
        error.start_date = "errors.start_date";
    }
    if (data.approval_from == undefined || data.approval_from.length == 0) {
        error.approval_from = "errors.approval_from";
    }
    if (data.new_salary == undefined || data.new_salary == "") {
        error.new_salary = "errors.new_salary";
    }



    return error;
};

export default {
    promotionValidaction,
};
