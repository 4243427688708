import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import ReferenceCategoryCard from "../../../componentGroups/ReferenceCategoryCard";
import { getAllData, getAllDataApi, getApi } from "../../../../api/axios";
import ComponentButton from "../../../componentGroups/componentButton";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

export default function ProfileTechnicalDepartmentsReferences(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const history = useHistory();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getApi(t, `/api/reference-categories`).then((res) => setCategories(res));
  }, []);

  return (
    <div className="profiledeptRef">
      <div className="CommonPd">
        <div className="latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Typography variant="h4" className="morningText">
                {t("titles.library_categories ")}
              </Typography>
              <ComponentButton
                sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                value={t("labels.add_new")}
                callback={() => {
                  if (getUserHasAccess("reference", 159)) {
                    history.push(`/reference-categories/category-form`);
                  } else {
                    setAuthorityModal(true);
                  }
                }}
                icon={<AddIcon sx={{ paddingRight: "4px", color: "#fff" }} />}
                variant={"contained"}
                class={"buttonFill"}
              />
            </Grid>
            <Grid container spacing={2}>
              {categories?.map((c, i) => (
                <ReferenceCategoryCard data={c} key={i} />
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
