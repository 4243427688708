import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Collapse } from "@mui/material";
import Svg from "../../../../assets/svg/svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import FileItem from "./FileItem";

const DocumentFolder = ({ item, enablArchive, enableSubmitTo, getFiles }) => {
  const [inExpanded, setInExpanded] = useState(false);
  const handleClose = () => {
    setInExpanded(false);
  };
  const handleOpen = () => {
    setInExpanded(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid
        style={{
          padding: "10px 10px !important",
          width: "100%",
          cursor: "pointer",
        }}
        className="perviewBox"
        container
        spacing={3}
        onClick={() => (inExpanded === false ? handleOpen() : handleClose())}
      >
        <Grid item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Svg type="folder" />
            <div style={{ marginLeft: "10px" }}>
              <Typography variant="h6" className="title iconText">
                {item?.name}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className=""
          item
          xs={6}
          md={6}
          sm={12}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <div className="folderArrow">
            <span>
              {inExpanded ? (
                <KeyboardArrowUp className="iconArrow" />
              ) : (
                <KeyboardArrowDownIcon className="iconArrow" />
              )}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12} style={{ padding: 0 }}>
        <Collapse in={inExpanded} timeout="auto" unmountOnExit>
          {item?.client_files?.map((item) => {
            return (
              <FileItem
                item={item}
                enablArchive={enablArchive}
                enableSubmitTo={enableSubmitTo}
                getFiles={getFiles}
              />
            );
          })}
          {item?.form_documents?.map((item) => {
            return (
              <FileItem
                item={item}
                enablArchive={enablArchive}
                enableSubmitTo={enableSubmitTo}
                getFiles={getFiles}
              />
            );
          })}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default DocumentFolder;
