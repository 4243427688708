import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import MeetingValidationModal from "./MeetingValidationModal";

const DashBoardPendingRequests = ({ enableMeetingValidation = true }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState({
    open: false,
    id: "",
    meeting_id: "",
  });

  const { setLoader } = useContext(LoadingContext);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [pendingRequestToshow, setPendingRequestToshow] = useState([]);

  const getPendingRequests = async () => {
    setLoader(true);
    let res = [];
    if (!!enableMeetingValidation) {
      res = await http.getApi(
        t,
        `/api/meeting/meeting-mins/validation/pending`
      );
    }

    const pending = await http.getApi(t, `/api/requestandassign/dashboard/all`);
    const pendingreq = pending?.map((item) => {
      return {
        ...item,
        type: "request",
      };
    });
    const consultation = await http.getApi(
      t,
      `/api/consultation/dashboard/all`
    );
    const consultationReq = consultation?.map((item) => {
      return {
        ...item,
        type: "consultation",
      };
    });
    setLoader(false);
    let result = [...pendingreq, ...consultationReq];
    if (!!enableMeetingValidation) {
      result = [...res, ...result];
    }
    if (!!result && result?.length > 0) {
      setPendingRequest(result);
    } else {
      setPendingRequest([]);
    }
  };

  useEffect(() => {
    getPendingRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStatus = async (id) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("status", 2);
    await http.putApi(t, `/api/requestandassign/changestatus/${id}`, formData);
    setLoader(false);
    getPendingRequests();
  };

  const changeConsultationStatus = async (id) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("status", 2);
    await http.putApi(t, `/api/consultation/changestatus/${id}`, formData);
    setLoader(false);
    getPendingRequests();
  };

  useEffect(() => {
    if (showAll) {
      setPendingRequestToshow(pendingRequest);
    } else {
      if (pendingRequest?.length > 5) {
        let sliced = [...pendingRequest]?.slice(0, 5);
        setPendingRequestToshow(sliced);
      } else {
        setPendingRequestToshow(pendingRequest);
      }
    }
  }, [showAll, pendingRequest]);

  return (
    <>
      <MeetingValidationModal
        open={modal?.open}
        id={modal?.id}
        meeting_id={modal?.meeting_id}
        onCLose={() => {
          setModal({
            open: false,
            id: "",
            meeting_id: "",
          });
        }}
        getPendingRequests={getPendingRequests}
      />
      <div className="CommonPd">
        <div className="dashTopGrid pending_req_box">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("titles.pending_requests")}
              </Typography>
            </Grid>
            <Grid className="btnStyle">
              {pendingRequest?.length > 5 && (
                <ComponentButton
                  value={showAll ? t("labels.show_less") : t("labels.view_all")}
                  variant={"outlined"}
                  class={"buttonBlank outlineBtn "}
                  callback={() => {
                    setShowAll(!showAll);
                  }}
                />
              )}
            </Grid>
          </Grid>

          {pendingRequest?.length > 0 ? (
            pendingRequestToshow?.map((op) => (
              <div className="dashboard Btn">
                <Grid className="gridLeft">
                  <Typography component="span" variant="body2">
                    {op?.type === "request"
                      ? op?.subject
                      : op?.type === "consultation"
                      ? op?.description
                      : op?.title}
                  </Typography>
                </Grid>
                {!op?.type && (
                  <Grid className="gridLeftbtn">
                    <ComponentButton
                      value={t("labels.view_details")}
                      variant={"outlined"}
                      class={"buttonBlank Mright"}
                      callback={() =>
                        history.push({
                          pathname: "/meetingDetails",
                          state: { id: op?.meeting_id },
                        })
                      }
                    />
                    <ComponentButton
                      value={t("labels.validate_now")}
                      variant={"contained"}
                      class={"buttonFill"}
                      callback={() =>
                        setModal({
                          open: true,
                          id: op?.id,
                          meeting_id: op?.meeting_id,
                        })
                      }
                    />
                  </Grid>
                )}
                {op?.type === "request" && (
                  <Grid className="gridLeftbtn">
                    <ComponentButton
                      value={t("labels.executing")}
                      variant={"contained"}
                      class={"buttonFill"}
                      callback={() => changeStatus(op?.id)}
                    />
                  </Grid>
                )}
                {op?.type === "consultation" && (
                  <Grid className="gridLeftbtn">
                    <ComponentButton
                      value={t("labels.executing")}
                      variant={"contained"}
                      class={"buttonFill"}
                      callback={() => changeConsultationStatus(op?.id)}
                    />
                  </Grid>
                )}
              </div>
            ))
          ) : (
            <span className="noDataFound">
              {" "}
              {t("labels.no_pending _requests_found")}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default DashBoardPendingRequests;
