const empValidate = (data) => {
  let error = {};
  if (data.name == undefined || data?.name?.trim() == "") {
    error.name = "errors.enter_name";
  }
  if (data.departmentName == undefined || data.departmentName == "") {
    error.departmentName = "errors.select_department";
  }
  if (data.branchName == undefined || data.branchName == "") {
    error.branchName = "errors.select_branch";
  }
  if (data.email == undefined || data.email == "") {
    error.email = "errors.enter_email";
  }
  if (
    data?.email &&
    !/^[A-Z0-9\u0600-\u06FF._%+-]+@[A-Z0-9\u0600-\u06FF.-]+\.[A-Z]{2,4}$/i.test(
      data.email
    )
  ) {
    error.email = "errors.enter_valid_email";
  }
  if (data.password == undefined || data.password == "") {
    error.password = "errors.enter_password";
  }
  if (data.password && data.password.length < 8) {
    error.password = "errors.pass_min_length";
  }
  if (data.jobId == undefined || data.jobId == "") {
    error.jobId = "errors.select_job_position";
  }
  if (data.responsibleHrId == undefined || data.responsibleHrId == "") {
    error.responsibleHrId = "errors.select_responsible_hr";
  }
  if (data.directManagerId == undefined || data.directManagerId == "") {
    error.directManagerId = "errors.select_direct_manager";
  }

  return error;
};

const leaveValidate = (data, leaveBalance) => {
  let error = {};
  if (data.leave_type == undefined || data.leave_type == "") {
    error.leave_type = "errors.leave_type";
  }
  if (data?.leave_type !== undefined && data?.leave_type !== "") {
    if (data?.leave_type === 1 && leaveBalance?.sick_leave <= 0) {
      error.leave_type = "labels.insufficient_sick_leave";
    } else if (data?.leave_type === 2 && leaveBalance?.casual_leave <= 0) {
      error.leave_type = "labels.insufficient_casual_leave";
    } else if (data?.leave_type === 3 && leaveBalance?.annual_leave <= 0) {
      error.leave_type = "labels.insufficient_annual_leave";
    }
  }
  if (data.leave_period == undefined || data.leave_period == "") {
    error.leave_period = "errors.leave_period";
  }
  if (
    data?.leave_type !== undefined &&
    data?.leave_type !== "" &&
    data.leave_period !== undefined &&
    data.leave_period !== ""
  ) {
    if (
      data?.leave_type === 1 &&
      leaveBalance?.sick_leave < data.leave_period
    ) {
      error.leave_period = "labels.insufficient_sick_leave";
    } else if (
      data?.leave_type === 2 &&
      leaveBalance?.casual_leave < data.leave_period
    ) {
      error.leave_period = "labels.insufficient_casual_leave";
    } else if (
      data?.leave_type === 3 &&
      leaveBalance?.annual_leave < data.leave_period
    ) {
      error.leave_period = "labels.insufficient_annual_leave";
    }
  }

  if (data.leave_date == undefined || data.leave_date == "") {
    error.leave_date = "errors.leave_date";
  }
  if (
    data.request_approval_from == undefined ||
    data.request_approval_from == ""
  ) {
    error.request_approval_from = "errors.request_approval_from";
  }
  if (data.attachments == undefined && data.attachments == "") {
    error.attachments = "errors.attachments";
  }
  if (data.description == undefined && data?.description?.trim() == "") {
    error.description = "errors.enter_description";
  }
  return error;
};
export default {
  empValidate,
  leaveValidate,
};
