import React, { useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import Folder from "../../../assets/images/folder.png";
import "./style.css";
import ComponentModal from "../../componentGroups/componectModel";
import FilterListIcon from "@mui/icons-material/FilterList";
import Svg from "../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
const CoordinateDocument = () => {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState([1, 2, 3, 4, 5, 6]);
  const [openMode, setOpenMode] = useState(false);
  const [modalData, setmodalData] = useState([]);

  const handleCloseModel = () => {
    setmodalData({
      ...modalData,
      model: false,
    });
  };
  const handleModal = () => {
    setmodalData({
      model: true,
      title: "labels.comment_on_document",
      data: [{ lable: "titles.comment", field: { type: "text" } }],
      buttonGroup: [
        {
          value: t("labels.discard"),
          variant: "outlined",
          class: "buttonBlank outlineBtn",
          callback: () => {
            handleCloseModel();
          },
        },
        {
          value: t("labels.save"),
          variant: "contained",
          class: "buttonFill FillBtn",
          callback: () => {
            handleCloseModel();
          },
        },
      ],
    });
  };

  return (
    <div className="mainContainer  MeetingFolderPg">
      <ComponentModal modalData={modalData} />
      {openMode ? (
        <div className="mainCon">
          <Typography className="morningText" variant="h4">
            {t("titles.shared_documents")}{" "}
          </Typography>
          <ComponentButton
            icon={<FilterListIcon />}
            value={t("labels.filter")}
            variant={"outlined"}
            class={"buttonBlank"}
          />
        </div>
      ) : (
        <Typography className="morningText" variant="h4">
          {t("titles.meeting_folders")}
        </Typography>
      )}
      {openMode ? (
        <div>
          {data.map((op, index) => (
            <Box sx={{ mt: 2, p: 2 }} className="box box1" key={index}>
              <Grid
                style={{ padding: "10px 10px !important" }}
                className="perviewBox"
                container
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <div className="detailsBox">
                    <div>
                      <Svg type="pdf" />
                    </div>
                    <div>
                      <Typography variant="h6" className="title iconText">
                        File Document Name
                      </Typography>
                      <Typography variant="body2" className="details">
                        Meeting Title : Meeting Title 1
                      </Typography>
                      <Typography variant="body2" className="details">
                        Modified : October 17, 2022 at 4 PM
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  className=""
                  item
                  xs={6}
                  md={6}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <ComponentButton
                    value={"Preview"}
                    variant={"contained"}
                    class={"buttonFill"}
                    sx={{ paddingRight: "0px !important" }}
                    callback={() => {
                      setOpenMode(true);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </div>
      ) : (
        <div>
          {data.map((op, index) => (
            <Box sx={{ mt: 2, p: 2 }} className="box box1" key={index}>
              <Grid
                style={{ padding: "10px 10px !important" }}
                className="perviewBox"
                container
                spacing={3}
              >
                <Grid item xs={12} md={6} sm={12}>
                  <div className="detailsBox">
                    <div className="FolderIcon">
                      <img src={Folder} />
                    </div>
                    <div className="FolderN">
                      <Typography variant="h6" className="title iconText">
                        Folder Name
                      </Typography>
                      <Typography variant="body2" className="details">
                        Modified : October 17, 2022 at 4 PM
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  className=""
                  item
                  xs={12}
                  md={6}
                  sm={12}
                  sx={{
                    textAlign: "right",
                    gap: "5px",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <ComponentButton
                    value={t("labels.open")}
                    variant={"contained"}
                    class={"buttonFill"}
                    sx={{ paddingRight: "0px !important" }}
                    callback={() => {
                      setOpenMode(true);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </div>
      )}
    </div>
  );
};

export default CoordinateDocument;
