import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import Info from "../../../assets/images/info.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import http, { baseURL } from "../../../api/axios";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import moment from "moment";
import hooks from "../../../Hooks/hooks";
import { loadOptions } from "../../../api/utils/common";
import { toast } from "react-toastify";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import auth from "../../../services/auth";

export default function SendTask(props) {
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { t } = useTranslation();
  const user = auth.getUser("user");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [setTask, setSendTask] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.task_number",
      name: "taskNumber",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.task_title",
      name: "taskTitle",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.project_name",
      name: "projectName",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.assigned_to",
      name: "assigned_to",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.task_type",
      name: "task_type",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.due_date",
      name: "dueDate",
      type: "date",
      width: "10px",
      align: "left",
      minWidth: 90,
    },
    {
      lable: "labels.attachments",
      name: "attachments",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 50,
    },
    {
      lable: "labels.task_status",
      name: "taskStatus",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.priority",
      name: "priority",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
  ]);
  const [data, setData] = useState({
    title: "tableTitle.6.sent_tasks",
    page: "Tasks",
    infoURL: "/tasks/edit-task",
    handlExport: (page, size, filterData) =>
      props.handleExport(page, size, filterData, 1),
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    props.setLoader(true);
    let formdata = new FormData();
    formdata.append(
      "filter[taskTitle]",
      filterData?.taskTitle ? filterData?.taskTitle : ""
    );
    formdata.append(
      "filter[taskNumber]",
      filterData?.taskNumber ? filterData?.taskNumber : ""
    );
    formdata.append(
      "filter[dueDate]",
      filterData?.dueDate
        ? moment(filterData?.dueDate).format("YYYY-MM-DD")
        : ""
    );
    formdata.append(
      "filter[priority]",
      filterData?.priority ? filterData?.priority : ""
    );
    formdata.append(
      "filter[assignedFrom]",
      filterData?.assignedFrom ? filterData?.assignedFrom : ""
    );
    formdata.append(
      "filter[taskStatus]",
      filterData?.taskStatus ? filterData?.taskStatus : ""
    );
    formdata.append(
      "filter[assigned_to]",
      filterData?.assigned_to ? filterData?.assigned_to : ""
    );
    formdata.append(
      "filter[projectName]",
      filterData?.projectName ? filterData?.projectName : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/task/details?access=1&page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setSendTask(res.data);
    setFilterData({ ...filterData, filter: false });

    props.setLoader(false);
    // TODO: if code is independent need to be added to another function
    // props.setLoader(false);
  };

  const setColumnData = async () => {
    let s1 = [...column];
    let res1 = await loadOptions(t, 1, 2);
    let res2 = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let res3 = await loadOptions(
      t,
      1,
      undefined,
      "post",
      "/api/project/details"
    );
    s1[3].options = hooks.sendName(res3, "projectName");

    s1[4].options = res2;
    s1[5].options = res2;
    s1[9].options = hooks.sendName(res1, "option");
    setColumn(s1);
  };

  useEffect(() => {
    setColumnData();
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, filterData?.filter == true]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("task", 120)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/task/delete",
          formData,
          "labels.task_deleted_successfully"
        );
        getApiData(page, size);
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <ComponentTable
      rows={setTask}
      columns={column}
      pageData={data}
      page={page}
      filterData={filterData}
      setFilterData={setFilterData}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      setSearch={setSearch}
      search={search}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{
        status: "",
      }}
    />
  );
}
