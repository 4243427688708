import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Tabs, Tab } from "@mui/material";
import ComponentCKEditer from "../../../../componentGroups/componentCKEditer";
import ComponentButton from "../../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import ValidationMintsModal from "./modal";
import ComponentModal from "../../../../componentGroups/componectModel";
import http from "../../../../../api/axios";
import validate from "../../../../../Hooks/meeting_decision_validaction";
import { loadOptions } from "../../../../../api/utils/common";
const MeetingMints = (props) => {
  const { t } = useTranslation();
  const Id = props?.Id.id;
  const user = JSON.parse(localStorage.getItem("user"));
  const [enableValidation, setEnableValidation] = useState(false);
  const [meetingMinsId, setMeetingMinsId] = useState(
    props?.meetingData?.MeetingMin?.id
  );
  const [errors, setErrors] = useState({});
  const [meetingMintsState, setMeetingMintsState] = useState({
    mints: props?.meetingData?.meeting_mins,
  });
  const [mintsModalState, setMintsModalState] = useState({
    meeting_mins_id: props?.meetingData?.MeetingMin?.id,
    sendTo: [],
  });
  const [requestModalData, setRequestModalData] = useState({ model: false });
  const [modalData, setModelData] = useState({
    model: false,
    title: "labels.validation_request",
    data: [
      {
        lable: "labels.send_to",
        field: {
          type: "multiple",
          findLable: "name",
          setlable: "name",
          required: true,
        },
        name: "sendTo",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.send",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          sendValidactionRequest(value);
        },
      },
    ],
  });

  const handleCloseModel = () => {
    setModelData({
      ...modalData,
      model: false,
    });
    setMintsModalState({});
  };

  const handleModal = (val) => {
    let s1 = { ...modalData };
    if (val == "otp") {
      s1.model = true;
      s1.data = [
        s1.data[0],
        {
          lable: "labels.otp_code",
          field: { type: "text", required: true },
          name: "otp",
        },
      ];
      setModelData(s1);
      setMintsModalState({
        ...mintsModalState,
        notification: 2,
        meeting_mins_id: meetingMinsId,
      });
    } else if (val == "inSystem") {
      s1.model = true;
      s1.data = [
        s1.data[0],
        {
          lable: "labels.request_subject",
          field: { type: "textArea", required: true, minRows: 4 },
          name: "subject",
        },
      ];
      setModelData(s1);
      setMintsModalState({
        ...mintsModalState,
        notification: 1,
        meeting_mins_id: meetingMinsId,
      });
    }
  };

  const addMints = async () => {
    let error = validate.mintsValidaction(meetingMintsState);
    if (Object.keys(error).length === 0) {
      props.setLoader(true);
      console.log(Id, "ididid");
      var formdata = new FormData();
      formdata.append("meeting_id", Id);
      formdata.append("content", meetingMintsState?.mints);
      let res = await http.postApi(t, `/api/meeting/meeting-mins`, formdata);
      if (res) {
        setEnableValidation(true);
        setMintsModalState({
          ...mintsModalState,
          meeting_mins_id: res?.dataPk,
        });
        setMeetingMinsId(res?.dataPk);
        setRequestModalData({ ...requestModalData, model: true });
      }
      props.setLoader(false);
      getModalData();
    } else {
      setErrors(error);
    }
  };

  const sendValidactionRequest = async (obj) => {
    let error =
      obj?.notification == 2
        ? validate.otpValidaction(obj)
        : validate.inSystemValidaction(obj);
    console.log(obj, error, "ididid");
    if (Object.keys(error).length === 0) {
      // props.setLoader(true);
      var formdata = new FormData();
      formdata.append("meeting_id", Id);
      obj?.sendTo?.forEach((element, index) => {
        formdata.append(`send_to[${index}]`, element?.id);
      });
      formdata.append("meeting_mins_id", obj?.meeting_mins_id);
      formdata.append("notification", obj?.notification);
      if (obj?.notification == 1) {
        formdata.append("subject", obj?.subject);
      } else {
        formdata.append("otp", obj?.otp);
      }
      let res = await http.postApi(
        t,
        `/api/meeting/meeting-mins/validation-request/send`,
        formdata
      );
      if (res) {
        handleCloseModel();
        setRequestModalData({ ...requestModalData, model: false });
        getModalData();
      }
      // props.setLoader(false);
    } else {
      setErrors(error);
    }
  };

  const getModalData = async () => {
    let s1 = { ...modalData };
    let res2 = await loadOptions(
      t,
      1,
      undefined,
      "get",
      `/api/meeting/users/${Id}`
    );
    console.log(res2, "here");
    s1.data[0].field.option = res2;
  };

  useEffect(() => {
    getModalData();
  }, []);

  return (
    <div className="CommonPd_decision">
      <ComponentModal
        errors={errors}
        modalData={modalData}
        setPerantState={setMintsModalState}
        perantState={mintsModalState}
      />
      <ValidationMintsModal
        setRequestModalData={setRequestModalData}
        modalData={requestModalData}
        handleModal={(val) => handleModal(val)}
      />
      <div className="mainCon minTabs butoonValidateRequest">
        <Typography variant="h4" className="morningText">
          {props?.meetingData?.title}
        </Typography>
        <div>
          {!props?.meetingData?.meeting_mins && (
            <ComponentButton
              value={t(`labels.save`)}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                addMints();
              }}
            />
          )}
          {props?.meetingData?.created_by_id === user?.id &&
            (!!props?.meetingData?.meeting_mins || enableValidation) && (
              <ComponentButton
                value={t(`labels.send_validation_request`)}
                variant={"contained"}
                class={"buttonFill ml-3 Mright"}
                callback={() => {
                  setRequestModalData({ ...requestModalData, model: true });
                }}
              />
            )}
        </div>
      </div>
      <div className="ckEditor MeetingDecisionPg">
        <ComponentCKEditer
          value={meetingMintsState?.mints}
          callback={(data) =>
            setMeetingMintsState({ ...meetingMintsState, mints: data })
          }
          style={{ marginRight: "25px" }}
        />
      </div>
      {errors?.mints && (
        <div style={{ color: "red", fontSize: "12px" }}>
          {`* ${t(errors?.mints)}`}
        </div>
      )}
    </div>
  );
};
export default MeetingMints;
