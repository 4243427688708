import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../../api/axios";
import ReminderItem from "../../CoordinatorScreens/ParnternMeetingTab/ReminderItem";
import ComponentButton from "../../../componentGroups/componentButton";
import LoadingContext from "../../../../context/LoaderContext";

const Schedule = ({
  endPoint = "",
  enableReminderPopup = false,
  disableAddNew = false,
  modulePath = "",
}) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const history = useHistory();
  const [reminders, setReminders] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [scheduleToView, setScheduleToView] = useState([]);

  const getReminders = async () => {
    setLoader(true);
    const res = await http.getApi(t, endPoint);
    setLoader(false);
    if (!!res && res?.length > 0) {
      setReminders(res);
    } else {
      setReminders([]);
    }
  };

  useEffect(() => {
    getReminders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAll) {
      setScheduleToView(reminders);
    } else {
      if (reminders?.length > 3) {
        let sliced = reminders?.slice(0, 3);
        setScheduleToView(sliced);
      } else {
        setScheduleToView(reminders);
      }
    }
  }, [showAll, reminders]);

  return (
    <div className="CommonPd">
      <div className="dashTopGrid pending_req_box">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid className="TxtMbottom">
            <Typography className="morningText" variant="h4">
              {t("labels.todays_schedule")}
            </Typography>
          </Grid>
          <Grid className="btnStyle">
            {reminders?.length > 3 && (
              <ComponentButton
                value={showAll ? t("labels.show_less") : t("labels.view_all")}
                variant={"outlined"}
                class={"buttonBlank Mright outlineBtn "}
                callback={() => {
                  setShowAll(!showAll);
                }}
              />
            )}
            {!disableAddNew && (
              <ComponentButton
                value={t("labels.add_new")}
                variant={"outlined"}
                class={"buttonFill outlineBtn ml-10"}
                callback={() => {
                  history.push("/createMeeting");
                }}
              />
            )}
          </Grid>
        </Grid>
        {scheduleToView?.map((item) => {
          return (
            <ReminderItem
              item={item}
              enableReminderPopup={enableReminderPopup}
              modulePath={modulePath}
            />
          );
        })}
        {reminders?.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <div className="noDataFound">{t("labels.no_meetings_today")}</div>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Schedule;
