
import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import validate from "../../../Hooks/employee_validaction";

import ComponentButton from "../../componentGroups/componentButton";
import { getApi, patchApi, postApi } from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import FormInput from "../ProfileModule/ProfileTechnicalDepartment/FormInput";
import axios from "axios";

import FileView from "../PartnerScreens/Documents/FileView";
import AddEmployeeModal from "./AddEmployeeModal";
import AuthorityContext from "../../../context/AuthorityContext";
import hooks from "../../../Hooks/hooks";
import { loadOptions } from "../../../api/utils/common";
import ComponentModal from "../../componentGroups/componectModel";
import { getUserHasAccess } from "../../../utils/common";


const CandidateInfo = () => {
  const vacancy = JSON.parse(localStorage.getItem("vacancy"));
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [candidateState, setCandidateState] = useState(candidate);
  const [cv, setCv] = useState()
  const [cover, setCover] = useState()


  const [vacancyState, setVacancyState] = useState(vacancy);

  // useEffect(() => {
  //   // if (localStorage.getItem("vacancy"))
  //   //   setVacancyState(JSON.parse(localStorage.getItem("vacancy")));
  //   if (localStorage.getItem("candidate"))
  //     setCandidateState(JSON.parse(localStorage.getItem("candidate")));
  // }, []);

  useEffect(() => {
    if (candidateState?.file?.length > 0) {

      if (candidateState?.file[0]?.fileType === 'cv')
        setCv(candidateState?.file[0]);

      if (candidateState?.file[1]?.fileType === 'cv')
        setCv(candidateState?.file[1]);

      if (candidateState?.file[0]?.fileType === 'cover')
        setCover(candidateState?.file[0]);

      if (candidateState?.file[1]?.fileType === 'cover')
        setCover(candidateState?.file[1]);

    }
  }, [candidateState]);


  // for add emp modal
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [empState, setEmpState] = useState({ responsibilities: [], departmentName: vacancy?.department?.departmentName, branchName: vacancy?.branch?.branchName, name: candidate?.name });
  const [errors, setErrors] = useState({});
  const { setLoader } = useContext(LoadingContext);

  const handleModel = (val) => {
    if (val == "addEmp") {
      setModalData({
        ...modalData,
        model: true,
      });
    }
  };
  const handleCloseModel = () => {
    setModalData({
      ...modalData,
      model: false,
    });
  };
  const [modalData, setModalData] = useState({
    model: false,
    data: [
      {
        lable: "labels.name",
        field: { type: "text", required: true },
        name: "name",
      },
      {
        lable: "labels.email",
        field: {
          type: "text",
          required: true,
        },
        name: "email",
      },
      {
        lable: "labels.password",
        field: { type: "text", required: true },
        name: "password",
      },
      {
        lable: "labels.branch",
        field: {
          type: "text",
          disable: true,
          required: true
        },
        name: "branchName",

      },
      {
        lable: "labels.department",
        field: {
          type: "text",
          disable: true,
          required: true
        },
        name: "departmentName",
      },

      {
        lable: "labels.job_position",
        name: "jobId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "title",
          option: [],
        },
      },
      {
        lable: "labels.responsible_hr",
        name: "responsibleHrId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
      {
        lable: "labels.direct_manager",
        name: "directManagerId",
        field: {
          type: "dropdown",
          required: true,
          findLable: "name",
          option: [],
        },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          // setEmpState({});
          setErrors({});
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (val) => {
          addEmployee(val, vacancyState?.department?.id, vacancyState?.branch?.id);
          // handleCloseModel();
        },
      },
    ],
    title: "titles.add_new_employee",
  });


  // console.log(vacancyState?.department?.id, vacancyState?.branch?.id);
  const addEmployee = async (obj, departmentId, branchId) => {
    let error = validate.empValidate(obj);
    console.log(departmentId, branchId);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      formData.append("name", obj?.name);
      formData.append("password", obj.password);
      formData.append("email", obj.email);
      formData.append("departmentId", vacancyState?.department?.id);
      formData.append("branchId", vacancyState?.branch?.id);
      formData.append("jobId", obj.jobId);
      formData.append("responsibleHrId", obj.responsibleHrId);
      formData.append("directManagerId", obj.directManagerId);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const responsibilities = [...obj?.responsibilities];
      responsibilities?.forEach((item) => {
        formData.append(`responsibilities[]`, item);
      });
      const res = await postApi(t, "/api/employee/create", formData);
      if (res) {
        history.push('/employees')
        setLoader(false);
        setEmpState({});
        handleCloseModel();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
    // setLoader(false);
  };

  const getJobPositions = async () => {
    setLoader(true);
    axios.all([
      loadOptions(
        t,
        "",
        null,
        "get",
        `/api/setting/department/job-position/${vacancyState?.department?.id}`
      ),
      loadOptions(t, "", null, "get", `/api/employee/dropdown/all`),
      loadOptions(
        t,
        "",
        null,
        "get",
        "/api/employee/all/department/8"
      )
      // loadOptions(t, "", null, "get", `/api/employee/responsibilities/all/${vacancyState?.department?.id}`),
    ])
      .then((res) => {
        console.log('res message', res);
        // console.log(res);
        let s1 = { ...modalData };
        s1.data[5].field.option = res[0];
        s1.data[6].field.option = res[2];
        s1.data[7].field.option = res[1];
        setModalData(s1);
      });
    const responsibilities = await getApi(
      t,
      `/api/employee/responsibilities/all/${vacancyState?.department?.id}`
    );
    const options = responsibilities?.map((op) => {
      return op?.id;
    });
    setEmpState({
      ...empState,
      responsibilities: [...options],
    });
  };

  // useEffect(() => {
  //   if (empState?.departmentName) {
  //     getJobPositions();
  //   }
  // }, [empState?.departmentName]);

  useEffect(() => {

    if (Object.keys(errors).length != 0) {
      let error = validate.empValidate(empState);
      setErrors(error);
    }
  }, [empState]);

  const loadDumpData = async (vacancy, candidate) => {
    console.log(vacancy, candidate, empState);
    setEmpState({ ...empState, departmentName: vacancy?.department?.departmentName, branchName: vacancy?.branch?.branchName, name: candidate?.name })
    await getJobPositions();
    setLoader(false);
  }

  useEffect(() => {
    loadDumpData(vacancyState, candidateState)
  }, []);

  console.log(vacancyState);
  console.log(empState);


  return (
    <div className="SuggestDetailPg">
      <div class="CommonPd">
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.candidate_details")}

              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            {/* branch */}
            {candidateState?.name ? <FormInput
              value={candidateState?.name}
              name="name"
              label={t("labels.name")}
              disabled={true}
            /> : ''}


            {/* age */}

            {candidateState?.age ? <FormInput
              value={candidateState?.age}
              name="age"

              label={t("labels.age")}
              disabled={true}
            /> : ''}

            {/* cityOfResidence */}
            {candidateState?.cityOfResidence ? <FormInput
              value={candidateState?.cityOfResidence}
              name="cityOfResidence"

              label={t("labels.city")}
              disabled={true}
            /> : ''}

            {/* university */}
            {candidateState?.university ? <FormInput
              value={candidateState?.university}
              name="university"

              label={t("labels.university")}
              disabled={true}
            /> : ''}

            {/* college */}
            {candidateState?.college ? <FormInput
              value={candidateState?.college}
              name="college"

              label={t("labels.college")}
              disabled={true}
            /> : ''}

            {/* degree */}
            {candidateState?.degree ? <FormInput
              value={candidateState?.degree}
              name="degree"

              label={t("labels.degree")}
              disabled={true}
            /> : ''}

            {/* grade */}
            {candidateState?.grade ? <FormInput
              value={candidateState?.grade}
              name="grade"

              label={t("labels.grade")}
              disabled={true}
            /> : ''}

            {/* expirence */}
            {candidateState?.expirence ? <FormInput
              value={`${candidateState?.expirence} years`}
              name="expirence"

              label={t("labels.experience")}
              disabled={true}
            /> : ''}

            {/* expectedSalary */}
            {candidateState?.expectedSalary ? <FormInput
              value={candidateState?.expectedSalary}
              name="expectedSalary"

              label={t("labels.expected_salary")}
              disabled={true}
            /> : ''}


            <div style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between"
              width: "100%",
              padding: "5px"
            }}>
              <span style={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#2b564c',
                flex: ' 0 0 15%'
              }}>{t("labels.cv")}</span>
              <div style={{ flex: 1 }}>

                {candidateState?.file?.length > 0 ? <FileView index={1} item={candidateState?.file[0]} /> : ""}
              </div>
            </div>

            <div style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between"
              width: "100%",
              padding: "5px"
            }}>
              <span style={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#2b564c',
                flex: ' 0 0 15%'
              }}>
                {t("labels.cover_letter")}
              </span>
              <div style={{ flex: 1 }}>

                {cover ? <FileView index={1} item={cover} /> : <p>{t('labels.no_cover_letter')}.</p>}
              </div>
            </div>


          </form>
          <div className="RefresugstBtn" style={{
            gap: '1rem',
            display: "flex"
          }}>


            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.create_employee")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                if (getUserHasAccess("employee", 71)) {
                  handleModel("addEmp");
                } else {
                  setAuthorityModal(true);
                }
              }}
            />

          </div>

        </div>
      </div>
      <ComponentModal
        modalData={modalData}
        setPerantState={setEmpState}
        perantState={empState}
        errors={errors}
      />
    </div >
  )
}

export default CandidateInfo