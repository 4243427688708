import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import ComponentTextString from "../../componentGroups/componentTextString";
import ComponentButton from "../../componentGroups/componentButton";
import logo from "../../../assets/images/jpg 2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ComponentModal from "../../componentGroups/componectModel";
import http from "../../../api/axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingContext from "../../../context/LoaderContext";
import validate from "../../../Hooks/login_validaction";

export default function Login(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});
  const [cred, setCred] = useState({ email: "", password: "" });
  const [modalData, setModelData] = useState({
    model: false,
    data: [],
    title: "",
    desc: "",
  });
  const handleCloseModel = () => {
    setState({});
    setModelData({ ...modalData, model: false });
  };
  const handleModel = (val) => {
    if (val == "executing") {
      setModelData({
        ...modalData,
        title: "Change Password Request",
        // title: "titles.change_password_request",
        desc: "titles.are_you_sure_to_cahnge_password",
        model: true,
        data: [
          {
            // lable: "titles.employee_Id",
            lable: "Employee Id",
            field: {
              type: "text",
            },
            name: "request",
          },
        ],
        buttonGroup: [
          {
            value: "labels.discard",
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              handleCloseModel();
            },
          },
          {
            value: "labels.send_request",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (value) => {
              handleRequest(value);
            },
          },
        ],
      });
    }
  };

  const handleRequest = async (obj) => {
    let error = validate.requestValidate(obj);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      formData.append("email", obj?.request);
      const res = await http.postApi(
        t,
        "/api/user/forgot-password",
        formData,
        "labels.hr_will_connect_regarding_password"
      );
      if (res) {
        handleCloseModel();
        setState({});
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.requestValidate(state);
      setErrors(error);
    }
  }, [state]);

  useEffect(() => {
    props?.user && (window.location.href = "/");
  }, []);

  const handleLogin = async () => {
    const formData = new FormData();
    formData.append("email", cred.email);
    formData.append("password", cred.password);
    props.setLoader(true);
    await http.Login_APi(t, "/api/user/login", formData);

    history.push("/");
    props.setLoader(false);
  };

  return (
    <>
      <div className="mainLogin">
        <ComponentModal
          errors={errors}
          modalData={modalData}
          setPerantState={setState}
          perantState={state}
        />
        <div class="wtrapperr">
          <div className="logoFormpage textCenter">
            <img src={logo} />
          </div>
          <div className="innerLogin">
            <div className="titleOuter">
              <Grid style={{ textAlign: "center" }} container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h6" className="loginTitle">
                    {t("titles.login")}
                  </Typography>
                </Grid>
              </Grid>
              <div className="loginOuter">
                <Grid container className="loginFlex">
                  <Grid item xs={12} sm={12} md={3}>
                    <Typography variant="h6" className="lableLogin">
                      {" "}
                      Employee Id
                      {/* {t("labels.employee_id")} */}
                    </Typography>
                  </Grid>
                  <Grid
                    className=""
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    sx={{ textAlign: "center" }}
                  >
                    <ComponentTextString
                      placeholder={t("placeholder.enter_employee_id")}
                      class="loginInput"
                      callback={(e) =>
                        setCred({ ...cred, email: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className="loginFlex">
                  <Grid item xs={12} sm={12} md={3}>
                    <Typography variant="h6" className="lableLogin">
                      Password
                    </Typography>
                  </Grid>
                  <Grid
                    className=""
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    sx={{ textAlign: "center" }}
                  >
                    <ComponentTextString
                      placeholder="Password"
                      type="password"
                      class="loginInput"
                      callback={(e) =>
                        setCred({ ...cred, password: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid className="" item xs={12} sm={12} md={3}></Grid>
                  <Grid
                    className=""
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    sx={{ textAlign: "center" }}
                  >
                    <div className="forgetPwd">
                      <Typography variant="body2" className="forgotCode">
                        Forget Password?{" "}
                        <a
                          onClick={() => {
                            handleModel("executing");
                          }}
                        >
                          Send Password Request
                        </a>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  style={{ textAlign: "center" }}
                  container
                  sx={{ marginTop: "30px" }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <ComponentButton
                      value="Login"
                      variant={"contained"}
                      class={"buttonFill borderButton"}
                      callback={handleLogin}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
