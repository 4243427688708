import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { TextField, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../componentGroups/componentButton";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import ComponentSelect from "../../../componentGroups/componentSelect";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";
import { loadOptions } from "../../../../api/utils/common";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { getDuration } from "../../../../utils/common";
import useFormatTime from "../../../../Hooks/useFormatTime";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import { WORKING_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/working_key_lable";

// predeined values to reuse the same component while in add and edit
const PAGE_VALUES = {
  add: "routes.add_working_schedule",
  edit: "routes.edit_working_schedule",
  addApi: "/api/branch/working-hours",
  getDataApi: "/api/branch/working-hours",
};

export default function AddEditWorkingHour() {
  const { workScheduleId } = useParams();
  const id = workScheduleId;
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { setPathValue } = useContext(BreadCrumbContext);
  const { setLoader } = useContext(LoadingContext);
  const { globalToLocal, localToGlobal, customDateTimeToGlobal } =
    useFormatTime();
  const [errors, setErrors] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    branches: [],
    days: [
      { name: "days.sun", id: "Sunday" },
      { name: "days.mon", id: "Monday" },
      { name: "days.tue", id: "Tuesday" },
      { name: "days.wed", id: "Wednesday" },
      { name: "days.thur", id: "Thursday" },
      { name: "days.fri", id: "Friday" },
      { name: "days.sat", id: "Saturday" },
    ],
  });
  const [workDetail, setWorkDetail] = useState({
    branchId: "",
    startTime: "",
    endTime: "",
    from: "",
    to: "",
    duration: "",
  });
  const [flag, setFlag] = useState(false);

  const getWorkingHours = async () => {
    const api = `${PAGE_VALUES.getDataApi}/${id}`;
    setLoader(true);
    const res = await http.getApi(t, `${api}`);
    const startTime = globalToLocal(res?.startTime, "HH:mm:ss");
    const endTime = globalToLocal(res?.endTime, "HH:mm:ss");
    setWorkDetail({
      to: res?.to,
      from: res?.from,
      duration: res?.duration,
      startTime: startTime,
      endTime: endTime,
      branchId: res?.branchId,
    });
    setLoader(false);
  };

  const validateErrors = (data) => {
    let error = {};
    if (data.from === undefined || data.from === "") {
      error.from = "errors.select_end_day";
    }
    if (data.to === undefined || data.to === "") {
      error.to = "errors.select_start_day";
    }
    if (data.startTime === undefined || data.startTime === "") {
      error.startTime = "errors.select_start_time";
    }
    if (data.endTime === undefined || data.endTime === "") {
      error.endTime = "errors.select_end_time";
    }
    if (data.endTime !== "" && data.startTime !== "") {
      const startTime = moment(data.startTime, "h:mm:ss a");
      const endTime = moment(data.endTime, "h:mm:ss a");
      if (endTime.isBefore(startTime)) {
        error.endTime = "errors.end_time_cant_be_less_than_start_time";
      }
    }
    if (data.branchId === undefined || data.branchId === "") {
      error.branchId = "errors.select_branch";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(workDetail);
      setErrors(error);
    }
  }, [workDetail]);

  //used to change breadcrumb name
  useEffect(() => {
    setPathValue([
      {
        path: pathname,
        name: id ? PAGE_VALUES.edit : PAGE_VALUES.add,
      },
      {
        name: PAGE_VALUES.title,
        path: PAGE_VALUES.path,
      },
    ]);
    axios
      .all([loadOptions(t, "branches", "", "post", `/api/branch/details`)])
      .then((res) => {
        const branches = res[0]?.map((item) => {
          return { ...item, name: item?.branchName };
        });
        setDropDownOptions({
          ...dropDownOptions,
          branches: branches,
        });
      })
      .finally(() => {
        if (id) {
          getWorkingHours();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (workDetail.startTime && workDetail.endTime) {
      const startMoment = moment(workDetail.startTime, "HH:mm:ss");
      const endMoment = moment(workDetail.endTime, "HH:mm:ss");
      const duration = getDuration(startMoment, endMoment);
      setWorkDetail({ ...workDetail, duration: duration });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workDetail.startTime, workDetail.endTime]);

  const handleAddEdit = async () => {
    let error = validateErrors(workDetail);
    if (Object.keys(error).length === 0) {
      const api = id ? `${PAGE_VALUES.addApi}/${id}` : PAGE_VALUES.addApi;
      setLoader(true);
      const currentDate = moment().format("YYYY-MM-DD");
      let startTime = customDateTimeToGlobal(
        `${currentDate} ${workDetail.startTime}`
      );
      let endTime = customDateTimeToGlobal(
        `${currentDate} ${workDetail.endTime}`
      );
      const formData = new FormData();
      formData.append("startTime", startTime);
      formData.append("endTime", endTime);
      formData.append("from", workDetail.from);
      formData.append("to", workDetail.to);
      formData.append("duration", workDetail.duration);
      formData.append(`branchIds[${0}]`, workDetail?.branchId);
      await http.postApi(t, api, formData);
      setLoader(false);
      history.goBack();
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(id ? PAGE_VALUES.edit : PAGE_VALUES.add)}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.from")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    class={"custonInput"}
                    value={workDetail?.from}
                    name="from"
                    option={dropDownOptions.days}
                    callback={(e) =>
                      setWorkDetail({
                        ...workDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.from && (
                    <span className="validate">{t(errors.from)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.to")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    class={"custonInput"}
                    value={workDetail?.to}
                    name="to"
                    option={dropDownOptions.days}
                    callback={(e) =>
                      setWorkDetail({
                        ...workDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.to && (
                    <span className="validate">{t(errors.to)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.work_start")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        className="timePicker"
                        value={
                          workDetail.startTime
                            ? moment(workDetail.startTime, "HH:mm:ss")
                            : null
                        }
                        onChange={(time) => {
                          let value = null;
                          if (!!time?.$d && time?.$d !== "INVALID DATE") {
                            value = moment(time?.$d).format("HH:mm:ss");
                          }
                          setWorkDetail({ ...workDetail, startTime: value });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  {errors?.startTime && (
                    <span className="validate">{t(errors.startTime)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.work_end")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        className="timePicker"
                        value={
                          workDetail.endTime
                            ? moment(workDetail.endTime, "HH:mm:ss")
                            : null
                        }
                        onChange={(time) => {
                          let value = null;
                          if (!!time?.$d && time?.$d !== "INVALID DATE") {
                            value = moment(time?.$d).format("HH:mm:ss");
                          }
                          setWorkDetail({ ...workDetail, endTime: value });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minTime={
                          workDetail.startTime
                            ? moment(workDetail.startTime, "HH:mm:ss")
                            : null
                        }
                      />
                    </LocalizationProvider>
                  </div>
                  {errors?.endTime && (
                    <span className="validate">{t(errors.endTime)}</span>
                  )}
                </Grid>

                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.branch_applied")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  {/* <Stack
                    spacing={3}
                    sx={{ width: "auto" }}
                    className="authoritiesGroup multiselect"
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      className="AutoComp"
                      multiple
                      getOptionLabel={(option) => option.name}
                      options={dropDownOptions?.branches || []}
                      value={workDetail?.branchIds}
                      onChange={(e, value) => {
                        setWorkDetail({
                          ...workDetail,
                          branchIds: [...value],
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Type at least 1 letter"
                        />
                      )}
                    />
                  </Stack> */}
                  <ComponentSelect
                    class={"custonInput"}
                    value={workDetail?.branchId}
                    name="branchId"
                    option={dropDownOptions.branches}
                    callback={(e) =>
                      setWorkDetail({
                        ...workDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.branchId && (
                    <span className="validate">{t(errors.branchId)}</span>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                style={{ margin: "16px 0", justifyContent: "center" }}
              >
                <Grid item>
                  <ComponentButton
                    value={t("labels.discard")}
                    variant={"outlined"}
                    class={"buttonBlank outlineBtn"}
                    callback={() => history.goBack()}
                  />
                </Grid>
                <Grid item>
                  <ComponentButton
                    value={t("labels.save")}
                    variant="contained"
                    class="buttonFill FillBtn ml-3"
                    callback={() => {
                      handleAddEdit();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {workScheduleId && (
              <LogNoteAccordion
                id={`BRANCH-WORK-HOURS${workScheduleId}`}
                flag={flag}
                labelKeys={WORKING_KEY_LABELS}
              />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
