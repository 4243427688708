import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import Svg from "../../../../assets/svg/svg";
import { baseURL } from "../../../../api/axios";

const FileView = ({ index, item }) => {
  const { t } = useTranslation();
  const history = useHistory();

  item.created_at = item?.created_at ? item?.created_at : item?.updatedAt;
  return (
    <Box sx={{ mt: 2, p: 2 }} className="box box1" key={index}>
      <Grid
        className="perviewBox"
        style={{
          padding: "10px 10px !important",
          marginTop: "0px",
        }}
        container
        spacing={3}
      >
        <Grid item xs={6} md={6}>
          <div className="detailsBox">
            <div>
              <Svg
                type={
                  item?.type === "pdf"
                    ? "pdf"
                    : item?.type === "doc"
                    ? "doc"
                    : item?.type === "xls"
                    ? "xls"
                    : "document"
                }
              />
            </div>
            <div>
              <Typography variant="h6" className="title iconText">
                {item?.shareType === 3 ? item?.title : item?.name}
              </Typography>
              {item?.meeting_title && (
                <Typography variant="body2" className="details">
                  {t("labels.meeting_title")} : {item?.meeting_title}
                </Typography>
              )}
              {item?.created_at && (
                <Typography variant="body2" className="details">
                  {t("labels.date")} :{" "}
                  {moment(item?.created_at ?? item?.created_at).format("LLL")}{" "}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          className=""
          item
          xs={6}
          md={6}
          sm={12}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <ComponentButton
            value={t("labels.preview")}
            variant={"contained"}
            class={"buttonFill"}
            sx={{ paddingRight: "0px !important" }}
            callback={() => {
              item?.shareType == 3
                ? history.push(`/form_preview/${"assigned"}/${item?.id}`)
                : window.open(`${baseURL}/${item?.path}`, "_blank");
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileView;
