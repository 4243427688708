import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentSelect from "../../../componentGroups/componentSelect";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";
import ComponentButton from "../../../componentGroups/componentButton";
import { useHistory } from "react-router-dom";
import axios from "axios";
import http from "../../../../api/axios";
import "../style.css";
import LoadingContext from "../../../../context/LoaderContext";

const INITIAL_VALUE = {
  requestTypeId: "",
  sendTo: "",
  subject: "",
  departmentId: "",
};

const AddRequestType = () => {
  const { setLoader } = useContext(LoadingContext);
  const { t, i18n } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [reqData, setReqData] = useState(INITIAL_VALUE);
  const [dropdownOptions, setDropDownOptions] = useState({
    departments: [],
    emp: [],
    requestType: [],
  });
  const [errors, setErrors] = useState({});

  const handleBack = () => {
    setReqData(INITIAL_VALUE);
    history.push("/requestAssign");
  };

  /**
   *
   * @param {*} key  dropdown options key
   * @param {*} id   category id on dropdown field
   */
  const loadDropdownOptions = async (
    key,
    id,
    type = "post",
    endPoint = "/api/dropdown/show-options"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("id", id);
      res = await http.getAllDataApi(t, endPoint, formData);
      res = res?.data;
    }
    if (type === "get") {
      res = await http.getApi(t, `${endPoint}`);
    }
    const options = res?.map((op) => {
      if (!op?.name) {
        return {
          ...op,
          name: op.departmentName ? op.departmentName : op.title ? op.title : op?.option
        };
      } else {
        return op;
      }
    });
    setDropDownOptions((prevVals) => ({
      ...prevVals,
      [key]: options,
    }));
  };

  /**
   * add custom options in dropdowns
   * @param {*} val
   * @param {*} key
   * @param {*} category_id
   * @param {*} type
   * @param {*} endPoint
   */
  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category",
    msg
  ) => {
    let res = {};
    if (type === "post") {
      setLoader(true);
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", i18n.language === "en" ? 1 : 2);
      res = await http.postApi(t, endPoint, formData, msg);
      setLoader(false);
      if (!!res) {
        loadDropdownOptions(key, category_id);
      }
    }
  };

  const validate = (data) => {
    let error = {};
    if (data.requestTypeId === undefined || data.requestTypeId === "") {
      error.requestTypeId = "errors.select_request_type";
    }
    if (data.sendTo === undefined || data.sendTo === "") {
      error.sendTo = "errors.select_send_to";
    }
    if (data.subject === undefined || data?.subject?.trim() === "") {
      error.subject = "errors.enter_subject";
    }
    if (data.departmentId === undefined || data?.departmentId === "") {
      error.departmentId = "errors.select_deaprtments";
    }
    if (data.documentId === undefined || data?.documentId === "") {
      error.documentId = "errors.select_doccument";
    }
    return error;
  };

  useEffect(() => {
    if (reqData?.departmentId) {
      setLoader(true);
      axios
        .all([
          loadDropdownOptions(
            "emp",
            "",
            "get",
            `/api/employee/all/department/${reqData?.departmentId}`
          ),
        ])
        .finally(() => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData?.departmentId]);

  useEffect(() => {
    axios
      .all([
        loadDropdownOptions("requestType", 16),
        loadDropdownOptions("departments", "", "get", `/api/department`),
        loadDropdownOptions("document", "", "get", "/api/form_doc/forms/getAll/forms")
      ])
      .finally(() => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validate(reqData);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqData]);

  const createReq = async () => {
    let error = validate(reqData);
    if (Object.keys(error).length === 0) {
      const formData = new FormData();
      formData.append("requestTypeId", reqData?.requestTypeId ?? "");
      formData.append("documentId", reqData?.documentId ?? "");
      formData.append("sendTo", reqData?.sendTo ?? "");
      formData.append("subject", reqData?.subject ?? "");
      setLoader(true);
      const res = await http.postApi(
        t,
        "/api/requestandassign/create",
        formData
      );
      setLoader(false);
      if (!!res) {
        handleBack();
      }
    } else {
      setErrors(error);
    }
  };
  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="DashTitle" variant="h4">
              {t("titles.create_new_request")}
            </Typography>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "40px" }}>
            <Grid className="AddEmpGenInfo" container rowSpacing={2}>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.request_type")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.requestType}
                    name="requestTypeId"
                    value={reqData?.requestTypeId}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                    enableDynamicOptionInput={true}
                    optionInputClass={"custonInput"}
                    onOptionSave={(e, val) =>
                      addDropdownOptions(
                        val,
                        "requestType",
                        16,
                        "labels.request_type_added_successfully"
                      )
                    }
                  />
                  {errors?.requestTypeId && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.requestTypeId)}`}
                    </div>
                  )}
                </div>
              </Grid>
              {/* //////////////////////////// Add Document Field ////////////////////////////////////////////////// */}
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.document")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    className="GenDropdown"
                    option={dropdownOptions?.document}
                    name="documentId"
                    value={reqData?.documentId}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.documentId && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.documentId)}`}
                    </div>
                  )}
                </div>
              </Grid>
              {/* ///////////////////////////////////////// End //////////////////////////////////////////////////// */}
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("titles.department_name")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    className="GenDropdown"
                    option={dropdownOptions?.departments}
                    name="departmentId"
                    value={reqData?.departmentId}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                        sendTo: "",
                      });
                    }}
                  />
                  {errors?.departmentId && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.departmentId)}`}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.send_to")} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentSelect
                    class={"custonInput"}
                    className="GenDropdown"
                    option={dropdownOptions?.emp?.filter(
                      (item) => item?.id !== user?.id
                    )}
                    name="sendTo"
                    value={reqData?.sendTo}
                    callback={(evt) => {
                      setReqData({
                        ...reqData,
                        [evt.target.name]: evt.target.value,
                      });
                    }}
                  />
                  {errors?.sendTo && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.sendTo)}`}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.request_subject")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="subject"
                    minRows={4}
                    placeholder={"Enter the description"}
                    value={reqData?.subject}
                    callback={(e) =>
                      setReqData({
                        ...reqData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.subject && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {`* ${t(errors?.subject)}`}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{ marginTop: "5px" }}
              >
                <Grid item>
                  <ComponentButton
                    value={t("labels.discard")}
                    variant={"outlined"}
                    class={"buttonBlank outlineBtn Mright "}
                    callback={() => {
                      handleBack();
                    }}
                  />
                </Grid>
                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    value={t("labels.send")}
                    variant="contained"
                    class="buttonFill FillBtn"
                    callback={() => {
                      createReq();
                    }}
                  />
                </Grid>

              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AddRequestType;
