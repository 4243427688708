import React, { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "./appbar";
import SideBar from "./sideBarNav";

// import RoutingComp from "../routing/routing";
import RoutingComp from "../routing/Routes";
import { useEffect, useState } from "react";
import Login from "../pages/login";
import Loader from "../../api/loader";
import { ToastContainer, toast } from "react-toastify";
import LoadingContext from "../../context/LoaderContext";
import cookie from "js-cookie";

const currentLanguageCode = cookie.get("i18next") || "en";
const initialLanguage = currentLanguageCode || "en";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const { loader, setLoader } = useContext(LoadingContext);
  const [langId, setlangId] = useState(1);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [login, setLogin] = useState(true);

  const [language, setLanguage] = useState(initialLanguage);
  const handleDrawerOpen = () => {
    setOpen(open == true ? false : true);
  };

  const [user, setUser] = useState({
    id: "",
    name: "",
    code: "",
    email: "",
    department_id: "",
    branch_id: "",
    gender: "",
    type: "",
    title: "",
    phone: "",
    isActive: "",
    employeeId: "",
    dob: "",
    addressId: "",
    fax: "",
    fatherName: "",
    motherName: "",
    status: "",
  });

  const handleDrawerClose = () => {
    setOpen(open == true ? false : true);
    document.body.className = open == true ? "sideBarHide" : "sideBarShow";
  };
  const [windowDimenion, detectHW] = useState({ winWidth: window.innerWidth });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    });
  };
  useEffect(() => {
    if (windowDimenion?.winWidth < 991 && open) {
      setOpen(false);
      document.body.className = "sideBarHide";
    } else if (windowDimenion?.winWidth > 991 && !open) {
      document.body.className = "sideBarShow";
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimenion?.winWidth]);

  /**
   * adding and event listner for width
   */
  useEffect(() => {
    detectSize();
    window.addEventListener("resize", detectSize);
  }, []);

  /**
   * removing event listner on dismount
   * so that it doe't call the event listner infinitely
   */
  useEffect(
    () => () => {
      window.removeEventListener("resize", detectSize);
    },
    []
  );

  const handleLogin = () => {
    setLogin(false);
  };
  const changeLanguage = (lang) => {
    setLanguage(lang);
  };
  const user1 = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <ToastContainer />
      <Loader loader={loader} />
      {user1 === null ? (
        <Login
          user={user1}
          setUser={setUser}
          setLogin={setLogin}
          setLoader={setLoader}
        />
      ) : (
        <Box
          className={
            language === "en"
              ? " mainInnerRouting "
              : " arabic mainInnerRouting "
          }
          style={{ backgroundColor: "#F5F5F5", height: "100%" }}
        >
          <MuiAppBar
            open={open}
            socketData={props?.socketData}
            setlangId={setlangId}
            setLoader={setLoader}
            changeLanguage={changeLanguage}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
          <Box sx={{ display: "flex", height: "100vh" }}>
            <SideBar
              open={open}
              className={`main_nav ${
                windowDimenion?.winWidth > 991
                  ? "sidebarfixed"
                  : "sidebarpopOver"
              } ${language === "en" ? "left-0" : "right-0"}`}
              style={{ height: "90%", overflow: "auto" }}
            />
            <Box
              className={`mainInnerRouting ${
                windowDimenion?.winWidth > 991
                  ? open
                    ? language === "en"
                      ? "ml-305"
                      : "mr-305"
                    : ""
                  : ""
              }`}
            >
              <RoutingComp setLoader={setLoader} langId={langId} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
