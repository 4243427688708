import React, { useEffect, useState } from "react";
import { Grid, Typography, Stack } from "@mui/material";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import http from "../../../../api/axios";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import validate from "../../../../Hooks/branch_validacion";
import { useTranslation } from "react-i18next";
import ComponentAutoComplete from "../../../componentGroups/componectAutoComplete";
export default function GeneralInfo({
  getCity,
  countries,
  cities,
  departments,
  Id,
  setLoader,
  branchState,
  setBranchState,
  errors,
  setErrors,
}) {
  const { t } = useTranslation();
  console.log(branchState, departments, "branch_departmentsbranch_departmentsbranch_departments");
  // useEffect(() => {
  //   if (Object.keys(errors).length != 0) {
  //     let error = validate.useBranchEditValidate(branchState)
  //     setErrors(error)
  //   }
  //   setLoader(false)
  // }, [branchState]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "countryName") {
      getCity(value);
      setBranchState({ ...branchState, [name]: value });
    } else {
      setBranchState({ ...branchState, [name]: value });
    }
  };

  const handleAddressFields = () => {
    let s1 = { ...branchState };
    let error = validate.useBranchEditValidate(branchState);
    if (Object.keys(error).length != 0) {
      setErrors(error);
    } else {
      s1.branches_addresses.push({ address: branchState?.address });
      setBranchState(s1);
    }
  };
  const removeAddress = (index) => {
    let s1 = { ...branchState };
    s1.branches_addresses.splice(index, 1);
    setBranchState(s1);
  };

  const handleBranchAddress = (e, index) => {
    const { value, name } = e.target;
    let s1 = { ...branchState };
    s1.branches_addresses[name].address = value;
    setBranchState(s1);
  };

  return (
    <Grid className="AddEmpGenInfo" container rowSpacing={2}>
      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.branch_name")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <ComponentTextString
          class={"custonInput"}
          value={branchState?.branchName}
          name="branchName"
          callback={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.branch_code")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <ComponentTextString
          class={"custonInput"}
          disable="true"
          name="branchCode"
          value={branchState?.branchCode}
        />
      </Grid>

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.country")}
        </Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        <ComponentSelect
          option={countries}
          name="countryName"
          value={branchState?.countryName}
          callback={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.city")}
        </Typography>
      </Grid>

      <Grid className="CustomWidth" item xs={11} md={9}>
        <ComponentSelect
          option={cities}
          name="cityName"
          value={branchState?.cityName}
          callback={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel">
          {t("labels.department")}
        </Typography>
      </Grid>

      <Grid className="CustomWidth" item xs={11} md={9}>
        {branchState?.branch_departments &&
          <Stack
            spacing={3}
            sx={{ width: "auto" }}
            className="authoritiesGroup multiselect"
          >
            <ComponentAutoComplete
              options={departments}
              values={branchState?.branch_departments}
              optionName={"departmentName"}
              callback={(e, value) => {
                setBranchState({
                  ...branchState, branch_departments
                    : value
                });
              }}
              disable={false}
            />
          </Stack>
        }
      </Grid>
      {/* <Grid item xs={2} md={2}>
        <Typography variant="body1" className="customLabel">
          Address Details
        </Typography>
      </Grid>
      <Grid className="" item xs={10} md={10} >
        <div style={{ display: "flex" }}>
          <ComponentTextString
            class={"custonInput"}
            name="address"
            value={branchState?.address}
            callback={handleChange}
          />
          <AddCircleRoundedIcon sx={{ mt: 1, color: "green", cursor: "pointer" }} onClick={() => handleAddressFields()} />
        </div>
        {errors?.address && <span className="" style={{ fontSize: "12px", color: "red" }}>{errors.address}</span>}
      </Grid>
      */}
      {branchState?.branches_addresses?.map((op, index) => (
        <>
          <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
            <Typography variant="body1" className="customLabel">
              {t("labels.address_detail")}
              {index + 1}
            </Typography>
          </Grid>
          <Grid className="CustomWidth" item xs={11} md={9}>
            <div style={{ display: "flex" }}>
              <ComponentTextString
                class={"custonInput"}
                value={op.address}
                name={index}
                callback={(e) => handleBranchAddress(e)}
              />
              {index == 0 ? (
                <AddCircleRoundedIcon
                  sx={{ mt: 1, color: "green", cursor: "pointer" }}
                  onClick={() => handleAddressFields()}
                />
              ) : (
                <CancelRoundedIcon
                  sx={{ mt: 1, color: "red", cursor: "pointer" }}
                  onClick={() => removeAddress(index)}
                />
              )}
            </div>
          </Grid>
        </>
      ))}

      <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
        <Typography variant="body1" className="customLabel"></Typography>
      </Grid>
      <Grid className="CustomWidth" item xs={11} md={9}>
        {errors?.addressArr &&
          errors?.addressArr?.map((op, index) => (
            <span
              className=""
              style={{ fontSize: "12px", color: "red" }}
              key={index}
            >
              {op}
            </span>
          ))}
      </Grid>
    </Grid>
  );
}
