import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ComponentButton from "../../../componentGroups/componentButton";

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormInput from "./FormInput";
import { useFormik } from "formik";
import * as YUP from "yup";
import { LargeText } from "../../Technical Department";
import { patchApi, postApi } from "../../../../api/axios";
import Svg from "../../../../assets/svg/svg";
import auth from "../../../../services/auth";
import http from "../../../../api/axios";
import ComponentUpload from "../../../componentGroups/componentUpload";
import FileView from "../../PartnerScreens/Documents/FileView";
export default function ReferencesuggestionDetails(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams();
  const refPreview = JSON.parse(localStorage.getItem("reference-info-page"))
  const category = JSON.parse(localStorage.getItem("category"));
  // const location = useLocation();
  // const [file, setFile] = useState("");
  const [fileError, setFileError] = useState('')
  const [refState, setRefState] = useState();

  useEffect(() => {
    if (localStorage.getItem("reference")) {
      let data = JSON.parse(localStorage.getItem("reference"));
      // setFile(data.file);
      setRefState(data);
    }
    return () => {
      localStorage.removeItem("reference");
      localStorage.removeItem("reference-info-page");
    };
  }, []);


  // const removeFile = async () => {
  //   if (file?.id) {
  //     await http.deleteApi(
  //       t,
  //       `/api/references/one/${file.id}`,
  //       "labels.file_deleted_successfully"
  //     );
  //     setFile({});
  //   } else {
  //     setFile({});
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      title: refState?.title ?? "",
      description: refState?.description ?? "",
      author: refState?.author ?? "",
      reviewer: refState?.reviewer ?? "",
      referenceCode: refState?.referenceCode ?? "",
      file: refState?.file ?? "",
    },
    // validateOnMount: true,
    enableReinitialize: true,
    validationSchema: YUP.object({
      title: YUP.string()
        .required("title is required.")
        .min(2, "must be at least 2 characters"),
      description: YUP.string()
        .required("description is required.")
        .min(10, "must be at least 10 characters"),
      author: YUP.string()
        .required("author is required.")
        .min(2, "must be at least 2 characters"),
      reviewer: YUP.string()
        .required("reviewer is required.")
        .min(2, "must be at least 2 characters"),
      referenceCode: YUP.number()
        .required("reference code is required.")
        .min(2, "must be at least 2 characters"),
    }),
    onSubmit: (values) => {
      const fd = new FormData();
      if (values.file) {
        fd.append("title", values.title);
        fd.append("description", values.description);
        fd.append("author", values.author);
        fd.append("reviewer", values.reviewer);
        fd.append("referenceCode", values.referenceCode);
        fd.append("categoryId", category?.id);
        fd.append("file", values.file);
      } else {
        return setFileError('reference file is required.')
      }
      if (category?.id) {
        if (localStorage.getItem("reference")) {
          // edit request
          patchApi(t, `/api/references/one/${refState.id}`, fd).then((res) => {
            if (res) history.push(`/reference-categories/references`);
          });
        }
        if (!localStorage.getItem("reference")) {
          // add request
          postApi(t, `/api/references/create`, fd).then((res) => {
            if (res) history.push(`/reference-categories/references`);
          });
        }
      }
    },
  });
  // console.log(file, "refState");
  return (
    <div className="SuggestDetailPg">
      <div class="CommonPd">
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.reference_form")}
              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            <FormInput
              name="title"
              label={t("labels.title")}
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : null
              }
              required={true}
              disabled={refPreview ? true : false}

            />

            <FormInput
              value={formik.values.author}
              name="author"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.author_name")}
              error={
                formik.errors.author && formik.touched.author
                  ? formik.errors.author
                  : null
              }
              required={true}
              disabled={refPreview ? true : false}

            />

            <FormInput
              value={formik.values.reviewer}
              name="reviewer"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.reviewer_name")}
              error={
                formik.errors.reviewer && formik.touched.reviewer
                  ? formik.errors.reviewer
                  : null
              }
              required={true}
              disabled={refPreview ? true : false}

            />

            <FormInput
              value={formik.values.referenceCode}
              name="referenceCode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.reference_code")}
              error={
                formik.errors.referenceCode && formik.touched.referenceCode
                  ? formik.errors.referenceCode
                  : null
              }
              required={true}
              disabled={refPreview ? true : false}

            />

            {formik.values?.file ?
              <div style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between"
                width: "100%",
                padding: "5px"
              }}>
                <span style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#2b564c',
                  flex: ' 0 0 15%'
                }}>{(refPreview || refState?.file_url) ? t("labels.preview_file") : t("labels.choose_file")}</span>
                <div style={{ flex: 1 }}>

                  {formik.values?.file ? <FileView index={1} item={formik.values?.file} /> : ""}
                </div>
              </div>
              : <div className="FormsuggestForm">
                <label for="file">{(refPreview || refState?.file_url) ? t("labels.preview_file") : t("labels.choose_file")}</label>

                {!refPreview ? <div className="">

                  <ComponentUpload
                    lable={t("labels.upload_files")}
                    name="file"
                    accept='application/pdf'
                    required={true}
                    callback={(e) => {
                      formik.setFieldValue('file', e.target.files[0])
                      // s1.documents.push(e.target.files[0])
                    }}
                  />
                  {fileError ?
                    <p style={{
                      color: "#ff0101",
                      fontSize: "13px",
                    }}>*{fileError}</p>
                    : ''}
                </div> : ''}
              </div>
            }

            {/* 
                {Object.keys(file).length != 0 && (
                  <div>
                    <span>{file?.name}</span>
                    <span onClick={() => removeFile({})}>
                      <Svg type="close" />
                    </span>
                  </div>
                )} */}



            <div className="FormsuggestForm">
              <label for="description">{t("labels.description")}</label>

              <LargeText
                desc={formik.values.description}
                inputName="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.errors.description && formik.touched.description
                    ? formik.errors.description
                    : null
                }
                required={true}
                disabled={refPreview ? true : false}
              />
            </div>
          </form>
          {!refPreview ? <div className="RefresugstBtn">
            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.reject")}
              variant={"outlined"}
              class={"buttonBlank Mright delbtnRefrence"}
              callback={() => history.push(`/reference-categories/references`)}
            />
            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.add_to_library")}
              variant={"contained"}
              class={"buttonFill"}
              callback={formik.submitForm}
            />
          </div> : ''}
        </div >
      </div >
    </div >
  );
}
