import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ComponentButton from "../../componentGroups/componentButton";
import ComponentModal from "../../componentGroups/componectModel";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "react-i18next";
import http from "../../../api/axios";
import validate from "../../../Hooks/department_valdaction";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";

export default function Department(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [depState, setDepState] = useState({});
  const [depData, setDepData] = useState([]);
  const [errors, setErrors] = useState({});
  const [modalData, setModelData] = useState({
    model: false,
    data: [],
    title: "",
  });

  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addNew") {
      setModelData({
        ...modalData,
        model: true,
        title: "titles.add_department",
        data: [
          {
            lable: "titles.department_name",
            field: {
              required: true,
              type: "text",
              placeholder: "titles.department_name",
            },
            name: "departmentName",
          },
        ],
        buttonGroup: [
          {
            value: "labels.discard",
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              setErrors({});
              setDepState({
                ...depState,
                departmentCode: depState?.departmentCode,
              });
              handleCloseModel();
            },
          },
          {
            value: "labels.create",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (value) => {
              addDept(value);
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.deptCreateValidate(depState);
      setErrors(error);
    }
  }, [depState]);

  const getApiData = async () => {
    let res = await http.getApi(t, "/api/department");
    setDepState({ ...depState });
    setDepData(res);
    props.setLoader(false);
  };

  useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  const addDept = async (obj) => {
    let error = validate.deptCreateValidate(obj);
    if (Object.keys(error).length === 0) {
      handleCloseModel();
      props.setLoader(true);
      const formData = new FormData();
      formData.append("departmentName", obj.departmentName);
      formData.append("langId", 1);
      const response = await http.postApi(t, "/api/department", formData);
      props.setLoader(false);
      setDepState({});
      getApiData();
    } else {
      setErrors(error);
    }
  };
  const handleOpenDept = (id) => {
    history.push(`/departments/edit-department/${id}`);
  };

  return (
    <div>
      <ComponentModal
        modalData={modalData}
        setPerantState={setDepState}
        perantState={depState}
        errors={errors}
      />
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="morningText" variant="h4">
              {t("titles.departments")}{" "}
            </Typography>
            <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                if (getUserHasAccess("department", 55)) {
                  handleModel("addNew");
                } else {
                  setAuthorityModal(true);
                }
              }}
            />
          </Grid>
          <Grid className="multiColsBreak">
            {depData?.map((op, index) => (
              <Grid item xs={12} sm={12} md={4} key={index}>
                <Card className="DeptContent">
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      className="iconText"
                    >
                      {op.departmentName}
                    </Typography>
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      {t("titles.department_code")} : {op.departmentCode}
                    </Typography>
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      {t("titles.employees_number")} : {op.userCount}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "end" }}>
                    <Button
                      className="OpenBtn"
                      size="small"
                      onClick={() => handleOpenDept(op.id)}
                      endIcon={
                        <EastIcon
                          sx={{ color: "#2B564C", paddingLeft: "5px" }}
                        />
                      }
                      sx={{ color: "#2B564C" }}
                    >
                      {t("labels.open")}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
