import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import GenericModal from "../../../componentGroups/genericModal";
import MinHeightTextarea from "../../../componentGroups/componentTextarea";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";

const INITIAL_VALUES = {
  title: "",
  sendTo: "",
  details: "",
};

const SendReminderModal = ({ id = 1, open, onCLose }) => {
  const { t, i18n } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [reminderData, setReminderData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState({});
  const [employees, setEmployees] = useState([]);
  const handleClose = () => {
    setErrors({});
    setReminderData(INITIAL_VALUES);
    onCLose();
  };

  const handleValidate = (data) => {
    let errors = {};
    if (data?.title === undefined || data?.title?.trim() === "") {
      errors.title = "errors.enter_reminder_title";
    }
    if (data?.sendTo === undefined || data?.sendTo === "") {
      errors.sendTo = "errors.select_send_to";
    }
    if (data?.details === undefined || data?.details?.trim() === "") {
      errors.details = "errors.enter_details";
    }
    return errors;
  };

  const getDropDownOptions = async () => {
    setLoader(true);
    const emp = await http.getApi(t, "/api/employee/dropdown/all");
    setLoader(false);
    const employees = emp?.map((item) => {
      return { ...item, option: item?.name };
    });
    if (!!employees && employees?.length > 0) {
      setEmployees(employees);
    }
  };

  useEffect(() => {
    getDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = handleValidate(reminderData);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminderData]);

  const handleReminder = async () => {
    let error = handleValidate(reminderData);
    if (Object.keys(error)?.length === 0) {
      setLoader(true);
      const formData = new FormData();
      const updatedData = { ...reminderData };
      delete updatedData.sendTo;
      Object.keys(updatedData).forEach((element) => {
        formData.append(element, updatedData[element]);
      });
      formData.append("send_to", reminderData.sendTo);
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.postApi(
        t,
        `/api/meeting/${id}/reminder/send`,
        formData
      );
      setLoader(false);
      if (!!res) {
        handleClose();
      }
    } else {
      setErrors(error);
    }
  };

  return (
    <GenericModal title={"labels.send_reminder"} open={open}>
      <div className="scroll-auto ModalContent" style={{ minHeight: "270px" }}>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.reminder_title")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.title && (
              <span className="validate">{t(errors.title)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentTextString
                placeholder={""}
                name="title"
                value={reminderData?.title}
                callback={(e) => {
                  setReminderData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
              {errors?.title && <span>{t(errors.title)}</span>}
            </div>
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.send_to")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.sendTo && (
              <span className="validate">{t(errors.sendTo)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentSelect
                value={reminderData?.sendTo}
                name="sendTo"
                option={employees}
                callback={(e) =>
                  setReminderData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {errors?.sendTo && (
                <span className="validate">{t(errors.sendTo)}</span>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.details")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.details && (
              <span className="validate">{t(errors.details)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <MinHeightTextarea
                class={"custonInput"}
                minRows={6}
                placeholder={""}
                value={reminderData?.details}
                name="details"
                callback={(e) =>
                  setReminderData({
                    ...reminderData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {errors?.details && (
                <span className="validate">{t(errors.details)}</span>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.discard")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn"}
              callback={() => {
                handleClose();
              }}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t("labels.save")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => handleReminder()}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default SendReminderModal;
