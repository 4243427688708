import React, { useState } from "react";
import {
  Modal,
  Button,
  Box,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Accordion,
  InputLabel,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ComponentButton from "./componentButton";
import ComponentUpload from "./componentUpload";
import ComponentSwitch from "./componentSwitch";
import ComponentTextString from "./componentTextString";
import ComponentDatePicker from "./componentDatepicker";
import ComponentSelect from "./componentSelect";
import ComponentCKEditer from "./componentCKEditer";
import ComponentAccordion from "./componentAccordion";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  // borderRadius: "2px solid red",
  // border: "2px solid ",
  boxShadow: 20,
  // p: 4,
};

export default function ComponentFilterModal(props) {
  const [modelState, setmodelState] = useState({});
  const { t } = useTranslation();
  const handleCheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked == true) {
      props.setfilterDisplay([...props.filterDisplay, value]);
    } else {
      let staticVar = props.filterDisplay.splice(value, 1);
      props.setfilterDisplay(staticVar);
    }
  };

  const styleHT = props.MHeight ? props.MHeight : 0;

  return (
    <Modal
      className="MainModal"
      open={props.modalData.model}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="ModalBG" sx={style}>
        <div className="ModalHeading">
          <Typography
            variant="h4"
            sx={{ paddingTop: "1px !important" }}
            className="morningText"
          >
            {t("labels.filter_options")}
          </Typography>
        </div>
        <div
          className="scroll-auto ModalContent"
          style={{ minHeight: styleHT }}
        >
          <div>
            <Accordion className="clientInfoDocAccodian">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4"> {t(props.title)} </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className="filterModeltXT" columnSpacing={2}>
                  {props.columns.map(
                    (op, index) =>
                      index != 0 &&
                      op?.hide == undefined && (
                        <Grid item xs={12} md={6} key={index}>
                          <Grid container className="filterModel">
                            <Grid item xs={12} md={3}>
                              <Typography variant="body2">
                                {t(op.lable)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <div class="filterModelAcc">
                                {op?.type === "statusSwitch" ? (
                                  <ComponentSwitch
                                    leftLabel={
                                      op?.leftLabel
                                        ? t(op?.leftLabel)
                                        : t("labels.inActive")
                                    }
                                    rightLabel={
                                      op?.rightLabel
                                        ? t(op?.rightLabel)
                                        : t("labels.active")
                                    }
                                    value={props?.filterData[op.name]}
                                    handleChange={(evt) => {
                                      props?.setFilterData({
                                        ...props.filterData,
                                        [op.name]: evt.target.checked,
                                      });
                                    }}
                                  />
                                ) : op?.type === "date" ? (
                                  <ComponentDatePicker
                                    class={"custonInput"}
                                    placeholder=""
                                    value={props?.filterData[op.name]}
                                    callback={(evt) => {
                                      props?.setFilterData({
                                        ...props.filterData,
                                        [op.name]: evt.target.value,
                                      });
                                    }}
                                  />
                                ) : op?.type === "dropDown" ? (
                                  <ComponentSelect
                                    class={"custonInput"}
                                    option={op?.options}
                                    value={props?.filterData[op.name]}
                                    callback={(evt) => {
                                      props?.setFilterData({
                                        ...props.filterData,
                                        [op.name]: evt.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  <ComponentTextString
                                    type={"text"}
                                    value={props?.filterData[op.name]}
                                    callback={(e) =>
                                      props?.setFilterData({
                                        ...props.filterData,
                                        [op.name]: e.target.value,
                                      })
                                    }
                                  />
                                )}{" "}
                                {/* <Typography variant="body2">
                              {" "}
                              <input
                                type="checkbox"
                                onChange={handleCheckbox}
                                value={index}
                                checked={props.filterDisplay.find(
                                  (op) => op == index
                                )}
                                style={{ background: "#ff0000" }}
                              />
                            </Typography> */}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Date</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} >
                    <div class="filterModelAcc">
                      <Typography variant="body2">Past Week Decissions</Typography>
                      <Typography variant="body2">  <input type="checkbox" onChange={handleCheckbox} /></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div class="filterModelAcc">
                      <Typography variant="body2">Past Month Decissions</Typography>
                      <Typography variant="body2">  <input type="checkbox" onChange={handleCheckbox} /></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}  >
                    <div class="filterModelAcc">
                      <Typography variant="body2">Other</Typography>
                      <Typography variant="body2">  <input type="checkbox" onChange={handleCheckbox} value="" /></Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} >
                    <div class="filterDate">
                      <div class="filterModelAcc">
                        <Typography variant="body2">  <input type="date" onChange={handleCheckbox} /></Typography>
                        <Typography variant="body2">From</Typography>
                      </div>
                      <div class="filterModelAcc">
                        <Typography variant="body2">  <input type="date" onChange={handleCheckbox} /></Typography>
                        <Typography variant="body2">To</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
         
            </Accordion>
          */}
          </div>
        </div>
        <div
          style={{ borderTop: "1px solid #2B564C ", marginTop: "20px" }}
          className="ModalFooter"
        >
          <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
            {!!props.enableReset && (
              <Grid item>
                <ComponentButton
                  value={t("labels.reset_filter_data")}
                  variant="outlined"
                  class="buttonBlank outlineBtn Mright"
                  callback={() => {
                    props.setModelData({ model: false });
                    props.setFilterData({ ...props.resetdata, filter: true });
                  }}
                />
              </Grid>
            )}
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant="outlined"
                class="buttonBlank outlineBtn Mright"
                callback={() => props.setModelData({ model: false })}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                callback={() => {
                  props.setModelData({ model: false });
                  props.setFilterData({ ...props.filterData, filter: true });
                }}
                value={t("labels.filter_result")}
                variant="contained"
                class="buttonFill FillBtn"
                style={{ marginLeft: "0px" }}
              />
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}
