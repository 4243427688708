import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Checkbox, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import GenericModal from "./genericModal";
import Svg from "../../assets/svg/svg";

/**
 * renders a modal to hide show columns
 * @param {*} param0
 * @returns
 */
const CoulmnFilterModel = ({ columns, setColumns }) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [selectAll, setSelectAll] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (!error) {
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Button
        key={"folterColumns"}
        variant={"outlined"}
        className={"buttonBlank"}
        onClick={(e) => handleClick(e)}
        startIcon={<Svg type="viewColumn" />}
      >
        {t("labels.view_columns")}
      </Button>
      <GenericModal
        title={"titles.filter_column"}
        open={open}
        enableCloseIcon={true}
        onClose={handleClose}
      >
        <div className="scroll-auto ModalContent" style={{ minHeight: "80px" }}>
          <Grid container md={12} className="modalGroup">
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    checked={selectAll}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setError("");
                      } else {
                        setError(t("errors.select_atleast_one_column"));
                      }
                      setSelectAll(e.target.checked);
                      let updatedColumns = columns.map((item) => {
                        if (item.name !== "checkbox") {
                          return { ...item, isActive: e.target.checked };
                        } else {
                          return item;
                        }
                      });
                      setColumns(updatedColumns);
                    }}
                  />
                }
                label={t("labels.select_all")}
              />
            </Grid>
            {columns
              ?.filter((item) => item.name !== "checkbox")
              ?.map((col) => {
                return (
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                          checked={col.isActive}
                          onChange={(e) => {
                            let updatedColumns = columns.map((item) => {
                              if (item.name === col.name) {
                                return { ...item, isActive: e.target.checked };
                              } else {
                                return item;
                              }
                            });
                            setColumns(updatedColumns);
                            let visibleColumns = updatedColumns?.filter(
                              (item) => item.isActive
                            );
                            let selectedColumn = updatedColumns?.filter(
                              (item) =>
                                item.isActive && item.name !== "checkbox"
                            );
                            if (!e.target.checked) {
                              if (selectAll) {
                                setSelectAll(false);
                              }
                              if (selectedColumn.length === 0) {
                                setError(t("errors.select_atleast_one_column"));
                                return false;
                              } else {
                                setError("");
                              }
                            } else {
                              if (
                                !selectAll &&
                                columns?.length === visibleColumns?.length
                              ) {
                                setSelectAll(true);
                              }
                              setError("");
                            }
                          }}
                        />
                      }
                      label={t(col.lable)}
                    />
                  </Grid>
                );
              })}
            {!!error && (
              <Grid item xs={12} md={12}>
                <span className="validate">{t(error)}</span>
              </Grid>
            )}
          </Grid>
        </div>
      </GenericModal>
    </>
  );
};

export default CoulmnFilterModel;
