import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography, ListItemAvatar, Avatar } from "@mui/material";
import ComponentButton from "../componentGroups/componentButton";
import profile from "../../assets/images/profile-edit.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ComponentModal from "../componentGroups/componectModel";
import Svg from "../../assets/svg/svg";
export default function Dashboard() {
  const { t } = useTranslation();

  const history = useHistory();
  const [menuLaw, setMenuLaw] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [modalData, setModelData] = useState({
    model: false,
    data: [],
    title: "",
  });
  const [dashState, setDashState] = useState({});
  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };
  const handleModel = (val) => {
    if (val == "executing") {
      setModelData({
        ...modalData,
        title: "Change Coordinator To Partners Board",
        model: true,
        data: [
          {
            lable: "Name",
            field: {
              type: "dropdown",
              option: ["Mohamed Ahmed", "Mohamed Ahmed"],
            },
          },
        ],
        buttonGroup: [
          {
            value: t("labels.discard"),
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              handleCloseModel();
            },
          },
          {
            value: t("labels.save"),
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: () => {
              handleCloseModel();
            },
          },
        ],
      });
    }
    if (val == "meetingValidation") {
      setModelData({
        ...modalData,
        title: "Meeting Mins Validation",
        model: true,
        data: [
          {
            lable: "Meeting Title",
            placeholder: "Meeting Title",
            field: {
              type: "text",
            },
          },
          {
            lable: "Department",
            field: {
              type: "dropdown",
              option: ["Mohamed Ahmed", "Mohamed Ahmed"],
            },
          },
          {
            lable: "Meeting Decisions",
            field: {
              type: "dropdown",
              option: ["Mohamed Ahmed", "Mohamed Ahmed"],
            },
          },
          {
            lable: "Meeting Decisions",
            field: {
              type: "file",
            },
          },
        ],
        buttonGroup: [
          {
            value: " Not Validate",
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              handleCloseModel();
            },
          },
          {
            value: "Validate",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: () => {
              handleCloseModel();
            },
          },
        ],
      });
    }
  };

  return (
    <>
      <ComponentModal
        modalData={modalData}
        setPerantState={setDashState}
        perantState={dashState}
      />

      <div>
        <div className="CommonPd">
          <Grid className="dashTopProfilEdit">
            <Grid>
              <Typography variant="h6" className="morningText">
                {t("titles.change_profile_picture")}
              </Typography>
            </Grid>
            <div className="proImg">
              <div className="profile-ImGsec">
                <Avatar
                  className="ProEditImg"
                  alt="ProfileEditPicture"
                  src={profile}
                />
                <div className="ProfileEditIocon">
                  <EditIcon className="editI" />
                </div>
              </div>
            </div>
            <div className="ProifileSection">
              <div
                className="ProfileditsEc"
                onMouseLeave={() => setMenuLaw(false)}
              >
                <ul className="menuTableListP">
                  <li>
                    <a
                      onClick={() => {
                        handleModel("");
                      }}
                    >
                      <Svg type={"change_profile_picture"} />
                      <span class=""> {t("titles.take_a_picture")}</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModel("");
                      }}
                    >
                      <Svg type={"add_nick_name"} />
                      <span class=""> {t("titles.upload_image")}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <Grid className="gridLeftbtnP">
              <ComponentButton
                value="Discard"
                variant={"outlined"}
                class={"buttonBlank Mright"}
                callback={() => history.push("")}
              />
              <ComponentButton
                value="Save Changes"
                variant={"contained"}
                class={"buttonFill"}
                callback={() => handleModel("")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
