import Dashboard from "../pages/dashboard";
import DashboardProfile from "../pages/dashboardProfile";
import Employess from "../pages/employees/index";
import Clients from "../pages/clients/index";
import Branches from "../pages/branches";
import Tasks from "../pages/tasks";
import ClientInfo from "../pages/clients/clientInfo";
import Coordinators from "../pages/coordinators";
import AddEmployee from "../pages/employees/addEmployees/index";
import Department from "../pages/departments/index";
import AddDepartment from "../pages/departments/addDepartment/index";
import FormDocumnent from "../pages/formDocument";
import CreateMeeting from "../pages/CreateMeeting/CreateMeeting";
import MeetingDetails from "../pages/companyDecision/meetingEmp/meetingDetails";
import Forms from "../pages/formDocument/forms/index";
import Documents from "../pages/formDocument/documents";
import PartnerDashboard from "../pages/PartnerScreens/dashboard";
import PartnerMeeting from "../pages/PartnerScreens/partnerMeeting";
import CoordinateDocument from "../pages/CoordinatorScreens/document";
import VotingValidation from "../pages/CoordinatorScreens/votingValidation";
import Notifaction from "../comman/notifaction/index";
import RequestAssign from "../pages/PartnerScreens/requestAssign";
import StudiesForms from "../pages/formDocument/forms/forms_admin_studies/studies";
import BodDecision from "../pages/PartnerScreens/bodDecision";
import ExpensesProfit from "../pages/PartnerScreens/expensesProfit";
import CreateDocument from "../pages/formDocument/forms/forms_admin_studies/addDocument/createDocument";
import FormPreview from "../pages/formDocument/forms/forms_admin_studies/preview";
import TaskInfo from "../pages/tasks/taskInfo";
import BranchInfo from "../pages/branches/branchInfo";
import ProfileTechnicalDepartmentsReferences from "../pages/ProfileModule/ProfileTechnicalDepartment/ProfileTechnicalDepartmentsReferences";
import PropheticTraditionReferences from "../pages/ProfileModule/ProfileTechnicalDepartment/PropheticTraditionReferences";
import ReferencesuggestionDetails from "../pages/ProfileModule/ProfileTechnicalDepartment/ReferencesuggestionDetails";
import ProfiledeptMeetingDecisions from "../pages/ProfileModule/ProfileTechnicalDepartment/ProfiledeptMeetingDecisions";
import Projects from "../pages/projects";
import ProjectInfo from "../pages/projects/projectInfo/index";
import Hrholidays from "../pages/ProfileModule/hrModule/vacations/vactions";
import HrProfileTasks from "../pages/ProfileModule/hrModule/profile_tasks";
import AccordionForm from "../pages/CreateMeeting/AddMeetingAccordian/AddMeetingAccordionForm";
import HorizontalLinearStepper from "../pages/CoordinatorScreens/votingValidation";
import Promotions from "../pages/ProfileModule/hrModule/Promotions";
import Exective from "../pages/LegalSecretary/executiveAttroneys";
import Reports from "../pages/Reports/reports";
import ReportTable from "../pages/Reports/reportTable";
import MatterReports from "../pages/Reports/matterReports";
import TechnicalResourcesReport from "../pages/Reports/technicalresourcesreport";
import EmployeesReport from "../pages/Reports/employeeReports/employeesReport";
import ManagerialReports from "../pages/Reports/managerialReports";
import DepartmentsReport from "../pages/Reports/departmentsReports";
import Attroneys from "../pages/LegalSecretary/attroneys";
import ExecutiveAttroneys from "../pages/LegalSecretary/executiveAttroneys";
import BodDecisionDetails from "../pages/PartnerScreens/BODMeeting/BodDecisionDetails";
import AddRequestType from "../pages/PartnerScreens/RequestType/addRequestType";
import DocumnetFolder from "../pages/PartnerScreens/Documents/DocumnetFolder";
import DocumnetFiles from "../pages/PartnerScreens/Documents/DocumentFiles";
import NewMeetingDecision from "../pages/Meeting/meeting_decsion/index";
import MeetingDecisionList from "../pages/Meeting";
import EmpAttendance from "../comman/attendance";
import AllComments from "../pages/formDocument/documents/allComment";
import PermisisionDenied from "./PermisisionDenied";
import AskForConsultation from "../pages/AcademicResearcher/AskForConsultation";
import ConsultationTables from "../pages/AcademicResearcher/ConsultationTables/ConsultationTables";
import MeetingAndDecisions from "../pages/ExecutiveSecretary/MeetingAndDecisions";
import SuggestionForm from "../pages/ProfileModule/ProfileTechnicalDepartment/SuggestionForm";
import CategoryForm from "../pages/ProfileModule/ProfileTechnicalDepartment/CategoryForm";
import ConsultationInfo from "../pages/AcademicResearcher/ConsultationTables/ConsultationInfo";
import PromotionDetail from "../pages/ProfileModule/hrModule/PromotionDetail";
import SearchResult from "../pages/SearchResult";
import LateAttendance from "../pages/Reports/performaceReports/lateAttendance";
import PerformanceReport from "../pages/Reports/performaceReports/performanceReport";
import LateMatters from "../pages/Reports/performaceReports/lateMatters";
import TimeOffReport from "../pages/Reports/timoffReport";
import SelectedEmployeeReport from "../pages/Reports/employeeReports/selectedEmployeeReport";
import LateAttendanceDetail from "../pages/Reports/performaceReports/lateAttendenceDetail";
import LateMatterDetail from "../pages/Reports/performaceReports/lateMatterDetail";
import Settings from "../pages/settings";
import CategoryListing from "../pages/settings/GlobalFields/categoreyListing";
import GlobalFields from "../pages/settings/GlobalFields";
import EmployeePerformance from "../pages/settings/Employee Performance";
import AttendanceScore from "../pages/settings/Employee Performance/attendanceScore";
import TaskScore from "../pages/settings/Employee Performance/taskScore";
import PenalitiesAndBonus from "../pages/settings/Penalties And Bonus";
import WorkingSchedule from "../pages/settings/Working Schedule";
import Broadcasts from "../pages/Broadcasts/Broadcasts";
import VacationSettings from "../pages/ProfileModule/hrModule/vacations/VacationSettings";
import Timeoffs from "../pages/ProfileModule/hrModule/timeoffs/Timeoffs";
import BroadcastForm from "../pages/Broadcasts/BroadcastForm";
import Penalities from "../pages/settings/Penalties And Bonus/Penalties";
import Bonus from "../pages/settings/Penalties And Bonus/Bonus";
import AddEditAttendanceScore from "../pages/settings/Employee Performance/addEditAttendanceScore";
import AddEditTaskScore from "../pages/settings/Employee Performance/addEditTaskScore";
import AddEditPenalties from "../pages/settings/Penalties And Bonus/Penalties/AddEditPenalties";
import AddEditBonus from "../pages/settings/Penalties And Bonus/Bonus/AddEditBonus";
import AddEditWorkingHour from "../pages/settings/Working Schedule/AddEditWorkingHour";
import JobPosition from "../pages/settings/JobPosition";
import AddEditJobPosition from "../pages/settings/JobPosition/AddEditJobPosition";
import TaskLog from "../pages/tasks/taskInfo/TaskLog";
import AddEditTaskLog from "../pages/tasks/taskInfo/AddEditTaskLog";
import AddEditPenalty from "../pages/employees/addEmployees/penaltyAndBonus/AddEditPenalty";
import AddEditEmployeeBonus from "../pages/employees/addEmployees/penaltyAndBonus/AddEditEmployeeBonus";
import EditEmployeeAttandance from "../pages/employees/addEmployees/EditEmployeeAttandance";
import AllocationForm from "../pages/Allocation/AllocationForm";
import Allocations from "../pages/Allocation/Allocations";

// Add Accountingn Files here
import Sales from "../pages/Accounting/Sales";
import Purchase from "../pages/Accounting/Purchase";
import Adminsitration from "../pages/Accounting/Adminsitration";
import FinancialServices from "../pages/Accounting/Financial Services";
import Services from "../pages/Accounting/Services";
import Inventory from "../pages/Accounting/Inventory";
import HrAndPayroll from "../pages/Accounting/HR Payroll";
import Loan from "../pages/Loan";
import AddEditLoanRequest from "../pages/Loan/AddEditLoanRequest";
import Vacancies from "../pages/Recruitments/Vacancies";
import VacancyForm from "../pages/Recruitments/VacancyForm";
import CandidateForm from "../pages/Recruitments/CandidateForm";
import VacancyInfo from "../pages/Recruitments/VacancyInfo";
import CandidateInfo from "../pages/Recruitments/CandidateInfo";
import BroadcastInfo from "../pages/Broadcasts/BroadcastInfo";
import AllocationApporval from "../pages/Allocation/AllocationApporval";
import Matters from "../pages/Matters/Matters";


export const NAVIGATION_ROUTES = [
  //dasboard
  {
    componentName: Dashboard,
    path: "/",
    hasAuthority: false,
    authorities: [],
    name: "routes.dashboard",
  },
  {
    componentName: SearchResult,
    path: "/searchResult",
    hasAuthority: false,
    authorities: [],
    name: "routes.search_results",
  },
  //notification
  {
    componentName: Notifaction,
    path: "/notification",
    hasAuthority: false,
    authorities: [],
    name: "routes.notification",
  },
  //partner Board Module
  {
    componentName: PartnerDashboard,
    path: "/partnerDashBoard",
    hasAuthority: false,
    authorities: [],
    name: "routes.partner_board",
  },
  {
    componentName: PartnerMeeting,
    path: "/partnerMeetings",
    hasAuthority: true,
    authorities: [140],
    name: "routes.partner_meetings",
  },

  {
    componentName: RequestAssign,
    path: "/requestAssign",
    hasAuthority: true,
    authorities: [155],
    name: "routes.requests_assign",
  },
  {
    componentName: AddRequestType,
    path: "/requestAssign/addrequest",
    hasAuthority: true,
    authorities: [156],
    name: "routes.add_requests",
  },
  {
    componentName: BodDecision,
    path: "/bodDecision",
    hasAuthority: true,
    authorities: [145],
    name: "routes.bod_decision",
  },

  {
    componentName: BodDecisionDetails,
    path: "/bodDecision/decionDetails/:id",
    hasAuthority: true,
    authorities: [145],
    name: "routes.bod_decision_info",
  },
  {
    componentName: DocumnetFolder,
    path: "/partnerBoard/documents",
    hasAuthority: true,
    authorities: [143],
    name: "routes.partner_board_documents",
  },
  {
    componentName: DocumnetFiles,
    path: "/partnerBoard/documents/docFiles/:id",
    hasAuthority: true,
    authorities: [143],
    name: "routes.document_details",
  },
  {
    componentName: ExpensesProfit,
    path: "/expenseProfit",
    hasAuthority: false,
    authorities: [],
    name: "labels.expese_and_profits",
  },
  //coordinator
  {
    componentName: Coordinators,
    path: "/coordinators",
    hasAuthority: true,
    authorities: [83],
    name: "routes.coordinator",
  },

  {
    componentName: HorizontalLinearStepper,
    path: "/votingvalidation",
    hasAuthority: false,
    authorities: [],
    name: "routes.voting_validation",
  },
  {
    componentName: CoordinateDocument,
    path: "/coordinator/documents",
    hasAuthority: false,
    authorities: [],
    name: "routes.coordinator_documents",
  },
  {
    componentName: AllComments,
    path: "/allComments/:shareType/:fileId",
    hasAuthority: true,
    authorities: [53],
    name: "",
  },
  //Meeting Decisions
  {
    componentName: MeetingDecisionList,
    path: "/meeting-decisons",
    hasAuthority: true,
    authorities: [139],
    name: "routes.meeting_decisions",
  },

  // Voting And Validation
  {
    componentName: VotingValidation,
    path: "/votingValidations",
    hasAuthority: true,
    authorities: [146],
    name: "routes.voting_validation",
  },

  //Company decisions
  {
    componentName: MeetingAndDecisions,
    path: "/company-decision",
    hasAuthority: true,
    authorities: [113],
    name: "routes.company_meeting_history",
  },

  //emp attendence
  {
    componentName: EmpAttendance,
    path: "/empAttendance",
    hasAuthority: false,
    authorities: [],
    name: "routes.employee_attendance",
  },
  //employee
  {
    componentName: Employess,
    path: "/employees",
    hasAuthority: true,
    authorities: [70],
    name: "routes.employee",
  },

  //clients
  {
    componentName: Clients,
    path: "/clients",
    hasAuthority: true,
    authorities: [87],
    name: "routes.clients",
  },

  //branch
  {
    componentName: Branches,
    path: "/branches",
    hasAuthority: true,
    authorities: [64],
    name: "routes.branches",
  },
  {
    componentName: BranchInfo,
    path: "/branches/branchInfo/:branchId",
    hasAuthority: true,
    authorities: [62],
    name: "routes.edit_branch",
  },

  //task
  {
    componentName: Tasks,
    path: "/tasks",
    hasAuthority: true,
    authorities: [117],
    name: "routes.tasks",
  },
  {
    componentName: TaskInfo,
    paths: [
      {
        path: "/tasks/edit-task",
        hasAuthority: true,
        authorities: [119],
        name: "routes.edit_task",
      },
      {
        path: "/taskInfo",
        hasAuthority: true,
        authorities: [119],
        name: "routes.edit_task",
      },
      {
        path: "/projectInfo/:projectId/edit-task",
        hasAuthority: true,
        authorities: [119],
        name: "routes.edit_task",
      },
    ],
  },
  {
    componentName: TaskLog,
    path: "/taskInfo/:taskId/task-log",
    hasAuthority: true,
    authorities: [119],
    name: "routes.task_working_logs",
  },
  {
    componentName: AddEditTaskLog,

    paths: [
      {
        path: "/taskInfo/:taskId/add-logDetail",
        hasAuthority: false,
        authorities: [],
        name: "Add Task Working Detail",
      },
      {
        path: "/taskInfo/:taskId/taskLogDetail/:logId",
        hasAuthority: false,
        authorities: [],
        name: "Task Working Detaiil",
      },
      {
        path: "/taskInfo/:taskId/task-log/task-logDetail/:logId",
        hasAuthority: false,
        authorities: [],
        name: "routes.task_working_details",
      },
    ],
  },
  //project
  {
    componentName: Projects,
    path: "/projects",
    hasAuthority: true,
    authorities: [126],
    name: "routes.projects",
  },

  //department
  {
    componentName: Department,
    path: "/departments",
    hasAuthority: true,
    authorities: [54],
    name: "routes.departments",
  },

  //form and document
  {
    componentName: Forms,
    path: "/forms",
    hasAuthority: true,
    authorities: [46],
    name: "routes.forms",
  },
  {
    componentName: StudiesForms,
    path: "/forms/:form_category/:id",
    hasAuthority: true,
    authorities: [45],
    name: "",
  },
  {
    componentName: CreateDocument,
    path: "/createDocument/:category_id",
    hasAuthority: true,
    authorities: [44],
  },
  {
    componentName: FormPreview,
    path: "/form_preview/document/:id",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  {
    componentName: FormPreview,
    path: "/form_preview/client/:assigned/:id",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  {
    componentName: FormPreview,
    path: "/preview/:requestAssigined/document/:id",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  {
    componentName: CreateDocument,
    path: "/edit_document/:category_id",
    hasAuthority: true,
    authorities: [43],
    name: "",
  },
  {
    componentName: CreateDocument,
    path: "/edit_document/:assigned/:category_id",
    hasAuthority: true,
    authorities: [43],
    name: "",
  },
  {
    componentName: CreateDocument,
    path: "/assigined_edit_document/:assigned/:category_id",
    hasAuthority: true,
    authorities: [43],
    name: "routes.formsAndDocs",
  },
  {
    componentName: FormDocumnent,
    path: "/formDocuments",
    hasAuthority: true,
    authorities: [41],
    name: "routes.formsAndDocs",
  },
  {
    componentName: Forms,
    path: "/editUrleditUrl",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  {
    componentName: Documents,
    path: "/documents/:preview",
    hasAuthority: true,
    authorities: [48, 49, 51],
    name: "",
  },
  //meeting

  {
    componentName: CreateMeeting,
    path: "/createMeeting",
    hasAuthority: true,
    authorities: [102, 103],
    name: "routes.add_meeting",
  },

  {
    componentName: AccordionForm,
    path: "/edit/meeting/:id",
    hasAuthority: true,
    authorities: [104],
    name: "routes.edit_meeting",
  },

  //techniacal department
  {
    componentName: ProfileTechnicalDepartmentsReferences,
    path: "/reference-categories",
    hasAuthority: true,
    authorities: [158],
    name: "routes.library_categories",
  },
  {
    componentName: PropheticTraditionReferences,
    path: "/reference-categories/references",
    hasAuthority: true,
    authorities: [158],
    name: "routes.references",
  },
  {
    componentName: ReferencesuggestionDetails,
    path: "/reference-categories/references/reference-form",
    hasAuthority: true,
    authorities: [161],
    name: "routes.reference",
  },
  {
    componentName: SuggestionForm,
    path: "/reference-categories/references/suggestion-form",
    hasAuthority: true,
    authorities: [162],
    name: "routes.suggestion",
  },
  {
    componentName: CategoryForm,
    path: "/reference-categories/category-form",
    hasAuthority: true,
    authorities: [159],
    name: "routes.add_category",
  },

  {
    componentName: ProfiledeptMeetingDecisions,
    path: "/Profiledept_meeting_decisions",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  // Reports
  {
    componentName: Reports,
    path: "/reports",
    hasAuthority: true,
    authorities: [165],
    name: "routes.report",
  },
  {
    componentName: EmployeesReport,
    path: "/reports/employee-report",
    hasAuthority: false,
    authorities: [],
    name: "routes.employees_report",
  },
  {
    componentName: ReportTable,
    path: "/reportTable",
    hasAuthority: false,
    authorities: [],
    name: "Report",
  },
  {
    componentName: MatterReports,
    path: "/reports/matter-reports",
    hasAuthority: false,
    authorities: [],
    name: "routes.matter_reports",
  },
  {
    componentName: ManagerialReports,
    path: "/managerialreports",
    hasAuthority: false,
    authorities: [],
    name: "routes.managerial_reports",
  },
  {
    componentName: DepartmentsReport,
    path: "/reports/department-report",
    hasAuthority: false,
    authorities: [],
    name: "routes.departments_reports",
  },
  {
    componentName: TechnicalResourcesReport,
    path: "/reports/technical-resource-report",
    hasAuthority: false,
    authorities: [],
    name: "routes.technical_resources_reports",
  },
  {
    componentName: PerformanceReport,
    path: "/reports/performance-report",
    hasAuthority: false,
    authorities: [],
    name: "routes.performance_reports",
  },
  {
    componentName: LateAttendance,
    path: "/reports/performance-report/late-attandance",
    hasAuthority: false,
    authorities: [],
    name: "routes.late_attendance",
  },
  {
    componentName: LateAttendanceDetail,
    path: "/reports/performance-report/late-attandance/late-attendance-detail/:attandanceId",
    hasAuthority: false,
    authorities: [],
    name: "routes.late_attendance_detail",
  },
  {
    componentName: LateMatters,
    path: "/reports/performance-report/late-matters",
    hasAuthority: false,
    authorities: [],
    name: "routes.late_matters",
  },
  {
    componentName: LateMatterDetail,
    path: "/reports/performance-report/late-matters/late-matter-detail/:matterId",
    hasAuthority: false,
    authorities: [],
    name: "routes.late_matter_detail",
  },
  {
    componentName: TimeOffReport,
    path: "/reports/timeoff-report",
    hasAuthority: false,
    authorities: [],
    name: "routes.time_off_reports",
  },
  {
    componentName: SelectedEmployeeReport,
    path: "/reports/employee-report/selected-employee-report/:selectedEmp",
    hasAuthority: false,
    authorities: [],
    name: "routes.selected_employee_report",
  },

  //Promotion
  {
    componentName: Promotions,
    path: "/promotions",
    hasAuthority: true,
    authorities: [152],
    name: "routes.promotions",
  },
  {
    componentName: PromotionDetail,
    paths: [
      {
        path: "/promotions/promotion-detail/:promotionId",
        hasAuthority: false,
        authorities: [],
        name: "routes.promotion_details",
      },
      {
        path: "/notification/promotiondetail/:promotionId",
        hasAuthority: false,
        authorities: [],
        name: "routes.promotion_details",
      },
    ],
  },
  {
    componentName: HrProfileTasks,
    path: "/profile_tasks",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  //Atorney
  {
    componentName: Attroneys,
    path: "/attroneys",
    hasAuthority: true,
    authorities: [141],
    name: "routes.attroneys",
  },

  //Legal sec
  {
    componentName: Exective,
    path: "/executive_director",
    hasAuthority: true,
    authorities: [142],
    name: "routes.executive_director",
  },

  {
    componentName: ExecutiveAttroneys,
    path: "/executiveAttroneys",
    hasAuthority: false,
    authorities: [],
    name: "Attorneys",
  },
  //others
  {
    componentName: DashboardProfile,
    path: "/dashboard_profile",
    hasAuthority: false,
    authorities: [],
    name: "",
  },
  {
    componentName: ConsultationTables,
    path: "/ask-consultation",
    hasAuthority: true,
    authorities: [149],
    name: "routes.consultations",
  },
  {
    componentName: AskForConsultation,
    path: "/ask-consultation/consultation-form",
    hasAuthority: true,
    authorities: [170],
    name: "routes.consultation",
  },
  {
    componentName: ConsultationInfo,
    path: "/ask-consultation/consultationInfo",
    hasAuthority: true,
    authorities: [171],
    name: "routes.consultation_info",
  },
  {
    componentName: PermisisionDenied,
    path: "/access_denied",
    hasAuthority: false,
    authorities: [],
    name: "Access Denied",
  },

  {
    componentName: Settings,
    path: "/settings",
    hasAuthority: false,
    authorities: [],
    name: "routes.settings",
  },
  {
    componentName: GlobalFields,
    path: "/settings/category",
    hasAuthority: false,
    authorities: [],
    name: "routes.global_fields",
  },
  {
    componentName: CategoryListing,
    path: "/settings/category/:categoryId",
    hasAuthority: false,
    authorities: [],
    name: "routes.category",
  },
  {
    componentName: EmployeePerformance,
    path: "/settings/employee-performance",
    hasAuthority: false,
    authorities: [],
    name: "routes.employee_performance",
  },
  {
    componentName: AttendanceScore,
    path: "/settings/employee-performance/atendance-score/:type",
    hasAuthority: false,
    authorities: [179],
    name: "Attendance Score",
  },
  {
    componentName: TaskScore,
    path: "/settings/employee-performance/task-score/:type",
    hasAuthority: false,
    authorities: [],
    name: "Task Score",
  },
  {
    componentName: PenalitiesAndBonus,
    path: "/settings/penalties-bonus",
    hasAuthority: false,
    authorities: [186],
    name: "routes.penalities_and_bonus",
  },
  {
    componentName: Penalities,
    path: "/settings/penalties-bonus/penalties",
    hasAuthority: false,
    authorities: [],
    name: "routes.penalties",
  },
  {
    componentName: Bonus,
    path: "/settings/penalties-bonus/bonus",
    hasAuthority: false,
    authorities: [],
    name: "routes.bonus",
  },
  {
    componentName: WorkingSchedule,
    path: "/settings/working-schedule",
    hasAuthority: false,
    authorities: [],
    name: "routes.working_schedule",
  },
  {
    componentName: JobPosition,
    path: "/settings/job-positions",
    hasAuthority: false,
    authorities: [],
    name: "routes.job_positions",
  },
  {
    componentName: AddEditJobPosition,
    paths: [
      {
        path: "/settings/job-positions/add",
        hasAuthority: true,
        authorities: [180],
        name: "route.add_job_position",
      },
      {
        path: "/settings/job-positions/edit/:jobPositionID",
        hasAuthority: true,
        authorities: [181],
        name: "route.edit_job_position",
      },
    ],
  },
  {
    componentName: AddEditWorkingHour,
    paths: [
      {
        path: "/settings/working-schedule/add-working-hour",
        hasAuthority: true,
        authorities: [180],
        name: "routes.add_working_schedule",
      },
      {
        path: "/settings/working-schedule/edit-working-hour/:workScheduleId",
        hasAuthority: true,
        authorities: [181],
        name: "routes.edit_working_schedule",
      },
    ],
  },
  {
    componentName: AddEditAttendanceScore,
    paths: [
      {
        path: "/settings/employee-performance/atendance-score/:type/add-score",
        hasAuthority: true,
        authorities: [180],
        name: "Add Attendance Score",
      },
      {
        path: "/settings/employee-performance/atendance-score/:type/edit-score/:attendaceScoreId",
        hasAuthority: true,
        authorities: [181],
        name: "Edit Attendance Score",
      },
    ],
  },
  {
    componentName: AddEditTaskScore,
    paths: [
      {
        path: "/settings/employee-performance/task-score/:type/add-score",
        hasAuthority: true,
        authorities: [183],
        name: "Add Attendance Score",
      },
      {
        path: "/settings/employee-performance/task-score/:type/edit-score/:taskScoreId",
        hasAuthority: true,
        authorities: [184],
        name: "Edit Attendance Score",
      },
    ],
  },
  {
    componentName: AddEditPenalties,
    paths: [
      {
        path: "/settings/penalties-bonus/penalties/add-penalty",
        hasAuthority: true,
        authorities: [187],
        name: "Add Penalty Type Name",
      },
      {
        path: "/settings/penalties-bonus/penalties/edit-penalty/:penaltyId",
        hasAuthority: true,
        authorities: [188],
        name: "Edit Penalty Type Name",
      },
    ],
  },
  {
    componentName: AddEditBonus,
    paths: [
      {
        path: "/settings/penalties-bonus/bonus/add-bonus",
        hasAuthority: true,
        authorities: [190],
        name: "Add Bonus Type Name",
      },
      {
        path: "/settings/penalties-bonus/bonus/edit-bonus/:bonusId",
        hasAuthority: true,
        authorities: [191],
        name: "Edit Bonus Type Name",
      },
    ],
  },
  {
    componentName: ProjectInfo,
    paths: [
      {
        path: "/projects/edit-project/:projectId",
        hasAuthority: true,
        authorities: [130],
        name: "routes.edit_projects",
      },
      {
        path: "/projectInfo/:projectId",
        hasAuthority: true,
        authorities: [130],
        name: "routes.edit_projects",
      },
    ],
  },

  {
    componentName: AddDepartment,
    paths: [
      {
        path: "/add_department/:deptId",
        hasAuthority: false,
        authorities: [],
        name: "routes.edit_department",
      },
      {
        path: "/departments/edit-department/:deptId",
        hasAuthority: false,
        authorities: [],
        name: "routes.edit_department",
      },
      {
        path: "/branches/branchInfo/:branchId/edit-department/:deptId",
        hasAuthority: false,
        authorities: [],
        name: "routes.edit_branch_department",
      },
    ],
  },

  {
    componentName: ClientInfo,
    paths: [
      {
        name: "routes.edit_client",
        path: "/clients/edit-client/:clientId",
        hasAuthority: true,
        authorities: [89],
      },
      {
        path: "/branches/branchInfo/:branchId/edit-client/:clientId",
        hasAuthority: true,
        authorities: [69],
        name: "routes.edit_branch_client",
      },
    ],
  },

  {
    componentName: AddEmployee,
    paths: [
      {
        path: "/addEmployee/:empId",
        hasAuthority: true,
        authorities: [72],
        name: "routes.edit_employee",
      },
      {
        path: "/employees/edit-employee/:empId",
        name: "routes.edit_employee",
        hasAuthority: true,
        authorities: [72],
      },
      {
        path: "/coordinators/edit-coordinators/:empId",
        name: "routes.edit_coorditnator",
        hasAuthority: true,
        authorities: [85],
      },
      {
        path: "/branches/branchInfo/:branchId/edit-employee/:empId",
        hasAuthority: true,
        authorities: [66],
        name: "routes.edit_branch_employee",
      },
      {
        path: "/departments/edit-department/:deptId/edit-employee/:empId",
        hasAuthority: true,
        authorities: [59],
        name: "routes.edit_department_employee",
      },
    ],
  },
  {
    componentName: AddEditPenalty,
    paths: [
      //add Penalty
      {
        path: "/addEmployee/:empId/add-employeepenalty",
        hasAuthority: false,
        authorities: [],
        name: "Add Penalty",
      },
      {
        path: "/employees/edit-employee/:empId/add-employeepenalty",
        hasAuthority: false,
        authorities: [],
        name: "Add Penalty",
      },
      {
        path: "/coordinators/edit-coordinators/:empId/add-employeepenalty",
        hasAuthority: false,
        authorities: [],
        name: "Add Penalty",
      },
      {
        path: "/branches/branchInfo/:branchId/edit-employee/:empId/add-employeepenalty",
        hasAuthority: false,
        authorities: [],
        name: "Add Penalty",
      },
      {
        path: "/departments/edit-department/:deptId/edit-employee/:empId/add-employeepenalty",
        hasAuthority: false,
        authorities: [],
        name: "Add Penalty",
      },
      // View penalty
      {
        path: "/addEmployee/:empId/view-employeepenalty/:penaltyId",
        hasAuthority: false,
        authorities: [],
        name: "View Penalty",
      },
      {
        path: "/employees/edit-employee/:empId/view-employeepenalty/:penaltyId",
        hasAuthority: false,
        authorities: [],
        name: "View Penalty",
      },
      {
        path: "/coordinators/edit-coordinators/:empId/view-employeepenalty/:penaltyId",
        hasAuthority: false,
        authorities: [],
        name: "View Penalty",
      },
      {
        path: "/branches/branchInfo/:branchId/edit-employee/:empId/view-employeepenalty/:penaltyId",
        hasAuthority: false,
        authorities: [],
        name: "View Penalty",
      },
      {
        path: "/departments/edit-department/:deptId/edit-employee/:empId/view-employeepenalty/:penaltyId",
        hasAuthority: false,
        authorities: [],
        name: "View Penalty",
      },
    ],
  },
  {
    componentName: AddEditEmployeeBonus,
    paths: [
      //add bonus
      {
        path: "/addEmployee/:empId/add-employeebonus",
        hasAuthority: false,
        authorities: [],
        name: "Add Bonus",
      },
      {
        path: "/employees/edit-employee/:empId/add-employeebonus",
        hasAuthority: false,
        authorities: [],
        name: "Add Bonus",
      },
      {
        path: "/coordinators/edit-coordinators/:empId/add-employeebonus",
        hasAuthority: false,
        authorities: [],
        name: "Add Bonus",
      },
      {
        path: "/branches/branchInfo/:branchId/edit-employee/:empId/add-employeebonus",
        hasAuthority: false,
        authorities: [],
        name: "Add Bonus",
      },
      {
        path: "/departments/edit-department/:deptId/edit-employee/:empId/add-employeebonus",
        hasAuthority: false,
        authorities: [],
        name: "Add Bonus",
      },
      // view bonus
      {
        path: "/addEmployee/:empId/view-employeebonus/:bonusId",
        hasAuthority: false,
        authorities: [],
        name: "View Bonus",
      },
      {
        path: "/employees/edit-employee/:empId/view-employeebonus/:bonusId",
        hasAuthority: false,
        authorities: [],
        name: "View Bonus",
      },
      {
        path: "/coordinators/edit-coordinators/:empId/view-employeebonus/:bonusId",
        hasAuthority: false,
        authorities: [],
        name: "View Bonus",
      },
      {
        path: "/branches/branchInfo/:branchId/edit-employee/:empId/view-employeebonus/:bonusId",
        hasAuthority: false,
        authorities: [],
        name: "View Bonus",
      },
      {
        path: "/departments/edit-department/:deptId/edit-employee/:empId/view-employeebonus/:bonusId",
        hasAuthority: false,
        authorities: [],
        name: "View Bonus",
      },
    ],
  },
  {
    componentName: EditEmployeeAttandance,
    paths: [
      //add Penalty
      {
        path: "/notification/emp-attendance/view/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance",
      },
      {
        path: "/addEmployee/:empId/emp-attendance/:type/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance ",
      },
      {
        path: "/employees/edit-employee/:empId/emp-attendance/:type/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance ",
      },
      {
        path: "/coordinators/edit-coordinators/:empId/emp-attendance/:type/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance ",
      },
      {
        path: "/branches/branchInfo/:branchId/edit-employee/:empId/emp-attendance/:type/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance ",
      },
      {
        path: "/departments/edit-department/:deptId/edit-employee/:empId/emp-attendance/:type/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance ",
      },
      {
        path: "/empAttendance/:empId/emp-attendance/:type/:attendanceId",
        hasAuthority: false,
        authorities: [],
        name: "Employee Attendance ",
      },
    ],
  },
  {
    componentName: MeetingDetails,
    paths: [
      {
        hasAuthority: true,
        authorities: [105],
        name: "routes.meeting_detail",
        path: "/meetingDetails",
      },
      {
        hasAuthority: true,
        path: "/partnerMeetings/meeting-detail",
        authorities: [105],
        name: "routes.meeting_detail",
      },
      {
        hasAuthority: true,
        authorities: [105],
        path: "/bodDecision/meeting-detail",
        name: "routes.meeting_detail",
      },
      {
        hasAuthority: true,
        path: "/meeting-decisons/meeting-detail",
        authorities: [105],
        name: "routes.meeting_detail",
      },
      {
        path: "/votingValidations/meeting-detail",
        hasAuthority: true,
        authorities: [105],
        name: "routes.meeting_detail",
      },
      {
        path: "/company-decision/meeting-detail",
        hasAuthority: true,
        authorities: [105],
        name: "routes.company_decision_meeting_detail",
      },
      {
        path: "/createMeeting/meeting-detail",
        hasAuthority: true,
        authorities: [105],
        name: "routes.meetings",
      },
      {
        path: "/attroneys/meeting-detail",
        hasAuthority: true,
        authorities: [105],
        name: "routes.meeting_detail",
      },
      {
        path: "/executive_director/meeting-detail",
        hasAuthority: true,
        authorities: [105],
        name: "routes.meeting_detail",
      },
    ],
  },
  {
    componentName: NewMeetingDecision,
    paths: [
      {
        path: "/meetingDetails/meeting-mins/:id",
        hasAuthority: false,
        authorities: [],
        name: "routes.meeitng_mins",
      },
      {
        path: "/notification/meeting-mins/:id",
        hasAuthority: false,
        authorities: [],
        name: "routes.meeitng_mins",
      },
    ],
  },
  // Broadcasts
  {
    componentName: Broadcasts,
    paths: [
      {
        path: "/broadcasts",
        hasAuthority: true,
        authorities: [206],
        name: "routes.broadcasts",
      },
      {
        path: "/notification/broadcasts",
        hasAuthority: true,
        authorities: [206],
        name: "routes.broadcasts",
      },
    ],
  },
  {
    componentName: BroadcastForm,
    path: "/broadcasts/create",
    hasAuthority: true,
    authorities: [207],
    name: "routes.create_broadcast",
  },
  {
    componentName: BroadcastInfo,
    path: "/broadcasts/broadcast-info",
    hasAuthority: true,
    authorities: [208],
    name: "routes.broadcast_info",
  },

  // HR vacations
  {
    componentName: VacationSettings,
    path: "/vacations",
    hasAuthority: false,
    authorities: [],
    name: "routes.vacations",
  },
  {
    componentName: Hrholidays,
    path: "/vacations/holidays",
    hasAuthority: true,
    authorities: [150],
    name: "routes.holidays",
  },
  {
    componentName: Timeoffs,
    path: "/vacations/timeoffs",
    hasAuthority: false,
    authorities: [],
    name: "routes.timeoffs",
  },
  // Allocations
  {
    componentName: Allocations,
    paths: [
      {
        path: "/allocation",
        hasAuthority: true,
        authorities: [193],
        name: "routes.allocations",
      },
      {
        path: "/notification/allocation",
        hasAuthority: true,
        authorities: [193],
        name: "routes.allocations",
      },
    ],
  },
  {
    componentName: AllocationForm,
    path: "/allocation/allocation-form",
    hasAuthority: true,
    authorities: [194],
    name: "routes.add_allocation",
  },
  {
    componentName: AllocationApporval,
    path: "/allocation/allocation-approval",
    hasAuthority: true,
    authorities: [195],
    name: "routes.allocation_approval",
  },
  {
    componentName: Loan,
    path: "/loan",
    hasAuthority: true,
    authorities: [196],
    name: "routes.loan",
  },
  {
    componentName: AddEditLoanRequest,
    path: "/loan/add-loan",
    hasAuthority: true,
    authorities: [198],
    name: "routes.add_loan",
  },
  {
    componentName: AddEditLoanRequest,
    paths: [
      {
        path: "/notification/loam-details/:loanId",
        hasAuthority: true,
        authorities: [199],
        name: "routes.loan_details",
      },
      {
        path: "/loan/loam-details/:loanId",
        hasAuthority: true,
        authorities: [199],
        name: "routes.loan_details",
      },
    ],
  },
  //Accounting Routes to be added below here
  {
    componentName: Sales,
    path: "/sales",
    hasAuthority: false,
    authorities: [],
    name: "Sales",
  },
  {
    componentName: Services,
    path: "/services",
    hasAuthority: false,
    authorities: [],
    name: "Services",
  },
  {
    componentName: Purchase,
    path: "/purchase",
    hasAuthority: false,
    authorities: [],
    name: "Purchase",
  },
  {
    componentName: Inventory,
    path: "/inventory",
    hasAuthority: false,
    authorities: [],
    name: "Inventory",
  },
  {
    componentName: Adminsitration,
    path: "/administration",
    hasAuthority: false,
    authorities: [],
    name: "Adminstration",
  },
  {
    componentName: FinancialServices,
    path: "/financialServices",
    hasAuthority: false,
    authorities: [],
    name: "Financial Services ",
  },
  {
    componentName: HrAndPayroll,
    path: "/hrPayroll",
    hasAuthority: false,
    authorities: [],
    name: "HR And Payroll",
  },
  // vacancies
  {
    componentName: Vacancies,
    path: "/recruitments",
    hasAuthority: true,
    authorities: [201],
    name: "routes.recruitments",
  },
  {
    componentName: VacancyForm,
    path: "/recruitments/create-recruitment",
    hasAuthority: true,
    authorities: [202],
    name: "routes.create_recruitment",
  },
  {
    componentName: VacancyInfo,
    path: "/recruitments/recruitment-info",
    hasAuthority: true,
    authorities: [204],
    name: "routes.recruitment_info",
  },
  {
    componentName: CandidateForm,
    path: "/recruitments/create-candidate",
    hasAuthority: true,
    authorities: [203],
    name: "routes.create_candidate",
  },
  {
    componentName: CandidateInfo,
    path: "/recruitments/candidate-info",
    hasAuthority: true,
    authorities: [205],
    name: "routes.candidate_info",
  },
  {
    componentName: Matters,
    path: "/matters",
    hasAuthority: true,
    authorities: [210],
    name: "routes.matters",
  },
];
