/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import moment from "moment";
import { loadOptions } from "../../../../api/utils/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";

export default function LateMatters() {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [lateMatters, setLateMatters] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.matter_name",
      name: "name",
      type: "text",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.code",
      name: "code",
      width: "10px",
      align: "left",
      minWidth: 110,
    },

    {
      lable: "labels.employee_name",
      width: "10px",
      align: "left",
      type: "dropDown",
      minWidth: 110,
      name: "assigned_to",
    },
    {
      lable: "labels.start_date",
      name: "startDate",
      width: "10px",
      align: "left",
      minWidth: 130,
      hide: true,
    },
    {
      lable: "labels.late_date",
      name: "endDate",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 130,
    },
    {
      lable: "labels.late_duration",
      name: "late_duration",
      hide: true,
      width: "10px",
      align: "left",
      minWidth: 50,
    },
  ]);
  const [data, setData] = useState({
    title: "titles.late_matters",
    page: "Late Matters",
    hideSearch: true,
    infoURL: "/reports/performance-report/late-matters/late-matter-detail",
    hasVariable: true,
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ?? "");
    formdata.append("filter[code]", filterData?.code ?? "");
    formdata.append("filter[assigned_to]", filterData?.assigned_to ?? "");
    const res = await http.getAllDataApi(
      t,
      `/api/task/late-matter?&page=${pageIndex + 1}&size=${pageSize}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setFilterData({ ...filterData, filter: false });
    setLateMatters(res.data);
    setLoader(false);
  };

  const setColumnData = async () => {
    let s1 = [...column];
    let employee = await loadOptions(t, 1, undefined, "post", "/api/employee");
    s1[3].options = employee;
    setColumn(s1);
  };

  useEffect(() => {
    setColumnData();
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("task", 120)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`id[${index}]`, item);
          });
        }
        await http.postApi(t, "", formData, "labels.task_deleted_successfully");
        getApiData(page, size);
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <ComponentTable
      rows={lateMatters}
      columns={column}
      pageData={data}
      filterData={filterData}
      setFilterData={setFilterData}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{}}
    />
  );
}
