import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComponentModal from "../../../componentGroups/componectModel";
import http from "../../../../api/axios";
import ComponentTable from "../../../componentGroups/componentTable";
import validate from "../../../../Hooks/department_valdaction";
import hooks from "../../../../Hooks/hooks";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import { loadOptions } from "../../../../api/utils/common";

export default function BranchesEmployee(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [search, setSearch] = useState("");
  const [codes, setCodes] = useState({});
  const { branchId } = useParams();
  const Id = branchId;
  const [column, setColumn] = useState([
    { lable: "labels.name", name: "name", width: "10px", align: "left" },
    { lable: "labels.code", name: "code", width: "10px", align: "left" },
    {
      lable: "labels.department",
      name: "department",
      width: "10px",
      align: "left",
    },
    { lable: "labels.email", name: "email", width: "10px", align: "left" },
    { lable: "labels.status", name: "status", width: "10px", align: "left" },
  ]);
  const [data, setData] = useState({
    title: "titles.branch_employees",
    page: "Branches",
    infoURL: `/branches/branchInfo/${Id}/edit-employee`,
    hasVariable: true,
    buttonGroup: [
      {
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("branch", 65)) {
            handleModel("addBranch");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });
  const [modalData, setmodelData] = useState({
    model: false,
    title: "titles.add_new_employee",
    data: [
      {
        lable: "labels.name",
        name: "name",
        field: { type: "text", required: true },
      },
      {
        lable: "labels.email",
        name: "email",
        field: { type: "text", required: true },
      },
      {
        lable: "labels.password",
        name: "password",
        field: { type: "text", required: true },
      },
      {
        lable: "labels.department",
        name: "departmentName",
        field: {
          type: "dropdown",
          findLable: "departmentName",
          option: [],
          required: true,
        },
      },
      {
        lable: "titles.department_code",
        name: "departmentCode",
        field: { type: "text", required: true, disable: true },
      },
      {
        lable: "labels.branch",
        name: "branchName",
        field: {
          type: "dropdown",
          findLable: "branchName",
          option: [],
          disable: true,
          required: true,
        },
      },
      {
        lable: "labels.branch_code",
        name: "branchCode",
        field: { type: "text", option: [], required: true, disable: true },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setBranchEmpState({});
          handleCloseModel();
          getDetailsApi();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (obj) => {
          addEmployee(obj);
        },
      },
    ],
  });
  const [errors, setErrors] = useState({});
  const [branchEmpData, setBranchEmpData] = useState();
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [branchEmpState, setBranchEmpState] = React.useState();

  const handleCloseModel = () => {
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addBranch") {
      setmodelData({
        ...modalData,
        model: true,
      });
    }
  };

  const addEmployee = async (obj) => {
    let error = validate.deptEmpValidate(obj);
    if (Object.keys(error).length === 0) {
      // props.addEmployee(true);
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("password", obj.password);
      formData.append("email", obj.email);
      formData.append("departmentId", obj.departmentName);
      formData.append("branchId", obj.branchName);
      formData.append("langId", `1`);
      const responsibilities = [...obj?.responsibilities];
      responsibilities?.forEach((item) => {
        formData.append(`responsibilities[]`, item);
      });
      const res = await http.postApi(t, "/api/employee/create", formData);
      if (res) {
        getApiData();
        setBranchEmpState({});
        handleCloseModel();
        getDetailsApi();
      }

      // props.addEmployee(false);
    } else {
      setErrors(error);
    }
    // props.addEmployee(false);
  };

  const handleDepAndBranchChange = async () => {
    if (
      branchEmpState?.departmentName &&
      codes.departmentName != branchEmpState?.departmentName
    ) {
      const res = await http.getApi(
        t,
        `/api/employee/responsibilities/all/${branchEmpState?.departmentName}`
      );
      const options = res?.map((op) => {
        return op?.id;
      });
      setCodes({ ...codes, departmentName: branchEmpState?.departmentName });
      setBranchEmpState({
        ...branchEmpState,
        departmentCode: hooks.sendId(
          modalData.data[3].field.option,
          "departmentCode",
          branchEmpState.departmentName,
          "id"
        ),
        responsibilities: [...options],
      });
    }
  };

  useEffect(() => {
    handleDepAndBranchChange();
    if (Object.keys(errors).length != 0) {
      let error = validate.deptEmpValidate(branchEmpState);
      setErrors(error);
    }
  }, [branchEmpState]);

  const getDetailsApi = async () => {
    let s1 = { ...modalData };
    let allDepartments = await loadOptions(
      t,
      "",
      null,
      "get",
      "/api/department"
    );
    let allBranches = await loadOptions(
      t,
      "",
      null,
      "post",
      "/api/branch/details"
    );
    s1.data[3].field.option = allDepartments;
    s1.data[5].field.option = allBranches;
    setmodelData(s1);
    setBranchEmpState({
      ...branchEmpState,
      branchName: props.branchData?.id,
      branchCode: props.branchData?.branchCode,
    });
    // props.addEmployee(false);
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    //TODO: API needs to be updated
    let res = await http.getAllDataApi(
      t,
      `/api/branch/employees/all/${Id}?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count || res?.data?.length);
    setBranchEmpData(res?.data);
    // props.addEmployee(false);
  };

  useEffect(() => {
    getDetailsApi();
  }, []);

  useEffect(() => {
    getApiData(page, size);
  }, [page, size, search]);

  return (
    <>
      <ComponentModal
        modalData={modalData}
        errors={errors}
        setPerantState={setBranchEmpState}
        perantState={branchEmpState}
      />
      <ComponentTable
        rows={branchEmpData}
        columns={column}
        pageData={data}
        page={page}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
      />
    </>
  );
}
