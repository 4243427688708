import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Svg from "../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import { hasAuthority } from "../../utils/common";

const LinkSideBarButton = (props) => {
  const location = useLocation();

  return (
    <Link to={props.link} style={{ textDecoration: "none" }}>
      <ListItemButton
        style={{ justifyContent: "initial" }}
        sx={{
          minHeight: 48,
          px: 2.5,
        }}
        className={location.pathname === props.link ? " side_nav_btn" : " "}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
          }}
          style={{ justifyContent: "center" }}
          className="iconGroup sidebar-Icons"
        >
          {props.icon}
        </ListItemIcon>
        <Typography variant="h6" gutterBottom>
          {props.text}
        </Typography>
      </ListItemButton>
    </Link>
  );
};

export default function SideBar(props) {
  const { t } = useTranslation();

  /**
   * navigationa menu items
   * allowedDept :inclueds the dept id in which item has to be rendered
   */
  const navMenuItems = [
    {
      name: "sideBar.0.dashboard",
      icon: "dashboard",
      link: "/",
      allowedDept: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    },
    {
      name: "sideBar.10.partners_meeting",
      icon: "partnerDecision",
      link: "/partnerMeetings",
      allowedDept: [2],
      authorities: [140],
    },
    {
      name: "sideBar.25.attroneys",
      icon: "executive_director",
      link: "/attroneys",
      allowedDept: [11],
      authorities: [141],
    },
    {
      name: "sideBar.28.executive_director_schedule",
      icon: "form_doc",
      link: "/executive_director",
      allowedDept: [11, 13],
      authorities: [142],
    },
    {
      name: "sideBar.11.bod_decisions",
      icon: "form_doc",
      link: "/bodDecision",
      allowedDept: [2],
      authorities: [145],
    },
    //TODO: enable it when expence profit is done
    // {
    //   name: "sideBar.12.expenses_profits",
    //   icon: "codinaters",
    //   link: "/expenseProfit",
    //   allowedDept: [2],
    // },
    {
      name: "sideBar.13.requests_assigns",
      icon: "deptartment",
      link: "/requestAssign",
      allowedDept: [2],
      authorities: [155],
    },
    {
      name: "sideBar.17.voting_validation",
      icon: "voting_validation",
      link: "/votingValidations",
      allowedDept: [3],
      authorities: [146],
    },
    {
      name: "sideBar.14.documents",
      icon: "form_doc",
      link: "/partnerBoard/documents",
      allowedDept: [2, 3, 8],
      authorities: [143],
    },
    {
      name: "sideBar.1.company_decisions",
      icon: "partnerDecision",
      link: "/company-decision",
      allowedDept: [1, 5, 6, 7],
      authorities: [113],
    },
    {
      name: "sideBar.2.forms_documents",
      icon: "form_doc",
      link: "/formDocuments",
      allowedDept: [1, 5, 6, 7, 9, 10, 11, 12, 13],
      authorities: [41],
    },
    {
      name: "sideBar.3.coordinators",
      icon: "codinaters",
      link: "/coordinators",
      allowedDept: [1, 5],
      authorities: [83],
    },
    {
      name: "sideBar.4.departments",
      icon: "deptartment",
      link: "/departments",
      allowedDept: [1, 5],
      authorities: [54],
    },
    {
      name: "sideBar.5.employees",
      icon: "employees",
      link: "/employees",
      allowedDept: [1, 5, 7, 8],
      authorities: [70],
    },

    {
      name: "sideBar.6.clients",
      icon: "client",
      link: "/clients",
      allowedDept: [1, 5, 7],
      authorities: [87],
    },
    {
      name: "sideBar.21.vacations",
      icon: "Vacations",
      link: "/vacations",
      allowedDept: [8],
      authorities: [150],
    },
    {
      name: "sideBar.22.promotions",
      icon: "Promotions",
      link: "/promotions",
      allowedDept: [8],
      authorities: [152],
    },
    {
      name: "sideBar.9.tasks",
      icon: "tasks",
      link: "/tasks",
      allowedDept: [1, 6, 7, 8, 9, 10, 11, 12, 13],
      authorities: [117],
    },
    {
      name: "sideBar.27.reports",
      icon: "reports",
      link: "/reports",
      allowedDept: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      authorities: [165],
    },
    {
      name: "sideBar.23.ask_for_a_consultation",
      icon: "Ask-consultation",
      link: "/ask-consultation",
      allowedDept: [10, 12, 13],
      authorities: [149],
    },
    {
      name: "sideBar.19.references",
      icon: "References",
      link: "/reference-categories",
      allowedDept: [6, 10, 12, 13],
      authorities: [158],
    },
    {
      name: "sideBar.7.branches",
      icon: "branches",
      link: "/branches",
      allowedDept: [1],
      authorities: [64],
    },
    {
      name: "sideBar.16.meeting_decision",
      icon: "meeting_decission",
      link: "/meeting-decisons",
      allowedDept: [3],
      authorities: [139],
    },
    {
      name: "sideBar.8.create_meeting",
      icon: "createMeeting",
      link: "/createMeeting",
      allowedDept: [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      authorities: [102],
    },
    {
      name: "sideBar.26.coding",
      icon: "createMeeting",
      link: "/coding",
      allowedDept: [5],
    },
    {
      name: "sideBar.18.projects",
      icon: "projects",
      link: "/projects",
      allowedDept: [1, 10, 12, 13],
      authorities: [126],
    },
    {
      name: "sideBar.29.settings",
      icon: "setting",
      link: "/settings",
      allowedDept: [],
      authorities: [175],
    },
    {
      name: "Loan",
      icon: "setting",
      link: "/loan",
      allowedDept: [],
      authorities: [196],
    },
    {
      name: "labels.sidebar_broadcasts",
      icon: "broadcast",
      link: "/broadcasts",
      allowedDept: [1],
      authorities: [206],
    },
    {
      name: "labels.allocation",
      icon: "allocation",
      link: "/allocation",
      allowedDept: [1],
      authorities: [193],
    },
    {
      name: "labels.recruitment",
      icon: "recruitment",
      link: "/recruitments",
      allowedDept: [1],
      authorities: [201],
    },
    {
      name: "labels.matters",
      icon: "form_doc",
      link: "/matters",
      allowedDept: [1],
      authorities: [210],
    },
  ];

  return (
    <List
      sx={{
        borderRightStyle: "inset",
        width: "300px",
        background: "#FFFFFF",
        display: props.open === true ? "show" : "none",
      }}
      style={props?.style}
      className={props?.className}
    >
      <ListItem
        key={"sideBar.0.dashboard"}
        disablePadding
        sx={{ display: "block" }}
      >
        <LinkSideBarButton
          link={"/"}
          icon={<Svg type={"dashboard"} />}
          text={t("sideBar.0.dashboard")}
        />
      </ListItem>
      {navMenuItems?.map((item, index) => {
        if (item?.authorities && hasAuthority(item?.authorities)) {
          return (
            <ListItem
              key={`${item?.link}-${index}`}
              disablePadding
              sx={{ display: "block" }}
            >
              <LinkSideBarButton
                link={item?.link}
                icon={<Svg type={item?.icon} />}
                text={t(item?.name)}
              />
            </ListItem>
          );
        }
        if (item?.authorities?.length === 0) {
          return (
            <ListItem
              key={`${item?.link}-${index}`}
              disablePadding
              sx={{ display: "block" }}
            >
              <LinkSideBarButton
                link={item?.link}
                icon={<Svg type={item?.icon} />}
                text={t(item?.name)}
              />
            </ListItem>
          );
        }
      })}
    </List>
  );
}
