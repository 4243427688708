import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 20,
};

export default function GenericModal({
  title,
  desc,
  children,
  open,
  enableCloseIcon = false,
  onClose,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      className="MainModal"
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="ModalBG" sx={style}>
        <div className="ModalHeading">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ paddingTop: "1px !important" }}
              className="morningText"
            >
              {t(title)}
            </Typography>
            {enableCloseIcon && (
              <CancelRoundedIcon
                style={{ marginLeft: "8px", fontSize: "25px" }}
                sx={{ color: "red", cursor: "pointer" }}
                onClick={() => onClose()}
              />
            )}
          </div>

          {desc && (
            <Typography
              variant="body2"
              sx={{ marginTop: "20px", textAlign: "center" }}
              className="morningText1"
            >
              {t(desc)}
            </Typography>
          )}
        </div>
        {children}
      </Box>
    </Modal>
  );
}

