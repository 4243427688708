import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentButton from "../../../componentGroups/componentButton";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import ComponentSelect from "../../../componentGroups/componentSelect";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";
import axios from "axios";
import { loadOptions } from "../../../../api/utils/common";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import { ATTENDANCE_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/attendence_key_lable";

// predeined values to reuse the same component while in add and edit
const PAGE_VALUES = {
  early: {
    title: "routes.early_attendance_score",
    add: "routes.add_early_attendance_score",
    edit: "routes.edit_early_attendance_score",
    path: "/settings/employee-performance/atendance-score/early",
    addApi: "/api/setting/early-attendance-score",
    getDataApi: "/api/setting/early-attendance-score/findOne",
  },
  late: {
    title: "routes.late_attendance_score",
    add: "routes.add_late_attendance_score",
    edit: "routes.edit_late_attendance_score",
    path: "/settings/employee-performance/atendance-score/late",
    addApi: "/api/setting/late-attendance-score",
    getDataApi: "/api/setting/late-attendance-score/findOne",
  },
};

/**
 * Component is used For Add / Edit Late and Early Attendance
 * Title and api are deifined in a constant to use as per the url
 * @returns
 */
export default function AddEditAttendanceScore() {
  const { type, attendaceScoreId } = useParams();
  const id = attendaceScoreId;
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { setPathValue } = useContext(BreadCrumbContext);
  const { setLoader } = useContext(LoadingContext);
  const [errors, setErrors] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    department: [],
    jobTitle: [],
  });
  const [attendanceDetail, setAttendanceDetail] = useState({
    departmentId: "",
    jobId: "",
    redundancy: "",
    score: "",
  });
  const [flag, setFlag] = useState(false);

  const getAttendanceScoreDetail = async () => {
    const api = `${PAGE_VALUES[type].getDataApi}/${id}`;
    setLoader(true);
    const res = await http.getApi(t, `${api}`);
    setAttendanceDetail({
      ...res,
    });
    setLoader(false);
  };

  const getJobPositions = async () => {
    setLoader(true);
    const res = await loadOptions(
      t,
      "",
      null,
      "get",
      `/api/setting/department/job-position/${attendanceDetail.departmentId}`
    );
    const jobIds = res?.map((item) => {
      return { ...item, name: item?.title };
    });
    setDropDownOptions({
      ...dropDownOptions,
      jobTitle: jobIds,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (attendanceDetail.departmentId) {
      getJobPositions();
    }
  }, [attendanceDetail.departmentId]);

  //used to change breadcumb name
  useEffect(() => {
    setPathValue([
      {
        path: pathname,
        name: id ? PAGE_VALUES[type].edit : PAGE_VALUES[type].add,
      },
      {
        name: PAGE_VALUES[type].title,
        path: PAGE_VALUES[type].path,
      },
    ]);
    axios
      .all([loadOptions(t, "departments", "", "get", `/api/department`)])
      .then((res) => {
        const department = res[0]?.map((item) => {
          return { ...item, name: item?.departmentName };
        });
        setDropDownOptions({
          ...dropDownOptions,
          department: department,
        });
      })
      .finally(() => {
        if (id) {
          getAttendanceScoreDetail();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateErrors = (data) => {
    let error = {};
    if (data.departmentId === undefined || data.departmentId === "") {
      error.departmentId = "errors.select_department";
    }
    if (data.jobId === undefined || data.jobId === "") {
      error.jobId = "errors.select_job_position";
    }
    if (data.redundancy === undefined || data.redundancy === "") {
      error.redundancy = "errors.enter_redundancy";
    }
    if (data.score === undefined || data.score === "") {
      error.score = "errors.enter_score";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = validateErrors(attendanceDetail);
      setErrors(error);
    }
  }, [attendanceDetail]);

  const handleAddEdit = async () => {
    let error = validateErrors(attendanceDetail);
    if (Object.keys(error).length === 0) {
      const api = id
        ? `${PAGE_VALUES[type].addApi}/${id}`
        : PAGE_VALUES[type].addApi;
      setLoader(true);
      const formData = new FormData();
      formData.append("departmentId", attendanceDetail.departmentId);
      formData.append("jobId", attendanceDetail.jobId);
      formData.append("score", attendanceDetail.score);
      formData.append("redundancy", attendanceDetail.redundancy);
      await http.postApi(t, api, formData);
      setLoader(false);
      history.goBack();
    } else {
      setErrors(error);
    }
  };

  return (
    <>
      <div className="allTaskPages">
        <div className="CommonPd latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Grid className="CustomWidth" item xs={11} md={9}>
                <Typography variant="h6" className="morningText">
                  {t(id ? PAGE_VALUES[type].edit : PAGE_VALUES[type].add)}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ paddingLeft: "50px" }}>
              <Grid className="AddEmpGenInfo" container rowSpacing={2}>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.department")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    value={attendanceDetail?.departmentId}
                    name="departmentId"
                    option={dropDownOptions.department}
                    callback={(e) =>
                      setAttendanceDetail({
                        ...attendanceDetail,
                        [e.target.name]: e.target.value,
                        jobId: "",
                      })
                    }
                  />
                  {errors?.departmentId && (
                    <span className="validate">{t(errors.departmentId)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.job_position")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentSelect
                    value={attendanceDetail?.jobId}
                    name="jobId"
                    option={dropDownOptions.jobTitle}
                    callback={(e) =>
                      setAttendanceDetail({
                        ...attendanceDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.jobId && (
                    <span className="validate">{t(errors.jobId)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.redundancy")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="redundancy"
                    value={attendanceDetail.redundancy}
                    callback={(e) =>
                      setAttendanceDetail({
                        ...attendanceDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.redundancy && (
                    <span className="validate">{t(errors.redundancy)}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" className="customLabel">
                    {t("labels.score")}
                  </Typography>
                </Grid>
                <Grid className="CustomWidth" item xs={11} md={9}>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="score"
                    value={attendanceDetail.score}
                    callback={(e) =>
                      setAttendanceDetail({
                        ...attendanceDetail,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {errors?.score && (
                    <span className="validate">{t(errors.score)}</span>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                style={{ margin: "16px 0", justifyContent: "center" }}
              >
                <Grid item>
                  <ComponentButton
                    value={t("labels.discard")}
                    variant={"outlined"}
                    class={"buttonBlank outlineBtn"}
                    callback={() => history.goBack()}
                  />
                </Grid>
                <Grid item>
                  <ComponentButton
                    value={t("labels.save")}
                    variant="contained"
                    class="buttonFill FillBtn ml-3"
                    callback={() => {
                      handleAddEdit();
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {attendaceScoreId && (
              <LogNoteAccordion
                id={
                  type === "early"
                    ? `EAS${attendaceScoreId}`
                    : `LAS${attendaceScoreId}`
                }
                flag={flag}
                labelKeys={ATTENDANCE_KEY_LABELS}
              />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
