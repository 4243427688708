import {
  Button,
  Card,
  CardActions,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTranslation } from "react-i18next";
import http from "../../api/axios";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ReferenceCategoryCard = ({ data }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();

  const history = useHistory();

  const handleExpandClick = () => {
    localStorage.setItem("category", JSON.stringify(data));
    history.push(`/reference-categories/references`);
  };

  const finalDate = new Date(data.createdAt ?? Date.now()).toLocaleString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      hour12: true,
    }
  );

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Card className="profileFormDoc-Card">
        <div className="Pro-traIcon" aria-label="prophetic_tradition">
          {data.image.includes("/") && (
            <Stack direction="row" spacing={2}>
              <Avatar
                variant="rounded"
                src={`${http.baseURL}/${data.image}`}
                alt={data.name}
              />
            </Stack>
          )}
        </div>
        <div className="Pendding-sugest">
          <div className="profiledeptcontent">
            <Typography className="morningText" variant="span">
              {data.name}{" "}
            </Typography>
            {data.suggestions && (
              <Button
                variant="span"
                color="text.secondary"
                className="Pendding-sugestbtn"
              >
                {data.suggestions?.length} Pending Suggestions{" "}
              </Button>
            )}
          </div>
          <div className="cardContentstyl">
            <Typography
              variant="body2"
              color="text.secondary"
              className="textFont"
            >
              {data?.references_count ?? 0} {t("titles.references")}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="textFont"
            >
              {t("labels.last_update")} : {finalDate}
            </Typography>
          </div>

          <CardActions className="TechDeptBtn" disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <Button
                variant="span"
                color="text.secondary"
                className="textFont OpenBtn"
                style={{ display: "flex" }}
              >
                {t("labels.open")} <ArrowRightAltIcon />
              </Button>
            </ExpandMore>
          </CardActions>
        </div>
      </Card>
    </Grid>
  );
};

export default ReferenceCategoryCard;
