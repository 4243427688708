import { Button, Card, CardActions, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LeaveTypeCard from './LeaveTypeCard';
import ComponentButton from '../../../../componentGroups/componentButton';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useEffect } from 'react';

import ComponentModal from '../../../../componentGroups/componectModel';
import { getUserHasAccess } from '../../../../../utils/common';
import { useContext } from 'react';
import AuthorityContext from '../../../../../context/AuthorityContext';
import validate from "../../../../../Hooks/timeoffReq_validaction";
import LoadingContext from '../../../../../context/LoaderContext';
import { getApi, postApi } from '../../../../../api/axios';



const VacationSettings = () => {
  const [leaveTypes, setLeaveTypes] = useState()
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);

  const handleExpandClick = (url, data) => {
    if (data) localStorage.setItem('vacationsData', JSON.stringify(data))
    history.push(`/${url}`);
  }
  const getData = async () => {
    const res = await getApi(t, `/api/leave/type`);
    if (res) setLeaveTypes(res);
  }

  useEffect(() => {
    getData()
  }, []);

  // for modal
  const { setAuthorityModal } = useContext(AuthorityContext);

  const ASSIGN_VALUE = {
    name: "",
  };
  const [leaveTypeState, setLeaveTypeState] = useState(ASSIGN_VALUE);
  const [errors, setErrors] = useState({});
  const handleCloseModel = () => {
    setModelData({ ...modalData, model: false });
  };
  const handleModel = (val) => {
    if (val == "addNew") {
      setModelData({
        ...modalData,
        model: true,
      });
    }
  };

  const addLeaveType = async (obj) => {

    let error = validate.leaveTypeValidation(obj);


    if (Object.keys(error)?.length === 0) {
      setLoader(true);
      var formdata = new FormData();
      formdata.append("name", obj?.name);

      const res = await postApi(t, "/api/leave/type", formdata);
      if (res) {
        setLeaveTypeState(ASSIGN_VALUE);
        getData();
        handleCloseModel();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  const [modalData, setModelData] = useState({
    model: false,
    title: "titles.add_request_leaveType",
    data: [
      {
        lable: "labels.leave_name",
        field: {
          type: "text",
          // disable: true,
          required: true,
        },
        name: "name",
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          setErrors({});
          setLeaveTypeState(ASSIGN_VALUE);
          handleCloseModel();
        },
      },
      {
        value: "labels.save",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (value) => {
          addLeaveType(value);
        },
      },
    ],

  })

  return (
    <div className='profiledeptRef'>
      <div className="CommonPd">
        <ComponentModal
          errors={errors}
          modalData={modalData}
          setPerantState={setLeaveTypeState}
          perantState={leaveTypeState}
        />
        <div className='latest_des_box'>
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBlock: "1rem"
              }}
            >
              <Grid>
                <Typography variant='h4' className="morningText">{t('titles.vacation_settings')}</Typography>
              </Grid>
              <Grid className="btnStyle">
                <ComponentButton
                  icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
                  value={t("labels.add_new")}
                  variant={"contained"}
                  class={"buttonFill"}
                  callback={() => {
                    if (getUserHasAccess("vacation", 151)) {
                      handleModel("addNew");
                    } else {
                      setAuthorityModal(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} >

              <LeaveTypeCard
                svg="Vacations"
                link='vacations/holidays'
                // data={ }
                title='vacations'
              />
              {leaveTypes ? leaveTypes.map((e, i) => <LeaveTypeCard
                svg="Vacations"
                link='vacations/timeoffs'
                data={e}
                key={i}
              />) : ""}



            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default VacationSettings


