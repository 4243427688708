import React from "react";
import MeeitngReminder from "../CoordinatorScreens/ParnternMeetingTab/MeeitngReminder";
import CompleteAndIncompleteDocumentViews from "../LegalSecretary/Common/CompleteAndIncompleteDocumentViews";
import Greeting from "../DashBoard/Greeting";
import DashBoardPendingRequests from "../DashBoard/DashBoardPendingRequests";
import DashBoardAttendanceRequest from "../DashBoard/DashBoardAttendanceRequest";

export default function ExecutiveSecDashboard(props) {
  return (
    <div>
      <Greeting />
      <DashBoardPendingRequests />
      <DashBoardAttendanceRequest />
      <MeeitngReminder
        titile={"labels.todays_schedule"}
        endPoint={"/api/meeting/upcoming/list"}
      />
      <CompleteAndIncompleteDocumentViews
        endPoint={"/api/form_doc/form/incompleteDoc"}
        title={"titles.incompleted_documents"}
      />
      <CompleteAndIncompleteDocumentViews
        endPoint={""}
        title={"labels.approved_documents"}
      />
    </div>
  );
}
