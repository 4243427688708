import React, { useEffect, useState } from "react";
import { Stack, Autocomplete, Chip, TextField } from "@mui/material";
import hooks from "../../Hooks/hooks";
import { useTranslation } from "react-i18next";
export default function ComponentAutoComplete({
  options,
  values,
  findLable,
  optionName,
  callback,
  disable,
}) {
  const { t } = useTranslation();
  return (
    <Autocomplete
      multiple
      className="AutoComp"
      id="tags-standard"
      options={options}
      readOnly={disable}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      value={values && values}
      getOptionLabel={(option) => option?.[optionName]}
      onChange={(e, value) => callback(e, value)}
      renderTags={(value, getTagProps) =>
        values?.map((option, index) => (
          <Chip
            variant="outlined"
            className="vijay"
            label={option?.[optionName]}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ paddingLeft: "2px" }}
          variant="standard"
          className="textF"
          // label="Multiple values"
          placeholder={t("placeholder.type_atleast_1_letter")}
        />
      )}
    />
  );
}
