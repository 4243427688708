/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import ComponentTable from "../../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";
import useFormatTime from "../../../../Hooks/useFormatTime";

export default function TaskLog() {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const { globalToLocal } = useFormatTime();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [taskLogs, setLogs] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);

  const [column, setColumn] = useState([
    {
      lable: "labels.start_date",
      name: "startDate",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.start_time",
      name: "startTime",
      hide: true,
    },
    {
      lable: "labels.end_date",
      name: "endDate",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.end_time",
      name: "endTime",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.details",
      name: "details",
      align: "left",
      hide: true,
    },
  ]);

  const [data, setData] = useState({
    title: "titles.previous_task_work",
    page: "Previous Task work",
    hideSearch: true,
    infoURL: `/taskInfo/${taskId}/task-log/task-logDetail`,
    hasVariable: true,
    buttonGroup: [],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/task/working-detail/${taskId}?page=${
        pageIndex + 1
      }&size=${pageSize}`
    );
    const data = res?.data?.map((item) => {
      return {
        ...item,
        startDate: globalToLocal(item.startTime, "DD-MM-YYYY"),
        startTime: globalToLocal(item.startTime, "h:mm:a"),
        endDate:
          item.endTime !== "Invalid date"
            ? globalToLocal(item.endTime, "DD-MM-YYYY")
            : "-",
        endTime:
          item.endTime !== "Invalid date"
            ? globalToLocal(item.endTime, "h:mm:a")
            : "-",
      };
    });
    setTotalrecords(res?.data_count);
    setLogs(data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size]);

  return (
    <ComponentTable
      rows={taskLogs}
      columns={column}
      pageData={data}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
    />
  );
}
