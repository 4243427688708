/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Grid } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Svg from "../../../assets/svg/svg";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import moment from "moment";

export default function Reports() {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const history = useHistory();
  const [reportsData, setReportsData] = useState({
    emp: {
      count: 0,
      updatedAt: "",
    },
    matter: {
      count: 0,
      updatedAt: "",
    },
    department: {
      count: 0,
      updatedAt: "",
    },
    performance: {
      count: 0,
      updatedAt: "",
    },
    timeOff: {
      count: 0,
      updatedAt: "",
    },
  });

  const handleExpandClick = (url) => {
    history.push(`${url}`);
  };

  const getRecords = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/reports/data-count");
    const report = { ...reportsData };
    res?.forEach((element) => {
      const res = {
        ...element,
        updatedAt: element.updatedAt
          ? moment(element.updatedAt).format("LLL")
          : "",
      };
      switch (element.type) {
        case "Employees Report":
          report.emp = res;
          break;
        case "Department Report":
          report.department = res;
          break;
        case "Matters Report":
          report.matter = res;
          break;
        case "Performance Reports":
          report.performance = res;
          break;
        case "Time Off Report":
          report.timeOff = res;
          break;
        default:
      }
    });
    setReportsData(report);
    setLoader(false);
  };

  useEffect(() => {
    getRecords();
  }, []);

  const reports = [
    {
      id: "emp",
      name: "titles.employees_report",
      path: "/reports/employee-report",
      svg: "multiuser",
    },
    {
      id: "matter",
      name: "titles.matters_report",
      path: "/reports/matter-reports",
      svg: "multiuser",
    },
    {
      id: "department",
      name: "titles.departments_report",
      path: "/reports/department-report",
      svg: "multiuser",
    },
    {
      id: "performance",
      name: "titles.performance_report",
      path: "/reports/performance-report",
      svg: "multiuser",
    },
    {
      id: "timeOff",
      name: "titles.time_off_report",
      path: "/reports/timeoff-report",
      svg: "multiuser",
    },
  ];

  return (
    <div className="profiledeptRef">
      <div className="CommonPd">
        <div className="latest_des_box">
          <Grid className="dashTopGrid">
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              style={{ marginTop: "10px" }}
            >
              <Grid>
                <Typography variant="h4" className="morningText">
                  {" "}
                  {t("titles.report")}
                </Typography>
              </Grid>
              {/* <Grid className="btnStyle">
                <ComponentButton
                  icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
                  value={t("labels.add_new")}
                  variant={"contained"}
                  class={"buttonFill"}
                />
              </Grid> */}
            </Grid>

            <Grid container spacing={3} style={{ marginTop: "20px" }}>
              {reports?.map((item) => {
                return (
                  <Grid item xs={12} md={6}>
                    <Card className="profileFormDoc-Card">
                      <div
                        className="Pro-traIcon"
                        aria-label="prophetic_tradition"
                      >
                        <Svg type={item?.svg} />
                      </div>
                      <div className="Pendding-sugest">
                        <Typography variant="h4" className="morningText">
                          {" "}
                          {t(item?.name)}
                        </Typography>
                        <div className="cardContentstyl">
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="textFont"
                          >
                            {reportsData?.[item.id]?.count} {t("titles.report")}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="textFont"
                          >
                            {t("labels.last_update")} :{" "}
                            {reportsData?.[item.id]?.updatedAt}
                          </Typography>
                        </div>

                        <CardActions className="TechDeptBtn" disableSpacing>
                          <Button
                            variant="span"
                            color="text.secondary"
                            className="textFont OpenBtn"
                            style={{ display: "flex" }}
                            onClick={() => handleExpandClick(item?.path)}
                          >
                            {t("labels.open")} <ArrowRightAltIcon />
                          </Button>
                        </CardActions>
                      </div>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
