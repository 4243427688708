import React from "react";
import CompanyDecision from "../companyDecision";
import Schedule from "../LegalSecretary/Common/Schedule";

const MeetingAndDecisions = () => {
  return (
    <>
      <Schedule
        endPoint={"/api/meeting/upcoming/list"}
        disableAddNew={true}
        enableReminderPopup={true}
        modulePath={"company-decision"}
      />{" "}
      <CompanyDecision />
    </>
  );
};

export default MeetingAndDecisions;
