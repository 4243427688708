import React, { useEffect, useContext } from "react";
import http from "../../../api/axios";
import { useTranslation } from "react-i18next";
import LoadingContext from "../../../context/LoaderContext";
import { useState } from "react";
import LogDetail from "./logDetail";
import "./logNote.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { INITIAL_PAGEINDEX } from "../../../constant";

const ComponentLogNote = ({ id, labelKeys, flag }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [logDetail, setLogDetail] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [hasMore, setHasMore] = useState(true);

  const getLogs = async (page = INITIAL_PAGEINDEX, intial = false) => {
    setLoader(true);
    const res = await http.getExportApi(
      t,
      `/api/logs/find/${id}?page=${page + 1}&size=5`
    );
    const newData = !!intial ? res?.data : [...logDetail, ...res?.data];
    setLogDetail(newData);
    setPage(page + 1);
    setHasMore(res?.data_count > newData?.length);
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      getLogs();
    }
  }, [id]);

  useEffect(() => {
    if (flag) {
      getLogs(INITIAL_PAGEINDEX, true);
    }
  }, [flag]);

  return (
    <>
      {logDetail.length !== 0 ? (
        <div>
          <InfiniteScroll
            dataLength={logDetail.length}
            next={() => getLogs(page)}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            height={220}
          >
            <div>
              {logDetail?.map((item, index) => (
                <LogDetail item={item} labelKeys={labelKeys} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <h3 className="textCenter">{t("titles.no_logs_available")}</h3>
      )}
    </>
  );
};

export default ComponentLogNote;
