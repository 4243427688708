import React from "react";
import { Button, Typography } from "@mui/material";
import Svg from "../../assets/svg/svg";

export default function ComponentUpload(props) {
  return (
    <Button variant="outlined" component="label" className="uploadFile">
      <Typography sx={{ pr: 1 }}>
        {" "}
        <Svg type="upload" />
      </Typography>{" "}
      <span className="textLabel">{props.lable}</span>
      <input
        hidden
        accept={props?.accept || "application/pdf,application/vnd.ms-excel"}
        onChange={props.callback}
        name={props.name}
        type="file"
        multiple
        required={props?.required}
      />
    </Button>
  );
}
