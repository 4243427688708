import React from "react";
import { useTranslation } from "react-i18next";
import Schedule from "./Common/Schedule";
import Meetings from "./Common/Meetings";

export default function Exective(props) {
  const { t } = useTranslation();

  return (
    <>
      <Schedule endPoint={"/api/meeting/upcoming/ed/list"} />
      <Meetings
        modulePath="executive_director"
        titile="titles.executive_director_meeting"
        endPoint={"/api/meeting/ed/list"}
      />
    </>
  );
}
