import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../componentGroups/componentButton";
import ComponentTextString from "../../componentGroups/componentTextString";
import ComponentSelect from "../../componentGroups/componentSelect";
import hooks from "../../../Hooks/hooks";
import GenericModal from "../../componentGroups/genericModal";
import http from "../../../api/axios";
import validate from "../../../Hooks/client_validation";
import { loadOptions } from "../../../api/utils/common";
import axios from "axios";
import LoadingContext from "../../../context/LoaderContext";

const INITIAL_VALUES = {
  matterName: "",
  matterCode: "",
  lawyer: "",
  department: "",
  contract: [],
  client: "",
};

const MatterEditView = ({
  id = 1,
  clientId,
  open,
  onCLose,
  getApiData,
}) => {
  const { setLoader } = useContext(LoadingContext);
  const { t, i18n } = useTranslation();
  const [viewMode, setViewMode] = useState(true);
  const [matterData, setMatterData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState([]);
  const [contractsList, setContractsList] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const handleClose = () => {
    setErrors({});
    setMatterData(INITIAL_VALUES);
    setViewMode(true);
    setValue([]);
    onCLose();
  };
  const [departments, setDepartments] = useState([])

  const handleChange = (e, value) => {
    setValue(value);
    const selctedOptionIds = value.map((ele) => ele?.id);
    setMatterData({ ...matterData, contract: selctedOptionIds });
  };

  const getMatterData = async () => {
    setLoader(true);
    await http
      .getApi(t, `/api/matter/${id}`)
      .then(async (res) => {
        const matterData = {
          matterName: res?.matterName ?? "",
          matterCode: res?.matterCode ?? "",
          lawyer: res?.lawyerId ?? "",
          department: res?.departmentId ?? "",
          contract: res?.matter_has_contracts?.map((item) => item?.contractId),
          client: res?.clientId ?? "",
        };
        const contracts = await http.getApi(
          t,
          `/api/matter/client/contracts/${res?.clientId}`
        );
        if (contracts?.length > 0) {
          setContractsList(contracts);
          let value = [];
          res?.matter_has_contracts?.forEach((item) => {
            const a = contracts?.find(
              (el) => parseInt(el?.id) === parseInt(item?.contractId)
            );
            if (!!a) {
              value.push(a);
            }
          });
          setValue(value);
        }
        setMatterData(matterData);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getDropDownOptions = async (val = false) => {
    setLoader(true);
    const contracts = await http.getApi(
      t,
      `/api/matter/client/contracts/${clientId}`
    );
    const departments = await http.getApi(t, `/api/department`);
    if (!!departments && departments?.length > 0) {
      const finalDepts = departments.map(dept => {
        return { ...dept, name: dept?.departmentName }
      })
      setDepartments(finalDepts)
    }
    console.log(departments);
    axios
      .all([
        loadOptions(
          t,
          "lawyer",
          "",
          "get",
          "/api/employee/dropdown/all/attorney"
        ),
      ])
      .then((res) => {
        const lawyers = res[0]?.map((item) => {
          return { ...item, option: item?.name };
        });
        if (!!lawyers && lawyers?.length > 0) {
          setLawyers(lawyers);

        }
      });

  };

  useEffect(() => {
    getDropDownOptions();
  }, []);

  useEffect(() => {
    if (id) {
      getMatterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.matterValidate(matterData);
      setErrors(error);
    }
  }, [matterData]);

  const updateMatter = async () => {
    let error = validate.matterValidate(matterData);
    if (Object.keys(error).length === 0) {
      setLoader(true);
      const formData = new FormData();
      const updatedData = { ...matterData };
      delete updatedData?.contract;
      Object.keys(updatedData).forEach((element) => {
        formData.append(element, updatedData[element]);
      });
      const doccuments = [...matterData.contract];
      doccuments?.forEach((item, index) => {
        formData.append(`contract[${index}]`, item);
      });
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      const res = await http.putApi(t, `/api/matter/${id}`, formData);
      if (!!res) {
        getApiData();
        handleClose();
      }
      setLoader(false);
    } else {
      setErrors(error);
    }
  };

  return (
    <GenericModal title={"labels.matters"} open={open}>
      <div className="scroll-auto ModalContent" style={{ minHeight: "270px" }}>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.matter_name")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.matterName && ( */}
            {/* {errors?.matterName && <span>{t(errors.matterName)}</span>})} */}

            {/* <span className="validate">{t(errors.matterName)}</span> */}
            {/* )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <ComponentTextString
              placeholder={""}
              disable={viewMode}
              name="matterName"
              value={matterData?.matterName}
              callback={(e) => {
                setMatterData({
                  ...matterData,
                  [e.target.name]: e.target.value,
                });
              }}
            />
            {errors?.matterName && (
              <span className="validate">{t(errors.matterName)}</span>
            )}
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.matter_code")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <ComponentTextString
              placeholder={""}
              disable={true}
              name="matterName"
              value={matterData?.matterCode}
              callback={(e) => { }}
            />
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.lawyer")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.lawyer && (
              <span className="validate">{t(errors.lawyer)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <ComponentSelect
              disable={viewMode}
              value={matterData?.lawyer}
              name="lawyer"
              option={lawyers}
              callback={(e) =>
                setMatterData({
                  ...matterData,
                  [e.target.name]: e.target.value,
                })
              }
            />
            {errors?.lawyer && (
              <span className="validate">{t(errors.lawyer)}</span>
            )}
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.department")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.department && (
              <span className="validate">{t(errors.department)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <ComponentSelect
              disable={viewMode}
              value={matterData?.department}
              name="department"
              option={departments}
              callback={(e) =>
                setMatterData({
                  ...matterData,
                  [e.target.name]: e.target.value,
                })
              }
            />
            {errors?.department && (
              <span className="validate">{t(errors.department)}</span>
            )}
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.contract")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.contract && (
              <span className="validate">{t(errors.contract)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <Autocomplete
              multiple
              id="tags-standard"
              disabled={viewMode}
              options={contractsList}
              getOptionLabel={(option) => option.name}
              value={value}
              onChange={(e, value) => handleChange(e, value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Contract"
                />
              )}
            />
            {errors?.contract && (
              <span className="validate">{t(errors.contract)}</span>
            )}
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        {viewMode && (
          <Grid container className="modalGroup" style={{ marginTop: "5px", gap: "10px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.close")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.edit")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => {
                  setViewMode(false);
                }}
              />
            </Grid>
          </Grid>
        )}
        {!viewMode && (
          <Grid container className="modalGroup" style={{ marginTop: "5px", gap: "10px" }}>
            <Grid item>
              <ComponentButton
                value={t("labels.discard")}
                variant={"outlined"}
                class={"buttonBlank outlineBtn"}
                callback={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <ComponentButton
                value={t("labels.save")}
                variant="contained"
                class="buttonFill FillBtn"
                callback={() => updateMatter()}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </GenericModal>
  );
};

export default MatterEditView;
