import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import Svg from "../../../../assets/svg/svg";
import ComponentButton from "../../../componentGroups/componentButton";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ComponentModal from "../../../componentGroups/componectModel";
import { loadOptions } from "../../../../api/utils/common";
import http from "../../../../api/axios";
import useFormatTime from "../../../../Hooks/useFormatTime";

export default function MeetingDetails({ values, setLoader }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { globalToLocal } = useFormatTime();
  const [value, setValue] = useState("1");
  const [meetingData, setMeetingData] = useState([]);
  const [filterState, setFilterState] = useState({
    employess: [],
    department: [],
    branch: [],
    personal: "",
  });
  const [modalData, setModelData] = useState({
    model: false,
  });
  const handleModel = async (val) => {
    if (val == "2") {
      let employessData = await loadOptions(
        t,
        1,
        undefined,
        "post",
        "/api/employee"
      );
      setModelData({
        ...modalData,
        model: true,
        title: "titles.filter_employee",
        data: [
          {
            lable: "labels.employee_name",
            field: {
              type: "multiple",
              required: true,
              setlable: "name",
              option: employessData,
            },
            name: "employess",
          },
        ],
        buttonGroup: [
          {
            value: "labels.discard",
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              setFilterState({ ...filterState, employess: [] });
              setModelData({ ...modalData, model: false });
            },
          },
          {
            value: "labels.filter_by",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (val) => {
              setModelData({ ...modalData, model: false });
              getApiData(val);
            },
          },
        ],
      });
    } else if (val == "1") {
      let departmentData = await loadOptions(
        t,
        1,
        undefined,
        "get",
        "/api/department"
      );
      setModelData({
        ...modalData,
        model: true,
        title: "titles.filter_department",
        data: [
          {
            lable: "labels.department",
            field: {
              type: "multiple",
              required: true,
              setlable: "departmentName",
              option: departmentData,
            },
            name: "department",
          },
        ],
        buttonGroup: [
          {
            value: "labels.discard",
            variant: "outlined",
            class: "buttonBlank outlineBtn Mright",
            callback: () => {
              setFilterState({ ...filterState, department: [] });
              setModelData({ ...modalData, model: false });
            },
          },
          {
            value: "labels.filter_by",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (val) => {
              setModelData({ ...modalData, model: false });
              getApiData(val);
            },
          },
        ],
      });
    } else if (val == "3") {
      let branchData = await loadOptions(
        t,
        1,
        undefined,
        "post",
        "/api/branch/details"
      );
      setModelData({
        ...modalData,
        model: true,
        title: "titles.filter_branch",
        data: [
          {
            lable: "labels.branch_name",
            field: {
              type: "multiple",
              required: true,
              setlable: "branchName",
              option: branchData,
            },
            name: "branch",
          },
        ],
        buttonGroup: [
          {
            value: "labels.discard",
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: () => {
              setFilterState({ ...filterState, branch: [] });
              setModelData({ ...modalData, model: false });
            },
          },
          {
            value: "labels.filter_by",
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (val) => {
              setModelData({ ...modalData, model: false });
              getApiData(val);
            },
          },
        ],
      });
    } else if (val == "4") {
      if (filterState?.personal == "1") {
        setFilterState({ ...filterState, personal: "" });
        getApiData(filterState, "");
      } else {
        setFilterState({ ...filterState, personal: 1 });
        getApiData(filterState, "1");
      }
    }
  };

  const getApiData = async (obj, personal) => {
    setLoader(true);
    console.log(obj, "obj");
    let formdata = new FormData();
    obj?.employess?.map((op, index) => {
      formdata.append(`filter[employees][${index}]`, op?.id ? op?.id : "");
    });
    obj?.department?.map((op, index) => {
      formdata.append(`filter[departments][${index}]`, op?.id ? op?.id : "");
    });
    obj?.branch?.map((op, index) => {
      formdata.append(`filter[branches][${index}]`, op?.id ? op?.id : "");
    });
    if (personal == "1") {
      formdata.append("filter[personal]", personal);
    }
    const res = await http.getAllDataApi(
      t,
      `/api/meeting/list?date=${moment(values.$d).format("YYYY-MM-DD")}`,
      formdata
    );
    setMeetingData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getApiData();
  }, [values]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <ComponentModal
        modalData={modalData}
        setPerantState={setFilterState}
        perantState={filterState}
      />
      {/* {meetingData?.length != 0 && */}
      <div className="tabss-meeting">
        <Box className="Tabmobile" sx={{ width: "100%", typography: "body1" }}>
          <TabContext className="Tabmobile" value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                className="Tabmobileeee"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Typography variant="h6" className="">
                  {" "}
                  {t("labels.filter_by")}{" "}
                </Typography>
                <div
                  className={
                    " " + filterState?.department?.length != 0
                      ? " tabs-meeting-click TabmobileBtn"
                      : "TabmobileBtn"
                  }
                  onClick={() => handleModel("1")}
                >
                  <Tab
                    className={
                      " " + filterState?.department?.length == 0
                        ? " Tabs-border "
                        : ""
                    }
                    label={t("titles.departments")}
                    value="1"
                  />
                  {filterState?.department?.length != 0 && (
                    <span className="totalCiount">
                      {filterState?.department?.length}
                    </span>
                  )}
                </div>
                <div
                  className={
                    " " + filterState?.employess?.length != 0
                      ? " tabs-meeting-click TabmobileBtn"
                      : "TabmobileBtn "
                  }
                  onClick={() => handleModel("2")}
                >
                  <Tab
                    className={
                      " " + filterState?.employess?.length == 0
                        ? " Tabs-border "
                        : ""
                    }
                    label={t("labels.employee")}
                    value="2"
                  />
                  {filterState?.employess?.length != 0 && (
                    <span className="totalCiount1">
                      {filterState?.employess?.length}
                    </span>
                  )}
                </div>
                <div
                  className={
                    " " + filterState?.branch?.length != 0
                      ? " tabs-meeting-click TabmobileBtn"
                      : "TabmobileBtn "
                  }
                  onClick={() => handleModel("3")}
                >
                  <Tab
                    className={
                      " " + filterState?.branch?.length == 0
                        ? " Tabs-border "
                        : ""
                    }
                    label={t("labels.branch")}
                    value="3"
                  />
                  {filterState?.branch?.length != 0 && (
                    <span className="totalCiount2">
                      {filterState?.branch?.length}
                    </span>
                  )}
                </div>
                <div
                  className={
                    " " + filterState?.personal == 1
                      ? " tabs-meeting-click TabmobileBtn"
                      : "TabmobileBtn"
                  }
                  onClick={() => handleModel("4")}
                >
                  <Tab
                    className={
                      " " + filterState?.personal != 1 ? " Tabs-border" : ""
                    }
                    label={t("labels.personal")}
                    value="4"
                  />
                </div>
              </TabList>
            </Box>

            <TabPanel className="" value="2">
              Department
            </TabPanel>
            <TabPanel className="" value="3">
              Employee
            </TabPanel>
            <TabPanel className="" value="4">
              Branch
            </TabPanel>
            <TabPanel className="" value="5">
              Personal
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      {/* // } */}
      {meetingData?.length != 0 &&
        meetingData?.map((meet, index) => (
          <div item xs={12} md={12} className="details_box">
            <div className="detailsBox">
              <div>
                <Typography variant="h6" className="title iconText">
                  {meet.title}
                </Typography>
                <Typography variant="body2" className="details">
                  {meet.description}
                </Typography>
                <Typography variant="body2" className="details">
                  {t("labels.meeting_time")}:{" "}
                  {globalToLocal(meet.start_time, "h:mm a")}
                </Typography>
              </div>
            </div>
            <ComponentButton
              value={t("labels.view_details")}
              variant="outlined"
              class="buttonBlank "
              sx={{ paddingRight: "0px !important" }}
              callback={() => {
                history.push({
                  pathname: "/createMeeting/meeting-detail",
                  state: { id: meet.id },
                });
              }}
            />
          </div>
        ))}
      <div className="messege_meeting">
        {meetingData?.length == 0 && t("titles.no_meeting_available")}
      </div>
    </div>
  );
}
