import { useRef } from "react";

const useDebounce = () => {
  const debounceSeed = useRef(null);
  const debounceSearch = useRef((onSearch, timeout = 200) => {
    if (debounceSeed.current) {
      clearTimeout(debounceSeed.current);
      debounceSeed.current = null;
    }
    debounceSeed.current = setTimeout(() => {
      onSearch();
    }, timeout);
  });
  return debounceSearch.current;
};

export default useDebounce;
