import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Card, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ComponentButton from "../../../componentGroups/componentButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Avatar from "@mui/material/Avatar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReferenceCard from "../../../componentGroups/componentRefrenceCard";

export default function ProfiledeptMeetingDecisions(props) {
  const { t, i18n } = useTranslation();

  return (
    <div className="CommonPd">
      <div className="dashTopGrid">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {" "}
          <Grid>
            <Typography className="morningText" variant="h4">
              {t("titles.meeting_title_decisions")}
            </Typography>
          </Grid>
          <div className="PublicDecisionsBtn">
            <Button
              variant="span"
              color="text.secondary"
              className="Pendding-sugestbtn"
            >
              Public Decisions{" "}
            </Button>
          </div>
        </Grid>
        <div className="reference_code_box">
          <div className="LeftRightmeetDecision">
            <div className="leftMeetdec">
              <Typography className="morningText" variant="h4">
                Decision 1){" "}
              </Typography>
            </div>
            <div className="rightMeetdec">
              <Typography component="span" variant="body2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </Typography>
            </div>
          </div>
        </div>
        <div className="reference_code_box">
          <div className="LeftRightmeetDecision">
            <div className="leftMeetdec">
              <Typography className="morningText" variant="h4">
                Decision 2){" "}
              </Typography>
            </div>
            <div className="rightMeetdec">
              <Typography component="span" variant="body2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </Typography>
            </div>
          </div>
        </div>
        <div className="reference_code_box">
          <div className="LeftRightmeetDecision">
            <div className="leftMeetdec">
              <Typography className="morningText" variant="h4">
                Decision 3){" "}
              </Typography>
            </div>
            <div className="rightMeetdec">
              <Typography component="span" variant="body2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </Typography>
            </div>
          </div>
        </div>
        <div className="reference_code_box">
          <div className="LeftRightmeetDecision">
            <div className="leftMeetdec">
              <Typography className="morningText" variant="h4">
                Decision 4){" "}
              </Typography>
            </div>
            <div className="rightMeetdec">
              <Typography component="span" variant="body2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </Typography>
            </div>
          </div>
        </div>
        <div className="reference_code_box">
          <div className="LeftRightmeetDecision">
            <div className="leftMeetdec">
              <Typography className="morningText" variant="h4">
                Decision 5){" "}
              </Typography>
            </div>
            <div className="rightMeetdec">
              <Typography component="span" variant="body2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </Typography>
            </div>
          </div>
        </div>
        <div className="reference_code_box">
          <div className="LeftRightmeetDecision">
            <div className="leftMeetdec">
              <Typography className="morningText" variant="h4">
                Decision 6){" "}
              </Typography>
            </div>
            <div className="rightMeetdec">
              <Typography component="span" variant="body2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
