import React from 'react';
import MiniDrawer from './components/comman';
import ContextProvider from './context/ContextProvider';

function App() {
  return (
    <ContextProvider>
      <MiniDrawer />
    </ContextProvider>
  );
}

export default App;
