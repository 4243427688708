import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { decode } from "string-encode-decode";
import DetailContainer from "./detailContainer";

const MeetingDecisionPrint = ({
  detailItem,
  meetingSignature,
  meetingData,
}) => {
  return (
    <div className="CommonPd_decision">
      <div>
        <div>
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Meeting Title :
          </span>{" "}
          <span style={{ fontSize: "14px" }}> {meetingData?.title} </span>
        </div>
        <div style={{ marginTop: "10px" }}>
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Description :
          </span>{" "}
          <span style={{ fontSize: "14px" }}> {meetingData?.description} </span>
        </div>
      </div>

      <div className="Meeting_decisionRoot">
        <div className="root_decision">
          <DetailContainer detailItem={detailItem} meetingData={meetingData} />
        </div>
      </div>

      {meetingSignature && meetingSignature.length != 0 && (
        <div
          container
          style={{ marginTop: "20px", display: "flex", flexWrap: "wrap" }}
        >
          {meetingSignature &&
            meetingSignature?.map((code, index) => (
              <div
                className="headerWidth"
                style={{
                  flex: "0 0 33.33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div className="headerForm">
                    <Typography
                      variant="body2"
                      className="titleHead menuTableRel"
                    >
                      {code.userName} ( {code.department} )
                    </Typography>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      textAlign: "center",
                      minHeight: "50px",
                    }}
                  >
                    <div className="CrossBtn cutombtnImg">
                      {code?.sign && (
                        <div className="qr">
                          <QRCode value={decode(code?.sign)} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
export default MeetingDecisionPrint;
