import React, { useState, useEffect, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Typography, Button, Grid } from "@mui/material";
import Svg from "../../../assets/svg/svg";
import { useTranslation } from "react-i18next";
import "../../../assets/css/comman/commonStyle.css";
import ComponentSearch from "../../componentGroups/componentSearch";
import ComponentButton from "../../componentGroups/componentButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import http from "../../../api/axios";
import LoadingContext from "../../../context/LoaderContext";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
const style = {
  width: "100%",
  // maxWidth: 360,
  bgcolor: "background.paper",
};

export default function Notifaction() {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const [notifactionData, setNotifactionData] = useState([]);
  const [search, setSearch] = useState("");

  const getApiData = async () => {
    setLoader(true);
    let res = await http.getAllDataApi(
      t,
      `/api/notification/details?search=${search}`
    );
    setLoader(false);
    setNotifactionData(res?.data);
  };

  const markAsRead = async (id) => {
    const formData = new FormData();
    formData.append("id", id);
    let res = await http.patchApi(
      t,
      `/api/user/profile/notification/read`,
      formData
    );
    getApiData();
  };

  useEffect(() => {
    getApiData();
  }, [search]);

  const gotoDetails = (id, type) => {
    switch (type) {
      case "Department":
        history.push({
          pathname: `/add_department/${id}`,
          state: { id: id },
        });
        break;
      case "Client":
        history.push({
          pathname: `/clients/edit-client/${id}`,
          state: { id: id },
        });
        break;
      case "Task":
        history.push({
          pathname: "/taskInfo",
          state: { id: id },
        });
        break;
      case "Branch":
        history.push({
          pathname: `/branches/branchInfo/${id}`,
          state: { id: id },
        });
        break;
      case "Users":
        history.push({
          pathname: `/addEmployee/${id}`,
          state: { id: id },
        });
        break;
      case "Meeting":
        history.push({
          pathname: "/meetingDetails",
          state: { id: id },
        });
        break;
      case "Project":
        history.push(`/projects/edit-project/${id}`);
        break;

      case "Attendance Request":
        history.push(`/notification/emp-attendance/view/${id}`);
        break;
      case "Promotion":
        history.push(`/notification/promotiondetail/${id}`);
        break;
      case "MeetingValidation":
      case "MeetingDecisionValidation":
        history.push(`/notification/meeting-mins/${id}`);
        break;
      case "Loan Request":
        history.push(`/notification/loam-details/${id}`);
        break;
      case "Broadcast":
        history.push(`/notification/broadcasts`);
        break;
      case "Allocation":
        history.push(`/notification/allocation`);
        break;
      default:
        return;
    }
  };

  return (
    <div className="mainConatiner container">
      <Grid container className="gridGroup">
        <Grid item md={2}>
          <Typography variant="h6" className="morningText">
            {t("labels.notification")}
          </Typography>
        </Grid>
        <Grid item md={4} className="searchResponsive">
          <ComponentSearch
            value={search}
            callback={(e) => setSearch(e.target.value)}
          />
        </Grid>
        {/* <Grid item md={6} sx={{ textAlign: "right" }}>
                <div className='NotificationBtn'>   
                 <ComponentButton icon={<FilterListIcon />} value="FILTER BY" variant="outlined" class="buttonBlank outlineBtn" />
                 </div>
                </Grid> */}
      </Grid>
      <List sx={style} component="nav" aria-label="mailbox folders">
        {notifactionData?.length != 0 &&
          notifactionData?.map((op, index) => {
            return (
              <div className="noficationPg" key={index}>
                <ListItem className="listBoxNotify">
                  <div className="listBoxItem" style={{ width: "65%" }}>
                    <Svg type="notifaction" />
                    <Typography className="titleNotify">
                      {op.message}
                    </Typography>
                    <Svg type="dot" />
                    <Typography className="time">
                      {moment(moment(op.created_at).add(1, "hours"))
                        .add(30, "minutes")
                        .fromNow()}
                    </Typography>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="text"
                      onClick={() => markAsRead(op.id)}
                      className="notifyButton"
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      {t("titles.mark_as_read")}
                    </Button>
                    {[
                      "Department",
                      "Client",
                      "Task",
                      "Branch",
                      "Meeting",
                      "Project",
                      "Attendance Request",
                      "Promotion",
                      "MeetingValidation",
                      "MeetingDecisionValidation",
                      "Loan Request",
                      "Allocation",
                      "Broadcast",
                    ].includes(op.share_type) && (
                      <ComponentButton
                        value={t("Check Now")}
                        variant={"outlined"}
                        class={"buttonBlank outlineBtn"}
                        callback={() =>
                          gotoDetails(op?.share_id, op?.share_type)
                        }
                      />
                    )}
                  </div>
                </ListItem>
                <Divider className="divider" />
              </div>
            );
          })}
      </List>
    </div>
  );
}
