import React, { createContext, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GenericModal from "../components/componentGroups/genericModal";
import ComponentButton from "../components/componentGroups/componentButton";

const initialVal = false;
const AuthorityContext = createContext(initialVal);
const AuthorityContextProvider = AuthorityContext.Provider;

export const AuthorityProvider = ({ children }) => {
  const { t } = useTranslation();
  const [authorityModal, setAuthorityModal] = useState(initialVal);

  return (
    <AuthorityContextProvider value={{ authorityModal, setAuthorityModal }}>
      <GenericModal
        title={""}
        open={authorityModal}
        onClose={() => setAuthorityModal(false)}
        enableCloseIcon={true}
      >
        <div className="CommonPd">
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography className="morningText" variant="h4">
                {t("titles.permission_denied")}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                {t("titles.you_dont_have_permission_to_perform_this_operation")}
              </div>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>{t("titles.contact_your_administrator")}</div>
            </Grid>
            <Grid
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <ComponentButton
                value={t("labels.close")}
                variant={"outlined"}
                class={"buttonBlank ml-10"}
                callback={() => {
                  setAuthorityModal(false);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </GenericModal>
      {children}
    </AuthorityContextProvider>
  );
};

export default AuthorityContext;
