import React from "react";

export default function ComponentDatePicker(props) {
  return (
    <input
      type={props?.type ? props?.type : "date"}
      min={props?.min}
      max={props?.max}
      name={props.name}
      onChange={props.callback}
      value={props.value}
      className={props.class}
    />
  );
}
