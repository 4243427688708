import React, { useState } from "react";
import ComponentButton from "../../../../../componentGroups/componentButton";
import MinHeightTextarea from "../../../../../componentGroups/componentTextarea";
import { useTranslation } from "react-i18next";
import http from "../../../../../../api/axios";
const AddComment = ({ setLoader, Id }) => {
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({});
  const [comment, setComment] = useState("");

  console.log(Id, "IdIdIdId");
  const sendComment = async () => {
    if (comment == undefined && comment == "") {
      setErrors({ comment: "errors.error_comment" });
    } else {
      setLoader(true);
      var formdata = new FormData();
      formdata.append("shareType", 3);
      formdata.append("fileId", Id);
      formdata.append("comments", comment);
      let res = await http.postApi(
        t,
        "/api/form_doc/form/addComment",
        formdata,
        "labels.comment_added_successfully"
      );
      if (res) {
        setErrors({});
        setComment("");
      }
      setLoader(false);
    }
  };

  return (
    <div className="commentOuter formCooment">
      <div>
        <MinHeightTextarea
          minRows={6}
          cols={140}
          value={comment}
          callback={(e) => setComment(e.target.value)}
        />
      </div>
      <div>
        <ComponentButton
          value={t("labels.add_comment")}
          class={"buttonFill"}
          callback={() => {
            sendComment();
          }}
        />
      </div>
    </div>
  );
};

export default AddComment;
