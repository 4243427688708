import React, { useState, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SendReminderModal from "./SendReminderModal";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";

const ReminderItem = ({
  item,
  enableReminderPopup = true,
  modulePath = "",
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [reminderModal, setReminderModal] = useState({
    open: false,
    id: "",
  });

  /**
   * handles seding reminder based on meeting users or use a custom popup to send reminder
   */
  const handleReminder = async () => {
    if (enableReminderPopup) {
      setReminderModal({
        id: item?.id,
        open: true,
      });
    } else {
      setLoader(true);
      await http.getApi(t, `/api/meeting/${item?.id}/reminder/send`, true);
      setLoader(false);
    }
  };

  return (
    <>
      <SendReminderModal
        id={reminderModal?.id}
        open={reminderModal?.open}
        onCLose={() =>
          setReminderModal({
            id: "",
            open: false,
          })
        }
      />
      <div className="dashboard Btn">
        <Grid className="gridLeft">
          <Typography component="span" variant="body2">
            {item?.title}
          </Typography>
        </Grid>
        <Grid className="gridLeftbtn">
          <ComponentButton
            value={t("labels.view_details")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
            callback={() => {
              history.push({
                pathname: modulePath
                  ? `/${modulePath}/meeting-detail`
                  : "/meetingDetails",
                state: { id: item?.id },
              });
            }}
          />
          <ComponentButton
            value={t("labels.send_reminder")}
            variant={"contained"}
            class={"buttonFill"}
            callback={() => {
              handleReminder();
            }}
          />
        </Grid>
      </div>
    </>
  );
};

export default ReminderItem;
