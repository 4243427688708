import React, { useState } from "react";
import { createContext } from "react";

const intialValue = [
  {
    name: "",
    path: "",
  },
];

const BreadCrumbContext = createContext(intialValue);
const BreadCrumbProvider = BreadCrumbContext.Provider;

export const BreadCrumbNameProvider = ({ children }) => {
  const [pathValue, setPathValue] = useState(intialValue);

  return (
    <BreadCrumbProvider value={{ pathValue, setPathValue }}>
      {children}
    </BreadCrumbProvider>
  );
};

export default BreadCrumbContext;
