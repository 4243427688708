import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ProjectItem = ({ item }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="dashboard Btn">
        <Grid className="gridLeft">
          <Typography component="span" variant="body2">
            {item?.projectName}
          </Typography>
        </Grid>
        <Grid className="gridLeftbtn">
          <ComponentButton
            value={t("labels.view_details")}
            variant={"outlined"}
            class={"buttonBlank Mright"}
            callback={() => {
              history.push({
                pathname: "/projectInfo",
                state: { id: item?.id },
              });
            }}
          />
        </Grid>
      </div>
    </>
  );
};

export default ProjectItem;
