import { Button, Card, CardActions, Grid, Typography } from '@mui/material'
import React from 'react'

// import { ExpandMore } from '@mui/icons-material'
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Svg from '../../../assets/svg/svg';
import { DateLine } from '../Technical Department';

const VacancyCard = ({ svg, link, data, title, countTitle }) => {

  const { t } = useTranslation();

  const history = useHistory();

  const handleExpandClick = (url, data) => {
    if (data?.id) localStorage.setItem('vacancy', JSON.stringify(data));
    history.push(url);
  }
  return (
    <Grid item xs={12} md={6} xl={4}>
      <Card className='profileFormDoc-Card' style={{
        cursor: "pointer"
      }}
        onClick={() => handleExpandClick(link, data)}
      >
        {svg ? <div className='Pro-traIcon' aria-label="prophetic_tradition">
          <Svg type={svg} />
        </div> : ""}
        <div className='Pendding-sugest'>
          <Typography variant='h4' className="morningText" style={{
            textTransform: "capitalize"
          }}>

            {data ? data?.jobTitle : ''}
          </Typography>


          <Typography color="text.secondary" className="textFont" style={{
            fontSize: "16px",
            marginTop: "10px"
          }}>
            {t('labels.branch')} : {data?.branch?.branchName ?? ''}
          </Typography>
          <div className="cardContentstyl">
            {data ? <DateLine title={t('labels.created_on')} date={data?.createdAt ? data?.createdAt : Date.now()} /> : ''}
          </div>
          <Grid item xs={12} md={12} sx={{
            justifyContent: "end", display: "flex"
          }}>
            <span variant="span" color="text.secondary" className="textFont OpenBtn" style={{ display: "flex", gap: "1rem", alignItems: "center", }}>
              {t('labels.open')} <EastIcon color='#000' />
            </span>
          </Grid>
        </div>
      </Card>
    </Grid >
  )
}

export default VacancyCard