import { Grid, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import ComponentTextString from '../../componentGroups/componentTextString';
import MinHeightTextarea from '../../componentGroups/componentTextarea';
import ComponentButton from '../../componentGroups/componentButton';
import { useHistory } from "react-router-dom";

const BroadcastInfo = () => {
  const { t } = useTranslation();
  const broadcast = JSON.parse(localStorage.getItem("broadcast"));
  const history = useHistory();

  return (
    <div className="BodDecisionPage">
      <div className="CommonPd latest_des_box">
        <Grid className="dashTopGrid">
          <div
            className="detailsBox"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="DashTitle" variant="h4">
              {t("labels.broadcast_info")}
            </Typography>
          </div>
          <div style={{ marginLeft: "10px", marginTop: "40px", paddingInline: "20px" }}>
            <Grid className="AddEmpGenInfo" container rowSpacing={4}>
              {/* title */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.title")}{" "}

                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="title"
                    value={broadcast?.title}
                    disabled={true}
                  />

                </div>
              </Grid>

              {/* from */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.from")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="from"
                    value={broadcast?.created_by}
                    disabled={true}
                  />
                </div>
              </Grid>


              {/* type */}
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.type")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="type"
                    value={broadcast?.employeeName ? t('labels.employee') : broadcast?.branchName ? t('labels.branch') : broadcast?.departmentName ? t('labels.department') : ''}
                    disabled={true}
                  />
                </div>
              </Grid>

              {/* to */}
              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.to")}
                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <ComponentTextString
                    className="GenDropdown"
                    name="to"
                    value={broadcast?.employeeName || broadcast?.branchName || broadcast?.departmentName || ''}
                    disabled={true}
                  />
                </div>
              </Grid>





              {/* description */}

              <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
                <Typography variant="body1" className="customLabel">
                  {t("labels.description")}{" "}

                </Typography>
              </Grid>
              <Grid className="CustomWidth" item xs={11} md={9}>
                <div>
                  <MinHeightTextarea
                    class={"custonInput"}
                    name="description"
                    minRows={4}
                    placeholder={"Enter the description"}
                    value={broadcast?.description}
                    disabled={true}
                  />
                  {/* <ComponentCKEditer
                    style={{ marginRight: "25px" }}
                    value={reqData?.description}
                    name="description"
                    callback={(data) =>
                      setReqData({
                        ...reqData,
                        description: data,
                      })
                    }
                  /> */}

                </div>
              </Grid>


            </Grid>



            <div style={{ marginTop: "20px" }} className="ModalFooter">
              <Grid
                container
                className="modalGroup"
                style={{ marginTop: "5px" }}
              >

                <Grid item style={{ marginLeft: "15px" }}>
                  <ComponentButton
                    sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                    value={t("labels.discard")}
                    variant={"outlined"}
                    class={"buttonBlank delbtnRefrence"}
                    callback={() => history.push(`/broadcasts`)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  )
}

export default BroadcastInfo