import axios from "axios";
import auth from "../services/auth";
import { toast } from "react-toastify";
// export const baseURL = "http://16.163.76.215:8000";
export const baseURL = "http://localhost:8000";

const localToken = auth.getUser("token");

const handleErrorMsg = (err, t) => {
  if (!err?.response) {
    toast.error(t("labels.no_server_response"));
  } else if (err.response?.status === 400) {
    toast.error(err.response.data.message);
  } else if (err.response?.status === 401) {
    toast.error(t("labels.unauthorized"));
  } else if (!err.status) {
    toast.error(err.response.data.message);
  } else {
    toast.error(t("labels.failed"));
  }
};

export const deleteApi = async (t, url, msg, params) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.delete(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
      params
    });
    toast.success(!!msg ? t(msg) : t("labels.data_deleted_permanently"));
    return response?.data?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

const deleteFileApi = async (t, url, data = {}) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.delete(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
      data: data,
    });
    toast.success(t("labels.data_deleted_permanently"));
    return response?.data?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const postApi = async (t, url, data, msg = "") => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.post(`${baseURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
    });
    if (msg !== false) {
      toast.success(
        !!msg
          ? t(msg)
          : response?.data?.message ?? t("labels.successfully_created")
      );
    }
    return response?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const getAllDataApi = async (t, url, data, params) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.post(`${baseURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
      params: params ? params : "",
    });
    return response?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const getAllData = async (t, url, params) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.get(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
      params: params ? params : "",
    });
    return response?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const putApi = async (t, url, data, headers = {}) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.put(`${baseURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
        ...headers,
      },
    });
    toast.success(t("labels.updated_successfully"));
    return response;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const patchApi = async (t, url, data, headers = {}) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.patch(`${baseURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
        ...headers,
      },
    });
    toast.success(t("labels.updated_successfully"));
    return response;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const getApi = async (t, url, showMsg = false, params) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.get(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
      params: params ? params : "",
    });
    showMsg &&
      response?.status === 200 &&
      toast.success(response?.data?.message);
    return response?.data?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export const postApiWithoutToast = async (path, params, data, token) => {
  const config = {
    headers: {
      Authorization: token ? token : `Bearer ${localToken}`,
    },
    params,
  };
  return axios
    .post(`${baseURL}${path}`, data, config)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const Login_APi = async (t, url, data) => {
  try {
    const response = await axios.post(`${baseURL}${url}`, data, {
      headers: {
        "content-type": "multipart/form-data",
        withCredentials: true,
      },
    });
    auth.login("token", response?.data?.token);
    auth.login("user", response?.data?.data);
    const user_authorities =
      response?.data?.data?.users_has_responsibilites?.map(
        (item) => item?.auth_id
      );
    auth.login("authority", user_authorities);

    return response?.data?.data;
  } catch (err) {
    if (!err?.response) {
      toast.error(t("labels.no_server_response"));
    } else if (err.response?.status == 400) {
      toast.error(
        err.response.data.message == t("labels.incomplete_request")
          ? t("labels.Missing_username")
          : err.response.data.message
      );
    } else if (err.response?.status == 401) {
      toast.error(t("labels.unauthorized"));
    } else {
      toast.error(t("labels.failed"));
    }
  }
};

const getExportApi = async (t, url, showMsg = false) => {
  let lawUser = auth.getUser("token");
  try {
    const response = await axios.get(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${lawUser}`,
      },
    });
    return response?.data;
  } catch (err) {
    handleErrorMsg(err, t);
  }
};

export default {
  baseURL,
  // socketApi,
  Login_APi,
  getApi,
  postApi,
  putApi,
  deleteApi,
  getAllDataApi,
  deleteFileApi,
  patchApi,
  getExportApi,
};
