import * as React from "react";
import "./style.css";
import PastDecisions from "./PartnerMeeting/PastDecisions";
import UpComingAppointments from "./PartnerMeeting/UpComingAppointments";
import Greeting from "../DashBoard/Greeting";
import DashBoardPendingRequests from "../DashBoard/DashBoardPendingRequests";
import DashBoardAttendanceRequest from "../DashBoard/DashBoardAttendanceRequest";

export default function PartnerDashboard() {
  return (
    <>
      <Greeting />
      <DashBoardPendingRequests />
      <DashBoardAttendanceRequest />
      <PastDecisions />
      <UpComingAppointments />
    </>
  );
}
