export const MEET_KEY_LABELS = {
  title: "labels.meeting_title",
  date: "labels.date",
  start_time: "labels.time",
  end_time: "labels.to",
  location: "labels.location",
  url: "labels.meeting_url",
  branchName: "labels.branch_name",
  notification: "labels.notification",
  departments: "titles.departments",
  guests: "labels.guests",
  documents: "labels.documents",
  description: "labels.description",
  updatedBy: "titles.updated_by",
};
