/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Grid } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "react-circular-progressbar/dist/styles.css";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";
import BreadCrumbContext from "../../../../context/BreadCrumbContext";
import ComponentButton from "../../../componentGroups/componentButton";
import AddEditCategory from "./AddEditCategory";
import AuthorityContext from "../../../../context/AuthorityContext";
import { getUserHasAccess } from "../../../../utils/common";

export default function GlobalFields() {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const { setPathValue } = useContext(BreadCrumbContext);
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const [categories, setCategories] = useState([]);

  const handleExpandClick = (item) => {
    setPathValue &&
      setPathValue([
        {
          name: item.name,
          path: `/settings/category/${item.id}`,
        },
      ]);
    history.push(`/settings/category/${item.id}`);
  };

  const getRecords = async () => {
    setLoader(true);
    const res = await http.getApi(t, "/api/dropdown/show-categories");
    const data = res?.map((element) => {
      return {
        ...element,
        updatedAt: element.updatedAt
          ? moment(element.updatedAt).format("LLL")
          : "",
      };
    });
    setCategories(data);
    setLoader(false);
  };

  useEffect(() => {
    getRecords();
  }, []);

  const handleClose = () => {
    setOpen(false);
    getRecords();
  };

  return (
    <>
      <AddEditCategory open={open} operationId={1} onClose={handleClose} />
      <div className="profiledeptRef">
        <div className="CommonPd">
          <div className="latest_des_box">
            <Grid className="dashTopGrid">
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                style={{ marginTop: "10px" }}
              >
                <Grid>
                  <Typography variant="h4" className="morningText">
                    {" "}
                    {t("titles.global_fields")}
                  </Typography>
                </Grid>
                <Grid className="btnStyle">
                  <ComponentButton
                    value={t("labels.add_new")}
                    variant={"contained"}
                    class={"buttonFill"}
                    callback={() => {
                      if (getUserHasAccess("settings", 176)) {
                        setOpen(true);
                      } else {
                        setAuthorityModal(true);
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                columnSpacing={3}
                spacing={3}
                style={{ marginTop: "20px" }}
              >
                {categories?.map((item) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <Card className="profileFormDoc-Card">
                        <div className="Pendding-sugest">
                          <Typography variant="h4" className="morningText">
                            {" "}
                            {item?.name}
                          </Typography>
                          <div className="cardContentstyl">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="textFont"
                            >
                              {t("labels.types_number")} : {item?.count}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="textFont"
                            >
                              {t("labels.last_update")} : {item?.updatedAt}
                            </Typography>
                          </div>

                          <CardActions className="TechDeptBtn" disableSpacing>
                            <Button
                              variant="span"
                              color="text.secondary"
                              className="textFont OpenBtn"
                              style={{ display: "flex" }}
                              onClick={() => handleExpandClick(item)}
                            >
                              {t("labels.open")} <ArrowRightAltIcon />
                            </Button>
                          </CardActions>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
