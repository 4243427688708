import React, { useContext, useEffect, useState } from 'react'
import { getApi, postApi } from '../../../api/axios';
import BroadcastCard from '../../componentGroups/BroadcastCard';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComponentButton from '../../componentGroups/componentButton';
// import ComponentModal from '../../componentGroups/componectModel';
import AddIcon from "@mui/icons-material/Add";
// import ModalComponent from './Modal';
// import { getUserHasAccess } from '../../../utils/common';
// import AuthorityContext from '../../../context/AuthorityContext';
// import broadcastValidaction from '../../../Hooks/broadcast_validation';
import { useHistory } from "react-router-dom";
import { getUserHasAccess } from '../../../utils/common';
import AuthorityContext from '../../../context/AuthorityContext';
import TabPanel, { a11yProps } from '../Allocation/TabPanel';
import SentBroadcasts from './SentBroadcasts';
import RecievedBroadcasts from './RecievedBroadcasts';

const Broadcasts = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthorityModal } = useContext(AuthorityContext);
  // const [errors, setErrors] = useState({});
  // const [broadcast, setBroadcast] = useState({})
  // const [dropdownOptions, setDropdwonOptions] = useState({});
  // const { setAuthorityModal } = useContext(AuthorityContext);
  // const [modalData, setModelData] = useState({
  //   model: false,
  //   title: "titles.add_new_broadcast",
  //   data: [
  //     {
  //       lable: "labels.broadcast_title",
  //       field: { type: "text", required: true },
  //       name: "broadcastTitle",
  //     },
  //     {
  //       lable: "labels.assigned_to",
  //       field: {
  //         type: "multiple",
  //         required: true,
  //         setlable: "name",
  //         option: [],
  //       },
  //       name: "assignedTO",
  //     },
  //     {
  //       lable: "labels.assigned_to",
  //       field: {
  //         type: "multiple",
  //         required: true,
  //         setlable: "name",
  //         option: [],
  //       },
  //       name: "assignedTO",
  //     },
  //     {
  //       lable: "labels.description",
  //       field: { type: "ck", required: true },
  //       name: "description",
  //     },
  //   ],
  //   buttonGroup: [
  //     {
  //       value: "labels.discard",
  //       variant: "outlined",
  //       class: "buttonBlank outlineBtn",
  //       callback: () => {
  //         handleCloseModel();
  //       },
  //     },
  //     {
  //       value: "labels.create",
  //       variant: "contained",
  //       class: "buttonFill FillBtn",
  //       callback: (val) => {
  //         addBroadcast(val);
  //       },
  //     },
  //   ],
  // })

  // const handleCloseModel = () => {
  //   setErrors({});
  //   setBroadcast({});
  //   setModelData({ ...modalData, model: false });
  // };


  // const handleModel = (val) => {
  //   if (val == "addBroadcast") {
  //     setModelData({
  //       ...modalData,
  //       model: true,
  //     });
  //   }
  // };

  // const addBroadcast = async (obj) => {
  //   // console.log(obj, "ppppppppppppppppp")
  //   let error = broadcastValidaction(obj);
  //   if (Object.keys(error).length === 0) {
  //     props.setLoader(true);
  //     const formData = new FormData();
  //     formData.append("taskTitle", obj.taskTitle);
  //     formData.append("taskType", obj.taskType);
  //     obj?.assignedTO?.map((op, index) => {
  //       formData.append(`assignedTo[${index}]`, op.id);
  //     });
  //     formData.append("priority", obj.priority);
  //     formData.append("dueDate", obj.dueDate);
  //     formData.append("requestBy", obj.requestBy);
  //     formData.append("sharedWith", obj.sharedWith);
  //     formData.append("description", obj.description);
  //     formData.append("related_project_id", obj.related_project_id);
  //     formData.append("langId", "1");
  //     const res = await postApi("/api/task/create", formData);
  //     if (res) {
  //       setBroadcast({});
  //       getApiData();
  //       handleCloseModel();
  //     }
  //     props.setLoader(false);
  //   } else {
  //     setErrors(error);
  //   }
  // };




  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="CommonPd latest_des_box">
      {/* <ComponentModal
      modalData={modalData}
      errors={errors}
      setPerantState={setTaskState}
      perantState={taskState}
      /> */}
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            padding: "2rem"
          }}
        >
          <Grid style={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Typography variant="h6" className="morningText">
              {t("titles.broadcasts")}

            </Typography>

            <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                // if (getUserHasAccess("broadcast", 207)) {
                history.push('/broadcasts/create');
                // } else {
                //   setAuthorityModal(true);
                // }

              }}
            />
          </Grid>

          <Box
            className="TaskTab TabActive"
            sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="iconText"
                label={t("labels.sent_requests")}
                {...a11yProps(0)}
              />
              <Tab
                className="iconText"
                label={t("labels.received_requests")}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <SentBroadcasts />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RecievedBroadcasts />
          </TabPanel>
        </Grid>
        {/* <ModalComponent /> */}
      </Grid>
    </div>
  )
}

export default Broadcasts