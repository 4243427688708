import React from "react";
import { Redirect, Route } from "react-router-dom";
import { hasAuthority } from "../../utils/common";
import HeaderBreadCrumbs from "./HeaderBreadCrumbs";

const PrivateRoute = ({
  component: Component,
  authorities = [],
  componentProps,
  routes,
  ...rest
}) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        hasAuthority(authorities) ? (
          <>
            <HeaderBreadCrumbs routes={routes} match={props.match} />
            <Component {...(componentProps || {})} {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/access_denied",
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
