import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import profile from "../../../../assets/images/pic.png";
import http from "../../../../api/axios";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Grid, Button, AvatarGroup, Avatar } from "@mui/material";
import GeneralInfo from "./generalInfo";
import Attachments from "./attachments";
import Matters from "./matters";
import Document from "./documents/document";
import Reminders from "./reminders";
import auth from "../../../../services/auth";
import moment from "moment";
import validate from "../../../../Hooks/client_validation";
import DocumentAction from "./documents/DocumentAction";
import LoadingContext from "../../../../context/LoaderContext";
import ComponentSearch from "../../../componentGroups/componentSearch";
import { Client_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/client_key_lable";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
function TabPanel(props) {
  const { children, value, index, setLoader, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClientInfo() {
  const { clientId } = useParams();
  clientId && auth.login("clientId", clientId);
  const Id = auth.getUser("clientId");
  const { setLoader } = useContext(LoadingContext);
  const [fileAddedToFolder, setFileAddedToFolder] = useState("");
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const [searchFilter, setSearchFilter] = useState("");
  const [dropDownOptions, setDropDownOptions] = useState({
    title: [],
    gender: [
      { id: 1, name: "labels.male", option: t("labels.male") },
      { id: 2, name: "labels.female", option: t("labels.female") },
      { id: 3, name: "labels.others", option: t("labels.others") },
    ],
    country: [],
    clientType: [],
    relationshipType: [],
    idTypes: [],
    category: [],
    subCategory: [],
    departments: [],
    branches: [],
    docRows: [],
    allBranches: [],
    allDepartments: [],
  });

  const INITIAL_ADDRESSFIELDS = {
    addressName: "Address 1",
    isPrimary: false,
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  };
  const [errors, setErrors] = useState({});
  const [clientState, setClientState] = useState({
    titleId: "",
    name: "",
    phoneNo: "",
    email: "",
    clientType: "",
    relationshipType: "",
    branchId: "",
    categoryId: "",
    subCategoryId: "",
    contractNumber: "",
    contractDate: "",
    tax: "",
    additionalId: "",
    additionalId2: "",
    companyGroup: "",
    refrence: "",
    isActive: false,
    country: "",
    procuration: "",
    //jobdetails
    jobTitle: "",
    jobPhone: "",
    jobEmail: "",
    //Address information
    addresses: [INITIAL_ADDRESSFIELDS],
    documents: [
      {
        category: "contract",
        name: "",
        type: "",
        path: "",
        file: "",
      },
      {
        category: "identity",
        name: "",
        type: "",
        path: "",
      },
    ],
  });
  const [flag, setFlag] = useState(false);
  /**
   *
   * @param {*} key  dropdown options key
   * @param {*} id   category id on dropdown field
   */
  const loadDropdownOptions = async (
    key,
    id,
    type = "post",
    endPoint = "/api/dropdown/show-options"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("id", id);
      res = await http.getAllDataApi(t, endPoint, formData);
      res = res?.data;
    }
    if (type === "get") {
      res = await http.getApi(t, `${endPoint}`);
    }
    const options = res?.map((op) => {
      return { ...op, name: op.name ?? op.option };
    });
    setDropDownOptions((prevVals) => ({
      ...prevVals,
      [key]: options,
    }));
  };

  /**
   * add custom options in dropdowns
   * @param {*} val
   * @param {*} key
   * @param {*} category_id
   * @param {*} type
   * @param {*} endPoint
   */
  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", i18n.language === "en" ? 1 : 2);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        loadDropdownOptions(key, category_id);
      }
    }
  };

  /**
   * client data and dropdown options are fetched
   */
  useEffect(() => {
    if (Id) {
      getApiData();
    }
    setLoader(true);
    axios
      .all([
        loadDropdownOptions("clientType", 3),
        loadDropdownOptions("relationshipType", 4),
        loadDropdownOptions("title", 7),
        loadDropdownOptions("category", 12),
        loadDropdownOptions("subCategory", 13),
        loadDropdownOptions("idTypes", 14),
        loadDropdownOptions(
          "docRows",
          "",
          "get",
          `/api/client/findallrows/${Id}?search=${searchFilter}`
        ),
        loadDropdownOptions("allBranches", "", "post", "/api/branch/details"),
        loadDropdownOptions("allDepartments", "", "get", "/api/department"),
      ])
      .finally(() => setLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * fetch client data and intialize intial client data
   */
  const getApiData = async () => {
    setLoader(true);
    let res = await http.getApi(t, `/api/client/find/${Id}`);
    setLoader(false);

    const clientData = {
      ...clientState,
      id: res?.id ?? "",
      titleId: res?.titleId ?? "",
      name: res?.name ?? "",
      code: res?.code ?? "",
      phoneNo: res?.phoneNo ?? "",
      email: res?.email ?? "",
      clientType: res?.clientType ?? "",
      relationshipType: res?.relationshipType ?? "",
      branchId: res?.branchId ?? "",
      categoryId: res?.categoryId ?? "",
      subCategoryId: res?.subCategoryId ?? "",
      contractNumber: res?.contractNumber ?? "",
      contractDate: res?.contractDate
        ? moment(res?.contractDate).format("YYYY-MM-DD")
        : "",
      tax: res?.tax ?? "",
      additionalId: res?.additionalId ?? "",
      additionalId2: res?.additionalId2 ?? "",
      companyGroup: res?.companyGroup ?? "",
      refrence: res?.refrence ?? "",
      isActive: res?.isActive === "0" ? false : true,
      country: "",
      //jobdetails
      jobTitle: res?.jobTitle ?? "",
      jobPhone: res?.jobPhone ?? "",
      jobEmail: res?.jobEmail ?? "",
      procuration: res?.procuration ?? "",
    };
    if (res?.clients_addresses?.length > 0) {
      clientData.addresses = res?.clients_addresses.map((item) => {
        return {
          ...item,
          isPrimary: JSON.parse(item.isPrimary),
          addressName: item.addressName ?? "",
          address1: item.address1 ?? "",
          address2: item.address2 ?? "",
          city: item.city ?? "",
          state: item.state ?? "",
          country: item.country ?? "",
          zip: item.zip ?? "",
        };
      });
    }
    if (res?.clients_attachements?.length > 0) {
      let doccuments = res?.clients_attachements?.map((item) => {
        return {
          ...item,
          file: "",
        };
      });

      const el = doccuments.map((el) => el.category);
      if (!el.includes("contract") && el.includes("identity")) {
        doccuments = [
          {
            category: "contract",
            name: "",
            type: "",
            path: "",
            file: "",
          },
          ...doccuments,
        ];
      } else if (el.includes("contract") && !el.includes("identity")) {
        doccuments = [
          ...doccuments,
          {
            category: "identity",
            name: "",
            type: "",
            path: "",
            file: "",
          },
        ];
      } else if (!el.includes("contract") && !el.includes("identity")) {
        doccuments = [
          ...doccuments,
          {
            category: "contract",
            name: "",
            type: "",
            path: "",
            file: "",
          },
          {
            category: "identity",
            name: "",
            type: "",
            path: "",
            file: "",
          },
        ];
      }
      clientData.documents = doccuments;
    }
    setClientState(clientData);
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.clientValidate(clientState);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientState]);

  /**
   * update client data
   */
  const updateClient = async () => {
    setFlag(false);
    let errors = validate.clientValidate(clientState, "edit");
    if (Object.keys(errors).length === 0) {
      setLoader(true);
      const formData = new FormData();
      const updatedData = { ...clientState };
      delete updatedData?.branchCode;
      delete updatedData?.documents;
      delete updatedData?.isActive;
      Object.keys(updatedData).forEach((element) => {
        if (Array.isArray(updatedData[element])) {
          updatedData[element].forEach((item, index) => {
            if (typeof item === "object" && item !== null) {
              Object.keys(item).forEach((ele) => {
                if (ele === "isPrimary") {
                  formData.append(
                    `${element}[${index}][${ele}]`,
                    !!updatedData[element][index][ele] ? 1 : 0
                  );
                } else {
                  formData.append(
                    `${element}[${index}][${ele}]`,
                    updatedData[element][index][ele] ?? ""
                  );
                }
              });
            } else {
              formData.append(
                `${element}[${index}]`,
                updatedData[element][index] ?? ""
              );
            }
          });
        } else {
          if (element === "contractDate") {
            formData.append(
              element,
              updatedData[element]
                ? moment(updatedData[element]).format("MM-DD-YYYY")
                : ""
            );
          } else {
            formData.append(element, updatedData[element]);
          }
        }
      });
      const doccuments = [...clientState.documents];
      doccuments.forEach((item) => {
        if (!!item?.file) {
          formData.append(`documents[${item?.category}]`, item.file);
        }
      });
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      formData.append("isActive", !!clientState.isActive ? 1 : 0);
      await http.putApi(t, `/api/client/update/${Id}`, formData);
      setFlag(true);
      setLoader(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="CommonPd">
      <div className=" latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <div className="profile">
                <AvatarGroup>
                  <Avatar
                    className="profileIMG"
                    alt="client"
                    src={profile}
                    sx={{ marginLeft: "-5px !important" }}
                  />
                  <div>
                    <Typography variant="h6" className="newText pro">
                      {clientState?.code}
                    </Typography>
                    <Typography variant="h6" className="newText">
                      {t("labels.client")}
                    </Typography>
                  </div>
                </AvatarGroup>
              </div>
            </Grid>
            {value == 3 && (
              <Grid item xs={3}>
                <div className="profile">
                  <ComponentSearch
                    value={searchFilter}
                    callback={(e) => setSearchFilter(e.target.value)}
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={3} style={{ display: "flex" }}>
              {parseInt(value) === 3 && (
                <DocumentAction
                  setLoader={setLoader}
                  clientId={Id}
                  dropDownOptions={dropDownOptions}
                  loadDropdownOptions={loadDropdownOptions}
                  setFileAddedToFolder={setFileAddedToFolder}
                />
              )}
              <Button
                variant="contained"
                sx={{ marginLeft: "50px" }}
                className="buttonFill"
                onClick={updateClient}
              >
                {t("labels.save")}
              </Button>
            </Grid>
          </Grid>

          <Box
            className="TaskTab TabActive"
            sx={{
              borderBottom: 1,
              borderColor: "rgba(183, 161, 96, 0.26)",
              pt: 6,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="iconText"
                label={t("labels.general_info")}
                {...a11yProps(0)}
              />
              <Tab
                className="iconText"
                label={t("labels.attachments")}
                {...a11yProps(1)}
              />
              <Tab
                className="iconText"
                label={t("labels.matters")}
                {...a11yProps(2)}
              />
              <Tab
                className="iconText"
                label={t("labels.documents")}
                {...a11yProps(3)}
              />
              <Tab
                className="iconText"
                label={t("labels.reminders")}
                {...a11yProps(4)}
              />
              {/* <Tab className="iconText" label="Expenses" {...a11yProps(5)} />
              <Tab className="iconText" label="Invoices" {...a11yProps(6)} />
              <Tab className="iconText" label="" {...a11yProps(7)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GeneralInfo
              initialAddressField={INITIAL_ADDRESSFIELDS}
              clientState={clientState}
              setClientState={setClientState}
              dropDownOptions={dropDownOptions}
              errors={errors}
              addDropdownOptions={addDropdownOptions}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Attachments
              clientState={clientState}
              setClientState={setClientState}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Matters
              setLoader={setLoader}
              clientId={Id}
              dropDownOptions={dropDownOptions}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Document
              setLoader={setLoader}
              clientId={Id}
              dropDownOptions={dropDownOptions}
              loadDropdownOptions={loadDropdownOptions}
              fileAddedToFolder={fileAddedToFolder}
              clientState={clientState}

            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Reminders
              setLoader={setLoader}
              clientId={Id}
              dropDownOptions={dropDownOptions}
              loadDropdownOptions={loadDropdownOptions}
            />
          </TabPanel>
          {clientState?.code && (
            <LogNoteAccordion
              id={clientState?.code}
              labelKeys={Client_KEY_LABELS}
              flag={flag}
            />
          )}
        </Grid>
      </div>
    </div>
  );
}
