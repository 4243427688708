import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import ComponentButton from "../../componentGroups/componentButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../api/axios";

export default function HorizontalLinearStepper() {
  const { t } = useTranslation();
  const history = useHistory();
  const [options, setOptions] = useState({});

  const getApiData = async () => {
    let res = await http.getApi(t, "/api/meeting/meeting-mins/validated/docs");
    let res2 = await http.getApi(
      t,
      "/api/meeting/meeting-mins/validation/pending/all"
    );
    setOptions({ ...options, validatedDecision: res, pendingDecision: res2 });
  };

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="voteValdate">
      <div className="CommonPd">
        <div className="dashTopGrid ">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("titles.pending_validation")}
              </Typography>
            </Grid>
          </Grid>
          {options?.pendingDecision?.map((op) => (
            <>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid className="gridLeft">
                  <Typography component="span" variant="body2">
                    {op?.title}
                  </Typography>
                </Grid>
                <Grid className="gridLeftbtn">
                  <ComponentButton
                    value={t("labels.view_details")}
                    variant={"outlined"}
                    class={"buttonBlank Mright"}
                    callback={() => {
                      history.push({
                        pathname: "/votingValidations/meeting-detail",
                        state: { id: op?.meeting_id },
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ width: "100%" }} className="here">
                <Stepper
                  activeStep={3}
                  className="votingStepper"
                  alternativeLabel
                >
                  {op?.MeetingValidations.length != 0 &&
                    op?.MeetingValidations?.map((label, index) => {
                      return (
                        <Step
                          completed={
                            label.validation_status === 1 ? true : false
                          }
                        >
                          <StepLabel>
                            {`${label.guest_name}
                              ${
                                label.validation_status === 1
                                  ? t("labels.verified")
                                  : t("labels.verification_pending")
                              }`}
                          </StepLabel>
                        </Step>
                      );
                    })}
                </Stepper>
              </Box>
              <hr />
            </>
          ))}
        </div>
      </div>

      <div className="CommonPd">
        <div className="dashTopGrid ">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {t("titles.validated_documents")}
              </Typography>
            </Grid>
          </Grid>
          {options?.validatedDecision?.map((op, index) => (
            <>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid className="gridLeft">
                  <Typography component="span" variant="body2">
                    {op?.title}
                  </Typography>
                </Grid>
                <Grid className="gridLeftbtn">
                  <ComponentButton
                    value={t("labels.view_details")}
                    variant={"outlined"}
                    class={"buttonBlank Mright"}
                    callback={() => {
                      history.push({
                        pathname: "/votingValidations/meeting-detail",
                        state: { id: op?.meeting_id },
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ width: "100%" }}>
                <Stepper
                  activeStep={3}
                  alternativeLabel
                  className="votingStepper"
                >
                  {op?.MeetingValidations.length != 0 &&
                    op?.MeetingValidations?.map((label, index) => {
                      return (
                        <Step
                          completed={
                            label.validation_status === 1 ? true : false
                          }
                        >
                          <StepLabel>{label.guest_name}</StepLabel>
                        </Step>
                      );
                    })}
                </Stepper>
              </Box>

              <hr />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
