import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ComponentButton from "../../../componentGroups/componentButton";

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormInput from "./FormInput";
import { useFormik } from "formik";
import * as YUP from "yup";
import { LargeText } from "../../Technical Department";
import { deleteApi, getApi, patchApi, postApi } from "../../../../api/axios";
import ComponentUpload from "../../../componentGroups/componentUpload";
import FileView from "../../PartnerScreens/Documents/FileView";
import ComponentSelect from "../../../componentGroups/componentSelect";
const SuggestionForm = () => {
  const { t } = useTranslation();
  const history = useHistory();


  const category = JSON.parse(localStorage.getItem("category"));
  const user = JSON.parse(localStorage.getItem("user"));

  // const location = useLocation();
  // const [file, setFile] = useState('')
  const [suggestionState, setSuggestionState] = useState();
  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   getApi(t, `/api/reference-categories`).then((res) => setCategories(res));
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("suggestion"))
      setSuggestionState(JSON.parse(localStorage.getItem("suggestion")));
    return () => {
      localStorage.removeItem("suggestion");
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      title: suggestionState?.title ?? "",
      description: suggestionState?.description ?? "",
      reference: suggestionState?.reference?.referenceCode ?? "",
      employee: suggestionState?.employee?.name ?? user?.name,
      department: user?.departmentName ?? '',
      jobId: user?.jobTitle,
      file: suggestionState?.file ?? '',
    },
    // validateOnMount: true,
    enableReinitialize: true,

    validationSchema: YUP.object({
      title: YUP.string()
        .required("title is required.")
        .min(2, "must be at least 2 characters"),
      description: YUP.string()
        .required("description is required.")
        .min(10, "must be at least 10 characters"),
      reference: YUP.number().required("reference is required."),
    }),
    onSubmit: async (values) => {
      // if (suggestionState) {
      //   // edit request
      //   patchApi(t,`/api/references/one/${suggestionState.id}`, fd).then((res) => { if (res) history.push(`/prophetic-tradition-references`) })
      // }
      if (!suggestionState) {
        // add request
        let fd = new FormData();
        fd.append("title", values.title);
        fd.append("description", values.description);
        fd.append("empId", user?.id);
        fd.append("categoryId", category?.id);
        fd.append("referenceId", values?.reference);
        fd.append("file", values.file);

        const res = await postApi(t, `/api/reference-suggestions/create`, fd);

        if (res.status) { history.push(`/reference-categories/references`); }
      }
    },
  });

  const reject = async () => {
    deleteApi(t, `/api/reference-suggestions/${suggestionState?.id}`, null,
      { categoryId: category?.id }
    ).then(res => history.push(`/reference-categories/references`));
  }
  const approve = async () => {
    const res = await patchApi(t, `/api/reference-suggestions/${suggestionState?.id}`, { categoryId: category?.id })
    if (res.status) { history.push(`/reference-categories/references`); }
  }

  // console.log(formik.values);
  // console.log(formik.values.file?.name);
  // console.log(suggestionState);
  return (
    <div className="SuggestDetailPg">
      <div class="CommonPd">
        <div class="dashTopGrid">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography
                className="morningText"
                variant="h4"
                sx={{
                  padding: "1rem",
                }}
              >
                {t("labels.suggestion_form")}
              </Typography>
            </Grid>
          </Grid>
          <form
            style={{
              paddingInline: "30px",
            }}
          >
            <FormInput
              name="title"
              label={t("labels.title")}
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : null
              }
              disabled={suggestionState?.title ? true : false}
            />

            <FormInput
              value={category.name}
              name="categoryId"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.category")}

              required={true}
              disabled={true}
            />

            {!suggestionState?.reference ? <>

              <div className="FormsuggestForm">
                <label for="reference">
                  {t("labels.reference")}
                </label>
                <div style={{
                  flex: 1
                }}>
                  <ComponentSelect
                    className="GenDropdown"
                    option={category?.references?.map(e => {
                      return { ...e, name: `${e?.title} - ${e?.referenceCode}` }
                    })}
                    name="reference"
                    value={formik.values.reference}
                    callback={(e) => {
                      formik.setFieldValue('reference', e.target.value)
                    }}
                  />
                </div>
              </div>

              {(formik.errors.reference && formik.touched.reference) ? (
                <p

                  style={{
                    color: "#ff0101",
                    fontSize: "13px",
                    marginBottom: "-5px",
                    marginInlineStart: "16%"
                  }}
                >
                  *{formik.errors.reference}
                </p>
              ) : ''}
            </> : ''}


            {suggestionState?.reference ? <FormInput
              value={`${suggestionState?.reference?.title} - ${suggestionState?.reference?.referenceCode}`}
              name="reference"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.reference")}

              required={true}
              disabled={true}
            /> : ''}

            <FormInput
              value={formik.values.employee}
              name="employee"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.employee_name")}

              required={true}
              disabled={true}
            />
            <FormInput
              value={formik.values.department}
              name="departmentId"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.department")}

              required={true}
              disabled={true}
            />
            <FormInput
              value={formik.values.jobId}
              name="jobId"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label={t("labels.job_title")}

              required={true}
              disabled={true}
            />

            <div className="FormsuggestForm">
              <label for="description">{t("labels.description")}</label>

              <LargeText
                desc={formik.values.description}
                inputName="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.errors.description && formik.touched.description
                    ? formik.errors.description
                    : null
                }
                required={true}
                disabled={suggestionState?.description ? true : false}
              />
            </div>

            {!suggestionState ? <div className="FormsuggestForm">
              <label for="documents">{t("labels.documents")}</label>
              <div className="">

                <ComponentUpload
                  lable={t("labels.upload_files")}
                  name="documents"
                  callback={(e) => {
                    formik.setFieldValue('file', e.target.files[0])
                    // s1.documents.push(e.target.files[0])
                  }}
                />
              </div>
            </div> : ''}

            {suggestionState?.file ? <FileView index={1} item={suggestionState?.file} /> : ""}

          </form>

          <div className="RefresugstBtn" style={{
            gap: '1rem',
            display: "flex"
          }}>
            <ComponentButton
              sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
              value={t("labels.go_back")}
              variant={"outlined"}
              class={"buttonBlank delbtnRefrence"}
              callback={() => history.push(`/reference-categories/references`)}
            />
            {suggestionState ? (
              <>
                <ComponentButton
                  sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                  value={t("labels.reject")}
                  variant={"outlined"}
                  class={"buttonBlank delbtnRefrence"}
                  callback={reject}
                />
                <ComponentButton
                  sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                  value={t("labels.approve")}
                  variant={"contained"}
                  class={"buttonFill"}
                  callback={approve}
                />
              </>
            ) : ''}
            {!suggestionState && (
              <ComponentButton
                sx={{ color: "#fff", fontSize: 34, fontWeight: "medium" }}
                value={t("labels.add_to_library")}
                variant={"contained"}
                class={"buttonFill"}
                callback={formik.submitForm}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionForm;
