export const TASK_KEY_LABELS = {
  taskTitle: "labels.task_title",
  taskType: "labels.task_type",
  assignTo: "labels.assigned_to",
  notifyType: `labels.days_by`,
  priority: "labels.priority",
  dueDate: "labels.due_date",
  requestBy: "labels.request_by",
  relatedProject: "labels.related_project",
  sharedWith: "labels.shared_with",
  notifyMeBefore: "labels.notify_me_before",
  matters: "labels.related_matters",
  clients_attachements: "labels.related_contracts",
  description: "labels.description",
  updatedBy: "titles.updated_by",
};
