import React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { baseURL } from "../../../../../api/axios";
import { useTranslation } from "react-i18next";

const DetailContainer = ({ detailItem, meetingData }) => {
  const { t } = useTranslation();
  return (
    <>
      <span style={{ fontSize: "16px", fontWeight: "600" }}>
        {" "}
        Meeting Decisions{" "}
      </span>{" "}
      <ul>
        {detailItem?.decisionData?.map((op, index) => (
          <li key={op.id || index}>
            <div className="Meeting_decisionRoot_container">
              <span for="decision" className="Meeting_decisionRoot_label">
                {t("labels.decision")} {index + 1}
              </span>
            </div>

            <div className="Meeting_decisionRoot_Title">
              <span>{op.title}</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {op?.meeting_decision_rejections?.length > 0 && (
                    <div className="avatarcontainer">
                      Rejected By :
                      <AvatarGroup max={3} className="avatargroup">
                        {op?.meeting_decision_rejections?.map((item) => {
                          return (
                            <Avatar
                              key={item?.id}
                              alt={item?.name}
                              src={`${baseURL}/${item?.imagePath}`}
                              className="avatar"
                            />
                          );
                        })}
                      </AvatarGroup>
                      <span>
                        {op?.meeting_decision_rejections[0]?.name}{" "}
                        {op?.meeting_decision_rejections?.length > 1 &&
                          t("labels.and")}
                        {op?.meeting_decision_rejections?.length > 1 && (
                          <>
                            <small>
                              {t("labels.more_people", {
                                count:
                                  op?.meeting_decision_rejections?.length - 1,
                              })}
                            </small>
                          </>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div style={{ margin: "10px 0" }}>
        <span style={{ fontSize: "16px", fontWeight: "600" }}>
          {" "}
          Meeting Mins
        </span>{" "}
        <div dangerouslySetInnerHTML={{ __html: meetingData?.meeting_mins }} />
      </div>
    </>
  );
};

export default DetailContainer;
