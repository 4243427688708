import React from 'react'

const colors = ['#B7A160', '#2B7D6A', '#4E4E4E', '#2B564C', '#4B91F7', '#9383F2'];


const AvailableLeaveBalanceCard = ({ days, type }) => {
  const id = type?.id;
  // const badgeColor = colors[Math.floor(Math.random() * 6)]
  const badgeColor = id === 1 ? '#B7A160' : id === 2 ? '#2B7D6A' : id === 3 ? '#4E4E4E' : id === 4 ? '#2B564C' : id === 5 ? '#4B91F7' : id === 6 ? '#9383F2' : '#B7A160';

  return (
    <div style={{
      textTransform: "capitalize",
      backgroundColor: badgeColor,
      paddingInline: "20px",
      paddingBlock: "10px",
      borderRadius: "38px",
      color: "white",
      gap: "1rem",
      display: "flex"
    }}>
      <span style={{ fontWeight: "bold", }}>{`${days} days`}</span>
      <span>{`available ${type?.name}`}</span>
    </div>
  )
}

export default AvailableLeaveBalanceCard