import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GenericModal from "../../../componentGroups/genericModal";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentTextString from "../../../componentGroups/componentTextString";
import http from "../../../../api/axios";

const AddEditCategory = ({
  open,
  onClose,
  operationId,
  categoryId,
  subCategoryId,
  subCategoryValue,
  categoryName,
}) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [name, setName] = useState(subCategoryValue);
  const operation = {
    1: { label: "Add New Category", api: "/api/dropdown/category" },
    2: { label: `Edit ${categoryName}`, api: "/api/dropdown/category" },
    3: {
      label: `Add New ${categoryName}`,
      api: "/api/dropdown/create-category",
    },
    4: {
      label: `Edit ${categoryName}`,
      api: "/api/dropdown/sub-category",
    },
    5: {
      label: "Delete Alert",
      api: "/api/dropdown/sub-category",
    },
  };

  useEffect(() => {
    subCategoryValue && setName(subCategoryValue);
  }, [subCategoryValue]);

  useEffect(() => {
    if (!!name && !!name.trim()) {
      setError("");
    }
  }, [name]);

  const handleSave = async () => {
    if (!!name && !!name.trim()) {
      const formData = new FormData();
      let api = operation[operationId].api;
      switch (operationId) {
        case 1:
          formData.append("name", name);
          formData.append("langId", i18n.language === "en" ? 1 : 2);
          break;
        case 2:
          formData.append("name", name);
          api = `${api}/${categoryId}`;
          break;
        case 3:
          formData.append("category_id", categoryId);
          formData.append("option", name);
          formData.append("langId", i18n.language === "en" ? 1 : 2);
          break;
        case 4:
          formData.append("value", name);
          formData.append("category_id", categoryId);
          api = `${api}/${subCategoryId}`;
          break;
        case 5:
          api = `${api}/${subCategoryId}`;
          break;
        default:
          break;
      }

      if (operationId === 5) {
        await http.deleteApi(t, api);
      } else {
        await http.postApi(t, api, formData);
      }
      handleClose();
    } else {
      setError("errors.enter_valid_value");
    }
  };

  const handleClose = () => {
    setName("");
    setError("");
    onClose();
  };

  return (
    <GenericModal title={operation[operationId]?.label} open={open}>
      <div className="scroll-auto ModalContent" style={{ minHeight: "80px" }}>
        <Grid container md={12} className="modalGroup">
          {operationId !== 5 && (
            <>
              <Grid item xs={12} md={3}>
                <Typography variant="h1" className="lable">
                  {t("labels.name")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <div>
                  <ComponentTextString
                    class={"custonInput"}
                    placeholder=""
                    name="name"
                    callback={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {error && <span className="validate">{t(error)}</span>}
                </div>
              </Grid>
            </>
          )}

          {operationId === 5 && (
            <Grid item sm={12}>
              <Typography textAlign={"center"}>
                {t("titles.sure_delete_data_from")}
                {categoryName} {t("titles.database")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.discard")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn"}
              callback={() => handleClose()}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t(operationId === 5 ? "labels.delete" : "labels.save")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => handleSave()}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default AddEditCategory;
