import React, { useEffect, useState, useContext } from "react";
import {
  Stack,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import "./style.css";
import { useTranslation } from "react-i18next";
import {
  getUserHasAccess,
  defaultModulevalues,
} from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

export default function Authorities({ depData, authorityName, setdepAuth }) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [value, setValue] = useState([]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  }

  const hasValue = (id) => {
    if (value?.map((item) => item.auth_id).includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * checks value is added or removed base on default values of that group
   * @param {*} newVal
   */
  const handleInputChange = (_e, newVal) => {
    let updatedValues = [...newVal];
    let selectedItem = [];
    if (newVal?.length > value?.length) {
      selectedItem = newVal?.filter((o) => value?.indexOf(o) === -1)[0];
    }
    if (newVal?.length < value?.length) {
      selectedItem = value.filter((o) => newVal.indexOf(o) === -1)[0];
    }
    if (
      defaultModulevalues[selectedItem?.group] === selectedItem?.auth_id &&
      newVal?.length < value?.length
    ) {
      updatedValues = newVal?.filter(
        (item) => item?.group !== selectedItem?.group
      );
    }
    const selctedOptionIds = updatedValues?.map((ele) => ele.auth_id);
    setdepAuth(selctedOptionIds);
    setValue(getUniqueListBy(updatedValues, "id"));
  };

  /**
   * checks previosu auth values to render in the component
   */
  useEffect(() => {
    if (
      !!depData?.department_has_authorities &&
      depData?.department_has_authorities?.length > 0 &&
      authorityName?.length > 0
    ) {
      let depAuth = [];
      depData?.department_has_authorities?.forEach((item) => {
        let auth = authorityName?.find(
          (el) => parseInt(el?.auth_id) === parseInt(item)
        );
        if (!!auth) {
          depAuth = [...depAuth, auth];
        }
      });
      const selctedOptionIds = depAuth?.map((ele) => ele?.auth_id);
      setValue(depAuth);
      setdepAuth(selctedOptionIds);
    } else {
      setValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depData?.department_has_authorities]);

  return (
    <>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="p" className="">
            {t("titles.authorities")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth morningTextDate" item xs={11} md={9}>
          <Stack
            spacing={3}
            sx={{ width: "auto" }}
            className="authoritiesGroup multiselect"
          >
            <Autocomplete
              limitTags={10}
              multiple
              getOptionDisabled={(option) =>
                option.group === "other"
                  ? false
                  : defaultModulevalues[option.group] === option.auth_id
                  ? false
                  : hasValue(defaultModulevalues[option.group])
                  ? false
                  : true
              }
              disableCloseOnSelect
              id="tags-standard"
              options={authorityName}
              value={value}
              getOptionLabel={(option) => option?.name}
              onChange={(e, value) => {
                if (getUserHasAccess("department", 56)) {
                  handleInputChange(e, value);
                } else {
                  setAuthorityModal(true);
                }
              }}
              groupBy={(option) => option.group}
              renderGroup={(params) => (
                <>
                  <div
                    style={{
                      paddingLeft: "20px",
                      background: "#6666661c",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {params.group.charAt(0).toUpperCase() +
                      params.group.slice(1)}
                  </div>
                  {params.children}
                </>
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={hasValue(option.auth_id)}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={t("placeholder.type_atleast_1_letter")}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
