import React, { useEffect, useState, useContext } from "react";
import {
  Stack,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import {
  getUserHasAccess,
  defaultModulevalues,
} from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";

const Responsibilities = ({ empState, setEmpState, dropDownOptions }) => {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [value, setValue] = useState([]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  }

  const hasValue = (id) => {
    if (value?.map((item) => item.auth_id).includes(id)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * checks value is added or removed base on default values of that group
   * @param {*} newVal
   */
  const handleChange = (_e, newVal) => {
    let updatedValues = [...newVal];
    let selectedItem = [];
    if (newVal?.length > value?.length) {
      selectedItem = newVal?.filter((o) => value?.indexOf(o) === -1)[0];
    }
    if (newVal?.length < value?.length) {
      selectedItem = value.filter((o) => newVal.indexOf(o) === -1)[0];
    }
    if (
      defaultModulevalues[selectedItem?.group] === selectedItem?.auth_id &&
      newVal?.length < value?.length
    ) {
      updatedValues = newVal?.filter(
        (item) => item?.group !== selectedItem?.group
      );
    }
    const selctedOptionIds = updatedValues?.map((ele) => ele?.auth_id);
    setEmpState({ ...empState, responsibilities: selctedOptionIds });
    setValue(getUniqueListBy(updatedValues, "id"));
  };

  /**
   * default authorities of department is added
   */
  const setDefault = () => {
    if (getUserHasAccess("employee", 79)) {
      let defaultAuth = [];
      dropDownOptions?.responsibility?.forEach((item) => {
        let auth = dropDownOptions?.allResponsibility?.find(
          (el) => parseInt(el?.auth_id) === parseInt(item?.id)
        );
        if (!!auth) {
          defaultAuth = [...defaultAuth, auth];
        }
      });
      const selctedOptionIds = defaultAuth?.map((ele) => ele?.auth_id);
      setValue(defaultAuth);
      setEmpState({ ...empState, responsibilities: selctedOptionIds });
    } else {
      setAuthorityModal(true);
    }
  };

  /**
   * checks previosu auth values to render in the component
   */
  useEffect(() => {
    if (
      !!empState?.responsibilities &&
      empState?.responsibilities?.length > 0 &&
      dropDownOptions?.allResponsibility?.length > 0
    ) {
      let empAuths = [];
      empState?.responsibilities?.forEach((item) => {
        let auth = dropDownOptions?.allResponsibility?.find(
          (el) => parseInt(el?.auth_id) === parseInt(item)
        );
        if (!!auth) {
          empAuths = [...empAuths, auth];
        }
      });
      setValue(empAuths);
    } else {
      setValue([]);
    }
  }, []);

  return (
    <>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="">
            {t("labels.responsibilities")}
          </Typography>
        </Grid>
        <Grid
          className=" empField morningTextDate CustomWidth"
          item
          xs={11}
          md={9}
        >
          <div className="Responsibiltystack">
            <Stack
              spacing={3}
              sx={{ width: "auto" }}
              className="authoritiesGroup"
            >
              <Autocomplete
                multiple
                limitTags={10}
                getOptionDisabled={(option) =>
                  option.group === "other"
                    ? false
                    : defaultModulevalues[option.group] === option.auth_id
                    ? false
                    : hasValue(defaultModulevalues[option.group])
                    ? false
                    : true
                }
                id="tags-standard"
                disableCloseOnSelect
                options={dropDownOptions.allResponsibility}
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(e, value) => {
                  if (getUserHasAccess("employee", 79)) {
                    handleChange(e, value);
                  } else {
                    setAuthorityModal(true);
                  }
                }}
                groupBy={(option) => option.group}
                renderGroup={(params) => (
                  <>
                    <div
                      style={{
                        paddingLeft: "20px",
                        background: "#6666661c",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {params.group.charAt(0).toUpperCase() +
                        params.group.slice(1)}
                    </div>
                    {params.children}
                  </>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={hasValue(option.auth_id)}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    // label="Multiple values"
                    placeholder={t("placeholder.type_atleast_1_letter")}
                  />
                )}
              />
            </Stack>
          </div>
        </Grid>
      </Grid>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}></Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <Grid className="AddaddressBTN">
            <Button
              variant="contained"
              startIcon={
                <SettingsIcon
                  sx={{ color: "#2B564C", paddingRight: "-10px" }}
                />
              }
              className="outlineBtn newBtn setDft"
              onClick={setDefault}
            >
              {t("labels.set_default")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Responsibilities;
