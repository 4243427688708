export const PROJECT_KEY_LABELS = {
  caseNo: "labels.related_case",
  createdBy: "labels.created_by",
  projectName: "labels.project_name",
  priority: "labels.priority",
  createdAt: "labels.created_on",
  description: "labels.description",
  sharedWith: "labels.shared_with",
  assign_to_users: "labels.assign_to",
  updatedBy: "titles.updated_by",
};
