/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ComponentTable from "../../componentGroups/componentTable";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import http from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import LoadingContext from "../../../context/LoaderContext";
import LoanRequest from "./LoanRequest";

export default function Loan() {
  const { t } = useTranslation();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);

  const [column, setColumn] = useState([
    {
      lable: "Name",
      name: "name",
      align: "left",
      hide: true,
    },
    {
      lable: "Amount",
      name: "amount",
      align: "left",
      hide: true,
    },
    {
      lable: "Period",
      name: "period",
      hide: true,
    },
    {
      lable: "Installment",
      name: "installment",
      align: "left",
      hide: true,
    },
    {
      lable: "labels.status",
      name: "status",
      width: "10px",
      align: "left",
    },
  ]);

  const [data, setData] = useState({
    title: "Loans",
    page: "Loans",
    hideSearch: true,
    infoURL: "/loan/loam-details",
    hasVariable: true,
    buttonGroup: [
      {
        type: "add",
        title: "titles.add_new",
        icon: <AddIcon sx={{ paddingRight: "4px", color: "white" }} />,
        class: "buttonFill",
        variant: "contained",
        callback: () => {
          if (getUserHasAccess("loan", 196)) {
            history.push("/loan/add-loan");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    const hasAllAcsess = getUserHasAccess("loan", 197);
    const api = hasAllAcsess
      ? "/api/employee/all-loan-requests"
      : `/api/employee/user-loan-requests/${user?.id}`;

    const res = await http.getAllDataApi(
      t,
      `${api}?page=${pageIndex + 1}&size=${pageSize}`,
      { type: [0, 1, 2] }
    );
    setTotalrecords(res?.data_count);
    const loans = res?.data?.map((item) => {
      return {
        ...item,
        status:
          parseInt(item?.status) === 0
            ? "Pending Approval"
            : parseInt(item?.status) === 1
            ? "Approved"
            : "Rejected",
      };
    });
    setRecords(loans || []);
    setLoader(false);
  };

  useEffect(() => {
    getApiData(page, size);
  }, [page, size]);

  return (
    <>
      <LoanRequest />
      <div className="CommonPd latest_des_box">
        <ComponentTable
          rows={records}
          columns={column}
          pageData={data}
          page={page}
          setPage={setPage}
          rowsPerPage={size}
          setRowsPerPage={setSize}
          totalRecords={totalRecords}
          defaultstatus={false}
        />
      </div>
    </>
  );
}
