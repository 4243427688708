import React, { useState, useEffect, useContext } from "react";
import { Typography, Box, Grid } from "@mui/material";
import Svg from "../../../../../assets/svg/svg";
import { useHistory, useLocation } from "react-router-dom";
import ComponentButton from "../../../../componentGroups/componentButton";
import ComponentModal from "../../../../componentGroups/componectModel";
import { useTranslation } from "react-i18next";
import http from "../../../../../api/axios";
import "../../style.css";
import moment from "moment";
import Breadcrumbs from "../../../../comman/Breadcrumb";
import BreadCrumbContext from "../../../../../context/BreadCrumbContext";
export default function StudiesForms(props) {
  const { setPathValue } = useContext(BreadCrumbContext);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [modalData, setmodalData] = useState([]);
  let { id, form_category } = props.match.params;

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/form_doc/formsListing/findAll/${id}`);
    setdata(res);
    props.setLoader(false);
  };
  useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  const handleCloseModel = () => {
    setmodalData({
      ...modalData,
      model: false,
    });
  };
  const handleModal = () => {
    setmodalData({
      model: true,
      title: "titles.comment_docc",
      data: [{ lable: "titles.comment", field: { type: "text" } }],
      buttonGroup: [
        {
          value: "labels.discard",
          variant: "outlined",
          class: "buttonBlank outlineBtn",
          callback: () => {
            handleCloseModel();
          },
        },
        {
          value: "labels.save",
          variant: "contained",
          class: "buttonFill FillBtn",
          callback: () => {
            handleCloseModel();
          },
        },
      ],
    });
  };

  const BreadcrumbsPathnames = [
    {
      name: t("labels.forms_and_docs"),
      path: "/formDocuments",
    },
    {
      name: t("labels.forms"),
      path: "/forms",
    },
    {
      name: form_category,
    },
  ];

  useEffect(() => {
    setPathValue([
      {
        name: form_category,
        path: pathname,
      },
    ]);
  }, []);

  return (
    <div className="CommonPd">
      <Breadcrumbs pathnames={BreadcrumbsPathnames} />
      <ComponentModal modalData={modalData} />
      <div className="latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <Typography variant="h6" className="morningText">
              {form_category}
            </Typography>

            <ComponentButton
              value={t("labels.create_document")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {
                history.push(`/createDocument/${id}`);
              }}
            />
          </Grid>
          {data?.map((op, index) => (
            <Box sx={{ mt: 2, p: 2 }} className="box" key={index}>
              <Grid
                sx={{ display: "flex", justifyContent: "right" }}
                className="perviewBox"
                container
                spacing={1}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <div className="detailsBox">
                    <div>
                      <Svg type="pdf" />
                    </div>
                    <div>
                      <Typography variant="body2" className="title iconText">
                        {" "}
                        {op.title} ({op.fields_count})
                      </Typography>
                      <Typography variant="body2" className="details">
                        {" "}
                        {moment(op.updatedAt).format("MMMM DD , YYYY")} at{" "}
                        {moment(op.updatedAt).format("hh A")}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6} md={6} className="buttonGroup1">
                  <ComponentButton
                    value={t("labels.preview")}
                    variant={"outlined"}
                    class={"buttonBlank"}
                    sx={{ paddingRight: "0px !important" }}
                    callback={() => {
                      history.push(`/form_preview/document/${op.id}`);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </div>
    </div>
  );
}
