import React, { useContext, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
// import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentCard from './CommentCard';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ComponentButton from './componentButton';
import { styled } from '@mui/material';
import { getAllDataApi, getApi, postApi } from '../../api/axios';

import TextInputWithMention from './TextInputWithMention/TextInputWithMention';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../context/LoaderContext';
import InfiniteScroll from 'react-infinite-scroll-component';

// import './Comments.css'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',

  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Comments(props) {
  const branchId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).branchId : '';
  const [showComments, setShowComments] = useState(false);
  const { t } = useTranslation()
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState();
  const [commentsToScroll, setCommentsToScroll] = useState()

  const [mentionUsers, setMentionUsers] = useState([])

  // these users that will be sent to the backend
  const [mentionedUsers, setMentionedUsers] = useState([])

  const [commentPushed, setCommentPushed] = useState(false)
  const { setLoader } = useContext(LoadingContext);
  const [hasMore, setHasMore] = useState(true)

  const scrollableTargetRef = useRef()

  const getApiData = async () => {

    let res = await getApi(t, `/api/comments`, false, { viewId: props.viewId });
    let mentionsRes = await getAllDataApi(t, `/api/branch/employees/all/${branchId}`);;

    if (!!res) {
      setComments(res)
      setCommentsToScroll(res?.slice(0, 7))
    }
    if (!!mentionsRes) {
      setMentionUsers(mentionsRes?.data)
    }
    if (res?.length === 0) {
      setHasMore(false);
    }

    setLoader(false);
  };


  const handleToggleComments = () => {
    setShowComments(!showComments);
  };

  const handleAddComment = async () => {

    setLoader(true);
    await postApi(t, `/api/comments`, { text: commentText, viewId: props.viewId, mentionedUsers })

    getApiData();
    setCommentText('');
    setCommentPushed(true)
  };
  // console.log(commentText);

  useEffect(() => {
    setLoader(true);
    getApiData();
  }, [])


  // infinite scroll
  const fetchMoreData = () => {
    if (commentsToScroll?.length >= comments?.length) {
      setHasMore(false)
      return;
    }

    // a fake async api call like which sends
    // 7 more records in .5 secs
    setTimeout(() => {
      setCommentsToScroll(
        prev => (
          prev.concat(comments?.slice(commentsToScroll?.length, commentsToScroll?.length + 6))
        )
      )
    }, 1);
  }


  console.log(commentsToScroll);
  return (
    <Box style={{
      // border: "0.5px solid rgba(43, 86, 76, 0.6",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)", borderRadius: ".6rem", padding: "1.2rem",
    }} sx={{
      marginBlock: "2rem",
      backgroundColor: "rgba(239, 239, 239, 0.35) !important",
      // marginBottom: "2rem"
    }} ref={scrollableTargetRef}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, justifyContent: "space-between" }}>
        <Typography variant="h5" component="h2" sx={{ marginRight: 1 }}>
          {t('labels.comments')}
        </Typography>
        {/* <IconButton onClick={handleToggleComments} sx={{ transition: 'transform 0.3s ease-out', color: "#2B564C", fontSize: "15px" }}>
          <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }} />
        </IconButton> */}
        <ExpandMore
          expand={showComments}
          onClick={handleToggleComments}
          aria-expanded={showComments}
          aria-label="show more"
        >
          <ExpandMoreIcon sx={{ fontSize: "2rem" }} />
        </ExpandMore>
      </Box>
      <Collapse in={showComments} >

        <InfiniteScroll
          dataLength={commentsToScroll?.length ? commentsToScroll?.length : 0}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          height={400}

          scrollableTarget={scrollableTargetRef}
        >

          {commentsToScroll && commentsToScroll?.map((comment, i) => (

            <CommentCard
              key={i}
              avatarUrl={'https://example.com/avatar3.jpg'}
              data={comment}
              getApiData={getApiData}
              setLoader={setLoader}

              // this is for reply component
              mentions={mentionUsers}
              setMentionedUsers={setMentionedUsers}
            />


          ))}
        </InfiniteScroll>

      </Collapse>
      <Box sx={{ display: 'flex', marginTop: 3, alignItems: "center", justifyContent: "space-between", gap: "2rem" }}>
        {/* <TextField
          variant="outlined"
          placeholder="Add a comment"
          value={commentText}
          multiline
          maxRows={3}
          inputProps={{ maxLength: 300 }}
          onChange={handleCommentTextChange}
          sx={{ flex: "1", marginInlineEnd: "2rem", }}
          style={{
            backgroundColor: "white",
            // height: "3rem"
          }}
          size="small"
        /> */}
        <TextInputWithMention
          mentions={mentionUsers}
          inputText={commentText}
          setInputText={setCommentText}

          setMentionedUsers={setMentionedUsers}
          commentPushed={commentPushed}
          setCommentPushed={setCommentPushed}
        />

        <ComponentButton
          sx={{ fontSize: 34, fontWeight: "medium" }}
          value={t("labels.add_comment")}
          variant={"contained"}
          class={"buttonFill"}
          callback={handleAddComment}
        />
        {/* <Button variant="contained" color="primary" onClick={handleAddComment}>
            Add Comment
          </Button> */}
      </Box>
    </Box >
  );
}

export default Comments;