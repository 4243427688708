const createMeeting = (data, moment) => {
  let error = {};
  if (data.title == undefined || data?.title?.trim() == "") {
    error.title = "errors.enter_title";
  }
  if (data.title !== undefined && data?.title?.length > 30) {
    error.title = "errors.max_length_30";
  }

  if (data.date == undefined || data.date == "") {
    error.date = "errors.enter_date";
  }
  if (data.location == undefined || data.location == "") {
    error.location = "errors.select_location";
  }

  if (data.location == "1") {
    if (data.url == undefined || data.url == "") {
      error.url = "errors.enter_url";
    }
    delete error["branch_id"];
  } else {
    if (data.branch_id == undefined || data.branch_id == "") {
      error.branch_id = "errors.select_branch";
    }
    delete error["url"];
  }
  if (data.guests == undefined || data.guests.length == 0) {
    error.guests = "errors.select_guest";
  }
  if (data.departments == undefined || data.departments.length == 0) {
    error.departments = "errors.select_deaprtments";
  }

  // if (data.documents.length == 0) {
  //   error.documents = "errors.select_doccument";
  // }
  // if (data.documents.length > 3) {
  //   error.documents = "errors.select_only_3_doccument";
  // }

  if (data.start_time == undefined || data.start_time == "") {
    error.start_time = "errors.select_start_time";
  }
  if (data.end_time == undefined || data.end_time == "") {
    error.end_time = "errors.select_end_time";
  }
  if (data.end_time !== "" && data.end_time === data.start_time) {
    error.end_time = "errors.end_time_cant_be_same_as_start_time";
  }
  if (data.end_time != "" && data.start_time != "") {
    const startDate = moment(data.start_time, "h:mm:ss a");
    const end_time = moment(data.end_time, "h:mm:ss a");
    if (end_time.isBefore(startDate)) {
      error.end_time = "errors.end_time_cant_be_less_than_start_time";
    }
  }
  if (
    data.reminder != "" &&
    data.start_time != "" &&
    data.reminder === data.start_time
  ) {
    error.reminder = "errors.reminder_time_cant_be_same_as_start_time";
  }

  if (data.reminder != "" && data.start_time != "") {
    const startDate = moment(data.start_time, "h:mm:ss a");
    const reminder = moment(data.reminder, "h:mm:ss a");
    if (startDate.isBefore(reminder)) {
      error.reminder = "errors.reminder_time_cant_be_greater_than_start_time";
    }
  }

  if (data.notification == undefined || data.notification == "") {
    error.notification = "errors.select_notification_type";
  }
  if (data.reminder == undefined || data.reminder == "") {
    error.reminder = "errors.select_reminder_time";
  }
  if (data.description == undefined || data.description == "") {
    error.description = "errors.enter_description";
  }

  return error;
};

const guestValdidate = (data) => {
  let error = [];
  if (data?.length != 0) {
    data.map((op) => {
      if (op.is_meeting_availability == 0) {
        error.push(`${op.name} `);
      }
    });
  }

  return error;
};

export default {
  createMeeting,
  guestValdidate,
};
