import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react'
import ComponentButton from '../../componentGroups/componentButton';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../../context/LoaderContext';
import VacancyCard from './VacancyCard';
import { getApi } from '../../../api/axios';

const Vacancies = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setLoader } = useContext(LoadingContext);
  const [Vacancies, setVacancies] = useState();
  const [closedVacancies, setClosedVacancies] = useState();

  const getData = async () => {
    const res = await getApi(t, `/api/vacancies`);
    const resClosed = await getApi(t, `/api/vacancies`, false, { status: 0 });

    if (res) setVacancies(res);
    if (resClosed) setClosedVacancies(resClosed);
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            padding: "1rem"
          }}
        >
          {/* open_vacancies */}
          <Grid style={{
            marginBottom: "2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Typography variant="h6" className="morningText">
              {t("labels.open_vacancies")}

            </Typography>

            <ComponentButton
              icon={<AddIcon sx={{ paddingRight: "4px", color: "white" }} />}
              value={t("labels.add_new")}
              variant={"contained"}
              class={"buttonFill"}
              callback={() => {

                history.push('/recruitments/create-recruitment');
              }}
            />
          </Grid>
          <Grid
            container spacing={5}
          // style={{
          //   marginBlock: "2px"
          // }}
          >
            {Vacancies?.length > 0 ? Vacancies.map((e, i) => <VacancyCard link='/recruitments/recruitment-info' data={e} key={i} />) : <span className="noDataFound">
              {t('labels.no_data')}
            </span>}

          </Grid>

          {/* closed_vacancies */}
          <Grid style={{
            marginBottom: "2rem",
            marginTop: "4rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Typography variant="h6" className="morningText">
              {t("labels.closed_vacancies")}

            </Typography>


          </Grid>
          <Grid
            container spacing={5}
          // style={{
          //   marginBlock: "2px"
          // }}
          >
            {closedVacancies?.length > 0 ? closedVacancies?.map((e, i) => <VacancyCard link='/recruitments/recruitment-info' data={e} key={i} />) : <span className="noDataFound">
              {t('labels.no_data')}
            </span>}

          </Grid>

        </Grid>
      </Grid>
    </div>

  )
}

export default Vacancies