import React, { useState, useEffect } from "react"
import Webcam from "react-webcam";
import auth from "../../services/auth";
import ComponentButton from "./componentButton";
const ComponentCaptureImage = ({ uploadFile }) => {
  const user = auth.getUser("user")
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log(imageSrc, "lllllllllllllliiiiiiiiiiiiiiiiiiiii");
    setImgSrc(imageSrc);
    var file = dataURLtoFile(imageSrc, `${user?.code}.jpg`);
    // console.log(file,"leoeoeoeoeoeo");
    uploadFile(file, 1)
  }, [webcamRef, setImgSrc]);

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: "image/jpeg" });
  }
  return (
    <div className="innerVideo">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
      />
      <ComponentButton callback={() => capture()} value={"Capture photo"} class={"buttonFill m5"} />
      {/* {imgSrc && (
        <img
          src={imgSrc}
        />
      )} */}
    </div>
  );
}

export default ComponentCaptureImage;