/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../constant";
import http, { baseURL } from "../../../api/axios";
import moment from "moment";
import { loadOptions } from "../../../api/utils/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";
import LoadingContext from "../../../context/LoaderContext";

export default function DepartmentReport(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [deptRerports, setDeptRerports] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({ filter: false });
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      maxWidth: 100,
    },
    {
      lable: "labels.department",
      name: "departmentName",
      width: "10px",
      align: "left",
      type: "dropDown",
    },
    {
      lable: "titles.department_code",
      name: "departmentCode",
      width: "10px",
      align: "left",
    },

    {
      lable: "labels.employees_number",
      hide: true,
      name: "usercount",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.branch",
      hide: true,
      name: "branchName",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
    {
      lable: "labels.performance",
      hide: true,
      name: "performance",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
  ]);
  const [data, setData] = useState({
    title: "titles.departments_report",
    page: "Department Report",
    handlExport: (page, size, filterData) =>
      handleExport(page, size, filterData),
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[departmentName]", filterData?.departmentName ?? "");
    formdata.append("filter[departmentCode]", filterData?.departmentCode ?? "");
    const res = await http.getAllDataApi(
      t,
      `/api/reports/export-departments`,
      formdata
    );
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setLoader(false);
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[departmentName]", filterData?.departmentName ?? "");
    formdata.append("filter[departmentCode]", filterData?.departmentCode ?? "");
    const res = await http.getAllDataApi(
      t,
      `/api/reports/department-reports/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setFilterData({ ...filterData, filter: false });
    setDeptRerports(res.data);
    setLoader(false);
  };

  const setColumnData = async () => {
    let s1 = [...column];
    let departments = await loadOptions(
      t,
      1,
      undefined,
      "get",
      "/api/department"
    );
    const department = departments.map((item) => {
      return {
        ...item,
        name: item?.departmentName,
      };
    });

    s1[1].options = department;
    setColumn(s1);
  };

  useEffect(() => {
    setColumnData();
    getApiData(page, size);
  }, [page, size, filterData?.filter == true]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("employee", 73)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`employee[${index}]`, item);
          });
        }
        await http.postApi(t, "", formData, "");
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <ComponentTable
      rows={deptRerports}
      columns={column}
      pageData={data}
      filterData={filterData}
      setFilterData={setFilterData}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      search={search}
      setSearch={setSearch}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{}}
    />
  );
}
