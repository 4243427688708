import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentSelect from "../../../componentGroups/componentSelect";
import GenericModal from "../../../componentGroups/genericModal";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";

const INITIAL_VALUES = {
  submitTo: "",
  department: "",
};

const SubmitToModal = ({ id, open, onCLose }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);
  const [submitToData, setSubmitToData] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState({});
  const [employees, setEmployees] = useState([]);
  const [depList, setDepList] = useState([]);

  const handleClose = () => {
    setErrors({});
    setSubmitToData(INITIAL_VALUES);
    onCLose();
  };

  /**
   * returns  all department
   */
  const getAllDept = async () => {
    let res = await http.getApi(t, `/api/department`);
    const options = res?.map((op) => {
      return {
        ...op,
        name: op.departmentName ?? op?.option,
      };
    });
    if (res?.length > 0) {
      setDepList(options);
    }
  };

  /**
   * returns  all department employees
   */
  const getAllDeptEmployees = async (depId) => {
    setLoader(true);
    let res = await http.getApi(t, `/api/employee/all/department/${depId}`);
    setLoader(false);
    if (res?.length > 0) {
      setEmployees(res);
    }
  };

  const handleValidate = (data) => {
    let errors = {};
    if (data?.submitTo === undefined || data?.submitTo === "") {
      errors.submitTo = "errors.select_send_to";
    }
    return errors;
  };

  useEffect(() => {
    getAllDept();
  }, []);

  useEffect(() => {
    if (submitToData?.department) {
      getAllDeptEmployees(submitToData?.department);
    }
  }, [submitToData?.department]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = handleValidate(submitToData);
      setErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitToData]);

  const handleSubmit = async () => {
    let error = handleValidate(submitToData);
    if (Object.keys(error)?.length === 0) {
      setLoader(true);
      const formdata = new FormData();
      formdata.append("userId", submitToData?.submitTo);
      formdata.append("fieldId", id);
      let res = await http.postApi(
        t,
        `/api/form_doc/form/submitDocument`,
        formdata,
        "labels.document_submitted_successfully"
      );
      setLoader(false);
      if (!!res) {
        handleClose();
      }
    } else {
      setErrors(error);
    }
  };

  return (
    <GenericModal title={"labels.submit_option"} open={open}>
      <div className="scroll-auto ModalContent" style={{ minHeight: "270px" }}>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.department")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <ComponentSelect
              value={submitToData?.department}
              name="department"
              option={depList}
              callback={(e) =>
                setSubmitToData({
                  ...submitToData,
                  [e.target.name]: e.target.value,
                  submitTo: "",
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container md={12} className="modalGroup">
          <Grid item xs={12} md={3}>
            <Typography variant="h1" className="lable">
              {t("labels.submit_to")}
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </Typography>
            {/* {errors?.submitTo && (
              <span className="validate">{t(errors.submitTo)}</span>
            )} */}
          </Grid>
          <Grid item xs={12} md={9}>
            <div>
              <ComponentSelect
                value={submitToData?.submitTo}
                name="submitTo"
                option={employees}
                callback={(e) =>
                  setSubmitToData({
                    ...submitToData,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              {errors?.submitTo && (
                <span className="validate">{t(errors.submitTo)}</span>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.discard")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn"}
              callback={() => {
                handleClose();
              }}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t("labels.save")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => handleSubmit()}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default SubmitToModal;
