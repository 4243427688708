/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Card, Grid } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import http from "../../../../api/axios";
import moment from "moment";

export default function PenalitiesAndBonus() {
  const { t } = useTranslation();
  const history = useHistory();
  const [penaltyAndBonusCount, setPenaltyAndBonusCount] = useState({
    penalty: {
      count: 0,
      updatedAt: "",
    },
    bonus: {
      count: 0,
      updatedAt: "",
    },
  });

  const categories = [
    {
      id: "penalty",
      name: t("routes.penalties"),
      count: 0,
      path: "/settings/penalties-bonus/penalties",
    },
    {
      id: "bonus",
      name: t("routes.bonus"),
      count: 0,
      path: "/settings/penalties-bonus/bonus",
    },
  ];

  const handleExpandClick = (item) => {
    history.push(`${item.path}`);
  };

  const getData = async () => {
    const res = await http.getApi(t, "/api/setting/penalty-bonus-list");
    const countData = { ...penaltyAndBonusCount };
    res?.forEach((element) => {
      const res = {
        ...element,
        updatedAt: element.updatedAt
          ? moment(element.updatedAt).format("LLL")
          : "",
      };
      switch (element.type) {
        case "Penalties":
          countData.penalty = res;
          break;
        case "Bonuses":
          countData.bonus = res;
          break;
        default:
      }
      setPenaltyAndBonusCount(countData);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="profiledeptRef">
        <div className="CommonPd">
          <div className="latest_des_box">
            <Grid className="dashTopGrid">
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                style={{ marginTop: "10px" }}
              >
                <Grid>
                  <Typography variant="h4" className="morningText">
                    {" "}
                    {t("titles.penalties_and_bonus")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                columnSpacing={3}
                spacing={3}
                style={{ marginTop: "20px" }}
              >
                {categories?.map((item) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <Card className="profileFormDoc-Card">
                        <div className="Pendding-sugest">
                          <Typography variant="h4" className="morningText">
                            {" "}
                            {item?.name}
                          </Typography>
                          <div className="cardContentstyl">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="textFont"
                            >
                              {t("labels.types_number")} :{" "}
                              {penaltyAndBonusCount[item.id].count}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="textFont"
                            >
                              {t("labels.last_update")} :{" "}
                              {penaltyAndBonusCount[item.id].updatedAt}
                            </Typography>
                          </div>

                          <CardActions className="TechDeptBtn" disableSpacing>
                            <Button
                              variant="span"
                              color="text.secondary"
                              className="textFont OpenBtn"
                              style={{ display: "flex" }}
                              onClick={() => handleExpandClick(item)}
                            >
                              {t("labels.open")} <ArrowRightAltIcon />
                            </Button>
                          </CardActions>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
