import React from "react";
import PartnerDashboard from "./PartnerScreens/dashboard";
import CoordinatorDashboard from "./CoordinatorScreens/dashboard";
import LegalSecretoryDashboard from "./LegalSecretary/dashboard";
import AcademicResearcherDashboard from "./AcademicResearcher/dashboard";
import ExecutiveSecDashboard from "./ExecutiveSecretary/ExecutiveSecDashboard";
import LatestDecision from "./DashBoard/LatestDecision";
import DashBoardPendingRequests from "./DashBoard/DashBoardPendingRequests";
import DashboardMeeting from "./DashBoard/DashboardMeeting";
import DashBoardAttendanceRequest from "./DashBoard/DashBoardAttendanceRequest";
import HRDashboard from "./ProfileModule/hrModule/HRDashboard";
import Greeting from "./DashBoard/Greeting";

export default function Dashboard(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      {user?.departmentId === 2 ? (
        <PartnerDashboard />
      ) : user?.departmentId === 3 ? (
        <CoordinatorDashboard />
      ) : user?.departmentId === 8 ? (
        <HRDashboard />
      ) : user?.departmentId === 9 ? (
        <ExecutiveSecDashboard />
      ) : user?.departmentId === 11 ? (
        <LegalSecretoryDashboard />
      ) : user?.departmentId === 10 || user?.departmentId === 12 ? (
        <AcademicResearcherDashboard
          isClassAteorney={user?.departmentId === 10 ? true : false}
        />
      ) : (
        <>
          <div>
            <Greeting />
            <LatestDecision />
            <DashBoardPendingRequests enableMeetingValidation={true} />
            <DashBoardAttendanceRequest />
            <DashboardMeeting />
          </div>
        </>
      )}
    </>
  );
}
