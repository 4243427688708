import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import logo from "../../../../../../assets/images/jpg 2.png";
import "../../../style.css";
import ComponentButton from "../../../../../componentGroups/componentButton";
import ComponentTextString from "../../../../../componentGroups/componentTextString";
import Sign from "./sign";
import ComponentSelect from "../../../../../componentGroups/componentSelect";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Link } from "react-router-dom";

import Svg from "../../../../../../assets/svg/svg";
import Model from "./model";
import http from "../../../../../../api/axios";
import { encode, decode } from "string-encode-decode";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QRCode from "react-qr-code";
import MinHeightTextarea from "../../../../../componentGroups/componentTextarea";
import AddComment from "./addComment";
import Action from "./action";
import auth from "../../../../../../services/auth";
import Files from "./files";
import validate from "../../../../../../Hooks/form_doc_validcation";
export default function CreateDocument(props) {
  const user = auth.getUser("user");
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { category_id, assigned } = props.match.params;
  let editUrl = location.pathname.slice(1, 14);
  const [showTablePop, setShowTablePop] = useState({});
  const [titleCount, setTitleCount] = useState(0);
  const Id = location?.state;
  const [formData, setFormData] = useState({ title: "", count: "" });
  const [documentSign, setDocumentSign] = useState([]);
  const [showPop, setShowPop] = useState({});
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [modalData, setmodelData] = useState({
    model: false,
    data: [],
    title: "",
  });
  const [data, setdata] = useState([]);
  const [prveData, SetPrveData] = useState([]);
  const [documentReviewer, setDocumentReviewer] = useState({});

  const getLoactionPath = () => {
    var urlll = location?.pathname
    var parts = urlll.split('/'); // Split the URL by backslashes
    if (parts.length >= 3) {
      var extractedString = parts[1]; // Get the string between the first and second backslashes
      return extractedString
    } else {
      console.log("Invalid URL format");
    }
  }

  const getApiData = async () => {
    if (getLoactionPath() == "edit_document") {
      let res = null;
      if (assigned) {
        res = await http.getApi(t, `/api/form_doc/assignedDoc/${category_id}`);
        setDocumentReviewer(res?.document_reviewer);
        setDocumentSign(res?.document_signatures);
      } else {
        res = await http.getApi(t, `/api/form_doc/${category_id}`);
      }
      props.setLoader(false);
      setFormData({
        ...formData,
        title: res?.title,
        count: res?.fields_count,
        status: res?.status,
      });
      SetPrveData(JSON.parse(decode(res?.json_data)));
      setdata(JSON.parse(decode(res?.json_data)));
    } else if (getLoactionPath() == "assigined_edit_document") {
      let res = null;
      if (assigned) {
        res = await http.getApi(t, `/api/form_doc/requestAssigined/${category_id}`);
        setDocumentReviewer(res?.document_reviewer);
        setDocumentSign(res?.document_signatures);
      } else {
        res = await http.getApi(t, `/api/form_doc/${category_id}`);
      }
      props.setLoader(false);
      setFormData({
        ...formData,
        title: res?.title,
        count: res?.fields_count,
        status: res?.status,
      });
      SetPrveData(JSON.parse(decode(res?.json_data)));
      setdata(JSON.parse(decode(res?.json_data)));
    } else {
      let data = [
        {
          row: 1,
          data: [
            {
              header: "Second Side",
              grid: {
                xs: "12",
                md: "6",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Contract Write’s Place",
              grid: {
                xs: "3",
                md: "3",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Contract Date",
              grid: {
                xs: "3",
                md: "3",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
          ],
        },
        {
          row: 2,
          data: [
            {
              header: "First Side",
              grid: {
                xs: "12",
                md: "6",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Second Side",
              grid: {
                xs: "12",
                md: "6",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Contract Write’s Place",
              grid: {
                xs: "3",
                md: "3",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Contract Date",
              grid: {
                xs: "3",
                md: "3",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
          ],
        },
        {
          row: 3,
          data: [
            {
              header: "First Side",
              grid: {
                xs: "12",
                md: "6",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Second Side",
              grid: {
                xs: "12",
                md: "6",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Contract Write’s Place",
              grid: {
                xs: "3",
                md: "3",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
            {
              header: "Contract Date",
              grid: {
                xs: "3",
                md: "3",
              },
              class: "",
              valueExist: false,
              footer: [],
            },
          ],
        },
      ];
      setdata(data);
      SetPrveData(data);
      setFormData({ ...formData, count: 11 });
      props.setLoader(false);
    }
  };
  useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  const handleCloseModel = (
    index,
    fields,
    rowIndex,
    tableRowIndex,
    tableColIndex
  ) => {
    const arr = [...data];

    if (
      fields.type == "table" &&
      tableRowIndex == null &&
      tableRowIndex == null
    ) {
      let rows = [];
      fields.fields.rows.map((op) => {
        let arr = [];
        fields.fields.columns.map((p) => {
          let obj = { ...fields, type: "", value: "", exist: false };
          arr.push(obj);
        });
        rows.push(arr);
      });
      arr[rowIndex].data[index].footer[0] = {
        fields: { rows: rows, columns: fields.fields.columns },
        type: fields.type,
      };
      arr[rowIndex].data[index].valueExist = true;
      setdata(arr);
    } else if (tableRowIndex != null || tableColIndex != null) {
      arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex][
        tableColIndex
      ] = { ...fields, value: "" };
      arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex][
        tableColIndex
      ].exist = true;
      setdata(arr);
    } else {
      arr[rowIndex].data[index].footer[0] = { ...fields, value: "" };
      arr[rowIndex].data[index].valueExist = true;
      setdata(arr);
    }
    setShowTablePop({});
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (
    showTable,
    val,
    index,
    rowIndex,
    tableRowIndex,
    tableColIndex
  ) => {
    if (val == "addNew") {
      setmodelData({
        ...modalData,
        model: true,
        tableShow: data[rowIndex].data.length == 1 && showTable ? true : false,
        tableRowIndex: tableRowIndex,
        tableColIndex: tableColIndex,
        rowIndex: rowIndex,
        fieldIndex: index,
        buttonGroup: [
          {
            value: t("labels.discard"),
            variant: "outlined",
            class: "buttonBlank outlineBtn",
            callback: (index, fields) => {
              setmodelData({ ...modalData, model: false });
            },
          },
          {
            value: t("labels.add"),
            variant: "contained",
            class: "buttonFill FillBtn",
            callback: (index, fields) => {
              handleCloseModel(
                index,
                fields,
                rowIndex,
                tableRowIndex,
                tableColIndex
              );
            },
          },
        ],
      });
    }
  };

  const removeFields = (
    footerIndex,
    index,
    rowIndex,
    tableRowIndex,
    tableColIndex,
    val
  ) => {
    const arr = [...data];
    if (val == "table") {
      // arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex].splice(tableColIndex, 1)
      arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex][
        tableColIndex
      ].exist = false;
      setdata(arr);
    } else {
      arr[rowIndex].data[index].valueExist = false;
      arr[rowIndex].data[index].footer.splice(footerIndex, 1);
      setdata(arr);
    }
  };

  const handleFormSave = () => {
    let error = validate.documentValidate(formData);
    if (Object.keys(error).length === 0) {
      let en = encode(JSON.stringify(data));
      const form_data = new FormData();
      form_data.append("json_data", en);
      form_data.append("title", formData.title);
      form_data.append("fields_count", formData.count);
      form_data.append("lang_id", "1");
      form_data.append("category_id", category_id);
      let res = null;
      props.setLoader(true);
      if (getLoactionPath() == "edit_document") {
        if (assigned) {
          res = http.putApi(
            t,
            `/api/form_doc/assignedDoc/${category_id}`,
            form_data
          );
        } else {
          res = http.putApi(t, `/api/form_doc/${category_id}`, form_data);
        }
      } else if (getLoactionPath() == "assigined_edit_document") {
        if (assigned) {
          res = http.putApi(
            t,
            `/api/form_doc/requestAssigined/${category_id}`,
            form_data
          );
        } else {
          res = http.putApi(t, `/api/form_doc/${category_id}`, form_data);
        }
      } else {
        res = http.postApi(t, `/api/form_doc/create`, form_data);
      }
      setdata([]);
      getApiData();
      setErrors({});
      setFormData({ ...formData, title: "" });
      if (res) {
        props.setLoader(false);
        // history.push(`/forms`)
      }
    } else {
      setErrors(error);
    }
  };

  const handlesArchive = () => { };

  const handleFieldsFileChange = async (e, index, footerIndex, rowIndex) => {
    const form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    let file = await http.getAllDataApi(
      t,
      `/api/form_doc/fileUpload`,
      form_data
    );
    if (file.data) {
      let s1 = [...data];
      s1[rowIndex].data[index].footer[footerIndex].value = file.data;
      console.log(file.data, "filesss");

      setdata(s1);
    }
  };
  const removeFieldFile = async (
    footerIndex,
    index,
    rowIndex,
    tableRowIndex,
    tableColIndex,
    val
  ) => {
    const arr = [...data];
    if (val == "table") {
      // arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex].splice(tableColIndex, 1)
      arr[rowIndex].data[index].footer[0].fields.rows[tableRowIndex][
        tableColIndex
      ].exist = false;
      setdata(arr);
    } else {
      const form_data = new FormData();
      form_data.append(
        "path",
        arr[rowIndex].data[index].footer[footerIndex].value.path
      );
      let file = await http.postApi(
        t,
        `/api/form_doc/form/deleteFile`,
        form_data,
        "labels.file_deleted_successfully"
      );
      if (file) {
        arr[rowIndex].data[index].valueExist = false;
        arr[rowIndex].data[index].footer.splice(footerIndex, 1);
        setdata(arr);
      }
    }
  };
  const handleFieldsChange = (e, index, footerIndex, rowIndex) => {
    let s1 = [...data];
    s1[rowIndex].data[index].footer[footerIndex].value = e.target.value;
    setdata(s1);
  };
  const handleTableFieldsChange = (
    e,
    index,
    footerIndex,
    rowIndex,
    tableRowIndex,
    tableColIndex
  ) => {
    let s1 = [...data];
    s1[rowIndex].data[index].footer[footerIndex].fields.rows[tableRowIndex][
      tableColIndex
    ].value = e.target.value;
    setdata(s1);
  };
  const handleChangeCheckBox = (
    e,
    index,
    footerIndex,
    checkIndex,
    check,
    rowIndex,
    tableRowIndex,
    tableColIndex,
    checkTable
  ) => {
    let s1 = [...data];
    if (checkTable == "table") {
      if (check == "checkbox") {
        s1[rowIndex].data[index].footer[footerIndex].fields.rows[tableRowIndex][
          tableColIndex
        ].fields[checkIndex].checked = e.target.checked;
      } else if (check == "radio") {
        s1[rowIndex].data[index].footer[footerIndex].fields.rows[tableRowIndex][
          tableColIndex
        ].selected = e.target.value;
      }
    } else {
      if (check == "checkbox") {
        s1[rowIndex].data[index].footer[footerIndex].fields[
          checkIndex
        ].checked = e.target.checked;
      } else if (check == "radio") {
        s1[rowIndex].data[index].footer[footerIndex].selected = e.target.value;
      }
    }
    setdata(s1);
  };

  const handleAddColoumn = (rowIndex, index, val) => {
    let s1 = [...data];
    if (val == "row") {
      s1.splice(rowIndex + 1, 0, {
        row: 3,
        data: [
          {
            header: "First Side",
            grid: {
              xs: "12",
              md: "6",
            },
            class: "",
            valueExist: false,
            footer: [],
          },
        ],
      });
      setFormData({ ...formData, count: formData.count + 1 });
      setShowPop({});
      setdata(s1);
    } else if (s1[rowIndex].data.length != 6) {
      s1[rowIndex].data.splice(index + 1, 0, {
        header: "Side",
        class: "",
        valueExist: false,
        footer: [],
      });
      setShowPop({});
      setdata(s1);
      handleTitleCounter();
    } else {
      s1.splice(rowIndex + 1, 0, {
        data: [
          {
            header: "Side",
            class: "",
            valueExist: false,
            footer: [],
          },
        ],
      });
      setShowPop({});
      setdata(s1);
      handleTitleCounter();
    }
  };
  const handleRemoveColoumn = (rowIndex, index, check) => {
    let s1 = [...data];
    if (check == "row") {
      s1.splice(rowIndex, 1);
      setShowPop({});
      setFormData({ ...formData, count: formData.count - 1 });
      setdata(s1);
    } else {
      if (s1[rowIndex].data.length != 1) {
        s1[rowIndex].data.splice(index, 1);
        setShowPop({});
        setdata(s1);
        handleTitleCounter();
      }
    }
  };
  const handleEditHeader = (
    e,
    rowIndex,
    index,
    footerIndex,
    colIndex,
    check
  ) => {
    let s1 = [...data];
    if (check == "table") {
      s1[rowIndex].data[index].footer[footerIndex].fields.columns[colIndex] =
        e.target.value;
    } else {
      s1[rowIndex].data[index].header = e.target.value;
    }
    setdata(s1);
  };

  const handleTitleCounter = () => {
    let count = 0;
    data.map((op) => {
      count = count + op.data.length;
    });
    setFormData({ ...formData, count: count });
    // setTitleCount(count)
  };

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      let error = validate.documentValidate(formData);
      setErrors(error);
    }
  }, [formData]);

  return (
    <Box className="mainDoc">
      <Model modalData={modalData} closeModel={handleCloseModel} />
      <div className="logoCreateDoc">
        <img src={logo} />
      </div>
      <div className="innerDoc">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <Typography
            variant="h6"
            className="morningText"
            sx={{ display: "flex" }}
          >
            <div>
              <ComponentTextString
                placeholder={"Enter the title"}
                value={formData.title}
                callback={(e) => {
                  setFormData({ ...formData, title: e.target.value });
                }}
              />
              {errors?.title && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  * {t(`${errors.title}`)}
                </span>
              )}
            </div>
            <span style={{ paddingTop: "5px", paddingLeft: "5px" }}>
              ({formData.count})
            </span>
          </Typography>
          <div className="ActionBUTTON" style={{ display: "flex" }}>
            {documentSign &&
              documentSign.length != 0 &&
              documentSign?.find(
                (op) =>
                  (user.id == op?.userId && op?.user_type == "reviewer") ||
                  op?.user_type == "verifier"
              ) && (
                <div className="Mright">
                  <Action
                    setLoader={props.setLoader}
                    documentSign={documentSign}
                    Id={category_id}
                    perarntGetApiData={getApiData}
                    user={user}
                    status={formData?.status}
                  />
                </div>
              )}

            {formData?.status != "Achirve" && formData?.status != "Complete" && (
              <ComponentButton
                value={t("labels.save")}
                class={"buttonBlank"}
                callback={() => {
                  handleFormSave();
                }}
              />
            )}
          </div>
        </Grid>
        {/* <Grid container className="outerHeader"> */}
        {data &&
          data?.length != 0 &&
          data.map((o, rootRowIndex) => {
            return (
              <div style={{ display: "flex", minHeight: "auto" }}>
                {o.data.map((op, index) => (
                  <div
                    className="form_previewTemplate"
                    style={{ flex: "1 0 15%", height: "auto" }}
                  >
                    <div className="headerForm ">
                      <Typography
                        variant="body2"
                        className="titleHead menuTableRel"
                      >
                        {showPop.index == index &&
                          showPop.rowIndex == rootRowIndex &&
                          !showPop.pop ? (
                          <div className="centerFlex">
                            <ComponentTextString
                              value={op.header}
                              callback={(e) => {
                                handleEditHeader(e, rootRowIndex, index);
                              }}
                            />
                            <AddCircleOutlineIcon
                              sx={{
                                color: "white",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowPop({})}
                            />
                          </div>
                        ) : (
                          <>
                            {" "}
                            <span className="headtxt"> {op.header}</span>
                            {!assigned && (
                              <span
                                class="sideHeaderIcon"
                                onClick={() =>
                                  setShowPop({
                                    rowIndex: rootRowIndex,
                                    index: index,
                                    pop: true,
                                  })
                                }
                              >
                                <MoreVertIcon />
                              </span>
                            )}
                          </>
                        )}
                        {showPop.index == index &&
                          showPop.rowIndex == rootRowIndex &&
                          showPop.pop && (
                            <div
                              className="TableMenu"
                              onMouseLeave={() => setShowPop({})}
                            >
                              <ul className="menuTableList">
                                <li>
                                  <a
                                    onClick={() =>
                                      handleAddColoumn(
                                        rootRowIndex,
                                        index,
                                        "row"
                                      )
                                    }
                                  >
                                    <Svg type={"add"} />
                                    <span class="sideicon">Add Row</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleAddColoumn(
                                        rootRowIndex,
                                        index,
                                        "column"
                                      )
                                    }
                                  >
                                    <Svg type={"add"} />
                                    <span class="sideicon">Add Coloumn</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      setShowPop({
                                        rowIndex: rootRowIndex,
                                        index: index,
                                        pop: false,
                                      })
                                    }
                                  >
                                    <Svg type={"edit"} />
                                    <span class="sideicon">Edit Header</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleRemoveColoumn(
                                        rootRowIndex,
                                        index,
                                        "column"
                                      )
                                    }
                                  >
                                    <Svg type={"delete"} />
                                    <span class="sideicon">Delete Coloum</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleRemoveColoumn(
                                        rootRowIndex,
                                        index,
                                        "row"
                                      )
                                    }
                                  >
                                    <Svg type={"delete"} />
                                    <span class="sideicon">Delete Row</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                      </Typography>
                    </div>
                    {op.valueExist == false ? (
                      <div
                        className="iconTool footerForm"
                        onClick={() =>
                          !assigned &&
                          handleModel(true, "addNew", index, rootRowIndex)
                        }
                      >
                        {!assigned && (
                          <>
                            <Svg type="addTool" />
                            <Typography variant="h6" className="slideText">
                              {t("forms.add_tool")}
                            </Typography>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="footerForm ">
                        {op.footer.map((footer, footerIndex) => {
                          if (footer.type == "radio") {
                            return (
                              <div className="CrossBtn">
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  // value={footer.selected}
                                  >
                                    {footer.fields.map((op, indexCheck) => (
                                      <FormControlLabel
                                        value={op.lable}
                                        checked={footer.selected == op.lable}
                                        control={
                                          <Radio
                                            onChange={(e) =>
                                              handleChangeCheckBox(
                                                e,
                                                index,
                                                footerIndex,
                                                indexCheck,
                                                "radio",
                                                rootRowIndex
                                              )
                                            }
                                          />
                                        }
                                        label={op.field}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                                {!assigned && (
                                  <div
                                    class="closeIcon"
                                    onClick={() =>
                                      removeFields(
                                        footerIndex,
                                        index,
                                        rootRowIndex
                                      )
                                    }
                                  >
                                    <Svg type="closeIcon" />
                                  </div>
                                )}
                              </div>
                            );
                          } else if (footer.type == "dropdown") {
                            return (
                              <div className="CrossBtn">
                                <FormControl
                                  fullWidth
                                  disabled={props?.disabled}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className="selectBox"
                                    onChange={(e) => {
                                      handleFieldsChange(
                                        e,
                                        index,
                                        footerIndex,
                                        rootRowIndex
                                      );
                                    }}
                                    name={props.name}
                                    value={footer.value}
                                  // defaultValue={props?.value}
                                  >
                                    {footer.fields?.length != 0 &&
                                      footer.fields?.map((op, index) => (
                                        <MenuItem value={op} key={index}>
                                          {op}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                                {!assigned && (
                                  <div
                                    class="closeIcon"
                                    onClick={() =>
                                      removeFields(
                                        footerIndex,
                                        index,
                                        rootRowIndex
                                      )
                                    }
                                  >
                                    <Svg type="closeIcon" />
                                  </div>
                                )}
                              </div>
                            );
                          } else if (footer.type == "checkbox") {
                            return (
                              <div className="CrossBtn">
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    {footer.fields.map((op, indexCheck) => (
                                      <FormControlLabel
                                        value={op.field}
                                        control={
                                          <Checkbox
                                            checked={op.checked}
                                            onChange={(e) =>
                                              handleChangeCheckBox(
                                                e,
                                                index,
                                                footerIndex,
                                                indexCheck,
                                                "checkbox",
                                                rootRowIndex
                                              )
                                            }
                                          />
                                        }
                                        label={op.field}
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                                {!assigned && (
                                  <div
                                    class="closeIcon"
                                    onClick={() =>
                                      removeFields(
                                        footerIndex,
                                        index,
                                        rootRowIndex
                                      )
                                    }
                                  >
                                    <Svg type="closeIcon" />
                                  </div>
                                )}
                              </div>
                            );
                          } else if (footer.type == "table") {
                            return (
                              <div className="CrossBtn2 table">
                                <table className="templateTable">
                                  <tr>
                                    <th>No. </th>
                                    {footer.fields.columns.map(
                                      (col, colIndex) => (
                                        <th>
                                          <Typography
                                            variant="body2"
                                            className=" menuTableRel"
                                          >
                                            {showTablePop.footerIndex ==
                                              footerIndex &&
                                              showTablePop.index == index &&
                                              showTablePop.rowIndex ==
                                              rootRowIndex &&
                                              showTablePop.colIndex ==
                                              colIndex ? (
                                              <div className="centerFlex">
                                                <ComponentTextString
                                                  value={col}
                                                  callback={(e) => {
                                                    handleEditHeader(
                                                      e,
                                                      rootRowIndex,
                                                      index,
                                                      footerIndex,
                                                      colIndex,
                                                      "table"
                                                    );
                                                  }}
                                                />
                                                <AddCircleOutlineIcon
                                                  sx={{
                                                    color: "#484848",
                                                    marginLeft: "10px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    setShowTablePop({})
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                {" "}
                                                <span
                                                  className="headtxt"
                                                  style={{
                                                    color: "#484848",
                                                    fontSize: "14px",
                                                    fontStyle: "bold",
                                                  }}
                                                >
                                                  {" "}
                                                  {col}
                                                </span>
                                                {!assigned && (
                                                  <span
                                                    class="sideHeaderIcon"
                                                    onClick={() =>
                                                      setShowTablePop({
                                                        footerIndex:
                                                          footerIndex,
                                                        rowIndex: rootRowIndex,
                                                        index: index,
                                                        colIndex: colIndex,
                                                      })
                                                    }
                                                  >
                                                    <Svg type={"edit"} />
                                                  </span>
                                                )}
                                              </>
                                            )}
                                          </Typography>
                                        </th>
                                      )
                                    )}
                                  </tr>
                                  {footer.fields.rows.map(
                                    (row, tableRowIndex) => (
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            width: "50px",
                                          }}
                                        >
                                          {tableRowIndex + 1}
                                        </td>
                                        {row.map((column, tableColIndex) => (
                                          <td>
                                            {!column.exist ? (
                                              <div
                                                className="iconTool footerForm"
                                                onClick={() =>
                                                  handleModel(
                                                    false,
                                                    "addNew",
                                                    index,
                                                    rootRowIndex,
                                                    tableRowIndex,
                                                    tableColIndex
                                                  )
                                                }
                                              >
                                                {!assigned && (
                                                  <>
                                                    <Svg type="addTool" />
                                                    <Typography
                                                      variant="h6"
                                                      className="slideText"
                                                    >
                                                      {t("forms.add_tool")}
                                                    </Typography>
                                                  </>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="footerForm ">
                                                {column.type == "radio" ? (
                                                  <div className="CrossBtn">
                                                    <FormControl>
                                                      <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={column.selected}
                                                      >
                                                        {column.fields.map(
                                                          (op, indexCheck) => (
                                                            <FormControlLabel
                                                              value={op.lable}
                                                              checked={
                                                                column.selected ==
                                                                op.lable
                                                              }
                                                              control={
                                                                <Radio
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeCheckBox(
                                                                      e,
                                                                      index,
                                                                      footerIndex,
                                                                      indexCheck,
                                                                      "radio",
                                                                      rootRowIndex,
                                                                      tableRowIndex,
                                                                      tableColIndex,
                                                                      "table"
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              label={op.field}
                                                            />
                                                          )
                                                        )}
                                                      </RadioGroup>
                                                    </FormControl>
                                                    {!assigned && (
                                                      <div
                                                        class="closeIcon"
                                                        onClick={() =>
                                                          removeFields(
                                                            footerIndex,
                                                            index,
                                                            rootRowIndex,
                                                            tableRowIndex,
                                                            tableColIndex,
                                                            "table"
                                                          )
                                                        }
                                                      >
                                                        <Svg type="closeIcon" />
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : column.type ==
                                                  "dropdown" ? (
                                                  <div className="CrossBtn">
                                                    <FormControl
                                                      fullWidth
                                                      disabled={props?.disabled}
                                                    >
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        className="selectBox"
                                                        onChange={(e) => {
                                                          handleTableFieldsChange(
                                                            e,
                                                            index,
                                                            footerIndex,
                                                            rootRowIndex,
                                                            tableRowIndex,
                                                            tableColIndex
                                                          );
                                                        }}
                                                        name={props.name}
                                                        value={column?.value}
                                                      // defaultValue={props?.value}
                                                      >
                                                        {column.fields
                                                          ?.length != 0 &&
                                                          column.fields?.map(
                                                            (op, index) => (
                                                              <MenuItem
                                                                value={op}
                                                                key={index}
                                                              >
                                                                {op}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                      </Select>
                                                    </FormControl>
                                                    {!assigned && (
                                                      <div
                                                        class="closeIcon"
                                                        onClick={() =>
                                                          removeFields(
                                                            footerIndex,
                                                            index,
                                                            rootRowIndex,
                                                            tableRowIndex,
                                                            tableColIndex,
                                                            "table"
                                                          )
                                                        }
                                                      >
                                                        <Svg type="closeIcon" />
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : column.type ==
                                                  "checkbox" ? (
                                                  <div className="CrossBtn">
                                                    <FormControl>
                                                      <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                      >
                                                        {column.fields.map(
                                                          (op, indexCheck) => (
                                                            <FormControlLabel
                                                              value={op.field}
                                                              control={
                                                                <Checkbox
                                                                  checked={
                                                                    op.checked
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeCheckBox(
                                                                      e,
                                                                      index,
                                                                      footerIndex,
                                                                      indexCheck,
                                                                      "checkbox",
                                                                      rootRowIndex,
                                                                      tableRowIndex,
                                                                      tableColIndex,
                                                                      "table"
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              label={op.field}
                                                            />
                                                          )
                                                        )}
                                                      </RadioGroup>
                                                    </FormControl>
                                                    {!assigned && (
                                                      <div
                                                        class="closeIcon"
                                                        onClick={() =>
                                                          removeFields(
                                                            footerIndex,
                                                            index,
                                                            rootRowIndex,
                                                            tableRowIndex,
                                                            tableColIndex,
                                                            "table"
                                                          )
                                                        }
                                                      >
                                                        <Svg type="closeIcon" />
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : column.type == "file" ? (
                                                  <Files
                                                    assigned={assigned}
                                                    column={column}
                                                    allIndex={{
                                                      index: index,
                                                      footerIndex: footerIndex,
                                                      rootRowIndex:
                                                        rootRowIndex,
                                                      tableRowIndex:
                                                        tableRowIndex,
                                                      tableColIndex:
                                                        tableColIndex,
                                                    }}
                                                    data={data}
                                                    setdata={setdata}
                                                    removeFields={removeFields}
                                                  />
                                                ) : (
                                                  <div className="CrossBtn">
                                                    <ComponentTextString
                                                      type={column.type}
                                                      value={column?.value}
                                                      callback={(e) => {
                                                        handleTableFieldsChange(
                                                          e,
                                                          index,
                                                          footerIndex,
                                                          rootRowIndex,
                                                          tableRowIndex,
                                                          tableColIndex
                                                        );
                                                      }}
                                                    />
                                                    {/* <input className='outerHeadinput' type={footer.type} value={footer?.value} onChange={(e) => { handleFieldsChange(e, index, footerIndex) }} /> */}
                                                    {!assigned && (
                                                      <div
                                                        class="closeIcon"
                                                        onClick={() =>
                                                          removeFields(
                                                            footerIndex,
                                                            index,
                                                            rootRowIndex,
                                                            tableRowIndex,
                                                            tableColIndex,
                                                            "table"
                                                          )
                                                        }
                                                      >
                                                        <Svg type="closeIcon" />
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                    )
                                  )}
                                </table>
                              </div>
                            );
                          } else {
                            return (
                              <div className="CrossBtn">
                                {footer.type == "file" ? (
                                  footer.value ? (
                                    <a
                                      href={`${http.baseURL}/${footer.value.path}`}
                                      target="_blank"
                                    >
                                      <span>{footer.value.name}</span>
                                    </a>
                                  ) : (
                                    <ComponentTextString
                                      type={footer.type}
                                      value={footer.value}
                                      callback={(e) => {
                                        handleFieldsFileChange(
                                          e,
                                          index,
                                          footerIndex,
                                          rootRowIndex
                                        );
                                      }}
                                    />
                                  )
                                ) : (
                                  <ComponentTextString
                                    type={footer.type}
                                    value={footer?.value}
                                    callback={(e) => {
                                      handleFieldsChange(
                                        e,
                                        index,
                                        footerIndex,
                                        rootRowIndex
                                      );
                                    }}
                                  />
                                )}
                                {/* <input className='outerHeadinput' type={footer.type} value={footer?.value} onChange={(e) => { handleFieldsChange(e, index, footerIndex) }} /> */}
                                {!assigned && (
                                  <div
                                    class="closeIcon"
                                    onClick={() => {
                                      footer.type == "file"
                                        ? removeFieldFile(
                                          footerIndex,
                                          index,
                                          rootRowIndex
                                        )
                                        : removeFields(
                                          footerIndex,
                                          index,
                                          rootRowIndex
                                        );
                                    }}
                                  >
                                    <Svg type="closeIcon" />
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        {documentSign && documentSign.length != 0 && (
          <div style={{ display: "flex", minHeight: "auto" }}>
            {documentSign &&
              documentSign?.map((code, index) => (
                <div
                  style={{ flex: "1 0 15%", height: "auto" }}
                  key={index}
                  className="form_previewTemplate addSignzz"
                >
                  <div className="headerForm ">
                    <Typography
                      variant="body2"
                      className="titleHead menuTableRel"
                    >
                      {code.userName} ( {code.department} )
                    </Typography>
                    <div>
                      <Typography
                        variant="body2"
                        className="titleHead menuTableRel"
                      >
                        ({code.user_type})
                      </Typography>
                    </div>
                  </div>
                  <div className="footerForm ">
                    <div className="CrossBtn cutombtnImg">
                      {code?.sign ? (
                        <div className="qr">
                          <QRCode value={decode(code?.sign)} />
                        </div>
                      ) : (
                        <Sign
                          data={data}
                          prveData={prveData}
                          code={code}
                          getApiData={getApiData}
                          setLoader={props.setLoader}
                          Id={category_id}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        {/* </Grid> */}
      </div>
      {assigned && getLoactionPath() != "assigined_edit_document" && <AddComment Id={category_id} setLoader={props.setLoader} />}
    </Box>
  );
}
