import * as React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function MinHeightTextarea(props) {
  return (
    <TextareaAutosize
      onChange={props.callback}
      name={props.name}
      value={props.value}
      aria-label="minimum height"
      minRows={props.minRows}
      cols={props?.cols}
      placeholder={props.placeholder}
      style={{ width: "100%" }}
      disabled={props.disable}

    />
  );
}
