import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ComponentButton from "../../../componentGroups/componentButton";
import GenericModal from "../../../componentGroups/genericModal";
import http from "../../../../api/axios";
import LoadingContext from "../../../../context/LoaderContext";

const MeetingMinsValidationModal = ({ open, item, onCLose }) => {
  const { t } = useTranslation();
  const { setLoader } = useContext(LoadingContext);

  const handleValidate = async (validateId) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("meeting_mins_id", item?.meeting_mins_id);
    formData.append("validation", validateId);
    await http.postApi(t, "/api/meeting/meeting-mins/validate", formData);
    setLoader(false);
    onCLose();
  };

  return (
    <GenericModal
      title={"labels.meeting_mins"}
      open={open}
      enableCloseIcon={true}
      onClose={onCLose}
    >
      <div className="scroll-auto ModalContent" style={{ minHeight: "120px" }}>
        <Grid container md={12} className="modalGroup">
          <div dangerouslySetInnerHTML={{ __html: item?.meeting_mints }}></div>
        </Grid>
      </div>
      <div style={{ marginTop: "20px" }} className="ModalFooter">
        <Grid container className="modalGroup" style={{ marginTop: "5px" }}>
          <Grid item>
            <ComponentButton
              value={t("labels.validate")}
              variant="contained"
              class="buttonFill FillBtn"
              callback={() => {
                handleValidate(1);
              }}
            />
          </Grid>
          <Grid item>
            <ComponentButton
              value={t("labels.notvalidate")}
              variant={"outlined"}
              class={"buttonBlank outlineBtn ml-10"}
              callback={() => {
                handleValidate(0);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </GenericModal>
  );
};

export default MeetingMinsValidationModal;
