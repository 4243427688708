import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ComponentTextString from "../../../componentGroups/componentTextString";
import ComponentSelect from "../../../componentGroups/componentSelect";
import hooks from "../../../../Hooks/hooks";
import ComponentDatePicker from "../../../componentGroups/componentDatepicker";
import ComponentButton from "../../../componentGroups/componentButton";
import ComponentAccordion from "../../../componentGroups/componentAccordion";
import EmployeePenaltyList from "./penaltyAndBonus/EmployeePenaltyList";
import EmployeeBonusList from "./penaltyAndBonus/EmployeeBonusList";

const Payroll = ({ setEmpState, empState, errors, dropDownOptions }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { empId } = useParams();

  return (
    <>
      <Grid className="AddEmpGenInfo" container rowSpacing={2}>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.contract_start_date")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentDatePicker
              class={"custonInput"}
              placeholder=""
              value={empState?.employees_payroll?.contractStartDate}
              name="contractStartDate"
              callback={(evt) => {
                let endDate = empState?.employees_payroll?.contractEndDate;
                if (
                  endDate !== "" &&
                  new Date(evt.target.value) > new Date(endDate)
                ) {
                  endDate = "";
                }
                setEmpState({
                  ...empState,
                  employees_payroll: {
                    ...empState?.employees_payroll,
                    [evt.target.name]: evt.target.value,
                    contractEndDate: endDate,
                  },
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.contract_end_date")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentDatePicker
            class={"custonInput"}
            placeholder=""
            value={empState?.employees_payroll?.contractEndDate}
            min={empState?.employees_payroll?.contractStartDate}
            name="contractEndDate"
            callback={(evt) => {
              setEmpState({
                ...empState,
                employees_payroll: {
                  ...empState?.employees_payroll,
                  [evt.target.name]: evt.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.salary_month")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <ComponentTextString
            class={"custonInput"}
            placeholder=""
            name="salary"
            value={empState?.employees_payroll?.salary}
            callback={(evt) => {
              setEmpState({
                ...empState,
                employees_payroll: {
                  ...empState?.employees_payroll,
                  [evt.target.name]: evt.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.house_allowance")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              value={empState?.employees_payroll?.houseAllowance}
              option={hooks.sendName(dropDownOptions.houseAllowance, "name")}
              name="houseAllowance"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  employees_payroll: {
                    ...empState?.employees_payroll,
                    [evt.target.name]: evt.target.value,
                  },
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={2} sx={{ paddingTop: 0 }}>
          <Typography variant="body1" className="customLabel">
            {t("labels.transportaion_allowance")}
          </Typography>
        </Grid>
        <Grid className="CustomWidth" item xs={11} md={9}>
          <div>
            <ComponentSelect
              className="GenDropdown"
              value={empState?.employees_payroll?.transporationAllowance}
              option={hooks.sendName(dropDownOptions.houseAllowance, "name")}
              name="transporationAllowance"
              callback={(evt) => {
                setEmpState({
                  ...empState,
                  employees_payroll: {
                    ...empState?.employees_payroll,
                    [evt.target.name]: evt.target.value,
                  },
                });
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ComponentButton
            value={t("Add Penalty")}
            variant={"outlined"}
            class={"buttonBlank outlineBtn"}
            callback={() => {
              history.push(`${location.pathname}/add-employeepenalty`);
            }}
          />{" "}
          <ComponentButton
            style={{ marginLeft: "10px" }}
            value={t("Add Bonus")}
            variant={"outlined"}
            class={"buttonBlank outlineBtn"}
            callback={() => {
              history.push(`${location.pathname}/add-employeebonus`);
            }}
          />{" "}
        </Grid>
      </Grid>
      <div style={{ marginTop: "10px" }}>
        <ComponentAccordion
          variant="h4"
          addedClassName={true}
          data={{
            heading: t("Penalties"),
          }}
          className="bgColor"
          editIcon={false}
          component={<EmployeePenaltyList id={empId} />}
        />{" "}
        <div style={{ marginTop: "10px" }}>
          <ComponentAccordion
            variant="h4"
            addedClassName={true}
            data={{
              heading: t("Bonuses"),
            }}
            className="bgColor"
            editIcon={false}
            component={<EmployeeBonusList id={empId} />}
          />{" "}
        </div>
      </div>
    </>
  );
};

export default Payroll;
