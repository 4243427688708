/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";
import http from "../../api/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { gotoDetails } from "../../utils/common";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#c5c5c55e",
  marginLeft: 0,
  // gap: "20px",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    color: "rgba(43, 86, 76, 0.38)",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

export default function ComponentSearch(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const debouce = useDebounce();
  const [searchResult, setSearchResult] = useState([]);
  const [loader, setLoader] = useState([]);
  const [hasMoreResults, setHasMoreResults] = useState(false);

  const { navSearch = false } = props;
  const navRef = React.useRef(null);
  const onToggleClick = (e) => {
    navRef.current.classList.toggle("hide");
  };

  const [enableDropDown, setEnableDropDown] = useState(false);
  const [value, setValue] = useState("");
  const onChange = (e) => {
    if (!e.target.value) {
      setEnableDropDown(false);
    }
    setValue(e.target.value);
  };

  const getSearchResult = async () => {
    setLoader(true);
    setEnableDropDown(true);
    const res = await http.getExportApi(
      t,
      `/api/user/search/${value}?page=1&size=6 `
    );
    setLoader(false);
    setSearchResult(res?.dataSet);
    if (res?.data_count > 6) {
      setHasMoreResults(true);
    } else {
      setHasMoreResults(false);
    }
  };

  useEffect(() => {
    if (value) {
      debouce(getSearchResult);
    }
  }, [value]);

  const seeMore = () => {
    setValue("");
    setEnableDropDown(false);
    history.push(`/searchResult?value=${value}`);
  };

  return (
    <>
      <div className="nav_wrapper_box" style={{ width: "100%" }}>
        <button className="mobile_sec_icon" onClick={onToggleClick}>
          <SearchIcon sx={{ color: "#2c2c2c" }} />
        </button>
        <nav ref={navRef}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: "#2c2c2c" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t("titles.search")}
              onChange={props.callback ?? onChange}
              value={props.value ?? value}
              inputProps={{ "aria-label": "search" }}
              onKeyDown={(e) => {
                if (navSearch && e.key === "Enter") {
                  seeMore();
                }
              }}
            />
          </Search>
        </nav>
        {navSearch && value && (
          <div
            className="search_dropdown"
            style={{ display: enableDropDown ? "block" : "none" }}
          >
            {loader && (
              <div className="center_loader">
                <CircularProgress />
              </div>
            )}
            {!loader && (
              <div>
                <Grid container>
                  {searchResult?.length > 0 &&
                    searchResult?.map((item) => (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        style={{ marginBottom: "5px" }}
                      >
                        <div className="searchinfo">
                          <div
                            onClick={() => {
                              setValue("");
                              setEnableDropDown(false);
                              gotoDetails(history, item.id, item.type);
                            }}
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                          >
                            <div className="searchtitle">{item?.code}</div>
                            <div className="searchdescription">
                              {item?.title}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  {searchResult?.length === 0 && (
                    <Grid item md={12} xs={12}>
                      <div
                        style={{
                          padding: "0 20px ",
                        }}
                      >
                        <div className=" center_loader searchdescription">
                          {t("labels.no_results_found")}
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
                {hasMoreResults && (
                  <div
                    style={{
                      padding: "0 20px ",
                    }}
                  >
                    <div
                      className="searchdescription"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <span
                        onClick={() => {
                          seeMore();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {t("labels.see_all_results")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
