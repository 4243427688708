const categoryValidate = (data) => {
  let error = {};
  if (data.category == undefined) {
    error.category = "errors.enter_category_name";
  }
  if (data.departmentName == undefined) {
    error.departmentName = "errors.select_department";
  }
  return error;
};

const documentValidate = (data) => {
  let error = {};
  if (data.title == undefined || data.title == "") {
    error.title = "errors.enter_title";
  }
  return error;
};

export default {
  categoryValidate, documentValidate
};
