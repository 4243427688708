import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../../componentGroups/componentTable";
import ComponentModal from "../../../../componentGroups/componectModel";
import { useTranslation } from "react-i18next";
import http from "../../../../../api/axios";
import { useParams } from "react-router-dom";
import auth from "../../../../../services/auth";
import validate from "../../../../../Hooks/department_valdaction";
import hooks from "../../../../../Hooks/hooks";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../../constant";
import { getUserHasAccess } from "../../../../../utils/common";
import AuthorityContext from "../../../../../context/AuthorityContext";
import { loadOptions } from "../../../../../api/utils/common";

export default function DapartmentEmployee({ setLoader, depData }) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { deptId } = useParams();
  const Id = deptId;
  const [column, setColumn] = useState([
    { lable: "labels.name", name: "name", width: "10px", align: "left" },
    { lable: "labels.code", name: "code", width: "10px", align: "left" },
    {
      lable: "labels.phone_number",
      name: "phone",
      width: "10px",
      align: "left",
    },
    { lable: "labels.email", name: "email", width: "10px", align: "left" },
    { lable: "labels.status", name: "status", width: "10px", align: "left" },
  ]);
  const [data, setData] = useState({
    title: "labels.department_employees",
    page: "Branches",
    infoURL: `/departments/edit-department/${deptId}/edit-employee`,
    hasVariable: true,
    buttonGroup: [
      {
        title: "titles.add_new_employee",
        variant: "contained",
        class: "buttonFill",
        callback: () => {
          if (getUserHasAccess("department", 58)) {
            handleModel("addEmp");
          } else {
            setAuthorityModal(true);
          }
        },
      },
    ],
  });
  const [depEmpData, setdepEmpData] = useState();
  const [deptState, setDeptState] = useState({});
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [totalRecords, setTotalrecords] = useState(0);
  const [codes, setCodes] = useState({});
  const [search, setSearch] = useState("");
  const [modalData, setmodelData] = useState({
    model: false,
    title: "titles.add_new_employee",
    data: [
      {
        lable: "labels.name",
        name: "name",
        field: { type: "text", required: true },
      },
      {
        lable: "labels.email",
        name: "email",
        field: { type: "text", required: true },
      },
      {
        lable: "labels.password",
        name: "password",
        field: { type: "text", required: true },
      },
      {
        lable: "labels.department",
        name: "departmentName",
        field: {
          disable: true,
          type: "dropdown",
          findLable: "departmentName",
          option: [],
          required: true,
        },
      },
      {
        lable: "titles.department_code",
        name: "departmentCode",
        field: { type: "text", required: true, disable: true },
      },
      {
        lable: "labels.branch",
        name: "branchName",
        field: {
          type: "dropdown",
          findLable: "branchName",
          option: [],
          required: true,
        },
      },
      {
        lable: "labels.branch_code",
        name: "branchCode",
        field: { type: "text", option: [], required: true, disable: true },
      },
    ],
    buttonGroup: [
      {
        value: "labels.discard",
        variant: "outlined",
        class: "buttonBlank outlineBtn",
        callback: () => {
          handleCloseModel();
        },
      },
      {
        value: "labels.add",
        variant: "contained",
        class: "buttonFill FillBtn",
        callback: (obj) => {
          addEmployee(obj);
        },
      },
    ],
  });
  const handleCloseModel = () => {
    setErrors({});
    setDeptState({});
    getDetailsApi();
    setmodelData({ ...modalData, model: false });
  };

  const handleModel = (val) => {
    if (val == "addEmp") {
      setmodelData({
        ...modalData,
        model: true,
      });
    }
  };

  const addEmployee = async (obj) => {
    let error = validate.deptEmpValidate(obj);
    if (Object.keys(error).length === 0) {
      // setLoader(true);
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("password", obj.password);
      formData.append("email", obj.email);
      formData.append("departmentId", obj.departmentName);
      formData.append("branchId", obj.branchName);
      formData.append("langId", `1`);
      const responsibilities = [...obj?.responsibilities];
      responsibilities?.forEach((item) => {
        formData.append(`responsibilities[]`, item);
      });
      const res = await http.postApi(t, "/api/employee/create", formData);
      if (res) {
        // setLoader(false);
        getApiData();
        handleCloseModel();
      }
      // setLoader(false);
    } else {
      setErrors(error);
    }
    // setLoader(false);
  };

  useEffect(() => {
    if (deptState?.branchName && codes.branchName != deptState?.branchName) {
      setCodes({ ...codes, branchName: deptState?.branchName });
      setDeptState({
        ...deptState,
        branchCode: hooks.sendId(
          modalData.data[5].field.option,
          "branchCode",
          deptState.branchName,
          "id"
        ),
      });
    }
    if (Object.keys(errors).length != 0) {
      let error = validate.deptEmpValidate(deptState);
      setErrors(error);
    }
  }, [deptState]);

  const getDetailsApi = async () => {
    setLoader(true);
    let s1 = { ...modalData };
    let allDepartments = await loadOptions(
      t,
      "",
      null,
      "get",
      "/api/department"
    );
    let allBranches = await loadOptions(
      t,
      "",
      null,
      "post",
      "/api/branch/details"
    );
    s1.data[3].field.option = allDepartments;
    s1.data[5].field.option = allBranches;
    setmodelData(s1);
    const res = await http.getApi(
      t,
      `/api/employee/responsibilities/all/${depData?.id}`
    );
    const options = res?.map((op) => {
      return op?.id;
    });

    setDeptState({
      ...deptState,
      departmentName: depData?.id,
      departmentCode: depData?.departmentCode,
      responsibilities: [...options],
    });
    setLoader(false);
  };
  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let res = await http.getAllDataApi(
      t,
      `/api/department/employees/all/${Id}/?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`
    );
    setTotalrecords(res?.data_count);
    setdepEmpData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getDetailsApi();
  }, []);

  useEffect(() => {
    getApiData(page, size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search]);

  return (
    <>
      {/* <ToastContainer /> */}
      <ComponentModal
        handleCloseModel={handleCloseModel}
        modalData={modalData}
        errors={errors}
        setPerantState={setDeptState}
        perantState={deptState}
      />
      <ComponentTable
        rows={depEmpData}
        columns={column}
        search={search}
        setSearch={setSearch}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords}
      />
    </>
  );
}
