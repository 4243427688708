import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import http, { baseURL } from "../../../api/axios";
import { INITIAL_PAGESIZE, INITIAL_PAGEINDEX } from "../../../constant";
import moment from "moment";
import { loadOptions } from "../../../api/utils/common";
import hooks from "../../../Hooks/hooks";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../utils/common";
import AuthorityContext from "../../../context/AuthorityContext";

export default function CompanyDecision() {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [filterData, setFilterData] = useState({ filter: false, date: "" });
  const [totalRecords, setTotalrecords] = useState(0);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("decision", 116)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        selectedData.forEach((item, index) => {
          formData.append(`id[${index}]`, item);
        });
        await http.postApi(
          t,
          "/api/meeting/delete",
          formData,
          "labels.meeting_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 20,
    },
    {
      lable: "labels.meeting_code",
      name: "meeting_code",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.meeting_title",
      name: "meeting_title",
      width: "10px",
      align: "left",
      minWidth: 20,
    },
    {
      lable: "labels.date",
      name: "date",
      type: "date",
      width: "10px",
      align: "left",
      minWidth: 20,
      type: "date",
    },
    {
      lable: "labels.department",
      name: "department",
      type: "dropDown",
      options: [],
      width: "10px",
      align: "left",
      minWidth: 20,
    },
  ]);
  const [data, setData] = useState({
    title: "tableTitle.0.company_meetings_history",
    page: "CompanyDecision",
    infoURL: "/company-decision/meeting-detail",
    handlExport: (_page, _size, filterData) => handleExport(filterData),
    buttonGroup: [
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
    ],
  });

  const [meetingData, setMeetingData] = useState([]);

  const handleExport = async (filterData) => {
    let formdata = new FormData();
    formdata.append(
      "filter[meeting_code]",
      filterData?.meeting_code ? filterData?.meeting_code : ""
    );
    formdata.append(
      "filter[meeting_title]",
      filterData?.meeting_title ? filterData?.meeting_title : ""
    );
    formdata.append(
      "filter[department]",
      filterData?.department ? filterData?.department : ""
    );
    formdata.append(
      "filter[date]",
      filterData?.date ? moment(filterData?.date).format("YYYY-MM-DD") : ""
    );
    const res = await http.postApi(
      t,
      `/api/meeting/meeting-decision/company/list/export`,
      formdata
    );
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    search = ""
  ) => {
    let formdata = new FormData();
    formdata.append(
      "filter[meeting_code]",
      filterData?.meeting_code ? filterData?.meeting_code : ""
    );
    formdata.append(
      "filter[meeting_title]",
      filterData?.meeting_title ? filterData?.meeting_title : ""
    );
    formdata.append(
      "filter[department]",
      filterData?.department ? filterData?.department : ""
    );
    formdata.append(
      "filter[date]",
      filterData?.date ? moment(filterData?.date).format("YYYY-MM-DD") : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/meeting/meeting-decision/company/list?page=${
        pageIndex + 1
      }&size=${pageSize}&search=${search}`,
      formdata
    );
    let res2 = await loadOptions(t, 1, undefined, "get", "/api/department");
    setFilterData({ ...filterData, filter: false });
    let s1 = [...column];
    s1[4].options = hooks.sendName(res2, "departmentName");
    setColumn(s1);
    setOptions(res2);
    if (!!res) {
      setTotalrecords(res?.total_count);
      setMeetingData(res?.data);
    }
  };

  useEffect(() => {
    getApiData(page, size, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, filterData?.filter == true]);

  return (
    <div className="">
      <ComponentTable
        rows={meetingData}
        filterData={filterData}
        setFilterData={setFilterData}
        columns={column}
        pageData={data}
        page={page}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        totalRecords={totalRecords || 0}
        search={search}
        setSearch={setSearch}
        enableReset={true}
        resetdata={{ date: "" }}
        selected={selectedData}
        handleSelected={handleSelected}
        handleAllselect={handleAllselect}
        handleDelete={handleDelete}
      />
    </div>
  );
}
