import { Grid, Typography } from '@mui/material';
import React from 'react'
import ComponentButton from '../../componentGroups/componentButton';
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from 'react-i18next';
import ComponentTable from '../../componentGroups/componentTable';
import { useState } from 'react';
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from '../../../constant';
import { useContext } from 'react';
import LoadingContext from '../../../context/LoaderContext';
import { getAllData, getApi } from '../../../api/axios';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

const WaitingAllocations = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const { setLoader } = useContext(LoadingContext);
  const { t } = useTranslation();
  const [allocations, setAllocations] = useState()
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalrecords] = useState(0);
  const history = useHistory();

  const [data, setData] = useState({
    title: "labels.saved_allocations",
    page: "allocations",
    // infoURL: "/allocations/allocation-approval",
    hideSearch: true,
    infoCallback: (row) => {
      localStorage.setItem('allocation', JSON.stringify(row));
      history.push('/allocation/allocation-approval');
    },
    // hasVariable: true,
    // setRowToLocal: "candidate"
  })
  const [column] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    {
      lable: "labels.timeOff",
      name: "leaveType",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.allocation_type",
      name: "type",
      width: "10px",
      align: "left",
      minWidth: 120,
    },
    {
      lable: "labels.apply_on",
      name: "applyOn",
      width: "10px",
      align: "left",
      minWidth: 80,
    },
    {
      lable: "labels.duration",
      name: "duration",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.date_of_start",
      name: 'startDate',
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.date_of_end",
      name: "endDate",
      width: "10px",
      align: "left",
      minWidth: 110,
    },
    {
      lable: "labels.status",
      name: "status",
      width: "10px",
      align: "left",
      minWidth: 110,
    },

  ]);
  const getAllocations = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true)
    const allocationsRes = await getAllData(t, `/api/allocations/waiting/${user?.id}`, {
      page: pageIndex + 1,
      size: pageSize,
      search
    })
    setTotalrecords(allocationsRes?.data_count);
    setAllocations(allocationsRes?.data)
    setLoader(false)
  }
  useEffect(() => {
    getAllocations(page, size);
  }, [page, size, search]);

  return (
    <>
      <ComponentTable
        rows={allocations}
        columns={column}
        page={page}
        setSearch={setSearch}
        search={search}
        // hideSearch={true}
        setPage={setPage}
        rowsPerPage={size}
        setRowsPerPage={setSize}
        pageData={data}
        totalRecords={totalRecords}
      />
    </>
  )
}

export default WaitingAllocations