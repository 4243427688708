import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import http from "../../../../api/axios";
import { useHistory, useParams } from "react-router-dom";
import DapartmentEmployee from "./departmentEmp/dapartmentEmp";
import { useTranslation } from "react-i18next";
import Authorities from "./authorities";
import { DEP_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/dept_key_lables";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AddDepartment(props) {
  const history = useHistory();
  const { deptId } = useParams();
  const Id = deptId;
  const [authorityName, setAuthorityName] = useState([]);
  const [depData, setDepData] = useState({});
  const [depAuth, setdepAuth] = useState([]);
  const [value, setValue] = useState(0);
  const [flag, setFlag] = useState(false);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getApiData = async () => {
    let res = await http.getApi(t, `/api/department/${Id}`);
    let res1 = await http.getApi(t, `/api/authorities/`);
    props.setLoader(false);
    const sortedAuths = res1.sort((a, b) => a.group.localeCompare(b.group));
    const groupByCategory = sortedAuths?.reduce((item, auth) => {
      const { group } = auth;
      item[group] = item[group] ?? [];
      item[group].push(auth);
      return item;
    }, {});
    let authorities = [];
    Object.keys(groupByCategory).forEach((item) => {
      authorities = [...authorities, ...groupByCategory[item]];
    });
    let otherAuths = authorities?.filter((item) => item?.group === "other");
    let allAuths = authorities?.filter((item) => item?.group !== "other");
    const auths = [...allAuths, ...otherAuths];
    setAuthorityName(auths);
    const responsibilities = res?.department_has_authorities?.map(
      (item) => item?.id
    );
    setDepData({ ...res, department_has_authorities: responsibilities });
  };

  React.useEffect(() => {
    props.setLoader(true);
    getApiData();
  }, []);

  const handleAssign = async (obj) => {
    setFlag(false);
    props.setLoader(true);
    const formData = new FormData();
    formData.append("department_id", Id);
    depAuth?.map((i) => {
      formData.append(`authorities_ids[]`, parseInt(i));
    });

    formData.append("lang_id", `1`);
    const res = await http.putApi(
      t,
      "/api/department/assign/authorities",
      formData
    );
    getApiData();
    setFlag(true);
    history.push("/departments");
    props.setLoader(false);
  };

  return (
    <div className="CommonPd">
      <div className=" latest_des_box">
        <Grid className="dashTopGrid">
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <Typography className="morningText" variant="h4">
                {" "}
                {depData?.departmentName} / {depData?.departmentCode}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {value != 1 && (
                <Button
                  variant="contained"
                  sx={{ marginLeft: "50px" }}
                  className="buttonFill"
                  onClick={handleAssign}
                >
                  {t("labels.save")}
                </Button>
              )}
            </Grid>
          </Grid>

          <Box
            className="TaskTab TabActive"
            sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="iconText"
                label={t("titles.authorities")}
                {...a11yProps(0)}
              />
              <Tab
                className="iconText"
                label={t("labels.department_employees")}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} sx={{ minHeight: "300px" }}>
            <Authorities
              depData={depData}
              setDepData={setDepData}
              authorityName={authorityName}
              setdepAuth={setdepAuth}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DapartmentEmployee setLoader={props.setLoader} depData={depData} />
          </TabPanel>
          {depData?.departmentCode && (
            <LogNoteAccordion
              id={depData?.departmentCode}
              labelKeys={DEP_KEY_LABELS}
              flag={flag}
            />
          )}
        </Grid>
      </div>
    </div>
  );
}
