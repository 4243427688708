/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ComponentTable from "../../../componentGroups/componentTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import { INITIAL_PAGEINDEX, INITIAL_PAGESIZE } from "../../../../constant";
import http, { baseURL } from "../../../../api/axios";
import moment from "moment";
import { loadOptions } from "../../../../api/utils/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserHasAccess } from "../../../../utils/common";
import AuthorityContext from "../../../../context/AuthorityContext";
import LoadingContext from "../../../../context/LoaderContext";

export default function EmployeesReport(props) {
  const { t } = useTranslation();
  const { setAuthorityModal } = useContext(AuthorityContext);
  const { setLoader } = useContext(LoadingContext);
  const [page, setPage] = useState(INITIAL_PAGEINDEX);
  const [size, setSize] = useState(INITIAL_PAGESIZE);
  const [empReports, setEmpReports] = useState([]);
  const [totalRecords, setTotalrecords] = useState(0);
  const [filterData, setFilterData] = useState({
    filter: false,
    status: 2,
    departmentName: "",
  });
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");

  const [column, setColumn] = useState([
    {
      lable: "",
      name: "checkbox",
      width: "10px",
      align: "center",
      minWidth: 10,
    },
    { lable: "labels.name", name: "name", width: "10px", align: "left" },
    { lable: "labels.code", name: "code", width: "10px", align: "left" },
    {
      lable: "labels.phone_number",
      name: "phone",
      width: "10px",
      align: "left",
    },
    {
      lable: "labels.department",
      name: "departmentName",
      width: "10px",
      align: "left",
      type: "dropDown",
      options: [],
    },
    {
      lable: "labels.branch",
      name: "branchName",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
    { lable: "labels.email", name: "email", width: "10px", align: "left" },
    {
      lable: "labels.status",
      type: "dropDown",
      name: "status",
      width: "10px",
      align: "left",
      minWidth: 20,
      options: [
        { id: 1, name: "Active", option: "Active" },
        { id: 0, name: "In Active", option: "In Active" },
        { id: 2, name: "All", option: "All" },
      ],
    },
    {
      lable: "labels.employee_evaluation",
      name: "employee_evaluation",
      type: "dropDown",
      width: "10px",
      align: "left",
      minWidth: 10,
    },
  ]);
  const [data, setData] = useState({
    title: "titles.employees_report",
    page: "Employees Report",
    infoURL: "/reports/employee-report/selected-employee-report",
    hasVariable: true,
    handlExport: (page, size, filterData) =>
      handleExport(page, size, filterData),
    buttonGroup: [
      {
        title: "labels.action",
        type: "actions",
        icon: <AddIcon />,
        variant: "outlined",
        class: "buttonFill ml-0",
        callback: () => {},
      },
      {
        type: "filter",
        title: "labels.filter_by",
        icon: <FilterListIcon />,
        variant: "outlined",
        class: "buttonBlank",
        callback: () => {},
      },
    ],
  });

  const handleExport = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE,
    filterData
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ? filterData?.name : "");
    formdata.append("filter[code]", filterData?.code ? filterData?.code : "");
    formdata.append(
      "filter[email]",
      filterData?.email ? filterData?.email : ""
    );
    formdata.append(
      "filter[status]",
      filterData?.status === 2 ? "" : filterData?.status
    );
    formdata.append(
      "filter[departmentName]",
      filterData?.departmentName ? filterData?.departmentName : ""
    );
    formdata.append(
      "filter[phone]",
      filterData?.phone ? filterData?.phone : ""
    );
    const res = await http.getAllDataApi(
      t,
      `/api/employee/exportemployees/`,
      formdata
    );
    if (!!res?.link) {
      const url = await `${baseURL}/${res?.link}`;
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute = ("download", url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setLoader(false);
  };

  const getApiData = async (
    pageIndex = INITIAL_PAGEINDEX,
    pageSize = INITIAL_PAGESIZE
  ) => {
    setLoader(true);
    let formdata = new FormData();
    formdata.append("filter[name]", filterData?.name ? filterData?.name : "");
    formdata.append("filter[code]", filterData?.code ? filterData?.code : "");
    formdata.append(
      "filter[email]",
      filterData?.email ? filterData?.email : ""
    );
    formdata.append(
      "filter[status]",
      filterData?.status === 2 ? "" : filterData?.status
    );
    formdata.append(
      "filter[departmentName]",
      filterData?.departmentName ? filterData?.departmentName : ""
    );
    formdata.append(
      "filter[phone]",
      filterData?.phone ? filterData?.phone : ""
    );
    // }
    const res = await http.getAllDataApi(
      t,
      `/api/employee/?page=${pageIndex + 1}&size=${pageSize}&search=${search}`,
      formdata
    );
    setTotalrecords(res.data_count);
    setFilterData({ ...filterData, filter: false });
    setEmpReports(res.data);
    setLoader(false);
  };

  const setColumnData = async () => {
    let s1 = [...column];
    let employee = await loadOptions(t, 1, undefined, "post", "/api/employee");
    let branches = await loadOptions(
      t,
      "branch",
      undefined,
      "post",
      "/api/branch/details"
    );
    const branch = branches.map((item) => {
      return {
        ...item,
        name: item?.branchName,
      };
    });
    s1[1].options = employee;
    s1[7].options = branch;
    setColumn(s1);
  };

  useEffect(() => {
    setColumnData();
    getApiData(page, size);
  }, [page, size, filterData?.filter == true, search]);

  /**
   * to handle select all via checkbox
   * @param {*} e
   * @param {*} ids
   */
  const handleAllselect = (e, ids = []) => {
    if (e.target.checked) {
      setSelectedData([...ids]);
    } else {
      setSelectedData([]);
    }
  };

  const handleSelected = (e, id) => {
    if (selectedData.includes(id)) {
      const updated = selectedData.filter(
        (item) => parseInt(item) !== parseInt(id)
      );
      setSelectedData([...updated]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  /**
   * delete selected items
   */
  const handleDelete = async () => {
    if (getUserHasAccess("employee", 73)) {
      if (selectedData?.length > 0) {
        const formData = new FormData();
        if (selectedData?.length > 0) {
          selectedData.forEach((item, index) => {
            formData.append(`employee[${index}]`, item);
          });
        }
        await http.postApi(
          t,
          "/api/employee/delete/multiple",
          formData,
          "labels.employee_deleted_successfully"
        );
        getApiData();
      } else {
        toast.error(t("labels.select_atleast_one_row"));
      }
    } else {
      setAuthorityModal(true);
    }
  };

  return (
    <ComponentTable
      rows={empReports}
      columns={column}
      pageData={data}
      filterData={filterData}
      setFilterData={setFilterData}
      page={page}
      setPage={setPage}
      rowsPerPage={size}
      setRowsPerPage={setSize}
      totalRecords={totalRecords}
      search={search}
      setSearch={setSearch}
      selected={selectedData}
      handleSelected={handleSelected}
      handleAllselect={handleAllselect}
      handleDelete={handleDelete}
      enableReset={true}
      resetdata={{}}
    />
  );
}
