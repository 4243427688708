import React from "react";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
// import mentions from "./mentions";
import editorStyles from "./editorStyles.module.css";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";

class TextInputWithMention extends React.Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin();
  }

  state = {
    editorState: EditorState.createWithContent(
      ContentState.createFromText(this.props.inputText || "")
    ),
    suggestions: this.props.mentions,
  };

  componentDidUpdate(prevProps) {
    if (this.props.commentPushed === true) {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
    this.props.setCommentPushed(false);
  }

  onChange = (editorState) => {
    this.setState({ editorState });
    const contentState = editorState.getCurrentContent();
    // get all text typed in text area
    const plainText = contentState.getPlainText();
    this.props.setInputText(plainText);

    // get all mentioned users
    const raw = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    this.props.setMentionedUsers(mentionedUsers);
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.props.mentions),
    });
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    // console.log(this.state.textData);
    // console.log("mentionedUsers", this.props.mentions);
    return (
      <div className={editorStyles.editor}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          placeholder={this.props.placeholder ?? "Add a reply..."}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
      </div>
    );
  }
}

export default TextInputWithMention;
