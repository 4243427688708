import React from "react";
import MeetingHistory from "../PartnerScreens/PartnerMeeting/MeetingHistory";
import MeeitngReminder from "./ParnternMeetingTab/MeeitngReminder";
import Greeting from "../DashBoard/Greeting";
import DashBoardPendingRequests from "../DashBoard/DashBoardPendingRequests";
import DashBoardAttendanceRequest from "../DashBoard/DashBoardAttendanceRequest";

export default function CoordinatorDashboard(props) {
  return (
    <div>
      <Greeting />
      <DashBoardPendingRequests />
      <DashBoardAttendanceRequest />
      <MeeitngReminder endPoint={"/api/meeting/upcoming/list"} />
      <MeetingHistory />
    </div>
  );
}
