import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
    Modal,
    Grid,
    Card,
    CardHeader,
    TextField,
    FormControl,
    Select,
    IconButton,
    CardContent,
    CardActions,
    MenuItem,
    InputLabel,
} from "@mui/material";
import ComponentButton from "../../../../../componentGroups/componentButton";
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from "react-router-dom";
import Svg from "../../../../../../assets/svg/svg";
import "../../../style.css"
import ResponsiveDialog from "./childComp";
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    // borderRadius: "2px solid red",
    // border: "2px solid ",
    boxShadow: 20,
    // p: 4,
};

export default function Model(props) {
    const { t, i18n } = useTranslation();
    const [childModel, setchildModel] = useState(false);
    const [childModelData, setChildModelData] = useState({});
    const [addClass, setAddClass] = useState("")
    const history = useHistory();
    const [data, setdata] = useState({});
    const handleFields = (type) => {
        setAddClass(type)
        let arr = { type: type, name: `${type}${props.modalData.fieldIndex}` }
        setdata(arr)
    }
    const handleChildComp = (val) => {
        setAddClass(val)
        if (val == "dropdown") {
            setChildModelData({ title: "Add Dropdown", type: "dropdown" })
            setchildModel(true)
        } else if (val == "checkbox") {
            setChildModelData({ title: "Add Checkbox", type: "checkbox" })
            setchildModel(true)
        } else if (val == "radio") {
            setChildModelData({ title: "Add Radio", type: "radio" })
            setchildModel(true)
        } else if (val == "table") {
            setChildModelData({ title: "Add Table", type: "table" })
            setchildModel(true)
        }
    }
    const handleChildModelCallback = (arr) => {
        setchildModel(false)
        setAddClass("")
        setdata(arr)
        props.closeModel(props.modalData.fieldIndex, arr, props.modalData.rowIndex, props.modalData.tableRowIndex, props.modalData.tableColIndex)
    }
    const closeChildModel = () => {
        setAddClass("")
        setdata({})
        setchildModel(false)
    }
    return (
        <>
            <ResponsiveDialog open={childModel} setchildModel={closeChildModel} data={childModelData} modelCallback={handleChildModelCallback} />
            <Modal className='MainModal'
                open={props.modalData.model}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="ModalBG" sx={style}>
                    <div>
                        <Typography variant='h6' className="morningText" >{t('forms.add_tool')}</Typography>
                    </div>
                    <div className="scroll-auto">
                        <Grid className="dashTopGrid">
                            {/* <Typography variant='h6' className="morningText" >{t('forms.add_tool')}</Typography> */}
                            <Grid container className="grid_container" spacing={1} >
                                <Grid item xs={12} md={4} onClick={() => handleFields("text")}>
                                    <Card className={addClass == "text" ? " FormDoc-Card forms_card active" : "FormDoc-Card forms_card "}>
                                        <Typography
                                            variant="body2"
                                            sx={{ paddingTop: "1px !important" }}
                                            className="morningText"
                                        >
                                            {t('forms.text_box')}
                                        </Typography>
                                        <Svg type="textField" />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} onClick={() => handleFields("date")}>
                                    <Card className={addClass == "date" ? " FormDoc-Card forms_card active" : "FormDoc-Card forms_card "}>
                                        <Typography
                                            variant="body2"
                                            sx={{ paddingTop: "1px !important" }}
                                            className="morningText"
                                        >
                                            {t('forms.date_field')}
                                        </Typography>
                                        <Svg type="date" />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} onClick={() => handleChildComp("dropdown")}>
                                    <Card className={addClass == "dropdown" ? " FormDoc-Card forms_card active" : " FormDoc-Card forms_card "}>
                                        <Typography
                                            variant="body2"
                                            sx={{ paddingTop: "1px !important" }}
                                            className="morningText"
                                        >
                                            {t('forms.drop_downlist')}
                                        </Typography>
                                        <Svg type="textField" />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} onClick={() => handleChildComp("checkbox")}>
                                    <Card className={addClass == "checkbox" ? " FormDoc-Card forms_card active" : " FormDoc-Card forms_card"}>
                                        <Typography
                                            variant="body2"
                                            className="morningText"
                                        >
                                            {t('forms.check_box')}
                                        </Typography>
                                        <Svg type="check_box" />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} onClick={() => handleChildComp("radio")}>
                                    <Card className={addClass == "radio" ? " FormDoc-Card forms_card active" : " FormDoc-Card forms_card"}>
                                        <Typography
                                            variant="body2"

                                            className="morningText"
                                        >
                                            {t('forms.radio_button')}
                                        </Typography>
                                        <Svg type="radio" />
                                    </Card>
                                </Grid>
                                {props.modalData.tableShow == true &&
                                    <Grid item xs={12} md={4} onClick={() => handleChildComp("table")}>
                                        <Card className={addClass == "table" ? " FormDoc-Card forms_card active" : " FormDoc-Card forms_card"}>
                                            <Typography
                                                variant="body2"
                                                className="morningText"
                                            >
                                                {t('forms.table')}
                                            </Typography>
                                            <Svg className="Tableicon" type="table" />
                                        </Card>
                                    </Grid>}
                                <Grid item xs={12} md={4} onClick={() => handleFields("file")}>
                                    <Card className={addClass == "file" ? " FormDoc-Card forms_card active" : " FormDoc-Card forms_card"}>
                                        <Typography
                                            variant="body2"
                                            className="morningText AttachmentIcon"
                                        >
                                            {t('forms.attachment')}
                                        </Typography>
                                        <Svg type="attachment" />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ borderTop: "1px solid #2B564C ", marginTop: "20px" }}>
                        <Grid
                            container
                            className="modalGroup"
                            style={{ marginTop: "5px" }}
                        >
                            {props.modalData.buttonGroup &&
                                props.modalData.buttonGroup.map((op, index) => (
                                    <Grid item key={index}>
                                        <ComponentButton
                                            value={op.value}
                                            variant={op.variant}
                                            class={op.class}
                                            callback={() => {
                                                op.callback(props.modalData.fieldIndex, data, props.modalData.rowIndex, props.modalData.tableRowIndex, props.modalData.tableColIndex);
                                            }}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>

                </Box>
            </Modal>
        </>);
}
