import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import "../style.css";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import http from "../../../../api/axios";
import AddressesSet from "./addresses";
import GeneralInfo from "./generalInfo";
import PersonalInformation from "./personalInfo";
import Responsibilities from "./responsibilities";
import Attachments from "./attachments";
import auth from "../../../../services/auth";
import moment from "moment";
import Payroll from "./payroll";
import LeaveBalance from "./leave_balance";
import Attendance from "./attendance";
import { getUserHasAccess } from "../../../../utils/common";
import { EMP_KEY_LABELS } from "../../../componentGroups/logNotes/Labels/empl_key_lable";
import LogNoteAccordion from "../../../componentGroups/logNotes/logNoteAccordian";
import EmployeePerformanceScore from "./EmployeePerformanceScore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddEmployee({ setLoader }) {
  const { empId } = useParams();
  const [journy, setJourny] = useState({})
  empId && auth.login("empId", empId);
  const Id = auth.getUser("empId");
  const user = JSON.parse(localStorage.getItem("user"));

  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [empData, setEmpData] = useState({});
  const [flag, setFlag] = useState(false);
  const [isCordinator, setIscordinator] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState({
    type: [],
    seniorityLevel: [],
    title: [],
    gender: [
      { id: 1, name: "labels.male", option: t("labels.male") },
      { id: 2, name: "labels.female", option: t("labels.female") },
      { id: 3, name: "labels.others", option: t("labels.others") },
    ],
    houseAllowance: [
      { id: 2, name: "labels.yes" },
      { id: 1, name: "labels.no" },
    ],
    leavePeriod: [
      { id: 1, name: "labels.sick_leave" },
      { id: 2, name: "labels.casual_leave" },
      { id: 3, name: "labels.annual_eave" },
    ],
    approvedStatus: [
      { id: 0, name: "labels.pending" },
      { id: 1, name: "labels.approved" },
      { id: 2, name: "labels.rejected" },
    ],
    allResponsibility: [],
    selectedDepartmentEmployee: [],
    jobTitle: [],
    allBranches: [],
    allDepartments: [],
  });
  const INITIAL_ADDRESSFIELDS = {
    addressName: "Address 1",
    isPrimary: false,
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  };

  const [errors, setErrors] = useState({});
  const [empState, setEmpState] = useState({
    code: "",
    //General information
    name: "",
    password: "",
    departmentId: "",
    departmentCode: "", // to be removed while updating
    branchId: "",
    branchCode: "", // to be removed while updating
    seniorityLevel: "",
    type: "",
    email: "",
    startWorkDate: "",
    endWorkDate: "",
    directManagerId: "",
    directCoachId: "",
    responsibleHrId: "",
    assignTo: "",
    jobId: "",
    //Peronal Information
    title: "",
    gender: "",
    fatherName: "",
    motherName: "",
    phone: "",
    dob: "",
    fax: "",
    nationality: "",
    emergencyContact: [{ id: 0, contact: "" }],
    //Address information
    addresses: [INITIAL_ADDRESSFIELDS],
    // Responsibilities
    responsibilities: [],
    //documents
    employeeDocuments: [
      {
        category: "cv",
        name: "",
        type: "",
        path: "",
        file: "",
      },
      {
        category: "identity",
        name: "",
        type: "",
        path: "",
      },
    ],
    //employees_payroll
    employees_payroll: {
      contractStartDate: "",
      contractEndDate: "",
      salary: "",
      houseAllowance: "",
      transporationAllowance: "",
    },
    //
    status: false,
  });

  /**
   *
   * @param {*} key  dropdown options key
   * @param {*} id   category id on dropdown field
   */
  const loadDropdownOptions = async (
    key,
    id,
    type = "post",
    endPoint = "/api/dropdown/show-options"
  ) => {
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("id", id);
      res = await http.getAllDataApi(t, endPoint, formData);
      res = res?.data;
    }
    if (type === "get") {
      res = await http.getApi(t, `${endPoint}`);
    }
    let options = res?.map((op) => {
      return { ...op, name: op.name ?? op.option ?? op.title };
    });
    if (key === "allResponsibility") {
      const sortedAuths = options.sort((a, b) =>
        a.group.localeCompare(b.group)
      );
      const groupByCategory = sortedAuths?.reduce((item, auth) => {
        const { group } = auth;
        item[group] = item[group] ?? [];
        item[group].push(auth);
        return item;
      }, {});
      let authorities = [];
      Object.keys(groupByCategory).forEach((item) => {
        authorities = [...authorities, ...groupByCategory[item]];
      });
      let otherAuths = authorities?.filter((item) => item?.group === "other");
      let allAuths = authorities?.filter((item) => item?.group !== "other");
      options = [...allAuths, ...otherAuths];
    }
    if (key === "employess_of_department") {
      const user = JSON.parse(localStorage.getItem("user"));
      options = options?.filter((item) => item?.id !== user?.id);
    }
    if (key === "selectedDepartmentEmployee") {
      options = options?.filter((item) => item?.id !== empState?.id);
    }
    setDropDownOptions((prevVals) => ({
      ...prevVals,
      [key]: options,
    }));
  };

  /**
   * add custom options in dropdowns
   * @param {*} val
   * @param {*} key
   * @param {*} category_id
   * @param {*} type
   * @param {*} endPoint
   */
  const addDropdownOptions = async (
    val,
    key,
    category_id,
    type = "post",
    endPoint = "/api/dropdown/create-category"
  ) => {
    setLoader(true);
    let res = {};
    if (type === "post") {
      const formData = new FormData();
      formData.append("category_id", category_id);
      formData.append("option", val);
      formData.append("langId", i18n.language === "en" ? 1 : 2);
      res = await http.postApi(t, endPoint, formData);
      if (!!res) {
        loadDropdownOptions(key, category_id);
      }
    }
    setLoader(false);
  };

  /**
   * fetch journy branch department
   */

  const getBranchDepartment = async () => {
    let department = await http.getApi(t, `/api/department/branch-basis/${empState?.branchId ? empState?.branchId : undefined}`);
    setJourny({ ...journy, allDepartments: department })
  }


  /**
   * fetch employee data and intialize intial employee data
   */
  const getApiData = async () => {
    setLoader(true);
    let res = await http.getApi(t, `/api/employee/find/${Id}`);
    setLoader(false);
    const employeeData = {
      ...empState,
      id: res?.id ?? "",
      code: res?.code ?? "",
      name: res?.name ?? "",
      departmentId: res?.departmentId ?? "",
      branchId: res?.branchId ?? "",
      email: res?.email ?? "",
      dob: res?.employees_detail?.dob
        ? moment(res?.employees_detail?.dob).format("YYYY-MM-DD")
        : "",
      fatherName: res?.employees_detail?.fatherName ?? "",
      fax: res?.employees_detail?.fax ?? "",
      directCoachId: res?.employees_detail?.directCoachId ?? "",
      directManagerId: res?.directManagerId ?? "",
      responsibleHrId: res?.responsibleHrId ?? "",
      gender: res?.employees_detail?.gender ?? "",
      motherName: res?.employees_detail?.motherName ?? "",
      nationality: res?.employees_detail?.nationality ?? "",
      phone: res?.employees_detail?.phone ?? "",
      seniorityLevel: res?.employees_detail?.seniorityLevel ?? "",
      title: res?.employees_detail?.title ?? "",
      type: res?.employees_detail?.type ?? "",
      status: res?.isActive === "0" ? false : true,
      assignTo: res?.employees_detail?.assignTo ?? "",
      jobId: res?.jobId ?? "",
      startWorkDate: res?.employees_detail?.startWorkDate
        ? moment(res?.employees_detail?.startWorkDate).format("YYYY-MM-DD")
        : "",
      endWorkDate: res?.employees_detail?.endWorkDate
        ? moment(res?.employees_detail?.endWorkDate).format("YYYY-MM-DD")
        : "",
    };
    if (res?.employees_emergency_contacts?.length > 0) {
      employeeData.emergencyContact = res?.employees_emergency_contacts;
    } else {
      employeeData.emergencyContact = [{ id: 0, contact: "" }];
    }
    let isCordinator = parseInt(res?.departmentId) === 3 ? true : false;
    setIscordinator(isCordinator);
    if (res?.addresses?.length > 0) {
      employeeData.addresses = res?.addresses.map((item) => {
        return {
          ...item,
          isPrimary: JSON.parse(item.isPrimary),
          addressName: item.addressName ?? "",
          address1: item.address1 ?? "",
          address2: item.address2 ?? "",
          city: item.city ?? "",
          state: item.state ?? "",
          country: item.country ?? "",
          zip: item.zip ?? "",
        };
      });
    }
    if (res?.users_has_responsibilites?.length > 0) {
      employeeData.responsibilities = res?.users_has_responsibilites?.map(
        (ele) => ele?.responsiblityId
      );
    }
    if (res?.employee_documents?.length > 0) {
      let doccuments = res?.employee_documents?.map((item) => {
        return {
          ...item,
          file: "",
        };
      });
      const el = doccuments.map((el) => el.category);
      if (!el.includes("cv") && el.includes("identity")) {
        doccuments = [
          {
            category: "cv",
            name: "",
            type: "",
            path: "",
            file: "",
          },
          ...doccuments,
        ];
      } else if (el.includes("cv") && !el.includes("identity")) {
        doccuments = [
          ...doccuments,
          {
            category: "identity",
            name: "",
            type: "",
            path: "",
            file: "",
          },
        ];
      } else if (!el.includes("cv") && !el.includes("identity")) {
        doccuments = [
          ...doccuments,
          {
            category: "cv",
            name: "",
            type: "",
            path: "",
            file: "",
          },
          {
            category: "identity",
            name: "",
            type: "",
            path: "",
            file: "",
          },
        ];
      }
      employeeData.employeeDocuments = doccuments;
    }
    const employees_payroll = {
      contractStartDate: res?.employees_payroll?.contractStartDate ?? "",
      contractEndDate: res?.employees_payroll?.contractEndDate ?? "",
      salary: res?.employees_payroll?.salary ?? "",
      houseAllowance: res?.employees_payroll?.houseAllowance ?? "",
      transporationAllowance:
        res?.employees_payroll?.transporationAllowance ?? "",
    };
    employeeData.employees_payroll = employees_payroll;
    if (isCordinator) {
      let branches = await http.getApi(t, `/api/branch/department-basis`);
      let department = await http.getApi(t, `/api/department/branch-basis/${res?.branchId}`);
      setJourny({ ...journy, allDepartments: department, allBranches: branches })
    } else {
      let branches = await http.getAllDataApi(t, `/api/branch/details`);
      let department = await http.getApi(t, `/api/department/branch-basis/${res?.branchId}`);
      setJourny({ ...journy, allDepartments: department, allBranches: branches?.data })
    }
    setEmpState(employeeData);
    setEmpData(res);
  };

  // useEffect(async () => {
  //   getBranchDepartment()
  // }, [empState?.branchId]);
  /**
   * employee data and dropdown options are fetched
   */
  useEffect(() => {
    if (Id) {
      getApiData();
    }
    axios.all([
      loadDropdownOptions("seniorityLevel", 5),
      loadDropdownOptions("type", 6),
      loadDropdownOptions("title", 7),
      loadDropdownOptions("nationality", 9),
      loadDropdownOptions("employees", "", "get", "/api/employee/dropdown/all"),
      loadDropdownOptions(
        "employess_of_department",
        "",
        "get",
        "/api/employee/all/department/8"
      ),
      loadDropdownOptions("allResponsibility", "", "get", "/api/authorities"),
      loadDropdownOptions("allBranches", "", "post", "/api/branch/details"),
      loadDropdownOptions("allDepartments", "", "get", "/api/department"),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      dropDownOptions?.allBranches?.length > 0 &&
      dropDownOptions?.allDepartments?.length > 0 &&
      empState?.departmentId
    ) {
      const selectedDepartMent = dropDownOptions?.allDepartments.find(
        (item) => parseInt(item.id) === parseInt(empState?.departmentId)
      );
      const selectedBranch = dropDownOptions?.allBranches.find(
        (item) => parseInt(item.id) === parseInt(empState?.branchId)
      );
      if (!!selectedBranch && !!selectedDepartMent) {
        setEmpState({
          ...empState,
          departmentCode: selectedDepartMent?.departmentCode,
          branchCode: selectedBranch?.branchCode,
          assignTo:
            parseInt(empState?.departmentId) !== 3 ? "" : empState?.assignTo,
        });
      }
      loadDropdownOptions(
        "responsibility",
        empState?.departmentId,
        "get",
        `/api/employee/responsibilities/all/${empState?.departmentId}`
      );
      loadDropdownOptions(
        "selectedDepartmentEmployee",
        "",
        "get",
        `/api/employee/all/department/${empState?.departmentId}`
      );
      loadDropdownOptions(
        "jobTitle",
        "",
        "get",
        `/api/setting/department/job-position/${empState?.departmentId}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dropDownOptions?.allBranches,
    dropDownOptions?.allDepartments,
    empState?.departmentId,
  ]);

  const empValidate = (data) => {
    let error = {};
    if (data.name === undefined || data.name.trim() === "") {
      error.name = `errors.enter_name`;
    }
    if (data.departmentId === undefined || data.departmentId === "") {
      error.departmentId = `errors.select_department`;
    }
    if (data.branchId === undefined || data.branchId === "") {
      error.branchId = `errors.select_branch`;
    }
    if (data.email === undefined || data.email.trim() === "") {
      error.email = `errors.enter_email`;
    }
    if (
      data?.email &&
      !/^[A-Z0-9\u0600-\u06FF._%+-]+@[A-Z0-9\u0600-\u06FF.-]+\.[A-Z]{2,4}$/i.test(
        data.email
      )
    ) {
      error.email = "errors.enter_valid_email";
    }
    if (data.password.trim().length !== 0 && data.password.trim().length < 8) {
      error.password = `errors.min_pass_length`;
    }
    if (data.jobId === undefined || data.jobId === "") {
      error.jobId = "errors.select_job_position";
    }
    if (data.responsibleHrId == undefined || data.responsibleHrId == "") {
      error.responsibleHrId = "errors.select_responsible_hr";
    }
    if (data.directManagerId == undefined || data.directManagerId == "") {
      error.directManagerId = "errors.select_direct_manager";
    }
    // if (
    //   (data.phone !== undefined || data.phone !== "") &&
    //   data.phone?.length < 10
    // ) {
    //   error.phone = "errors.enter_phone_number_min_length";
    // }
    // if (
    //   (data.phone !== undefined || data.phone !== "") &&
    //   data.phone?.length > 20
    // ) {
    //   error.phone = "errors.enter_phone_number_max_length";
    // }
    return error;
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      let error = empValidate(empState);
      setErrors(error);
    }
    // getBranchDepartment()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empState]);

  /**
   * update emp data
   */
  const updateEmployee = async () => {
    setFlag(false);
    let errors = empValidate(empState);
    if (Object.keys(errors).length === 0) {
      setLoader(true);
      const formData = new FormData();
      const updatedData = { ...empState };
      delete updatedData?.departmentCode;
      delete updatedData?.branchCode;
      delete updatedData?.code;
      delete updatedData?.employeeDocuments;
      delete updatedData?.status;
      delete updatedData?.employees_payroll;
      delete updatedData?.responsibilities;
      delete updatedData?.startWorkDate;
      delete updatedData?.endWorkDate;
      Object.keys(updatedData).forEach((element) => {
        if (Array.isArray(updatedData[element])) {
          updatedData[element].forEach((item, index) => {
            if (typeof item === "object" && item !== null) {
              Object.keys(item).forEach((ele) => {
                if (ele === "isPrimary") {
                  formData.append(
                    `${element}[${index}][${ele}]`,
                    !!updatedData[element][index][ele] ? 1 : 0
                  );
                } else {
                  formData.append(
                    `${element}[${index}][${ele}]`,
                    updatedData[element][index][ele] ?? ""
                  );
                }
              });
            } else {
              formData.append(
                `${element}[${index}]`,
                updatedData[element][index] ?? ""
              );
            }
          });
        } else {
          if (element === "dob") {
            formData.append(
              element,
              updatedData[element]
                ? moment(updatedData[element]).format("MM-DD-YYYY")
                : ""
            );
          } else {
            formData.append(element, updatedData[element]);
          }
        }
      });
      const doccuments = [...empState.employeeDocuments];
      doccuments.forEach((item) => {
        if (!!item?.file) {
          formData.append(`documents[${item?.category}]`, item.file);
        }
      });
      const responsibilities = [...empState.responsibilities];
      responsibilities.forEach((item) => {
        formData.append(`responsibilities[]`, item);
      });
      formData.append(
        `startWorkDate`,
        empState?.startWorkDate
          ? moment(empState?.startWorkDate).format("MM-DD-YYYY")
          : ""
      );
      formData.append(
        `endWorkDate`,
        empState?.endWorkDate
          ? moment(empState?.endWorkDate).format("MM-DD-YYYY")
          : ""
      );
      const payroll = empState.employees_payroll;
      Object.keys(payroll).forEach((ele) => {
        formData.append(`payroll[${ele}]`, payroll[ele]);
      });
      formData.append("langId", i18n.language === "en" ? `1` : `2`);
      formData.append("isActive", !!empState.status ? "1" : "0");
      await http.putApi(t, `/api/employee/update/${Id}`, formData).then(() => {
        const { code } = JSON.parse(localStorage.getItem("user")) || {};
        if (code === empState?.code) {
          let str = JSON.stringify([...empState.responsibilities]);
          localStorage.setItem("authority", str);
        }
      });

      setFlag(true);
      setLoader(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="CommonPd latest_des_box">
      <Grid className="dashTopGrid">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={9}>
            <Typography variant="h6" className="morningText">
              {empData?.name} /{empData?.code}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              sx={{ marginLeft: "50px" }}
              className="buttonFill"
              onClick={updateEmployee}
            >
              {t("labels.save")}
            </Button>
          </Grid>
        </Grid>
        <Box
          className="TaskTab TabActive"
          sx={{ borderBottom: 1, borderColor: "rgba(183, 161, 96, 0.26)" }}
        >
          <Tabs
            className="TabActive"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="iconText"
              label={t("labels.general_info")}
              value={0}
            />
            <Tab
              className="iconText"
              label={t("labels.personal_information")}
              value={1}
            />
            <Tab className="iconText" label={t("labels.addresses")} value={2} />
            <Tab
              className="iconText"
              label={t("labels.responsibilities")}
              value={3}
            />
            <Tab
              className="iconText"
              label={t("labels.attachments")}
              value={4}
            />
            {getUserHasAccess("employee", 166) && (
              <Tab className="iconText" label={t("labels.payroll")} value={5} />
            )}
            {getUserHasAccess("employee", 167) && (
              <Tab
                className="iconText"
                label={t("labels.attendance")}
                value={6}
              />
            )}
            {getUserHasAccess("employee", 168) && (
              <Tab
                className="iconText"
                label={t("labels.leave_balance")}
                value={7}
              />
            )}
            <Tab className="iconText" label={t("Performance")} value={8} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GeneralInfo
            empData={empData}
            empState={empState}
            setEmpState={setEmpState}
            dropDownOptions={dropDownOptions}
            errors={errors}
            addDropdownOptions={addDropdownOptions}
            isCordinator={isCordinator}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PersonalInformation
            errors={errors}
            empState={empState}
            setEmpState={setEmpState}
            dropDownOptions={dropDownOptions}
            addDropdownOptions={addDropdownOptions}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AddressesSet
            empState={empState}
            setEmpState={setEmpState}
            dropDownOptions={dropDownOptions}
            initialAddressFields={INITIAL_ADDRESSFIELDS}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Responsibilities
            empState={empState}
            setEmpState={setEmpState}
            dropDownOptions={dropDownOptions}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Attachments empState={empState} setEmpState={setEmpState} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Payroll
            empState={empState}
            setEmpState={setEmpState}
            errors={errors}
            dropDownOptions={dropDownOptions}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Attendance
            Id={Id}
            empState={empState}
            setEmpState={setEmpState}
            errors={errors}
            setLoader={setLoader}
            dropDownOptions={dropDownOptions}
          />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <LeaveBalance
            empState={empState}
            setEmpState={setEmpState}
            errors={errors}
            setLoader={setLoader}
            dropDownOptions={dropDownOptions}
            empId={Id}
          />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <EmployeePerformanceScore id={Id} />
        </TabPanel>

        {empState?.code && (
          <LogNoteAccordion
            id={empState?.code}
            labelKeys={EMP_KEY_LABELS}
            flag={flag}
          />
        )}
      </Grid>
    </div>
  );
}
