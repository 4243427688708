const timeoffValidation = (data, description) => {
    let error = {};
    if (data.leave_type == undefined || data.leave_type == "") {
        error.leave_type = "errors.select_leave_type";
    }
    if (data.days == undefined || data.days == "") {
        error.days = "errors.holiday_period";
    }
    if (data.start_date == undefined || data.start_date == "") {
        error.start_date = "errors.start_date";
    }

    if (data.end_date == undefined || data.end_date == "") {
        error.end_date = "errors.end_date";
    }

    return error;
};
const leaveTypeValidation = (data, description) => {
    let error = {};
    if (data.name == undefined || data.name == "") {
        error.name = "errors.enter_name";
    }

    return error;
};

export default {
    timeoffValidation, leaveTypeValidation
};
